import { getLocalStorage } from '@/helpers'
import { DEFAULT_SUPPLIER_KEY, DEFAULT_UOM } from '@/const'
import { Supplier } from '@/interfaces/global'

export function getDefaultSupplier(tenantDefaultSupplierKey: string|null, suppliers: Supplier[]): Supplier|null { 
  const pbDefaultSupplierKey = getLocalStorage(DEFAULT_SUPPLIER_KEY)
  const defaultSupplierKey: string|null = pbDefaultSupplierKey ?? tenantDefaultSupplierKey
  const matchingSupplier = suppliers
    .find((it: Supplier) => it.supplierKey === defaultSupplierKey)
  return matchingSupplier ?? null
}

export function getDefaultUom(tenantDefaultUom: string): string|null {
  const userDefaultUom = getLocalStorage(DEFAULT_UOM)
  //  Ensure that empty string value is returned if it is set
  if(userDefaultUom || userDefaultUom === '') {
    return userDefaultUom
  } else {
    return tenantDefaultUom || null
  }
}
