<template>
  <component
    :is="filterType"
    :filter="filter"
    :selected-values="selected"
    class="filter-item"
    @change="filterValuesChanged"
    @remove="removeFilter"
  />
</template>

<script>
import CategoryFilter from './CategoryFilter'
import DateFilter from './DateFilter'
import FacetFilter from './FacetFilter'
import ListOptionsFilter from './ListOptionsFilter'
import MappedListOptionsFilter from './MappedListOptionsFilter'
import RangeFilter from './RangeFilter'
import TextFilter from './TextFilter'
import TextSuggestFilter from './TextSuggestFilter'
import TypeFilter from './TypeFilter'

export default {
  name: 'FilterItem',
  props: {
    filter: {
      type: Object,
      required: true
    },
    selectedOptions: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selectedOptions () {
      this.updateSelected()
    }
  },
  computed: {
    filterType () {
      switch (this.filter.type) {
        case 'facet':
        case 'list':
          return FacetFilter
        case 'text':
          return TextSuggestFilter
        case 'type':
          return TypeFilter
        case 'category':
          return CategoryFilter
        case 'mapped':
          return MappedListOptionsFilter
        case 'range':
          return RangeFilter
        case 'date':
          return DateFilter
        case 'textfield':
        case 'tag':
          return TextFilter
        case 'listbox':
          return ListOptionsFilter
        default: throw new Error('no filter exists')
      }
    }
  },
  methods: {
    filterValuesChanged (values) {
      this.selected = values
      this.$emit('updateOption', {
        filter: this.filter,
        options: this.selected
      })
    },
    removeFilter () {
      this.$emit('remove', this.filter)
    },
    updateSelected () {
      if (this.filter.type === 'range') {
        const value = this.selectedOptions[this.filter.field]
        const prefix = this.selectedOptions[`${this.filter.field}_prefix`]
        const suffix = this.selectedOptions[`${this.filter.field}_suffix`]
        this.selected = value
          ? [{ value, prefix, suffix }]
          : []
      } else {
        this.selected = this.selectedOptions[this.filter.field]
          ? this.selectedOptions[this.filter.field]
          : []
      }
    }
  },
  mounted () {
    this.updateSelected()
  }
}
</script>

<style scoped>
.filter-item {
  margin: 0.25rem .25rem .25rem 0;
}
</style>
