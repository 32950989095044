export const vDMouseout = {
  hasBindingValue: false,
  beforeMount(el, binding, somFun) {
    const newEvent = new MouseEvent("mouseout");
    if(!binding.value) return

    binding.dir.hasBindingValue = true
    el.dispatchEvent(newEvent)
    el.mouseoutExtend = function(event) {
      // Check if the clicked element is neither the element
      // to which the directive is applied nor its child
      if (!(el === event.target || el.contains(event.target))) {
        // Invoke the provided method
        binding.value();
      }
    };
    document.addEventListener('mouseout', el.mouseoutExtend);
  },
  beforeUnmount(el, binding) {
    if (!binding.dir.hasBindingValue) return
    // Remove the event listener when the bound element is unmounted
    document.removeEventListener('mouseout', el.mouseoutExtend);
    binding.dir.hasBindingValue = false
  },
}
