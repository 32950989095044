import { Colors, Images, StylesData } from '@/interfaces/admin'

export const enum SourceType {
  ORGANIZATION = 'ORGANIZATION',
  TENANT = 'TENANT',
  SYSTEM = 'SYSTEM'
}

export interface InheritedProperty {
  sourceId: number | null,
  sourceName: string | null,
  sourceType: SourceType
}

export interface InheritedColors extends Colors {
  hotpointSource: InheritedProperty,
  infoSource: InheritedProperty,
  linksSource: InheritedProperty,
  primarySource: InheritedProperty
}

export interface InheritedImages extends Images {
  ecommerceLogoSource: InheritedProperty,
  heroImageSource: InheritedProperty,
  mainLogoSource: InheritedProperty,
  loginBackgroundLogoSource: InheritedProperty,
  printLogoSource: InheritedProperty
}

export interface InheritedStyles {
  colors: InheritedColors,
  images: InheritedImages
}

export interface OrganizationAdminStyles {
  inheritedStyles: InheritedStyles,
  styles: StylesData
}
