import http from '@/http'

export async function eagerLoad (url, sort = null, filter = null) {
  const items = []
  const params = {
    offset: 0,
    filter: filter || null,
    sort: sort || 'name:asc'
  }

  let total = -1
  let previousOffset = -1

  while (total !== items.length) {
    if (params.offset === previousOffset) {
      console.error('Pagination failure')
      throw new Error()
    }
    const { data, headers } = await http.get(url, { params })
    total = parseInt(headers['x-count'])
    previousOffset = params.offset
    params.offset = parseInt(headers['x-offset']) + parseInt(headers['x-limit'])
    items.push(...data)
  }

  return items
}
