<template>
  <td
    class="price-cell"
    :class="{'has-text-info': bomItem.highlight}"
    @dblclick.stop="open"
  >
    <label v-if="!bomItem.orderable" />
    <label v-else-if="priceAmount === 0">{{ $t('noPrice') }}</label>
    <label v-else-if="price">{{ price }}</label>
  </td>
</template>

<script>
export default {
  name: 'BomCellPrice',
  props: {
    bomItem: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      price: '',
      priceAmount: -1
    }
  },
  methods: {
    computePrice () {
      const defaultPrice = ''

      if (this.column.property === 'retail' && !this.bomItem.hideRetailPrice) {
        this.price = this.bomItem.retailPrice
      } else if (this.column.property === 'wholesale' && !this.bomItem.hideWholeSalePrice) {
        this.price = this.bomItem.wholesalePrice
      } else if (this.column.property === 'discounted' && !this.bomItem.hideDiscountPrice) {
        this.price = this.bomItem.discountPrice
      } else {
        this.price = defaultPrice
      }
    },
    computePriceAmount () {
      const defaultPriceAmount = -1

      if (this.column.property === 'retail' && !this.bomItem.hideRetailPrice) {
        this.priceAmount = this.bomItem.retailAmount
      } else if (this.column.property === 'wholesale' && !this.bomItem.hideWholeSalePrice) {
        this.priceAmount = this.bomItem.wholesaleAmount
      } else if (this.column.property === 'discounted' && !this.bomItem.hideDiscountPrice) {
        this.priceAmount = this.bomItem.discountAmount
      } else {
        this.priceAmount = defaultPriceAmount
      }
    },
    open () {
      this.$emit('open', {
        content: this.bomItem,
        route: ''
      })
    }
  },
  mounted () {
    this.computePrice()
    this.computePriceAmount()
  }
}
</script>

<style scoped>
.price {
  text-align: center;
}
.table tr.is-selected td {
  color: currentColor !important;
}
</style>
