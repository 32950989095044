<template>
  <nav class="is-flex flex-start my-2 p-1">
    <ul class="is-flex">
      <li v-for="(crumb, i) in breadcrumbsWithRedirect"
          :key="i"
          :style="[isStaticRootBreadcrumb(i) ? 'min-width: min-content' : '']"
          :class="{ 'breadcrumb-li-slash': !(i === 0) }"
          class="has-text-link pointer-on-hover breadcrumb-li"
          @click="$emit('openAsset', crumb)">
        <div :class="['mx-1', { 'breadcrumb-item': !(isStaticRootBreadcrumb(i)) }]">
          {{ crumb?.name }}
        </div>
      </li>
      <li :class="[{ 'breadcrumb-li-slash': !hasSingleBreadcrumb },
                  'breadcrumb-li']">
        <div class="mx-1 breadcrumb-item">
          {{ lastBreadcrumb?.name }}
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { computed } from "vue"
import { WidgetRootBreadcrumbs } from '@/interfaces/global/widgets'

const props = defineProps({
  breadcrumbs: {
    type: Array,
    default: () => []
  }
})

const lastIdx = computed(() => {
  return props.breadcrumbs.length - 1
})

const breadcrumbsWithRedirect = computed(() => {
  return props.breadcrumbs.slice(0, lastIdx.value)
})

const lastBreadcrumb = computed(() => {
  return props.breadcrumbs[lastIdx.value]
})

const hasSingleBreadcrumb = computed(() => {
  return props.breadcrumbs.length === 1
}) 

function isStaticRootBreadcrumb(index) {
  if (index !== 0) return false

  const staticRootCrumbs = new Set([ ...Object.keys(WidgetRootBreadcrumbs) ])
  const rootBreadcrumb = breadcrumbsWithRedirect.value[0]?.name?.toLowerCase() ?? ''

  return staticRootCrumbs.has(rootBreadcrumb)
}
</script>
<style scoped>
.breadcrumb {
  margin: auto .5rem;
}
.breadcrumb > * {
  padding: 0 .4rem;
}
.breadcrumb-li {
  min-width: 0;
  max-width: 14rem;
  display: flex;
  align-items: center;
}
.breadcrumb-li-slash::before {
  color: #b5b5b5;
  content: "/";
}
.breadcrumb-item {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 99;
}
</style>