export default {
  install(Vue) {
    Vue.directive('indeterminate', {
      bind(el, binding) {
        el.indeterminate = binding.value;
      },
      update(el, binding) {
        el.indeterminate = binding.value;
      }
    });
  }
};
