<template>
  <Details  v-if="!isLoading"
            style="overflow-y: scroll;"/>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import { useSearch } from '@/components/widgets/composables/useSearch'
import Details from '@/components/library/content/details/Details.vue'

const { 
  hasInfoPageOpen, 
  setHasInfoPageOpen,
  syncPartInfoWithVuex, 
  closeInfoPage } = useInfoLandingPage()
const { part } = useWidgetNavigator()
const { isLoading } = useFetch()
const { logSearchClickThrough } = useSearch()

onMounted(async() => {
  if (!hasInfoPageOpen.value) {
    setHasInfoPageOpen(true)
  }
  if (!!part.value) {
    await syncPartInfoWithVuex()
    await logSearchClickThrough()
  }
})
onUnmounted(() => {
  if (!!hasInfoPageOpen.value) {
    closeInfoPage()
  }
})
</script>