import i18n from '@/locales'
import { ActionContext, GetterTree, MutationTree } from 'vuex'
import { getOrderTemplates } from '@/controllers/admin'
import { SortDirection, TableFields, OrderForm } from '@/interfaces/admin'
import { Pagination, PaginationHeaders } from '@/interfaces/global'

interface ActiveColumnSetter extends Record<string, any> {
    activeColumn: TableFields | '',
    sortDirection: SortDirection
}

interface State extends ActiveColumnSetter {
    tableData: OrderForm[],
    pagination: Pagination
}

type Context = ActionContext<State, any>

enum Mutations {
    SET_PAGINATION = 'SET_PAGINATION',
    SET_TABLE_DATA = 'SET_TABLE_DATA'
}

const state = {
  tableData: [],
  pagination: {}
}

const getters: GetterTree<State, any> = {
  pagination: (state): Pagination => {
    return state.pagination
  }
}

const actions = {
  async executeSearch ({ commit }: Context, params: any): Promise<{}> {
    function getTypeTranslation (type: string) {
      return (type.toLowerCase() === 'order') ? 'orderFormType' : 'requestFormType'
    }
    let formattedOrders, pagination, newData
    params = {
      ...params,
      offset: params.offset ?? 0
    }
    try {
      const {
        data,
        headers
      } = await getOrderTemplates({ params })
      formattedOrders = data.map((it: any) => ({
        id: it.id,
        name: it.name,
        type: getTypeTranslation(it.type),
        default: it.default ?? false,
        assignedOrgs: it.orgCount,
        created: i18n.global.d(new Date(it.created)[Symbol.toPrimitive]('number'), 'long'),
        updated: i18n.global.d(new Date(it.updated)[Symbol.toPrimitive]('number'), 'long'),
        deleteDisabled: it.default == true || it.orgCount > 0 || it.orderCount > 0,
        tags: it.default
          ? {
            targetField: 'name',
            values: [
              {
                name: i18n.global.t('default'),
                styleClass: 'is-info'
              }
            ]
          }
          : null
      }))
      if (params.offset === 0) {
        newData = formattedOrders
      } else {
        newData = state.tableData.concat(formattedOrders)
      }

      pagination = new Pagination(headers as PaginationHeaders)
    } catch (e) {
      formattedOrders = []
    } finally {
      commit(Mutations.SET_TABLE_DATA, newData)
      commit(Mutations.SET_PAGINATION, pagination)
      return {
        orders: formattedOrders,
        pagination: pagination
      }
    }
  }
}

const mutations: MutationTree<State> = {
    [Mutations.SET_PAGINATION] (state, payload: Pagination) {
      state.pagination = payload
    },
    [Mutations.SET_TABLE_DATA] (state, payload: OrderForm[]) {
      state.tableData = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
