import { format, getHours, getMinutes, getYear, getDate } from 'date-fns'

/*
 * @dateObj  Accepts millisecond or full javascript date object
 * => returns a formatted date
 */
export default {
  methods: {
    // returns month abbr day, year => Apr 04, 1974
    dateFormatShort (dateObj) {
      return `${format(dateObj, 'MMM')} ${getDate(dateObj)}, ${getYear(dateObj)}`
    },
    //  returns month abbr day, year, time => Apr 04, 1974 1:45 AM
    dateFormatLong (dateObj) {
      return `${format(dateObj, 'MMM')} ${getDate(dateObj)}, ${getYear(dateObj)}, ${this.convertToAmPm(getHours(dateObj))}:${this.getFormattedMinutes(getMinutes(dateObj))} ${this.getAmPm(dateObj)}`
    },
    convertToAmPm(hour) {
      return hour < 12 ? hour : hour - 12
    },
    getAmPm(date) {
      const dateObj = new Date(date)
      const hours = dateObj.getHours()
      return hours > 11? 'PM' : 'AM'
    },
    getFormattedMinutes(min) {
      return String(min).length === 1 ? "0" + min : min
    },
    // This is a locale specific implementation which should be used instead
    // of the other methods
    dateFormatLocaleLong(date, locale) {
      return new Date(date).toLocaleString(locale.replace('_', '-'), {
        // weekday: 'narrow', // long, short, narrow
        day: 'numeric', // numeric, 2-digit
        year: 'numeric', // numeric, 2-digit
        month: 'short', // numeric, 2-digit, long, short, narrow
        hour: 'numeric', // numeric, 2-digit
        minute: 'numeric', // numeric, 2-digit
        // second: 'numeric', // numeric, 2-digit,
        hour12: true
    })
    }
  }
}
