<template>
  <td v-if="canEdit" class="actions" data-int="cart-cell-actions">
    <span class="icon is-medium has-text-danger" @click="remove">
      <i class="fa fa-lg fa-trash-alt" />
    </span>
    <delete-modal v-if="itemToDelete"
                  :item="itemToDelete"
                  @deleteConfirm="deleteConfirm"
                  @cancel="itemToDelete = undefined" />
  </td>
</template>

<script>
import DeleteModal from '@/components/common/DeleteModal';

export default {
  name: 'CartCellActions',
  components: {
    DeleteModal
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      itemToDelete: undefined
    };
  },
  methods: {
    remove() {
      this.itemToDelete = this.item;
    },
    deleteConfirm() {
      this.itemToDelete = undefined;
      this.$emit('remove', {item: this.item});
    }
  }
};
</script>

<style scoped>
.actions {
  width: 3rem;
}
</style>
