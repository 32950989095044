import http from '@/http'
import { PrintSetting, PrintSettingCoverStitchApplication } from '@/interfaces/admin'
import {
  useUploadProgressComputation
} from '@/components/common/upload-progress/useUploadProgressComputation'

export const getTenantPrintSettings = async () => {
  return http.get('/admin/print-settings/parent').then(res => res.data)
}

export const getTenantPrintSetting = async (id:number): Promise<PrintSetting> => {
  return http.get(`/admin/print-settings/parent/${id}`).then(res => res.data)
}

export const updateTenantPrintSetting = async (id:number, data:any):Promise<any> => {
  return http.put(`/admin/print-settings/parent/${id}`, data).then(res => res.data)
}

export const deleteTenantPrintSetting = async (id:number) => {
  return http.delete(`/admin/print-settings/parent/${id}`).then(res => res.data)
}

export const createTenantPrintSetting = async (data:any) => {
  return http.post('/admin/print-settings/parent', data).then(res => res.data)
}

export const setTenantDefaultPrintSetting = async (id:number):Promise<any> => {
  return http.put(`/admin/print-settings/parent/default/${id}`).then(res => res.data)
}

export const copyTenantPrintSetting = async (printSettingId:number, name:string):Promise<any> => {
  return http.post(`/admin/print-settings/parent/copy?printSettingId=${printSettingId}&name=${name}`).then(res => res.data)
}

export const getPrintSettingApplicableFont = async (printSettingId:number):Promise<any> => {
  return http.get(`/admin/print-settings/applicable-font/all/${printSettingId}`).then(res => res.data)
}

export const getPrintSettingWatermark = async (printSettingId:number):Promise<any> => {
  return http.get(`/admin/print-settings/watermark/${printSettingId}`).then(res => res.data)
}

export const updatePrintSettingWatermark = async (applicableFontId:number, payload:any):Promise<any> => {
  return http.put(`/admin/print-settings/watermark/${applicableFontId}`, payload).then(res => res.data)
}

export const createPrintSettingWatermark = async (applicableFontId:number, payload:any):Promise<any> => {
  return http.post(`/admin/print-settings/watermark/${applicableFontId}`, payload).then(res => res.data)
}

export const updatePrintSettingApplicableFont = async (applicableFontId:number, payload:any):Promise<any> => {
  return http.put(`/admin/print-settings/applicable-font/${applicableFontId}`, payload).then(res => res.data)
}

export const createPrintSettingApplicableFont = async (payload:any):Promise<any> => {
  return http.post('/admin/print-settings/applicable-font', payload).then(res => res.data)
}

export const getStitchesOrCovers = async (printSettingId:number):Promise<any> => {
  return http.get(`/admin/print-settings/soc/all/${printSettingId}`).then(res => res.data)
}

export const addStitchOrCover = async (payload: {
  application: PrintSettingCoverStitchApplication,
  file: Blob,
  printSettingId: number,
  uploadProgressCallback: Function
}):Promise<any> => {
  const formData = new FormData()
  formData.append('files', payload.file)

  return http.put(
    `/admin/print-settings/soc/${payload.printSettingId}?stitchOrCover=${payload.application}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent) {
        const { percentage } = useUploadProgressComputation(progressEvent)

        if (payload.uploadProgressCallback) {
          payload.uploadProgressCallback(percentage)
        }
      }
    }
  ).then(res => res.data)
}

export const deleteStitchOrCover = async (printSettingId:number, application:PrintSettingCoverStitchApplication):Promise<any> => {
  return http.delete(`/admin/print-settings/soc/${printSettingId}?stitchOrCover=${application}`).then(res => res.data)
}

export const getPrintSettingPartColumns = async (printSettingId:number): Promise<any> => {
  return http.get(`/admin/print-settings/${printSettingId}/part-columns`).then(res => res.data)
}

export const deletePrintSettingPartColumn = async (columnId:number):Promise<any> => {
  return http.delete(`/admin/print-settings/part-columns/${columnId}`).then(res => res.data)
}

export const updatePrintSettingPartColumns = async (printSettingId: number, data: any): Promise<void> => {
  await http.put(`/admin/print-settings/${printSettingId}/part-columns`, data)
}

export const deleteMarginalColumnItem = async (itemId:number):Promise<any> => {
  return http.delete(`/admin/print-settings/marginal-column-item/${itemId}`).then(res => res.data)
}

export const getPrintSettingMarginalTree = async (printSettingId:number):Promise<any> => {
  return http.get(`/admin/print-settings/marginal/all-tree/${printSettingId}`).then(res => res.data)
}

export const updatePrintSettingMarginalTree = async (printSettingId:number, payload:any):Promise<any> => {
  return http.put(`/admin/print-settings/marginal/tree/${printSettingId}`, payload).then(res => res.data)
}

export const deleteMarginalRow = async (marginalRowId:number):Promise<any> => {
  return http.delete(`/admin/print-settings/marginal-row/${marginalRowId}`).then(res => res.data)
}

export const deleteMarginalColumn = async (columnId:number):Promise<any> => {
  return http.delete(`/admin/print-settings/marginal-column/${columnId}`).then(res => res.data)
}
