<template>
  <th
    data-unit="quantity-header"
    class="quantity-header"
  >
    {{ $t('qty') }}
  </th>
</template>

<script>
export default {
  name: 'BomHeaderQuantity'
}
</script>

<style scoped>
.quantity-header {
  width: 5rem;
}
</style>
