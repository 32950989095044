export enum CursorTypesMap {
    'default' = 'default',
    'zoom-in' = 'zoom-in',
    'zoom-out' = 'zoom-out',
    'crosshair' = 'crosshair',
    'grab' = 'grab',
    'move' = 'move',
    'n-resize' = 'n-resize',
    'e-resize' = 'e-resize',
    's-resize' = 's-resize',
    'w-resize' = 'w-resize',
    'ns-resize' = 'ns-resize',
    'ew-resize' = 'ew-resize',
    'ne-resize' = 'ne-resize',
    'nw-resize' = 'nw-resize',
    'se-resize' = 'se-resize',
    'sw-resize' = 'sw-resize',
    'nwse-resize' = 'nwse-resize',
    'nesw-resize' = 'nesw-resize',
    'text' = 'text'
}
