<template>
  <th data-int="cart-header-uom">
    {{ $t('uom') }}
  </th>
</template>

<script>
export default {
  name: 'CartHeaderUom'
};
</script>

<style scoped>

</style>
