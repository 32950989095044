const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
const SET_SORT = 'SET_SORT'

const state = {
  searchTerm: '',
  sort: {
    field: 'name',
    order: 'asc'
  }
}

const actions = {
  setSearchTerm (context, term) {
    context.commit('SET_SEARCH_TERM', term)
  },
  setSort (context, sortData) {
    context.commit('SET_SORT', sortData)
  }
}

const mutations = {
  [SET_SEARCH_TERM] (state, payload) {
    state.searchTerm = payload
  },
  [SET_SORT] (state, payload) {
    Object.assign(state.sort, payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
