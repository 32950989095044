import http from '@/http';
import i18n from '@/locales';
import { notify } from "@kyvg/vue3-notification"
import { postError } from '@/helpers/notification'
const actions = {
  async reportAnIssue(context, {payload, attachment}) {
    try {
      const formData = new FormData();
      if (attachment) {
        formData.append('files', attachment, attachment.name);
      }
      if (payload.mediaId) {
        formData.append('contentType', payload.contentType);
        formData.append('mediaId', payload.mediaId);
      }
      if (payload.chapterId) {
        formData.append('chapterId', payload.chapterId);
      }
      if (payload.pageId) {
        formData.append('pageId', payload.pageId);
      }
      if (payload.partId) {
        formData.append('partId', payload.partId);
      }
      if (payload.pagePartId) {
        formData.append('pagePartId', payload.pagePartId);
      }
      formData.append('from', payload.from || '');
      formData.append('ccUser', payload.ccUser.toString());
      formData.append('subject', payload.subject || '');
      formData.append('body', payload.body || '');
      formData.append('link', window.location.href);
      await http.post('contact', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      notify({
        title: i18n.global.t('reportAnIssue'),
        text: i18n.global.t('reportAnIssueMessage'),
        type: 'success'
      });
    } catch (err) {
      postError({
        title: err.response.data,
      });
    }
  },
  async uploadAttachment(context, attachment) {
    let attachments;
    try {
      const formData = new FormData();
      formData.append('file', attachment);
      const {data} = await http.post('api/user-messages/media-feedback/attachments', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      attachments = data.files;
    } catch (err) {
      const response = err.response;
      postError({
        title: i18n.global.t('contactOwner'),
        text: response ? response.data.message : err,
      });
    }
    return attachments;
  }
};

export default {
  actions
};
