import { ActionContext, GetterTree, MutationTree } from 'vuex'
import { DraftHotpointLink, DraftBomItem, ModalHotpointLink, targetEntity, HotpointLinkType, EditTypes, draftBomStorePaths } from '@/interfaces/admin'
import { NumberToNumberMap, StringToStringMap } from '@/interfaces/global'

// Interfaces & Types
interface DraftHPLState { checkedHotpointLinks: DraftHotpointLink[] }
type Context = ActionContext<any, any>
enum MutationTypes { SET_CHECKED_HPLS = 'SET_CHECKED_HPLS' }
interface LinksAndEntity { list: DraftHotpointLink[], entityType: string }

const state: DraftHPLState = { checkedHotpointLinks: [] }

const getters: GetterTree<DraftHPLState, any> = {
  hasCheckedHotpointLinks (state): boolean {
    return state.checkedHotpointLinks.length > 0
  },
  partInDetailEditorIndex (state, getters, rootState) {
    return rootState.draftBom.partInDetailEditorIndex
  },
  partInDetailEditor (state, getters, rootState): DraftBomItem|{} {
    const { draftBomItems } = rootState.draftPage
    if (getters.partInDetailEditorIndex === -1) {
      return {}
    }
    return draftBomItems[getters.partInDetailEditorIndex]
  },
  hotpointLinks (state, getters): DraftHotpointLink[] {
    return getters.partInDetailEditor?.hotpointLinks ?? []
  },
  // Necessaary getter for HotpointLinkType enum to evaluate as string
  hplType: () => (type: HotpointLinkType): string => {
    return Object.values(HotpointLinkType).find(it => it === type) || ''
  },
  isDuplicateHPL: (state, getters) => (dto: ModalHotpointLink): boolean => {
    const { entityId, entityType } = dto
    const formattedType = entityType.charAt(0).toUpperCase() + entityType.slice(1)
    // Check proper entityId field (e.g. mediaId, pageId)
    // of HotpointLink for duplicate id
    return getters.hotpointLinks.some((hpl: DraftHotpointLink) => {
      const hplType = getters.hplType(formattedType)
      const field: string = targetEntity[hplType]
      return hpl[field] === entityId
    })
  },
  getDraftHotpointDto: (state, getters) => (dto: ModalHotpointLink, entityType: string): DraftHotpointLink => {
    const result = {
      hotpointLinkType: getters.hplType(entityType),
      displayName: dto?.identifier ?? dto.name,
      // Conditionally provide proper entityId field (e.g. mediaId, pageId) and value
      [targetEntity[entityType]]: dto.entityId
    }
    // Apply pendingId to object if there is one
    if (dto.pendingId) {
      result.pendingId = dto.pendingId
    }
    return result
  },
  idsToDelete (state): NumberToNumberMap {
    const idsToDelete: NumberToNumberMap = {}
    state.checkedHotpointLinks.forEach(it => {
      if (it.id) {
        idsToDelete[it.id] = it.id
      }
    })
    return idsToDelete
  },
  pendingIdsToDelete (state): StringToStringMap {
    const idsToDelete: StringToStringMap = {}
    state.checkedHotpointLinks.forEach(it => {
      if (it.pendingId) {
        idsToDelete[it.pendingId] = it.pendingId
      }
    })
    return idsToDelete
  }
}

const actions = {
  setCheckedHotpointLinks ({ commit }: Context, list: DraftHotpointLink[]): void {
    commit(MutationTypes.SET_CHECKED_HPLS, list)
  },
  async getDraftHotpointLinks ({ getters }: Context, payload: LinksAndEntity): Promise<DraftHotpointLink[]> {
    const { list, entityType } = payload
    const filteredHotpointLinks = list.flatMap(dto => {
      return getters.isDuplicateHPL(dto) ? [] : [getters.getDraftHotpointDto(dto, entityType)]
    })
    return getters.partInDetailEditor.hotpointLinks.concat(filteredHotpointLinks)
  },
  async deleteHotpointLinks ({ commit, dispatch, getters }: Context) {
    // Filter out hotpoint links selected for deletion
    const updatedHotpointLinks: DraftHotpointLink[] = getters.partInDetailEditor.hotpointLinks
      .filter((it: DraftHotpointLink) => {
        const idMatch = getters.idsToDelete[Number(it.id)]
        const pendingIdMatch = getters.pendingIdsToDelete[it?.pendingId ?? '']
        return !idMatch && !pendingIdMatch
      })

    // Replace hotpointLinks on draftBomItem with updated list of HPLs
    const payload = {
      index: getters.partInDetailEditorIndex,
      type: EditTypes.hotpointLinks,
      value: updatedHotpointLinks
    }
    await dispatch(draftBomStorePaths.updateDraftBomItem, payload, { root: true })

    // Zero out checked hotpoint links
    commit(MutationTypes.SET_CHECKED_HPLS, [])
  }
}

const mutations: MutationTree<DraftHPLState> = {
  [MutationTypes.SET_CHECKED_HPLS] (state, list: DraftHotpointLink[]) {
    state.checkedHotpointLinks = list
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
