<template>
  <th   class="item-cell"
        :class="{'has-text-info': bomItem.highlight}"
        :style="{'padding-left': bomItem.indention + 'rem'}"
        @dblclick.stop="open">
    <div class="d-flex">
      <bulma-icon v-show="bomItem.children.length"
                  :iconClass="bomItem.isCollapsed
                    ? 'fa fa-caret-right'
                    : 'fa fa-caret-down'"
                  spanClass="is-small hover"
                  style="min-width: 1rem; align-self: center;"/>
      <span   v-text="itemLabel"
              :class="{ 'ml-4': !bomItem.children.length }"/>
      <bulma-icon v-if="bomItem.hasHotpointLink"
                  iconClass="fa fa-link"
                  class="pointer-on-hover"
                  @click="$emit('openHotpointPopover', false)"/>
    </div>
    <div v-if="bomItem.showHotpointLinkPopover"
         class="hpl-popover"
         :class="{ 'is-bottom': bomItem.popoverPositionBottom, 'is-widget': isWidget }">
      <bom-links-popover :callout="hotpoint"
                         :selected="isSelected ? bomItem : null"
                         :is-bottom="bomItem.popoverPositionBottom"
                         @close="$emit('openHotpointPopover', true)"
                         @openHotpointLink="val => $emit('openHotpointLink', val)"/>
    </div>
  </th>
</template>

<script>
import BomLinksPopover from "@/components/library/content/asset/viewer/illustrationViewer/bom/BomLinksPopover.vue"
import BulmaIcon from "@/components/common/BulmaIcon.vue"

export default {
  name: 'BomCellItem',
  props: {
    bomItem: {
      type: Object,
      required: true
    },
    hotpoint: {
      type: Object
    },
    isSelected: Boolean,
    isWidget: Boolean,
    itemLabel: String,
    showPreview: Boolean
  },
  components: {
    BomLinksPopover,
    BulmaIcon
  },
  methods: {
    open () {
      this.$emit('open', {
        content: this.bomItem,
        route: ''
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.item-cell {
  border-left: none !important;
  border-right: none !important;
}
th {
  position: relative;
}
.hover:hover {
  cursor: pointer;
}
@media only screen and (min-width: 1024px) {
  .hpl-popover:not(.is-widget) {
    display: none;
  }
}
</style>
