import { h as createElement, useSlots } from 'vue'
import { isVueComponent } from './helpers'

export default {
  name: 'BSlotComponent',
  props: {
    component: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: 'default'
    },
    scoped: {
      type: Boolean
    },
    props: {
      type: Object
    },
    tag: {
      type: String,
      default: 'div'
    },
    event: {
      type: String,
      default: 'hook:updated'
    }
  },
  methods: {
    refresh() {
      this.$forceUpdate()
    }
  },
  created() {
    if (isVueComponent(this.component)) {
      this.component.$on(this.event, this.refresh)
    }
  },
  beforeUnmount() {
    if (isVueComponent(this.component)) {
      this.component.$off(this.event, this.refresh)
    }
  },
  render() {
    if (!this.component.$slots[this.name] && !this.component.$scopedSlots[this.name]) return null
    const props = this.props
    return createElement(this.tag, {
        row: (props.row? props.row : 'tester') },
        this.scoped
          ? this.component.$scopedSlots[this.name](this.props)
          : this.component.$slots[this.name][0])
  }
}
