import { v4 as uuidv4 } from 'uuid'
import { DraftPage } from '@/interfaces/admin/draftPage'
import { DraftTagValue } from '@/interfaces/admin'
import { NumberToStringMap } from '@/interfaces/global'

interface IndentRefs {
  [key: number]: number
}

export function formatBomJson (draftPage: DraftPage, hotpoints?: Array<string>, tagIdToNameMap: NumberToStringMap = {}): DraftPage {
  const partsList = draftPage.draftBomItems
  const extendedParts: DraftPage|any = []

  // Stores key-value pairs of indentation level - pointers/index location for parts
  // with child lists currently being enqueued/concatenated/built
  // as the for loop iterates over the parts list.
  // indentRefs allows an O(1) look up to all hierarchical levels
  // to assign a part to its correct parent and vice versa
  const indentRefs:IndentRefs = { 0: -1 }

  for (let idx = 0; idx < partsList.length; idx++) {

    const part = partsList[idx]
    const previousPart = partsList[idx - 1]
    const isPartAtRoot = part.indentation === 0
    const refKey = uuidv4()

    /* Set or update part indentation ref as appropriate
      * I.e., if there is:
      * 1) No previous part, OR
      * 2) No indent ref previously assigned for current indentation value, OR
      * 3) The previous part's indentation indicates it is a parent or sibling of the current part, OR
      * 4) a part is at root level -- indentation of 0, OR
      * 5) the previous part is a descendant of one of the part's siblings && an indent ref
      *     has been previously assinged for the part's indent level
      * then we can correctly infer that all children have been pushed to the children list
      * of the current indent Ref pointer assigned
      * Thus, we need to update the indent Ref pointer assignment for the current part's identation value
      * to be the current part's index/reference pointer
      */
    const prevPartIsNephew = !!previousPart && (previousPart.indentation > part.indentation)
    const hasIndentRef = indentRefs[part.indentation] !== undefined
    const prevPartIsNephewAndIndentRefSet = prevPartIsNephew && hasIndentRef
    const prevPartIsParentOrSibling = !!previousPart && (previousPart.indentation <= part.indentation)
    if (!previousPart || !indentRefs[part.indentation] || prevPartIsParentOrSibling || isPartAtRoot || prevPartIsNephewAndIndentRefSet) {
      indentRefs[part.indentation] = idx
    }

    let parent
    // Assign parent for each part
    if (isPartAtRoot) {
      parent = null
    } else {
      parent = extendedParts[indentRefs[part.indentation - 1]].refKey
    }

    if (parent) {
      extendedParts[indentRefs[part.indentation - 1]].children.push(refKey)
    }
    const result = {
      ...part,
      parent,
      children: [],
      refKey,
      visible: true,
      expanded: true,
      deleted: partsList[idx].deleted || false,
      hotpointCount: hotpoints? hotpoints.filter(point => point === part.item).length : 0
    }
    if (result.partNumberHasBeenSuperseded) {
      // Use tag Id to Name map to confirm tagNameId of SupersededPartNumber
      const isSupersededTag = (tag: DraftTagValue) => tagIdToNameMap[tag.tagNameId] === "SupersededPartNumber"
      // @ts-ignore
      result.supersededPartNumber = part.partTagValues.findLast(isSupersededTag)?.value
    }
    extendedParts.push(result)
  }
  draftPage.draftBomItems = extendedParts
  return draftPage
}
