<template>
  <cart-table :items="items"
              :isLoaded="isLoaded"
              :columns="columns"
              :hasPricing="hasPricing"
              :selected="selected"
              :totalRetailPrice="totalRetailPrice"
              :totalWholesalePrice="totalWholesalePrice"
              :totalDiscountedPrice="totalDiscountedPrice"
              @select="select"
              @updateQuantity="updateQuantity"
              @removeFromCart="removeFromCart">
    <slot />
  </cart-table>
</template>

<script>
import { mapState, mapActions } from "vuex"
import CartTable from "./CartTable"
export default {
  name: "CartTableWrapper",
  components: { CartTable },
  computed: {
    ...mapState({
      items: ({ cart }) => cart.items,
      isLoaded: ({ cart }) => cart.isLoaded,
      columns: ({ cart }) => cart.columns.items,
      hasPricing: ({ cart }) => cart.columns.hasPricing,
      selected: ({ cart }) => cart.selected,
      totalRetailPrice: ({ cart }) => cart.totalRetailPrice,
      totalWholesalePrice: ({ cart }) => cart.totalWholesalePrice,
      totalDiscountedPrice: ({ cart }) => cart.totalDiscountedPrice
    })
  },
  methods: {
    ...mapActions({
      select: 'cart/selectCartItem',
      updateQuantity: 'cart/updateQuantity',
      removeFromCart: 'cart/removeFromCart'
    })
  }
}
</script>