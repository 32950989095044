<template>
  <th
    data-unit="name-header"
    class="name-header"
  >
    {{ $t('name') }}
  </th>
</template>

<script>
export default {
  name: 'BomHeaderName'
}
</script>
