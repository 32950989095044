import { WidgetTypes, WidgetEvents,
  WidgetEventDetailTypes } from "@/interfaces/global"
import { store } from '@/store'

export class WidgetEvent {
  action: string
  item: {
    detail: { 
      type: WidgetEventDetailTypes,
      message: string, 
      widgetType: WidgetTypes }
    bubbles: boolean
    cancelable: boolean
    composed: boolean
  }

  constructor({ action, message, 
    widgetType = store.getters['widgets/widgetType'] }: { 
      action: WidgetEvents, 
      message: string, 
      widgetType?: WidgetTypes }) {
    this.action = action
    this.item = {
      detail: { 
        type: (action === WidgetEvents.error) 
          ? WidgetEventDetailTypes.Error
          : WidgetEventDetailTypes.Success,
        message, 
        widgetType },
      bubbles: true,
      cancelable: true,
      composed: false
    }
  }
}