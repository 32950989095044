export enum FontAwesomeIcons {
    'history' = 'history',
    'pen' = 'pen',
    'repeat' = 'repeat',
    'search' = 'search',
    'search-plus' = 'search-plus',
    'search-minus' = 'search-minus',
    'shapes' = 'shapes',
    'sync-alt' = 'sync-alt',
    'text-size' = 'text-size',
    'text-width' = 'text-width',
    'trash' = 'trash',
    'undo' = 'undo',
    'auto-hotpoint' = 'fa-solid fa-bolt-auto'
}
