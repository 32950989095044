import http from '@/http'
import { APIKeyDTO, APIKeyDTOV2 } from '@/interfaces/admin'

export const postAPIKey = async function (dto: APIKeyDTO): Promise<APIKeyDTO> {
  const { data } = await http.post('/api-key', dto)
  return data
}

export const postAPIKeyForSuperAdmin = async function (dto: APIKeyDTOV2): Promise<APIKeyDTOV2> {
  const { data } = await http.post('/super-admin-api-key', dto)
  return data
}

export const getAPIKeys = async function (): Promise<Array<APIKeyDTO>> {
  const { data } = await http.get('/api-key')
  return data
}

export const getAPIKeysForSuperAdmin = async function (tenantId: number): Promise<Array<APIKeyDTOV2>> {
  const params = {
    tenantId: tenantId
  }
  const { data } = await http.get('/super-admin-api-key', { params })
  return data
}

export const refreshAPIKey = async function (id: number): Promise<APIKeyDTO> {
  const { data } = await http.put(`/api-key/${id}`)
  return data
}

export const refreshAPIKeyForSuperAdmin = async function (id: number, tenantId: number): Promise<APIKeyDTOV2> {
  const { data } = await http.put(`/super-admin-api-key/${id}?tenantId=${tenantId}`)
  return data
}

export const deleteAPIKey = async function (id: number): Promise<APIKeyDTO> {
  const { data } = await http.delete(`/api-key/${id}`)
  return data
}

export const deleteAPIKeyForSuperAdmin = async function (id: number, tenantId: number): Promise<APIKeyDTOV2> {
  const params = {
    tenantId: tenantId
  }
  const { data } = await http.delete(`/super-admin-api-key/${id}`, { params })
  return data
}
