import http from '@/http'
import router from '@/router'

const NOTIFICATIONS_RECEIVED = 'NOTIFICATIONS_RECEIVED'
const NOTIFICATIONS_COUNTS_RECEIVED = 'NOTIFICATIONS_COUNTS_RECEIVED'
const NOTIFICATIONS_UPDATE_SEARCH_OFFSET = 'NOTIFICATIONS_UPDATE_SEARCH_OFFSET'
const NOTIFICATIONS_UPDATE_SEARCH_LIMIT = 'NOTIFICATIONS_UPDATE_SEARCH_LIMIT'

const state = {
  requiredReadingCount: 0,
  notViewedCount: 0,
  items: [],
  offset: 0,
  limit: 10,
  total: 0
}

const getters = {
  requiredReading: (state) => state.items.filter((item) => item.required && !item.viewed),
  notViewed: (state) => state.items.filter((item) => !item.required && !item.viewed)
}

const actions = {
  async getNotifications ({ commit, state }, unread) {
    try {
      const params = {
        limit: state.limit,
        offset: state.offset * state.limit,
        unread: unread || undefined
      }

      const { headers, data } = await http.get('bulletins', { params })
      commit(NOTIFICATIONS_RECEIVED, { notifications: data, meta: headers })
    } catch (err) {
      commit(NOTIFICATIONS_RECEIVED, { notifications: [] })
    }
  },
  async getRequiredReadingWithReturn () {
    let requiredReading = []
    try {
      const params = {
        limit: 3,
        offset: 0,
        required: true,
        unread: true
      }

      const { data } = await http.get('bulletins', { params })
      requiredReading = data
    } catch (err) {
      // na
    }
    return requiredReading
  },
  async getNotificationCounts ({ commit }) {
    try {
      const [requiredReading, notViewed] = await Promise.all([
        http.get('bulletins', {
          params: {
            limit: 0,
            offset: 0,
            required: true,
            unread: true
          }
        }),
        http.get('bulletins', {
          params: {
            limit: 0,
            offset: 0,
            required: false,
            unread: true
          }
        })
      ])

      const requiredReadingCount = parseInt(requiredReading.headers['x-count'], 10)
      const notViewedCount = parseInt(notViewed.headers['x-count'], 10)

      commit(NOTIFICATIONS_COUNTS_RECEIVED, { requiredReadingCount, notViewedCount })
    } catch (err) {
      // na
    }
  },
  setNotificationsPage ({ commit, dispatch }, page) {
    commit(NOTIFICATIONS_UPDATE_SEARCH_OFFSET, {
      offset: Math.max(page, 0)
    })
    dispatch('getNotifications')
  },
  setNotificationsResultsPerPage ({ commit, dispatch }, limit) {
    commit(NOTIFICATIONS_UPDATE_SEARCH_LIMIT, { limit })
    dispatch('getNotifications')
  },
  navigateToNotification (context, id) {
    router.push({ name: 'Notification', params: { id: id.toString() } })
  }
}

const mutations = {
  [NOTIFICATIONS_RECEIVED] (state, { notifications, meta }) {
    state.items = notifications
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0
  },
  [NOTIFICATIONS_COUNTS_RECEIVED] (state, { requiredReadingCount, notViewedCount }) {
    state.requiredReadingCount = requiredReadingCount || 0
    state.notViewedCount = notViewedCount || 0
  },
  [NOTIFICATIONS_UPDATE_SEARCH_OFFSET] (state, { offset }) {
    state.offset = offset
  },
  [NOTIFICATIONS_UPDATE_SEARCH_LIMIT] (state, { limit }) {
    state.limit = limit
    state.offset = 0
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
