<template>
  <section class="hero background-image is-fullheight is-dark">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column">
          <div class="box">
            <figure class="avatar">
              <div class="logo" />
            </figure>
            <span
              v-if="status"
              class="is-size-3"
            >{{ status }}</span><br>
            <br>
            <slot name="title">
              <span class="is-size-3"
                    data-unit="error-page-title">
                {{ $t('errorPageTitle') }}
              </span><br>
            </slot>
            <slot name="message">
              <span class="is-size-5"
                    data-unit="error-page-message">
                {{ $t('errorPageMessage') }}
              </span><br><br>
              <router-link  to="/"
                            exact
                            class="button is-primary">
                {{ $t('goToHome') }}
              </router-link>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Error',
  props: {
    loadTheme: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    status () {
      return this.$route.query.status || ''
    }
  },
  async mounted () {
    if (!this.loadTheme) return
    // to force a reload of theme data if this occured because of the sso session replacement
    await this.$store.dispatch('theme/getTheme')
  }
}
</script>

<style scoped>
.avatar {
  margin: auto;
  width: 16rem;
}
.logo {
  min-height: 100px;
}
.background-image {
  flex-shrink: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
