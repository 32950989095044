import { SchedulerJobState, DraftPage } from '@/interfaces/admin'

export function draftPageHasJobScheduler (draft: DraftPage|null): boolean {
  if (!draft) {
    return false
  }
  const hasJobId = Object.prototype.hasOwnProperty.call(draft, 'schedulerJobId')
  const hasJobState = Object.prototype.hasOwnProperty.call(draft, 'schedulerJobState')
  const jobIsRunning = draft.schedulerJobState === SchedulerJobState.INPROCESS || draft.schedulerJobState === SchedulerJobState.SUBMITTED
  return hasJobId && hasJobState && jobIsRunning
}
