import mutations from '../../mutations'
import cssVars from 'css-vars-ponyfill'
import { bulmaColorTools } from './bulma-color-tools'
import { getColorValue } from './getColorValue'
import { getTheme } from '@/controllers/global'

export default {
  namespaced: true,
  state: {
    error: false,
    loading: false,
    data: null
  },
  mutations,
  actions: {
    async getTheme (context) {
      context.commit('DATA_LOADING', true)

      try {
        let themeUrl = 'styles/user'

        if (!context.rootState.auth.authenticated) {
          const getParameterByName = (param) => {
            try {
              const key = param.replace(/[\[\]]/g, '\\$&'); // eslint-disable-line
              const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)', 'gi'); // eslint-disable-line
              const results = regex.exec(window.location.href)

              if (!results || !results[2]) {
                return ''
              }
              return decodeURIComponent(results[2].replace(/\+/g, ' '))
            } catch (e) {
              return ''
            }
          }

          const getSubdomainedTenant = () => {
            if (
              window.location.host.endsWith(".documoto.com") ||
              window.location.host.endsWith(".documoto.com.localhost")
            ) {
              return window.location.host.substring(0, window.location.host.indexOf("."))
            }

            return null
          }

          const ok = getParameterByName('ok')
          const tk = getSubdomainedTenant() ||  getParameterByName('tk') || localStorage.getItem('tenantKey') || null

          if (ok && tk) {
            themeUrl = `styles/user?ok=${ok}&tk=${tk}`
          } else if (ok) {
            themeUrl = `styles/user?ok=${ok}`
          } else if (tk) {
            themeUrl = `styles/user?tk=${tk}`
          }
        }

        const result = await getTheme(themeUrl)
        if (result) {
          context.commit('DATA_SUCCESS', result)
          applyTheme(result)
        }
      } catch (error) {
        console.log('error :>> ', error)
        context.commit('DATA_ERROR', error)
      }
    },
    async checkThemeAndUpdate({ state, getters }, providedTheme) {
      // Wrap in promise to prevent briefly rendering tenent/org theme
      await new Promise(resolve => {
        const checkTheme = setInterval(overrideTheme, 250)

        function overrideTheme() {
          try {
            const themeLoaded = getters.themeLoaded()
            if (!themeLoaded) return
            const theme = Object.assign({}, state.data)
            for (let key in providedTheme) {
              if (!theme[key]) continue
              theme[key] = providedTheme[key]
            }
            applyTheme(theme)
          } catch(e) {
            console.error(e)
          } finally {
            resolve(clearInterval(checkTheme))
          }
        }
      })
    }
  },
  getters: {
    themeLoaded: (state) => () => {
      return state.data !== null && !state.loading
    },
    getTheme: (state) => {
      return state.data
    }
  }
}

function applyTheme(result) {
  const primary = getColorValue(result.brand_primary)
  const info = getColorValue(result.hotpoint_highlighted)
  const links = getColorValue(result.links)

  /**
   * default Bulma theme values
   *
   * @reference https://bulma.io/documentation/customize/variables/
   *
   */

  const success = '#48C774'
  const warning = '#FFDD57'
  const danger = '#F14668'
  const greyDark = '#4a4a4a'
  const greyDarker = '#363636'
  const black = '#0A0A0A'
  const grey = '#7A7A7A'

  const themeValues = [
    {
      name: 'primary',
      value: primary
    },
    {
      name: 'info',
      value: info
    },
    {
      name: 'success',
      value: success
    },
    {
      name: 'warning',
      value: warning
    },
    {
      name: 'danger',
      value: danger
    },
    {
      name: 'links',
      value: links
    },
    {
      name: 'grey-dark',
      value: greyDark
    },
    {
      name: 'grey-darker',
      value: greyDarker
    },
    {
      name: 'grey',
      value: grey
    },
    {
      name: 'black',
      value: black
    }
  ]

  let themeConfig = ':root {'

  function addVariables (name, value) {
    const lightColor = bulmaColorTools['light-color'](value)
    const colorInvert = bulmaColorTools['color-invert'](value)

    // base
    themeConfig += `--${name}: ${value};`

    // color invert
    themeConfig += `--${name}--color-invert: ${colorInvert};`

    // light color
    themeConfig += `--${name}--light-color: ${lightColor};`

    // color invert darken
    themeConfig += `--${name}--color-invert--darken: ${bulmaColorTools.darken(colorInvert, 2.5)};`

    // light color darken
    themeConfig += `--${name}--light-color--darken: ${bulmaColorTools.darken(lightColor, 2.5)};`

    // dark color
    themeConfig += `--${name}--dark-color: ${bulmaColorTools['dark-color'](value)};`

    // darken color for hover
    themeConfig += `--${name}--darken: ${bulmaColorTools.darken(value, 2.5)};`

    // datepicker
    themeConfig += `--${name}--150--lighten: ${bulmaColorTools.lighten(value, 150)};`

    // focus shadow
    themeConfig += `--${name}--25--rgba: ${bulmaColorTools.rgba(value, 25)};`
    themeConfig += `--${name}--50--rgba: ${bulmaColorTools.rgba(value, 25)};`

    // shades
    themeConfig += `--${name}--90--rgba: ${bulmaColorTools.rgba(value, 90)};`
    themeConfig += `--${name}--80--rgba: ${bulmaColorTools.rgba(value, 80)};`
    themeConfig += `--${name}--70--rgba: ${bulmaColorTools.rgba(value, 70)};`

    // invert shades
    themeConfig += `--${name}--color-invert--90--rgba: ${bulmaColorTools.rgba(colorInvert, 90)};`
    themeConfig += `--${name}--color-invert--70--rgba: ${bulmaColorTools.rgba(colorInvert, 70)};`
  }

  themeValues.forEach(({ name, value }) => addVariables(name, value))

  for (const item in result) {
    if (typeof result[item] === 'string') {
      if (result[item].charAt(0) === '/' || result[item].includes('http')) {
        themeConfig += `--${item}: url('${result[item]}');`
      }
    }
  }

  // manually add a few values here
  themeConfig += `--grey-darker--800--lighten: ${bulmaColorTools.lighten(greyDarker, 800)};`
  themeConfig += '--family-sans-serif: \'Source Sans Pro\', Helvetica, sans-serif;'
  themeConfig += '--columnGap: 2rem;'
  themeConfig += `--loginUrl: url('${result.loginUrl}');`
  themeConfig += `--ssoDisplay: ${result.ssoDisplay};`

  themeConfig += `--hotpoint: ${getColorValue(result.hotpoint)};`
  themeConfig += `--hotpoint-highlighted: ${getColorValue(result.hotpoint_highlighted)};`

  // add pspdf values
  themeConfig += `--PSPDFKit-ToolbarButton-isActive-background: ${primary};`
  themeConfig += `--PSPDFKit-Toolbar-dropdownButton-isFocused-background: ${primary};`
  themeConfig += `--PSPDFKit-Toolbar-dropdownButton-isSelected-background: ${primary};`
  themeConfig += `--PSPDFKit-PrintLoadingIndicator-progressBar-background: ${primary};`
  themeConfig += `--PSPDFKit-ThumbnailsSidebar-gridView-thumbnail-isSelected-boxShadow: ${primary};`
  themeConfig += `--PSPDFKit-ThumbnailsSidebar-gridView-label-isSelected-background: ${primary};`
  themeConfig += `--PSPDFKit-DocumentOutline-icon-isHovered-color: ${primary};`

  themeConfig += '}'

  document.getElementById('themeConfig').innerHTML = themeConfig

  /**
  * Generate fallback CSS for IE and modern browsers.
  * Specifically for Buefy checkbox style + emit event to enable,
  * single check event for multiselect b-table. Revert onlyLegacy to
  * default when possible.
  *
  * Buefy checkbox scss uses variable $primary which gets converted with bulma-colors.
  * SVGs as background images do not support css variables in inline style.
  *
  */
  cssVars({
    include: 'style',
    onlyLegacy: false
  })
}
