const GROUP = 'group'
const TENANT = 'tenant'
const ORGANIZATION = 'org'

function expandAccessPrivSchema (obj) {
  return Object.keys(obj).map((jsName) => ({
    jsName,
    applicableTo: obj[jsName] instanceof Array ? obj[jsName] : [obj[jsName]],
    name: jsName.replace(/([A-Z])/g, '_$1').toUpperCase()
  })).reduce((acc, el) => ({
    ...acc,
    [el.jsName]: el
  }), {})
}

const accessPrivileges = expandAccessPrivSchema({
  publish: GROUP,
  administration: GROUP,
  viewRelatedItems: GROUP,
  relateMedia: GROUP,
  shoppingCartEnabled: [TENANT, ORGANIZATION],
  shoppingCartSendToErpEnabled: [TENANT, ORGANIZATION],
  partsListFetchPartInfoFromErpEnabled: [TENANT, ORGANIZATION],
  shoppingCartDisabled: GROUP,
  reporting: GROUP,
  partOrderSuggestionsEnabled: TENANT,
  disableUserPreferences: GROUP,
  displayRetailPrices: [TENANT, ORGANIZATION],
  displayWholesalePrices: [TENANT, ORGANIZATION],
  displayDiscountedPrices: [TENANT, ORGANIZATION],
  quickPublish: GROUP,
  rfqTemplateEnabled: TENANT, // Rocket library used this; hence, defer to html*
  rfqTemplateDisabled: ORGANIZATION, // Rocket library used this; hence, defer to html*
  poTemplateEnabled: TENANT, // Rocket library used this; hence, defer to html*
  poTemplateDisabled: ORGANIZATION, // Rocket library used this; hence, defer to html*
  offlineContentEnabled: [TENANT, GROUP],
  tenantAdministration: GROUP,
  viewHelp: TENANT,
  displayPricesInBom: [TENANT, ORGANIZATION],
  displayPricesInCart: [TENANT, ORGANIZATION],
  exportToLinkone: TENANT,
  shoppingCartAddToCartPricingEnabled: TENANT,
  shoppingCartAddToCartPricingDisabled: ORGANIZATION,
  exporter: [TENANT, GROUP],
  exportToSmartequip: TENANT,
  addToCartErpEnabled: TENANT,
  autoNameShoppingCarts: TENANT,
  saveShoppingCarts: TENANT,
  viewComments: GROUP,
  editAllComments: GROUP,
  addAndEditUsersComments: GROUP,
  viewAttachments: GROUP,
  editAllAttachments: GROUP,
  addAndEditUsersAttachments: GROUP,
  shoppingCartErpAvailabilityEnabled: TENANT,
  shoppingCartErpAvailabilityDisabled: ORGANIZATION,
  shoppingCartErpEtaEnabled: TENANT,
  shoppingCartErpEtaDisabled: ORGANIZATION,
  disablePrinting: GROUP,
  disableBookPrinting: GROUP,
  recentlyAdded: TENANT,
  recentlyVisited: TENANT,
  bulletins: TENANT,
  shoppingCartImportFromXlsEnabled: TENANT,
  shoppingCartImportFromXlsDisabled: ORGANIZATION,
  shoppingCartAddToCartPromptForQtyEnabled: TENANT,
  shoppingCartAddToCartPromptForQtyDisabled: ORGANIZATION,
  addToCartErpDisabled: ORGANIZATION,
  addEditPublicShoppingCarts: GROUP,
  publicShoppingCartsEnabled: TENANT,
  publicShoppingCartsDisabled: ORGANIZATION,
  hidePriceValuesEnabled: TENANT,
  hidePriceValuesDisabled: ORGANIZATION,
  orderAdministration: GROUP,
  orderApprover: GROUP,
  ecommerceOrderManagementEnabled: TENANT,
  ecommerceOrderManagementDisabled: ORGANIZATION,
  addressesFetchFromErpEnabled: [TENANT, ORGANIZATION],
  orderViewer: GROUP,
  htmlPoTemplateEnabled: TENANT,
  htmlPoTemplateDisabled: ORGANIZATION,
  htmlRfqTemplateEnabled: TENANT,
  htmlRfqTemplateDisabled: ORGANIZATION,
  htmlUiEnabled: GROUP,
  exportCartToXlsxDisabled: ORGANIZATION,
  exportCartToXlsxEnabled: TENANT,
  shoppingCartPartLocationDataEnabled: TENANT,
  quickAddInCartEnabled: TENANT,
  apiKeyAdmin: TENANT,
  widgetKeyAdmin: TENANT,
  enableSmartequipApiIntegration: TENANT,
  enable_3dFeatures: TENANT
})

const lookup = Object.keys(accessPrivileges).reduce((acc, el) => ({
  ...acc,
  [accessPrivileges[el].name]: el
}), {})

const baseMethods = {
  getApplicablePrivileges (target) {
    return Object.keys(this.$options.accessPrivileges).filter((priv) => {
      const rule = this.$options.accessPrivileges[priv].applicableTo
      return rule.includes(target)
    })
  },
  validatePrivilegesList (privs, target) {
    const validTargets = this.getApplicablePrivileges(target)
    if (!Object.keys(privs).every((priv) => validTargets.includes(priv))) {
      throw new Error('Invalid priv description')
    }
  }
}

export function toServer (privs) {
  const payload = {}

  for (const key in privs) {
    if (Object.prototype.hasOwnProperty.call(privs, key)) {
      const newKey = accessPrivileges[key].name
      payload[newKey] = privs[key]
    }
  }

  return payload
}

export function fromServer (privs) {
  return privs.map((priv) => lookup[priv])
}

export default {
  tenantAccessPrivs: {
    accessPrivileges,
    methods: {
      ...baseMethods,
      /*
       * Note: There are different sets of privileges within the same tenant or org property table.
       * The endpoint will update the privileges declared.
       *    true -> set
       *    false -> delete
       */
      async saveTenantAccessPrivileges (tk, privs) {
        const url = `/tenants/${tk}/privileges`
        this.validatePrivilegesList(privs, TENANT)
        const payload = toServer(privs)
        return await this.$rest.put(url, payload)
      },
      async loadTenantAccessPrivileges (tk) {
        const url = `/tenants/${tk}/privileges`
        const { data } = await this.$rest.get(url)
        return fromServer(data)
      }
    }
  },
  organizationAccessPrivs: {
    accessPrivileges,
    methods: {
      ...baseMethods,
      async saveOrganizationAccessPrivileges (orgId, privs, opts = {}) {
        const url = `/organizations/${orgId}/privileges`

        if (opts.log) {
          console.log('saveOrgPrivs.org', orgId)
          console.log('saveOrgPrivs.privs', privs)
        }

        this.validatePrivilegesList(privs, ORGANIZATION)

        const payload = toServer(privs)

        if (opts.log) {
          console.log('saveOrgPrivs.payload', payload)
        }

        return await this.$rest.put(url, payload)
      },
      async loadOrganizationAccessPrivileges (orgId) {
        const url = `/organizations/${orgId}/privileges`
        const { data } = await this.$rest.get(url)
        return fromServer(data)
      }
    }
  }
}
