/**
 * getColorValue
 *
 * Transforms the color received from the API.
 * If we get a SASS variable, return the correct hex
 * value. If not, convert to hex.
 *
 * @reference https://bulma.io/documentation/customize/variables/
 *
 * @param {string} color sass variable
 * @return {string} color value
 */
const color = require('color');

export function getColorValue(input) {
  let value = '';

  if (input.charAt(0) === '#') {
    return input;
  } else if (input.charAt(0) !== '$') {
    value = color(input).hex();
  } else {
    switch (input) {
      case '$black':
        value = '#0A0A0A';
        break;
      case '$black-bis':
        value = '#121212';
        break;
      case '$black-ter':
        value = '#242424';
        break;
      case '$grey-darker':
        value = '#363636';
        break;
      case '$grey-dark':
        value = '#4A4A4A';
        break;
      case '$grey':
        value = '#7A7A7A';
        break;
      case '$grey-light':
        value = '#B5B5B5';
        break;
      case '$grey-lighter':
        value = '#DBDBDB';
        break;
      case '$grey-lightest':
        value = '#EDEDED';
        break;
      case '$white-ter':
        value = '#F5F5F5';
        break;
      case '$white-bis':
        value = '#FAFAFA';
        break;
      case '$white':
        value = '#FFFFFF';
        break;
      case '$orange':
        value = '#FF470F';
        break;
      case '$yellow':
        value = '#FFDD57';
        break;
      case '$green':
        value = '#48C774';
        break;
      case '$turquoise':
        value = '#00D1B2';
        break;
      case '$cyan':
        value = '#3298DC';
        break;
      case '$blue':
        value = '#3273DC';
        break;
      case '$purple':
        value = '#B86BFF';
        break;
      case '$red':
        value = '#F14668';
        break;
      case '$primary':
        value = '#00D1B2';
        break;
      case '$info':
        value = '#3298DC';
        break;
      case '$success':
        value = '#48C774';
        break;
      case '$warning':
        value = '#FFDD57';
        break;
      case '$danger':
        value = '#F14668';
        break;
      case '$light':
        value = '#F5F5F5';
        break;
      case '$dark':
        value = '#363636';
        break;
      case '$link':
        value = '#3273DC';
        break;
      case '$link-invert':
        value = '#FFFFFF';
        break;
      case '$code':
        value = '#F14668';
        break;
      default:
        value = '';
    }
  }

  /**
   * The above switch does not include all derived
   * Bulma SCSS variables. Double check with team to see
   * if this should cover what we have in the DB. If
   * one gets through that we're not covering above,
   * log it to the console and style it nasty red
   * so we can identify gaps and add it in right away.
   *
   */
  if (!value) {
    console.log('\x1b[41m%s\x1b[0m', ' ERROR ', 'Variable -> Hex Conversion failed. Provided Variable: ', input, '\n');

    return 'red';
  }

  return value;
}
