import { toRaw, computed } from "vue"
import { useStore } from "vuex"
import { useWidgetNavigator } from "./useWidgetNavigator"
import { getPart } from "@/controllers/library"

const { media, chapter, page, part } = useWidgetNavigator()

export function useWidgetAddToCart() {
  const store = useStore()
  const targetUri = computed(() => store.getters['widgets/targetUri'])
  const hasCartPromptForQtyEnabled = computed(() => {
    return store.getters['user/hasCartPromptForQtyEnabled']
  })
  const hasWidgetCart = computed(() => store.getters['widgets/isWidgetCartEnabled'])

  function emitWidgetAddToCartEvent({ content, qty, hasCartPromptForQtyEnabled, addToCartQtyBehavior }) {
    if (!hasWidgetCart.value) return

    const contentDto = toRaw(content)

    const item = {
      widgetType: store.getters['widgets/widgetType'],
      partId: contentDto.id || contentDto.entityId,
      partDescription: contentDto.description,
      partName: contentDto.name,
      partNumber: contentDto.partNumber || contentDto.identifier,
      quantity: qty ??
        getQtyFromContent(contentDto, hasCartPromptForQtyEnabled, addToCartQtyBehavior),
      supplierKey: contentDto.supplierKey,
      uom: contentDto.uom
    }

    if (Object.hasOwn(contentDto, 'itemText')) {
      item.itemText = contentDto.itemText
    }
    if (!!page.value?.id) {
      item.pageId = page.value.id
      item.pageHashkey = page.value.hashKey
      item.pageName = page.value.name
      item.pagePartId = contentDto.pagePartId ?? part.value?.pagePartId
    }
    if (!!chapter.value?.id) {
      item.chapterId = chapter.value.id
      item.chapterName = chapter.value.name
    }
    if (!!media.value?.id) {
      item.mediaId = media.value.id
      item.mediaIdentifier = media.value.identifier
      item.mediaName = media.value.name
    }
    window.parent.postMessage(
      { item, action: "addToCart" },
      targetUri.value)
  }

  function getQtyFromContent(content, hasCartPromptForQtyEnabled, addToCartQtyBehavior) {
    const map = new Map([
      ['qtyZero', hasCartPromptForQtyEnabled ? 0 : 1],
      ['qtyFromBom', getQtyFromBom(content.quantity ?? '')]
    ])
    return map.get(addToCartQtyBehavior) ?? 1
  }

  function getQtyFromBom(quantityString) {
    const number = parseInt(quantityString, 10)
    return isNaN(number) ? 1 : number
  }

  async function handleWidgetAddToCart({ part, qty }) {
    const partDto = await getPart(part.id)
    emitWidgetAddToCartEvent({
      content: { ...partDto },
      qty: qty,
      hasCartPromptForQtyEnabled: hasCartPromptForQtyEnabled.value
    })
  }

  return { handleWidgetAddToCart, emitWidgetAddToCartEvent, hasWidgetCart  }
}
