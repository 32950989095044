<template>
  <aside>
    <div class="filter-controls">
      <filters-add
        :filters="filters"
        :selected-filters="selectedFilters"
        @selectFilters="setSelectedFilters"
      />
      <filters-clear @clear="clear" />
      <div class="spacer" />
      <filters-sort
        :sort="sort"
        :direction="direction"
        @setSort="setSort"
      />
      <view-mode
        class="is-hidden-mobile"
        :view-mode-key="viewModeKey"
        @setMode="setMode"
      />
    </div>
    <div class="filter-items">
      <filter-item
        v-for="filter in selectedFilters"
        :key="filter.field"
        :filter="filter"
        :selected-options="selectedOptions"
        @remove="removeSelectedFilter"
        @updateOption="updateSelectedFilterOptions"
      />
    </div>
  </aside>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import debounce from '@/plugins/debounce'
import FiltersAdd from '@/components/common/filters/FiltersAdd'
import FilterItem from '@/components/common/filters/FilterItem'
import FiltersClear from '@/components/common/filters/FiltersClear'
import FiltersSort from '@/components/common/filters/FiltersSort'
import ViewMode from '@/components/common/filters/ViewMode'

export default {
  name: 'SearchFilterBar',
  mixins: [debounce],
  components: {
    FiltersSort,
    FiltersClear,
    FilterItem,
    FiltersAdd,
    ViewMode
  },
  computed: {
    ...mapState({
      filters: ({ search }) => search.filters.items,
      selectedOptions: ({ search }) => search.filters.selected,
      sort: ({ search }) => search.sort,
      direction: ({ search }) => search.direction,
      viewModeKey: ({ search }) => search.viewModeKey
    }),
    ...mapGetters({
      selectedFilters: 'search/selectedFilters',
      isWidget: 'widgets/isWidget'
    })
  },
  methods: {
    ...mapActions({
      setSelectedFilters: 'search/setSelectedFilters',
      removeSelectedFilter: 'search/removeSelectedFilter',
      updateSelectedFilterOptions: 'search/updateSelectedFilterOptions',
      clear: 'search/clearSelectedFilters',
      setSort: 'search/setSort',
      resetOffset: 'search/resetOffset',
      search: 'search/search'
    }),
    async setMode(key) {
      this.$store.dispatch('search/setMode', key)
      if (this.isWidget) {
        this.resetOffset()
        const { q = '', 
          exact: exactMatch = false } = this.$route.query
        await this.search({ q, exactMatch })
      }
    }
  },
  debounceMethods: {
    updateSelectedFilterOptions: 500
  }
}
</script>

<style scoped>
.filter-controls {
  display: flex;
  flex-direction: row;
  padding: .15rem .35rem;
  margin: 0 -0.15rem;
}
.spacer {
  flex: 1 1 0;
}
.filter-controls > * {
  padding: 0 0.15rem;
}
.filter-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 .25rem;
}
.filter-items > div {
  margin: .15rem .25rem .25rem .15rem !important;
}
</style>
