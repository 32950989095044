<template>
  <component :is="renderer"
             :column="column"
             :item="item"
             :hide-retail-price="hideRetailPrice"
             :hide-discount-price="hideDiscountPrice"
             :hide-whole-sale-price="hideWholeSalePrice"
             :can-edit="canEdit"
             @update="updateQuantity"
             @remove="removeFromCart"/>
</template>

<script>
import { mapState } from "vuex"
import CartCellActions from '@/components/library/cart/cells/CartCellActions';
import CartCellBook from '@/components/library/cart/cells/CartCellBook';
import CartCellAvailability from '@/components/library/cart/cells/CartCellAvailability';
import CartCellChapter from '@/components/library/cart/cells/CartCellChapter';
import CartCellEta from '@/components/library/cart/cells/CartCellEta';
import CartCellName from '@/components/library/cart/cells/CartCellName';
import CartCellPage from '@/components/library/cart/cells/CartCellPage';
import CartCellPrice from '@/components/library/cart/cells/CartCellPrice';
import CartCellQty from '@/components/library/cart/cells/CartCellQty';
import CartCellSubChapter from '@/components/library/cart/cells/CartCellSubChapter';
import CartCellTag from '@/components/library/cart/cells/CartCellTag';
import CartCellTotal from '@/components/library/cart/cells/CartCellTotal';
import CartCellUom from '@/components/library/cart/cells/CartCellUom';

export default {
  name: 'CartCell',
  components: {
    CartCellActions,
    CartCellAvailability,
    CartCellBook,
    CartCellChapter,
    CartCellEta,
    CartCellName,
    CartCellPage,
    CartCellPrice,
    CartCellQty,
    CartCellSubChapter,
    CartCellTag,
    CartCellTotal,
    CartCellUom
  },
  props: {
    column: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      hideRetailPrice: ({ user }) => user.pricing.hideRetailPrice,
      hideDiscountPrice: ({ user }) => user.pricing.hideDiscountPrice,
      hideWholeSalePrice: ({ user }) => user.pricing.hideWholeSalePrice
    }),
    renderer() {
      switch (this.column.type) {
        case 'price':
          return 'cart-cell-price';
        case 'total':
          return 'cart-cell-total';
        case 'tag':
          return 'cart-cell-tag';
        default:
          return `cart-cell-${this.column.property}`;
      }
    }
  },
  methods: {
    updateQuantity({item, qty}) {
      this.$emit('updateQuantity', {item, qty});
    },
    removeFromCart({item}) {
      this.$emit('removeFromCart', {item});
    }
  }
};
</script>

<style scoped>

</style>
