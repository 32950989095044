<template>
  <section class="section">
    <h1 class="subtitle has-text-centered">
      {{ $t('assetsNotFound', { type }) }}
    </h1>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'NoAssetViewer',
  computed: {
    ...mapState({
      content: ({ content }) => content
    }),
    type() {
      return this.$i18n.tc(this.content.contentType || this.content.type, 1)
    }
  }
};
</script>

<style scoped>
.section {
  height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
