<template>
  <div class="modal is-active">
    <div class="modal-background"/>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('requiredReading') }}</p>
        <button data-int="cart-open-modal-cancel"
                class="delete"
                aria-label="cancel"
                @click="cancel"/>
      </header>
      <section class="modal-card-body">
        <span class="icon is-large has-text-danger">
          <i class="fa fa-3x fa-exclamation-circle"/>
        </span>
        {{ $t('requiredReadingMessage') }} <br/><br/>
        <ul class="menu-list">
          <li v-for="notification in requiredReading" :key="notification.id">
            <a class="required-reading" @click="open(notification)">
              <h2 class="is-size-5 has-text-info">{{ notification.name }}</h2>
              <span>{{ notification.startDate ? $d(notification.startDate, 'short') : '' }}</span>
            </a>
          </li>
        </ul>
      </section>
      <footer class="modal-card-foot">
        <button class="button ok-button" @click="cancel">
          {{ $t('ok') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'RequiredReadingModal',
  data() {
    return {
      requiredReading: []
    };
  },
  methods: {
    ...mapActions({
      navigateToNotification: 'notifications/navigateToNotification',
      getRequiredReading: 'notifications/getRequiredReadingWithReturn'
    }),
    open(notification) {
      this.navigateToNotification(notification.id);
      this.cancel();
    },
    cancel() {
      this.$emit('cancel');
    }
  },
  async mounted() {
    this.requiredReading = await this.getRequiredReading();
  },
};
</script>

<style scoped>
.modal {
  z-index: 999;
}
.modal-card-body {
  flex-direction: column;
  min-height: 50vh;
}
.modal-card {
  min-width: 60%;
  max-width: 80%;
}
</style>
