<template>
  <div style="position: relative"
       class="quick-add-wrapper">
    <bulma-button-icon class="button"
                       :stylingClass="quickAddClasses"
                       :icon-class="'fas fa-cart-plus fa-lg'"
                       :text="$t('quickAdd')"
                       @click="accessPopover">
    </bulma-button-icon>
    <template v-if="showPopover">
      <quick-add-to-cart-popover class="popover"
                                 @close="accessPopover">
        <div class="title-six">
          {{ $t('quickAddHelpText') }}
        </div>
        <header class="card-head">
          <h4>
            {{ $t('quickAdd') }}
          </h4>
        </header>

        <section class="card-body is-flex-direction-column">
          <div class="control">
            <input v-model="query"
                   type="text"
                   autocomplete="off"
                   autofocus
                   :placeholder="$t('search') + '...'"
                   data-int="quick-add-input"
                   class="input quick-add-input is-primary">
          </div>
          <div class="table-container">
          <table class="table is-bordered is-striped is-narrow is-fullwidth is-hoverable">
            <tbody>
            <tr v-for="suggestion in suggestions"
                :key="suggestion.id"
                :class="{'is-selected': suggestion === selected}"
                class="suggestion"
                @click="select(suggestion)"
                @dblclick.stop="add(suggestion)">
              <td>
                {{ suggestion.identifier }} - {{ suggestion.name }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        </section>
        <footer class="card-foot">
          <button :disabled="!selected"
                  class="button is-primary add-button mr-1"
                  @click="add(selected)">
            {{ $t('add') }}
          </button>
          <button class="button cancel-button"
                  @click="clearAll(true)">
            {{ $t('cancel') }}
          </button>
        </footer>
      </quick-add-to-cart-popover>
    </template>
  </div>
</template>

<script lang="ts" setup>
import SearchPopover from "@/components/common/popover/SearchPopover.vue"
import BulmaButtonIcon from "@/components/common/BulmaButtonIcon.vue"
import QuickAddModal from "@/components/common/QuickAddModal.vue"
import {ref, computed, watch} from 'vue'
import { getColorValue } from "@/store/modules/theme/getColorValue"
import contrastColors from "@/helpers/contrastColors"
import { useStore } from "vuex"
import { Button } from "@/buefy/dist/cjs"
import {SearchCallTypes} from "@/helpers/SearchCallType";
import QuickAddToCartPopover from "@/components/common/popover/QuickAddToCartPopover.vue";

const store = useStore()
const showPopover = ref<boolean>(false)
const query = ref<string>('')

const suggestions = ref<Array<any>>([])
const selected = ref(null)
const quickAddClasses = computed(() => {
  const primaryColor = store.getters["theme/getTheme"].brand_primary
  let fontColor = contrastColors.colorText(primaryColor) === '#ffffff' ? 'has-text-white' : 'black-color'
  return 'is-primary' + ' ' + fontColor
})

watch(query, async (val, old) => {
  if(val === old) return
  // ensure search is cleared
  if( query.value === '') {
    suggestions.value = []
    return
  }
  selected.value = null
  suggestions.value = await store.dispatch('search/searchWithReturn', {
    callType: SearchCallTypes.QUICKADD,
    q: val,
    limit: 3,
    searchableType: ['Part']
  })
})

const select = (part: any) => {
  if (selected.value !== part) {
    selected.value = part
  } else {
    selected.value = null
  }
}

const clearAll = async (close: boolean) => {
  selected.value = null
  suggestions.value = []
  query.value = ''
  if (close) showPopover.value = false
}
const add = async (part: any) => {
  await clearAll(false)
  await store.dispatch('cart/addToCart',{
    part,
    qty: 1
  } )
}

const accessPopover = () => {
  if(showPopover.value) {
    clearAll(true)
  } else {
    showPopover.value = true
  }
}

</script>

<style scoped lang="scss">
.quick-add-wrapper {
  .popover {
    position: absolute;
    z-index: 11;
    right: 0;

    .title-six {
      text-wrap: wrap;
    }
    .card-body {
      margin: 1em 0;
    }
    .card-head {
      border-bottom: 1px solid hsl(0, 0%, 86%);

      h4 {
        margin-bottom: 0;
      }
    }
    .card-foot {
      border-top: 1px solid hsl(0, 0%, 86%);
    }
    .card-head, .card-foot {
      background-color: hsl(0, 0%, 96%);
      padding: .5em;
    }

    &:before {
      content: " ";
      border-bottom: 11px solid lightslategray;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: relative;
      top: -1.5em;
      bottom: 13.3em;
      left: 84%;
    }

    @media screen and (max-width: 500px) {
      right: 1em;
      left: 1em;

      &:before {
        left: 70%;
      }
    }
  }

}
@media screen and (max-width: 500px) {
  .quick-add-wrapper {
    position: static !important;
  }
}
</style>
