<template>
  <section data-int="browse" class="section">
    <h1 data-int="browse-title" class="title is-size-4 is-marginless">
      {{ name }}
    </h1>
    <div v-if="taxonLoaded" class="taxon-container">
      <div class="columns is-multiline is-mobile" >
        <div v-for="taxon in taxons" :key="taxon.id" class="column is-2-desktop is-3-tablet is-4-mobile">
          <browse-card  data-int="browse-card" 
                        class="browse-card" 
                        :item="taxon" 
                        @click="select"
                        @widgetNav="widgetNavToTaxon" />
        </div>
      </div>
    </div>
    <div v-else class="taxon-container-loading">
      <loading-icon />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import BrowseCard from './BrowseCard';
import { useRouter } from "vue-router"
import { getRouteParamId } from "@/helpers/router";
import { title } from "./browseMetaTitle"
import { useBrowseFlows } from "@/components/widgets/composables/useBrowseFlows"

export default {
  name: 'Browse',
  components: {
    BrowseCard,
    LoadingIcon
  },
  /* type of 'id' should be an Array of stringified ids.
   * However, in some cases it will be resolved as string.
   * The getRouteParamId helper is meant to mitigate related issues.
   * The root cause is likely due to the component being architected
   * to interpret id as a prop despite being rendered as a root router-view.
  */
  props: ['id'], 
  setup() {
    const { 
      setTaxonIdList,
      navigateToBrowse } = useBrowseFlows()

    async function widgetNavToTaxon(taxon) {
      if (taxon.hasChildren) {
        navigateToBrowse(taxon)
      } else {
        setTaxonIdList(taxon)
      }
    }
    return { widgetNavToTaxon }
  },
  computed: {
    ...mapState({
      taxon: ({ browse }) => browse,
      taxonLoaded: ({ browse }) => browse.isLoaded
    }),
    taxons() {
      return this.taxon.children.slice(0, 100);
    },
    ...title,
    name() {
      return this.taxon && this.taxon.name
        ? this.taxon.name
        : this.$i18n.t('browse');
    }
  },
  watch: {
    async id(val) {
      let setId = await getRouteParamId(val)
      await this.getBrowse({ id: setId, router: this.router });
    },
    taxon: {
      deep: true,
      handler (val, oldVal) {
        // sometimes when the user refreshes the page, there is no difference between val and oldVal
        //  Ensure that tab title is set no matter what based on the taxon name whenever it changes
        document.title = this.title
      }
    }
  },
  methods: {
    ...mapActions({
      getBrowse: 'browse/getBrowse',
      navigateToBrowse: 'browse/navigateToBrowse',
      navigateToHome: 'browse/navigateToHome'
    }),
    async select(taxon) {
      await this.navigateToBrowse({ taxon: taxon , router: this.router });
    }
  },
  async created() {
    this.router = useRouter()
    if (!this.id) {
      await this.navigateToHome({ replace: true, router: this.router });
    } else {
      await this.getBrowse({ id: await getRouteParamId(this.id), router: this.router });
    }
  },
};
</script>

<style scoped>
.title {
  flex-shrink: 0;
}
.section {
  padding: .5rem 1rem 1rem 1rem;
  flex-grow: 2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.taxon-container {
   display: flex;
   flex-direction: column;
   flex: 1;
   overflow: auto;
   padding-top: 1rem;
   padding-left: .25rem;
   padding-right: .25rem;
   padding-bottom: 0;
}
.taxon-container-loading {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
