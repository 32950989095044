<template>
  <td :data-int="className">
    <p class="truncate">{{ tagValues }}</p>
  </td>
</template>

<script>
export default {
  name: 'CartCellTag',
  props: {
    column: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    className() {
      return `cart-cell-${this.column.property}`;
    },
    tagValues() {
      const tag = this.item.tags ? this.item.tags[this.column.property] : null;
      return tag ? tag.join(', ') : '';
    }
  }
};
</script>

<style scoped>
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  vertical-align: middle;
  min-height: 2rem;
}
</style>
