<template>
  <div  class="control"
        :class="{ 'is-expanded': expanded }">
    <span class="select"
          :class="{
            'is-danger': isDanger,
            'is-fullwidth': expanded,
            'is-multiple': multiple,
            'is-rounded': rounded,
            'is-empty': value === null
          }">
      <select v-model="computedValue"
              :disabled="disabled">
        <slot/>
      </select>
    </span>

  </div>
</template>

<script setup lang="ts">
/*
 * Intended as replacement for Buefy Select.vue AKA b-select.
 * Uses simpler design than b-select in that there is no support for
 * placeholder option element via a conditional template tag and
 * placeholder prop. Just make use of slot to apply placeholder
 * option along with select-able options.
 * See SettingInput.vue for implementation example.
 */
import { computed } from "vue"
const props = defineProps({
  expanded: Boolean,
  disabled: Boolean,
  multiple: Boolean,
  rounded: Boolean,
  value: {
    type: [String, Number, Boolean, Object, Array, Function],
    default: null
  },
  isDanger: Boolean
})
const emit = defineEmits(["input"])

const computedValue = computed({
  get: () => props.value,
  set(updatedValue) {
    emit("input", updatedValue)
  }
})
</script>
