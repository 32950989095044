import { DraftTagValue, TagsMap } from '@/interfaces/admin'
import { NumberToStringMap } from '@/interfaces/global'

export function getStructuredTagValues (tagValues: DraftTagValue[], tagIdToName: NumberToStringMap) {
  // Build an object map to store values of each unique TagNameId/tag name
  const tagNames: TagsMap = {}
  tagValues.forEach(tag => {
    const name: string = tagIdToName[tag.tagNameId]
    if (name) {
      tagNames[name] = {
        data: {
          tagCount: 0,
          tagValues: []
        },
        name: name
      }
    }
  })
  // Push each tag to the object map
  tagValues.forEach(tag => {
    const name: string = tagIdToName[tag.tagNameId]
    if (name) {
      tagNames[name].data.tagCount++
      tagNames[name].data.tagValues.push({ ...tag, tagName: name })
    }
  })

  // Return the values, which are now structured properly for TagEditorView.vue
  return Object.values(tagNames)
}
