<template>
  <td class="name-cell">
    <div class="name-container">
      <strong class="strong-name">
        <component :is="isWidget ? 'span' : 'router-link'"
                   :class="{  'has-text-info': bomItem.highlight,
                              'has-text-link': hasWidgetInfoLandingPage,
                              'pointer-on-hover': hasWidgetInfoLandingPage }"
                   :to="{ path: getNewDetailsPath() }"
                   class="name-link"
                   @click="handlePartInfoRedirect">
          <p class="truncate">
            {{ bomItem.name }}
          </p>
        </component>
      </strong>

      <div class="indicators pointer-on-hover">
        <a v-if="!!bomItem.thumbnailUrl"
            :class="['icon is-smaller fas fa-image pointer-on-hover',
                    {'has-text-info': bomItem.highlight}]"
            :id="`imagePopoverIcon` + bomItem.refKey"
            @click="$emit('openThumbnailPopover', bomItem.refKey)"/>
        <a  v-if="hasEnabledWidgetTags && bomItem.tagCount"
            :class="['icon is-smaller tags-icon',
                    {'has-text-info': bomItem.highlight}]"
            @click="getTags">
          <i class="fa fa-tags" />
        </a>
        <a v-else-if="!isWidget && bomItem.tagCount"
           :class="{'has-text-info': bomItem.highlight}"
           class="icon is-smaller tags-icon pointer-on-hover"
           @click="openPartInfoPage('tags')">
          <i class="fa fa-tags" />
        </a>
        <a v-if="bomItem.relatedCount && !isWidget"
           :class="{'has-text-info': bomItem.highlight}"
           class="icon is-smaller related-icon pointer-on-hover"
           @click="openPartInfoPage('related')">
          <i class="fa fa-paperclip" />
        </a>
        <a  v-if="hasEnabledWidgetComments && bomItem.commentCount"
            :class="['icon is-smaller comments-icon',
                    {'has-text-info': bomItem.highlight}]"
            class="pointer-on-hover"
            @click="getComments">
          <i class="fa fa-comments" />
        </a>
        <a v-else-if="!isWidget && bomItem.commentCount"
           :class="{'has-text-info': bomItem.highlight}"
           class="icon is-smaller comments-icon pointer-on-hover"
           @click="openPartInfoPage('comments')">
          <i class="fa fa-comments" />
        </a>
        <a v-if="has3dFeatures && has3dFile"
           @click="open3dModal">
          <i class="fas fa-cube icon is-smaller" />
        </a>
      </div>
    </div>
    <div v-if="bomItem.showThumbnailPopover">
      <bom-thumbnail-popover :bomItemUrl="bomItem.thumbnailUrl"
                             :bomItemId="bomItem.refKey"
                             :popoverPosition="bomItem.thumbnailPopoverPosition"
                             :type="bomItem.type"
                             :isBottom="bomItem.popoverPositionBottom"
                             :isWidget="isWidget"
                             @closeThumbnailPopover="$emit('closeThumbnailPopover', bomItem.refKey)">
      </bom-thumbnail-popover>
    </div>
    <div v-else-if="bomItem.showTagsPopover && isWidget">
      <bom-tags-popover :popoverPosition="bomItem.thumbnailPopoverPosition"
                        :id="bomItem.id"
                        :isBottom="bomItem.popoverPositionBottom"
                        :tags="popoverTags"
                        @closePopover="$emit('closeTagsPopover', bomItem.refKey)"/>
    </div>
    <div v-else-if="bomItem.showCommentsPopover && isWidget">
      <bom-comments-popover :popoverPosition="bomItem.thumbnailPopoverPosition"
                            :id="bomItem.id"
                            :isBottom="bomItem.popoverPositionBottom"
                            :comments="popoverComments"
                            @closePopover="$emit('closeCommentsPopover', bomItem.refKey)"/>
    </div>
  </td>
</template>

<script>
import BomThumbnailPopover from "../popovers/BomImagePopover.vue"
import BomTagsPopover from "../popovers/BomTagsPopover"
import BomCommentsPopover from "../popovers/BomCommentsPopover"
import { computed } from 'vue'
import { handlePartInfoRedirect } from './compositionObjects'
import { useStore } from 'vuex'

export default {
  name: 'BomCellName',
  emits: ['openWidgetPartInfo', 'open'],
  components: {
    BomThumbnailPopover,
    BomTagsPopover,
    BomCommentsPopover
  },
  props: {
    bomItem: {
      type: Object,
      required: true
    },
    isWidget: Boolean,
    hasEnabledWidgetTags: Boolean,
    hasEnabledWidgetComments: Boolean,
    hasWidgetInfoLandingPage: Boolean,
    popoverTags: {
      type: Array,
      default: () => []
    },
    popoverComments: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getNewDetailsPath() {
      return this.bomItem.pagePartUrl + '/details'
    },
    open (route = '') {
      this.$emit('open', {
        content: this.bomItem,
        route
      })
    },
    openPartInfoPage(tab = "info") {
      const map = {
        info: '/details',
        tags: '/details#tags',
        related: '/details#related',
        comments: '/details#comments'
      }
      if (!map[tab]) return
      const path = map[tab]
      this.open(path)
    },
    async getTags() {
      await this.$store.dispatch('content/getTags', {
        filter: '',
        id: this.bomItem.id,
        type: this.bomItem.type,
        pagePartId: this.bomItem.pagePartId
      })
      this.$emit('openTagsPopover', this.bomItem.refKey)
    },
    async getComments() {
      await this.$store.dispatch('widgets/partComments/getComments', this.bomItem.id)
      this.$emit('openCommentsPopover', this.bomItem.refKey)
    },
    ...handlePartInfoRedirect
  },
  setup (props) {
    const store = useStore()
    const has3dFeatures = store.getters['user/has3dFeatures'] && !store.state['auth/isWidget']
    const has3dFile = computed(() => !!props.bomItem.current3dThumbnailInfo?.scsFileUrl)
    const open3dModal = async () => await store.dispatch(
      'content/open3dModal',
      props.bomItem
    )

    return {
      has3dFeatures,
      has3dFile,
      open3dModal,
    }
  }
}
</script>

<style scoped>
.name-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  text-wrap: nowrap;
  flex-wrap: wrap
}
.strong-name {
  margin-right: auto;
  text-wrap: wrap;
}
.name-link {
  flex-grow: 2;
}
.indicators {
  text-align: right;
}
.is-smaller {
  width: 1.25rem;
}
.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor !important;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  vertical-align: middle;
  min-height: 2rem;
}
</style>
