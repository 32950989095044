<template>
  <info :content="content"
        :contentLoaded="contentLoaded"
        :pricing="pricing"
        :addToCartQtyBehavior="addToCartQtyBehavior"
        :displayRetail="displayRetail"
        :displayWholeSale="displayWholeSale"
        :displayDiscounted="displayDiscounted"
        :displayEta="displayEta"
        :displayAvailability="displayAvailability"
        :isCartOrErpCartEnabled="isCartOrErpCartEnabled"
        :isCartEnabled="isCartEnabled"
        :showTags="showTags"
        @getPricing="getPricing"
        @addToCart="addToCart"/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Info from "./Info"
export default {
  name: "InfoWrapper",
  props: {
    showTags: {
      type: Boolean,
      default: true
    }
  },
  components: { Info },
  computed: {
    ...mapState({
      content: ({ content }) => content,
      contentLoaded: ({ content }) => content.isLoaded,
      pricing: ({ content }) => content.pricing,
      addToCartQtyBehavior: ({ user }) => {
        return user.preferences.addToCartQtyBehavior
      },
      isCartLoaded: ({ cart }) => cart.isLoaded
    }),
    ...mapGetters({
      isCartEnabled: 'user/isCartEnabled',
      displayRetail: 'user/displayRetail',
      displayWholeSale: 'user/displayWholeSale',
      displayDiscounted: 'user/displayDiscounted',
      displayEta: 'user/displayEta',
      displayAvailability: 'user/displayAvailability',
      isCartOrErpCartEnabled: 'user/isCartOrErpCartEnabled'
    }),
  },
  methods: {
    ...mapActions({
      getPricing: 'content/getPricing',
      addToCart: 'cart/addToCart'
    })
  },
  async created() {
    await this.getPricing()
  }
}
</script>