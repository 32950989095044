// This module is only accessible as superadmin
import { ActionContext, GetterTree, MutationTree } from 'vuex'
import { getTenantKeys } from '@/controllers/admin/superAdmin'

interface State {
  tenantIdToKey: Map<number, string>
}

type Context = ActionContext<State, any>

enum Mutations {
  SET_TENANT_ID_TO_KEY = "SET_TENANT_ID_TO_KEY"
}

const state = {
  tenantIdToKey: new Map()
}

const getters: GetterTree<State, any> = {
  isSuperAdmin(state, getters, rootState) {
    return rootState.user.superAdmin
  },
  getTenantKeyById(state, getters) {
    return function(id: number): string {
      return getters.isSuperAdmin
        ? state.tenantIdToKey.get(id) ?? ""
        : ""
    }
  },
  tenantKeys(state) {
    return Array.from(state.tenantIdToKey.values())
  }
}

const actions = {
  async loadTenantsMap({ commit, getters }: Context) {
    if (!getters.isSuperAdmin) return

    let tenantsMap
    try {
      const { data } = await getTenantKeys({
        offset: 0,
        filterTerm: "",
        sortAsc: false
      })
      tenantsMap = data.reduce((map, dto) => {
        map.set(dto.id, dto.tenantKey)
        return map
      }, new Map())
    } catch {
      tenantsMap = new Map()
    } finally {
      commit(Mutations.SET_TENANT_ID_TO_KEY, tenantsMap)
    }
  }
}

const mutations: MutationTree<State> = {
  [Mutations.SET_TENANT_ID_TO_KEY] (state, map: Map<number, string>) {
    state.tenantIdToKey = map
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}