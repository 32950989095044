import { DraftBomItem } from '@/interfaces/admin'
import { StringToStringMap, StringToNumberMap } from '@/interfaces/global'

export function updateIndentsOutsideDrag(parts: DraftBomItem[], orphanedParts: StringToStringMap, draggedItems: StringToStringMap, masterListRefsToIdx: StringToNumberMap, masterList: DraftBomItem[]): DraftBomItem[] {
    let partsWithUpdatedIndents: DraftBomItem[] = []
    parts.forEach((it: DraftBomItem) => {
      // If parent is null, part is at root and can return old obj
      if (!it.parent) {
        partsWithUpdatedIndents.push(it)
        return
      }
      // If a part is orphaned, then loop up its hierarchy from
      // parent to parent until finding an ancestor not in draggedItems.
      // The degrees of separation determines by how much to decrement
      // indentation for the orphaned part to be +1 relative to its new parent
      const isOrphaned: boolean = !!orphanedParts[it?.refKey ?? '']
      if (isOrphaned) { // If is orphaned, then decrement indent
        let decrement = 0
        let currentAncestor = it?.parent ?? ''
        while (!!draggedItems[currentAncestor]) {
          const index = masterListRefsToIdx[currentAncestor]
          currentAncestor = masterList[index]?.parent ?? ''
          decrement++
        }
        partsWithUpdatedIndents.push({ ...it, indentation: it.indentation - decrement })
      } else { // If not orphaned, re-verify indent is still +1 relative to its parent
        const parent = it?.parent ?? ''
        const parentIndent = partsWithUpdatedIndents
          .find((item: DraftBomItem) => item.refKey === parent)?.indentation ?? 0
        partsWithUpdatedIndents.push({ ...it, indentation:  parentIndent + 1 })
      }
    })
    return partsWithUpdatedIndents
}

export function updateDraggedPartIndents(draggedItems: DraftBomItem[], startingIndent: number, smallestIndentInDragged: number): DraftBomItem[] {
    if (draggedItems.length === 0) return [] 
    // Build a map of all orphaned parts within draggedItems
    const orphans: StringToStringMap = getDraggedOrphansMap(draggedItems, smallestIndentInDragged)
    let list: DraftBomItem[] = []
    // Use orphans map to determine whether to hoist part to top level -- set indentation to startingIndent
    // Otherwise, set part's indentation to +1 relative to its parent's updated value
    draggedItems.forEach((it: DraftBomItem) => {
      if (orphans[it?.refKey ?? '']) {
        list.push({ ...it, indentation: startingIndent })
      } else {
        const parent = it?.parent ?? ''
        const parentIndent = list
          .find((item: DraftBomItem) => item.refKey === parent)?.indentation ?? 0
        list.push({ ...it, indentation:  parentIndent + 1 })
      }
    })
    return list
  }

export function getDraggedOrphansMap(draggedItems: DraftBomItem[], smallestIndentInDragged: number): StringToStringMap {
    if (draggedItems.length === 0) return {}
    const firstRefKey = draggedItems[0]?.refKey ?? ''
    const map: StringToStringMap = { [firstRefKey]: firstRefKey }
    let hasHitSmallestIndent = draggedItems[0].indentation === smallestIndentInDragged
    for (let i = 1; i < draggedItems.length; i++) {
      const item = draggedItems[i]
      const refKey = item?.refKey ?? ''
      const equalsSmallestIndent = item.indentation === smallestIndentInDragged
      const lessThanOrEqualFirstIndent = item.indentation <= draggedItems[0].indentation
      // Dragged items with smallest indentation will always be orphaned
      // because they are being removed from their parent
      if (equalsSmallestIndent) {
        map[refKey] = refKey
        hasHitSmallestIndent = true
      } 
      // Until the first item with smallest indententation is encountered,
      // initial items at an equal or higher level in hierarchy than
      // the first dragged item will be orphaned as well
      else if (lessThanOrEqualFirstIndent && !hasHitSmallestIndent) {
        map[refKey] = refKey
      }
    }
    return map
  }