import http from '@/http';

/**
 *
 * @param {number|string} pageId
 * @param {number|number} currentPage
 * @return {array}
 */
export async function getHotpoints(pageId: string, currentPage: number):Promise<void>  {
  return await http.get(`/admin/pages/${pageId}/hotpoints?sheet=${currentPage}`).then((res) => res.data);
}
