<template>
  <main class="cart-container">
    <nav class="level menu-margin">
      <div class="level-left is-paddingless">
        <div class="level-item">
          <h1 class="menu">
            <label class="title is-size-4">{{ $tc('cart', 1) }}</label>
          </h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item menu">
          <cart-menu />
        </div>
      </div>
    </nav>
    <cart-table-wrapper>
      <div class="level-left">
        <button
          v-if="isQuickAddEnabled"
          class="button level-item is-info add-button"
          @click="quickAddModalOpen = true"
        >
          {{ $t('add') }}
        </button>
      </div>
      <div class="level-right">
        <button
          v-if="hasPurchaseOrder"
          class="button level-item is-primary purchase-order-button"
          :disabled="items.length === 0"
          @click="purchase"
        >
          {{ $t('submitPurchaseOrder') }}
        </button>
        <button
          v-if="hasRequestQuote"
          class="button level-item is-primary request-for-quote-button"
          :disabled="items.length === 0"
          @click="quote"
        >
          {{ $t('submitRequestForQuote') }}
        </button>
      </div>
    </cart-table-wrapper>
    <cart-suggestions
      v-if="canViewSuggestions"
      class="is-hidden-mobile suggestions"
    />
    <cart-quick-add-modal-wrapper
      v-if="quickAddModalOpen && isQuickAddEnabled"
      @cancel="quickAddModalOpen = false"
    />
  </main>
</template>

<script>
import CartQuickAddModalWrapper from './CartQuickAddModalWrapper'
import CartTableWrapper from './CartTableWrapper'
import CartMenu from './CartMenu'
import CartSuggestions from './CartSuggestions'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'Cart',
  components: {
    CartSuggestions,
    CartMenu,
    CartTableWrapper,
    CartQuickAddModalWrapper
  },
  data () {
    return {
      quickAddModalOpen: false
    }
  },
  computed: {
    ...mapState({
      items: ({ cart }) => cart.items
    }),
    ...mapGetters({
      isQuickAddEnabled: 'user/isQuickAddEnabled',
      canViewSuggestions: 'user/hasPartOrderSuggestions',
      hasPurchaseOrder: 'user/hasPurchaseOrder',
      hasRequestQuote: 'user/hasRequestQuote'
    })
  },
  methods: {
    ...mapActions({
      navigateToPurchaseOrder: 'order/navigateToPurchaseOrder',
      navigateToRequestForQuote: 'order/navigateToRequestForQuote'
    }),
    purchase () {
      this.navigateToPurchaseOrder()
    },
    quote () {
      this.navigateToRequestForQuote()
    }
  },
  created() {
    document.title = this.$tc('cart', 1)
  }
}
</script>

<style scoped>
.menu {
  flex-shrink: 0;
  padding-bottom: .25rem
}
.menu-margin {
  margin-top: .25rem;
  margin-bottom: .5rem !important
}
.cart-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-top: .5rem;
}
.level-left {
  padding-left: 1rem;
}
.title {
  padding-left: 1rem;
}
.add-button {
  margin-left: .75rem;
}
@media only screen and (max-height: 768px) {
  .suggestions {
    display: none !important;
  }
}
</style>
