import http from '@/http'
import { AxiosPromise } from 'axios'

export enum TemplateType { RFQ, ORDER }

export interface CopyOrderTemplateRequest {
  targetOrderTemplateId: number,
  newTemplateName: String,
  newTemplateType: TemplateType
}

export interface OrderFormTemplate {
  id?: number,
  created?: Date,
  updated?: Date,
  version?: number,
  isDefault?: boolean,
  name?: string,
  type?: TemplateType,
  applyBoldStyle?: boolean,
  applyItalicStyle?: boolean,
  applyUnderlineStyle?: boolean,
  fontColor?: string | null,
  fontFamily?: string | null,
  fontSize?: number,
  orgCount?: number,
  orderCount?: number
}

export const copyOrderTemplate = async function(request: CopyOrderTemplateRequest): Promise<AxiosPromise> {
  return http.post('/admin/orders/templates/copy', request);
}

export const deleteOrderTemplate = async function(id: number): Promise<void> {
  await http.delete(`/admin/orders/templates/${id}`)
}

export const loadOrderTemplate = async function(id: number): Promise<OrderFormTemplate> {
  const { data } = await http.get(`/admin/orders/templates/${id}`)
  return data
}

export const updateOrderTemplate = async function(orderTemplate: OrderFormTemplate): Promise<OrderFormTemplate> {
  const { data } = await http.put(`/admin/orders/templates`, orderTemplate)
  return data
}

export const saveOrderTemplate = async function(orderTemplate: OrderFormTemplate): Promise<OrderFormTemplate> {
  const { data } =  await http.post(`/admin/orders/templates`, orderTemplate)
  return data
}

export const getOrderTemplates = async function (params: any): Promise<AxiosPromise> {
  return http.get('/admin/orders/templates', params)
}

export const setDefaultOrderTemplate = async function (id: number): Promise<void> {
  await http.put(`/admin/orders/templates/default/${id}`)
}

export const getOrderTemplateById = async function(orgId: number): Promise<number|null> {
  const { data } = await http.get("/org-admin/order-template/override", { params: { orgId }})
  return data || null
}

export const setOrderTemplateById = async function(orgId: number, overrideId: number|null): Promise<void> {
  const API_PATH = "/org-admin/order-template/override"
  const QUERY_PARAMS = `orgId=${orgId}` + (overrideId ? `&overrideId=${overrideId}` : "")
  await http.put(`${API_PATH}?${QUERY_PARAMS}`)
}

export const getQuoteTemplateById = async function(orgId: number): Promise<number|null> {
  const { data } = await http.get("/org-admin/quote-template/override", { params: { orgId }})
  return data || null
}

export const setQuoteTemplateById = async function(orgId: number, overrideId: number|null): Promise<void> {
  const API_PATH = "/org-admin/quote-template/override"
  const QUERY_PARAMS = `orgId=${orgId}` + (overrideId ? `&overrideId=${overrideId}` : "")
  await http.put(`${API_PATH}?${QUERY_PARAMS}`)
}
export const getOrderFormPartsColumnsById = async function (id: number): Promise<Array<any>> {
  const { data } = await http(`/admin/orders/templates/part-column/all/${id}`)
  return data
}

export const updateOrderFormPartsColumnById = async function(id: number, dto: Object): Promise<void> {
  await http.put(`/admin/orders/templates/part-column/${id}`, dto)
}

export const updateOrderPartColumnDisplayOrderById = async function(id: number, orderList: number[]): Promise<void> {
  await http.put(`/admin/orders/templates/part-column/display-order/${id}`, orderList)
}

export const createOrderFormPartsColumn= async function(dto: Object): Promise<any> {
  await http.post("/admin/orders/templates/part-column", dto)
}

export const deleteOrderFormPartsColumnById = async function(id: number): Promise<void> {
  await http.delete(`/admin/orders/templates/part-column/${id}`)
}
