<template>
  <component :is="renderer"
             :column="column"
             :has-hide-price="hasHidePrice"
             :hide-retail-price="hideRetailPrice"
             :hide-discount-price="hideDiscountPrice"
             :hide-whole-sale-price="hideWholeSalePrice"
             :can-edit="canEdit"
             @togglePrice="toggleHidePriceFromBom" />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import CartHeaderAvailability from '@/components/library/cart/headers/CartHeaderAvailability';
import CartHeaderBook from '@/components/library/cart/headers/CartHeaderBook';
import CartHeaderChapter from '@/components/library/cart/headers/CartHeaderChapter';
import CartHeaderEta from '@/components/library/cart/headers/CartHeaderEta';
import CartHeaderName from '@/components/library/cart/headers/CartHeaderName';
import CartHeaderPage from '@/components/library/cart/headers/CartHeaderPage';
import CartHeaderPrice from '@/components/library/cart/headers/CartHeaderPrice';
import CartHeaderQty from '@/components/library/cart/headers/CartHeaderQty';
import CartHeaderSubChapter from '@/components/library/cart/headers/CartHeaderSubChapter';
import CartHeaderTag from '@/components/library/cart/headers/CartHeaderTag';
import CartHeaderTotal from '@/components/library/cart/headers/CartHeaderTotal';
import CartHeaderUom from '@/components/library/cart/headers/CartHeaderUom';
import CartHeaderActions from '@/components/library/cart/headers/CartHeaderActions';

export default {
  name: 'CartHeader',
  components: {
    CartHeaderAvailability,
    CartHeaderBook,
    CartHeaderChapter,
    CartHeaderEta,
    CartHeaderName,
    CartHeaderPage,
    CartHeaderPrice,
    CartHeaderQty,
    CartHeaderSubChapter,
    CartHeaderTag,
    CartHeaderTotal,
    CartHeaderUom,
    CartHeaderActions
  },
  props: {
    column: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      hideRetailPrice: ({ user }) => user.pricing.hideRetailPrice,
      hideDiscountPrice: ({ user }) => user.pricing.hideDiscountPrice,
      hideWholeSalePrice: ({ user }) => user.pricing.hideWholeSalePrice
    }),
    ...mapGetters({
      hasHidePrice: "user/hasHidePrice"
    }),
    renderer() {
      switch (this.column.type) {
        case 'price':
          return 'cart-header-price';
        case 'total':
          return 'cart-header-total';
        case 'tag':
          return 'cart-header-tag';
        default:
          return `cart-header-${this.column.property}`;
      }
    }
  },
  methods: {
    ...mapActions({
      toggleHidePriceFromBom: 'user/toggleHidePriceFromBom'
    })
  }
};
</script>

<style scoped>

</style>
