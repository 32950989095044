<template>
  <section v-if="taxon.children.length" data-int="browse" class="section">
    <h1 data-int="browse-title" class="title is-size-4">
      {{ $t('browse') }}
    </h1>
    <div v-if="taxonLoaded" class="columns is-multiline is-mobile" >
      <div  v-for="taxon in taxons" 
            :key="taxon.id" 
            class="column is-2-desktop is-3-tablet is-4-mobile">
        <browse-card  class="browse-card" 
                      data-int="browse-card" 
                      :item="taxon"
                      @click="select"
                      @widgetNav="widgetSelect"/>
      </div>
    </div>
    <div v-else class="taxon-container-loading">
      <loading-icon />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, useStore } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import BrowseCard from '@/components/library/browse/BrowseCard';
import { useRouter } from "vue-router"
import { useBrowseFlows } from '@/components/widgets/composables/useBrowseFlows'

export default {
  name: 'Browse',
  components: {
    BrowseCard,
    LoadingIcon
  },
  setup() {
    const store = useStore()
    const { navigateToBrowse, 
      resetWidgetBrowseState } = useBrowseFlows()

    function widgetSelect(dto) {
      resetWidgetBrowseState()
      store.dispatch('browse/resetBrowseState')
      navigateToBrowse(dto)
    }
    return { widgetSelect }
  },
  computed: {
    ...mapState({
      taxon: ({ browse }) => browse,
      taxonLoaded: ({ browse }) => browse.isLoaded
    }),
    taxons() {
      return this.taxon.children.slice(0, 100);
    }
  },
  methods: {
    ...mapActions({
      navigateToBrowse: 'browse/navigateToBrowse',
      getBrowse: 'browse/getBrowse',
      resetBrowseState: 'browse/resetBrowseState'
    }),
    async select(taxon) {
      await this.resetBrowseState()
      await this.navigateToBrowse({ taxon: taxon, router: this.router });
    }
  },
  async created() {
    this.router = useRouter()
    await this.getBrowse({ router: this.router })
  }
};
</script>

<style scoped>
.section {
  padding: .5rem 1rem 1rem 1rem;
  height: auto;
  overflow: hidden;
  border-bottom: lightgray 1px solid;
}
.columns {
  padding-bottom: 1rem;
}
.taxon-container-loading {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 16rem;
}
</style>