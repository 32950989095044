<template>
  <td
    v-if="!isHidden"
    :data-int="className"
  >
    {{ total }}
  </td>
</template>

<script>
import { mapState } from "vuex"
export default {
  name: 'CartFooterTotal',
  props: {
    column: {
      type: Object,
      required: true
    },
    totalRetailPrice: {
      type: String,
      default: ''
    },
    totalWholesalePrice: {
      type: String,
      default: ''
    },
    totalDiscountedPrice: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      hideRetailPrice: ({ user }) => user.pricing.hideRetailPrice,
      hideDiscountPrice: ({ user }) => user.pricing.hideDiscountPrice,
      hideWholeSalePrice: ({ user }) => user.pricing.hideWholeSalePrice
    }),
    className () {
      return `cart-footer-${this.column.property}`
    },
    isHidden () {
      switch (this.column.property) {
        case 'totalRetailPrice':
          return this.hideRetailPrice
        case 'totalWholesalePrice':
          return this.hideWholeSalePrice
        case 'totalDiscountedPrice':
          return this.hideDiscountedPrice
        default:
          return false
      }
    },
    total () {
      let total = ''
      if (this.column.property === 'totalRetailPrice' && !this.hideRetailPrice) {
        total = this.totalRetailPrice
      } else if (this.column.property === 'totalWholesalePrice' && !this.hideWholeSalePrice) {
        total = this.totalWholesalePrice
      } else if (this.column.property === 'totalDiscountedPrice' && !this.hideDiscountedPrice) {
        total = this.totalDiscountedPrice
      }
      return total
    }
  }
}
</script>
