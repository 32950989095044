export interface FileInfo {
  name: String
  image: String
  width: Number
  height: Number

}
const emptyObj = {
  name: '',
  image: '',
  width: 0,
  height: 0
}

export const createFileInfoWithReader = async (inputFile: File): Promise<FileInfo> => {
  const reader = new FileReader()

  return new Promise((resolve) => {
    reader.onerror = () => {
      reader.abort()
      resolve(emptyObj)
    }

    reader.onload = (theFile) => {
      const image = new Image()
      image.src = theFile.target?.result as string
      image.onload = function () {
        resolve(createFileInfo(inputFile, image, reader.result as string))
      }
    }
    reader.readAsDataURL(inputFile)
  })
}

export const createFileInfo = (fileData: File, image: HTMLImageElement, readerResult: String): FileInfo => {
  return {
    name: fileData.name,
    image: readerResult,
    height: image.height,
    width: image.width
  }
}
