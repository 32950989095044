import http from '@/http';

import {
  BOM_COLUMNS_RECEIVED, CLEAR_BOM_COLUMNS
} from '../contentTypes';

const state = getDefaultState()

const actions = {
  async getBomColumns({commit, state}) {
    try {
      if (!state.isLoaded) {
        const {data} = await http.get('pages/metadata');
        commit(BOM_COLUMNS_RECEIVED, {items: data});
      }
    } catch (err) {
      // na
    }
  }
};

const mutations = {
  [BOM_COLUMNS_RECEIVED](state, {items}) {
    const cols = [
      {property: 'indicator'},
      {property: 'item'},
      {property: 'actions'}
    ];

    if (items.length) {
      items.forEach((col) => {
        switch (col.field) {
          case 'name':
            cols.push({property: 'name'});
            break;
          case 'partNumber':
            cols.push({property: 'part-number'});
            break;
          case 'quantity':
            cols.push({property: 'quantity'});
            break;
          case 'uom':
            break;
          case 'retailPrice':
            cols.push({property: 'retail', type: 'price'});
            break;
          case 'wholesalePrice':
            cols.push({property: 'wholesale', type: 'price'});
            break;
          case 'discountPrice':
            cols.push({property: 'discounted', type: 'price'});
            break;
          default: {
            const fields = col.field.split(':');
            const property = fields.length > 2 ? fields[2] : fields.length > 1 ? fields[1] : col.fields; // eslint-disable-line
            cols.push({property, type: 'tag', name: col.name});
          }
        }
      });
    }
    state.items = cols;
    state.isLoaded = true;
  },
  [CLEAR_BOM_COLUMNS] (state) {
    Object.assign(state, getDefaultState())
  }
};

export default {
  state,
  actions,
  mutations
};

export function getDefaultState() {
  return {
    items: [],
    isLoaded: false
  }
}