<template>
  <div :class="{'is-active': isActive}" class="dropdown" v-on-clickaway="closeActive">
    <div class="dropdown-trigger">
      <button class="button is-primary" @click="toggleAcive">
        <span>{{ $tc('filter', 2) }}</span>
        <span class="icon">
          <i class="fa fa-plus"/>
        </span>
      </button>
    </div>
    <div class="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <nav class="navbar is-light is-hidden-mobile">
          <h1 class="title is-size-5 has-text-centered">{{ $tc('filter', 2) }}</h1>
        </nav>
        <div class="dropdown-options">
          <div v-for="filter in filtersWithValues" :key="'filter:' + filter.field" class="dropdown-item">
            <div class="field">
              <input v-model="selected"
                     type="checkbox"
                     :id="filter.field"
                     :value="filter"
                     class="is-checkradio"
                     @change="selectFilters"/>
              <label :for="filter.field">{{ filterName(filter) }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {directive as onClickaway} from 'vue3-click-away';

export default {
  name: 'FiltersAdd',
  directives: {
    onClickaway
  },
  props: {
    filters: {
      type: Array,
      required: true
    },
    selectedFilters: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filtersWithValues() {
      return this.filters.filter((filter) => {
        if (filter.values) {
          return Object.keys(filter.values).length;
        }
        return true;
      });
    }
  },
  watch: {
    selectedFilters() {
      this.selected = this.selectedFilters;
    }
  },
  data() {
    return {
      isActive: false,
      selected: []
    };
  },
  methods: {
    filterName(filter) {
      if (filter.name.indexOf('%%') === 0) {
        return this.$i18n.tc(filter.name.replace(/%%/g, ''), 2);
      }
      return filter.name;
    },
    closeActive() {
      this.isActive = false;
    },
    toggleAcive() {
      this.isActive = !this.isActive;
    },
    selectFilters() {
      this.$emit('selectFilters', this.selected);
    }
  }
};
</script>

<style scoped>
.dropdown-content {
  min-width: 20rem;
  width: max-content;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 50vh;
  overflow: auto;
}
.dropdown-menu {
  padding: .1rem;
}
.dropdown-item {
  width: max-content;
  padding: .5rem;
}
.dropdown-options {
  height: auto;
  overflow: auto;
}
.navbar {
  min-height: 2.5rem;
  padding-top: .25rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: lightgray 1px solid;
}
.title {
  margin-top: .25rem;
  margin-left: .5rem;
}
.icon {
  padding-top: .25rem;
}
</style>
