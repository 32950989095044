<template>
  <section  class="is-flex m-2 is-justify-content-space-between">
    <div class="is-flex">
      <table-of-contents  v-show="showTocDropdown"/>
      <widget-breadcrumbs   v-show="!isLoading"
                            :breadcrumbs="breadcrumbs"
                            @openAsset="handleRedirect"/>
    </div>
    <widget-toc-steppers v-show="showTocDropdown && !hasInfoPageOpen"/>
  </section>
  <widget-loading-icon v-if="isLoading"/>
  <widget-content-header />
  <slot />
</template>

<script setup>
import { toRaw, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import WidgetBreadcrumbs from '../components/WidgetBreadcrumbs'
import WidgetLoadingIcon from '@/components/widgets/components/WidgetLoadingIcon'
import TableOfContents from '@/components/library/content/header/TableOfContents'
import WidgetTocSteppers from '@/components/widgets/components/WidgetTocSteppers'
import WidgetContentHeader from '@/components/widgets/components/WidgetContentHeader'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import { useSearch } from '@/components/widgets/composables/useSearch'
import { useBrowseFlows } from '@/components/widgets/composables/useBrowseFlows'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import { WidgetRootBreadcrumbs } from '@/interfaces/global'

const { breadcrumbs, 
  isBook,
  navigateToContent, 
  goHome } = useWidgetNavigator()
const { hasInfoPageOpen } = useInfoLandingPage()
const { isLoading } = useFetch()
const { navigateToSearch } = useSearch()
const { goToLastBrowse } = useBrowseFlows()
const route = useRoute()
const store = useStore()

const showToc = computed(() => {
  return store.getters['widgets/showToc']
})
const showTocDropdown = computed(() => {
  return showToc.value && isBook.value && !isLoading.value
})

function handleRedirect(dto) {  
  switch(dto.type) {
    case WidgetRootBreadcrumbs.browse:
      goToLastBrowse()
      break
    case WidgetRootBreadcrumbs.search:
      navigateToSearch(toRaw(route.query.q ?? ''), { 
        clearAllContent: true })
      break
    case WidgetRootBreadcrumbs.home:
      goHome()
      break
    default:
      navigateToContent(toRaw(dto))
  }
}
</script>