import { DraftTagValue, PartTags } from '@/interfaces/admin'
import { v4 as uuidv4 } from 'uuid'

export function formatPartTagValues (tags: PartTags, tenant: number): DraftTagValue[] {
  const tagsList: DraftTagValue[] = []

  for (const key in tags) {
    for (let i = 0; i < tags[key].tagValues.length; i++) {
      const tagData = tags[key].tagValues[i]
      const dto: DraftTagValue = {
        id: null,
        pendingId: uuidv4(),
        targetEntity: tagData.targetEntity,
        tagNameId: tagData.tagNameId,
        tenantId: tenant
      }
      const hasValue = tagData.hasOwnProperty('value')
      const hasUpperAndLower = tagData.hasOwnProperty('upperBound') && tagData.hasOwnProperty('lowerBound')
      const hasPrefix = tagData.hasOwnProperty('prefix')
      const hasSuffix = tagData.hasOwnProperty('suffix')
      if (hasValue) { dto.value = tagData.value } 
      else if (hasUpperAndLower) {
        dto.lowerBoundValue = tagData.lowerBound
        dto.upperBoundValue = tagData.upperBound
      }
      if (hasPrefix) { dto.prefixValue = tagData.prefix }
      if (hasSuffix) { dto.suffixValue = tagData.suffix }
      tagsList.push(dto)
    }
  }
  return tagsList
}
