/* Normalize font families. Primarily used in admin */
export const fontFamilyOptions = [
  {
    value: 'andale mono',
    text: 'Andale Mono'
  },
  {
    value: 'arial',
    text: 'Arial'
  },
  {
    value: 'arial black',
    text: 'Arial Black'
  },
  {
    value: 'comic sans ms',
    text: 'Comic Sans MS'
  },
  {
    value: 'courier new',
    text: 'Courier New'
  },
  {
    value: 'georgia',
    text: 'Georgia'
  },
  {
    value: 'impact',
    text: 'Impact'
  },
  {
    value: 'tahoma',
    text: 'Tahoma'
  },
  {
    value: 'times new roman',
    text: 'Times New Roman'
  },
  {
    value: 'trebuchet ms',
    text: 'Trebuchet MS'
  },
  {
    value: 'verdana',
    text: 'Verdana'
  },
  {
    value: 'webdings',
    text: 'Webdings'
  }
]
