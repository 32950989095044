import http from '@/http'

export async function fetchAllPartComments(id: number): Promise<unknown[]> {
  let comments: unknown[] = []
  const params = { offset: 0 }
  let pageCount, pageNum

  do {
    const { data, headers } = await http.get(`/parts/${id}/comments`, { params })
    pageCount = parseInt(headers['x-page-count'])
    pageNum = parseInt(headers['x-page-num'])
    params.offset = parseInt(headers['x-offset']) + parseInt(headers['x-limit'])
    comments = comments.concat(data)
  } while (pageNum < pageCount)

  return comments
}