<template>
  <th data-int="cart-header-qty">
    {{ $t('qty') }}
  </th>
</template>

<script>
export default {
  name: 'CartHeaderQty'
};
</script>

<style scoped>

</style>
