import * as Communicator from '@/plugins/hoops/types/web-viewer'
import NodeId = Communicator.NodeId

export interface HoopsModelParams {
  childNodeIds: NodeId[]
  edgeCount: number
  faceCount: number
  nodeId: number
  nodeName: string
  properties: any
}

export class HoopsModelProperties {
  childNodeIds: NodeId[] // very soft concept; more relevant in phase 2
  edgeCount: number // unknown usage
  faceCount: number // unknown usage
  nodeId: number // node id of model or selected
  nodeName: string // name pulled from model or selected
  properties: any // variable content; pulled from the model or selected

  constructor ({
    childNodeIds,
    edgeCount,
    faceCount,
    nodeId,
    nodeName,
    properties
  }: HoopsModelParams) {
    this.childNodeIds = childNodeIds
    this.edgeCount = edgeCount
    this.faceCount = faceCount
    this.nodeId = nodeId
    this.nodeName = nodeName
    this.properties = properties
  }
}
