import { ActionContext, GetterTree, MutationTree } from 'vuex'
import { savePendingTags } from '@/controllers/admin/superAdmin'
import { TagUpdatesMap, TagToAdd, TagToEdit } from '@/interfaces/admin'


interface PendingTagsState {
  pendingCreate: TagToAdd[],
  pendingDelete: number[],
  pendingEdit: TagToEdit[],
  isSaving: boolean,
  entityId: number|null
}

type Context = ActionContext<PendingTagsState, any>

enum Mutations {
  CLEAR_STATE = "CLEAR_STATE",
  ADD_TO_CREATE = "ADD_TO_CREATE",
  ADD_TO_DELETE = "ADD_TO_DELETE",
  ADD_TO_EDIT = "ADD_TO_EDIT",
  REMOVE_FROM_CREATE = "REMOVE_FROM_CREATE",
  SET_ENTITY_ID = "SET_ENTITY_ID",
  SET_IS_SAVING = "SET_IS_SAVING"
}

const state: PendingTagsState = {
  pendingDelete: [],
  pendingCreate: [],
  pendingEdit: [],
  isSaving: false,
  entityId: null
}

const getters: GetterTree<PendingTagsState, any> = {
  tagUpdatesMap(state): TagUpdatesMap {
    return {
      create: state.pendingCreate,
      update: state.pendingEdit,
      delete: state.pendingDelete
    }
  },
  pendingTagIdsMap(state): Map<string, TagToAdd> {
    return state.pendingCreate.reduce((map, it) => {
      map.set(it.pendingId, it)
      return map
    }, new Map())
  },
  hasPendingTags(state) {
    return state.pendingCreate.length || state.pendingDelete.length || state.pendingEdit.length
  }
}

const actions = {
  clearPendingState({ commit, state }: Context) {
    // save dispatches (savePendingTags) will invoke clear state on completion
    if (state.isSaving) return
    commit(Mutations.CLEAR_STATE)
  },
  setIsSaving({ commit }: Context, isSaving: boolean) {
    commit(Mutations.SET_IS_SAVING, isSaving)
  },
  addToCreate({ commit, state, dispatch, getters }: Context, dtoList: TagToAdd[]) {
    const listWithEntityId = dtoList.flatMap(it => {
      return state.entityId && !getters.pendingTagIdsMap.has(it.pendingId)
        ? { ...it, targetEntity: state.entityId }
        : []
    })
    commit(Mutations.ADD_TO_CREATE, listWithEntityId)
    dispatch('tagEditor/addToTableData', listWithEntityId, { root: true })
  },
  addToEdit({ commit, dispatch }: Context, dtoList: TagToEdit[]) {
    commit(Mutations.ADD_TO_EDIT, dtoList)
    dispatch('tagEditor/updateTableData', dtoList, { root: true })
  },
  addToDelete({ commit, dispatch }: Context, idList: Array<number|string>) {
    const filteredList = idList
      .filter((it: number|string) => typeof it === "number") as number[]
    const pendingIds = idList
      .filter((it: number|string) => typeof it === "string") as string[]
    commit(Mutations.ADD_TO_DELETE, filteredList)
    pendingIds.forEach(id => {
      commit(Mutations.REMOVE_FROM_CREATE, { pendingId: id })
    })
    dispatch('tagEditor/removeFromTableByIdSet', 
      new Set([ ...idList ]), { root: true })
  },
  removePending({ commit, dispatch }: Context, dto: TagToAdd) {
    commit(Mutations.REMOVE_FROM_CREATE, dto)
    dispatch('tagEditor/removeFromTableByPendingId', 
      dto.pendingId, { root: true })
  },
  async savePendingTags({ commit, getters }: Context, id: number) {
    if (!getters.hasPendingTags) return []

    commit(Mutations.SET_IS_SAVING, true)
    let tags
    try {
      tags = await savePendingTags(id, getters.tagUpdatesMap)
      commit(Mutations.CLEAR_STATE)
    } catch {
      // Error toast
      tags = []
    } finally {
      commit(Mutations.SET_IS_SAVING, false)
      return tags
    }
  },
  setEntityId({ commit }: Context, id: number) {
    commit(Mutations.SET_ENTITY_ID, id)
  }
}

const mutations: MutationTree <PendingTagsState> = {
  [Mutations.CLEAR_STATE](state) {
    state.pendingCreate = []
    state.pendingDelete = []
    state.pendingEdit = []
    state.entityId = null
    state.isSaving = false
  },
  [Mutations.ADD_TO_CREATE](state, dto: TagToAdd) {
    state.pendingCreate = state.pendingCreate.concat(dto)
  },
  [Mutations.ADD_TO_EDIT](state, dtoList: TagToEdit[]) {
    state.pendingEdit = dtoList
  },
  [Mutations.ADD_TO_DELETE](state, idList: number[]) {
    state.pendingDelete = idList
  },
  [Mutations.REMOVE_FROM_CREATE] (state, dto: TagToAdd) {
    state.pendingCreate = state.pendingCreate
      .filter(it => it.pendingId !== dto.pendingId)
  },
  [Mutations.SET_ENTITY_ID] (state, id: number) {
    state.entityId = id
  }, 
  [Mutations.SET_IS_SAVING] (state, isSaving: boolean) {
    state.isSaving = isSaving
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}