import http from '@/http';

export const getErpServices = async () => {
  return http.get('/erp-services').then((res) => res.data);
};

export const getTenantErpSystem = async () => {
  return http.get('/tenant-erp-system').then((res) => res.data);
};

export const getOrgErpSystem = async (orgId) => {
  return http.get(`/org-erp-system/org/${orgId}`).then((res) => res.data);
};

export const createOrgErpSystem = async ({ orgId, payload, enableTenantErp }) => {
  return http.post(`/org-erp-system/org?orgId=${orgId}&enableTenantErp=${enableTenantErp}`, payload)
    .then((res) => res.data);
};

/**
 *
 * @param {string|number} orgId the organization id
 * @param {boolean} enableTenantErp
 * @param {object|null} payload do not send payload if disabling org ERP
 * @return {Promise}
 */
export const updateOrgErpSystem = async ({orgId, enableTenantErp, payload}) => {
  return http.put(`/org-erp-system/${orgId}/${enableTenantErp}`, payload, {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then((res) => res.data);
};
