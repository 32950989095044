import i18n from '@/locales'
export const COL_OPTIONS = [
  {
    text: i18n.global.t('itemAlt'),
    value: 0
  },
  {
    text: i18n.global.t('partNumber'),
    value: 1
  },
  {
    text: i18n.global.t('partName'),
    value: 2
  },
  {
    text: i18n.global.t('quantity'),
    value: 3
  },
  {
    text: i18n.global.t('uom'),
    value: 4
  },
  {
    text: i18n.global.t('retailPrice'),
    value: 5
  },
  {
    text: i18n.global.t('wholesalePrice'),
    value: 6
  },
  {
    text: i18n.global.t('discountedPrice'),
    value: 7
  },
  {
    text: i18n.global.tc('supplier', 1),
    value: 8
  },
  {
    text: i18n.global.t('retailTotal'),
    value: 9
  },
  {
    text: i18n.global.t('wholesaleTotal'),
    value: 10
  },
  {
    text: i18n.global.t('discountedTotal'),
    value: 11
  },
  {
    text: i18n.global.t('variantSKU'),
    value: 12
  }
]
