import bowser, {check} from 'bowser'
import { loadAnalytics } from '@/controllers/global'
import { checkSubDomain } from "@/helpers"
import { getRoute } from "@/router/getRoute"

export default {
  namespaced: true,
  state: {
    isIE: false,
    analyticsLoaded: false,
    analyticsPending: false,
    subdomain: ''
  },
  actions: {
    checkBrowser (context) {
      context.commit('IS_IE', bowser.check({ msie: '11' }, true))
    },
    async checkSubdomain (context) {
      const subdomain = await checkSubDomain()
      if (subdomain) {
        await context.commit('SET_SUBDOMAIN', subdomain)
      }
    },
    async initializeAnalytics (context) {
      try {
        context.commit('ANALYTICS_PENDING', true)
        const route = getRoute()
        await loadAnalytics({
          tk: context.state.subdomain || route.query?.tk || context.rootState.user.tenantKey,
          ok: route.query?.ok
        })
        context.commit('ANALYTICS_LOADED', true)
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      } finally {
        context.commit('ANALYTICS_PENDING', false)
      }
    }
  },
  mutations: {
    'IS_IE' (state, payload) {
      state.isIE = payload
    },
    'ANALYTICS_PENDING' (state, payload) {
      state.analyticsPending = payload
    },
    'ANALYTICS_LOADED' (state, payload) {
      state.analyticsLoaded = payload
    },
    'SET_SUBDOMAIN' (state, payload) {
      state.subdomain = payload
    },
  }

}
