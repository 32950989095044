import { asyncFetcher } from '@/store'
import http from '@/http'
import i18n from '@/locales'
import { postError } from '@/helpers/notification'

const SAVED_CARTS_RECEIVED = 'SAVED_CARTS_RECEIVED'
const SAVED_CARTS_LOADING = 'SAVED_CARTS_LOADING'

const state = {
  isLoaded: false,
  items: []
}

const actions = {
  async fetchAll ({ commit, rootGetters }) {
    try {
      if (rootGetters['user/isSavedCartEnabled']) {
        commit(SAVED_CARTS_LOADING)
        const { data } = await http.get('cart/saved')
        commit(SAVED_CARTS_RECEIVED, { saved: data })
      } else {
        commit(SAVED_CARTS_RECEIVED, { saved: [] })
      }
    } catch (err) {
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('failedAction', {
          content: i18n.global.tc('cart', 2),
          action: i18n.global.t('actionLoad').toLocaleLowerCase()
        }),
      })
    }
  },
  async save ({ rootGetters, dispatch }, { name }) {
    try {
      if (rootGetters['user/isSavedCartEnabled']) {
        await asyncFetcher(`cart/saved/async/${name}`, null, null,
          'put', 'cart/async-poll', 'cartDto', 1000,
          1000, 600000, (message) => {
            postError({
              title: i18n.global.t('error'),
              text: message,
            })
          })
      }
    } catch (err) {
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('failedAction', {
          content: i18n.global.tc('cart', 1),
          action: i18n.global.t('save').toLocaleLowerCase()
        }),
      })
    } finally {
      dispatch('fetchAll')
    }
  },
  async delete ({ rootGetters, dispatch }, { cartId }) {
    try {
      if (rootGetters['user/isSavedCartEnabled']) {
        await http.delete(`cart/saved/${cartId}`)
        dispatch('fetchAll')
      }
    } catch (err) {
      console.log('error :>> ', err)
    }
  }
}

const mutations = {
  [SAVED_CARTS_LOADING] (state) {
    state.isLoaded = false
  },
  [SAVED_CARTS_RECEIVED] (state, { saved }) {
    state.items = saved
    state.isLoaded = true
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
