/**
 * @deprecated
 * Previous notification implementation; consider moving to
 * notification.ts
 *
 * @type {{errorNotification(null=): void}}
 */
export const errorNotification = {
  errorNotification(textTr = null) {
    const error = {
      title: this.$t('error'),
      type: 'error',
      duration: 10000
    }
    if (textTr) error.text = textTr
    this.$notify(error)
  }
}

/**
 * @deprecated
 * Previous notification implementation; consider moving to
 * notification.ts
 *
 * @type {{errorNotification(null=): void}}
 */
export const successNotification = {
  successNotification(title, text = "") {
    const success = {
      title: title,
      type: 'success',
      duration: 5000
    }
    if (!!text) success.text = text
    this.$notify(success)
  }
}
