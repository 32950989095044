<template>
  <section v-if="tocLoaded"
           class="section">
    <div class="columns is-mobile is-multiline">
      <div v-for="child in children"
           :key="child.id"
           class="column is-2-desktop is-3-tablet is-4-mobile">
        <card :item="child"
              :path="path"
              @click="select"/>
      </div>
    </div>
  </section>
  <section v-else class="loading">
    <loading-icon />
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import Card from './NavigatorCard';
import { path } from '@/router/compositionObjects';

export default {
  name: 'NavigatorViewer',
  components: {
    Card,
    LoadingIcon
  },
  computed: {
    ...mapState({
      tocLoaded: ({ content }) => content.toc.isLoaded
    }),
    ...mapGetters({
      children: 'content/tocChildren'
    }),
    ...path
  },
  methods: {
    ...mapActions({
      navigateToContentPath: 'content/navigateToContentPath'
    }),
    select(item) {
      this.navigateToContentPath({
        path: `${this.path}/${item.type}:${item.id}`
      });
    }
  }
};
</script>

<style scoped>
.section {
  padding: 1rem;
  height: 100%;
  overflow: auto;
}
</style>
