<template>
  <widget-media-controls-header />
  <component  :is="componentInView"
              :style="componentStyle" />
</template>

<script setup>
import { computed, onMounted } from "vue"
import WidgetMediaViewer from "../components/WidgetMediaViewer"
import WidgetChapter from "../components/WidgetChapter"
import WidgetPage from "../components/WidgetPage"
import { useWidgetNavigator } from "@/components/widgets/composables/useWidgetNavigator"
import { useOpenToContent } from "@/components/widgets/composables/useOpenToContent"
import { useInfoLandingPage } from "@/components/widgets/composables/useInfoLandingPage"
import { MediaWidgetEntityTypes,
  LibraryWidgetPages } from "@/interfaces/global/widgets"
import WidgetMediaControlsHeader from "@/components/widgets/components/WidgetMediaControlsHeader"
import WidgetInfoPage from "@/components/widgets/components/WidgetInfoPage"

const { getComponentInViewFromMapping } = useWidgetNavigator()
const { hasInfoLandingPage } = useInfoLandingPage()
const { setLandingPage } = useOpenToContent()

const ComponentViews = Object.freeze({
  [MediaWidgetEntityTypes.media]: WidgetMediaViewer,
  [MediaWidgetEntityTypes.chapter]: WidgetChapter,
  [MediaWidgetEntityTypes.page]: WidgetPage,
  [LibraryWidgetPages.info]: WidgetInfoPage
});
const componentInView = computed(getComponentInViewFromMapping(ComponentViews))
const componentStyle = computed(() => {
  const maxHeight = hasInfoLandingPage.value ? '75%;' : '90%;'
  return `max-height: ${maxHeight} ` +
    'min-height: 50%;'
})

onMounted(async () => {
  await setLandingPage()
});
</script>
