import { onBeforeUnmount, onMounted, Ref, ref } from 'vue'

export interface InteractivePropOptions {
  debug?: boolean
  debugElement?: HTMLElement|null
  imageScale: Ref<number>
  isPanning: Ref<boolean>
  panPosition: Ref<{ x: number; y: number }>
  previewContainerId: string
  scaleFactor?: number
}

export class InteractiveProps {
  debug: boolean
  debugElement: HTMLElement|null
  imageScale: Ref<number>
  isPanning: Ref<boolean>
  panPosition: Ref<{ x: number; y: number }>
  previewContainerId: string
  scaleFactor: number

  constructor ({
    debug = false,
    debugElement = null,
    imageScale,
    isPanning,
    panPosition,
    previewContainerId,
    scaleFactor = 0.25
  }: InteractivePropOptions) {
    this.debug = debug
    this.debugElement = debugElement
    this.imageScale = imageScale
    this.isPanning = isPanning
    this.panPosition = panPosition
    this.previewContainerId = previewContainerId
    this.scaleFactor = scaleFactor
  }
}

/**
 * Supports desktop panning and zooming. Supports touch panning. Returns zoom functions for
 * any callable actions/buttons. Pinch-to-zoom has been dropped. This may be possible, but
 * it has been a challenge to programmatically find a path forward to stop the browser
 * window defaults.
 *
 * Consider CSS for 'user-select' to prevent highlighting. And '-webkit-user-drag' to prevent
 * dragging of the image -- different from interactive gestures.
 *
 * @param props
 */
export function useInteractiveEventListeners (props: InteractiveProps) {
  const debugLog = (...messages: string[]) => {
    if (props.debug) {
      console.log(...messages)
    }
  }
  const panStartPoint = ref({ x: 0, y: 0 }) // when panning begins

// desktop event listeners
  const panStartEventListener = (e: MouseEvent) => {
    debugLog('Desktop pan start')
    document.body.style.cursor = 'grabbing'
    props.isPanning.value = true
    panStartPoint.value = {
      x: e.clientX - (props.panPosition.value.x * props.imageScale.value),
      y: e.clientY - (props.panPosition.value.y * props.imageScale.value),
    }
  }
  const panEndEventListener = () => {
    document.body.style.cursor = ''
    props.isPanning.value = false
    debugLog('Desktop end')
  }
  const panEventListener = (e: MouseEvent) => {
    if (props.isPanning.value) {
      debugLog('Desktop panning')
      props.panPosition.value = {
        x: (e.clientX - panStartPoint.value.x) / props.imageScale.value,
        y: (e.clientY - panStartPoint.value.y) / props.imageScale.value
      }
    }
  }
  const resetEventListener = () => {
    debugLog('Double click reset')
    resetImage()
  }
  const wheelEventListener = (event: WheelEvent) => {
    debugLog('Wheel event')
    event.preventDefault()
    if (event.deltaY < 0) {
      zoomIn()
    } else {
      zoomOut()
    }
  }
  // end of desktop event listeners



  // touch event listeners
  let lastTapTime: number = 0
  const touchStartEventListener = (e: TouchEvent) => {
    function recordTap () {
      // support for double-tap
      const currentTime = new Date().getTime()

      const elapsedTime = currentTime - lastTapTime
      if (elapsedTime < 300) {
        resetImage()
        debugLog('Double tap reset')
      } else {
        debugLog('Single tap')
      }

      lastTapTime = currentTime
    }

    function startPan () {
      props.isPanning.value = true
      const touch = e.touches[0]
      debugLog('Touch start pan')

      panStartPoint.value = {
        x: touch.clientX - (props.panPosition.value.x * props.imageScale.value),
        y: touch.clientY - (props.panPosition.value.y * props.imageScale.value),
      }
    }

    switch (e.touches.length) {
      case 1: {
        recordTap()
        startPan()
        break
      }
      default: {
        // no defined behaviors
      }
    }
  }
  const touchEndEventListener = () => {
    props.isPanning.value = false
    debugLog('Touch end')
  }
  const touchMoveEventListener = (e: TouchEvent) => {
    function pan() {
      const touch = e.touches[0]
      debugLog('Touch panning')
      props.panPosition.value = {
        x: (touch.clientX - panStartPoint.value.x) / props.imageScale.value,
        y: (touch.clientY - panStartPoint.value.y) / props.imageScale.value
      }
    }

    if (props.isPanning.value) {
      switch (e.touches.length) {
        case 1: {
          pan()
          break
        }
        default: {
          // no defined behaviors
        }
      }
    }
  }
  // end of touch event listeners

  const resetImage = () => {
    props.imageScale.value = 1.00
    props.panPosition.value = { x: 0, y: 0 }
  }
  const zoomIn = () => {
    if (props.imageScale.value >= 50.00) return
    props.imageScale.value += props.scaleFactor
  }
  const zoomOut = () => {
    if (props.imageScale.value <= props.scaleFactor) return
    props.imageScale.value -= props.scaleFactor
  }

  onMounted(() => {
    // desktop
    document.addEventListener('mousemove', panEventListener)
    document.addEventListener('mouseup', panEndEventListener)
    // touch
    document.addEventListener('touchmove', touchMoveEventListener)
    document.addEventListener('touchend', touchEndEventListener)

    const containerElement = document.getElementById(props.previewContainerId)
    if (!!containerElement) {
      // desktop
      containerElement.addEventListener('dblclick', resetEventListener)
      containerElement.addEventListener('mousedown', panStartEventListener)
      containerElement.addEventListener('wheel', wheelEventListener)
      // touch
      containerElement.addEventListener('touchstart', touchStartEventListener)
    }
  })

  onBeforeUnmount(() => {
    // desktop
    document.removeEventListener('mousemove', panEventListener)
    document.removeEventListener('mouseup', panEndEventListener)
    // touch
    document.removeEventListener('touchmove', touchMoveEventListener)
    document.removeEventListener('touchend', touchEndEventListener)

    const containerElement = document.getElementById(props.previewContainerId)
    if (!!containerElement) {
      // desktop
      containerElement.removeEventListener('dblclick', resetEventListener)
      containerElement.removeEventListener('mousedown', panStartEventListener)
      containerElement.removeEventListener('wheel', wheelEventListener)
      // touch
      containerElement.removeEventListener('touchstart', touchStartEventListener)
    }
  })

  return {
    zoomIn,
    zoomOut
  }
}
