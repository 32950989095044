import { DraftImage } from '@/interfaces/admin'
import http from '@/http'

export async function updateDraftImages (imgFile: DraftImage, pageId: any, updateDraftPageVersion: boolean = true): Promise<DraftImage> {
  const { data } = await http.put(`/admin/draft-sheets/${pageId}`, JSON.stringify(imgFile), {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*'
    },
    params: {
      updateDraftPageVersion: updateDraftPageVersion
    }
  })
  return data
}
