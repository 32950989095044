import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"
import { v4 as uuid } from 'uuid'
import { ActionContext, GetterTree } from 'vuex'
import { Hotpoint } from '@/interfaces/admin/hotpoint'
import {
  deleteDraftSheet,
  getDraftSheets,
  putSheetOrderBy,
  saveDraftImage,
  standardizeImage,
  superImposeImages,
  updateDraftImages
} from '@/controllers/admin/draftPage'
import {
  DraftImage,
  DraftImageStore,
  FontAlignment,
  Fonts,
  FontSizes,
  FontStylings,
  ImageEditorAttributes,
  ImageEditorAttributesDTO,
  LineAttributesDTO,
  LineAttributes,
  opacityRange,
  Shapes,
  ShapeAttributes,
  ShapeAttributesDTO
} from '@/interfaces/admin'
import hotpointActions from '@/helpers/hotpointActions'
import { cloneDeep } from "lodash"
import { postError } from '@/helpers/notification'

type Context = ActionContext<DraftImageStore, any>

const state: DraftImageStore = {
  font: Fonts['times new roman'],
  fontAlignment: FontAlignment['align-left'],
  fontBold: false,
  fontColor: '',
  fontItalic: false,
  fontSize: 10,
  fontUnderline: false,
  autoHotpointing: [],
  images: [],
  isDirty: false,
  loading: false,
  lineColor: '',
  lineStroke: 12,
  opacity: 100,
  originalRadius: null,
  pageId: '',
  selected: null,
  selectedHotpointImg: null,
  shape: Shapes.rectangle,
  shapeBorderColor: '',
  shapeFillColor: '#ffffff',
  shapeStroke: 3,
  softDeletedImages: [],
  saving: false,
}

const getters: GetterTree<DraftImageStore, any> = {
  isFontDefault: (state: DraftImageStore) => {
    return !state.fontBold && !state.fontItalic && !state.fontUnderline
  },
  getDraftImages: (state: DraftImageStore) => state.images,
  getImageOrderArray: (state: DraftImageStore) => {
    return state.images.filter(img => img.imageUrl)
      .map((image: DraftImage, index: number | string) => {
        return [image.id, index]
      })
  },
  getSaveArray: (state: DraftImageStore) => {
    return state.images.map((image: DraftImage, index: number) => {
      return {
        ...image,
        displayOrder: index
      }
    })
  },
  getSaveArrayData: (state: DraftImageStore) => {
    return state.images.map((image: DraftImage, index: number) => {
      return {
        ...image,
        displayOrder: index
      }
    }).filter((img: DraftImage) => img.data || img.svgElement)
  },
  getSelectedFontStylings: (state: DraftImageStore) => {
    const list = []
    if (state.fontBold) list.push(FontStylings.bold)
    if (state.fontItalic) list.push(FontStylings.italic)
    if (state.fontUnderline) list.push(FontStylings.underline)
    return list
  },
  getSaveState: (state: DraftImageStore) => {
    return state.saving
  },
  getOriginalRadius: (state: DraftImageStore) => {
    return state.originalRadius
  },
  getHotpoints: (state: DraftImageStore) => (pageIndex: number):  Hotpoint[] | undefined => {
    if (!state.images.length) return undefined
    return state.images.find(img => img.displayOrder === pageIndex)!.imageHotPoints
  },
  mapHotpointsExisting: (state: DraftImageStore) => {
    return state.images.map((image) => {
      if (image.imageHotPoints && image.imageHotPoints.length > 0) {
        return image.imageHotPoints.map((hotpoint, index) => {
          return hotpoint
        })
      } else { return null }
    })
  }
}

const actions = {
  addNewImage ({ commit, state }: Context, insertionIdx: number) {
    const image = state.images[insertionIdx]
    if (!image || image.imageUrl || image.streamData) {
      const newImage: DraftImage = {
        imageFileName: '',
        data: null,
        refKey: uuid(),
        displayOrder: insertionIdx
      }
      commit('addImage', newImage)
      return newImage
    }
  },
  removeTempImgHolder ({ state, commit}: Context) {
    const images = state.images.filter((img: DraftImage) => img.imageUrl || img.streamData)
    commit('setImages', { images: images })
  },
  deleteSelectedHotpoints (context: Context, data: { imageIndex: number, hotpoints: Array<{id: number, oId: number, text: string }> }) {
    for (let i = 0; i < data.hotpoints.length; i++) {
      context.commit('deleteHotpoints', { id: data.hotpoints[i].id, text: data.hotpoints[i].text, imageIndex: data.imageIndex })
    }
  },
  dataURLtoFile (context: Context, data: { dataurl: any, filename: string }) {
    let arr = data.dataurl.split(','),
      bstr = atob(arr[1]),
      name = data.filename.split('.').shift() + '.png',
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], name, {type: 'png'});
  },
  async fetchDraftSheets (context: Context) {
    context.commit('setLoading', true)
    try {
      const images = await getDraftSheets(context.state.pageId)
      if (images && images.length > 0) {
        let newImages, originalRadius
        [ newImages, originalRadius ] = await hotpointActions.configureImages(images)
        context.commit('saveOriginalRadius', originalRadius)
        await context.dispatch('sortImagesAndSet', newImages)

        if (!!state.selectedHotpointImg) {
          const displayOrder = state.selectedHotpointImg.displayOrder!!
          await context.dispatch('setHotpointSelectedImage', state.images[displayOrder])
        }
      } else {
        context.commit('setImages', { images: [] })
      }
    } catch (error) {
      await context.dispatch('notifications', { type: 'error', error: error as Error })
    }
    context.commit('setLoading', false)
  },
  async hardDeleteImages ({ state, commit, dispatch }: Context) {
    if (state.softDeletedImages!.length > 0) {
      for (let i = 0; i < state.softDeletedImages!.length; i++) {
        if (state.softDeletedImages![i].id) {
          try {
            await deleteDraftSheet(state.softDeletedImages![i].id)
          } catch (error) {
            await dispatch('notifications', { type: 'error', error: error as Error })
          }
        }
      }
      commit('resetDeletedImages')
    }
  },
  notifications (context: Context, data: { type: string, message?: string, error?: Error }) {
    if (data.type === 'success') {
      notify({
        title: i18n.global.t('success'),
        text: i18n.global.t('draftPageSaveSuccess'),
        type: 'success',
        duration: 5000
      })
    } else {
      //@ts-ignore
      postError({
        title: i18n.global.t('error'),
        text: data.error!.message ? data.error!.message : data.message,
      })
    }
  },
  async pushHotpointToImage (context: Context, payload: { selectedImage: DraftImage, hotpoint: Hotpoint }) {
    let index
    context.state.images.forEach((image: DraftImage, i) => {
      if (image.id === payload.selectedImage.id) index = i
    })
    if (index || index === 0) context.commit('updateHotpointByImageIndex', { hotpoint: payload.hotpoint, index: index })
  },
  removeAllImages (context: Context) {
    context.commit('removeImages')
  },
  resetUploadedImage (context: Context) {
    context.commit('resetUploadedImage')
  },
  setImageEditorAttribute ({ commit }: Context, payload: ImageEditorAttributesDTO) {
    // Dynamically use action to mutate either colorHex or stroke
    const mutationsMap = Object.freeze({
      [ImageEditorAttributes.font]: 'setFont',
      [ImageEditorAttributes.fontAlignment]: 'setFontAlignment',
      [ImageEditorAttributes.fontBold]: 'setFontBold',
      [ImageEditorAttributes.fontColor]: 'setFontColor',
      [ImageEditorAttributes.fontItalic]: 'setFontItalic',
      [ImageEditorAttributes.fontSize]: 'setFontSize',
      [ImageEditorAttributes.fontUnderline]: 'setFontUnderline',
      [ImageEditorAttributes.lineColor]: 'setLineColor',
      [ImageEditorAttributes.lineStroke]: 'setLineStroke',
      [ImageEditorAttributes.opacity]: 'setShapeOpacity',
      [ImageEditorAttributes.shapeBorderColor]: 'setShapeBorderColor',
      [ImageEditorAttributes.shapeFillColor]: 'setShapeFillColor',
      [ImageEditorAttributes.shapeStroke]: 'setShapeStroke',
      [ImageEditorAttributes.shape]: 'setShape'
    })
    if (!mutationsMap[payload.type]) return
    commit(mutationsMap[payload.type], payload.value)
  },
  reorderImages (context: Context, event: any) {
    const images = cloneDeep(context.state.images)
    const movedImage = images.splice(event.oldIndex, 1)
    images.splice(event.newIndex, 0, movedImage[0])
    context.commit('setImages', { images: images })
  },
  async saveImages (context: Context, saveArray: Array<DraftImage>) {
    let error
    for (let index = 0; index < saveArray.length; index++) {
      try {
        let image
        if(saveArray[index].svgElement) {
          image = await context.dispatch('superImposeImages', { svg: saveArray[index].svgElement, selectedImage: saveArray[index] })
        } else {
          image = await saveDraftImage(saveArray[index].data as File, state.pageId, saveArray[index].width!, saveArray[index].height!)
        }
        //  If previous saves fail, set to original image
        if(!image) {
          error = true
          image = saveArray[index]
        }
        await context.commit('updateImgByIndex', { image, index: saveArray[index].displayOrder })
        if(error) {
          throw new Error()
        }
      } catch (error: unknown) {
        await context.dispatch('notifications', { type: 'error', error: { message: saveArray[index].imageFileName + ' ' + i18n.global.t('imageSaveFail') }})
        throw new Error()
      }
    }
  },
  async setCleanState ({ dispatch, state }: Context) {
    await dispatch('fetchDraftSheets')
  },
  setLineAttribute ({ commit }: Context, payload: LineAttributesDTO) {
    // Dynamically use action to mutate either colorHex or stroke
    const mutationsMap = Object.freeze({
      [LineAttributes.lineColor]: 'setLineColor',
      [LineAttributes.lineStroke]: 'setLineStroke'
    })
    if (!mutationsMap[payload.type]) return
    commit(mutationsMap[payload.type], payload.value)
  },
  setPageId ({ commit, dispatch }: Context, pageId: string) {
    if (!pageId) {
      dispatch('notifications', { type: 'error', error: i18n.global.t('errorPageMessage') })
    } else {
      commit('setPage', pageId)
    }
  },
  setShapeAttribute ({ commit }: Context, payload: ShapeAttributesDTO) {
    // Dynamically use action to mutate either colorHex or stroke
    const mutationsMap = Object.freeze({
      [ShapeAttributes.opacity]: 'setShapeOpacity',
      [ShapeAttributes.shapeBorderColor]: 'setShapeBorderColor',
      [ShapeAttributes.shapeFillColor]: 'setShapeFillColor',
      [ShapeAttributes.shapeStroke]: 'setShapeStroke',
      [ShapeAttributes.shape]: 'setShape'
    })
    if (!mutationsMap[payload.type]) return
    commit(mutationsMap[payload.type], payload.value)
  },
  softDeleteImage ({ state, commit }: Context, index: number) {
    commit('softDeletion', state.images[index])
    commit('removeImage', index)
  },
  sortImagesAndSet (context: Context, images: DraftImage[]) {
    const sortedImages = images.sort((a: DraftImage, b: DraftImage) => {
      return ((a.displayOrder as number) > (b.displayOrder as number)) ? 1 : -1
    })
    context.commit('setImages', { images: sortedImages })
  },
  async createStandardizedImg ({dispatch, commit }: Context, image: File) {
    try{
      commit('setSaving', true)
      const updatedImage = await standardizeImage(image)
      const newImage = new Image()
      newImage.src = 'data:image/png;base64,' + updatedImage
      if(image.type !== 'image/svg+xml') {
        //  tiff image file data will be overwritten with new png img File
        //  svg images will send their original image file data to save API
        const file = await dispatch('dataURLtoFile', { dataurl: newImage.src, filename: image.name })
        await dispatch('updateImageFileData', file)
      }
      commit('setSaving', false)
      return {image: newImage.src, width: newImage.width, height: newImage.height}
    } catch(e) {
      await dispatch('notifications', { type: 'error', error: { message: i18n.global.t('imageUploadFailure') } })
      commit('setSaving', false)
      throw new Error()
    }
  },
  saveSvgLayerToSheet (context: Context, data: { image: DraftImage, svg: SVGElement }) {
    let index
    try{
      context.state.images.forEach((img, i) => {
        if(data.image.refKey === img.refKey) {
          index = i
        }
      })
      context.commit('saveSvgLayer', { index: index, svg: data.svg })
    } catch(e) {
      console.log(e)
    }
  },
  async superImposeImages (context: Context, data: any) {
    context.commit('setSaving', true)
    try{
      const serializedSVG = new XMLSerializer().serializeToString(data.svg)
      const base64Data = window.btoa(serializedSVG)
      const encodeUri = encodeURIComponent(base64Data)
      const updatedImage = await superImposeImages({
          file: data.selectedImage.data as File,
          name: data.selectedImage.imageFileName
        },
        encodeUri,
        Number(context.state.pageId),
        Number(data.selectedImage.id))
     return updatedImage
    } catch(e) {
      await context.dispatch('notifications', { type: 'error', error: { message: i18n.global.t('draftPageSaveUnsuccessful') } })
      context.commit('setSaving', false)
      return null
    }
  },
  //  This update is called from the image editor tab
  async updateAll (context: Context) {
    context.commit('setSaving', true)
    try {
      await context.dispatch('hardDeleteImages')
      const saveArray = context.getters.getSaveArrayData
      if (saveArray.length > 0) {
        await context.dispatch('saveImages', saveArray)
      }
      await putSheetOrderBy(state.pageId, context.getters.getImageOrderArray)
      if (context.state.selected) {

        const img = context.state.images[context.state.selected!.displayOrder!]

        await context.commit('setSelectedImage', img)
        await context.commit('setSelectedHotpointImg', context.state.images[0])
      }
      await context.dispatch('notifications', { type: 'success' })
    } catch (error) {
      await context.dispatch('notifications', { type: 'error', error: { message: i18n.global.t('draftPageSaveUnsuccessful') } })
      context.commit('setSaving', false)
      throw new Error()
    }
    context.commit('setSaving', false)
  },
  async updateAllHotpoints (context: Context, data: {hotpoints: Hotpoint[], index: number, autoHotpointing: Boolean}) {
    if(data.autoHotpointing) {
      context.commit('setAutoHotpointing', {autoHotpointing: data.autoHotpointing, imageIndex: data.index })
    }
    context.commit('replaceHotpointsObject', {hotpoints: data.hotpoints, index: data.index})
  },
  updateDataStream ({ state, commit }: Context, data: { streamData: ReadableStream, imageFileName: string, width: number, height: number }) {
    commit('updateImageStreamData', data)
  },
  //  This update is called from hotpoints editor tab. Saves all the new hotpoint data for all images
  async updateDraftImages ({ state, rootState, dispatch, commit, getters }: Context) {
    commit('setSaving', true)
    let draftSheets = cloneDeep([ ...rootState?.draftPage?.draftSheets ])
    draftSheets = await dispatch('updateImagesByDisplayOrderProp', draftSheets)
    try {
      for (let i = 0; i < state.images.length; i++) {
        if(state.images[i].imageHotPoints && state.images[i].imageHotPoints!.length > 0) {
          let hotpointsToSave = state.autoHotpointing[i]? await dispatch('stripIdsFromAutoHotpoints', i) : state.images[i].imageHotPoints
          draftSheets[i].imageHotPoints = await dispatch('stripIdsFromHotpoints', hotpointsToSave)
        } else {
          delete draftSheets[i].imageHotPoints
        }
        const updatedDraft = await updateDraftImages(draftSheets[i], draftSheets[i].id, (i === (state.images.length - 1))? true : false)
        //  Ensure the return mapping is correct as calls may be coming back out of order
        let index = state.images.findIndex((img, i) => img.id === updatedDraft.id)
        const hotpoints = (updatedDraft.imageHotPoints && updatedDraft.imageHotPoints.length > 0)
          ? await hotpointActions.setNewIds(updatedDraft.imageHotPoints!)
          : null

        if (hotpoints) {
          await dispatch('updateAllHotpoints', {hotpoints: hotpoints, index: index, autoHotpointing: false})
        }
      }
      await dispatch('notifications', { type: 'success' })
    } catch (e) {
      await dispatch('notifications', { type: 'error', error: { message: i18n.global.t('draftPageSaveUnsuccessful') } })
    }
    commit('setSaving', false)
  },
  async updateImagesByDisplayOrderProp (context: Context, images: Array<DraftImage>) {
    let newOrder:DraftImage[]  = []
    images.forEach((image, index) => {
      newOrder[Number(image.displayOrder!)] = image
    })
    return newOrder
  },
  async stripIdsFromHotpoints(context: Context, hotpoints: Array<Hotpoint>) {
    let newArray: Array<Hotpoint> = []
    //  unbind store instance reference
    let oldArray = cloneDeep(hotpoints)
    oldArray.forEach((hotpoint: Hotpoint) => {
      if(hotpoint.newHotpoint && hotpoint.id) {
        delete hotpoint.id
      }
      if(hotpoint.oId && hotpoint.id) {
        hotpoint.id = hotpoint.oId
        delete hotpoint.oId
      }
      newArray.push(hotpoint)
    })
    return newArray
  },
  stripIdsFromAutoHotpoints({ state, commit }: Context, imageIndex: number) {
    commit('setAutoHotpointing', { autoHotpointing: false, imageIndex: imageIndex })
    return state.images[imageIndex]!.imageHotPoints!.map((hotpoint: Hotpoint) => {
      return {
        draftSheetId: hotpoint.draftSheetId,
        shapePositionX: hotpoint.shapePositionX,
        shapePositionY: hotpoint.shapePositionY,
        shapeRadiusX: hotpoint.shapeRadiusX,
        shapeRadiusY: hotpoint.shapeRadiusY,
        text: hotpoint.text,
        fillOpacity: hotpoint.fillOpacity
      }
    })
  },
  updateHotpointCoordinates (context: Context, data: { hotpoints: Array<any>, image: DraftImage }) {
    const imageHotpoints = context.state.images[Number(data.image.displayOrder)].imageHotPoints
    data.hotpoints.forEach(point => {
      const i = imageHotpoints!.findIndex(x => {
        return x.shapePositionX === Number(point.origX) && x.shapePositionY === Number(point.origY)
      })
      context.commit('updateCoordinates', { hotpoint: point, index: i, image: data.image })
    })
  },
  updateHotpointRadius (context: Context, data: { hotpointRadius: Array<Number>, hasStaticRadius: Boolean }) {
    const images = context.state.images
    for (let i = 0; i < data.hotpointRadius.length; i++) {
      if (data.hotpointRadius[i]) {
        let radius
        if (data.hasStaticRadius)  {
          radius = context.getters.getOriginalRadius[i]? context.getters.getOriginalRadius[i] : ( data.hotpointRadius[i] || 15 )
        } else {
          radius = data.hotpointRadius[i] || 15
        }
        for (let x = 0; x < images[i].imageHotPoints!.length; x++) {
          if(images[i].imageHotPoints && images[i].imageHotPoints!.length < 1 || !images[i].imageHotPoints) {
            context.commit('removeOriginalRadiusAtIndex', i)
          }
          context.commit('updateRadius', { radius: radius, index: x, imageIndex: i })
        }
      }
    }
  },
  updateImageFileData ({ state, commit }: Context, data: File) {
    commit('updateImageFileData', data)
  },
  updateFontStyling ({ dispatch, state }: Context, value: string) {
    const payload = value === FontStylings.bold
      ? { type: ImageEditorAttributes.fontBold, value: !state.fontBold }
      : value === FontStylings.italic
        ? { type: ImageEditorAttributes.fontItalic, value: !state.fontItalic }
        : value === FontStylings.underline
          ? { type: ImageEditorAttributes.fontUnderline, value: !state.fontUnderline }
          : null
    if (!payload) return
    dispatch('setImageEditorAttribute', payload)
  },
  setIsDirty ({ commit }: Context, value: boolean) {
    commit('setIsDirty', value)
  },
  setSelectedImage ({ commit }: Context, value: DraftImage) {
    commit('setSelectedImage', value)
  },
  setHotpointSelectedImage ({ commit }: Context, value: DraftImage) {
    commit('setSelectedHotpointImg', value)
  }
}

const mutations = {
  saveSvgLayer(state: DraftImageStore, data: { index: number, svg: SVGElement }) {
    state.images[data.index].svgElement = data.svg
  },
  saveOriginalRadius(state: DraftImageStore, data: Array<number>) {
    state.originalRadius = data
  },
  removeOriginalRadiusAtIndex (state: DraftImageStore, index: number) {
    if (state.originalRadius && state.originalRadius[index]) delete state.originalRadius[index]
  },
  setAutoHotpointing(state: DraftImageStore, data: { autoHotpointing: boolean, imageIndex: number }){
    state.autoHotpointing[data.imageIndex] = data.autoHotpointing
  },
  replaceHotpointsObject({ images }: DraftImageStore, data: {hotpoints: Hotpoint[], index: number}) {
    images[data.index].imageHotPoints = data.hotpoints
  },
  deleteHotpoints (state: DraftImageStore, data: any) {
    const hotpointIndex = state.images[data.imageIndex].imageHotPoints!.findIndex(point => {
      return point.id === data.id
    })
    state.images[data.imageIndex].imageHotPoints!.splice(hotpointIndex, 1)
  },
  updateCoordinates (state: DraftImageStore, data: { hotpoint: any, index: number, image: DraftImage}) {
    if (!data.hotpoint.shapePositionX || !data.hotpoint.shapePositionY) return
    const hotpoint = state.images![Number(data.image.displayOrder)].imageHotPoints![data.index]
    hotpoint.shapePositionX = data.hotpoint.shapePositionX
    hotpoint.shapePositionY = data.hotpoint.shapePositionY
  },
  updateRadius (state: DraftImageStore, data: {radius: number, index: number, imageIndex: number}) {
    const hotpoint = state.images![data.imageIndex].imageHotPoints![data.index]
    hotpoint.shapeRadiusX = data.radius
    hotpoint.shapeRadiusY = data.radius
  },
  setPage: (state: DraftImageStore, pageId: string) => { state.pageId = pageId },
  setImages: (state: DraftImageStore, images: DraftImageStore) => {
    if (state.images.length > 0) {
      state.images = []
      state.images.length = 0
    }
    state.images = [...images.images]
    updateDisplayOrder(state.images)
  },
  addImage: ({ images }: DraftImageStore, newImage: DraftImage) => {
    images.splice(newImage.displayOrder ?? 0, 0, newImage)
    updateDisplayOrder(images)
  },
  updateImgByIndex: (state: DraftImageStore, payload: { image: DraftImage, index: number }) => {
    state.images[payload.index] = payload.image
    updateDisplayOrder(state.images)
  },
  updateHotpointByImageIndex: ({ images }: DraftImageStore, payload: { hotpoint: Hotpoint, index: number }) => {
    images![payload.index]!.imageHotPoints!.push(payload.hotpoint)
  },
  updateImageStreamData: (state: DraftImageStore, payload: { streamData: ReadableStream, imageFileName: string, width: number, height: number}) => {
    const index = state.images.findIndex(element => !element.imageUrl && !element.streamData)
    const img = Object.assign({}, state.images[index])
    img.streamData = payload.streamData
    img.imageFileName = payload.imageFileName
    img.width = payload.width
    img.height = payload.height
    state.images[index] = img
  },
  updateImageFileData: ({ images }: DraftImageStore, image: File) => {
    const index = images.findIndex(element => !element.imageUrl && !element.streamData)
    images[index].data = image
  },
  removeImage: ({ images }: DraftImageStore, index: number) => {
    images.splice(index, 1)
    updateDisplayOrder(images)
  },
  removeImages: (state: DraftImageStore, index: number) => {
    // responsive issue not picking up this change after images deleted
    // double configuring does the trick
    state.images = []
    state.images.splice(0)
  },
  resetDeletedImages: (store: DraftImageStore) => {
    delete store.softDeletedImages
    store.softDeletedImages = []
  },
  resetUploadedImage: (state: DraftImageStore) => {
    const index = state.images.findIndex(element => !element.imageUrl && !element.streamData)
    if (state.images[index] != null && !state.images[index].imageUrl) {
      state.images[index] = {
        imageFileName: '',
        data: null
      }
    }
    updateDisplayOrder(state.images)
  },
  setSelectedImage (state: DraftImageStore, payload: DraftImage) {
    state.selected = payload
  },
  setSelectedHotpointImg (state: DraftImageStore, payload: DraftImage) {
    state.selectedHotpointImg = payload
  },
  softDeletion: ({ softDeletedImages }: DraftImageStore, image: DraftImage) => { softDeletedImages!.push(image) },
  setFont: (store: DraftImageStore, value: Fonts) => {
    store.font = value
  },
  setFontAlignment: (store: DraftImageStore, value: FontAlignment) => {
    store.fontAlignment = value
  },
  setFontBold: (store: DraftImageStore, value: boolean) => {
    store.fontBold = value
  },
  setFontColor: (store: DraftImageStore, value: string) => {
    store.fontColor = value
  },
  setFontItalic: (store: DraftImageStore, value: boolean) => {
    store.fontItalic = value
  },
  setFontSize: (store: DraftImageStore, value: FontSizes) => {
    store.fontSize = value
  },
  setFontUnderline: (store: DraftImageStore, value: boolean) => {
    store.fontUnderline = value
  },
  setLoading: (store: DraftImageStore, loading: boolean) => {
    state.loading = loading
  },
  setLineColor: (store: DraftImageStore, value: string) => {
    store.lineColor = value
  },
  setLineStroke: (store: DraftImageStore, value: number) => {
    store.lineStroke = checkMinMax(value, 1, 30)
  },
  setSaving: (state: DraftImageStore, saving: boolean) => { state.saving = saving },
  setShapeBorderColor: (store: DraftImageStore, value: string) => {
    store.shapeBorderColor = value
  },
  setShapeFillColor: (store: DraftImageStore, value: string|null) => {
    store.shapeFillColor = value || ''
  },
  setShapeOpacity: (store: DraftImageStore, value: opacityRange) => {
    store.opacity = value
  },
  setShapeStroke: (store: DraftImageStore, value: number) => {
    store.shapeStroke = checkMinMax(value, 3, 300)
  },
  setShape: (store: DraftImageStore, value: Shapes) => {
    store.shape = value
  },
  setIsDirty: (store: DraftImageStore, value: boolean) => {
    store.isDirty = value
  }
}

// Helper function to ensure value is within acceptable range
// Buefy b-numberinput component in DrawingControls.vue
// does not enforce min and max constraints
function checkMinMax (value: number, min: number, max: number): number {
  value = Math.min(value, max)
  value = Math.max(value, min)
  return value
}

function updateDisplayOrder(images: DraftImage[]) {
  images.forEach((it, i) => it.displayOrder = i)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
