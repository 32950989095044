<template>
  <section class="comments-container">
    <section v-if="comments.length && !updating && !loadingComments"
             class="comments-results"
             :class="{'full-height': !!editingComment, 'new-active': active}">
      <virtual-list :data-component="CommentsList"
                    :data-sources="comments"
                    :style="scroll"
                    :keeps="20"
                    :bottom-threshold="300"
                    :data-key="'id'"
                    @tobottom="toBottom">
      </virtual-list>
    </section>
    <div v-if="!total && !updating  && !loadingComments"
         class="no-data">
      <h1 data-int="comments-no-data"
          class="subtitle has-text-centered">
        {{ $t('assetsNotFound', {type: $tc('comment', 2)}) }}
      </h1>
    </div>
    <template v-if="loadingComments || updating">
      <utility-block />
    </template>
    <div v-if="(canAddAndEditComments || canEditAllComments) && !editingComment"
         class="comments-new"
         :class="{'active': active}">
      <comments-edit :comment="editing"
                     @active="val => active = val"
                     @submit="submit"
                     @cancel="cancel"/>
    </div>
  </section>
</template>

<script setup lang="ts">
import CommentsEdit from './CommentsEdit.vue'
import CommentsList from "./CommentsList.vue"
import VirtualList from "vue3-virtual-scroll-list"
import UtilityBlock from "@/components/common/UtilityBlock.vue"

import { computed, onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useBreakpoints } from "@/helpers/responsiveBreakpoints"

const store = useStore()
const { width } = useBreakpoints()

const editing = ref<Object>({
  acl: 'all',
  id: '',
  text: '',
  publicBelowOrg: null
})
const loading = ref<boolean>(false)
const active = ref<boolean>(false)
const comments = computed(() => store.state.content.comments.items)
const editingComment = computed(() => store.state.content.comments.editingComment)
const total = computed(() => store.state.content.comments.total)
const offset = computed(() => store.state.content.comments.offset)
const loadingComments = computed(() => store.state.content.comments.loadingComments)
const updating = computed(() => store.state.content.comments.updating)

const scroll = computed(() => {
  if(width.value > 900) {
    return `
            max-height: 340px;
            overflow-y: scroll;
            overflow-x: hidden;
          `
  } else {
    return `
            max-height: 320px;
            overflow-y: scroll;
            overflow-x: hidden;
          `
  }
})
const canAddAndEditComments = computed(() => store.getters['user/canAddAndEditComments'])
const canEditAllComments = computed(() => store.getters['user/canEditAllComments'])


const submit = async () => {
  await store.dispatch('content/saveComment', { comment: editing.value, saveOnly: true  })
  await clearState()
}
// cancel is only being used for click button event
const cancel = () => {
  editing.value = {
    id: '',
    text: '',
    acl: 'all',
    publicBelowOrg: null
  }
}
const setCommentsResultsPerPage = async () => {
  await store.dispatch('content/setCommentsResultsPerPage', 5)
}
const setBypassPagination = async () => {
  await store.dispatch('content/setBypassPagination', true)
}
const toBottom = async () => {
  if(loadingComments.value) return
  if(comments.value.length < total.value) {
    const newOffset = offset.value + 1
    await store.dispatch('content/setCommentsPage', newOffset)
  }
}
const clearState = async () => {
  await store.dispatch('content/clearComments')
  await setBypassPagination()
  await setCommentsResultsPerPage
  await store.dispatch('content/getComments')
}
onMounted(async () => {
  await setBypassPagination()
  await setCommentsResultsPerPage()
})

</script>

<style scoped lang="scss">
.comments-container {
  flex: 1;
  display: flex;
  flex-flow: column;

  .comments-new {
    height: 168px;
    &.active {
      height: 250px;
    }
  }
  @media screen and (max-width: 900px), print {
    .comments-new {
      height: 191px;
    }
  }
  @media screen and (max-width: 768px), print {
    .comments-new {
      padding: 0 0 1em 1em;
    }
  }
}
.comments-results {
  flex: 1;
  overflow: auto;
  padding: 0 1em 1em;
  border-bottom: lightgray 1px solid;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;

  &.new-active {
    max-height: 260px;
  }
  &.full-height {
    max-height: 100%;
  }
}
@media screen and (max-width: 768px), print {
  .comments-results {
    padding: 0 1rem .5rem;
  }
}
@media screen and (max-width: 1060px), print {
  .comments-results {
    padding: 0 1rem .5rem;
  }
}
.no-data {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
