<script setup lang="ts">
import DeleteContentModal from '@/components/common/modal/DeleteContentModal.vue'
import i18n from '../../../../../../locales'
import { acceptedFileTypes, HoopsModelView, HoopsToolbarIcon } from '@/plugins/hoops'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['delete', 'upload'])
const fileInput = ref<HTMLInputElement | null>(null)
defineProps({
  disabled: Boolean, // affects all buttons except upload
  hasEditOptions: Boolean, // controls visibility of properties, upload and delete option
  hasFileProcessing: Boolean, // disables upload option
  title: String
})
const store = useStore()
const captureSnapshot = () => store.dispatch('hoopsWebViewer/captureSnapshot')
const changeModelView = async (modelView: HoopsModelView) => await store.dispatch('hoopsWebViewer/changeModelView', modelView)
const currentModelView = computed(() => store.getters['hoopsWebViewer/currentModelView'])
const handleFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement

  if (target.files && target.files.length > 0) {
    emit('upload', Array.from(target.files))
  }
  target.value = ''
}
const triggerFileInput = () => {
  if (fileInput.value) {
    fileInput.value.click();
  }
}
const hoopsDeleteModal = ref(null)
const modelProperties = computed( () => store.getters['hoopsWebViewer/modelProperties'])
const modelViewOptions = computed( () => store.getters['hoopsWebViewer/modelViewOptions'])
const onDeleteModelOpen = () => hoopsDeleteModal.value!!.open()
const onDeleteModelSubmit = async () => {
  emit('delete')
  hoopsDeleteModal.value!!.close()
}
</script>

<template>
  <div class="is-hidden-touch">
    <h4 class="title-four title-overflow mb-0">
      {{ title ?? $t('threeD.viewerTitle') }}
    </h4>
    <nav class="custom-navbar-style navbar mb-1">
      <div class="is-fullwidth navbar-menu">
        <div class="force-button-alignment navbar-start"
             :class="{ 'force-alignment': !hasEditOptions }">
          <div class="navbar-item normal-icon"
               :class="{ 'has-dropdown': modelViewOptions.length > 0, 'is-hoverable': modelViewOptions.length > 0 }">
            <a class="is-arrowless navbar-link"
               :class="{ 'disabled': modelViewOptions.length === 0 || disabled }">
              <span class="icon fa-lg fas fa-binoculars is-disabled" />
            </a>
            <div class="is-boxed navbar-dropdown">
              <template v-for="option in modelViewOptions">
                <a class="navbar-item"
                   :class="{ 'is-selected': currentModelView?.nodeId === option.nodeId }"
                   @click="changeModelView(option)">
                  {{ option.label }}
                </a>
              </template>
            </div>
          </div>
          <a class="hoops-icon navbar-item"
             :class="{ 'custom-disabled': disabled }"
             @click="captureSnapshot">
            <hoops-toolbar-icon type="snapshot" />
          </a>
          <div v-if="hasEditOptions"
               class="hoops-icon navbar-item"
               :class="{ 'has-dropdown': !disabled, 'is-hoverable': !disabled }">
            <a class="is-arrowless navbar-link"
               :class="{ 'custom-disabled': disabled }">
              <hoops-toolbar-icon type="settings" />
            </a>
            <div v-if="modelProperties !== null"
                 class="is-boxed navbar-dropdown p-2">
              <table class="table">
                <thead>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th>Name</th>
                  <td>{{ modelProperties.nodeName }}</td>
                </tr>
                <template v-for="(value, key) in modelProperties.properties">
                  <tr>
                    <th>{{ key }}</th>
                    <td>{{ value }}</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="force-button-alignment navbar-end">
          <a v-if="hasEditOptions"
             class="navbar-item normal-icon"
             :class="{ 'disabled': hasFileProcessing }"
             @click="triggerFileInput">
            <span class="icon fa-lg fas fa-upload" />
          </a>
          <input class="is-hidden"
                 multiple
                 ref="fileInput"
                 type="file"
                 :accept="acceptedFileTypes"
                 @change="handleFileChange" />
          <a v-if="hasEditOptions"
             class="navbar-item normal-icon"
             :class="{ 'disabled': disabled }"
             @click="onDeleteModelOpen">
            <span class="icon fa-lg fas fa-trash" />
          </a>
        </div>
        <delete-content-modal ref="hoopsDeleteModal"
                              @submit="onDeleteModelSubmit">
          <template #title>
            {{ i18n.global.t('threeD.deleteTitle') }}
          </template>
        </delete-content-modal>
      </div>
    </nav>
  </div>
</template>

<style scoped lang="scss">
a:hover {
  color: var(--grey-dark)
}

// HOOPS icons require custom styling
.custom-disabled {
  cursor: default;
  opacity: 0.2;
  pointer-events: none;
}

.custom-navbar-style {
  border: unset;
  z-index: 10; // required to compensate for the app navbar dropdown
}

.disabled {
  color: #dbdbdb;
  cursor: default;
  pointer-events: none;
}

// center if there are no edit options allowed
.force-alignment {
  flex: 1 1 auto;
  justify-content: center;
}

.force-button-alignment {
  align-items: end !important;
}

a.hoops-icon {
  border-radius: 4px;
  padding: 0.25rem;
}
div.hoops-icon {
  padding: 0;
}
div.hoops-icon > a {
  border-radius: 4px;
  padding: 0.25rem;
}

.normal-icon:hover {
  color: unset;
}
a.normal-icon {
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
}
div.normal-icon {
  padding: 0;
}
div.normal-icon > a {
  border-radius: 4px;
}


// current bulma version does not have .is-selected for .navbar-item yet
.is-selected {
  background-color: var(--primary);
  color: white;
}

.is-selected:hover {
  background-color: var(--primary) !important;
  color: white !important;
}

.title-overflow {
  align-content: center;
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 67vw;
  word-break: break-all;
}
</style>
