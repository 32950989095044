<template>
  <b-modal
    v-model="isActive"
    has-modal-card
    trap-focus
    :can-cancel="false"
  >
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t('open') }}
        </p>
        <button
          type="button"
          class="delete"
          @click="close"
        />
      </header>

      <section class="modal-card-body">
        <b-tabs
          data-unit="cart-tabs"
          v-model="activeTab"
          class="is-full-width"
        >
          <b-tab-item
            v-if="isSavedCartEnabled"
            data-unit="saved-tab"
            :label="$tc('myCart', 2)"
            :value="$options.tabValues.saved"
          >
            <search-bar
              expanded
              @change="filterSaved=$event"
              @refresh="loadSavedCarts"
            />
            <template v-if="isLoaded">
              <saved-cart-table
                :data="savedCarts"
                :filter="filterSaved"
                @delete:cart="loadSavedCarts"
                @update:selected="selectSaved"
              />
            </template>
            <template v-else>
              <utility-block
                data-unit="loading-icon"
                class="is-full-width has-text-centered"
              />
            </template>
          </b-tab-item>
          <b-tab-item v-if="isSharedCartEnabled && !isEssentialsTenant"
                      :label="$tc('sharedCart', 2)"
                      :value="$options.tabValues.shared"
                      data-unit="shared-tab">
            <search-bar
              expanded
              @change="filterShared=$event"
              @refresh="loadSharedCarts"
            />
            <template v-if="isLoaded">
              <shared-cart-table
                :data="sharedCarts"
                :filter="filterShared"
                @update:selected="selectShared"
              />
            </template>
            <template v-else>
              <utility-block
                data-unit="loading-icon"
                class="is-full-width has-text-centered"
              />
            </template>
          </b-tab-item>
        </b-tabs>
      </section>

      <footer class="modal-card-foot">
        <b-field
          grouped
          position="is-right"
          class="is-full-width"
        >
          <b-button
            data-int="cart-open-modal-cancel-button"
            @click="close"
          >
            {{ $t('cancel') }}
          </b-button>
          <b-button
            :disabled="missingSelected"
            data-int="cart-open-modal-open-button"
            type="is-primary"
            @click="requestOpenCart"
          >
            {{ $t('open') }}
          </b-button>
        </b-field>
      </footer>

      <cart-merge-override-modal
        ref="mergeOverrideModal"
        @merge="merge"
        @override="override"
      />
    </div>
  </b-modal>
</template>

<!--
New modal to replace the former open cart modals
Will include both saved and shared options
-->
<script>
import CartMergeOverrideModal from '@/components/library/cart/CartMergeOverrideModal'
import SavedCartTable from '@/components/library/cart/open/SavedCartTable'
import SearchBar from '@/components/common/search/SearchBar'
import SharedCartTable from '@/components/library/cart/open/SharedCartTable'
import UtilityBlock from '@/components/common/UtilityBlock'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'OpenCartModal',
  components: {
    CartMergeOverrideModal,
    SavedCartTable,
    SearchBar,
    SharedCartTable,
    UtilityBlock
  },
  data () {
    return {
      activeTab: this.$options.tabValues.saved,
      filterSaved: '',
      filterShared: '',
      isActive: false,
      selected: null,
      selectedSaved: null,
      selectedShared: null
    }
  },
  tabValues: {
    saved: 'SAVED',
    shared: 'SHARED'
  },
  computed: {
    ...mapState({
      isSavedCartsLoaded: (state) => state.savedCarts.isLoaded,
      isSharedCartsLoaded: (state) => state.sharedCarts.isLoaded,
      savedCarts: (state) => state.savedCarts.items,
      sharedCarts: (state) => state.sharedCarts.items,
    }),
    ...mapGetters({
      isSavedCartEnabled: 'user/isSavedCartEnabled',
      isSharedCartEnabled: 'user/isSharedCartEnabled',
      isEssentialsTenant: 'user/isEssentialsTenant'
    }),
    isCartEmpty () {
      return this.$store.state.cart.items.length === 0
    },
    isLoaded () {
      return this.isSavedCartsLoaded && this.isSharedCartsLoaded
    },
    missingSelected () {
      return this.selected == null
    }
  },
  methods: {
    ...mapActions({
      openSavedCart: 'cart/openSavedCart',
      openSharedCart: 'cart/openSharedCart'
    }),
    open () {
      if (!this.isLoaded) {
        this.load()
      }

      this.activeTab = this.isSavedCartEnabled
        ? this.$options.tabValues.saved
        : this.$options.tabValues.shared
      this.selectedSaved = null
      this.selectedShared = null
      this.isActive = true
    },
    close () {
      this.isActive = false
    },
    async load () {
      await Promise.all(
        [
          this.loadSavedCarts(),
          this.loadSharedCarts()
        ]
      )
    },
    async loadSavedCarts () {
      await this.$store.dispatch('savedCarts/fetchAll')
    },
    async loadSharedCarts () {
      await this.$store.dispatch('sharedCarts/fetchAll')
    },
    requestOpenCart () {
      this.isCartEmpty ? this.merge() : this.$refs.mergeOverrideModal.open()
    },
    selectSaved (data) {
      this.selectedSaved = data
      this.selected = this.selectedSaved
    },
    async selectShared (data) {
      this.selectedShared = data
      this.selected = this.selectedShared
    },
    merge () {
      const request = {
        cartId: this.selected.id,
        clear: false
      }
      if (this.activeTab === this.$options.tabValues.saved) {
        this.openSavedCart(request)
      } else {
        this.openSharedCart(request)
      }
      this.close()
    },
    override () {
      const request = {
        cartId: this.selected.id,
        clear: true
      }
      if (this.activeTab === this.$options.tabValues.saved) {
        this.openSavedCart(request)
      } else {
        this.openSharedCart(request)
      }
      this.close()
    }
  }
}
</script>
