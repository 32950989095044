import http from '@/http'

export const getOrgProperties = async (id) => {
  return http.get(`/organizations/${id}/properties`).then((res) => res.data)
}

export const getOrgPrivileges = async (id) => {
  return http.get(`/organizations/${id}/privileges`).then((res) => res.data)
}

export const updateOrgPrivileges = async (id, data) => {
  return http.put(`/organizations/${id}/privileges`, data).then((res) => res.data)
}

export const updateOrgProperties = async (id, data) => {
  return http.put(`/organizations/${id}/properties`, data).then((res) => res.data)
}

/**
 *
 * @param {number[]} orgs
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getOrganizationChildrenList = async (orgs) => {
  return http.post('/organizations/list', orgs).then(res => res.data)
}

export const orgSearch = async (params) => {
  return http.get('org-admin/search-v2', { params }).then(res => res.data)
}

export const updateOrganizationParents = async (payload) => {
  return http.put('/organizations/parents', payload).then(res => res.data)
}
