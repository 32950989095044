<template>
  <section class="cart-suggestions-container">
    <h1 class="title is-size-4">
      {{ $tc('suggestion', 2) }}
    </h1>
    <div v-if="suggestions && suggestions.length" data-int="suggestions" class="columns is-mobile">
      <div v-for="item in suggestions" :key="item.id" class="column is-2-desktop is-3-tablet is-4-mobile">
        <suggestions-card data-int="suggestions-card"
                          :item="item"
                          :add-to-cart-qty-behavior="addToCartQtyBehavior"
                          :has-access-to-carts="hasAccessToCarts"
                          @addToCart="add"/>
      </div>
    </div>
    <div v-else data-int="suggestions-no-data" class="no-data subtitle has-text-centered">
      {{ $t('assetsNotFound', {type: $tc('suggestion', 2)}) }}
    </div>
  </section>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import SuggestionsCard from '@/components/library/content/suggestions/SuggestionsCard';

export default {
  name: 'CartSuggestions',
  components: {
    SuggestionsCard
  },
  computed: {
    ...mapState({
      addToCartQtyBehavior: (state) => state.user.preferences.addToCartQtyBehavior,
      suggestions: (state) => state.cart.suggestions
    }),
    hasAccessToCarts () {
      return this.$store.getters['user/isCartEnabled']
    }
  },
  methods: {
    ...mapActions({
      add: 'cart/addToCart'
    })
  }
};
</script>

<style scoped>
.cart-suggestions-container {
  min-height: 23rem;
  padding: .5rem 1rem;
}
.title {
  flex: 0;
  margin-bottom: 1rem;
}
.no-data {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
