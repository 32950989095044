import http from '@/http';

export async function createHotpoint(data = '') {
  return await http.post(`/admin/hotpoint-links`, data, {
    headers: {
      'Content-type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((res) => res.data);
}
