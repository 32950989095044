import { ActionContext, GetterTree, MutationTree } from 'vuex'
import { NumberToNumberMap } from '@/interfaces/global'
import { arrowKey, DraftBomItem, draftBomStorePaths } from '@/interfaces/admin'

// Interfaces and Types
interface SelectedRowsState { indices: number[] }

type Context = ActionContext<SelectedRowsState, any>

interface LastItemChildrenPayload {
  visListIdx: number,
  masterListIdx: number
}

interface AddToSelectedPayload {
  index: number,
  indexInVisibleList: number,
  clickType: 'click' | 'shift-click'
}

export enum MutationTypes {
  ADD_TO_SELECTED_ROWS = 'ADD_TO_SELECTED_ROWS',
  SET_SELECTED_ROW = 'SET_SELECTED_ROW',
  CLEAR_SELECTED_ROWS = 'CLEAR_SELECTED_ROWS',
  REMOVE_SELECTED_ROW = 'REMOVE_SELECTED_ROW'
}

// selectedRows Module
const state: SelectedRowsState = {
  indices: [] as number[]
}

const getters: GetterTree<SelectedRowsState, any> = {
  selectedIndicesMap (state): NumberToNumberMap {
    const selectedIndicesMap: NumberToNumberMap = {}
    state.indices.forEach((idx: number) => {
      selectedIndicesMap[idx] = idx
    })
    return selectedIndicesMap
  },
  lastVisiblePart (state, getters, rootState): DraftBomItem|undefined {
    const lastIndex = rootState.draftPage.draftBomItems.length - 1
    let lastVisiblePartIndex = -1
    for (let i = lastIndex; i >= 0; i--) {
      if (rootState.draftPage.draftBomItems[i].visible) {
        lastVisiblePartIndex = i
        break
      }
    }
    return rootState.draftPage.draftBomItems[lastVisiblePartIndex]
  },
  willAddRowAtBottom (state, getters, rootState, rootGetters): boolean {
    if (state.indices.length === 0) {
      return false
    }
    // const lastIdxInVisibleList = rootGetters['draftBom/visibleRowsCount'] - 1
    // const lastVisiblePart = rootState.draftBom.visiblePartsList[lastIdxInVisibleList]
    const refKey = getters.lastVisiblePart?.refKey ?? ''

    const lastVisiblePartMasterListIdx = rootGetters[draftBomStorePaths.refKeyToMasterListIdx][refKey]
    return state.indices.includes(lastVisiblePartMasterListIdx)
  },
  lastVisibleSelected (state, getters, rootState): number {
    let lastVisibleIdx
    for (let i = state.indices.length -1; i >= 0; i--) {
      let idx = state.indices[i]
      let part = rootState.draftPage.draftBomItems[idx]
      if (part.visible) {
        lastVisibleIdx = idx
        break
      }
    }
    return lastVisibleIdx ?? -1
  }
}

const actions = {
  async getNextVisiblePart ({ rootGetters, rootState }: Context, masterListIdx: number): Promise<DraftBomItem|undefined> {
    let nextVisiblePart: DraftBomItem|undefined

    for (let i = masterListIdx + 1; i < rootState.draftPage.draftBomItems.length; i++) {
      if (rootState.draftPage.draftBomItems[i].visible) {
        nextVisiblePart = rootState.draftPage.draftBomItems[i]
        break
      }
    }

    return nextVisiblePart
  },
  async getPreviousVisiblePart ({ rootState }: Context, masterListIdx: number): Promise<DraftBomItem|null> {
    let prevVisiblePart = null

    for (let i = masterListIdx - 1; i >= 0; i--) {
      if (rootState.draftPage.draftBomItems[i].visible) {
        prevVisiblePart = rootState.draftPage.draftBomItems[i]
        break
      }
    }

    return prevVisiblePart
  },
  async getLastItemChildren (context: Context, payload: LastItemChildrenPayload): Promise<number[]> {
    const { rootGetters, rootState, dispatch } = context
    let { masterListIdx } = payload
    const lastIdxInMasterList = rootState.draftPage.draftBomItems.length - 1

    // Return empty list if last item has no children or is expanded or is last part in list
    // const { children, expanded } = rootState.draftBom.visiblePartsList[visListIdx]
    const { children, expanded } = rootState.draftPage.draftBomItems[masterListIdx]
    const hasNoChildren = children.length === 0
    const isExpanded = expanded === true

    const isLastPart = masterListIdx === lastIdxInMasterList
    if (hasNoChildren || isExpanded || isLastPart) {
      return []
    }

    // Get backstop index / index of next visible part
    // If there is no next item, then backStop index will be last index + 1
    // This allows nested children to be grabbed when appropriate

    const nextVisiblePart: DraftBomItem|undefined = await dispatch('getNextVisiblePart', masterListIdx)
    const nextVisiblePartRef = nextVisiblePart?.refKey ?? ''
    const backStopIndex: number = rootGetters[draftBomStorePaths.refKeyToMasterListIdx][nextVisiblePartRef] || lastIdxInMasterList + 1

    // Populate master list indexes between last selected part and backStopIndex --
    // These will be all the nested children of the last selected part
    const childrenIndexes: number[] = []
    masterListIdx++
    while (masterListIdx < backStopIndex) {
      childrenIndexes.push(masterListIdx)
      masterListIdx++
    }

    return childrenIndexes
  },
  async addToSelectedRows (context: Context, payload: AddToSelectedPayload) {
    const { state, rootState, getters, rootGetters, commit, dispatch } = context
    const { index, clickType, indexInVisibleList } = payload

    // Prevent accidentally selecting row when selecting dropdown item
    if (rootState.draftPage.draftBomItemUpdating.isSelectingDropdown) return

    // If no previously selected row,
    // then just add the clicked or shift-clicked row and its children if it is collapsed
    if (state.indices.length === 0) {
      const children: number[] = await dispatch('getLastItemChildren', { visListIdx: indexInVisibleList, masterListIdx: index })
      commit(MutationTypes.ADD_TO_SELECTED_ROWS, [index].concat(children))
      return
    }

    if (clickType === 'click') {
      // Clear previously selected rows
      if (state.indices.length >= 1) {
        // Get previous visible item's index in master list
        const previousVisibleItem: DraftBomItem|null = await dispatch('getPreviousVisiblePart', index)
        const previousVisibleItemRef: string = previousVisibleItem?.refKey ?? ''
        const previousItemMasterListIdx: number = rootGetters[draftBomStorePaths.refKeyToMasterListIdx][previousVisibleItemRef] || -1

        // Get next visible item's index in master list
        const nextVisibleItem = await dispatch('getNextVisiblePart', index)
        const nextVisibleItemRef: string = nextVisibleItem?.refKey ?? ''
        const nextItemMasterListIdx: number = rootGetters[draftBomStorePaths.refKeyToMasterListIdx][nextVisibleItemRef] || -1

        // Helpers used to infer if click is a re-click of the only currently selected row
        const hasPreviousOrNextPart = state.indices.some(idx => {
          return idx === previousItemMasterListIdx || idx === nextItemMasterListIdx
        })
        const isSelected = state.indices.includes(index)

        // If the clicked part was the only previously selected part --
        // either by itself or recursively with its children --
        // then, clear it from selectedRows and return so that nothing is now highlighted
        if (!hasPreviousOrNextPart && isSelected) {
          dispatch('clearSelectedRows')
          return
        }
        dispatch('clearSelectedRows')
      }

      // Add selected row and its children if it's collapsed to selected part rows
      const children: number[] = await dispatch('getLastItemChildren', { visListIdx: indexInVisibleList, masterListIdx: index })
      commit(MutationTypes.ADD_TO_SELECTED_ROWS, [index].concat(children))
    } else { // Else block handles shift-click
      // Determine if shift-clicked row is above or below previously clicked row
      const prevClickedRow = state.indices[0]
      const belowPrevClickedRow = prevClickedRow < index
      const equalsPrevClickedRow = prevClickedRow === index
      const selected: number[] = [] // List to be populated

      if (equalsPrevClickedRow) {
        // In this case, clear selectedRows because the same row has been clicked again
        await dispatch('clearSelectedRows')
        return
      } else if (belowPrevClickedRow) {
        for (let i = prevClickedRow + 1; i <= index; i++) {
          selected.push(i)
        }
      } else { // In this case, shift-clicked row is above previously selected row
        for (let i = index; i < prevClickedRow; i++) {
          selected.push(i)
        }
      }

      // If the last row in selection range is collapsed & has children,
      // then concatenate them all to selected and then add them to state
      const lastSelectedIndex = belowPrevClickedRow ? selected.length - 1 : 0
      const lastSelected = selected[lastSelectedIndex]
      const refKey: string = rootState.draftPage.draftBomItems[lastSelected].refKey
      const visListIdx: number = getters.refKeyToVisibleListIdx[refKey]
      const masterListIdx: number = rootGetters[draftBomStorePaths.refKeyToMasterListIdx][refKey]
      const lastItemChildren: number[] = await dispatch('getLastItemChildren', { visListIdx, masterListIdx })
      const selectedAndChildren = selected.concat(lastItemChildren)

      commit(MutationTypes.ADD_TO_SELECTED_ROWS, selectedAndChildren)
    }
  },
  setSelectedRow (context: Context, index: number) {
    context.commit(MutationTypes.SET_SELECTED_ROW, index)
  },
  clearSelectedRows (context: Context) {
    context.commit(MutationTypes.CLEAR_SELECTED_ROWS)
  },
  removeSelectedRow (context: Context, index: number) {
    context.commit(MutationTypes.REMOVE_SELECTED_ROW, index)
  },
  async selectBomByArrowKeyDirection({ dispatch, getters, rootState }: Context, payload: string) {
    if(!payload) return
    const bomItems = getters.getDraftBomItems
    const visibleRowsKeysToIndex = getters.refKeyToVisibleListIdx
    const currentSelections = getters.selectedIndicesMap
    let selectIndex: number

    async function handleArrowUp() {
      if (Object.keys(currentSelections).length !== 0) {
        const selectedFirstIndex = Number(Object.keys(currentSelections)[0])
        if (selectedFirstIndex !== 0) {
          const refKey = bomItems[Object.keys(currentSelections)[0]].refKey
          //  get current index of visible index key map
          let newSelectionIndex = visibleRowsKeysToIndex[refKey] - 1
          let newSelectionRefKey: string = ''
          //  get the key of the next lowest index on visible index key map
          for (const property in visibleRowsKeysToIndex) {
            if(visibleRowsKeysToIndex[property] === newSelectionIndex) {
              newSelectionRefKey = property
            }
          }
          selectIndex = bomItems.findIndex((bom: any) => bom.refKey === newSelectionRefKey)
        }
      }
      if (!selectIndex) selectIndex = 0
    }

    async function handleArrowDown() {
      if (Object.keys(currentSelections).length === 0) {
        selectIndex = bomItems.length - 1
      } else {
        const selectedLastIndex = Number(Object.keys(currentSelections)[Object.keys(currentSelections).length -1])
        if(selectedLastIndex === Object.keys(visibleRowsKeysToIndex).length - 1) return
        let refKey: string
        // get refKey of last row of currentSelection
        for (const property in visibleRowsKeysToIndex) {
          if(visibleRowsKeysToIndex[property] === selectedLastIndex) refKey = property
        }

        //   test if current selected has child and expand if not visible
        const bomChildren = bomItems.find((bom:any) => bom.refKey === refKey).children
        if(bomChildren && bomChildren.length > 0) {
          let child = bomItems.find((bom: DraftBomItem) => bom.refKey === bomChildren[0])
          if (!child.visible) {
            await dispatch('draftPage/draftBomItemUpdating/showPartAndAllParents', (selectedLastIndex + 1), { root: true })
          }
        }
        selectIndex = selectedLastIndex + 1
      }
    }

    if(payload === arrowKey.arrowUp) await handleArrowUp()
    if(payload === arrowKey.arrowDown) await handleArrowDown()

    if(selectIndex! === undefined) return
    await dispatch('clearSelectedRows')
    await dispatch('setSelectedRow', selectIndex!)
  }
}

const mutations: MutationTree <SelectedRowsState> = {
  [MutationTypes.ADD_TO_SELECTED_ROWS] (state, indexList: number[]) {
    // Keep selected rows in ascending order and without duplicates
    state.indices = Array.from(new Set(state.indices.concat(indexList).sort((a, b) => a - b)))
  },
  [MutationTypes.SET_SELECTED_ROW] (state, index: number) {
    state.indices = [index]
  },
  [MutationTypes.CLEAR_SELECTED_ROWS] (state) {
    state.indices = []
  },
  [MutationTypes.REMOVE_SELECTED_ROW] (state, index: number) {
    state.indices = state.indices.filter((rowIdx: number) => rowIdx !== index)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
