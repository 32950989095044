<template>
  <td
    class="tag-cell is-hidden-mobile"
    :class="{'has-text-info': bomItem.highlight}"
    @dblclick.stop="open"
  >
    <p class="truncate">
      {{ tagValues }}
    </p>
  </td>
</template>

<script>
export default {
  name: 'BomCellTag',
  props: {
    bomItem: {
      type: Object,
      required: true
    },
    column: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tagValues: ''
    }
  },
  methods: {
    open () {
      this.$emit('open', {
        content: this.bomItem,
        route: ''
      })
    }
  },
  mounted () {
    const tags = this.bomItem.tags[this.column.property] || []

    // dedupe
    const deduped = tags.reduce((acc, cur) => {
      if (!acc.find((tag) => tag === cur)) {
        acc.push(cur)
      }
      return acc
    }, [])

    this.tagValues = deduped.length ? deduped.join(', ') : ''
  }
}
</script>

<style scoped>
.table tr.is-selected td {
  color: currentColor !important;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  vertical-align: middle;
  min-height: 2rem;
}
</style>
