<template>
  <th
    class="indicator-cell"
    @dblclick.stop="open"
    :class="{'highlighted': bomItem.isInSearchResults}"
  />
</template>

<script>
export default {
  name: 'BomCellIndicator',
  props: {
    bomItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    open () {
      this.$emit('open', {
        content: this.bomItem,
        route: ''
      })
    }
  }
}
</script>

<style scoped>
.indicator-cell {
  width: .25rem !important;
  border-right: none;
  padding: 0 !important;
}
</style>
