import { computed, toRaw } from 'vue'
import { useStore } from 'vuex'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
import { TocFlatListItemDto } from '@/interfaces/global/widgets'

export function useWidgetToc() {
  const store = useStore()

  const { contentPath, media,
    updateChapters, navToViewWithLoading } = useWidgetNavigator()

  const contentPathWithBook = computed(() => {
    return !!media.value 
      ? `/book/${media.value?.id}${contentPath.value}`
      : contentPath.value
  })

  const widgetTocFlatMap = computed(() => {
    return store.getters['content/widgetTocFlatMap']
  })

  const widgetTocFlatList = computed(() => {
    return store.getters['content/widgetTocFlatList']
  })

  function updateChaptersForToc(chapters: number[]) {
    const chapterPathKeys: string[] = []
    let previousChapters = ""

    chapters.forEach(id => {
      chapterPathKeys.push(`${previousChapters}/chapter:${id}`)
      previousChapters += `/chapter:${id}`
    })

    const updatedParentChapters = chapterPathKeys
      .map(pathKey => widgetTocFlatMap.value.get(pathKey).item)
    const chapter = updatedParentChapters.pop()
    
    updateChapters(chapter, updatedParentChapters)
  }

  async function goToContentInBook(contentDto: TocFlatListItemDto) {
    try {
      // Grab DTO to avoid race condition
      // while breadcrumbs & contentPath are re-computed
      const dto = toRaw(contentDto)
      const { item, chapters } = dto
      updateChaptersForToc([ ...chapters])
      await navToViewWithLoading(item)
    } catch(e) {
      console.error(e)
    }
  }

  function getFlatListIdx(itemPath: string) {
    const contentPath = ("/")
      .concat(itemPath.split("/").slice(3).join("/"))
    return widgetTocFlatMap.value.get(contentPath)?.index ?? -1
  }

  async function useWidgetTocNav(itemPath: string) {
    const contentPath = ("/")
      .concat(itemPath?.split("/").slice(3).join("/"))
    const { item, chapters } = widgetTocFlatMap.value.get(contentPath)
    await goToContentInBook({ item, chapters })
  }

  return { 
    contentPath: contentPathWithBook,
    widgetTocFlatMap, widgetTocFlatList, 
    goToContentInBook,
    useWidgetTocNav,
    getFlatListIdx }
}