import http from '@/http';

const RECENTLY_ADDED_RECEIVED = 'RECENTLY_ADDED_RECEIVED';

const state = {
  items: []
};

const actions = {
  async getRecentlyAdded({commit, rootState}) {
    try {
      if (rootState.user.accessPrivileges.indexOf('RECENTLY_ADDED') >= 0) {
        const width = window.innerWidth;
        const limit = width > 1024 ? 12 : width > 769 && width < 1024 ? 8 : 6; // eslint-disable-line

        const {data} = await http.get(`recent/added?limit=${limit}`);
        commit(RECENTLY_ADDED_RECEIVED, {items: data.items});
      } else {
        commit(RECENTLY_ADDED_RECEIVED, {items: []});
      }
    } catch (err) {
      // na
    }
  }
};

const mutations = {
  [RECENTLY_ADDED_RECEIVED](state, {items}) {
    state.items = items;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
