<template>
  <component v-bind="$attrs"
             :is="tag"
             :aria-current="page.isCurrent"
             :aria-label="page['aria-label']"
             :class="{ 'is-current': page.isCurrent, [page.class]: true }"
             :disabled="disabledOrUndefined"
             :href="href"
             class="pagination-link"
             role="button"
             @click.prevent="page.click">
    <slot>{{ page.number }}</slot>
  </component>
</template>

<script>
import config from '../../utils/config'

export default {
  name: 'BPaginationButton',
  props: {
    page: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: 'a',
      validator: (value) => {
        return config.defaultLinkTags.indexOf(value) >= 0
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    href() {
      if (this.tag === 'a') {
        return '#'
      } else {
        return undefined
      }
    },
    isDisabled() {
      return this.disabled || this.page.disabled
    },
    disabledOrUndefined() {
      return this.isDisabled || undefined
    }
  }
}
</script>
