<template>
  <div class="modal is-active">
    <div class="modal-background"/>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ $t('history') }}</p>
      </header>
      <section v-if="isLoaded" class="modal-card-body">
        <table class="table is-bordered is-striped is-narrow is-fullwidth is-marginless">
          <thead>
            <tr>
              <th data-int="order-history-date-modified-header">
                {{ $t('dateModified') }}
              </th>
              <th data-int="order-history-status-header">
                {{ $t('status') }}
              </th>
              <th data-int="order-history-user-header">
                {{ $t('user') }}
              </th>
              <th data-int="order-history-comments">
                {{ $tc('comment', 2) }}
              </th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr v-for="history in orderHistory" :key="history.id">
              <td data-int="order-history-date-modified">
                {{ $d(new Date(history.updated), 'short') }}
              </td>
              <td data-int="order-history-status">
                {{ statusCodes[history.state] }}
              </td>
              <td data-int="order-history-user">
                {{ history.updatedByUsername }}
              </td>
              <td data-int="order-comments">
                <article  v-if="typeof history.comments === 'string'"
                          v-html="getSanitizedHtml(history.comments)"/>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section class="modal-card-body loading" v-else>
        <loading-icon />
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary"
                @click="close">
          {{ $t('close') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import { sanitizeHtml } from '@/helpers/sanitizeHtml';

export default {
  name: 'OrderHistoryModal',
  components: {
    LoadingIcon
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      orderHistory: [],
      isLoaded: false
    };
  },
  computed: {
    ...mapState({
      statusCodes: (state) => state.statuscodes.items
    })
  },
  methods: {
    ...mapActions({
      getOrderHistory: 'order/getOrderHistoryWithReturn'
    }),
    close() {
      this.$emit('close');
    },
    getSanitizedHtml(html) {
      return (!html) ? '' : sanitizeHtml(html)
    }
  },
  async created() {
    this.isLoaded = false;
    this.orderHistory = await this.getOrderHistory(this.order.id);

    this.isLoaded = true;
  },
};
</script>


<style scoped>
.modal-card {
  width: auto;
  max-width: 60%;
  min-width: 40%;
}
.modal-card-body {
  color: #4a4a4a;
}
.loading {
  align-items: center;
  justify-content: center;
  height: 8rem;
}
</style>
