import { ActionContext, GetterTree, MutationTree } from 'vuex'
import { DefaultTemplate, TemplateIdentifier, mediaTemplates } from "@/interfaces/library/detailLayouts"

interface DetailPageLayoutState {
  template: DefaultTemplate | null
}
type Context = ActionContext<DetailPageLayoutState, any>

enum MutationTypes {
  'SET_TEMPLATE' = 'SET_TEMPLATE'
}
const state: DetailPageLayoutState = {
  template: null
}
const getters: GetterTree<DetailPageLayoutState, any> = {
  getDetailPageLayoutState: (state): DefaultTemplate | null => {
    return state.template
  }
}
const actions = {
  async fetchDetailPageLayout ({ commit }: Context, payload: TemplateIdentifier) {
    // this area for when backend Rest Services are available
    // if no values have been set on the media templates, use defaults
    commit('SET_TEMPLATE', mediaTemplates[payload.type])
  }
}
const mutations: MutationTree <DetailPageLayoutState> = {
  async [MutationTypes.SET_TEMPLATE] (state, template: DefaultTemplate) {
    state.template = template
  }
}
export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
