<template>
  <section  class="section" style="overflow: visible;">
    <div class="columns is-mobile is-multiline">
      <div v-for="child in children"
           :key="child.id"
           class="column is-2-desktop is-3-tablet is-4-mobile">
        <card :item="child"
              :path="path"
              @click="$emit('select', $event)">
              <strong class="has-text-link">
                {{ child.name }}
              </strong>
        </card>
      </div>
    </div>
  </section>
</template>

<script>
import Card from "@/components/library/content/asset/viewer/navigatorViewer/NavigatorCard"
export default {
  name: "Tiles",
  components: { Card },
  props: {
    children: {
      type: Array,
      default: () => []
    },
    path: String
  }
}
</script>

<style scoped>
.section {
  padding: 1rem;
  height: 100%;
  overflow: auto;
}
</style>