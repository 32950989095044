import router from '@/router';
import http from '@/http';
import { getRoute } from "@/router/getRoute";
import { readFromServer } from "@/components/admin/content/editor/accessControlBindings";
import { entityTypePluralize } from './contentHelpers';
import { toRaw } from "vue";

import {
  CONTENT_RECEIVED,
  COMMENTS_RECEIVED,
  COMMENTS_UPDATE_LIMIT,
  COMMENTS_UPDATE_OFFSET,
  CLEAR_COMMENTS,
  EDITING_COMMENT,
  LOADING_COMMENTS,
  CONCATENATE_COMMENTS,
  SET_BYPASS_PAGINATION,
  COMMENTS_UPDATING,
  REVERT_COMMENT,
  UPDATE_ORIGINAL_COMMENT
} from './contentTypes';
import {cloneDeep, find, findIndex} from "lodash"

const state = getDefaultState();

const actions = {
  async getComments({commit, state, rootState}) {
    try {
      commit('LOADING_COMMENTS', true)
      if (rootState.content.id) {
        if (rootState.user.accessPrivileges.indexOf('VIEW_COMMENTS') >= 0) {
          const entityTypePlural = entityTypePluralize(rootState.content.type);
          const entityId = rootState.content.id;
          const params = {
            limit: state.limit,
            offset: state.offset * state.limit
          };

          const { headers, data } = await http.get(`${entityTypePlural}/${entityId}/comments`, {params});
          const dataMap = await data.map(readFromServer)
          const comments = Array.from(dataMap.values())
          if(!state.byPassPagination) {
            commit(COMMENTS_RECEIVED, { comments: comments, meta: headers });
          } else {
          //   using virtual scrolling so comments fetches will add to the existing array cumulatively
            commit(CONCATENATE_COMMENTS, { comments: toRaw(comments), meta: headers })
          }
        }
      }
    } catch (err) {
      commit(COMMENTS_RECEIVED, {comments: []});
    } finally {
      commit('LOADING_COMMENTS', false)
    }
  },
  async saveComment({dispatch, commit, rootState}, {comment, saveOnly = false}) {
    try {
      const entityTypePlural = entityTypePluralize(rootState.content.type);
      const entityId = rootState.content.id;

      const formData = new URLSearchParams();
      formData.append('comment', comment.text);
      formData.append('global', comment.acl === 'all');
      formData.append('publicBelowOrg', comment.acl === 'myOrgAndChildren');

      if (comment.id) {
        await http.put(`${entityTypePlural}/${entityId}/comments/${comment.id}`, formData);
      } else {
        await http.post(`${entityTypePlural}/${entityId}/comments`, formData);
      }
      if(!saveOnly) {
        dispatch('getComments')
      } else {
        commit('UPDATE_ORIGINAL_COMMENT', comment)
      }
    } catch (err) {
      // na
    }
  },
  async editingComment({ commit }, comment) {
    await commit('EDITING_COMMENT', comment)
  },
  async removeComment({dispatch, rootState}, {comment, deleteOnly = false}) {
    try {
      const entityTypePlural = entityTypePluralize(rootState.content.type);
      const entityId = rootState.content.id;

      await http.delete(`${entityTypePlural}/${entityId}/comments/${comment.id}`);
      if(!deleteOnly) dispatch('getComments');
    } catch (err) {
      // na
    }
  },
  setCommentsPage({commit, dispatch}, page) {
    commit(COMMENTS_UPDATE_OFFSET, {
      offset: Math.max(page, 0)
    });
    dispatch('getComments');
  },
  setCommentsResultsPerPage({commit, dispatch}, limit) {
    commit(COMMENTS_UPDATE_LIMIT, {limit});
    dispatch('getComments');
  },
  setBypassPagination ({commit}, value) {
    commit('SET_BYPASS_PAGINATION', value)
  },
  async clearComments ({ commit }) {
    await commit('CLEAR_COMMENTS')
  },
  async updatingComments ({ commit }, payload) {
    await commit('COMMENTS_UPDATING', payload)
  },
  async revertComment ({ commit, state }, comment) {
    commit('REVERT_COMMENT', comment)
  }
};

const mutations = {
  [COMMENTS_RECEIVED](state, {comments, meta}) {
    state.items = comments;
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0;
  },
  [CONCATENATE_COMMENTS] (state, {comments, meta}) {
    // const toRaw = toRaw(state.items)
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0;
    const mergedArray = [ ...state.items, ...comments];
    const uniqueArray = [];
    const seenIds = new Set();

    for (const obj of mergedArray) {
      if (!seenIds.has(obj.id)) {
        uniqueArray.push(obj);
        seenIds.add(obj.id);
      }
    }
    state.originalListState = cloneDeep(uniqueArray)
    state.items = uniqueArray
  },
  [CONTENT_RECEIVED](state, {content}) {
    state.items = [];
    state.offset = 0;
    state.limit = 20;
    state.total = content.commentCount || 0;
  },
  [COMMENTS_UPDATE_OFFSET](state, {offset}) {
    state.offset = offset;
  },
  [COMMENTS_UPDATE_LIMIT](state, {limit}) {
    state.limit = limit;
    state.offset = 0;

  },
  [CLEAR_COMMENTS] (state) {
    Object.assign(state, getDefaultState())
  },
  [COMMENTS_UPDATING] (state, payload) {
    state.updating = payload
  },
  [EDITING_COMMENT] (state, payload) {
    state.editingComment = payload? payload.comment : null
  },
  [LOADING_COMMENTS] (state, payload) {
    state.loadingComments = payload
  },
  [REVERT_COMMENT] (state, comment) {
    const originalValue = cloneDeep(find(state.originalListState, { id: comment.id}))
    if (!originalValue) return
    let changedIndex = findIndex(state.items, { id: comment.id})
    state.items.splice(changedIndex, 1, originalValue)
  },
  [SET_BYPASS_PAGINATION] (state, value) {
    state.byPassPagination = value
  },
  [UPDATE_ORIGINAL_COMMENT] (state, comment) {
    const index = findIndex(state.originalListState, { id: comment.id})
    if(index < 0) return
    state.originalListState[index] = cloneDeep(comment)
  }
};

export default {
  state,
  actions,
  mutations
};

function getDefaultState() {
  return {
    items: [],
    offset: 0,
    limit: 20,
    total: 0,
    editingComment: null,
    loadingComments: false,
    byPassPagination: false,
    updating: false,
    originalListState: null

  }
}
