import { RequiredWidgetConfig, WidgetConfig, WidgetTypesToIdentifiers, isWidgetLackingIdentifier } from '@/interfaces/global'

export function isValidWidgetConfig(config: WidgetConfig) {
  return (typeof config === "object") 
    && hasRequiredFields(config) 
    && hasIdentifierForType(config)
}

function hasRequiredFields(config: WidgetConfig) {
  return Object.keys(RequiredWidgetConfig)
    .every(field => !!config[field])
}

function hasIdentifierForType(config: WidgetConfig) {
  const { widgetType } = config
  return isWidgetLackingIdentifier(widgetType)
    || !!config[WidgetTypesToIdentifiers[widgetType]]
}