import http from '@/http'

export const getOrderStatusValues = async (orgId = '') => {
  let url = '/order-status-admin'

  if (orgId) {
    url += `?organizationId=${orgId}`
  }

  return http.get(url).then(res => res.data)
}

export const deleteOrderStatus = async (statusId) => {
  return http.delete(`/order-status-admin/${statusId}`).then(res => res.data)
}

export const createOrderStatus = async (payload) => {
  return http.post('/order-status-admin', payload).then(res => res.data)
}

export const updateOrderStatus = async (statusId, payload, orgId = '') => {
  let url = `/order-status-admin/${statusId}`

  if (orgId) {
    url += `?organizationId=${orgId}`
  }
  return http.put(url, payload).then(res => res.data)
}

export const getOrderStatus = async (statusId, orgId = '') => {
  let url = `/order-status-admin/${statusId}`

  if (orgId) {
    url += `?organizationId=${orgId}`
  }
  return http.get(url).then(res => res.data)
}
