import http from '@/http'
import { getSuppliersByTenantId } from '@/controllers/admin/superAdmin'

const SUPPLIERS_RECEIVED = 'SUPPLIERS_RECEIVED'

export default {
  namespaced: true,
  state: {
    suppliers: [],
    loaded: false
  },
  actions: {
    async loadIfMissing ({ state, dispatch }) {
      if (state.loaded) return
      await dispatch("loadSuppliers")
    },
    async loadSuppliers({ commit }) {
      try {
        const { data } = await http.get('/suppliers')
        data.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          } else if (a.name > b.name) {
            return 1
          } else {
            return 0
          }
        })
        commit(SUPPLIERS_RECEIVED, { suppliers: data })
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async loadByTenantId({ commit }, tenantId) {
      try {
        const data = await getSuppliersByTenantId(tenantId)
        commit(SUPPLIERS_RECEIVED, { suppliers: data })
      } catch (error) {
        console.log('error :>> ', error)
      }
    }
  },
  mutations: {
    [SUPPLIERS_RECEIVED] (state, { suppliers }) {
      state.suppliers = suppliers
      state.loaded = true
    }
  }
}