<template>
  <span class="select is-primary">
    <select v-model="operator">
      <option :value=false>{{ $t('contains') }}</option>
      <option :value=true>{{ $t('exact') }}</option>
    </select>
  </span>
</template>

<script>
export default {
  name: 'SearchOperator',
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      isExactMatch: this.value || false
    };
  },
  computed: {
    operator: {
      get() {
        return this.isExactMatch;
      },
      set(value) {
        this.isExactMatch = value;
        this.$emit('change', value);
      }
    }
  }
};
</script>


<style scoped>

</style>
