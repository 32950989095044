import { onBeforeMount, onBeforeUnmount, Ref } from 'vue'

export type EscProps = {
  dismissFunction: Function
  isActive: Ref<boolean>
}

export function useComposableForEsc (props: EscProps) {
  const escEventListener = (event: KeyboardEvent) => {
    if (!props.isActive.value) return

    if (event.key === 'Escape' || event.key === 'Esc') {
      props.dismissFunction()
    }
  }

  onBeforeMount(() => {
    window.addEventListener('keyup', escEventListener)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('keyup', escEventListener)
  })
}
