<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('cancel')" />
    <div data-int="favorites-modal" class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <strong data-int="favorites-modal-title">
            {{ $t('favorites') }}
          </strong>
        </p>
      </header>
      <section class="modal-card-body">
        <p>{{ $t('name') }}: {{ media.name }}</p>
        <p>{{ $t('identifier') }}: {{ media.identifier }}</p>

        <hr />

        <div class="favorites-options">
          <template v-if="shelves.length">
            <div class="option" v-for="(shelf, index) in shelves" :key="index">
              <input type="checkbox"
                     :checked="memberships.includes(shelf.id)"
                     @change="checkboxChanged(shelf.id)" />
              {{ shelf.name }}
            </div>
          </template>
          <template v-else>
            {{ $t('noItemsFound') }}
          </template>
          <div class="new-option">
            <input type="text" v-model="newShelfName" class="input" />
            <button class="button is-primary"
                    :disabled="!newShelfName.length"
                    @click="createNewShelf()">
              {{ $t('new') }}
            </button>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="$emit('cancel')">
          {{ $t('close') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  props: {
    media: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      newShelfName: '',
      shelves: [],
      memberships: []
    };
  },
  created() {
    this.fetchShelves();
    this.fetchMemberships();
  },
  methods: {
    async fetchShelves() {
      try {
        const {data} = await http.get('media-shelves');
        this.shelves = data;
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    async fetchMemberships() {
      try {
        const { data } = await http.get(`medias/${this.media.id}/media-shelves`)
        this.memberships = data;
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    async checkboxChanged(shelfId) {
      try {
        const wasChecked = this.memberships.includes(shelfId);
        if (wasChecked) {
          await http.delete(`medias/${this.media.id}/media-shelves/${shelfId}`)
        } else {
          await http.put(
            `medias/${this.media.id}/media-shelves`,
            shelfId,
            { headers: { 'Content-Type': 'application/json' } }
          )
        }
        await this.fetchMemberships();
        this.$emit('favoritesUpdated');
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    async createNewShelf() {
      const newName = this.newShelfName;
      this.newShelfName = '';

      // Forces the UI to update so that the button that triggers this is disabled.
      // This gives users the impression that things are happening so they don't click again.
      await this.$nextTick();

      const dto = {
        translations: {
          'en-US': newName
        }
      };

      try {
        await http.post('media-shelves', dto);
      } catch (error) {
        console.log('error :>> ', error);
      }

      await this.fetchShelves();
    }
  }
};
</script>

<style scoped>
.modal-card-body {
  min-height: 10vh;
  max-height: 75vh;
  overflow-y: auto;
  display: block;
}

.favorites-options {
  display: block;
}

.option {
}

.new-option {
  display: flex;
  margin-top: 2rem;
}

.new-option > button {
  flex: 0 0 auto;
  margin-left: 1rem;
}

.option > input {
  margin-right: 0.5rem;
}
</style>
