<template>
  <component :is="renderer"
             :column="column"
             :total-retail-price="totalRetailPrice"
             :total-wholesale-price="totalWholesalePrice"
             :total-discounted-price="totalDiscountedPrice"
             :can-edit="canEdit"/>
</template>

<script>
import CartFooterActions from './CartFooterActions';
import CartFooterEmpty from './CartFooterEmpty';
import CartFooterTotal from './CartFooterTotal';

export default {
  name: 'CartFooter',
  components: {
    CartFooterActions,
    CartFooterEmpty,
    CartFooterTotal
  },
  props: {
    column: {
      type: Object,
      required: true
    },
    totalRetailPrice: {
      type: String,
      default: ''
    },
    totalWholesalePrice: {
      type: String,
      default: ''
    },
    totalDiscountedPrice: {
      type: String,
      default: ''
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    renderer() {
      switch (this.column.type) {
        case 'total':
          return 'cart-footer-total';
        default:
          if (this.column.property === 'actions') {
            return 'cart-footer-actions';
          }
          return 'cart-footer-empty';
      }
    }
  }
};
</script>
