import axios, { InternalAxiosRequestConfig, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'
import { store } from '@/store'
import { minLoadCheck } from '@/helpers'
import localforage from 'localforage'
import { WidgetEvent } from '@/classes/WidgetEvent'
import { WidgetEvents } from '@/interfaces/global'
import { FALLBACK_MSG } from '@/components/widgets/composables/useWidgetEvents'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '/api/',
  withCredentials: true,
  validateStatus: (status) => (status >= 200 && status < 300),
  paramsSerializer: (params) => qs.stringify(params, { indices: false })
})

// 401 Interceptor to Reload on 401
http.interceptors.response.use(
  (response) => response,
  async (error) => {
    const is401 = error.response.status === 401
    const { isWidget, isRefreshingToken } = store.state.auth
    const { headers } = error.response
    const domainStatus = headers['domain-status']

    if (is401 && isWidget && !isRefreshingToken 
      && domainStatus === "Invalid Domain") {
      window.parent.postMessage(
        new WidgetEvent({
          action: WidgetEvents.error,
          message: FALLBACK_MSG,
          widgetType: store.getters['widgets/widgetType']
        })
      )
    } else if (is401 && isWidget && !isRefreshingToken) {
      await tryRefresh(error.config)
    } else if (is401 && store.getters['auth/isAuthenticated']) {
      // TODO: Can we do this in a different way!?
      // A window reload isn't ideal because it will
      // have side affects like displaying required
      // reading again.
      return window.location.reload()
    } else {
      return Promise.reject(error)
    }
  }
)

/**
 * Add timestamp to outgoing request. We can use ts-ignore here with confidence.
 */
http.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  // @ts-ignore
  config.metaData = {
    startTime: new Date()
  }
  const csrfToken = await localforage.getItem('csrfToken')
  if (csrfToken) {
    config.headers['csrf-token'] = csrfToken
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

/**
 * Some API calls we do want to resolve as fast as possible.
 * For example, we want to load the app theme ASAP because it
 * blocks the whole app from rendering. See main.js L45-57.
 */
const whiteListedURLs = ['/api/styles/user', '/api/auth/whoami']

http.interceptors.response.use(async (response:AxiosResponse) => {
  if (response.config.url && whiteListedURLs.includes(response.config.url)) return response

  // @ts-ignore
  await minLoadCheck(response.config.metaData.startTime)

  return response
}, (error) => {
  return Promise.reject(error)
})

export default http

async function tryRefresh(config:AxiosRequestConfig) {
  try {
    const originalRequest = config
    await store.dispatch('auth/checkAccessToken')
    const updatedToken = store.state.auth.accessToken
    const paths = (originalRequest.url || '')
      .split(originalRequest.baseURL || '')
    if (updatedToken && originalRequest.headers) {
      originalRequest.headers['Authorization'] = 'Bearer ' + updatedToken
    }
    originalRequest.url = `/${paths[1]}`
    await axios(originalRequest)
  } catch {
    await store.dispatch('auth/setIsRefreshTokenValid', false)
    await store.dispatch('auth/setAuthenticated', { authenticated: false })
  }
}