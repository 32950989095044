export interface DraftTagValue extends Record<string, any> {
    id?: number|null,
    targetEntity?: number,
    tagNameId: number,
    tagName?: string,
    value?: string,
    lowerBoundValue?: number,
    upperBoundValue?: number,
    prefixValue?: string,
    suffixValue?: string,
    tenantId?: number,
    pendingId?: string
}

export enum TagTypes {
    partTag = 'partTag',
    pagePartTag = 'pagePartTag'
}

export interface TagToAdd {
    lowerBoundValue?: number,
    prefixValue?: string,
    pendingId: string,
    suffixValue?: string,
    tagName: string,
    tagNameId: number,
    targetEntity?: number,
    upperBoundValue?: number,
    value?: string
}

interface ImportedTag extends Record<string, any> {
    pendingId?: string,
    tagName?: string,
    tagNameId?: number,
    value?: string,
    tagType?: TagTypes
}

export interface ImportedTags extends Record<string, ImportedTag[]> {
    [key: string]: ImportedTag[]
}

export enum TagFields {
    partTagValues = 'partTagValues',
    pagePartTagValues = 'pagePartTagValues'
}

export interface FieldAndTagsToAdd {
    type: TagFields,
    tagsList: TagToAdd[]
}

export interface FieldAndTagsToDelete {
    type: TagFields,
    idList: number[]
}

export interface FieldAndTagsToUpdate {
    type: TagFields,
    tagsList: TagToEdit[]
}

export interface TagMetaData {
    additionalPartNumber: boolean,
    applicableTypes: string[],
    created: number,
    displayInCart: boolean,
    displayInFilters: boolean,
    displayInPartsList: boolean,
    displayOrder: number,
    facet: boolean,
    highlight: boolean,
    id: number,
    indexed: number,
    inputType: string,
    name: string,
    selectOptionsSortDirection: number,
    selectOptionsSortType: number,
    systemTag: boolean,
    updated: number,
    usedForSearching: boolean,
    usedForTagGroup: boolean,
    wildCardEnabled: boolean
}

export interface TagToEdit extends Record<string, any> {
    displayOrder?: number,
    id: number,
    lowerBoundValue?: number,
    name: string,
    pendingId?: string,
    prefixValue?: string,
    suffixValue?: string,
    tagNameId: number,
    targetEntity: number,
    tenantId: number,
    upperBoundValue?: number,
    value?: string
}

export interface Tag {
    id?: number|null,
    tagName?: string,
    tagNameId: number,
    targetEntity?: number,
    tenantId?: number,
    value?: string,
    lowerBoundValue?: number,
    upperBoundValue?: number,
    lowerBound?: number,
    upperBound?: number,
}

interface TagData {
    tagCount: number,
    tagValues: Tag[]
}

export interface TagDataAndName {
    data: TagData,
    name: string
}

export interface TagsMap {
    [key: string]: TagDataAndName
}

export enum draftTagTypes {
    part = 'part',
    'page-part' = 'page-part'
}

export interface DraftTagState {
    loading: boolean,
    checkedPagePartTags: TagDataAndName[],
    checkedPartTags: TagDataAndName[]
}

export enum checkedTags {
    checkedPagePartTags = 'checkedPagePartTags',
    checkedPartTags = 'checkedPartTags'
}
