const BROWSE_FLOW_SEARCH_TERM = 'BROWSE_FLOW_SEARCH_TERM'
const BROWSE_FLOW_SORT_FIELD = 'BROWSE_FLOW_SORT_FIELD'
const BROWSE_FLOW_SORT_DIR = 'BROWSE_FLOW_SORT_DIR'

const state = {
  searchTerm: '',
  sortField: '',
  sortDir: 'asc'
}

const actions = {
  setSearchTerm (context, data) {
    context.commit('BROWSE_FLOW_SEARCH_TERM', data)
  },
  setSortField (context, data) {
    context.commit('BROWSE_FLOW_SORT_FIELD', data)
  },
  setSortDir (context, data) {
    context.commit('BROWSE_FLOW_SORT_DIR', data)
  }
}

const mutations = {
  [BROWSE_FLOW_SEARCH_TERM] (state, value) {
    state.searchTerm = value
  },
  [BROWSE_FLOW_SORT_FIELD] (state, value) {
    state.sortField = value
  },
  [BROWSE_FLOW_SORT_DIR] (state, value) {
    state.sortDir = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
