<template>
  <tiles  v-if="!isLoading"
          :children="chapterChildren"
          :path="chapterPath"
          class="widget-media-viewer"
          @select="navToViewWithLoading"/>
</template>

<script setup>
import { onMounted, watch } from "vue"
import { useStore } from "vuex"
import Tiles from "@/components/widgets/components/WidgetTiles"
import { WidgetMediaTypes } from "@/interfaces/global/widgets"
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import { useWidgetNavigator } from "@/components/widgets/composables/useWidgetNavigator"

const store = useStore()
const { fetchDecorator, isLoading } = useFetch()
const {  chapterPath, chapterChildren,  
  navToViewWithLoading } = useWidgetNavigator()

async function fetchChapterForVuex() {
  if (!chapterPath.value) return
  const params = {
    id: chapterPath.value,
    type: WidgetMediaTypes.book
  }
  await store.dispatch("content/getContent", params)
}
async function fetchForVuexWithSpinners() {
  await fetchDecorator(fetchChapterForVuex)()
}

onMounted(fetchForVuexWithSpinners)
watch(chapterPath, fetchForVuexWithSpinners)
</script>