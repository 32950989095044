<template>
  <th data-int="cart-header-subChapter">
    {{ $t('itemName', { item: $tc('subChapter', 1)}) }}
  </th>
</template>

<script>
export default {
  name: 'CartHeaderSubChapter'
}
</script>
