import http from '@/http'
import { UserGroup, UserGroupUser, NewUserGroupRequest, Headers } from '@/interfaces/admin'

export async function getUserGroups (queryParam = ''): Promise<Array<UserGroup>> {
  const { data } = await http.get(`/user-groups${queryParam}`)
  return data
}

export async function getUserGroupsForSuperAdmin (tenantId: number): Promise<Array<UserGroup>> {
  const params = {
    tenantId: tenantId
  }
  const { data } = await http.get(`/user-groups/super-admin`, { params })
  return data
}

export async function getUserGroup (id: number): Promise<UserGroup> {
  const { data } = await http.get(`user-groups/${id}`)
  return data
}

export async function updateUserGroup (id: number, dto: UserGroup): Promise<UserGroup> {
  const { data } = await http.put(`user-groups/${id}`, dto)
  return data
}

export async function saveUserGroup (dto: NewUserGroupRequest): Promise<UserGroup> {
  const { data } = await http.post('user-groups', dto)
  return data
}

export async function getNextUsersInGroup (id: number, params: Object): Promise<UserGroupUser> {
  const { data, headers } = await http.get(`user-groups/${id}/users`, params)
  return { data, headers: headers as Headers}
}
