import http from '@/http';

const COUNTRY_CODES_RECEIVED = 'COUNTRY_CODES_RECEIVED';

const state = {
  items: {},
  isLoaded: false
};

const actions = {
  async getCountryCodes({commit}) {
    try {
      if (!state.isLoaded) {
        const {data} = await http.get('countries');
        commit(COUNTRY_CODES_RECEIVED, {countries: data});
      }
    } catch (err) {
      console.log('err :>> ', err);
    }
  }
};

const mutations = {
  [COUNTRY_CODES_RECEIVED](state, {countries}) {
    const items = [];
    Object.keys(countries).forEach((key) => {
      items.push({
        key,
        name: countries[key]
      });
    });
    state.items = items;
    state.isLoaded = true;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
