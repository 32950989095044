<template>
  <div class="pagination"
       v-if="selectedImage">
    <div class="control left-arrow"
         :class="{'disable': disableLeftArrow}"
         @click="selectMinus()">
       <span class="fas fa-arrow-left button"/>
    </div>
    <div class="counter-window control">
      <span class="button">
        {{currentImageNumber}} of {{imagesArray.length}}
      </span>
    </div>
    <div class="control right-arrow"
         :class="{'disable': disableRightArrow}"
         @click="selectPlus()">
      <span class="fas fa-arrow-right button"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImagePaginator',
  props: {
    imagesArray: {
      type: Array,
      default: () => []
    },
    selectedImg: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    selectedImage: null,
    interval: null
  }),
  watch: {
    selectedImg() {
      this.selectedImage = this.imagesArray[this.selectedImg.displayOrder]
      this.interval = this.selectedImg.displayOrder
    }
  },
  methods: {
    selectPlus () {
      if (this.disableRightArrow) return
      this.selectedImage = this.imagesArray[this.interval + 1]
      this.interval++
      this.$emit('selected-image', this.selectedImage)
    },
    selectMinus () {
      if (this.disableLeftArrow) return
      this.selectedImage = this.imagesArray[this.interval - 1]
      this.interval--
      this.$emit('selected-image', this.selectedImage)
    }
  },
  computed: {
    currentImageNumber () {
      return this.selectedImage.displayOrder + 1
    },
    disableLeftArrow () {
      return this.interval === 0
    },
    disableRightArrow () {
      return this.interval === (this.imagesArray.length - 1)
    }
  },
  created () {
    this.selectedImage = this.imagesArray[this.selectedImg.displayOrder]
    this.interval = this.selectedImg.displayOrder
  }
}
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  flex-direction: row;

  .control {
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: inherit;

    &.disable {
      background-color: white;
      border-color: #dbdbdb;
      box-shadow: none;
      opacity: 0.5;
    }
  }

  .left-arrow {
    .button {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  .right-arrow {
    .button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  .counter-window {
    .button {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  .counter-window {
    text-align: center;
    margin: 0 auto;
  }
  .disable {
    color: var(--grey-dark--50--rgba);
  }

}
</style>
