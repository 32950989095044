import http from '@/http'
import { Page, Translation } from '@/interfaces/admin'

export const getPage = async (pageId: number): Promise<Page> => {
  return http.get(`/admin/pages/${pageId}`).then(res => res.data)
}

export const copyPage = async (request: CopyPageRequest): Promise<PageCopyResultDto> => {
  return http.post('/admin/pages/copy', request).then(res => res.data)
}

export const deletePage = async (pageId: number) => {
  return http.delete(`/admin/pages/${pageId}`)
}

export const getPageUsage = async (pageId: number): Promise<PageUsageStats> => {
  return http.get(`/admin/pages/${pageId}/page-usage`).then(res => res.data)
}

export interface PageUsageStats {
  pagePartCount: Number,
  mediaPageCount: Number,
  templatePageCount: Number
}

export interface CopyPageRequest {
  pageName: String,
  pageId: Number,
  translations: Translation[]
}

export interface PageCopyResultDto {
  pageId: number,
  pageFile: String,
  pagePath: String,
  translations: Translation[],
  secondaryPages: Map<String, any>[]
}
