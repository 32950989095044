<template>
  <div class="stepper-button-wrap">
    <stepper-btn  :disabled="disablePrevious"
                  icon="fa-arrow-left"
                  @click="emit('goToPrevious')"/>
    <stepper-btn  :disabled="disableNext"
                  icon="fa-arrow-right"
                  @click="emit('goToNext')"/>
  </div>
</template>

<script setup>
import StepperBtn from './StepperBtn'

defineProps({
  disablePrevious: Boolean,
  disableNext: Boolean
})

const emit = defineEmits(['goToPrevious', 'goToNext'])
</script>
<style scoped>
.stepper-button-wrap {
  min-width: 6em;
}
</style>
