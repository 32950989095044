<template>
  <component  :is="renderer"
              :column="column"
              :has-hide-price="hasHidePrice"
              :hide-retail-price="hideRetailPrice"
              :hide-discount-price="hideDiscountPrice"
              :hide-whole-sale-price="hideWholeSalePrice"
              @togglePrice="toggleHidePriceFromBom">
    <slot />
  </component>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BomHeaderActions from './BomHeaderActions'
import BomHeaderItem from './BomHeaderItem'
import BomHeaderIndicator from './BomHeaderIndicator'
import BomHeaderName from './BomHeaderName'
import BomHeaderPartNumber from './BomHeaderPartNumber'
import BomHeaderPrice from './BomHeaderPrice'
import BomHeaderQuantity from './BomHeaderQuantity'
import BomHeaderTag from './BomHeaderTag'

export default {
  name: 'BomHeader',
  props: {
    column: {
      type: Object,
      required: true
    }
  },
  components: {
    BomHeaderActions,
    BomHeaderItem,
    BomHeaderIndicator,
    BomHeaderName,
    BomHeaderPartNumber,
    BomHeaderPrice,
    BomHeaderQuantity,
    BomHeaderTag
  },
  computed: {
    ...mapGetters({
      hasHidePrice: 'user/hasHidePrice'
    }),
    ...mapState({
      hideRetailPrice: ({ user }) => user.pricing.hideRetailPrice,
      hideDiscountPrice: ({ user }) => user.pricing.hideDiscountPrice,
      hideWholeSalePrice: ({ user }) => user.pricing.hideWholeSalePrice
    }),
    renderer () {
      switch (this.column.type) {
        case 'price':
          return 'bom-header-price'
        case 'tag':
          return 'bom-header-tag'
        default:
          return `bom-header-${this.column.property}`
      }
    }
  },
  methods: {
    toggleHidePriceFromBom (propertyObject) {
      this.$store.dispatch('user/toggleHidePriceFromBom', propertyObject)
      this.$emit('togglePrice', propertyObject)
    }
  }
}
</script>
