<template>
  <div class="field has-addons">
    <div class="control control-left">
      <date-input class="date min-date"
                  :filter="{name: $i18n.t('startDate')}"
                  :value="min"
                  @change="changeMin"/>
    </div>
    <div class="control">
      <date-input class="date max-date"
                  :filter="{name: $i18n.t('endDate')}"
                  :value="max"
                  :show-icon="false"
                  @change="changeMax"/>
    </div>
    <div class="control">
      <a class="button" @click="removeFilter">
        <span class="icon is-small">
          <i class="fa fa-times" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import DateInput from '@/components/common/DateInput';
import {getTime} from 'date-fns';

export default {
  name: 'DateFilter',
  components: {
    DateInput
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      min: null,
      max: null
    };
  },
  watch: {
    selectedValues() {
      const [first, second] = this.selectedValues;
      const firstInt = parseInt(first, 10);
      const secondInt = parseInt(second, 10);

      this.min = firstInt <= secondInt ? firstInt : secondInt;
      this.max = firstInt >= secondInt ? firstInt : secondInt;
    }
  },
  methods: {
    changeMin(value) {
      const date = value ? getTime(value) : '';
      if (this.min !== date) {
        this.min = date;
        if (!this.max) {
          this.max = date;
        }
        if (this.min > this.max) {
          this.min = this.max;
          this.max = date;
        }
        this.$emit('change', [this.min, this.max]);
      }
    },
    changeMax(value) {
      const date = value ? getTime(value) : '';
      if (this.max !== date) {
        this.max = date;
        if (!this.min) {
          this.min = date;
        }
        if (this.min > this.max) {
          this.max = this.min;
          this.min = date;
        }
        this.$emit('change', [this.min, this.max]);
      }
    },
    removeFilter() {
      this.$emit('remove');
    }
  }
};
</script>

<style scoped>
.min-date {
  max-width: 8.5rem;
}
.max-date {
  max-width: 7rem;
}
</style>
