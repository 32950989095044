import { getTenantPrintSettings } from '@/controllers'
import mutations from '../mutations'

export default {
  namespaced: true,
  state: {
    data: null,
    loading: false,
    error: null,
    searchTerm: '',
    sort: {
      field: 'name',
      order: 'asc'
    }
  },
  mutations: {
    ...mutations,
    'SET_SEARCH_TERM' (state, payload) {
      state.searchTerm = payload
    },
    'REMOVE_DELETED_SETTING' (state, payload) {
      state.data = state.data.filter(item => item.id !== payload)
    },
    'SET_SORT' (state, payload) {
      Object.assign(state.sort, payload)
    }
  },
  actions: {
    async load (context) {
      try {
        context.commit('DATA_REQUEST')
        const result = await getTenantPrintSettings()

        context.commit('DATA_SUCCESS', result)
      } catch (error) {
        console.log('error', error)

        context.commit('DATA_ERROR', error)
      }
    },
    setSearchTerm (context, term) {
      context.commit('SET_SEARCH_TERM', term)
    },
    removeDeletedSetting (context, id) {
      context.commit('REMOVE_DELETED_SETTING', id)
    },
    setSort (context, data) {
      context.commit('SET_SORT', data)
    }
  },
  getters: {
    filteredSettings: state => {
      if (state.searchTerm.length && state.data) {
        return state.data.filter(item => item.name.toLowerCase().includes(state.searchTerm.toLowerCase()))
      }

      return null
    }
  }
}
