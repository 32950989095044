<template>
  <div v-if="contentLoaded"
       class="columns is-marginless">
    <content-preview class="figure"
                     :content="content" />
    <div class="column info-content-container">
      <article>
        <div class="media-content">
          <div class="content">
            <p>
              <strong data-testid="name">{{ content.name }}</strong>
              <br v-if="identifier">
              <span v-if="identifier"
                    class="has-text-weight-semibold"
                    data-testid="identifier">
                {{ identifier }}
              </span>
            </p>
            <p v-if="content.description">
              {{ content.description }}
            </p>
            <div v-if="createdDate"
                 data-testid="created">
              {{ $t('createdOn') }}: {{ createdDate }} <br>
            </div>
            <div v-if="updatedDate"
                 data-testid="updated">
              {{ $t('lastUpdatedOn') }}: {{ updatedDate }}
            </div>
          </div>
        </div>
      </article>
      <slot name="content">
        <template v-if="loading">
          <utility-block />
        </template>
        <template v-else>
          <info-add-to-cart v-if="showInfoAddToCartBlock"
                            :add-to-cart-qty-behavior="addToCartQtyBehavior"
                            :content="content"
                            :display-availability="displayAvailability"
                            :display-discounted="displayDiscounted"
                            :display-eta="displayEta"
                            :display-retail="displayRetail"
                            :display-whole-sale="displayWholeSale"
                            :hasWidgetCart="isWidgetCartEnabled"
                            :is-cart-or-erp-cart-enabled="showAddToCartButtons"
                            :pricing="pricing || {}"
                            @widgetAddToCart="handleWidgetAddToCart" />
          <template v-if="showTags">
            <hr>
            <info-tags :id="content.id"
                       :type="content.type"/>
          </template>
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import ContentPreview from '@/components/library/content/info/ContentPreview.vue'
import InfoAddToCart from './InfoAddToCart'
import InfoTags from './tags/InfoTags'
import { mapGetters } from 'vuex'
import { useWidgetAddToCart } from '@/components/widgets/composables/useWidgetAddToCart'
import { UtilityBlock, Thumbnail } from '@/components/common'

export default {
  name: 'Info',
  persistentData: {
    showNewAdmin: {
      default: false
    }
  },
  props: {
    addToCartQtyBehavior: {
      type: String,
      default: ''
    },
    content: {
      type: Object,
      required: true
    },
    contentLoaded: {
      type: Boolean,
      required: true
    },
    displayAvailability: {
      type: Boolean
    },
    displayDiscounted: {
      type: Boolean
    },
    displayEta: {
      type: Boolean
    },
    displayRetail: {
      type: Boolean
    },
    displayWholeSale: {
      type: Boolean
    },
    isCartEnabled: {
      type: Boolean
    },
    isCartOrErpCartEnabled: {
      type: Boolean
    },
    pricing: {
      type: Object,
      default: () => {}
    },
    showTags: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const { handleWidgetAddToCart } = useWidgetAddToCart()

    return { handleWidgetAddToCart }
  },
  components: {
    ContentPreview,
    InfoAddToCart,
    InfoTags,
    Thumbnail,
    UtilityBlock
  },
  watch: {
    contentLoaded () {
      this.$emit('getPricing')
    }
  },
  computed: {
    ...mapGetters({
      isWidget: 'widgets/isWidget',
      isWidgetCartEnabled: 'widgets/isWidgetCartEnabled',
    }),
    createdDate () {
      return this.content.created ? this.$i18n.d(this.content.created, 'short') : ''
    },
    entityType () {
      return (this.content.contentType || this.content.type ||
              this.content.mediaType || this.content.entityType)
    },
    identifier () {
      if (this.content.partNumber) {
        return `${this.$i18n.t('partNumber')}: ${this.content.partNumber}`
      } else if (this.content.identifier) {
        if (this.entityType === 'Part') {
          return `${this.$i18n.t('partNumber')}: ${this.content.identifier}`
        }
        return `${this.$i18n.t('identifier')}: ${this.content.identifier}`
      }
      return ''
    },
    loading () {
      return (this.$store.getters['widgets/isWidget'])
        ? false
        : (!this.$store.state.cart.isLoaded && this.isCartEnabled)
          || !this.pricing.isLoaded

    },
    showAddToCartButtons() {
      return !(this.content.partNumber && this.content.orderable) ? false
        : this.isWidget ? this.isWidgetCartEnabled
        : this.isCartOrErpCartEnabled
    },
    showInfoAddToCartBlock() {
      return (this.content.orderable && !!this.content.partNumber)
        && (this.isWidget ? this.isWidgetCartEnabled : this.pricing.isLoaded)
    },
    updatedDate () {
      return this.content.updated ? this.$i18n.d(this.content.updated, 'short') : ''
    }
  },
  methods: {
    addToCart (partWithQuantityObject) {
      this.$emit('addToCart', partWithQuantityObject)
    }
  }
}
</script>

<style scoped>
hr {
  margin: .25rem 0;
}
article {
  flex: 0 0 auto;
}
.columns {
  overflow: auto;
  width: 100%;
}
.info-content-container {
  flex: 1;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  margin: .5rem 1rem;
}
.figure {
  margin: 0.5rem;
}
.info-thumbnail {
  width: 256px;
  height: 256px;
}
@media only screen and (max-width: 1120px) {
  .info-thumbnail {
    width: 200px;
    height: 200px;
  }
  .figure, .info-content-container {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .info-content-container {
    align-items: flex-start;
  }
}
</style>
