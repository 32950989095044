/* eslint-disable max-len */
module.exports =
{
  about: 'About',
  account: 'Account',
  actualSize: 'Actual Size',
  add: 'Add',
  addComment: 'Add a comment',
  addToCartMessage: 'Successfully added {partNumber}',
  address: 'Address',
  admin: 'Admin',
  all: 'All',
  any: 'Any',
  april: 'April',
  assembly: 'Assembly | Assemblies',
  asset: 'Asset | Assets',
  assetsNotFound: 'No {type} Found',
  attachment: 'Attachment | Attachments',
  attributions: 'Attributions',
  august: 'August',
  automaticZoom: 'Automatic Zoom',
  availability: 'Availability',
  backToSearch: 'Back to Search',
  badCredentials: 'Bad Credentials',
  bomLayout: 'Layout of Bill of Materials',
  book: 'Book | Books',
  bookLabel: 'Book | Books',
  browse: 'Browse',
  browserPartiallySupport: 'You may see aesthetic differences and other changes in this browser.',
  cancel: 'Cancel',
  canceled: 'Canceled',
  cart: 'Cart | Carts',
  cartErpMessage: 'Successfully sent to Erp',
  cartImportError: 'Error importing cart',
  category: 'Category | Categories',
  chapter: 'Chapter | Chapters',
  chaptersOnly: 'Chapters Only',
  city: 'City',
  classicAdminMessage: 'This will direct you back to Flex to use the admin modules.',
  classicAdmins: 'Classic Admins',
  clear: 'Clear',
  clickToDownload: 'Click to Download',
  clickToOpen: 'Click to Open',
  close: 'Close',
  closed: 'Closed',
  collapseAll: 'Collapse All',
  comingSoon: 'Coming Soon',
  comment: 'Comment | Comments',
  completed: 'Completed',
  contains: 'Contains',
  copyMeMessage: 'Copy me on this message',
  copyrightMessage: '© 2010-{year} Documoto Inc. All rights reserved.',
  country: 'Country | Countries',
  createdOn: 'Created on',
  currentPassword: 'Current Password',
  dateModified: 'Date Modified',
  december: 'December',
  defaults: 'Defaults',
  delete: 'Delete',
  deleteMessage: 'Are you sure you want to delete?',
  description: 'Description',
  discounted: 'Discounted',
  discountedTotal: 'Discounted Total',
  document: 'Document | Documents',
  documentLabel: 'Document | Documents',
  download: 'Download',
  downloadBrowser: 'Download one of these great browsers.',
  duplicate: 'Duplicate',
  email: 'Email',
  emailMessage: 'An email is required',
  endDate: 'End Date',
  erp: 'ERP',
  error: 'Error',
  errorPageMessage: 'Not all who wander are lost... But you certainly are.',
  errorPageTitle: "Something's wrong here...",
  eta: 'ETA',
  exact: 'Exact',
  expandAll: 'Expand All',
  export: 'Export',
  exportAsCsv: 'Export as CSV',
  exportAsPdf: 'Export as PDF',
  exportAsPlz: 'Export as PLZ',
  exportJob: 'Export Job',
  exportMessage: 'Successfully submitted export job.',
  exporter: 'Exporter',
  exporterMessage: 'Allows you to export content',
  external: 'External | Externals',
  failedToLoad: '{content} failed to load.',
  february: 'February',
  feedbackForm: 'Feedback Forum',
  feedbackFormMessage: 'Provide input on features you would like to see',
  fields: 'Fields',
  filter: 'Filter | Filters',
  filterBy: 'Filter By',
  firstName: 'First Name',
  forgotPassword: 'Forgot Password',
  fri: 'Fri',
  from: 'From',
  fromDate: 'From Date',
  goToHome: 'Go to Home',
  goToJobManager: 'Go to Job Manager',
  goToSearch: 'Go to Search',
  help: 'Help',
  history: 'History',
  horizontal: 'Horizontal',
  identifier: 'Identifier',
  illustration: 'Illustration | Illustrations',
  image: 'Image | Images',
  imageOptimizer: 'Image Optimizer',
  import: 'Import',
  includeImages: 'Include Images',
  includePartsList: 'Include Parts List',
  includeSubChapters: 'Include Sub Chapters',
  info: 'Info',
  information: 'Information',
  invalidField: '{field} is invalid',
  item: '#',
  itemNotShown: '',
  january: 'January',
  job: 'Job | Jobs',
  jobManager: 'Job Manager',
  jobManagerMessage: 'Export, publish, and print/download jobs',
  july: 'July',
  june: 'June',
  landscape: 'Landscape',
  language: 'Language',
  lastName: 'Last Name',
  lastUpdatedOn: 'Last updated on',
  ledger: 'Ledger',
  legal: 'Legal',
  letter: 'Letter',
  line: 'Line',
  login: 'Login',
  loginSSO: 'Login SSO',
  logout: 'Logout',
  march: 'March',
  may: 'May',
  merge: 'Merge',
  mergeOrOverrideMessage: 'Would you like to overwrite or merge these items?',
  message: 'Message',
  messageMessage: 'A message is required',
  mine: 'Mine',
  mon: 'Mon',
  name: 'Name',
  new: 'New',
  newPassword: 'New Password',
  noItemsFound: 'No Items Found',
  noPrice: 'Call for Price',
  noUnsavedChanges: 'No Unsaved Changes',
  none: 'None',
  notImplemented: 'Not Implemented',
  notification: 'Notification | Notifications',
  notificationsMessage: 'Important announcements and news',
  november: 'November',
  october: 'October',
  of: 'of',
  ok: 'Ok',
  open: 'Open',
  order: 'Order | Orders',
  orderNumber: 'Order Number',
  orderSubmittedMessage: 'Successfully Submitted',
  ordersMessage: 'Order submission history',
  organization: 'Organization | Organizations',
  organizationAdmin: 'Organization Admin',
  organizationAdminMessage: 'Controls user management and org settings',
  override: 'Override',
  owner: 'Owner | Owners',
  page: 'Page | Pages',
  pageFile: 'Page File',
  pageFit: 'Page Fit',
  pageLabel: 'Page | Pages',
  pageWidth: 'Page Width',
  part: 'Part | Parts',
  partCodesMessage: "Part codes can be used in place of part numbers, and are often used to indicate when BOM items are obsolete, not available, or are reference only items (ex. 'PNNA', '-', 'REF').",
  partLabel: 'Part | Parts',
  partName: 'Part Name',
  partNumber: 'Part Number',
  partRequired: 'At least one part is required',
  partialSupport: 'Partial Support',
  password: 'Password',
  passwordBlankMessage: 'Password cannot be blank',
  passwordLowerCaseMessage: 'Password must contain at least one upper case character',
  passwordMinLengthMessage: 'Passwords must be at least 8 characters',
  passwordNotMatchingMessage: 'Passwords are not the same',
  passwordSpecialCharactersMessage: 'Password must contain at least one special character',
  passwordUpperCaseMessage: 'Password must contain at least one lower case character',
  portrait: 'Portrait',
  postComment: 'Post Comment',
  postalCode: 'Postal Code',
  preferences: 'Preferences',
  printJob: 'Print Job',
  processing: 'Processing',
  profile: 'Profile',
  province: 'Province',
  publish: 'Publish',
  publisher: 'Publisher',
  publisherJob: 'Publisher Job',
  publisherMessage: 'Content creation, management, and print settings',
  purchaseOrder: 'Purchase Order',
  qty: 'Qty',
  quickAdd: 'Quick Add',
  recentlyAdded: 'Recently Added',
  recentlyViewed: 'Recently Viewed',
  related: 'Related',
  rememberMe: 'Remember me',
  reportAnIssue: 'Report an Issue',
  reportAnIssueMessage: 'Successfully sent email.',
  reporting: 'Reporting',
  reportingMessage: 'Access to various reports and metrics',
  requestForQuote: 'Request for Quote',
  requiredReading: 'Required Reading',
  requiredReadingMessage: 'Please review the following:',
  reset: 'Reset',
  resetPassword: 'Reset Password',
  resetPasswordError: 'There was a problem resetting your password, please try again.',
  resetPasswordTokenError: 'Password reset token is invalid.',
  result: 'Result | Results',
  resultFor: 'Result for {item} | Results for {item}',
  retail: 'Retail',
  retailTotal: 'Retail Total',
  sat: 'Sat',
  save: 'Save',
  search: 'Search',
  searchPrompt: 'Search ...',
  section: 'Section',
  selectAField: 'Select a {field}',
  send: 'Send',
  september: 'September',
  settings: 'Settings',
  settingsMessage: 'Update your user preferences and password',
  shared: 'Shared',
  showLess: 'Show Less',
  showMore: 'Show More',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Most Relevant',
  startDate: 'Start Date',
  state: 'State',
  status: 'Status',
  subject: 'Subject',
  subjectMessage: 'A Subject is required',
  submit: 'Submit',
  submitPurchaseOrder: 'Purchase Order',
  submitRequestForQuote: 'Request for Quote',
  submitted: 'Submitted',
  submittedBy: 'Submitted by',
  suggestion: 'Suggestion | Suggestions',
  sun: 'Sun',
  supplier: 'Supplier',
  system: 'System',
  systemAdmin: 'System Admin',
  tabloid: 'Tabloid',
  tag: 'Tag | Tags',
  tagsDisplayMore: 'Displaying first 100 values. Use filter to view more.',
  tenant: 'Tenant | Tenants',
  tenantAdmin: 'Tenant Admin',
  tenantAdminMessage: 'Controls tenant wide settings',
  thu: 'Thu',
  thumbnail: 'Thumbnail',
  toDate: 'To Date',
  toc: 'Toc',
  tocState: 'Initial State of Table of Contents',
  total: 'Total',
  tue: 'Tue',
  twoSided: '2-sided',
  type: 'Type',
  unauthorized: 'Unauthorized',
  unsupportedBrowserMessage: 'We built this application with the latest technology. This makes the application faster and easier to use. Unfortunately, your browser does not support those technologies.',
  uom: 'UOM',
  update: 'Update',
  updateIndex: 'Update Index',
  updatePassword: 'Update Password',
  updatePasswordMessage: 'Successfully updated password',
  upgradeBrowser: 'Please upgrade your browser.',
  user: 'User',
  username: 'Username',
  verifyPassword: 'Verify Password',
  vertical: 'Vertical',
  video: 'Video | Videos',
  wed: 'Wed',
  whereUsed: 'Where Used',
  wholesale: 'Wholesale',
  wholesaleTotal: 'Wholesale Total',
  zipCode: 'Zip Code'
}
