<template>
  <div  v-if="content.isLoaded"
        style="min-height: fit-content;"
        class="library-content-header">
    <slot name="navigation">
      <div class="navigation" :class="{ 'details': isDetailsPage }">
        <h3 v-if="!isMobile"
            class="title-five">
          {{ content.name }}
        </h3>
        <bulma-button-icon  v-if="!isDetailsPage && !isWidgetInfoPage"
                            iconClass="fas fa-info-circle links-color"
                            class="pointer-on-hover mr-1 info-btn"
                            :disable="noDetailInfo"
                            :text="$t('moreInfo')"
                            @click="emit('infoButtonClicked')"/>
        <bulma-button-icon  v-if="isDetailsPage && !hideBackNavigation"
                            iconClass="fas fa-arrow-left links-color"
                            class="pointer-on-hover mr-1 return-btn"
                            :text="$t('returnToViewer')"
                            @click="emit('backButtonClicked')"/>
      </div>
    </slot>
    <slot name="contentActions">
      <div class="content-action-wrapper">
        <content-actions :content="content"
                        :book="book"
                        :isDetailsPage="isDetailsPage"
                        :has-disabled-book-printing="disableBookPrinting"
                        :has-disabled-printing="disablePrinting"
                        :report-an-issue-enabled="reportAnIssueEnabled"/>
      </div>
    </slot>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { watch, ref, onMounted, computed } from 'vue'
import BulmaButtonIcon from "@/components/common/BulmaButtonIcon.vue"
import { useStore } from "vuex"
import contentActions from "@/components/library/content/ContentActions.vue"
import { useBreakpoints } from "@/helpers/responsiveBreakpoints"
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
const { hasInfoPageOpen } = useInfoLandingPage()
const route = useRoute()
const store = useStore()

const props = defineProps({
  title:{
    type: String,
    required: true
  },
  content: {
    type: Object,
    required: true
  },
  hideBackNavigation: Boolean
})

const isDetailsPage = ref(false)
const book = computed(() => store.state.content.toc.book)
const isWidget = computed(() =>  store.state.auth.isWidget)
const reportAnIssueEnabled = computed( () => store.state.user.contactOwnerEnabled)
const disableBookPrinting = computed(() => store.getters['user/hasDisableBookPrinting'] || book.value?.disableExport )
const disablePrinting = computed(() =>  store.getters['user/hasDisablePrinting'] || props.content?.disableExport)
const noDetailInfo = computed(() => {
  return props.content.type !== 'page'
    ? !props.content.tags?.total && !props.content.comments?.total && !props.content.related?.total
    : false
})
const { width } = useBreakpoints()
const isMobile = computed(() => {
  return width.value <= 768
})

const isWidgetInfoPage = computed(() => {
  return isWidget.value
    ? hasInfoPageOpen.value
    : false
})

function setPageName(route) {
  isDetailsPage.value = route.path.includes('details')
}
watch([route], ([x,y,z]) => {
  setPageName(x)
})
onMounted(() => {
  setPageName(route)
})
const emit = defineEmits(['infoButtonClicked, backButtonClicked'])

</script>
