<template>
  <span class="button"
        :disabled="disabled"
        @click="emit('click')">
    <i :class="['fas', icon]" />
  </span>
</template>

<script setup>
defineProps({
  icon: { type: String, default: "" },
  disabled: Boolean
})
const emit = defineEmits(['click'])
</script>