import { DraftImage } from '@/interfaces/admin'
import { Hotpoint } from '@/interfaces/admin/hotpoint'
import { v4 as uuidv4 } from 'uuid'

export default {
  async configureImages (newImages: DraftImage[]): Promise<[ newImages: DraftImage[], originalRadius: Array<number>]> {
    const _this = this
    let originalRadius: Array<number> = []
    for(let i = 0; i < newImages.length; i++) {
      newImages[i].refKey = uuidv4()
      if(newImages[i] && newImages[i].imageHotPoints && newImages[i].imageHotPoints!.length > 0) {
        let index = Number(newImages[i].displayOrder!)
        originalRadius[index] = Math.ceil(newImages![i].imageHotPoints![0].shapeRadiusX)
        newImages[i].imageHotPoints = await _this.setNewIds(newImages[i].imageHotPoints!)
      }
    }
    return [ newImages, originalRadius ]
  },
  setNewIds:  async (hotpoints: Array<Hotpoint>) => {
    return hotpoints.map(hotpoint => {
      return {
        ...hotpoint,
        oId: hotpoint.id,
        id: uuidv4()
      }
    })
  }
}
