import mutations from '../mutations';
import actions from '../actions';

export default {
  namespaced: true,
  state: {
    data: null,
    loading: false,
    error: null
  },
  mutations,
  actions
};
