import { Cart } from '@/interfaces/library'
import { getCurrentInstance } from 'vue'
import { asyncFetcher } from '@/store'
import i18n from '@/locales'
import qs from 'qs'
import http from '@/http'
import { notify } from "@kyvg/vue3-notification"
import { postError } from '@/helpers/notification'

export interface PartLocation {
  bookId?: number
  chapterId?: number
  subChapterId?: number
  pageId?: number
}

export interface CartItemRequest {
  partId: number
  qty: number
  bookId?: number
  chapterId?: number
  subChapterId?: number
  pageId?: number
}

export const getCart = async (): Promise<Cart> => {
  return asyncFetcher('cart/async', null, null,
    'get', 'cart/async-poll', 'cartDto', 1000,
    1000, 600000, (message: string) => {
      postError({
        title: i18n.global.t('error'), //i18n.global.t
        text: message,
      })
    })
}

export const getSuggestions = async (size: number): Promise<any> => {
  const params = {
    limit: size,
    offset: 0
  }

  return http.get('cart/suggestions', { params })
}

export const addToInternalCart = async (request: CartItemRequest): Promise<Cart> => {
  const opts = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  return asyncFetcher('cart/async', qs.stringify(request), opts,
    'post', 'cart/async-poll', 'cartDto',
    1000, 1000, 600000, (message: string) => {
      postError({
        title: i18n.global.t('error'),
        text: message,
      })
    })
}

export const addToErpCart = async (
  part: any,
  qty: string,
  toastDuration: number,
  partLocation?: PartLocation
): Promise<void> => {
  const formData = new FormData()
  formData.append('partId', part.id.toString())
  formData.append('qty', qty.toString())

  if (partLocation) {
    if (partLocation.bookId) {
      formData.append('mediaIdentifier', partLocation.bookId.toString())
    }
    if (partLocation.chapterId) {
      formData.append('chapterIdentifier', partLocation.chapterId.toString())
    }
    if (partLocation.subChapterId) {
      formData.append('subChapterIdentifier', partLocation.subChapterId.toString())
    }
    if (partLocation.pageId) {
      formData.append('pageIdentifier', partLocation.pageId.toString())
    }
  }

  return await asyncFetcher('erp/shopping-cart-item-async', formData, null,
    'post', 'erp/shopping-cart-async-poll', null, 1000,
    1000, 600000, (message: string) => {
      postError({
        title: i18n.global.t('error'),
        text: message,
      })
    }, (resp: any) => {
      if (toastDuration > 0) {
        let message = i18n.global.t('cartErpMessage', { partNumber: part.partNumber })
        if (resp.headers && resp.headers['x-message']) {
          message = resp.headers['x-message']
        }
        notify({
          title: i18n.global.t('cart'),
          text: message,
          type: 'success',
          duration: toastDuration
        })
      }
    })
}

export const sendToErp = async (): Promise<any> => {
  return await asyncFetcher('erp/shopping-cart-async', null, null,
    'post', 'erp/shopping-cart-async-poll', null, 1000,
    1000, 600000, (message: string) => {
      postError({
        title: i18n.global.t('error'),
        text: message,
      })
    }, (resp: any) => {
      return resp.headers
    })
}

export const removeFromCart = async (request: CartItemRequest): Promise<void> => {
  const opts = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  await asyncFetcher('cart/async', qs.stringify(request), opts,
    'put', 'cart/async-poll', 'cartDto',
    1000, 1000, 600000, (message: string) => {
      postError({
        title: i18n.global.t('error'),
        text: message,
      })
    })
}

export const updateQuantity = async (request: CartItemRequest): Promise<Cart> => {
  const opts = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  return await asyncFetcher('cart/async', qs.stringify(request), opts,
    'put', 'cart/async-poll', 'cartDto', 1000,
    1000, 600000, (message: string) => {
      postError({
        title: i18n.global.t('error'),
        text: message,
      })
    })
}

export const clearCart = async (): Promise<void> => {
  await http.delete('cart')
}

export const importCart = async (attachment: any, overwrite: boolean): Promise<void> => {
  const formData = new FormData()
  formData.append('files', attachment, attachment.name)

  if (overwrite) {
    formData.append('clear', 'true')
  }

  await http.put('cart/csv', formData)
}

export const openSavedCart = async (cartId: number, overwrite: boolean): Promise<Cart> => {
  const formData = new FormData()
  if (overwrite) {
    formData.append('overwrite', 'true')
  }

  return await asyncFetcher(`cart/saved/async/${cartId}`, formData,
    null, 'post', 'cart/async-poll',
    'cartDto', 1000, 1000, 600000,
    (message: string) => {
      postError({
        title: i18n.global.t('error'),
        text: message,
      })
    })
}

export const openSharedCart = async (cartId: number, overwrite: boolean): Promise<Cart> => {
  const formData = new FormData()
  if (overwrite) {
    formData.append('overwrite', 'true')
  }

  return await asyncFetcher(`shared-carts/${cartId}/open/async`, formData,
    null, 'post', 'cart/async-poll',
    'cartDto', 1000, 1000, 600000,
    (message: string) => {
      postError({
        title: i18n.global.t('error'),
        text: message,
      })
    })
}
