<template>
  <div class="notification-container">
    <article class="media">
      <div class="media-content">
        <p><strong class="title is-4">{{ notification.name }}</strong></p>
        <markdown-view
          class="markdown"
          :markdown="notification.content"
        />
      </div>
    </article>
    <nav class="level is-mobile">
      <div class="level-left">
        <div class="level-item">
          <button
            class="button is-primary"
            @click="back"
          >
            {{ $t('close') }}
          </button>
        </div>
        <notification-attachments
          v-for="attachment in notification.attachments"
          :key="attachment.id"
          :attachment="attachment"
        />
        <notification-link
          v-if="notification.externalUrl"
          :external-url="notification.externalUrl"
          :open-in-new-window="notification.openInNewWindow"
        />
        <notification-media
          v-if="notification.relatedMediaId"
          :media-id="notification.relatedMediaId"
        />
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import NotificationAttachments from './NotificationAttachments'
import NotificationLink from './NotificationLink'
import NotificationMedia from './NotificationMedia'
import MarkdownView from '@/components/common/MarkdownView'
import { saveUserAction } from '@/controllers/library'
import { getUserActionQuery } from '@/store/modules/content/helper'
import { MediaTypes } from '@/interfaces/library'

export default {
  name: 'Notification',
  props: {
    /* eslint-disable */
    // TODO set a type and test it
    id: {
      required: true,
    }
  },
  components: {
    MarkdownView,
    NotificationAttachments,
    NotificationLink,
    NotificationMedia
  },
  computed: {
    ...mapState({
      notification: (state) => state.notification.item
    })
  },
  methods: {
    ...mapActions({
      navigateToNotifications: 'notification/navigateToNotifications',
      getNotification: 'notification/getNotification'
    }),
    back () {
      this.navigateToNotifications()
    }
  },
  async created () {
    await this.getNotification(this.id.toString())
    document.title = this.notification.name
  },
  // Update userActionLog to reflect view of the news-item
  async mounted () {
    try {
      const id = this.$route.params.id.toString()
      const query = getUserActionQuery(id, MediaTypes.bulletin)
      saveUserAction(query)
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped lang="scss">
.notification-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.notification-content {
  padding: 0 .5rem;
  display: flex;
  flex-direction: column;
}
.media {
  margin: 0;
  padding: 1rem;
  border-top: lightgray 1px solid;
  overflow: auto;
  flex-grow: 2;
  flex-direction: column;
  align-items: unset;

  .media-content {
    display: flex;
    flex-direction: column;

    p {
      flex: 0 0 auto;
    }
    .markdown {
      flex: 1 1 0;
      margin: 0.5rem -8px;
    }
  }
}
.level {
  padding: .5rem 1rem;
  border-top: lightgray 1px solid;
  flex-shrink: 0;
}
.level.is-mobile .level-item:last-child {
  margin-right: 0;
}
</style>
