<template>
  <div class="raised-card">
    <b-field
      v-if="title || subtitle"
    >
      <h5
        v-if="title"
        class="title-five"
        v-text="title"
      />
      <template #message>
        <slot name="subtitle">
          {{ subtitle || '' }}
        </slot>
      </template>
    </b-field>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'RaisedCard',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.raised-card {
  padding: 1rem;
  background-color:#fff;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
}
</style>
