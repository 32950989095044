<template>
  <span class="item-name">
    <span class="icon is-small">
      <i v-show="item.children"
          :class="[props.open ? 'fa-angle-down' : 'fa-angle-right', hasChildrenHighlighted(item) ? 'has-text-info' :'']"
          class="fa fa-fw open-icon"
          @click.stop.prevent="emit('toggle')"/>
    </span>
    {{ item.name }}
  </span>
</template>

<script setup>
const props = defineProps({ 
  item: { 
    type: Object, 
    default: () => {}
  },
  highlighted: {
    type: Array,
    default: () => []
  },
  open: Boolean
})

const emit = defineEmits(['toggle'])

function hasChildrenHighlighted(parent) {
  return parent?.children?.some(child => {
    return props.highlighted.some(tocItem => {
      return (tocItem?.entityId === child.id) 
        && (tocItem?.entityType === child.type)
    }) || hasChildrenHighlighted(child)
  }) ?? false
}
</script>

<style lang="scss" scoped>
.item-name {
  white-space: nowrap;
}
</style>