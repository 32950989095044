import { useRouter } from "vue-router"

export async function router () {
  const router = useRouter()
  return router
}

export async function getRouteParamId (idParam) {
  if (!idParam) return null
  let setId
  if (typeof idParam === 'string') {
    setId =  idParam.split(',').join('/')
  } else if (idParam.length > 1) {
    setId = idParam.join('/')
  } else {
    setId = idParam[0]
  }
  return setId
}
