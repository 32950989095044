<template>
  <div v-if="book && toc.length" :class="[{'is-active': openToc}, 'dropdown']">
    <div>
      <button class="button is-primary toc-trigger"
              aria-haspopup="true"
              :class="{'toc-button-with-results': searchResults.length}"
              @click.exact="toggleToc">
        <span class="icon">
          <i class="fas fa-lg fa-list-ol"/>
        </span>
        <span v-if="searchResults.length" class="tag is-info">
          {{ searchResults.length }}
        </span>
      </button>
    </div>
    <vue-draggable-resizable :y="0"
                             :w="tocWidth"
                             :h="tocHeight"
                             :minw="350"
                             :minh="400"
                             :handles="['br']"
                             :active="true"
                             :draggable="false"
                             @resizing="resizing"
                             v-on-clickaway="close"
                             class="dropdown-menu">
      <div class="dropdown-content">
        <nav class="navbar is-light">
          <search-type-ahead :placeholder="$t('searchInContent', { contentName: searchContentNameLabel })"
                             :value="query"
                             :content="book"
                             :clear-on-enter="false"
                             :number-of-results="searchResults.length"
                             class="toc-search"
                             @enter="search">
            <p v-if="searchResults.length" class="control">
              <button class="button is-primary is-outlined previous-button" @click="previousResult">
                <span class="icon is-medium">
                  <i class="fa fa-chevron-left"/>
                </span>
              </button>
            </p>
            <p v-if="searchResults.length" class="control">
              <button class="button is-primary is-outlined next-button" @click="nextResult">
                <span class="icon is-medium">
                  <i class="fa fa-chevron-right"/>
                </span>
              </button>
            </p>
          </search-type-ahead>
          <div class="buttons has-addons is-centered">
            <button class="button expand-button" @click="expand">
              <span class="icon">
                <i class="fa fa-lg fa-plus-square"/>
              </span>
              <span>{{ $t('expandAll') }}</span>
            </button>
            <button class="button collapse-button" @click="collapse">
              <span class="icon">
                <i class="fa fa-lg fa-minus-square"/>
              </span>
              <span>{{ $t('collapseAll') }}</span>
            </button>
          </div>
        </nav>
        <aside class="menu is-paddingless">
          <ul v-for="item in toc"
              :key="item.id"
              class="menu-list"
              :class="{'menu-list-highlighted': isHighlighted(item)}">
            <table-of-contents-item :item="item"
                                    :item-path="getItemPath(item)"
                                    :highlighted="searchResults"
                                    :force-open="forceOpen"
                                    :is-widget="isWidget"
                                    :flat-list-idx="getFlatListIdx(getItemPath(item))"
                                    :widget-content-path="widgetContentPath"
                                    @click.stop.prevent="select"
                                    @widgetNav="useWidgetTocNav"/>
          </ul>
        </aside>
        <div class="dropdown-footer"/>
      </div>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapState, mapActions, useStore } from 'vuex';
import bowser from 'bowser';
import {directive as onClickaway} from 'vue3-click-away';
import SearchTypeAhead from '@/components/common/search/SearchTypeAhead';
import TableOfContentsItem from './TableOfContentsItem';
import { useWidgetToc } from '@/components/widgets/composables/useWidgetToc'
import { useOpenToContent } from '@/components/widgets/composables/useOpenToContent'

export default {
  name: 'TableOfContents',
  components: {
    SearchTypeAhead,
    TableOfContentsItem
  },
  directives: {
    onClickaway
  },
  data() {
    return {
      query: this.searchQuery,
      searchResults: [],
      scrollAfterUpdate: false,
      scrollAfterSearchUpdate: false,
      bookSearch: false,
      highlightedInstance: 0,
      forceOpen: 0
    }
  },
  setup() {
    const store = useStore()
    const { contentPath,
      useWidgetTocNav, getFlatListIdx } = useWidgetToc()
    const { tocQuery, setTocQuery } = useOpenToContent()
    const book = computed(() => store.state.content?.toc?.book)
    const isWidget = computed(() => store.getters['widgets/isWidget'])
    const searchContentNameLabel = computed(() => (book.value?.name?.length > 25)
      ? book.value?.name.substring(0,25) : book.value?.name)

    return { widgetContentPath: contentPath,
      isWidget,
      widgetTocQuery: tocQuery,
      setWidgetTocQuery: setTocQuery,
      useWidgetTocNav, getFlatListIdx,
      searchContentNameLabel,
      book
    }
  },
  computed: {
    ...mapState({
      toc: ({ content }) => content.toc.items,
      tocLoaded: ({ content }) => content.toc.isLoaded,
      isTocOpen: ({ content }) => content.toc.isTocOpen,
      tocWidth: ({ content }) => content.toc.width,
      tocHeight: ({ content }) => content.toc.height
    }),
    searchQuery() {
      return this.$route.query.exact && this.$route.query.q
          ? `"${this.$route.query.q}"`
          : this.$route.query.q || ''
    },
    openToc: {
      get() {
        return this.isTocOpen;
      },
      async set(value) {
        await this.updateTocOpen(value);

        if (value) {
          this.scrollAfterUpdate = true;
        }
      }
    },
    path() {
      return this.book ? `/${this.book.contentType}/${this.book.id}/` : '';
    }
  },
  watch: {
    id() {
      const width = window.innerWidth;
      if (width < 769) {
        this.openToc = false;
      }
    },
    async toc(list, previousList) {
      // For Widgets, block re-calling toc; should have it on mount
      if (this.isWidget && !!previousList.length) return

      if (this.isWidget && !!this.widgetTocQuery) {
        // Update this.query to partNumber provided in Widget config
        // Pre-existing architecture necessitates updating as a side effect
        // of router push update rather than a direct update.
        await this.$router.push({ query: { q: this.widgetTocQuery }})
        this.setWidgetTocQuery('')
      }
      await this.search(this.query, true);
    },
    searchQuery() {
      this.query = this.searchQuery || this.query;
    }
  },
  methods: {
    ...mapActions({
      getTOCSearchResults: 'content/tocSearchWithReturn',
      navigateToContentPath: 'content/navigateToContentPath',
      updateTocOpen: 'content/updateTocOpen',
      resizeToc: 'content/tocResize'
    }),
    isHighlighted(item) {
      let index = -1;
      this.searchResults.some((tocItem, i) => {
        if (tocItem.entityId === item.id &&
            tocItem.entityType === item.type) {
          index = i;
          return true;
        }
        return false;
      });

      return index >= 0;
    },
    async search(query, bookSearch) {
      if (query) {
        this.searchResults = await this.getTOCSearchResults({query});
        if (this.searchResults.length) {
          this.openToc = true;
          this.scrollAfterSearchUpdate = true;
          this.bookSearch = bookSearch || false;
        }
      } else {
        this.searchResults = [];
      }
    },
    toggleToc() {
      this.openToc = !this.openToc;
    },
    /**
     * Closes the TOC dropdown.
     *
     * Triggered by the click-away directive on the
     * vue-draggable-resizable component. To avoid closing
     * the TOC when we actually want to open it, check to
     * see if the click event target contains the toc-trigger
     * class. If it does, do nothing, it doesn't close the TOC.
     *
     * @param {object} event click event info
     */
    close(event) {
      if (bowser.check({msie: '11'}, true)) {
        if (event.target.msMatchesSelector('.toc-trigger, .toc-trigger *')) return;
      } else {
        if (event.target.matches('.toc-trigger, .toc-trigger *')) return;
      }

      if (this.openToc) {
        this.openToc = false;
      }
    },
    select(itemPath) {
      this.navigateToContentPath({
        path: itemPath
      });
    },
    scrollTo(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    },
    previousResult() {
      this.highlightedInstance = this.highlightedInstance - 1 < 0 ?
        this.searchResults.length - 1 : this.highlightedInstance - 1;
      this.navigateToResult();
    },
    nextResult() {
      this.highlightedInstance = this.highlightedInstance + 1 >= this.searchResults.length ?
        0 : this.highlightedInstance + 1;
      this.navigateToResult();
    },
    navigateToResult() {
      const highlighted = document.getElementsByClassName('menu-list-highlighted');
      if (highlighted.length) {
        highlighted[this.highlightedInstance].scrollIntoView();
        const item = highlighted[this.highlightedInstance];

        if (item) {
          const [, path] = item.firstElementChild.firstElementChild
            .getAttribute('href')?.split('/ui');

          if (path) {
            this.navigateToContentPath({ path });
            if (this.isWidget) {
              this.useWidgetTocNav(path)
            }
          }
        }
      }
    },
    expand() {
      this.forceOpen += 1;
    },
    collapse() {
      this.forceOpen -= 1;
    },
    async resizing(left, top, width, height) {
      await this.resizeToc({ width, height});
    },
    getItemPath(item) {
      return this.path + item.type + ':' + item.id
    }
  },
  mounted() {
    this.query = this.searchQuery || this.query;

    if (this.tocLoaded) {
      this.search(this.query, true);
    }
    // Ensure Widgets always open ToC irrespective of tenant or user settings
    if (this.isWidget) {
      this.openToc = true
    }
  },
  async updated() {
    if (this.scrollAfterUpdate) {
      this.scrollTo(this.id);
      this.scrollAfterUpdate = false;
    }

    if (this.scrollAfterSearchUpdate) {
      const highlighted = document.getElementsByClassName('menu-list-highlighted');
      if (highlighted.length) {
        highlighted[0].scrollIntoView();
        const item = highlighted[0].firstElementChild.firstElementChild;
        const [, path] = item.getAttribute('href')?.split('/ui');

        this.highlightedInstance = 0;

        if (path) {
          await this.navigateToContentPath({
            path,
            replace: this.bookSearch
          });
          this.bookSearch = false;
          if (this.isWidget) {
            this.useWidgetTocNav(path)
          }
        }
      }
      this.scrollAfterSearchUpdate = false;
    }
  },
};
</script>

<style scoped>
.dropdown {
  flex-shrink: 0;
}
.dropdown.is-active .dropdown-menu, .dropdown.is-hoverable:hover .dropdown-menu {
  display: flex;
}
.menu {
  padding: .5rem;
  flex-grow: 2;
  overflow: auto;
}
.dropdown-menu {
  box-shadow: 15px 30px 80px 0 lightgrey;
  z-index: 25 !important;
  max-width: 80vw;
  max-height: 80vh;
}
.dropdown-content {
  padding-top:  0;
  padding-bottom: 0;
  display: flex !important;
  flex-direction: column;
  flex-grow: 2;
  overflow: hidden;
  min-height: 300px;
  min-width: 250px;
  height: 100%;
  z-index: 25;
}
.field {
  padding: 0 .5rem;
  flex-grow: 1;
}
.menu-list {
  border-left: .25rem solid #dbdbdb;
  margin: 0 0 0 .1rem;
  padding: 0 0 0 .1rem;
}
.menu-list-highlighted {
  border-left-color: #209CEE;
}
.navbar {
  flex-direction: column;
  padding: .5rem;
  flex-shrink: 0;
  border-bottom: lightgray 1px solid;
}
.toc-search {
  padding: 0;
  margin-bottom: .5rem;
}
.is-outlined {
  background-color: white !important;
  color: grey !important;
}
.result-actions {
  float: right;
}
.toc-button-with-results {
  padding-right: .25rem;
}
.buttons > .button {
  width: 50%;
}
.dropdown-footer {
  border-top: lightgray 1px solid;
  background-color: #f5f5f5;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-right: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

@media only screen and (max-width: 768px) {
  .dropdown-menu {
    max-width: 95vw;
    max-height: 80vh;
  }
  .dropdown-footer {
    margin-right: 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .dropdown-content {
    /*overflow: auto;*/
  }
}
</style>

<style>
  .handle-br {
    display: block !important;
    bottom: 0 !important;
    right: 0 !important;
  }
  .handle {
    width: 16px !important;
    height: 16px !important;
    z-index: 26 !important;
    border: none !important;
    background: repeating-linear-gradient(
      -45deg,
      lightgrey,
      lightgrey 3px,
      darkgrey 3px,
      darkgrey 5px
    ) !important;
  }

  @media only screen and (max-width: 768px) {
    .handle-br {
      display: none !important;
    }
  }
</style>
