<template>
  <th data-int="cart-header-page">
    {{ $t('itemName', { item: $tc('page', 1)}) }}
  </th>
</template>

<script>
export default {
  name: 'CartHeaderUom'
};
</script>

<style scoped>

</style>
