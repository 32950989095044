<template>
  <span class="loading-icon icon is-medium">
    <span class="fa-stack">
      <i class="fa fa-cog fa-stack-2x fa-spin has-text-primary" />
      <i class="fa fa-cog fa-stack-2x fa-counter-spin fa-stack-right" />
    </span>
  </span>
</template>
<script>
export default {
  name: 'LoadingIcon'
};
</script>
