import http from '@/http'

export async function search (params: Object) {
  return await http.get('search', { params })
}

export async function publisherSearch (params: Object) {
  return await http.get('publisher-search', { params })
}

export async function mediaTemplatesSearch (pageId: number, params: Object) {
  return await http.get(`medias/templates/${pageId}`, { params })
}
