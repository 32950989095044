<template>
  <main class="preferences-defaults-container">
    <section class="defaults-container">
      <div class="field">
        <label class="label">{{ $t('language') }}</label>
        <div class="control is-expanded">
          <div class="select is-fullwidth">
            <select v-model="locale" class="locale-select">
              <option v-for="{id, name, code4} in locales" :key="id" :value="code4">
                {{ name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <br/>
      <div class="field">
        <label class="label">{{ $t('tocState') }}</label>
        <div class="control checkRadio toc-opened" @click="toc = true">
          <input type="radio" :checked="toc" class="is-checkradio"/>
          <label>{{ $t('open') }}</label>
        </div>
        <div class="control checkRadio toc-closed" @click="toc = false">
          <input type="radio" :checked="!toc" class="is-checkradio"/>
          <label>{{ $t('closed') }}</label>
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('bomLayout') }}</label>
        <div class="control checkRadio bom-layout-horizontal" @click="layout = 'horizontal'">
          <input type="radio" :checked="layout === 'horizontal'" class="is-checkradio"/>
          <label>
            <span class="icon">
              <i class="fa fa-columns"/>
            </span>
            {{ $t('horizontal') }}
          </label>
        </div>
        <div class="control checkRadio bom-layout-vertical" @click="layout = 'vertical'">
          <input type="radio" :checked="layout === 'vertical'" class="is-checkradio"/>
          <label>
            <span class="icon">
              <i class="fa fa-rotate-270 fa-columns"/>
            </span>
            {{ $t('vertical') }}
          </label>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Preferences',
  computed: {
    ...mapState({
      locales: ({ locales }) => locales.items,
      bomLayout: ({ user }) => user.preferences.bomLayout,
      tocState: ({ user }) => user.preferences.tocState
    }),
    locale: {
      get() {
        return this.$i18n.locale;
      },
      async set(value) {
        this.$i18n.locale = value;
        localStorage.setItem("userLocale", value)
        await this.updatePreferences({ locale: value })
        const matchingLocale = this.locales.find(it => it.code4 === value)
        await this.updateLocale({ 
          locale: matchingLocale.code4, 
          shortLocale: matchingLocale.code2 
        })
      }
    },
    toc: {
      get() {
        return this.tocState;
      },
      async set(value) {
        await this.updatePreferences({ tocState: value })
      }
    },
    layout: {
      get() {
        return this.bomLayout;
      },
      async set(value) {
        await this.updatePreferences({ bomLayout: value })
      }
    }
  },
  methods: {
    ...mapActions({
      updateLocale: 'user/updateLocale',
      updatePreferences: 'user/updatePreferences',
      getLocales: 'locales/getLocales',
      getUserPreferences: 'user/getUserPreferences'
    })
  },
  async created() {
    document.title = this.$t('preferences')
    await this.getLocales()
    await this.getUserPreferences()
  }
};
</script>

<style scoped>
.preferences-defaults-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-top: .5rem;
}
.defaults-container {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  max-width: 48rem;
}
.checkRadio {
  padding: .25rem 0;
}
@media only screen and (min-width: 768px) {
  .defaults-container {
    width: 48rem;
    margin: 0 auto;
  }
}
</style>
