<template>
  <tiles  :children="children"
          :path="path"
          @select="navToViewWithLoading"/>
</template>

<script setup>
import { computed } from "vue"
import { useStore } from "vuex"
import Tiles from "@/components/widgets/components/WidgetTiles"
import { useWidgetNavigator } from "@/components/widgets/composables/useWidgetNavigator"

const { navToViewWithLoading } = useWidgetNavigator()

const store = useStore()
const content = computed(() => store.state.content)
const children = computed(() => {
  return store.getters['content/tocChildren']
})
const path = computed(() => {
  return `/${content.value.contentType}/` 
    + content.value.id
})
</script>