const LoginRoutes = [{
  path: '/login',
  name: 'Login',
  component: () => import('@/components/login/Login')
}, {
  path: '/forgot-password',
  name: 'ForgotPassword',
  component: () => import('@/components/login/ForgotPassword')
}, {
  path: '/reset-password',
  name: 'ResetPassword',
  component: () => import('@/components/login/ResetPassword')
}, {
  path: '/unsupported-browser',
  name: 'UnsupportedBrowser',
  component: () => import('@/components/login/UnsupportedBrowser')
}, {
  path: '/expired-password',
  name: 'ExpiredPassword',
  component: () => import('@/components/login/ExpiredPassword.vue')
}]

export default LoginRoutes
