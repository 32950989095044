<template>
  <div
    v-if="isActive"
    class="modal is-active"
  >
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t('merge') }}
        </p>
      </header>
      <section class="modal-card-body">
        {{ $t('mergeOrOverrideMessage') }}
      </section>
      <footer class="modal-card-foot">
        <b-field
          grouped
          position="is-right"
          class="is-full-width"
        >
          <button
            class="button"
            @click="override"
          >
            {{ $t('override') }}
          </button>
          <button
            class="button is-primary"
            @click="merge"
          >
            {{ $t('merge') }}
          </button>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartMergeOverrideModal',
  data () {
    return {
      isActive: false
    }
  },
  /**
   * TODO openClear is the previous setting; pending clean-up when
   * former Open cart gets removed
   */
  methods: {
    merge () {
      this.$emit('openClear', false)
      this.$emit('merge')
      this.isActive = false
    },
    open () {
      this.isActive = true
    },
    override () {
      this.$emit('openClear', true)
      this.$emit('override')
      this.isActive = false
    }
  }
}
</script>

<style scoped>
.modal-card {
  width: auto;
  max-width: 60%;
}
.modal-card-body {
  color: #4a4a4a;
  min-height: 5rem;
}
</style>
