import http from '@/http'
import actions from '../actions'
import mutations from '../mutations'
import * as types from '../mutations/mutations-types'

export default {
  namespaced: true,
  state: {
    error: false,
    loading: false,
    data: null,
    pagination: {},
    filters: {},
    searchTerm: '',
    viewMode: 'all',
    sort: {
      field: 'email',
      order: 'asc'
    }
  },
  mutations: {
    ...mutations,
    [types.DATA_SUCCESS] (state, payload) {
      state.data = payload
    },
    'SET_PAGINATION' (state, payload) {
      Object.assign(state.pagination, payload)
    },
    'SET_FILTERS' (state, payload) {
      Object.assign(state.filters, payload)

      if (payload.remove) {
        payload.remove.forEach((filter) => {
          delete state.filters[filter]
        })
        delete state.filters.remove
      }
    },
    'SET_SEARCH_TERM' (state, payload) {
      state.searchTerm = payload
    },
    'RESET_FILTERS' (state) {
      state.filters = {}
    },
    'LOAD_MORE' (state, payload) {
      const newItems = []

      payload.forEach((item) => {
        if (state.data.findIndex((existing) => existing.id === item.id) === -1) {
          newItems.push(item)
        }
      })
      state.data = state.data.concat(newItems)
    },
    'SET_SORT' (state, payload) {
      Object.assign(state.sort, payload)
    },
    'SET_VIEW_MODE' (state, payload) {
      state.viewMode = payload
    },
    'RESET_USERS' (state) {
      state.data = null
    }
  },
  actions: {
    ...actions,
    async loadUsers (context, { searchTerm = '', filters = {}, loadMore = false }) {
      const formattedFilters = filters || context.state.filters
      const offset = (loadMore ? context.state.pagination.offset + 100 : context.state.pagination.offset) || ''
      const params = {
        ...formattedFilters,
        sort: `${context.state.sort.field}:${context.state.sort.order}`,
        offset,
        filter: searchTerm || context.state.searchTerm,
        limit: context.state.pagination.limit || 100
      }

      try {
        context.commit(types.DATA_REQUEST)

        const { data, headers } = await http.get('users', { params })

        if (data && headers) {
          if (!loadMore) {
            context.commit('DATA_SUCCESS', data)
          } else {
            context.commit('LOAD_MORE', data)
          }

          context.commit('SET_PAGINATION', {
            count: Number(headers['x-count']),
            limit: Number(headers['x-limit']),
            offset: Number(headers['x-offset']),
            pageCount: Number(headers['x-page-count']),
            currentPage: Number(headers['x-page-num'])
          })
        }
      } catch (error) {
        context.commit(types.DATA_ERROR, error)
      } finally {
        context.commit(types.DATA_LOADING, false)
      }
    },
    setFilters (context, data) {
      context.commit('SET_FILTERS', data)
    },
    setSearchTerm (context, term) {
      context.commit('SET_SEARCH_TERM', term)
    },
    resetFilters (context) {
      context.commit('RESET_FILTERS')
    },
    setSort (context, data) {
      context.commit('SET_SORT', data)
    },
    setViewMode (context, data) {
      context.commit('SET_VIEW_MODE', data)
    },
    resetUsers (context) {
      context.commit('RESET_USERS')
    }
  }
}
