import { Translation } from './translation'
import { TagToEdit, TagToAdd } from './draftTags'

export interface TenantInfo {
  id: number,
  tenantKey: string,
  translations: Translation[],
  tags: any[],
  accountErrorMessage: string
}

export interface TenantParams {
  offset: number,
  filterTerm: string,
  sortAsc: boolean
}

export interface TenantsResponse {
  data: TenantInfo[],
  total: number
}

export interface TenantTagParams {
  tagId: number,
  tenantId: number,
  filter: string
}

export interface TagUpdatesMap {
  create: TagToAdd[],
  update: TagToEdit[],
  delete: number[]
}

interface TenantReportDto {
  tenantId: number,
  activeForTenant: boolean,
  reportId: number,
  name: string
}

export interface TenantReportUpdatesMap {
  create: TenantReportDto[],
  delete: TenantReportDto[]
}

export interface TenantAdminBanner {
  id: number | null,
  enabled: Boolean,
  body: string | null,
  header: string | null,
  type: TenantAdminBannerType | null
}

export enum TenantAdminBannerType {
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN'
}
