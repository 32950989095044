<template>
  <error  :loadTheme="false">
    <template #message>
      <section  class="is-flex is-flex-direction-column">
        <span class="is-size-5"
              data-unit="widget-refresh-error-page-title">
          {{ $t('widgetRefreshErrorMsg') }}
        </span>
      </section>
    </template>
  </error>
</template>

<script>
import Error from "@/components/Error"

export default {
  name: "WidgetRefreshError",
  components: { Error }
}
</script>