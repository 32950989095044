<template>
  <div class="field has-addons is-marginless">
    <filters-type-ahead
      :property-field="filter.field"
      :value="selected"
      :name="name"
      @change="change"
      @remove="removeFilter"
    />
    <div class="control">
      <a
        class="button"
        @click="removeFilter"
      >
        <span class="icon is-small">
          <i class="fa fa-times" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
/**
 * A filter with an input for typing in a value to filter on.
 */
import FiltersTypeAhead from './FiltersTypeAhead'

export default {
  name: 'TextFilter',
  props: {
    filter: {
      type: Object,
      required: true
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },
  components: {
    FiltersTypeAhead
  },
  data () {
    return {
      selected: ''
    }
  },
  watch: {
    selectedValues () {
      this.selected = this.selectedValues.length ? this.selectedValues[0] : ''
    }
  },
  computed: {
    name () {
      if (this.filter.name.indexOf('%%') === 0) {
        return this.$i18n.t(this.filter.name.replace(/%%/g, ''))
      }
      return this.filter.name
    }
  },
  methods: {
    change (value) {
      if (this.selected !== value) {
        const values = []
        this.selected = value

        if (this.selected) {
          values.push(this.selected)
        }
        this.$emit('change', values)
      }
    },
    removeFilter () {
      this.$emit('remove')
    }
  }
}
</script>
