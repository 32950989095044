<script setup lang="ts">
import { ref } from 'vue'

const emit = defineEmits(['change', 'input'])
const props = defineProps({
  disabled: Boolean,
  isVertical: Boolean,
  max: {
    type: Number,
    default: 1
  },
  min: {
    type: Number,
    default: 0
  },
  step: {
    type: Number,
    default: 0.01
  },
  value: {
    type: Number,
    default: 0
  },
})

const containerClasses = props.isVertical ? 'vertical-container' : ''
const sliderClasses = props.isVertical ? 'vertical' : ''
const sliderElement = ref(null) // reference to element

const onInput = (event: any) => {
  emit('input', event.target.value)
}
const onChange = (event: any) => {
  emit('change', event.target.value)
}

</script>

<template>
  <div :class="containerClasses">
    <input class="slider"
           ref="sliderElement"
           type="range"
           :class="sliderClasses"
           :disabled="disabled"
           :max="max"
           :min="min"
           :orient="isVertical ? 'vertical' : 'horizontal'"
           :step="step"
           :value="value"
           @input="onInput"
           @change="onChange">
  </div>
</template>

<style scoped lang="scss">
.slider {
  -webkit-appearance: none;
  background: #ddd;
  border-radius: 4px;
  outline: none;
  transition: opacity 0.2s;
  height: 12px;
  width: 100%;
}
.slider:hover {
  opacity: 1;
}
.slider.vertical {
  direction: rtl;
  writing-mode: vertical-rl;
  height: 100%;
  width: 12px;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: var(--primary);
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.slider[disabled]::-webkit-slider-thumb {
  background-color: var(--grey--light-color--darken);
}
.slider::-moz-range-thumb {
  background: var(--primary);
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.slider[disabled]::-moz-range-thumb {
  background-color: var(--grey--light-color--darken);
}
.slider::-ms-thumb {
  background: var(--primary);
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.slider[disabled]::-ms-thumb {
  background-color: var(--grey--light-color--darken);
}
.slider::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
  height: 8px;
  width: 100%;
}
.slider[disabled]::-ms-track {
  background-color: var(--grey--light-color--darken);
}

.vertical-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width:  100%;
}
</style>
