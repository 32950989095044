<script setup lang="ts">
import { ref } from 'vue'
import { v4 as uuidv4 } from "uuid"
import {
  InteractiveProps,
  useInteractiveEventListeners
} from '@/components/common/useInteractiveEventListeners'

const emit = defineEmits(['close'])
defineProps({
  filepath: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  }
})

const uuidPrefix = uuidv4()
const previewContainerId = `preview-container-${uuidPrefix}`

const close = () => emit('close')

// common variables
const imageScale = ref(1.00)
const isPanning = ref(false)
const panPosition = ref({ x: 0, y: 0 })

const { zoomIn, zoomOut } = useInteractiveEventListeners(
  new InteractiveProps({
    imageScale: imageScale,
    isPanning: isPanning,
    panPosition: panPosition,
    previewContainerId: previewContainerId
}))
</script>

<template>
  <div class="is-active modal"
       :class="{ 'grabbing': isPanning }">
    <div class="modal-background"
         @click="close" />
    <div class="modal-card">
      <div class="box">
        <div class="mb-2">
          <button class="is-pulled-right delete"
                  aria-label="close"
                  @click="close" />
          <span class="title-four title-overflow">
            {{ title }}
          </span>
        </div>
        <div class="preview"
             :class="{ 'grabbing': isPanning }"
             :id="previewContainerId">
          <img :alt="title"
               :src="filepath"
               :style="{ 'scale': imageScale, transform: `translate(${panPosition.x}px, ${panPosition.y}px)` }" />
        </div>
        <div class="is-flex is-justify-content-center mt-1">
          <button class="button is-white"
             @click="zoomIn">
            <span class="icon fa-lg fas fa-search-plus" />
          </button>
          <button class="button is-white"
             @click="zoomOut">
            <span class="icon fa-lg fas fa-search-minus" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.preview {
  align-items: center;
  cursor: grab;
  display: flex;
  justify-content: center;
  outline: 1px solid #dbdbdb;
  overflow: hidden;
  position: relative;

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
}
.preview.grabbing {
  cursor: grabbing;
}

.preview img {
  height: auto;
  max-height: 60vh;
  max-width: 60vw;
  width: auto;

  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
}

@media (max-width: 768px) {
  .preview {
    height: 60vh;
  }

  .preview img {
    max-height: unset;
    max-width: unset;
  }
}

.title-overflow {
  align-content: center;
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 570px;
  word-break: break-all;
}
@media(max-width: 768px) {
  .title-overflow {
    width: 83vw;
  }
}
</style>
