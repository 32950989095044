import {AutoHotpointSettings} from "@/interfaces/admin/autoHotpointSettings";
import {ActionContext} from "vuex";

type Context = ActionContext<AutoHotpointSettings, any>

const LOAD_AUTO_HOT_POINT_SETTINGS = 'LOAD_AUTO_HOT_POINT_SETTINGS'

export enum AutoHotpointSettingsActions {
  loadAutoHotpointSettings = 'loadAutoHotpointSettings'
}

const state: AutoHotpointSettings = {
  blobMinArea: 25,
  shapeMinArea: 50,
  shape: 'CIRCLE',
  algorithm: 'CONTOUR'
}

const actions = {
  async loadAutoHotpointSettings ({ commit, dispatch }: Context) {
    const autoHotpointSettings = await dispatch('getLocalStorage', { key: 'autoHotpointSettings' }, { root: true })
    if (autoHotpointSettings) {
      commit(LOAD_AUTO_HOT_POINT_SETTINGS, autoHotpointSettings)
    }
  },
  async storeAutoHotpointSettings ({ commit, dispatch }: Context, autoHotpointSettings: AutoHotpointSettings) {
    await dispatch('setLocalStorage', { key: 'autoHotpointSettings' , value: autoHotpointSettings }, { root: true })
    commit(LOAD_AUTO_HOT_POINT_SETTINGS, autoHotpointSettings)
  }
}

const mutations = {
  [LOAD_AUTO_HOT_POINT_SETTINGS] (state: AutoHotpointSettings, payload: AutoHotpointSettings) {
    state.shapeMinArea = payload.shapeMinArea
    state.blobMinArea = payload.blobMinArea
    state.shape = payload.shape
    state.algorithm = payload.algorithm
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
