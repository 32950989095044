<template>
  <div class="level-item">
    <figure class="media-left">
      <a :href="externalUrl" :target="openInNewWindow ? '_blank' : '_self'">
        <span class="icon">
          <i class="fa fa-lg fa-globe"/>
        </span>
        {{ externalUrl }}
      </a>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'NotificationLink',
  props: {
    externalUrl: {
      type: String,
      default: ''
    },
    openInNewWindow: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>

</style>
