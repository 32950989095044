<template>
  <b-field position="is-centered">
    <b-radio-button :value="isBasicExport"
                    :native-value="false"
                    :disabled="disabled"
                    type="is-primary"
                    @input="$emit('update:isBasicExport', $event)">
      <span>{{ $t('exportDisplayedCartColumns') }}</span>
    </b-radio-button>
    <b-radio-button :value="isBasicExport"
                    :native-value="true"
                    :disabled="disabled"
                    type="is-primary"
                    @input="$emit('update:isBasicExport', $event)">
      <span>{{ $t('exportBasicPartInfo') }}</span>
    </b-radio-button>
  </b-field>
</template>

<script>
export default {
  name: "CartColumnsButtons",
  props: {
    isBasicExport: Boolean,
    disabled: Boolean
  }
}
</script>