<template>
  <td data-int="cart-cell-name">
    <router-link :to="{ path: uri }">
      <strong>{{ item.name }}</strong>
    </router-link>
    <br/>
    {{ item.partNumber }}
  </td>
</template>

<script>
export default {
  name: 'CartCellName',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    uri() {
      return `/part/${this.item.partId}`;
    }
  }
};
</script>

<style scoped>

</style>
