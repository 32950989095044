export default {
  namespaced: true,
  state: {
    timers: {}
  },
  mutations: {
    'ADD_TIMER' (state, payload) {
      state.timers[payload.id] = setTimeout(payload.callBack, payload.time)
    },
    'CLEAR_TIMER' (state, payload) {
      clearTimeout(state.timers[payload.id])
    },
    'CLEAR_ALL_TIMERS' (state) {
      for (const id in state.timers) {
        clearTimeout(state.timers[id])
      }

      state.timers = {}
    }
  },
  actions: {
    addTimer (context, data) {
      context.commit('ADD_TIMER', data)
    },
    cancelTimer (context, data) {
      context.commit('CLEAR_TIMER', data)
    },
    cancelAllTimers (context) {
      context.commit('CLEAR_ALL_TIMERS')
    }
  }
}
