<template>
  <div data-unit="utility-block"
       :class="['utility-block d-flex align-center justify-center', state]">
    <loading-icon v-if="state === 'loading'" />
    <b-message v-if="state === 'error'"
               type="is-danger"
               has-icon>
      {{ message || 'Error. Something went wrong.' }}
    </b-message>
    <b-message v-if="state === 'empty'"> {{ message || 'No results.' }} </b-message>
  </div>
</template>
<script>
import LoadingIcon from './LoadingIcon';
export default {
  name: 'UtilityBlock',
  props: {
    state: {
      type: String,
      required: false,
      default: 'loading',
      validator: (val) => ['loading', 'empty', 'error'].indexOf(val) > -1
    },
    message: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    LoadingIcon
  }
};
</script>
<style lang="scss" scoped>
.utility-block {
  height: 150px;
}
</style>
