import http from '@/http'
import {ActionContext, GetterTree, MutationTree} from "vuex";
import {Translation} from '@/interfaces/admin'
import i18n from '@/locales'
import {Pagination, PaginationHeaders} from "@/interfaces/global";

export async function loadTenantEmailFormats(searchTerm: string = '', sortField: string = 'name', sortDir: string = 'asc'): Promise<EcommerceEmailFormatTranslation[]> {
  const sort = sortField + ':' + sortDir
  const { data } = await http.get(`/admin/orders/templates/formats`, { params: { filter: searchTerm, sort: sort } })
  return data
}

export async function loadTenantEmailFormat (formatId: number): Promise<EcommerceEmailFormatTranslation> {
  const { data } = await http.get(`/admin/orders/templates/formats/${formatId}`)
  return data
}

export async function loadEmailFormatHeaderMacros (): Promise<EcommerceEmailHeader[]> {
  const { data } = await http.get(`/admin/orders/templates/formats/macro-headers`)
  return data
}

export async function createEmailFormatTranslation (emailFormatTranslation: EcommerceEmailFormatTranslation):
  Promise<EcommerceEmailFormatTranslation> {
  const { data } = await http.post('/admin/orders/templates/formats', emailFormatTranslation)
  return data
}

export async function updateEmailFormatTranslation (emailFormatTranslation: EcommerceEmailFormatTranslation): Promise<EcommerceEmailFormatTranslation> {
  const { data } = await http.put('/admin/orders/templates/formats', emailFormatTranslation)
  return data
}

export async function deleteEmailFormatTranslation (id: number) {
  await http.delete(`/admin/orders/templates/formats/${id}`)
}

export async function copyEmailFormatTranslation (id: number, newlocaleCode: string) {
  const { data } = await http.get(`/admin/orders/templates/formats/copy/${id}`, { params: { localeCode4s: newlocaleCode } })
  return data
}

interface EcommerceEmailFormatState {
  loading: boolean,
  emailFormats: EcommerceEmailFormatTranslation[],
  emailFormatsTotal: number,
  emailFormatsCount: number,
  emailFormatsLoaded: boolean,
  headerMacrosLoaded: boolean,
  headerMacros: EcommerceEmailHeader[],
  pagination: Pagination
}

export interface EcommerceEmailFormatTranslation {
  id?: number | null,
  created?: Date | null,
  updated?: Date | null,
  version?: number,
  bodyTextContent?: string | null,
  subjectTextContent?: string | null,
  signatureTextContent?: string | null,
  languagecode4s?: string,
  languageName?: string,
  tenantId?: number | null
}

enum EcommerceHeaderFieldType {
  ADDRESS,
  DATE,
  EMAIL_LIST,
  HORIZONTAL_LINE,
  LABEL,
  LISTBOX,
  LOGO,
  ORDER_NUMBER,
  SUBMITTED_FOR_FIELD,
  TEXTFIELD,
  TEXTAREA,
  TAG
}

enum EcommerceHeaderFieldAddressType {
  ORGANIZATION,
  USER,
  BOTH,
  NONE
}

interface EcommerceEmailHeader {
  id?: number | null,
  name: string | null,
  type?: EcommerceHeaderFieldType | null,
  addressType?: EcommerceHeaderFieldAddressType | null,
  defaultValue?: string | null,
  displayedInOrdersList?: boolean | null,
  editable?: boolean | null,
  listBoxValues?: [] | null
  required?: boolean | null,
  tagNameId?: number | null,
  translations: Translation[] | null
}

type Context = ActionContext<EcommerceEmailFormatTranslation, any>

export enum MutationTypes {
  SET_LOADING = 'LOADING',
  SET_EMAIL_FORMATS_LOADED = 'EMAIL_FORMATS_LOADED',
  SET_EMAIL_FORMATS = 'SET_EMAIL_FORMATS',
  SET_HEADER_MACROS_LOADED = 'SET_HEADER_MACROS_LOADED',
  SET_HEADER_MACROS = 'SET_HEADER_MACROS',
  SET_PAGINATION = 'SET_PAGINATION'
}

const state: EcommerceEmailFormatState = {
  loading: false,
  emailFormats: [],
  headerMacros: [],
  emailFormatsTotal: 0,
  emailFormatsCount: 0,
  emailFormatsLoaded: false,
  headerMacrosLoaded: false,
  pagination: {}
}

const getters: GetterTree<EcommerceEmailFormatState, any> = {

}

const actions = {
  async getTenantEmailFormats ( context: Context, params: any): Promise<void> {
    try {
      context.commit(MutationTypes.SET_LOADING, {
        isLoading: true
      })
      const { data, headers } = await http.get(`/admin/orders/templates/formats`, {params})
      context.commit(MutationTypes.SET_EMAIL_FORMATS, data)
      context.commit(MutationTypes.SET_EMAIL_FORMATS_LOADED, {
        loaded: true
      })
      let pagination = new Pagination(headers as PaginationHeaders)
      context.commit(MutationTypes.SET_PAGINATION, pagination)
    } finally {
      context.commit(MutationTypes.SET_LOADING, {
        isLoading: false
      })
    }
  },
  async getEmailHeaderMacros ( context: Context ): Promise<void> {
    try {
      context.commit(MutationTypes.SET_LOADING, {
        isLoading: true
      })
      const data = await loadEmailFormatHeaderMacros()
      //add the standard ones
      data.push(macro_order_number)
      data.push(macro_status)
      data.push(macro_last_mode_date)
      data.push(macro_created_date)
      data.push(macro_submitter_user_name)
      data.push(macro_submitter_email)
      data.push(macro_submitter_first_name)
      data.push(macro_submitter_last_name)
      data.push(macro_submitter_org_name)
      const headers = data.sort((a : EcommerceEmailHeader, b : EcommerceEmailHeader) =>
        (a.name || '').localeCompare(b.name || ''))
      context.commit(MutationTypes.SET_HEADER_MACROS, headers)
      context.commit(MutationTypes.SET_HEADER_MACROS_LOADED, {
        loaded: true
      })
    } finally {
      context.commit(MutationTypes.SET_LOADING, {
        isLoading: false
      })
    }
  }
}

const mutations: MutationTree <EcommerceEmailFormatState> = {
  [MutationTypes.SET_LOADING] (state : EcommerceEmailFormatState, payload: boolean) {
    state.loading = payload
  },
  [MutationTypes.SET_EMAIL_FORMATS] (state: EcommerceEmailFormatState, payload: EcommerceEmailFormatTranslation[]) {
    state.emailFormats = payload
  },
  [MutationTypes.SET_EMAIL_FORMATS_LOADED] (state: EcommerceEmailFormatState, payload: boolean) {
    state.emailFormatsLoaded = payload
  },
  [MutationTypes.SET_HEADER_MACROS_LOADED] (state: EcommerceEmailFormatState, payload: boolean) {
    state.headerMacrosLoaded = payload
  },
  [MutationTypes.SET_HEADER_MACROS] (state: EcommerceEmailFormatState, payload: EcommerceEmailHeader[]) {
    state.headerMacros = payload
  },
  [MutationTypes.SET_PAGINATION] (state: EcommerceEmailFormatState, payload: Pagination) {
    state.pagination = payload
  }
}

const macro_order_number: EcommerceEmailHeader = {
  name: 'order_number',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('orderNumber') ? i18n.global.t('orderNumber').toString() : 'orderNumber'
  }]
}
const macro_status: EcommerceEmailHeader = {
  name: 'status',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('status') ? i18n.global.t('status').toString() : 'status'
  }]
}
const macro_last_mode_date: EcommerceEmailHeader = {
  name: 'last_mode_date',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('lastModifiedDate') ? i18n.global.t('lastModifiedDate').toString() : 'lastModifiedDate'
  }]
}
const macro_created_date: EcommerceEmailHeader = {
  name: 'created_date',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('createdDate') ? i18n.global.t('createdDate').toString() : 'createdDate'
  }]
}
const macro_submitter_user_name: EcommerceEmailHeader = {
  name: 'submitter_user_name',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('submitterUserName') ? i18n.global.t('submitterUserName').toString() : 'submitterUserName'
  }]
}
const macro_submitter_email: EcommerceEmailHeader = {
  name: 'submitter_email',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('submitterEmail') ? i18n.global.t('submitterEmail').toString() : 'submitterEmail'
  }]
}
const macro_submitter_first_name: EcommerceEmailHeader = {
  name: 'submitter_first_name',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('submitterFirstName') ? i18n.global.t('submitterFirstName').toString() : 'submitterFirstName'
  }]
}
const macro_submitter_last_name: EcommerceEmailHeader = {
  name: 'submitter_last_name',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('submitterLastName') ? i18n.global.t('submitterLastName').toString() : 'submitterLastName'
  }]
}
const macro_submitter_org_name: EcommerceEmailHeader = {
  name: 'submitter_org_name',
  translations: [{
    desc: '',
    lang: i18n.global.locale ? i18n.global.locale : 'en-US',
    name: i18n.global.t('submitterOrgName') ? i18n.global.t('submitterOrgName').toString() : 'submitterOrgName'
  }]
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions
}
