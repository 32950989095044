import http from '@/http'
import { store } from '@/store'
import { v4 as uuidv4 } from 'uuid'

/*
May benefit from wrapping into an options object. Or perhaps defaults for common async
patterns.

interface IAsyncFetcherOptions {
    initialUrl: string
    initialData: any // idk
    // ... other fields
}

export const asyncFetcher = async (options:IAsyncFetcherOptions) :Promise<any> => {
 */
export const asyncFetcher = async (
  initialUrl,
  initialData,
  initialConfig,
  initialMethod,
  pollingUrl,
  resultName,
  delayTime,
  pollTime,
  waitingLimit,
  errorCallback,
  responseCallback
) => {
  const uuid = uuidv4()

  const initial = async () => {
    return new Promise(
      (resolve) => {
        if (initialMethod === 'get') {
          resolve(http.get(initialUrl, initialConfig))
        } else if (initialMethod === 'put') {
          resolve(http.put(initialUrl, initialData, initialConfig))
        } else if (initialMethod === 'post') {
          resolve(http.post(initialUrl, initialData, initialConfig))
        } else {
          throw new Error('Unsupported http method: ' + initialMethod)
        }
      })
  }

  const requestAndDelay =
    initial().catch((error) => {
      console.log(error.message)

      if (errorCallback != null) {
        errorCallback(error.message)
      }

      throw error
    }).then(
      resp => {
        return new Promise((resolve) => {
          const requestId = resp.data

          store.dispatch('asyncFetcher/addTimer', {
            id: uuid,
            time: delayTime,
            callBack: resolve(requestId)
          })
        })
      })

  return requestAndDelay
    .then((requestId) => {
      return new Promise((resolve) => {
        (function pollAttempt (waitingTotal) {
          let pollFailure = null
          http.get(`${pollingUrl}/${requestId}`).catch((error) => {
            pollFailure = error.message
            console.log(pollFailure)

            if (errorCallback != null) {
              errorCallback(pollFailure)
            }
          }).then((resp) => {
            if (pollFailure != null) {
              resolve(null)
              return
            }

            const pollingDto = resp.data
            if (pollingDto != null && pollingDto.status === 'IN_PROGRESS' && waitingTotal < waitingLimit) {
              store.dispatch('asyncFetcher/addTimer', {
                id: uuid,
                time: pollTime,
                callBack: pollAttempt.bind(null, pollTime + waitingTotal)
              })
            } else if (pollingDto != null && pollingDto.status === 'COMPLETED') {
              if (responseCallback != null) {
                resolve(responseCallback(resp))
              } else if (resultName) {
                resolve(pollingDto[`${resultName}`])
              } else {
                resolve(pollingDto)
              }
            } else if (pollingDto != null && pollingDto.status === 'ERROR') {
              console.log(`Polling result was ERROR: ${pollingDto.error}`)

              if (errorCallback != null) {
                errorCallback(pollingDto.error)
              }

              resolve(null)
            } else {
              console.log('No polling result returned')

              if (errorCallback != null) {
                errorCallback('Request timed out')
              }

              resolve(null)
            }
          }
          )
        })(delayTime)
      })
    })
}
