import http from '@/http';

export const BROWSE_FILTERS_RECEIVED = 'BROWSE_FILTERS_RECEIVED';
export const BROWSE_FILTERS_SET_SELECTED = 'BROWSE_FILTERS_SET_SELECTED';
export const BROWSE_FILTERS_CLEAR_SELECTED = 'BROWSE_FILTERS_CLEAR_SELECTED';
export const BROWSE_FILTERS_REMOVE_FILTER = 'BROWSE_FILTERS_REMOVE_FILTER';
export const BROWSE_FILTERS_UPDATE_SELECTED_OPTIONS = 'BROWSE_FILTERS_UPDATE_SELECTED_OPTIONS';
export const BROWSE_FILTERS_SET_SELECTED_AND_OPTIONS = 'BROWSE_FILTERS_SET_SELECTED_AND_OPTIONS';

const state = {
  items: [],
  isLoaded: false,
  selected: {}
};

const getters = {
  selectedFilters: (state) => state.items.filter((filter) => state.selected[filter.field])
};

const actions = {
  async getFilters({commit}) {
    try {
      const {data} = await http.get('search/metadata');
      commit(BROWSE_FILTERS_RECEIVED, {filters: data});
    } catch (err) {
      // na
    }
  },
  setSelectedFilters({commit}, selected) {
    commit(BROWSE_FILTERS_SET_SELECTED, {selected});
  },
  removeSelectedFilter({commit, dispatch}, filter) {
    commit(BROWSE_FILTERS_REMOVE_FILTER, {filter});
    dispatch('navigateToSearch');
  },
  clearSelectedFilters({commit, dispatch}) {
    commit(BROWSE_FILTERS_CLEAR_SELECTED);
    dispatch('navigateToSearch');
  },
  updateSelectedFilterOptions({commit, dispatch}, {filter, options}) {
    commit(BROWSE_FILTERS_UPDATE_SELECTED_OPTIONS, {filter, options});
    dispatch('navigateToSearch');
  },
  setSelectedFiltersAndOptions({commit}, options) {
    commit(BROWSE_FILTERS_SET_SELECTED_AND_OPTIONS, {options});
  }
};

const mutations = {
  [BROWSE_FILTERS_RECEIVED](state, {filters}) {
    state.items = filters;
    state.isLoaded = true;
  },
  [BROWSE_FILTERS_SET_SELECTED](state, {selected}) {
    const filterFields = selected.map((filter) => filter.field);
    const selectedFilters = {};
    filterFields.forEach((field) => {
      selectedFilters[field] = state.selected[field] ?
        state.selected[field] : [];
    });
    state.selected = selectedFilters;
  },
  [BROWSE_FILTERS_CLEAR_SELECTED](state) {
    const selectedFilters = {};
    Object.keys(state.selected).forEach((key) => {
      selectedFilters[key] = [];
    });
    state.selected = selectedFilters;
  },
  [BROWSE_FILTERS_REMOVE_FILTER](state, {filter}) {
    if (filter.type === 'range') {
      // Vue.delete(state.selected, `${filter.field}_prefix`);   -- deprecated
      // Vue.delete(state.selected, `${filter.field}_suffix`);   -- deprecated
      delete state.selected[`${filter.field}_prefix`]
      delete state.selected[`${filter.field}_suffix`]
    }
    // Vue.delete(state.selected, filter.field);   -- deprecated
    delete state.selected[filter.field]
  },
  [BROWSE_FILTERS_UPDATE_SELECTED_OPTIONS](state, {filter, options}) {
    if (filter.type === 'range') {
      const value = options[0];
      state.selected[filter.field] = value.value;
      if (value.prefix) {
        state.selected[`${filter.field}_prefix`] = value.prefix;
      } else if (value.suffix) {
        state.selected[`${filter.field}_suffix`] = value.suffix;
      }
    } else {
      state.selected[filter.field] = options;
    }
  },
  [BROWSE_FILTERS_SET_SELECTED_AND_OPTIONS](state, {options}) {
    const query = {};
    Object.keys(state.selected).forEach((key) => {
      const selectedValues = state.selected[key];
      if (!selectedValues.length) {
        query[key] = [];
      } else {
        query[key] = selectedValues;
      }
    });
    Object.keys(options).forEach((key) => {
      const value = options[key];
      const values = Array.isArray(value) ? value : value && value.length ? [value] : []; // eslint-disable-line
      const filter = state.items.find((f) => f.field === key);
      if (filter) {
        if (filter.type === 'range') {
          query[key] = values;
          const prefix = `${key}_prefix`;
          if (options[prefix]) {
            query[prefix] = options[prefix];
          }
          const suffix = `${key}_suffix`;
          if (options[suffix]) {
            query[suffix] = options[suffix];
          }
        } else {
          query[key] = values;
        }
      // Unused; browse flows has fixed search-terms and search-modes
      } else if (key === 'q') {
        state.query = options[key];
      } else if (key === 'exact') {
        state.exact = options[key] ? options[key] === true : false
      }
    });
    if (Object.keys(query).length === 0) {
      state.items.forEach((filter) => {
        if (filter.pin) {
          query[filter.field] = [];
        }
      });
    }
    state.selected = query;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
