<template>
  <div class="markdown">
    <iframe :srcdoc="generatedHtml" />
  </div>
</template>

<!--
This lovely component is a solution where the global styles for html tags
are overwritten. To isolate the generated html, it is put into an iframe.

WARNING: This component will not render nicely without additional styling
to the parent. Ref the style section for styling.
-->
<script>
import { marked } from 'marked';

export default {
  name: 'MarkdownView',
  props: {
    markdown: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      generatedHtml: ''
    };
  },
  computed: {
    styling() {
      return `<style>body{font-family: 'Source Sans Pro', Helvetica, sans-serif;}</style>`;
    }
  },
  watch: {
    markdown: {
      immediate: true,
      handler(value) {
        this.generatedHtml = this.styling + marked(value, {breaks: true});
      }
    }
  }
};
</script>

<style scoped lang="scss">
// REF for parent container to view this component properly
//.parent-container {
//  display: flex;
//  flex-direction: column;
//}

.markdown {
  position: relative;
  flex: 1 1 0;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
