import * as Communicator from '@/plugins/hoops/types/web-viewer'
import { cloneDeep } from 'lodash'
import {
  CuttingPlaneType,
  HoopsModelProperties,
} from '@/plugins/hoops/index'
import { DrawMode, Projection } from '@/plugins/hoops/types/web-viewer'

const DEFAULT_CUTTING_SECTION_FOR_X = 0
const DEFAULT_CUTTING_SECTION_FOR_Y = 1
const DEFAULT_CUTTING_SECTION_FOR_Z = 2
const MERGED_CUTTING_SECTION = 3

export class HoopsWebViewer {
  constructor (configs, cameraCallbackFunction, readyCallbackFunction) {
    this.cameraCallbackFunction = cameraCallbackFunction
    this.readyCallbackFunction = readyCallbackFunction

    this.cuttingPlanesHidden = false
    this.cuttingPlaneSectionsMerged = false
    this.cuttingSections = [[], [], [], []] // internal tracker of sections and planes
    this.debugMode = configs.debug
    this.elementId = configs.elementId
    this.explodeMagnitudeMaxValue = configs.explodeMagnitudeMaxValue
    this.filepath = configs.filepath
    this.hoopsWebViewer = new Communicator.WebViewer({
      containerId: configs.elementId,
      endpointUri: configs.filepath,
    })
    this.initialCameraData = null // for resetting to originally loaded camera view
    this.isViewerReady = false
    this.modelViewOptions = null // custom views of the models, may not exist
    this.modelProperties = null
    this.resizeObserver = null // mechanism to resize HOOPS canvas when window or element change size
    this.selectionHighlighting = configs.selectionHighlighting
    this.snapshotFilename = configs.snapshotFilename
    this.snapshotWidth = configs.snapshotWidth
    this.zoomAnimationDuration = configs.zoomAnimationDuration
    this.zoomScaleFactor = configs.zoomScaleFactor
    this.debugLog('Hoops Communicator version', this.hoopsWebViewer.getViewerVersionString())

    this.hoopsWebViewer.setCallbacks({
      camera: () => {
        if (!!document.getElementById('HoopsCameraData')) {
          document.getElementById('HoopsCameraData').innerHTML = JSON.stringify(this.hoopsWebViewer.view.getCamera().toJson(), null, 4)
        }

        if (typeof cameraCallbackFunction === 'function') {
          this.cameraCallbackFunction()
        }
      },
      deprecated: () => {
        // TODO Create notes when using deprecated functionality
      },
      modelStructureReady: async () => {
        if (!!document.getElementById('HoopsCameraData')) {
          document.getElementById('HoopsCameraData').innerHTML = JSON.stringify(this.hoopsWebViewer.view.getCamera().toJson(), null, 4)
        }
        if (!!document.getElementById('HoopsCuttingPlanesData')) {
          document.getElementById('HoopsCuttingPlanesData').innerHTML = JSON.stringify(this.hoopsWebViewer.cuttingManager.toJson(), null, 4)
        }

        // Renders the axis triad and nav cube (top, left, right, etc)
        const view = this.hoopsWebViewer.view
        await view.getNavCube().enable()
        await view.getAxisTriad().enable()

        // Controls click events for selection/highlight
        await this.hoopsWebViewer.selectionManager.setHighlightFaceElementSelection(this.selectionHighlighting)
        await this.hoopsWebViewer.selectionManager.setHighlightLineElementSelection(this.selectionHighlighting)
        await this.hoopsWebViewer.selectionManager.setHighlightNodeSelection(this.selectionHighlighting)
        await this.hoopsWebViewer.selectionManager.setHighlightPointElementSelection(this.selectionHighlighting)
        await this.hoopsWebViewer.view.fitWorld(200, this.initialCameraData)

        // To support reset back to original view
        this._persistCameraData()

        const modelViewOptions = []
        for (const [key, value] of this.hoopsWebViewer.model.getCadViewMap()) {
          if (value.startsWith('__')) continue
          modelViewOptions.push({
            nodeId: key,
            label:  value
          })
        }

        this.modelViewOptions = modelViewOptions
        this.modelProperties = cloneDeep(await this._readProperties(0))

        // Declare readiness and call any post-ready functions
        this.isViewerReady = true
        this.debugLog('Hoops Web Viewer ready!', this.isViewerReady)

        const container = document.getElementById(this.elementId)

        if (container) {
          this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
              if (entry.target === container) {
                this.resizeCanvas()
              }
            }
          })
          this.resizeObserver.observe(container)
          this.debugLog(`ResizeObserver watching ${this.elementId} when width changes`)
        } else {
          this.debugLog('Missing element for ResizeObserver!')
        }

        if (typeof readyCallbackFunction === 'function') {
          this.readyCallbackFunction()
        }
      },
      sceneReady: () => {
        this.hoopsWebViewer.view.setBackgroundColor(Communicator.Color.white())
        // default behavior upon load
        this.hoopsWebViewer.view.setDrawMode(DrawMode.WireframeOnShaded)
        this.hoopsWebViewer.view.setProjectionMode (Projection.Orthographic)
      },
    })

    this.hoopsWebViewer.start()
  }

  /**
   * Adds a new cutting plane or flips the normal of an existing cutting plane.
   * To remove a cutting plane, call 'removeCuttingPlane'.
   *
   * @param cuttingPlaneType
   * @returns {Promise<void>}
   */
  async activateCuttingPlane (cuttingPlaneType) {
    const { sectionIndex, planeIndex } = this._getSectionAndPlaneIndexByTypeIfAny(cuttingPlaneType)

    switch (planeIndex) {
      case null: {
        if (planeIndex != null) {
          this.debugLog(`Activating ${CuttingPlaneType[cuttingPlaneType]} ignored; cutting plane should already exist`)
          return
        }

        let axis
        switch (cuttingPlaneType) {
          case CuttingPlaneType.PLANE_X: {
            axis = Communicator.Axis.X
            break
          }
          case CuttingPlaneType.PLANE_Y: {
            axis = Communicator.Axis.Y
            break
          }
          case CuttingPlaneType.PLANE_Z: {
            axis = Communicator.Axis.Z
            break
          }
          default: {
            this.debugLog(`Cannot activate ${CuttingPlaneType[cuttingPlaneType]} -- ignored`)
          }
        }
        const bounding = await this.hoopsWebViewer.model.getModelBounding(true, false)
        const position = bounding.max

        const normal = this._getNextNormal(cuttingPlaneType, null)
        const plane = Communicator.Plane.createFromPointAndNormal(position, normal)
        const referenceGeometry = this.hoopsWebViewer.cuttingManager.createReferenceGeometryFromAxis(axis, bounding)
        const cuttingSection = this.hoopsWebViewer.cuttingManager.getCuttingSection(sectionIndex)

        await cuttingSection.addPlane(plane, referenceGeometry)
        await cuttingSection.activate()
        await this._syncCuttingPlaneVisibility()

        this._pushPlaneIntoSectionAfterCreation(sectionIndex, cuttingPlaneType)
        this.debugLog(`Added ${CuttingPlaneType[cuttingPlaneType]} with positive normal`, cuttingSection.toJson())
        break
      }
      default: {
        const cuttingSection = this.hoopsWebViewer.cuttingManager.getCuttingSection(sectionIndex)
        const originalCuttingPlane = cuttingSection.getPlane(planeIndex)
        const newPlane = originalCuttingPlane.copy()
        newPlane.normal = this._getNextNormal(cuttingPlaneType, originalCuttingPlane.normal)
        newPlane.d = originalCuttingPlane.d * -1

        await cuttingSection.setPlane(
          planeIndex,
          newPlane,
          cuttingSection.getReferenceGeometry(planeIndex),
        )
        await this._syncCuttingPlaneVisibility()
        this.debugLog(`Added ${CuttingPlaneType[cuttingPlaneType]} with negative normal`, cuttingSection.toJson())
        break
      }
    }
  }

  captureSnapshot () {
    const canvasSize = this.hoopsWebViewer.view.getCanvasSize()
    const previewHeight = (this.snapshotWidth * canvasSize.y) / canvasSize.x

    const config = new Communicator.SnapshotConfig(this.snapshotWidth, previewHeight)
    // Cannot be read from within then-method
    const filename = this.snapshotFilename.toLocaleLowerCase().endsWith('.png') ?
      this.snapshotFilename : `${this.snapshotFilename}.png`

    this.hoopsWebViewer.takeSnapshot(config)
      .then((imageElement) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.width = imageElement.width
        canvas.height = imageElement.height

        context.drawImage(imageElement, 0, 0)

        canvas.toBlob(function(blob) {
          const link = document.createElement('a')
          link.download = filename // Specify filename
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
        }, 'image/png')
      })
  }

  changeDrawMode (mode) {
    this.hoopsWebViewer.view.setDrawMode(mode)
    this.debugLog(`Changing Draw mode to ${DrawMode[mode]}`)
  }

  /**
   * Magnitude should range from 0 to 1
   *
   * @param magnitude
   * @returns {Promise<void>}
   */
  async changeExplode (magnitude) {
    const explodeManager = this.hoopsWebViewer.explodeManager
    const value = Math.max(0, Math.min(1, magnitude));

    switch (value > 0) {
      case true:
        await explodeManager.start()
        await explodeManager.setMagnitude(value * this.explodeMagnitudeMaxValue)
        this.debugLog(`Explode started with magnitude ${explodeManager.getMagnitude()}`)
        break
      case false:
        await explodeManager.stop()
        this.debugLog("Explode stopped")
        break
    }
  }

  async changeModelView (modelView) {
    const option = this.modelViewOptions.find((item) => item.id === modelView.nodeId)

    if (option === null) {
      this.debugLog(`Cannot find model view with node id ${modelView.nodeId}`)
      return
    }

    this.debugLog(`Changing model view with node id ${modelView.nodeId}`)
    await this.hoopsWebViewer.model.activateCadView(modelView.nodeId)
  }

  async changeProjection (projection) {
    // Prevent redundant calls; if the same value is set, there is a jiggle.
    const current = this.hoopsWebViewer.view.getCamera().getProjection()
    this.debugLog('Changing projection', Communicator.Projection[projection])

    if (projection !== null && current !== projection) {
      await this.hoopsWebViewer.view.resetCamera()
      await this.hoopsWebViewer.view.setProjectionMode(projection)
    }
  }

  async changeViewOrientation (viewOrientation) {
    this.debugLog('Changing view orientation', Communicator.ViewOrientation[viewOrientation])

    if (viewOrientation !== null) {
      await this.hoopsWebViewer.setViewOrientation(viewOrientation)
    }
  }

  debugLog (...messages) {
    if (this.debugMode) {
      console.log(...messages)
    }
  }

  /**
   * Internal function.
   *
   * @returns {{yNodeId: Communicator.NodeId, xNodeId: Communicator.NodeId, zNodeId: Communicator.NodeId}}
   */
  _getCuttingPlaneNodeIds () {
    const cuttingManager = this.hoopsWebViewer.cuttingManager
    const xData = this._getSectionAndPlaneIndexByTypeIfAny(CuttingPlaneType.PLANE_X)
    const yData = this._getSectionAndPlaneIndexByTypeIfAny(CuttingPlaneType.PLANE_Y)
    const zData = this._getSectionAndPlaneIndexByTypeIfAny(CuttingPlaneType.PLANE_Z)

    return {
      xNodeId: cuttingManager.getCuttingSection(xData.sectionIndex).getNodeId(xData.planeIndex),
      yNodeId: cuttingManager.getCuttingSection(yData.sectionIndex).getNodeId(yData.planeIndex),
      zNodeId: cuttingManager.getCuttingSection(zData.sectionIndex).getNodeId(zData.planeIndex)
    }
  }

  /**
   * Internal function to return the next normal for the respective cutting plane.
   *
   * @param cuttingPlaneType
   * @param normal
   * @returns {Communicator.Point3}
   * @private
   */
  _getNextNormal (cuttingPlaneType, normal) {
    switch (cuttingPlaneType) {
      case CuttingPlaneType.PLANE_X: {
        if (normal === null) {
          return new Communicator.Point3(1, 0, 0)
        }

        return normal.x === 1 ?
          new Communicator.Point3(-1, 0, 0) :
          new Communicator.Point3(1, 0, 0)
      }
      case CuttingPlaneType.PLANE_Y: {
        if (normal === null) {
          return new Communicator.Point3(0, 1, 0)
        }

        return normal.y === null ?
          new Communicator.Point3(0, -1, 0) :
          new Communicator.Point3(0, 1, 0)
      }
      case CuttingPlaneType.PLANE_Z: {
        if (normal === null) {
          return new Communicator.Point3(0, 0, 1)
        }

        return normal.z === null ?
          new Communicator.Point3(0, 0, -1) :
          new Communicator.Point3(0, 0, 1)
      }
    }
  }

  /**
   * Internal function.
   *
   * @param type
   * @returns {{sectionIndex: null, planeIndex: null}|{sectionIndex: (number), planeIndex: (number|null)}}
   */
  _getSectionAndPlaneIndexByTypeIfAny (type) {
    switch (type) {
      case CuttingPlaneType.PLANE_X: {
        const sectionIndex = this.cuttingPlaneSectionsMerged ?
          MERGED_CUTTING_SECTION : DEFAULT_CUTTING_SECTION_FOR_X
        const planeIndex = this.cuttingSections[sectionIndex]
          .findIndex((item) => item === CuttingPlaneType.PLANE_X)
        return { sectionIndex: sectionIndex, planeIndex: planeIndex >= 0 ? planeIndex : null }
      }
      case CuttingPlaneType.PLANE_Y: {
        const sectionIndex = this.cuttingPlaneSectionsMerged ?
          MERGED_CUTTING_SECTION : DEFAULT_CUTTING_SECTION_FOR_Y
        const planeIndex = this.cuttingSections[sectionIndex]
          .findIndex((item) => item === CuttingPlaneType.PLANE_Y)
        return { sectionIndex: sectionIndex, planeIndex: planeIndex >= 0 ? planeIndex : null }
      }
      case CuttingPlaneType.PLANE_Z: {
        const sectionIndex = this.cuttingPlaneSectionsMerged ?
          MERGED_CUTTING_SECTION : DEFAULT_CUTTING_SECTION_FOR_Z
        const planeIndex = this.cuttingSections[sectionIndex]
          .findIndex((item) => item === CuttingPlaneType.PLANE_Z)
        return { sectionIndex: sectionIndex, planeIndex: planeIndex >= 0 ? planeIndex : null }
      }
      default: {
        this.debugLog('_getSectionAndPlaneIndexByTypeIfAny: Ignoring invalid type')
        return { sectionIndex: null, planeIndex: null }
      }
    }
  }

  /**
   * Important cleanup step. Resizing is self-contained per instances of viewer.
   * Unfortunately, the viewer does not know how to disconnect the viewer. Hence,
   * call this before navigating away or before destroying the reference.
   */
  onBeforeDestroy () {
    this.resizeObserver?.disconnect()
    this.debugLog('Hoops Web Viewer cleaned up -- onBeforeDestroy')
  }

  /**
   * Internal function to support camera view reset.
   */
  _persistCameraData () {
    this.initialCameraData = this.hoopsWebViewer.view.getCamera()
  }

  /**
   * Internal function. Cutting sections are not synchronous with the cutting section(s)
   * in HOOPS Web Viewer. We use a small set of sections. Other functions may add cutting
   * planes that we don't track. Thus, these -1 values only help us track any
   * offsets to our cutting plane indices.
   *
   * @param sectionIndex
   * @param cuttingPlaneType
   * @private
   */
  _pushPlaneIntoSectionAfterCreation (sectionIndex, cuttingPlaneType) {
    const planeCount = this.hoopsWebViewer.cuttingManager.getCuttingSection(sectionIndex).getCount()
    const diff = planeCount - this.cuttingSections[sectionIndex].length - 1 // -1 for the plane added

    // Add any untracked planes that affect this new plane's index in the section
    if (planeCount > 1) {
      this.cuttingSections[sectionIndex].push(...new Array(diff).fill(-1))
    }

    this.cuttingSections[sectionIndex].push(cuttingPlaneType)
  }

  /**
   * Internal function. Optional node id; defaults to root node id
   *
   * @param nodeId
   */
  async _readProperties (nodeId) {
    const model = this.hoopsWebViewer.model
    let targetNodeId = nodeId ?? 0

    const response = new HoopsModelProperties({
      childNodeIds: model.getNodeChildren(targetNodeId),
      edgeCount: await model.getEdgeCount(targetNodeId), // rare property
      faceCount: await model.getFaceCount(targetNodeId), // rare property
      nodeId: targetNodeId,
      nodeName: model.getNodeName(targetNodeId),
      properties: cloneDeep(await model.getNodeProperties(targetNodeId)),
    })

    this.debugLog(`Reading properties of node id ${nodeId}`, response)
    return response
  }

  async removeAllCuttingPlanes () {
    await this.hoopsWebViewer.cuttingManager.clearAllCuttingSections()
    this.cuttingSections = [[], [], [], []]
    this.debugLog('All cutting sections reset', this.hoopsWebViewer.cuttingManager.toJson())
  }

  async removeCuttingPlane (cuttingPlaneType) {
    const { sectionIndex, planeIndex } = this._getSectionAndPlaneIndexByTypeIfAny(cuttingPlaneType)
    const cuttingSection = this.hoopsWebViewer.cuttingManager.getCuttingSection(sectionIndex)
    await cuttingSection.removePlane(planeIndex)
    this.cuttingSections[sectionIndex].splice(planeIndex, 1)
    this.debugLog(`Remove ${CuttingPlaneType[cuttingPlaneType]}`, cuttingSection.toJson())
  }

  async resetCameraView () {
    await this.hoopsWebViewer.view.fitWorld(200, this.initialCameraData)
  }

  /**
   * Adjust canvas when window or container element size changes
   */
  resizeCanvas () {
    this.hoopsWebViewer.resizeCanvas()
    this.debugLog('Resized HOOPS Web Viewer')
  }

  /**
   * Internal function. Ensure each plane's visibility is consistent with
   * cuttingPlanesHidden state.
   *
   * @returns {Promise<void>}
   */
  async _syncCuttingPlaneVisibility () {
    const {xNodeId, yNodeId, zNodeId} = this._getCuttingPlaneNodeIds()
    await this.hoopsWebViewer.model.setNodesVisibility(
      [xNodeId, yNodeId, zNodeId],
      !this.cuttingPlanesHidden
    )

    this.debugLog(`Sync cutting plane(s) visibility to ${this.cuttingPlanesHidden}`)
  }

  async toggleCuttingPlaneSection () {
    const _this = this

    async function movePlane (type) {
      const { sectionIndex, planeIndex } = _this._getSectionAndPlaneIndexByTypeIfAny(type)

      if (planeIndex === null) {
        _this.debugLog(`Ignored moving plane ${CuttingPlaneType[type]}; plane is inactive`)
        return
      }

      const cuttingManager = _this.hoopsWebViewer.cuttingManager
      const movingPlane = cuttingManager.getCuttingSection(sectionIndex)
        .getPlane(planeIndex).copy()
      const referenceGeometry = cuttingManager.getCuttingSection(sectionIndex)
        .getReferenceGeometry(planeIndex)

      let targetSectionIndex
      switch (sectionIndex) {
        case DEFAULT_CUTTING_SECTION_FOR_X:
        case DEFAULT_CUTTING_SECTION_FOR_Y:
        case DEFAULT_CUTTING_SECTION_FOR_Z:
          targetSectionIndex = MERGED_CUTTING_SECTION
          break
        case MERGED_CUTTING_SECTION: {
          switch (type) {
            case CuttingPlaneType.PLANE_X:
              targetSectionIndex = DEFAULT_CUTTING_SECTION_FOR_X
              break
            case CuttingPlaneType.PLANE_Y:
              targetSectionIndex = DEFAULT_CUTTING_SECTION_FOR_Y
              break
            case CuttingPlaneType.PLANE_Z:
              targetSectionIndex = DEFAULT_CUTTING_SECTION_FOR_Z
              break
            default:
              _this.debugLog(`Moving invalid ${type} from merged section ${MERGED_CUTTING_SECTION}`, cuttingManager.toJson())
              return
          }
          break
        }
        default:
          _this.debugLog(`Ignored moving plane from unhandled section ${sectionIndex}`)
          return
      }

      // Add new plane
      const targetSection = cuttingManager.getCuttingSection(targetSectionIndex)
      await targetSection.addPlane(movingPlane, referenceGeometry)
      await targetSection.activate()
      _this._pushPlaneIntoSectionAfterCreation(targetSectionIndex, type)

      // Remove old plane
      await cuttingManager.getCuttingSection(sectionIndex).removePlane(planeIndex)
      _this.cuttingSections[sectionIndex].splice(planeIndex, 1)
      _this.debugLog(`Moved plane ${CuttingPlaneType[type]} from section ${sectionIndex} to ${targetSectionIndex}`, cuttingManager.toJson())
    }

    await movePlane(CuttingPlaneType.PLANE_X)
    await movePlane(CuttingPlaneType.PLANE_Y)
    await movePlane(CuttingPlaneType.PLANE_Z)
    this.cuttingPlaneSectionsMerged = !this.cuttingPlaneSectionsMerged
  }

  async toggleCuttingPlaneVisibility () {
    const {xNodeId, yNodeId, zNodeId} = this._getCuttingPlaneNodeIds()
    const model = this.hoopsWebViewer.model

    await model.setNodesVisibility(
      [ xNodeId, yNodeId, zNodeId ],
      this.cuttingPlanesHidden
    )
    this.cuttingPlanesHidden = !this.cuttingPlanesHidden
  }

  zoomIn () {
    const newCamera = this.hoopsWebViewer.view.getCamera()
    newCamera.setHeight(newCamera.getHeight() * (1 - this.zoomScaleFactor))
    newCamera.setWidth(newCamera.getWidth() * (1 - this.zoomScaleFactor))
    this.hoopsWebViewer.view.setCamera(newCamera, this.zoomAnimationDuration)
  }

  zoomOut () {
    const newCamera = this.hoopsWebViewer.view.getCamera()
    newCamera.setHeight(newCamera.getHeight() * (1 + this.zoomScaleFactor))
    newCamera.setWidth(newCamera.getWidth() * (1 + this.zoomScaleFactor))
    this.hoopsWebViewer.view.setCamera(newCamera, this.zoomAnimationDuration)
  }
}
