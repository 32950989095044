<template>
  <span :class="['icon', spanClass]"
        @click="emit('click')">
    <i :class="iconClass"/>
  </span>
</template>

<script setup>
  defineProps({
    iconClass: { type: String, default: '' },
    spanClass: { type: String, default: '' }
  })
  const emit = defineEmits(['click'])
</script>