<template>
  <div
    v-on-clickaway="closeMenu"
    class="dropdown is-right"
    :class="{'is-active': isMenuOpened}"
  >
    <div class="dropdown-trigger">
      <b-dropdown
        :class="['ml-2','mr-2', 'mb-2']"
        position="is-bottom-left"
        data-unit="actions-dropdown"
        append-to-body
        aria-role="menu"
      >
        <template #trigger>
          <b-button
            icon-right="angle-down"
          >
            {{ $tc('action', 2) }}
          </b-button>
        </template>
        <b-dropdown-item
          v-if="isSavedCartEnabled && isSharedCartEnabled && isSharedCartMaintainer"
          aria-role="listitem"
          has-link
        >
          <router-link
            taget="_blank"
            :to="{name: 'LibrarySharedCarts'}"
          >
            <span class="actions-icon fas fa-shopping-cart" />
            {{ $t('manageItem', {item: $tc('sharedCart', 2)}) }}
          </router-link>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isSavedCartEnabled"
          aria-role="listitem"
          @click="viewCarts"
        >
          <span class="actions-icon fas fa-folder-open" />
          {{ $t('open') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isSavedCartEnabled"
          aria-role="listitem"
          @click="$refs.saveCartModal.open()"
        >
          <span class="actions-icon fas fa-save" />{{ $t('save') }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="isSavedCartEnabled"
          aria-role="listitem"
        >
          <label class="file-label">
            <input
              type="file"
              accept="text/csv"
              class="file-input"
              id="cart-import-file-input"
              @input="importCart"
            >
            <span class="actions-icon fas fa-upload" />{{ $t('import') }}
          </label>
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="!hasItems"
          aria-role="listitem"
          @click="openExportOptionsModal"
        >
          <span class="actions-icon fas fa-download" /><span>{{ $t('export') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="!hasItems"
          aria-role="listitem"
          @click="clearCart"
        >
          <span class="actions-icon fas fa-eraser" />{{ $t('clear') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="actions-buttons buttons mr-1">
      <b-button
        v-if="isSavedCartEnabled && isSharedCartEnabled && isSharedCartMaintainer && !isEssentialsTenant"
        tag="router-link"
        target="_blank"
        :to="{name: 'LibrarySharedCarts'}"
        icon-left="shopping-cart"
        :label="$t('manageItem', {item: $tc('sharedCart', 2)})"
      />
      <b-button
        v-if="isSavedCartEnabled"
        icon-pack="fas"
        icon-left="folder-open"
        @click="viewCarts"
      >
        {{ $t('open') }}
      </b-button>
      <b-button
        v-if="isSavedCartEnabled"
        :disabled="!hasItems"
        icon-pack="fas"
        icon-left="save"
        @click="$refs.saveCartModal.open()"
      >
        {{ $t('save') }}
      </b-button>
      <b-button
        v-if="isSavedCartEnabled"
        icon-pack="fas"
        icon-left="upload"
      >
        <label class="file-label">
          <input
            type="file"
            accept="text/csv"
            class="file-input"
            id="cart-import-file-input"
            @input="importCart"
          >{{ $t('import') }}
        </label>
      </b-button>
      <b-button
        :disabled="!hasItems"
        icon-pack="fas"
        icon-left="download"
        @click="openExportOptionsModal"
      >
        {{ $t('export') }}
      </b-button>
      <b-button
        v-if="isSavedCartEnabled || isSharedCartEnabled"
        :disabled="!hasItems"
        icon-pack="fas"
        icon-left="eraser"
        @click="clearCart"
      >
        {{ $t('clear') }}
      </b-button>
    </div>

    <save-cart-modal ref="saveCartModal" />
    <open-cart-modal ref="openCartModal" />

    <cart-merge-override-modal
      ref="mergeOverrideModal"
      @openClear="importConfirm"
    />
    <delete-modal
      v-if="clearCartModalOpened"
      :item="items"
      @deleteConfirm="clearConfirm"
      @cancel="clearCartModalOpened = false"
    />
    <submit-modal ref="exportOptionsModal"
                  :is-loading="isLoading"
                  :canSubmit="canSubmitExport"
                  @submit="exportSelected">
      <template #title>
        {{ $t('export') }}
      </template>
      <template #content>
        <label class="label is-size-6 mt-4">{{ $t('format') }}</label>
        <b-field>
          <b-radio  v-model="exportFormat"
                    native-value="email">
            {{ $t('email') }}
          </b-radio>
        </b-field>
        <emails-list  v-model="emails"
                      :newAddress="newAddress"
                      :canSave="canSubmitExport"
                      :disableAll="exportFormat !== 'email'"
                      :isLoading="isLoading"
                      @update:newAddress="newAddress = $event"
                      @update:canSave="canSubmitExport = $event"/>
        <cart-columns-buttons v-show="exportFormat === 'email'"
                              :isBasicExport="isBasicExport"
                              :disabled="exportFormat !== 'email'"
                              @update:isBasicExport="isBasicExport = $event"/>

        <b-field>
          <b-radio
            v-model="exportFormat"
            native-value="csv"
          >
            {{ $t('exportAsCsv') }}
          </b-radio>
        </b-field>
        <cart-columns-buttons v-show="exportFormat === 'csv'"
                              :isBasicExport="isBasicExport"
                              :disabled="exportFormat !== 'csv'"
                              @update:isBasicExport="isBasicExport = $event"/>
        <b-field>
          <b-radio
            v-model="exportFormat"
            native-value="xlsx"
          >
            {{ $t('exportAsXlsx') }}
          </b-radio>
        </b-field>
      </template>
    </submit-modal>
  </div>
</template>

<script>
import DeleteModal from '@/components/common/DeleteModal'
import { directive as onClickaway } from 'vue3-click-away'
import CartMergeOverrideModal from './CartMergeOverrideModal'
import fileServices from '@/components/common/fileServices'
import OpenCartModal from './open/OpenCartModal'
import SaveCartModal from './save/SaveCartModal'
import SubmitModal from '@/components/common/modal/SubmitModal'
import { mapGetters } from 'vuex'
import EmailsList from './EmailsList'
import CartColumnsButtons from './CartColumnsButtons'
import { postError } from '@/helpers/notification'

export default {
  name: 'CartMenu',
  mixins: [fileServices],
  directives: {
    onClickaway
  },
  components: {
    CartMergeOverrideModal,
    DeleteModal,
    OpenCartModal,
    SaveCartModal,
    SubmitModal,
    EmailsList,
    CartColumnsButtons
  },
  data () {
    return {
      exportFormat: null,
      clearCartModalOpened: false,
      itemToImport: false,
      isBasicExport: false,
      isLoading: false,
      isMenuOpened: false,
      emails: [],
      canSubmitExport: true,
      newAddress: ""
    }
  },
  watch: {
    exportFormat () { // Reset value when export format changes
      this.isBasicExport = false
    }
  },
  computed: {
    ...mapGetters({
      isSavedCartEnabled: 'user/isSavedCartEnabled',
      isSharedCartEnabled: 'user/isSharedCartEnabled',
      isSharedCartMaintainer: 'user/isSharedCartMaintainer',
      isEssentialsTenant: 'user/isEssentialsTenant'
    }),
    hasItems () {
      return this.items.length > 0
    },
    items () {
      return this.$store.state.cart.items
    },
    mailToList() {
      return [this.newAddress].concat(this.emails)
    }
  },
  methods: {
    clearCart () {
      if (this.hasItems) {
        this.clearCartModalOpened = true
        this.closeMenu()
      }
    },
    clearConfirm () {
      this.clearCartModalOpened = false
      this.$store.dispatch('cart/clearCart')
    },
    async emailCart() {
      try {
        await this.$rest.post(`cart/${this.exportFormat}`, this.mailToList, {
          params: {
            trim: this.isBasicExport
          }
        })
        this.$notify({
          title: this.$t('success'),
          type: 'success',
          text: this.$t('cartEmailSent'),
          duration: 5000
        })
      } catch {
        postError({
          title: this.$t('error'),
          text: this.$t('cartEmailFailed'),
        })
      }
    },
    async exportSelected () {
      if (!this.canSubmitExport) return
      try {
        this.isLoading = true
        if (this.exportFormat === 'csv') {
          await this.$rest.get(`cart/${this.exportFormat}`, {
            params: {
              trim: this.isBasicExport
            }
          }).then((response) => {
            this.downloadCsvFrom(response.data)
          })
        } else if (this.exportFormat === "xlsx") {
          await this.$rest.get(`cart/${this.exportFormat}`)
            .then((response) => {
              this.downloadXlsxFrom(response.data.body)
            })
        } else if (this.exportFormat === "email") {
          await this.emailCart()
        }
      } catch (e) {
        postError({
          title: this.$t('error'),
          text: this.$t('failedAction', {
            content: this.$tc('cart', 2),
            action: this.$t('download').toLocaleLowerCase()
          }),
        })
      } finally {
        this.isLoading = false
        this.emails = []
        this.newAddress = ""
      }
      this.$refs.exportOptionsModal.close()
    },
    importCart (event) {
      if (event.target.files?.length > 0) {
        const [file] = event.target.files

        if (file) {
          if (this.items.length) {
            this.itemToImport = file
            this.$refs.mergeOverrideModal.open()
          } else {
            this.$store.dispatch(
              'cart/importCart',
              { attachment: file, clear: false }
            )
            this.closeMenu()
            this.resetFileUploadInput()
          }
        }
      }
    },
    openExportOptionsModal () {
      this.exportFormat = 'csv'
      this.isBasicExport = false
      this.$refs.exportOptionsModal.open()
    },
    resetFileUploadInput () {
      document.getElementById('cart-import-file-input').value = ''
    },
    importConfirm (clear) {
      this.$store.dispatch(
        'cart/importCart',
        { attachment: this.itemToImport, clear }
      )
      this.itemToImport = undefined
      this.closeMenu()
    },
    toggleMenu () {
      this.isMenuOpened = !this.isMenuOpened
    },
    closeMenu () {
      this.isMenuOpened = false
    },
    viewCarts () {
      this.$refs.openCartModal.open()
      this.closeMenu()
    }
  }
}
</script>

<style scoped>
.actions-icon {
  padding-right: 1.0rem;
  padding-left: 0;
  margin-left: 0;
}

@media only screen and (min-width: 1024px) {
  .dropdown-trigger {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .actions-buttons {
    display: none;
  }
}

.file-input {
  display: none;
}
</style>
