import i18n from '@/locales'
import { notify } from '@kyvg/vue3-notification'
import { postError } from '@/helpers/notification'

/*
 * There is a limited use case where an anchor element is insufficient and
 * require a custom download solution. iFrames are a primary use case.
 */
export function customDownload(downloadUrl: string) {
  const filename = getFilename(downloadUrl)

  fetch(downloadUrl, { credentials: 'include' })
    .then(response => {
      /*
       * JS Fetch may not clean up after itself. Repetitive downloads may look
       * off. Hence, we have to look at this transactions' status.
       */
      if (!response.ok) {
        throw new Error('Failed to download')
      }

      // Reduce case where two toasts pop up.
      notify({
        title: i18n.global.t('downloadStarted', { name: filename }),
        type: 'success',
        duration: 5000
      })

      return response.blob()
    })
    .then(blob => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      link.remove()
    })
    .catch(_ => {
      postError({
        title: i18n.global.t('downloadFailed', { name: filename }),
      })
    })
}

export function getFilename (file: string) {
  const path = file.split('/')
  const { [path.length - 1]: last } = path

  return decodeURIComponent(last)
}
