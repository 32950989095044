import { Translation } from '@/interfaces/admin/index'

export enum OrderFieldValue {
  USER = 'USER',
  FEDEX = 'FEDEX',
  UPS = 'UPS',
  USPS = 'USPS'
}

export enum OrderFieldAddressType {
  ORGANIZATION = 'ORGANIZATION',
  USER = 'USER',
  BOTH = 'BOTH',
  NONE = 'NONE'
}

export enum OrderFieldType {
  ADDRESS = 'ADDRESS',
  DATE = 'DATE',
  EMAIL_LIST = 'EMAIL_LIST',
  HORIZONTAL_LINE = 'HORIZONTAL_LINE',
  LABEL = 'LABEL',
  LISTBOX = 'LISTBOX',
  LOGO = 'LOGO',
  ORDER_NUMBER = 'ORDER_NUMBER',
  // SUBMITTED_FOR_FIELD = 'SUBMITTED_FOR_FIELD', // Not specified for use
  TAG = 'TAG',
  TEXTAREA = 'TEXTAREA',
  TEXTFIELD = 'TEXTFIELD'
}

export interface OrderField {
  id: number
  name: string
  type: OrderFieldType
  numberOfOrderTemplates: number
  numberOfEmailTemplates: number
  defaultValue: OrderFieldValue[] | string[]
  displayedInOrdersList: boolean
  addressType?: OrderFieldAddressType
  listBoxValues?: {name:string, translations: Translation[]}[],
  translatedName: string,
  created: Date
  updated: Date
}

export interface OrderFieldDetail {
  id: number
  name: string
  type: OrderFieldType
  translations: Translation[]
  numberOfOrderTemplates: number
  numberOfEmailTemplates: number
  defaultValue: OrderFieldValue[] | string[]
  created: Date
  updated: Date
}
