<template>
  <figure class="image logo" 
          @click="goHome"/>
</template>

<script setup>
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'

const { goHome } = useWidgetNavigator()
</script>

<style lang="scss" scoped>
  figure:hover {
    cursor: pointer;
  }
</style>