<template>
  <div class="field has-addons is-marginless">
    <div class="control">
      <input autocomplete="off"
             :value="input"
             :placeholder="name"
             class="input"
             @input="debounceInput"/>
    </div>
    <div class="control">
      <a class="button" @click="removeFilter">
        <span class="icon is-small">
          <i class="fa fa-times" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
  name: 'TextFilter',
  props: {
    filter: {
      type: Object,
      required: true
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    name() {
      if (this.filter.name.indexOf('%%') === 0) {
        return this.$i18n.t(this.filter.name.replace(/%%/g, ''));
      }
      return this.filter.name;
    },
    input: {
      get() {
        return this.selected;
      },
      set(value) {
        const values = [];
        this.selected = value;
        if (this.selected) {
          values.push(this.selected);
        }
        this.$emit('change', values);
      }
    }
  },
  data() {
    return {
      selected: ''
    };
  },
  watch: {
    selectedValues() {
      this.selected = this.selectedValues.length ? this.selectedValues[0] : '';
    }
  },
  methods: {
    /* eslint-disable no-invalid-this */
    // TODO - fix this if you happen to be working in this file.  The value of 'this' is potentially
    // ambuguous
    debounceInput: debounce(function(e) {
      this.input = e.target.value;
    }, 400), // TODO - why is this 400 where it's 500 most eveywhere else?
    /* eslint-enable no-invalid-this */
    removeFilter() {
      this.$emit('remove');
    }
  }
};
</script>

<style scoped>
.button {
  padding-left: .5rem;
  padding-right: .5rem;
}
.button .icon:last-child:not(:first-child) {
  margin-left: 0;
}
</style>
