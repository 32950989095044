import { ContentDto } from "./content"

export enum RequiredWidgetConfig {
  accessToken = "accessToken",
  refreshToken = "refreshToken",
  documotoDomain = "documotoDomain",
  locale = "locale",
  widgetType = "widgetType"
}

export enum MediaWidgetEntityTypes {
  media = "media",
  chapter = "chapter",
  page = "page"
}

export enum WidgetMediaTypes {
  book = 'book',
  document = 'document',
  external = 'external',
  illustration = 'illustration',
  image = 'image',
  microsite = 'microsite',
  video = 'video'
}

type WidgetTheme = {
  "brand_primary": string
}

export type WidgetConfig = Record<string, any> & {
  [RequiredWidgetConfig.accessToken]: string,
  [RequiredWidgetConfig.refreshToken]: string,
  [RequiredWidgetConfig.locale]: string,
  [RequiredWidgetConfig.documotoDomain]: string,
  enablePartTags?: boolean,
  enablePartComments?: boolean,
  enableCart?: boolean,
  bindToElementById?: string,
  theme: WidgetTheme,
  widgetType: WidgetTypes,
  mediaIdentifier?: string,
  targetUri?: string,
  showTableOfContents?: boolean,
  pageHashKey?: string,
  partNumber?: string,
  enableInformationLandingPage?: boolean,
  hostedDomain?: string|null
}

export enum WidgetTypes {
  media = "media",
  library = "library",
  loading = "loading",
  page = "page"
}

const lacksIdentifier = [ WidgetTypes.library, WidgetTypes.loading ]
type LacksIdentifier = (typeof lacksIdentifier)[number]

export function isWidgetLackingIdentifier(x: any): x is LacksIdentifier {
  return lacksIdentifier.includes(x)
}

export const WidgetTypesToIdentifiers = Object.freeze({
  [WidgetTypes.media]: "mediaIdentifier",
  [WidgetTypes.page]: "pageHashKey"
})

export type Breadcrumb = {
  created?: Date,
  id?: number,
  identifier?: string,
  name: string,
  type: string,
  updated?: Date
}

export enum LibraryWidgetOnlyPages {
  home = 'home',
  search = 'search',
  browse = 'browse',
  part = 'part'
}

export enum InfoPage {
  info = 'info'
}

export const LibraryWidgetPages = Object.freeze({
  ...LibraryWidgetOnlyPages,
  ...MediaWidgetEntityTypes,
  ...InfoPage
})

export enum WidgetRootBreadcrumbs {
  search = 'search',
  browse = 'browse',
  home = 'home'
}

export enum WidgetEvents {
  login = 'widgetLogin',
  error = 'widgetError'
}

export enum WidgetEventDetailTypes {
  Success = 'Success',
  Error = 'Error'
}

export enum WidgetEventSuccessMessages {
  login = 'Successful Documoto Widget Login'
}

export enum WidgetEventErrors {
  config = 'config',
  init = 'init',
  location = 'location'
}

export type TocFlatListItemDto = { item: ContentDto, chapters: Set<number>, index?: number }
