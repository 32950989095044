<template>
  <td class="part-number-cell">
    <strong>
      <component  :is="isWidget ? 'span' : 'router-link'"
                  :to="{ path: getNewDetailsPath() }"
                  :class="{ 'has-text-info': bomItem.highlight,
                            'has-text-link': hasWidgetInfoLandingPage,
                            'pointer-on-hover': hasWidgetInfoLandingPage }"
                  @click="handlePartInfoRedirect">
        {{ bomItem.partNumber }}
      </component>
    </strong>
  </td>
</template>

<script>
import { handlePartInfoRedirect } from './compositionObjects'
export default {
  name: 'BomCellPartNumber',
  emits: ['openWidgetPartInfo'],
  props: {
    bomItem: {
      type: Object,
      required: true
    },
    isWidget: Boolean,
    hasWidgetInfoLandingPage: Boolean
  },
  methods: {
    getNewDetailsPath() {
      return this.bomItem.pagePartUrl + '/details'
    },
    open () {
      this.$emit('open', {
        content: this.bomItem,
        route: ''
      })
    },
    ...handlePartInfoRedirect
  }
}
</script>

<style scoped>
.table tr.is-selected a, .table tr.is-selected strong {
  color: currentColor !important;
}
</style>
