<template>
  <th class="cart-price"
      :class=className
      :data-int=className
      @click="showHidePrice">
    <label>{{ label }}</label>
    <a v-if="hasHidePrice"
       data-toggle="tooltip"
       :title="label"
       class="is-pulled-right">
      <span class="icon is-small has-text-link">
        <i class="fa" :class="icon"/>
      </span>
    </a>
  </th>
</template>

<script>
export default {
  name: 'CartHeaderPrice',
  props: {
    column: {
      type: Object,
      required: true
    },
    hasHidePrice: {
      type: Boolean,
      default: false
    },
    hideRetailPrice: {
      type: Boolean,
      default: false
    },
    hideDiscountPrice: {
      type: Boolean,
      default: false
    },
    hideWholeSalePrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      return [`cart-header-${this.column.property}`, this.isHidden ? 'cart-price-hidden' : ''];
    },
    isHidden() {
      switch (this.column.property) {
        case 'retailPrice':
          return this.hideRetailPrice;
        case 'wholesalePrice':
          return this.hideWholeSalePrice;
        case 'discountedPrice':
          return this.hideDiscountPrice;
        default:
          return false;
      }
    },
    label() {
      let label = '';
      switch (this.column.property) {
        case 'retailPrice':
          label = this.$t('retail');
          break;
        case 'wholesalePrice':
          label = this.$t('wholesale');
          break;
        case 'discountedPrice':
          label = this.$t('discounted');
          break;
        default:
          break;
      }
      return !this.isHidden ? label : '';
    },
    icon() {
      return !this.isHidden ? 'fa-eye' : 'fa-eye-slash';
    }
  },
  methods: {
    showHidePrice() {
      this.$emit('togglePrice', {property: this.column.property});
    }
  }
};
</script>

<style scoped>

</style>
