<template>
  <section class="section is-paddingless">
    <img  v-if="isWidget"
          ref="img"
          class="image"/>
    <img  v-else
          :src="asset" 
          class="image"/>
  </section>
</template>

<script>
import { ref } from 'vue'
import { mapState, useStore } from 'vuex'
import { useFetchImage } from '@/components/widgets/composables/useFetchImage'

export default {
  name: 'ImageViewer',
  setup() {
    const img = ref()

    const store = useStore()
    const { imgSrc, isWidget } = useFetchImage(store.state.content.contentUrl, img)
    
    return { imgSrc, isWidget, img }
  },
  computed: {
    ...mapState({
      asset: ({ content }) => content.contentUrl
    })
  }
};
</script>

<style scoped>
.section {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  position: relative;
}
.image {
  position: absolute;
  height: 100%;
  width: auto;
}
</style>