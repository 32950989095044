<template>
  <div class="field">
    <label class="checkbox switch">
      <input type="checkbox"
             :checked="value"
             @change="updateValue" />
      <span class="slider"
            :data-id="uuid"
            :id="uuid"></span>
      <slot/>
    </label>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { v4 as uuidv4 } from 'uuid'

/**
 * Common input component for a custom switch using the input type
 * checkbox. Here are a few use cases:
 *  - One-way binding via :value and optionally perform additional
 *      event handling via input events
 *  - Two-way binding via v-model
 *
 * Text is slotted if necessary.
 */
const emit = defineEmits(['input', 'update'])
const props = defineProps({
  value: Boolean
})
const updateValue = (event: Event) => {
  const value = event.target!!.checked;
  emit('input', value);
  emit('update', value);
};
const uuid = uuidv4()

onMounted(() => {
  if (props.value) {
    document.getElementById(uuid)!!.checked = true;
  }
})
</script>

<style scoped lang="scss">
.switch {
  align-items: center;
  display: inline-flex;
  gap: calc(0.75em - 1px);
  position: relative;
}

.switch input {
  opacity: 0;
}

.slider {
  background-color: hsl(0, 0%, 71%);
  border-radius: 290486px;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 1.575em;
  padding: 0.2em;
  position: static;
  transition: 0.4s;
  width: 2.75em;
}

.slider:before {
  background-color: hsl(0, 0%, 96%);
  border-radius: 50%;
  bottom: 0.2em;
  content: "";
  height: 1.175em;
  position: absolute;
  transition: 0.4s;
  width: 1.175em;
}

input:checked + .slider {
  background-color: var(--primary);
}
input:checked + .slider::before {
  transform: translate3d(100%, 0, 0);
}
input:focus + .slider {
  box-shadow: 0 0 0.5em hsl(0, 0%, 71%);
}
input:focus:checked + .slider {
  box-shadow: 0 0 0.5em var(--primary);
}
</style>
