import http from '@/http'

export async function superImposeImages (imgFile: any, svgContent: string, formPageId: any, draftSheetId?: any) {
  let api = '/admin/draft-sheets/image/superimpose'
  const formData = new FormData()
  formData.append('svgContent', svgContent)
  formData.append('targetPageId', formPageId)
  if (imgFile.file) {
    formData.append('files', imgFile.file, imgFile.name)
  } else {
    formData.append('draftSheetId', draftSheetId)
    api = '/admin/draft-sheets/image/superimpose-existing'
  }
  const { data } = await http.put(api, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    }
  })
  return data
}
