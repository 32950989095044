<template>
  <td data-int="cart-cell-eta">
    {{ item.eta }}
  </td>
</template>

<script>
export default {
  name: 'CartCellEta',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
