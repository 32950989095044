<template>
  <th class="item-header">
    <div class="is-flex">
      <slot />
      <div class="ml-3">{{ $t('item') }}</div>
    </div>
  </th>
</template>

<script>
export default {
  name: 'BomHeaderItem'
}
</script>

<style scoped>
.item-header {
  border-right: none;
  border-left: none;
}
</style>
