import http from '@/http'
import { DraftImage } from '@/interfaces/admin'

export async function saveDraftImage (imgFile: File, formPageId: any, width: any, height: any): Promise<DraftImage> {
  const formData = new FormData()
  formData.append('files', imgFile)
  formData.append('targetPageId', formPageId)
  formData.append('width', width)
  formData.append('height', height)
  const { data } = await http.put('/admin/draft-sheets/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json'
    }
  })
  return data
}
