<template>
  <div class="details-page-wrapper">
    <anchor-navigation></anchor-navigation>
    <div v-if="isMobileView">
      <mobile-details></mobile-details>
    </div>
    <div v-else>
      <desktop-details></desktop-details>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useBreakpoints } from "@/helpers"

import AnchorNavigation from "@/components/library/content/details/AnchorNavigation.vue"
import DesktopDetails from "@/components/library/content/details/DesktopDetails.vue"
import MobileDetails from "@/components/library/content/details/MobileDetails.vue"

const { width } = useBreakpoints()
const isMobileView = computed(() => width.value <= 768)
</script>
