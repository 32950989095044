import http from '@/http';

const STATUS_CODES_RECEIVED = 'STATUS_CODES_RECEIVED';

const state = {
  items: {},
  isLoaded: false
};

const actions = {
  async getStatusCodes({commit}) {
    try {
      if (!state.isLoaded) {
        const {data} = await http.get('orders/statuses');
        commit(STATUS_CODES_RECEIVED, {locales: data});
      }
    } catch (err) {
      // na
    }
  }
};

const mutations = {
  [STATUS_CODES_RECEIVED](state, {locales}) {
    state.items = locales;
    state.isLoaded = true;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
