<template>
  <span
    class="icon base"
    :style="{'max-width': computedWidth}"
  >
    <b-icon
      :pack="iconPack"
      :icon="icon"
      :size="size"
    />
    <b-icon
      v-if="showBadge"
      :pack="badgePack"
      :icon="badge"
      class="badge"
      :size="badgeSize"
    />
  </span>
</template>

<script>
export default {
  name: 'BadgedIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    iconPack: {
      type: String,
      default: 'fas'
    },
    badge: {
      type: String,
      required: true
    },
    badgePack: {
      type: String,
      default: 'fas'
    },
    showBadge: Boolean,
    size: {
      type: String,
      default: 'is-medium'
    }
  },
  computed: {
    // Allows the badge to grow with the icon
    badgeSize () {
      if (this.size === 'is-medium') return 'is-small'
      else if (this.size === 'is-large') return 'is-medium'
      else return 'is-small'
    },
    /**
     * BomInfo and BomCellAction have two sizes; this ensures the badges is in the relative same location
     * as the badged icon may scale
     */
    computedWidth () {
      if (this.size === 'is-medium') return '3rem'
      else if (this.size === 'is-large') return '4rem'
      else return '3rem'
    }
  }
}
</script>

<style scoped>
.base {
  text-align: center;
  vertical-align: middle;
  position: relative;
  height: 2rem;
  width: fit-content;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  font-size: 12px;
  line-height: 1;
  border-radius: 50%;
  align-items: flex-start;
  justify-content: flex-end;
}
</style>
