<template>
  <div>
    <b-table v-model:selected="selected"
             :data="filteredData"
             default-sort="name"
             focusable
             hoverable
             sort-icon="chevron-up"
             sort-icon-size="is-small"
             @update:selected="$emit('update:selected', selected)">
      <b-table-column :label="$t('name')"
                      field="name"
                      sortable>
        <template v-slot:name="props">
          <div v-if="props">
            {{ props.row.name }}
          </div>
        </template>
      </b-table-column>
      <b-table-column :label="$t('numberOf', {item: $tc('part', 2)})"
                      field="size"
                      header-class="header"
                      sortable
                      width="100">
        <template v-slot:size="props">
          <div v-if="props">
            {{ props.row.size }}
          </div>
        </template>
      </b-table-column>
      <b-table-column :label="$t('updated')"
                      field="updated"
                      sortable>
        <template v-slot:updated="props">
          <div v-if="props">
            {{ $d(props.row.updated, 'long') }}
          </div>
        </template>
      </b-table-column>
      <b-table-column width="100" field="buttons">
        <template #header/>
        <template v-slot:buttons="props">
          <div v-if="props">
            <div class="buttons is-pulled-right">
              <b-button icon-right="trash"
                        size="is-small"
                        type="is-danger is-light"
                        @click="requestDelete(props.row)"/>
            </div>
          </div>
        </template>
      </b-table-column>

      <delete-content-modal ref="deleteContentModal"
                            :item="deleteContentModalProps"
                            @submit="deleteSelected"/>
    </b-table>
  </div>
</template>

<script>
import DeleteContentModal from '@/components/common/modal/DeleteContentModal'

export default {
  name: 'SavedCartTable',
  props: {
    data: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      default: ''
    }
  },
  components: {
    DeleteContentModal
  },
  data() {
    return {
      selected: null,
      selectedForDelete: null
    }
  },
  computed: {
    deleteContentModalProps() {
      return this.selectedForDelete
        ? {
          type: this.$tc('cart', 1),
          name: this.selectedForDelete.name
        }
        : {}
    },
    filteredData() {
      if (!this.filter) return this.data
      const f = this.filter.toLocaleLowerCase()
      return this.data.filter((item) => item.name.toLocaleLowerCase().includes(f))
    }
  },
  methods: {
    async requestDelete(cart) {
      this.selectedForDelete = cart
      this.$refs.deleteContentModal.open()
    },
    async deleteSelected() {
      const cartId = this.selectedForDelete.id
      await this.$store.dispatch(
        'savedCarts/delete',
        {cartId}
      )
      this.selected = null
      this.selectedForDelete = null
    }
  }
}
</script>
