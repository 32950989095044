import http from '@/http'

const CATEGORIES_RECEIVED = 'CATEGORIES_RECEIVED'

const state = {
  items: {},
  isLoaded: false
}

const actions = {
  async getCategories ({ commit }) {
    try {
      if (!state.isLoaded) {
        const { data } = await http.get('/medias/categories')
        commit(CATEGORIES_RECEIVED, { categories: data })
      }
    } catch (err) {
      console.log('err :>> ', err)
    }
  }
}

const mutations = {
  [CATEGORIES_RECEIVED] (state, payload) {
    state.searchTerm = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
