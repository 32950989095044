import { DraftBomItem, DraftHotpointLink, Translation } from '@/interfaces/admin'

interface Constructor {
    refKey: string,
    draftPageId: number,
    indentation: number,
    parent: string|null,
    locale?: string,
    supplierId?: number|null
}
export default class DraftPart implements DraftBomItem {
    children: string[]
    created: Date|null
    discountedPrice: number|null
    draftPageId: number
    expanded: boolean
    hashKey: string|null
    hotpointLinks: DraftHotpointLink[]
    id: number|null
    indentation: number
    instanceId: number|null
    item?: string
    orderableForPagePart: boolean
    orderableForPart: boolean
    pagePartTagValues: any[]
    parent?: null | string
    partNumber: string
    partNumberCodeId?: number
    partNumberVisibleForPagePart: boolean
    partNumberVisibleForPart: boolean
    partTagValues: any[]
    partTranslations: Translation[]
    quantity: string
    refKey: string
    retailPrice: number|null
    supplierId: number
    unitOfMeasure: string
    updated: Date|null
    visible: boolean
    wholesalePrice: number|null
    constructor({ draftPageId, indentation, refKey, parent, locale, supplierId = null }: Constructor) {
        this.created = null,
        this.children = [],
        this.discountedPrice = null,
        this.draftPageId = draftPageId,
        this.expanded = false,
        this.hashKey = null,
        this.hotpointLinks = [],
        this.id = null,
        this.indentation = indentation,
        this.instanceId = null,
        this.item = "",
        this.orderableForPagePart = true,
        this.orderableForPart = true,
        this.pagePartTagValues = [],
        this.parent = parent,
        this.partNumber = '',
        this.partNumberVisibleForPagePart = true,
        this.partNumberVisibleForPart = true,
        this.partTagValues = [],
        this.partTranslations = !!locale ? [{ desc: '', lang: locale, name: '' }] : [],
        this.quantity = '',
        this.refKey = refKey,
        this.retailPrice = null,
        this.supplierId = supplierId ?? 0,
        this.unitOfMeasure = '',
        this.updated = null,
        this.visible = true,
        this.wholesalePrice = null
    }
}
