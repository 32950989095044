<template>
  <div
    class="field has-addons is-marginless"
    v-on-clickaway="closeActive"
  >
    <div
      v-if="hasPrefix"
      class="control"
    >
      <div
        :class="{'is-active': isPrefixActive}"
        class="dropdown"
      >
        <div class="dropdown-trigger">
          <button
            class="button"
            @click="isPrefixActive = !isPrefixActive"
          >
            <span>{{ prefixIndicator }}</span>
            <span class="icon is-small"><i class="fa fa-angle-down" /></span>
          </button>
        </div>
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <div
              v-for="(value, index) in filter.prefixes"
              :key="index"
              class="dropdown-item"
            >
              <div
                data-testid="category-dropdown-item"
                class="field range-prefix-dropdown-item"
                @click="changePrefix(value)"
              >
                <input
                  type="checkbox"
                  :checked="value === prefix"
                  class="is-checkradio"
                >
                <label>{{ value }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="control">
      <input
        :id="`${filter.field}`"
        autocomplete="off"
        :value="selectedInput"
        :placeholder="filter.name"
        class="input"
        type="number"
        @input="debounceInput"
      >
    </div>
    <div
      v-if="hasSuffix"
      class="control"
    >
      <div
        :class="{'is-active': isSuffixActive}"
        class="dropdown"
      >
        <div class="dropdown-trigger">
          <button
            class="button"
            @click="isSuffixActive = !isSuffixActive"
          >
            <span>{{ suffixIndicator }}</span>
            <span class="icon is-small">
              <i class="fa fa-angle-down" />
            </span>
          </button>
        </div>
        <div class="dropdown-menu">
          <div class="dropdown-content">
            <div
              v-for="(value, index) in filter.suffixes"
              :key="index"
              class="dropdown-item"
            >
              <div
                class="field range-suffix-dropdown-item"
                @click="changeSuffix(value)"
              >
                <input
                  type="checkbox"
                  :checked="value === suffix"
                  class="is-checkradio"
                >
                <label>{{ value }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="control">
      <a
        data-testid="remove-filter-button"
        class="button remove-filter-button"
        @click="removeFilter"
      >
        <span class="icon is-small">
          <i class="fa fa-times" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue3-click-away'
import debounce from 'lodash.debounce'

export default {
  name: 'RangeFilter',
  props: {
    filter: {
      type: Object,
      required: true
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },
  directives: {
    onClickaway
  },
  data () {
    return {
      selected: '',
      prefix: undefined,
      suffix: undefined,
      isPrefixActive: false,
      isSuffixActive: false
    }
  },
  watch: {
    selectedValues () {
      this.updateValues()
    }
  },
  computed: {
    selectedInput: {
      get () {
        return this.selected
      },
      set (value) {
        if (this.selected !== value) {
          this.selected = value
          this.$emit('change', [{
            value,
            prefix: this.prefix,
            suffix: this.suffix
          }])
        }
      }
    },
    hasPrefix () {
      return this.filter && this.filter.prefixes && this.filter.prefixes.length > 0
    },
    hasSuffix () {
      return this.filter && this.filter.suffixes && this.filter.suffixes.length > 0
    },
    prefixIndicator () {
      if (this.prefix) {
        return this.prefix
      }
      return this.$i18n.t('any')
    },
    suffixIndicator () {
      if (this.suffix) {
        return this.suffix
      }
      return this.$i18n.t('any')
    }
  },
  methods: {
    /* eslint-disable no-invalid-this */
    // TODO - fix this if you happen to be working in this file.  The value of 'this' is potentially
    // ambuguous
    debounceInput: debounce(function (e) {
      this.selectedInput = e.target.value
    }, 500),
    /* eslint-enable no-invalid-this */
    changePrefix (value) {
      if (this.prefix !== value) {
        this.prefix = value
        this.change()
      }
    },
    changeSuffix (value) {
      if (this.suffix !== value) {
        this.suffix = value
        this.change()
      }
    },
    change () {
      this.$emit('change', [{
        value: this.selected,
        prefix: this.prefix,
        suffix: this.suffix
      }])
    },
    removeFilter () {
      this.$emit('remove')
    },
    updateValues () {
      const item = this.selectedValues.length ? this.selectedValues[0] : undefined
      if (item) {
        this.selected = item.value.length ? item.value : ''

        const element = document.getElementById(this.filter.field)
        element.value = item.value

        if (this.hasPrefix) {
          this.prefix = item.prefix || this.filter.prefixes[0]
        }
        if (this.hasSuffix) {
          this.suffix = item.suffix || this.filter.suffixes[0]
        }
      } else {
        this.selected = ''
        this.prefix = this.hasPrefix ? this.filter.prefixes[0] : undefined
        this.suffix = this.hasSuffix ? this.filter.suffixes[0] : undefined
      }
    },
    closeActive () {
      this.isPrefixActive = false
      this.isSuffixActive = false
    }
  },
  mounted () {
    this.updateValues()
  }
}
</script>

<style scoped>
input {
  max-width: 10rem;
}
.dropdown-content {
  width: max-content;
  max-height: 50vh;
  overflow: auto;
}
.button {
  padding-left: .5rem;
  padding-right: .5rem;
}
.button .icon:last-child:not(:first-child) {
  margin-left: 0;
}
</style>
