import actions from '../actions'
import mutations from '../mutations'
import http from '@/http'
import { UserGroupTypes } from '@/interfaces/admin'

export default {
  namespaced: true,
  state: {
    data: null,
    loading: false,
    error: null
  },
  getters: {
    idToNameMap(state) {
      return state.data?.reduce((map, dto) => {
        map.set(dto.id, dto.name)
        return map
      }, new Map()) ?? new Map()
    },
    widgetUserGroups(state) {
      return state.data?.filter(ug => {
        return ug.userGroupType === UserGroupTypes.widget
      })
    }
  },
  mutations,
  actions: {
    ...actions,
    async getProperty (context) {
      const loadUserGroups = async function * (rest, queryParam = '') {
        for (let page = 0; true; page++) {
          const limit = 100
          const offset = limit * page
          const params = { limit, offset }

          // Error handling around for await block.
          const { data } = await rest.get(`/user-groups${queryParam}`, { params })
          yield * data

          if (data.length < limit) {
            break
          }
        }
      }

      try {
        this.isLoading = true
        const items = []
        for await (const value of loadUserGroups(http)) {
          items.push(value)
        }
        if (context.rootGetters['user/hasAccessToWidgetUserGroups']) {
          const queryParam = `?userGroupType=${UserGroupTypes.widget}`
          for await (const value of loadUserGroups(http, queryParam)) {
            items.push(value);
          }
        }
        const result = items.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        context.commit('DATA_SUCCESS', result)
      } catch (e) {
        console.log('e', e)
        context.commit('DATA_ERROR', e)
      }
    }
  }
}
