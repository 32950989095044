<template>
  <div v-d-click-outside="closePopover">
    <popover :isBottom="isBottom"
             :popoverPosition="popoverPosition"
             arrowPosition="right">
      <virtual-list :style="listStyle"
                    :data-component="PopoverTagItem"
                    :data-sources="tags"
                    data-key="id"/>
    </popover>
  </div>
</template>

<script setup>
import VirtualList from 'vue3-virtual-scroll-list'
import Popover from "@/components/common/popover/BOMPopover"
import PopoverTagItem from "./PopoverTagItem"
import { usePopoverKeyUp } from "../../../../../../../common/popover/usePopoverKeyUp"
import { CLOSE_EVENT, listStyle } from "./const"

const emits = defineEmits([CLOSE_EVENT])
const { closePopover } = usePopoverKeyUp(emits, CLOSE_EVENT)

const props = defineProps({
  isBottom: Boolean,
  bomItemId: String,
  popoverPosition: Number,
  tags: {
    type: Array,
    default: () => []
  }
})
</script>
