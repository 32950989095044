import { notify } from '@kyvg/vue3-notification'

type Message = {
  title?: string,
  text?: string,
  duration?: number
}

type TypedMessage = Message & {
  type?: string
}

/*
 * Current interfaces for @kyvg/vue3-notification library
 * with pre-defined durations
 */

export function postError (message: Message) {
  notify({
    title: message.title,
    text: message.text,
    type: 'error',
    duration: message.duration ?? 10000
  })
}

export function postInfo (message: Message) {
  notify({
    title: message.title,
    text: message.text,
    type: 'info',
    duration: message.duration ?? 5000
  })
}

export function postNotification (message: TypedMessage){
  switch (message?.type ?? '') {
    case 'error':
      postError(message)
      break
    case 'success':
      postMessage(message)
      break
    case 'warning':
      postWarning(message)
      break
    default:
      postInfo(message)
  }
}

export function postSuccess (message: Message){
  notify({
    title: message.title,
    text: message.text,
    type: 'success',
    duration: message.duration ?? 5000
  })
}

export function postWarning (message: Message){
  notify({
    title: message.title,
    text: message.text,
    type: 'warn',
    duration: message.duration ?? 5000
  })
}
