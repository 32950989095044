export default {
    methods: {
      emitToComponent (component, eventName, ...rest) {
        let parent = this.$parent || this.$root
        let name = parent.$options.name
  
        while (parent && (!name || name !== component)) {
          parent = parent.$parent
          if (parent) {
            name = parent.$options.name
          }
        }
        if (parent) {
          parent.$emit.apply(parent, [eventName].concat(rest))
        }
      }
    }
  }
  