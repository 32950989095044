<template>
  <div class="color-input">
    <b-dropdown append-to-body
                aria-role="menu"
                ref="dropdown"
                trap-focus
                :position=position
                @active-change="setIsOpen">
      <template #trigger>
        <slot name="button">
          <b-button :size="size"
                    :style="{ 'background-color': value}"
                    :disabled="disabled" />
        </slot>
      </template>

      <b-dropdown-item custom
                       paddingless
                       :focusable="false">
        <slot name="dropdown-insert" />
        <chrome-picker :value="value"
                       @input="handleInput" />
        <div class="px-2 py-2">
          <b-button expanded
                    type="is-primary"
                    ref="submitBtn"
                    @click.exact="handleSubmit">
            {{ $t('ok') }}
          </b-button>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'

export default {
  name: 'ColorInput',
  props: {
    disabled: {
      type: Boolean
    },
    position: {
      type: String,
      default: 'is-bottom-left',
      validator: val => ['is-top-right', 'is-top-left', 'is-bottom-left'].includes(val)
    },
    size: {
      type: String,
      default: 'is-default',
      required: false,
      validator: val => ['is-small', 'is-default'].includes(val)
    },
    value: {
      required: true,
      validate: (t) => {
        return (
          ['number', 'string', 'undefined'].includes(typeof t) || t === null
        )
      }
    }
  },
  components: {
    'chrome-picker': Chrome
  },
  data: () => ({
    hex: '',
    isOpen: false
  }),
  methods: {
    handleSubmit () {
      this.$emit('update:value', this.hex)
      this.$emit('input', this.hex)
      this.$emit('change', this.hex)
      this.$refs.dropdown.toggle()
    },
    handleInput ({ hex }) {
      this.hex = hex
    },
    keyHandler({ key }) {
      if (key === 'Enter') {
        this.$refs.submitBtn.$el.click()
      }
    },
    // Using window events is necessary here because
    // Vue @keypress & similar events don't work w/chrome-picker component
    // Removing event listener is necessary for clean-up and
    // to avoid conflicts with other renderings of this component
    setIsOpen() {
      if (!this.isOpen) {
        this.isOpen = true
        window.addEventListener('keydown', this.keyHandler)
      } else {
        this.isOpen = false
        window.removeEventListener('keydown', this.keyHandler)
      }
      this.$emit('toggle', this.isOpen)
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  min-width: 2.5rem;
  &.is-small {
    min-width: 30px;
  }
}
</style>
