<template>
  <div class="notifications-container">
    <section v-if="notifications.length"
             class="notification-list-container">
      <notification-result v-for="notification in notifications"
                           :notification="notification"
                           :key="notification.id"
                           @open="open"/>
    </section>
    <notification-pagination-wrapper v-if="notifications.length" />
    <section class="no-data" v-else>
      <h1 class="subtitle has-text-centered">
        {{ $t('assetsNotFound', {type: $tc('newsItem', 2)}) }}
      </h1>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NotificationPaginationWrapper from './NotificationsPaginationWrapper';
import NotificationResult from './NotificationsResult';

export default {
  name: 'Notifications',
  components: {
    NotificationResult,
    NotificationPaginationWrapper
  },
  computed: {
    ...mapState({
      notifications: ({ notifications }) => notifications.items,
      offset: ({ content }) => content.suggestions.offset
    })
  },
  methods: {
    ...mapActions({
      navigateToNotification: 'notifications/navigateToNotification',
      getNotifications: 'notifications/getNotifications'
    }),
    open(notification) {
      this.navigateToNotification(notification.id)
    }
  },
  async mounted() {
    document.title = this.$tc('newsItem', 2)
    await this.getNotifications()
  }
};
</script>

<style scoped>
.notifications-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.notification-list-container {
  flex: 1;
  overflow: auto;
  border-top: lightgray 1px solid;
  border-bottom: lightgray 1px solid;
}
.no-data {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
</style>
