<template>
  <div id="app">
    <!-- Different sets of notifications -->
    <notifications>
      <template #body="props">
        <!--
        undefined style classes in this component, like 'vue-notification',
         should match Notification-style classes in order to leverage
         pre-defined classes.
        -->
        <div class="vue-notification"
             style="padding-right: 1.25rem"
             :class="props.item.type"
             @click="props.close">
          <div v-if="props.item.title"
               class="is-flex is-justify-content-space-between is-align-items-start mb-1">
            <div class="notification-title"
                 v-html="props.item.title" />
            <button class="delete"
                    aria-label="close"
                    @click="props.close" />
          </div>
          <div class="is-flex is-justify-content-space-between is-align-items-start">
            <div class="notification-content"
                 v-html="props.item.text" />
            <!-- Redundant close button for text-only notification -->
            <button v-if="!props.item.title"
                    class="delete"
                    aria-label="close"
                    @click="props.close" />
          </div>
        </div>
      </template>
    </notifications>

    <!--
      Notification group, cookie, has a hardcoded body content and structure.
      Hence, every $notify call will create a new instance regardless of the
      provided options.

      Visibility is controlled in the Vuex module, user. A user should only
      have to accept cookies once per session. Once accepted, it should call
      an endpoint to rewrite the cookie with an accepted cookie value.
    -->
    <notifications group="cookie"
                   position="bottom center"
                   width="50%">
      <template #body="props">
        <p class="gdpr is-unselectable legal">
          <i18n-t keypath="gdprMessage" tag="span">
            <template #policy>
              <a class="has-text-link"
                 href="https://documoto.com/privacy/"
                 target="_blank">
                {{ $t('privacyPolicy') }}
              </a>
            </template>
          </i18n-t>


        </p>
        <a class="gdpr-close"
           @click="handleCookieClose(props)">
          <i class="fas fa-lg fa-window-close"/>
        </a>
      </template>
    </notifications>
    <required-reading-modal v-if="requiredReadingOpen"
                            @cancel="requiredReadingOpen = false"/>
    <router-view id="router-view"/>
  </div>
</template>

<script lang="ts">
import "vue-draggable-resizable/style.css";
import Vue from 'vue'
import { mapState } from 'vuex'
import RequiredReadingModal from '@/components/common/RequiredReadingModal.vue'
import { agreeToCookieUse } from "@/checks";

const routeState = mapState('route', ['path'])

export default Vue.defineComponent({
  name: 'App',
  components: {
    RequiredReadingModal
  },
  data () {
    return {
      requiredReadingOpen: false
    }
  },
  watch: {
    requiredReadingCount (val: number) {
      if (val && this.$route.path.indexOf('notifications') < 0) {
        this.requiredReadingOpen = true
      }
    }
  },
  computed: {
    ...routeState,
    ...mapState('notifications', ['requiredReadingCount'])
  },
  methods: {
    dropHandler (e: Event): void {
      if ((e.target as HTMLElement).dataset.dropAllowed) return
      e.preventDefault()
    },

    handleCookieClose (notificationRef: any) {
      notificationRef.close()
      agreeToCookieUse()
    },

    onGlobalKeydown (e: KeyboardEvent): void {
      if (e.defaultPrevented) return

      if (e.key === 'Esc' || e.key === 'Escape') {
        this.$root?.$emit('escPressed', e)
      }
    },
    onResize (e: Event): void {
      this.$root?.$emit('windowResize', e)
    }
  },
  created () {
    window.addEventListener('resize', this.onResize)
    window.addEventListener('keydown', this.onGlobalKeydown)
    window.addEventListener('dragover', this.dropHandler)
    window.addEventListener('drop', this.dropHandler)
    this.$store.dispatch('app/checkBrowser')
  },
  unmounted () {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('keydown', this.onGlobalKeydown)
    window.removeEventListener('dragover', this.dropHandler)
    window.removeEventListener('drop', this.dropHandler)
  }
})
</script>

<style scoped>
.gdpr {
  background-color: #dbdbdb;
  padding: 1rem 2rem 1rem 1rem;
}

.gdpr-close {
  position: absolute;
  right: .5rem;
  top: .5rem;
}
</style>

<style>
/* Override vue-draggable-resizable .vdr class, which
 * a dashed black line border by default
*/
.vdr {
  border: none !important;
}
* {
  /* Firefox bug fix styles */
  min-height: 0;
  min-width: 0;
}

.fa-stack-right {
  margin-top: 1rem;
  margin-left: 1.15rem;
}

.fa-counter-spin {
  -webkit-animation: spin-counter 2s infinite linear;
  -moz-animation: spin-counter 2s infinite linear;
  -o-animation: spin-counter 2s infinite linear;
  animation: spin-counter 2s infinite linear;
}

.modal {
  text-align: left;
  min-height: 100vh;
}

.modal-card {
  margin: 0 !important;
  overflow: auto;
}

.modal-card-head {
  padding: .5rem;
}

.modal-card-body {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex: 1;
}

.modal-card-foot {
  padding: .5rem;
  min-height: 3rem;
}

@-moz-keyframes spin-counter {
  0% {
    -moz-transform: rotate(359deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}

@-webkit-keyframes spin-counter {
  0% {
    -webkit-transform: rotate(359deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-o-keyframes spin-counter {
  0% {
    -o-transform: rotate(359deg);
  }
  100% {
    -o-transform: rotate(0deg);
  }
}

@-ms-keyframes spin-counter {
  0% {
    -ms-transform: rotate(359deg);
  }
  100% {
    -ms-transform: rotate(0deg);
  }
}

@keyframes spin-counter {
  0% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
