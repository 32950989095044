/*
 * This module contains imperative and managment logic
 * for the ephemeral, front-end state of the draft BOM list
 * in Admin Page Composer.
 * E.g., performing searches, opening/closing modals,
 * tracking which draft bom cell is in edit mode, etc.
 *
 * In contrast, the draftPage and draftBomItems modules
 * manage the permanent, back-end state
 * of data associated with the draft BOM list
 * E.g., changes to part translations, part numbers,
 * parent-child relationships, etc.
 */
import { ActionContext, GetterTree, MutationTree } from 'vuex'
import {
  DraftBomItem,
  Translation,
  Supplier,
  IndexType,
  EditTypes,
  DraftBomColumns
} from '@/interfaces/admin'
import { StringToNumberMap } from '@/interfaces/global'
import selectedRows from './selectedRows'

// Interfaces and Types
interface DraftBomState {
  bomNodeToDelete: Array<string>,
  cellInEditMode: IndexType,
  currentSearchResultsIndex: number,
  isDraftBomUpdate: boolean,
  isUpdating: boolean,
  partInDetailEditorIndex: number,
  showDeleteModal: boolean,
  searchResults: number[],
  searchSuggestions: any[],
  columnTitles: string[]
}

type Context = ActionContext<DraftBomState, any>

export enum MutationTypes {
  REMOVE_HOTPOINT_LINKS = 'REMOVE_HOTPOINT_LINKS',
  SET_SHOW_DELETE_MODAL = 'SET_SHOW_DELETE_MODAL',
  SET_BOM_NODE_TO_DELETE = 'SET_BOM_NODE_TO_DELETE',
  SET_CELL_IN_EDIT_MODE = 'SET_CELL_IN_EDIT_MODE',
  SET_IS_DRAFT_BOM_UPDATE = 'SET_IS_DRAFT_BOM_UPDATE',
  SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS',
  RESET_SEARCH = 'RESET_SEARCH',
  SET_CURRENT_SEARCH_RESULT = 'SET_CURRENT_SEARCH_RESULT',
  SET_IS_UPDATING = 'SET_IS_UPDATING',
  ADD_TO_UNSAVED_UPDATES = 'ADD_TO_UNSAVED_UPDATES',
  SET_PART_IN_DETAIL_EDITOR_INDEX = 'SET_PART_IN_DETAIL_EDITOR_INDEX',
  SET_SEARCH_SUGGESTIONS = 'SET_SEARCH_SUGGESTIONS',
  SET_SELECTED_DROPDOWN_ITEM = 'SET_SELECTED_DROPDOWN_ITEM',
  SET_MAPPED_COLUMN_TITLES = 'SET_MAPPED_COLUMN_TITLES',
  UPDATE_MAPPED_COLUMN_TITLES = 'UPDATE_MAPPED_COLUMN_TITLES'
}

export enum DraftBomActions {
  setBomNodeToDelete = 'setBomNodeToDelete',
  setShowDeleteModal = 'setShowDeleteModal',
  setCellInEditMode = 'setCellInEditMode',
  tabOverEditMode = 'tabOverEditMode',
  setIsDraftBomUpdate = 'setIsDraftBomUpdate',
  syncVisiblePartsList = 'syncVisiblePartsList',
  resetSearch = 'resetSearch',
  clearSelectedRows = 'clearSelectedRows',
  getItemNumberMatches = 'getItemNumberMatches',
  getSupplierMatches = 'getSupplierMatches',
  setSearchSuggestions = 'setSearchSuggestions'
}

export const RootMutationTypes: any = {}
Object.values(MutationTypes).forEach(val => {
  RootMutationTypes[val] = `draftBom/${val}`
})

// draftBom Module
const state: DraftBomState = {
  bomNodeToDelete: [],
  cellInEditMode: {} as IndexType,
  currentSearchResultsIndex: 0,
  isDraftBomUpdate: false,
  isUpdating: false,
  partInDetailEditorIndex: -1,
  showDeleteModal: false,
  searchResults: [],
  searchSuggestions: [],
  columnTitles: Object.keys(DraftBomColumns)
}

const getters: GetterTree<DraftBomState, any> = {
  // Support O(1) look ups from visiblePartsList w/item refKey
  refKeyToVisibleListIdx (state, getters, rootState): StringToNumberMap {
    const map: StringToNumberMap = {}
    rootState.draftPage.draftBomItems
      .filter((item: DraftBomItem) => item.visible)
      .forEach((part: DraftBomItem, index: number) => {
        map[part?.refKey ?? ''] = index
      })
    return map
  },
  visibleRowsCount (state, getters, rootState): number {
    return rootState.draftPage.draftBomItems
      .filter((item: DraftBomItem) => item.visible).length
  },
  hasDetailEditorOpen (state, getters, rootState): boolean {
    return state.partInDetailEditorIndex >= 0 || rootState.draftPage.draftBomItemUpdating.isImporting
    || rootState.draftPage.draftBomItemUpdating.isExporting
  },
  isInCellEditMode(state) {
    return state.cellInEditMode.hasOwnProperty('index')
  },
  getDraftBomItems (state, getters, rootState) {
    return rootState.draftPage.draftBomItems
  }
}

const actions = {
  setBomNodeToDelete (context: Context, key: string): void {
    const keys = context.rootState.draftPage.draftBomItems.map((item: DraftBomItem, index: number) => {
      if (selectedRows.state.indices.includes(index)) return item.refKey
    }).filter((key:string) => !!key)
    if(!keys.includes(key)) keys.push(key)
    context.commit(MutationTypes.SET_BOM_NODE_TO_DELETE, keys)
  },
  setShowDeleteModal (context: Context, payload: boolean): void {
    context.commit(MutationTypes.SET_SHOW_DELETE_MODAL, payload)
  },
  setCellInEditMode (context: Context, payload: IndexType): void {
    context.commit(MutationTypes.SET_CELL_IN_EDIT_MODE, payload)
  },
  tabOverEditMode ({ dispatch, getters }: Context, { index, type }: IndexType): void {
    let lastIndex, isLastIndex
    switch (type) {
      case EditTypes.item:
        type = EditTypes.partNumber
        break
      case EditTypes.partNumber:
        type = EditTypes.supplierId
        break
      case EditTypes.supplierId:
        type = EditTypes.partName
        break
      case EditTypes.partName:
        type = EditTypes.quantity
        break
      case EditTypes.quantity:
        type = EditTypes.unitOfMeasure
        break
      case EditTypes.unitOfMeasure:
        // Tab to the item cell of the next visible row in BomList
        // If on the last row, tab to the item cell of the first row of BomList
        lastIndex = getters.visibleRowsCount - 1
        isLastIndex = index === lastIndex
        index = isLastIndex ? 0 : index + 1
        type = EditTypes.item
        break
      default:
        break
    }
    dispatch(DraftBomActions.setCellInEditMode, { index, type })
  },
  tabBackward ({ dispatch, getters }: Context, { index, type }: IndexType): void {
    let lastIndex, isFirstIndex
    switch (type) {
      case EditTypes.item:
        // Tab to the item cell of the previous visible row in BomList
        // If on the first row, tab to the item cell of the last row of BomList
        lastIndex = getters.visibleRowsCount - 1
        isFirstIndex = index === 0
        index = isFirstIndex ? lastIndex : index - 1
        type = EditTypes.unitOfMeasure
        break
      case EditTypes.partNumber:
        type = EditTypes.item
        break
      case EditTypes.supplierId:
        type = EditTypes.partNumber
        break
      case EditTypes.partName:
        type = EditTypes.supplierId
        break
      case EditTypes.quantity:
        type = EditTypes.partName
        break
      case EditTypes.unitOfMeasure:
        type = EditTypes.quantity
        break
      default:
        break
    }
    dispatch('setCellInEditMode', { index, type })
  },
  async getSupplierMatches ({ rootState }: Context, query: string): Promise<void> {
    const matches = await rootState.suppliers.suppliers
      .filter((supplier: Supplier) => supplier.name.toLowerCase().includes(query))
      .map((supplier: Supplier) => supplier.supplierId)
    return matches
  },
  async getItemNumberMatches({ rootState }: Context, query: string): Promise<Number> {
    return rootState.draftPage.draftBomItems
      .filter((bom: DraftBomItem) => bom.item === query).length
  },
  async search ({ dispatch, commit, rootState }: Context, query: string) {
    // Zero out previously saved search results and current search result index
    dispatch(DraftBomActions.resetSearch)
    dispatch(DraftBomActions.clearSelectedRows)

    // Do not search if provided empty string
    if (query === '') {
      return
    }

    // Update searchResults to parts that contain -- case insensitive --
    // the search query in name, partNumber, or supplierName
    const lowercaseQuery = query.toLowerCase()
    const supplierMatches = await dispatch(DraftBomActions.getSupplierMatches, lowercaseQuery)
    const { locale } = rootState.user
    const results: number[] = []

    rootState.draftPage.draftBomItems.forEach((part: DraftBomItem, i: number) => {
      const { partNumber, partTranslations, supplierId } = part
      const translation: Translation|undefined = partTranslations.find(it => it.lang === locale)
      const name = translation ? translation.name : ''
      const nameMatch = name.toLowerCase().includes(lowercaseQuery)
      const partNumberMatch = partNumber?.toLowerCase().includes(lowercaseQuery) ?? false
      const supplierMatch = supplierMatches.includes(supplierId)
      const hasPartCode = Object.prototype.hasOwnProperty.call(part, 'partNumberPartCode')
      const partCodeMatch = hasPartCode && part.partNumberPartCode?.toLowerCase().includes(lowercaseQuery)
      if (nameMatch || partNumberMatch || supplierMatch || partCodeMatch) {
        results.push(i)
      }
    })

    commit(MutationTypes.SET_SEARCH_RESULTS, results)
  },
  resetSearch (context: Context): void {
    context.commit(MutationTypes.RESET_SEARCH)
  },
  incrementCurrentSearchResult ({ commit, state }: Context): void {
    const { currentSearchResultsIndex, searchResults } = state
    const lastIdx = searchResults.length - 1
    const isLastResult = currentSearchResultsIndex === lastIdx
    const incrementedIdx = currentSearchResultsIndex + 1

    const value = isLastResult ? 0 : incrementedIdx
    commit(MutationTypes.SET_CURRENT_SEARCH_RESULT, value)
  },
  decrementCurrentSearchResult ({ commit, state }: Context): void {
    const { currentSearchResultsIndex, searchResults } = state
    const lastIdx = searchResults.length - 1
    const isFirstResult = currentSearchResultsIndex === 0
    const decrementedIdx = currentSearchResultsIndex - 1

    const value = isFirstResult ? lastIdx : decrementedIdx
    commit(MutationTypes.SET_CURRENT_SEARCH_RESULT, value)
  },
  setPartInDetailEditor ({ commit, dispatch }: Context, index: number): void {
    // Ensure no BOM cells can be edited
    dispatch('setCellInEditMode', {})
    commit(MutationTypes.SET_PART_IN_DETAIL_EDITOR_INDEX, index)
    if (index >= 0) commit('SET_SELECTED_ROW', index)
  },
  closeDraftBomDetailEditor ({ dispatch }: Context): void {
    dispatch('setPartInDetailEditor', -1)
  },
  setSearchSuggestions ({ commit }: Context, payload: any[]): void {
    commit(MutationTypes.SET_SEARCH_SUGGESTIONS, payload)
  },
  setIsUpdating ({ commit }: Context, payload: boolean): void {
    commit(MutationTypes.SET_IS_UPDATING, payload)
  }
}

const mutations: MutationTree <DraftBomState> = {
  [MutationTypes.SET_BOM_NODE_TO_DELETE] (state, refKeys: Array<string>) {
    state.bomNodeToDelete = refKeys
  },
  [MutationTypes.SET_SHOW_DELETE_MODAL] (state, payload: boolean) {
    state.showDeleteModal = payload
  },
  [MutationTypes.SET_CELL_IN_EDIT_MODE] (state, payload: IndexType) {
    state.cellInEditMode = payload
  },
  [MutationTypes.SET_IS_DRAFT_BOM_UPDATE] (state, payload: boolean) {
    state.isDraftBomUpdate = payload
  },
  [MutationTypes.SET_SEARCH_RESULTS] (state, indexList: number[]) {
    state.searchResults = indexList
  },
  [MutationTypes.RESET_SEARCH] (state) {
    state.searchResults = []
    state.currentSearchResultsIndex = 0
  },
  [MutationTypes.SET_CURRENT_SEARCH_RESULT] (state, index: number) {
    state.currentSearchResultsIndex = index
  },
  [MutationTypes.SET_IS_UPDATING] (state, value: boolean) {
    state.isUpdating = value
  },
  [MutationTypes.SET_PART_IN_DETAIL_EDITOR_INDEX] (state, index: number) {
    state.partInDetailEditorIndex = index
  },
  [MutationTypes.SET_SEARCH_SUGGESTIONS] (state, payload: any[]) {
    state.searchSuggestions = payload
  }
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
  modules: {
    selectedRows
  }
}
