<template>
  <aside>
    <div class="filter-controls">
      <filters-add :filters="filters" :selected-filters="selectedFilters" @selectFilters="setSelectedFilters" />
      <filters-clear @clear="clear" />
      <div class="spacer" />
      <filters-sort :sort="sort" :direction="direction" @setSort="setSort" />
      <view-mode class="is-hidden-mobile" :view-mode-key="viewModeKey" @setMode="setMode" />
    </div>
    <div class="filter-times">
      <filter-item v-for="filter in selectedFilters"
                   :key="filter.field"
                   :filter="filter"
                   :selected-options="selectedOptions"
                   @remove="removeSelectedFilter"
                   @updateOption="updateSelectedFilterOptions" />
    </div>
  </aside>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import debounce from '@/plugins/debounce';
import FiltersAdd from '@/components/common/filters/FiltersAdd';
import FilterItem from '@/components/common/filters/FilterItem';
import FiltersClear from '@/components/common/filters/FiltersClear';
import FiltersSort from '@/components/common/filters/FiltersSort';
import ViewMode from '@/components/common/filters/ViewMode';

export default {
  name: 'BrowseFilterBar',
  components: {
    FiltersClear,
    FilterItem,
    FiltersAdd,
    FiltersSort,
    ViewMode
  },
  mixins: [debounce],
  debounceMethods: {
    updateSelectedFilterOptions: 500
  },
  computed: {
    ...mapState({
      taxon: ({ browse }) => browse.taxon,
      filters: ({ browse }) => browse.search.filters.items,
      selectedOptions: ({ browse }) => browse.search.filters.selected,
      sort: ({ browse }) => browse.search.sort,
      direction: ({ browse }) => browse.search.direction,
      viewModeKey: ({ search }) => search.viewModeKey
    }),
    ...mapGetters({
      selectedFilters: 'browse/selectedFilters',
      isWidget: 'widgets/isWidget'
    })
  },
  methods: {
    ...mapActions({
      setSelectedFilters: 'browse/setSelectedFilters',
      removeSelectedFilter: 'browse/removeSelectedFilter',
      updateSelectedFilterOptions: 'browse/updateSelectedFilterOptions',
      clearSelectedFilters: 'browse/clearSelectedFilters',
      setSort: 'browse/setSort',
      resetOffset: 'browse/resetOffset',
      search: 'browse/search'
    }),
    async setMode(key) {
      this.$store.dispatch('search/setMode', key)
      if (this.isWidget) {
        this.resetOffset()
        const { q = '', 
          exact: exactMatch = false } = this.$route.query
        await this.search({ q, exactMatch })
      }
    },
    clear() {
      this.clearSelectedFilters()
    }
  }
};
</script>

<style scoped>
.filter-controls {
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  padding: .15rem .35rem;
  justify-content: space-between;
}
.spacer {
  flex: 1 1 0;
}
.filter-controls > * {
  padding: 0 0.15rem;
}
.filter-times {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 .25rem;
}
.filter-times > div {
  margin: .15rem .25rem .25rem .15rem !important;
}
</style>
