import PdfViewer from '@/components/library/content/asset/viewer/pdfViewer/PdfViewer.vue'
import DownloadViewer from '@/components/library/content/asset/viewer/DownloadViewer.vue'
import ExternalViewer from '@/components/library/content/asset/viewer/ExternalViewer'
import IllustrationViewer from "@/components/library/content/asset/viewer/illustrationViewer/IllustrationViewer"
import ImageViewer from '@/components/library/content/asset/viewer/ImageViewer'
import MicrositeViewer from '@/components/library/content/asset/viewer/MicrositeViewer.vue'
import VideoViewer from '@/components/library/content/asset/viewer/VideoViewer'
import NoAssetViewer from '@/components/library/content/asset/viewer/NoAssetViewer'
import { useStore } from "vuex"
import { computed } from "vue"
import { WidgetMediaTypes } from '@/interfaces/global/widgets'

export function useStaticContent() {
  const store = useStore()
  const content = computed(() => store.state.content)

  const componentInViewer = computed(() => {
    switch (content.value.contentType) {
      case WidgetMediaTypes.document:
        return content.value.contentUrl.split('.').pop().toLowerCase() === 'pdf'
          ? PdfViewer
          : DownloadViewer
      case WidgetMediaTypes.external:
        return ExternalViewer
      case WidgetMediaTypes.illustration:
        return IllustrationViewer
      case WidgetMediaTypes.image:
        return ImageViewer
      case WidgetMediaTypes.microsite:
        return MicrositeViewer
      case WidgetMediaTypes.video:
        return VideoViewer
      default:
        return NoAssetViewer
    }
  })
  return { componentInViewer }
}