import http from '@/http'
import i18n from '@/locales'
import router from '@/router'
import { asyncFetcher } from '@/store'
import { eagerLoad } from '@/components/common/eagerLoadPagination'
import { postError } from '@/helpers/notification'

const SHARED_CART_NAMES_RECEIVED = 'SHARED_CART_NAMES_RECEIVED'
const SHARED_CARTS_RECEIVED = 'SHARED_CARTS_RECEIVED'
const SHARED_CARTS_LOADING = 'SHARED_CARTS_LOADING'
const SHARED_CARTS_LOADED = 'SHARED_CARTS_LOADED'

const state = {
  isLoaded: false,
  items: [],
  allNames: [] // All names in tenant; items are carts visible to organization only
}

const actions = {
  async fetchAll ({ commit, rootGetters }) {
    try {
      commit(SHARED_CARTS_LOADING)

      if (rootGetters['user/isSharedCartEnabled']) {
        const items = await eagerLoad('shared-carts', 'name:asc')
        commit(SHARED_CARTS_RECEIVED, { items: items })

        if (rootGetters['user/isSharedCartMaintainer']) {
          const { data } = await http.get('shared-carts/other-names')
          commit(SHARED_CART_NAMES_RECEIVED, { names: data.map(x => x.toLocaleLowerCase()) })
        }
      }
    } catch (e) {
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('failedAction', {
          content: i18n.global.tc('sharedCart', 2),
          action: i18n.global.t('actionLoad').toLocaleLowerCase()
        }),
      })
    } finally {
      commit(SHARED_CARTS_LOADED)
    }
  },
  async delete ({ rootGetters, dispatch }, { cartId }) {
    try {
      if (rootGetters['user/isSharedCartEnabled'] &&
        rootGetters['user/isSharedCartMaintainer']
      ) {
        await http.delete(`shared-cart/${cartId}`)
        dispatch('fetchAll')
      }
    } catch (e) {
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('failedAction', {
          content: i18n.global.tc('sharedCart', 2),
          action: i18n.global.t('actionDelete').toLocaleLowerCase()
        }),
      })
    }
  },
  async save ({ rootGetters, dispatch }, { name }) {
    try {
      if (rootGetters['user/isSharedCartMaintainer']) {
        await asyncFetcher(
          `shared-carts/save/${name}/async`,
          null,
          null,
          'put',
          'cart/async-poll',
          'cartId',
          1000,
          1000,
          600000,
          (message) => {
            postError({
              title: i18n.global.t('error'),
              text: message,
            })
          }
        ).then(response => {
          /**
           * Shared cart ids are returned when they are new.
           */
          if (response) {
            const routeData = router.resolve({
              name: 'LibrarySharedCartEditor',
              params: {
                id: response
              }
            })
            window.open(routeData.href, '_blank')
          }
        })
      }
    } catch (err) {
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('failedAction', {
          content: i18n.global.tc('sharedCart', 1),
          action: i18n.global.t('save').toLocaleLowerCase()
        }),
      })
    } finally {
      dispatch('fetchAll')
    }
  }
}

const mutations = {
  [SHARED_CART_NAMES_RECEIVED] (state, { names }) {
    state.allNames = names
  },
  [SHARED_CARTS_LOADED] (state) {
    state.isLoaded = true
  },
  [SHARED_CARTS_LOADING] (state) {
    state.isLoaded = false
  },
  [SHARED_CARTS_RECEIVED] (state, { items }) {
    state.items = items
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
