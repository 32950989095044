/*
 * This module contains imperative and management logic for the back-end state
 * of data associated with the draft Page in Admin Page Composer,
 * e.g., changes to draftBomItems, draftSheets, etc.
 * In contrast, the draftBom module and its submodule(s) manage the ephemeral, front-end state
 * of the draft BOM list , e.g., performing searches,
 * opening/closing modals, which draft bom cell is in edit mode, etc.
 */

import i18n from '@/locales'
import { v4 as uuidv4 } from 'uuid'
import Big from "big.js"
import { cloneDeep } from "lodash"
import { ActionContext, GetterTree, MutationTree } from 'vuex'
import {
  DraftBomItem,
  DraftHotpointLink,
  DraftPage,
  DraftTagValue,
  EditTypes,
  ImportedTags,
  IndexTypeValue,
  MappedColumn,
  PartCodeDto,
  Translation
} from '@/interfaces/admin'
import {
  StringToNumberMap,
  StringToNumListMap,
  StringToStringMap,
  Supplier
} from '@/interfaces/global'
import {
  createDraftPage,
  draftPartLookup,
  getDraftPage,
  updateDraftPage
} from '@/controllers/admin/draftPage'
import { formatBomJson, getDefaultSupplier, getDefaultUom, getAllHotpointItemInArray, hasMatchingTranslations } from './helpers'
import draftBomItemUpdating from './draftBomItemUpdating'
import publishing from './publishing'
import DraftPart from './helpers/draftPart'
import { getImportColumnsToBomItems, getTrDescToTrCodes, getTrNamesToTrCodes } from '@/const'
import { recursivelyTrim } from '@/helpers'
import { postError } from '@/helpers/notification'

// Interfaces and Types
export enum MutationTypes {
  SET_DRAFT_PAGE = 'SET_DRAFT_PAGE',
  SET_DRAFT_BOM_ITEMS = 'SET_DRAFT_BOM_ITEMS',
  UPDATE_DRAFT_BOM_ITEM = 'UPDATE_DRAFT_BOM_ITEM',
  DELETE_DRAFT_BOM_ITEMS = 'DELETE_DRAFT_BOM_ITEMS',
  ADD_PART = 'ADD_PART',
  DELETE_PART_ID = 'DELETE_PART_ID',
  BULK_DRAFT_BOM_ITEM_UPDATE = 'BULK_DRAFT_BOM_ITEM_UPDATE',
  UPDATE_DRAFT_BOM_ORDER = 'UPDATE_DRAFT_BOM_ORDER',
  SHOW_ALL_CHILDREN = 'SHOW_ALL_CHILDREN',
  HIDE_ALL_CHILDREN = 'HIDE_ALL_CHILDREN',
  UPDATE_ORPHANED_PARTS = 'UPDATE_ORPHANED_PARTS',
  BULK_PART_UPDATES = 'BULK_PART_UPDATES',
  REMOVE_DELETED_BOM_ITEMS = 'REMOVE_DELETED_BOM_ITEMS',
  SET_PART_AS_PART_CODE = 'SET_PART_AS_PART_CODE'
}

export enum DraftPageActions {
  setDraftPage = 'setDraftPage',
  setDraftBomItems = 'setDraftBomItems',
  updateDraftBomItem = 'updateDraftBomItem',
  bulkDraftBomItemUpdate = 'bulkDraftBomItemUpdate',
  deleteDraftBomItem = 'deleteDraftBomItem',
  addPart = 'addPart',
  showPartAndAllParents = 'showPartAndAllParents',
  openDropDown = 'openDropDown',
  closeDropDown = 'closeDropDown',
  loadDraftPage = 'loadDraftPage',
  saveDraftPage = 'saveDraftPage'
}

export enum GettersDraftPage {
  hasParts = 'draftPage/hasParts',
  refKeyToMasterListIdx = 'draftPage/refKeyToMasterListIdx',
  selected = 'draftPage/selected'
}

export const RootMutationTypes: any = {}
Object.values(MutationTypes).forEach(val => {
  RootMutationTypes[val] = `draftPage/${val}`
})

type Context = ActionContext<DraftPage, any>

interface IndexPartDataSupplier {
  index: number,
  partData: {
    id: number,
    partNumber: string,
    translations: Translation[],
    uom: 'string',
    partTagValues: DraftTagValue[],
    partNumberCodeId?: number,
    partNumberPartCode?: string,
    retailPrice?: number,
    discountedPrice?: number,
    wholesalePrice?: number,
    orderable: boolean,
    partNumberVisible: boolean
  },
  supplierId: number
}

interface IDAndParams {
  id: number,
  params: {
    autoGenerateDraftPage: boolean
  }
}

interface IdxParentIndent {
  idx: number,
  updatedParent: string,
  updatedIndent: number
}

interface ImportColumns {
  value: string,
  [key: string]: string
}

interface ImportPayload {
  columns: ImportColumns[]
  bomData: string[][],
  override: boolean,
  lookupParts: boolean,
  ignoreMatchingTr: boolean
}

// draftPage Module
const state: DraftPage = {
  created: null,
  draftSheets: [],
  draftBomItems: [],
  draftTagValueItems: [],
  hashKey: '',
  id: null,
  targetPageId: null,
  tenantId: null,
  translations: [],
  updated: null,
  useLibvips: null
}

const getters: GetterTree<DraftPage, any> = {
  hasParts (state): boolean {
    return state.draftBomItems.length > 0
  },
  isDirty (state, getters, rootState): boolean {
    return state.draftBomItemUpdating.isDirty || rootState.draftImage.isDirty
  },
  bomItemLinks (state) {
    let linkArray: any[] = []
    state.draftBomItems.forEach((bomItem) => {
      if(bomItem.hotpointLinks && bomItem.hotpointLinks.length > 0) {
        let valid
        bomItem.hotpointLinks.forEach((link: any) => {
          if(link.valid) valid = true
        })
        if(valid) linkArray.push(bomItem.item)
      }
    })
    return linkArray
  },
  useLibvip: (state) => state.useLibvips
}

const actions = {
  async setDraftPage ({ commit }: Context, payload: DraftPage): Promise<void> {
    commit(MutationTypes.SET_DRAFT_PAGE, payload)
    commit(MutationTypes.SET_DRAFT_BOM_ITEMS, payload.draftBomItems)
  },
  async loadDraftPage ({ dispatch, state, commit, rootGetters }: Context, { id, params }: IDAndParams): Promise<void> {
    try {
      // Get the draftPage and ensure suppliers & tags are loaded in store
      const draftPage = await getDraftPage(id, params)
      await dispatch('suppliers/loadIfMissing', '', { root: true })
      await dispatch('tagEditor/getTagNames', '', { root: true })
      // Putting this action here as this function is async, and we need to wait to get this value before
      // extending BOM List
      const hotpoints = getAllHotpointItemInArray(draftPage.draftSheets)
      // Extend draftBomItem JSON w/ visible, expanded, parent, and children
      const extendedBomListData = formatBomJson(draftPage, hotpoints, rootGetters['draftPage/draftBomItemUpdating/draftTags/tagIdToName'])
      await dispatch(DraftPageActions.setDraftPage, extendedBomListData)

      // load the latest Draft Page Version
      if (state.id) {
        await dispatch('draftPageVersion/loadDraftPageVersion', state.id, { root: true })
      }
    } catch (e) {
      throw e // Callers may have multiple calls; expect callers to handle error
    } finally {
      // Set all dirty states to false
      commit('draftPage/draftBomItemUpdating/SET_IS_DIRTY', false, { root: true })
    }
  },
  async saveDraftPage ({ state, dispatch, commit, rootGetters }: Context): Promise<void> {
    try {
      // Run spinning gears during save
      await dispatch('draftBom/setIsUpdating', true, {root: true})

      // Filter out items marked for deletion
      commit(MutationTypes.REMOVE_DELETED_BOM_ITEMS)

      const dto = state
      let draftPage
      if (state.id) {
        draftPage = await updateDraftPage(state.id, dto)
      } else {
        draftPage = await createDraftPage(dto)
      }
      const hotpoints = getAllHotpointItemInArray(draftPage.draftSheets)
      // Extends draftBomItem JSON w/ visible, expanded, parent, and children
      const extendedBomListData = formatBomJson(draftPage, hotpoints, rootGetters['draftPage/draftBomItemUpdating/draftTags/tagIdToName'])
      await dispatch('setDraftPage', extendedBomListData)
    } catch {
      postError({
        title: i18n.global.t('draftBomError'),
      })
    } finally {
      // Set all dirty states to false & end spinning gears
      commit('draftPage/draftBomItemUpdating/SET_IS_DIRTY', false, { root: true })
      await dispatch('draftBom/setIsUpdating', false, {root: true})
      await dispatch('draftImage/fetchDraftSheets', false, {root: true})
    }
  },
  async importDraftBom({ commit, rootState, state, dispatch, getters, rootGetters }: Context, { bomData, columns, override, lookupParts, ignoreMatchingTr }: ImportPayload)  {
    // Map columns: string[] & bomData: string[][] into draftBomItem structure:
    // These maps support building translations & tagValues lists
    const { columnToDraftBomFields, columnToIdxMap, trDescMap,
      trNamesMap, partTagsMap, pagePartTagsMap } = getMaps()
    let shouldLookupParts = lookupParts
    const defaultSupplier: Supplier|null = getDefaultSupplier(rootState.user?.tenantDefaultSupplier ?? null, rootState.suppliers.suppliers)
    // Determine where there's a need to look up parts
    if (lookupParts) {
      const columnValues = columns.map(it => it.value)
      const partNumberText = i18n.global.t('partNumber') as string
      const supplierText = i18n.global.tc('supplier', 1) as string
      const hasPartNumber = columnValues.includes(partNumberText)
      const hasDefaultSupplier = !!defaultSupplier
      const hasSupplier = columnValues.includes(supplierText) || hasDefaultSupplier
      shouldLookupParts = hasPartNumber && hasSupplier
    }
    let importedBomList = await getImportBomList(
      bomData,
      rootState.suppliers.suppliers,
      shouldLookupParts,
      defaultSupplier,
      override
    )

    if (override) {
       // Map HPLs of matching item numbers in oldBom to importedBomList
      let hplMap: {[key: string]: DraftHotpointLink[]} = {}
      state.draftBomItems.forEach(it => {
        if (!it.item) return
        const mapHasItem = hplMap.hasOwnProperty(it.item)
        const hasHotpointLinks = it?.hotpointLinks?.length ?? false
        if (hasHotpointLinks) {
          hplMap[it.item] = mapHasItem
            ? (hplMap[it.item] ?? []).concat(it.hotpointLinks)
            : it.hotpointLinks
        }
      })
      importedBomList = importedBomList.map(it => ({
        ...it,
        hotpointLinks: !!it.item
          ? (hplMap[it.item] ?? []).concat(it.hotpointLinks)
          : it.hotpointLinks
      }))
      commit(MutationTypes.SET_DRAFT_BOM_ITEMS, importedBomList)
    } else {
      // Determine insertion point & update indent in same manner as addPart
      const insertionIdx = getInsertionIdx(rootState.draftBom.selectedRows.indices)
      const indent = getters['draftBomItemUpdating/getIndentationAndParent'][0]
      // Update indentation for new bom items; existing bom items should remain in-place
      // adding in trimming for item field at first iterator opportunity before merging
      importedBomList.forEach((it: DraftBomItem, index: number) => {
        importedBomList[index].item = it.item?.trim() || ''
        if (!it.id) {
          it.indentation = indent
        }
      })

      // If part matches are present, then update matching part in place
      const hasMatchesToUpdate = checkForMatches(columns, defaultSupplier)

      if (hasMatchesToUpdate) {
        const draftBomItems: DraftBomItem[] = cloneDeep(state.draftBomItems)
        const itemPartToImportMap = getItemPartMap(draftBomItems, importedBomList)
        const bomWithInPlaceUpdates = getBomWithInPlaceUpdates(draftBomItems, itemPartToImportMap, importedBomList)
        commit(MutationTypes.SET_DRAFT_BOM_ITEMS, bomWithInPlaceUpdates)
        // New BOM items should get appended to the end at this point; existing remain in-place
        importedBomList = importedBomList.filter((it: DraftBomItem) => {
          const key = getItemPartNumberSupplierAsString(it)
          return !itemPartToImportMap.hasOwnProperty(key)
        })
      }

      const draftBomItems: DraftBomItem[] = cloneDeep(state.draftBomItems)
      // Slice new list together & set it as draftBomItems[]
      const frankenListMonster = draftBomItems
        .slice(0, insertionIdx)
        .concat(importedBomList)
        .concat(draftBomItems.slice(insertionIdx))
      commit(MutationTypes.SET_DRAFT_BOM_ITEMS, frankenListMonster)
    }

    commit('draftPage/draftBomItemUpdating/SET_IS_DIRTY', true, { root: true })

    // Run formatBomJson to update parent/child values
    const draftPage = cloneDeep(state)
    const hotpoints = getAllHotpointItemInArray(draftPage.draftSheets)
    const extendedBomListData = formatBomJson(draftPage, hotpoints, rootGetters['draftPage/draftBomItemUpdating/draftTags/tagIdToName'])
    await dispatch(DraftPageActions.setDraftPage, extendedBomListData)

    // Helper functions
    function getMaps() {
      const columnToDraftBomFields: StringToStringMap = {}
      const columnToIdxMap: StringToNumListMap = {}
      const trNamesMap: StringToStringMap = {}
      const trDescMap: StringToStringMap = {}
      const partTagsMap: ImportedTags = {}
      const pagePartTagsMap: ImportedTags = {}
      const importColumnsToBomItems = getImportColumnsToBomItems()
      const trNamesToTrCodes = getTrNamesToTrCodes()
      const trDescToTrCodes = getTrDescToTrCodes()
      columns.forEach((col: MappedColumn, idx: number) => {
        if (importColumnsToBomItems.hasOwnProperty(col.value)) {
          columnToDraftBomFields[col.value] = importColumnsToBomItems[col.value]
          columnToIdxMap[col.value] = [idx]
        } else if (trNamesToTrCodes.hasOwnProperty(col.value)) {
          trNamesMap[col.value] = col.value
          columnToIdxMap[col.value] = [idx]
        } else if (trDescToTrCodes.hasOwnProperty(col.value)) {
          trDescMap[col.value] = col.value
          columnToIdxMap[col.value] = [idx]
        } else if (col.hasOwnProperty('tagType') && col.tagType === 'partTag') {
          if (!!columnToIdxMap[col.value]) {
            columnToIdxMap[col.value].push(idx)
            partTagsMap[col.value].push({ ...col })
          } else {
            columnToIdxMap[col.value] = [idx]
            partTagsMap[col.value] = [{ ...col }]
          }
        } else if (col.hasOwnProperty('tagType') && col.tagType === 'pagePartTag') {
          if (!!columnToIdxMap[col.value]) {
            columnToIdxMap[col.value].push(idx)
            pagePartTagsMap[col.value].push({ ...col })
          } else {
            columnToIdxMap[col.value] = [idx]
            pagePartTagsMap[col.value] = [{ ...col }]
          }
        }
      })
      return { columnToDraftBomFields, columnToIdxMap, trDescMap, trNamesMap, partTagsMap, pagePartTagsMap }
    }

    async function getImportBomList(
      bomData: string[][],
      suppliers: Supplier[],
      shouldLookupParts: boolean,
      defaultSupplier: Supplier|null,
      override: boolean = false
    ): Promise<DraftBomItem[]> {
      let lookedUpParts: DraftBomItem[] = []
      if (shouldLookupParts) {
        const payload = bomData.map((it: string[]) => {
          const partNumberText = i18n.global.t('partNumber') as string
          const supplierText = i18n.global.tc('supplier', 1) as string
          const partNumber = it[columnToIdxMap[partNumberText][0]]?.trim() ?? ""
          const importHasSupplier = !!columnToIdxMap[supplierText]
          const supplierKey = importHasSupplier
            ? it[columnToIdxMap[supplierText][0]]?.trim() ?? ""
            : defaultSupplier?.supplierKey ?? ""
          return { partNumber, supplierKey }
        })
        lookedUpParts = await draftPartLookup(payload)
      }
      // * Need to update endpoint so that it just returns a list
      // If items are a part code, then make DTO an empty DraftBomItem with partNumberCodeId and partNumberPartCode set
      // along with whatever else the user provided
      // Need to then update the columnToDraftBomFields assignment with an exception to prevent setting partCode as partNumber
      const result = bomData.map((it: string[], i) => {
        const refKey = uuidv4()
        let part: DraftBomItem = new DraftPart({
          refKey,
          draftPageId: rootState.draftPage.id,
          indentation: 0,
          parent: null,
          supplierId: defaultSupplier?.supplierId ?? 0
        })
        if (shouldLookupParts && !!lookedUpParts[i]) {
          const hasHotpointLinks = !!lookedUpParts[i].hotpointLinks.length
          const formatHPLAsDraft = (it: DraftHotpointLink) => {
            const fields = ['displayName', 'targetMediaId', 'targetChapterId',
              'targetPageId',  'targetEntityName', 'targetFileName',
              'targetMediaIdentifier', 'targetEntityNameLocaleId' ]
            const draftHPL: DraftHotpointLink = {
              pendingId: uuidv4(),
              hotpointLinkType: it.hotpointLinkType,
            }
            fields.forEach(field => {
              if (it[field]) draftHPL[field] = it[field]
            })
            return draftHPL
          }
          part = {
            ...lookedUpParts[i],
            refKey,
            parent: null,
            indentation: 0,
            hotpointLinks: hasHotpointLinks
              ? lookedUpParts[i].hotpointLinks.map(formatHPLAsDraft)
              : []
          }
          // If part is a part code, then ensure orderable is set to false
          const isPartCode = !!lookedUpParts[i]?.partNumberCodeId
          if (isPartCode) {
            part.orderableForPart = false
            part.orderableForPagePart = false
          }
        } else if (!override) { // Use existing data as base part data if part and item pair exists
          const item = i18n.global.t('itemAlt') as string
          const partNumber = i18n.global.t('partNumber') as string
          const supplier = i18n.global.tc('supplier', 1) as string
          let importItem = columnToIdxMap[item] ? it[columnToIdxMap[item][0]].trim() : ''
          let importPartNumber = columnToIdxMap[partNumber] ? it[columnToIdxMap[partNumber][0]].trim() : ''
          let importSupplierKey = columnToIdxMap[supplier] ? it[columnToIdxMap[supplier][0]].trim() : ''

          if (!importSupplierKey) {
            importSupplierKey = defaultSupplier?.supplierKey ?? ''
          }

          if (!!importItem && !!importPartNumber && !!importSupplierKey) {
            const importSupplier = suppliers
              .find((item) => item.supplierKey === importSupplierKey)

            if (!!importSupplier) {
              const existingBom = state.draftBomItems.find((item) =>
                item.item === importItem
                && (item.partNumber === importPartNumber || item?.partNumberPartCode === importPartNumber)
                && item.supplierId === importSupplier.supplierId
              )

              if (!!existingBom) {
                part = cloneDeep(existingBom)
              }
            }
          }
        }

        // Conditionally apply default UOM if one was not already associated with part or provided in upload
        const defaultUom = getDefaultUom(rootState.user.tenantDefaultUom)
        if (!!defaultUom && !part.unitOfMeasure) { part.unitOfMeasure = defaultUom }

        // Conditionally apply default Supplier if one was not already associated with part or provided in upload
        if (!!defaultSupplier && part.supplierId <= 0) { part.supplierId = defaultSupplier.supplierId }

        // Conditionally apply part code description
        if (!!part.partNumberPartCodeDescription) {
          const trIdx = part.partTranslations.findIndex(it => it.lang === rootState.user.locale)
          if (trIdx >= 0 ) part.partTranslations[trIdx].name = part.partNumberPartCodeDescription
        }


        // Set fields on draftBomItem DTO based off mappings
        // Value type must be converted to correspond to field of DraftBomItem interface

        for (let key in columnToDraftBomFields) {
          const field: string = columnToDraftBomFields[key]
          let value: string = it[columnToIdxMap[key][0]] ?? ""
          switch (field) {
            case 'supplier':
              const supplierMatch = suppliers
                .find((supplier: Supplier) => supplier.supplierKey === value.trim())
              if (!!supplierMatch) { part.supplierId = supplierMatch.supplierId }
              break
            case 'retailPrice': case 'wholesalePrice': case 'discountedPrice':
              value = value.trim()
              if (!value || isNaN(Number(value))) break
              const bigNum = new Big(value)
              part[field] = Number(bigNum.round(2))
              break
            case 'orderableForPart': case 'orderableForPagePart':
            case 'partNumberVisibleForPart': case 'partNumberVisibleForPagePart':
              if (!!value)
                 part[field] = ((value.toLowerCase() === "true") || (value === "1"))
              else
                part[field] = false
              break
            case 'partNumber':
              // Make sure part Number string is trimmed before assigning
              value = value.trim()
              part[!!part.partNumberCodeId ? 'partNumberPartCode': 'partNumber'] = value
              const valueMatchesAPartCode = rootGetters['partCodeLookups/isAPartCode'](value)
              if (valueMatchesAPartCode) {
                const { partCodesMap } = rootState.partCodeLookups
                const partCode: PartCodeDto = partCodesMap[value]
                if (partCode.id) part.partNumberCodeId = partCode.id
                part.partNumberPartCode = partCode?.partcode ?? String(partCode.partcode)
                part.partNumberPartCodeDescription = partCode?.desc ?? ''
                part.orderableForPart = false
                part.orderableForPagePart = false
                // Conditionally apply part code description to translation name only for lookups
                // This makes no sense to me, but apparently DocuStudio does it this way

                if (shouldLookupParts) {
                  const trIdx = part.partTranslations.findIndex(it => it.lang === rootState.user.locale)
                  const hasTr = trIdx >= 0
                  if (hasTr) {
                    part.partTranslations[trIdx].name = part.partNumberPartCodeDescription
                  } else {
                    part.partTranslations.push({ name: part.partNumberPartCodeDescription, lang: rootState.user.locale, desc: '' })
                  }
                }
              }
              break
            case 'quantity':
              part.quantity = isNaN(Number(value)) ? "" : value
              break
            default:
              part[field] = value
          }
        }
        // Additional check for part code to ensure orderable fields are set as false
        // This is necessary to override an import of TRUE for orderable for a part code
        const isAPartCode = !!part?.partNumberCodeId
        if (isAPartCode) {
          part.orderableForPart = false
          part.orderableForPagePart = false
        }

        // Set partTranslations -- require special handling
        const trNamesToTrCodes = getTrNamesToTrCodes()
        const trDescToTrCodes = getTrDescToTrCodes()
        const preImportTranslations: Map<string, string> = part.partTranslations
          .reduce((map: Map<string, string>, it: Translation) => {
            map.set(it.lang, it.lang)
            return map
          }, new Map()
        )
        for (let key in trNamesMap) {
          const trIndex = part.partTranslations.findIndex(tr => {
            return tr.lang === trNamesToTrCodes[key]
          })
          const hadTrPreImport = preImportTranslations.has(trNamesToTrCodes[key] ?? "")
          const hasTranslation = trIndex !== -1
          const isValidName = !!it[columnToIdxMap[key][0]]
          // Only update translation name or create a translation for name
          // when name is not an empty string.
          // Caveat: when doing a part lookup with ignoreMatchingTr set as TRUE,
          // do not update any pre-existing part translations
          if (shouldLookupParts && hadTrPreImport && ignoreMatchingTr) continue
          else if (hasTranslation && isValidName) {
            part.partTranslations[trIndex].name = it[columnToIdxMap[key][0]]?.trim() ?? ''
          } else if (isValidName) {
            part.partTranslations.push({
              desc: '',
              lang: trNamesToTrCodes[key],
              name: it[columnToIdxMap[key][0]]?.trim() ?? ''
            })
          }
        }
        for (let key in trDescMap) {
          const trIndex = part.partTranslations.findIndex(tr => {
            return tr.lang === trDescToTrCodes[key]
          })
          const hadTrPreImport = preImportTranslations.has(trDescToTrCodes[key] ?? "")
          const hasTranslation = trIndex !== -1
          if (shouldLookupParts && hadTrPreImport && ignoreMatchingTr) continue
          else if (hasTranslation) {
            part.partTranslations[trIndex].desc = it[columnToIdxMap[key][0]]?.trim() ?? ''
          } else {
            part.partTranslations.push({
              desc: it[columnToIdxMap[key][0]]?.trim() ?? '',
              lang: trDescToTrCodes[key],
              name: ''
            })
          }
        }

        // Set partTagValues & pagePartTagValues -- require special handling
        const extendedPartTags: DraftTagValue[] = part.partTagValues
          .flatMap(it => {
            const isValidTag = it.hasOwnProperty('value') || it.hasOwnProperty('lowerBoundValue')
            return isValidTag ? { ...it, pendingId: uuidv4() } : []
          })
        const extendedPgPartTags: DraftTagValue[] = part.pagePartTagValues
          .flatMap(it => {
            const isValidTag = it.hasOwnProperty('value') || it.hasOwnProperty('lowerBoundValue')
            return isValidTag ? { ...it, pendingId: uuidv4() } : []
          })
        part.partTagValues = extendedPartTags
          .concat(getTags(partTagsMap, it, columnToIdxMap, extendedPartTags))
        part.pagePartTagValues = extendedPgPartTags
          .concat(getTags(pagePartTagsMap, it, columnToIdxMap, extendedPgPartTags))
        return part
      })
      return result
    }

    function getTags(map: ImportedTags, bomItemData: string[], columnToIndicesMap: StringToNumListMap, tagsList: DraftTagValue[]): DraftTagValue[] {
      // Build map of values in tagsList to which results will be appended
      const tagsValuesMap: StringToStringMap = {}
      tagsList.forEach(it => {
        if (typeof it.value === 'string') tagsValuesMap[it.value] = it.value
      })
      // Build map for idx lookup of tag values from bomItemData
      const tagPointers: StringToNumListMap = {}
      for (let key in map) {
        tagPointers[key] = columnToIndicesMap[key]
      }
      // Build list of tag values to return
      const tagValues: DraftTagValue[] = []
      for (let key in map) {
        for (let i = 0; i < map[key].length; i++) {
          // Ensure importedTagDto has all required fields
          const importedTagDto = map[key][i]
          const tagIdx = tagPointers[key][i] // Use tagPointers map to lookup tag value from bomItemData
          if (!importedTagDto.pendingId || !importedTagDto.tagName || !importedTagDto.tagNameId || !bomItemData[tagIdx]) continue
          const tag: DraftTagValue = {
            pendingId: importedTagDto.pendingId,
            tagNameId: importedTagDto.tagNameId,
            tagName: importedTagDto.tagName,
            value: bomItemData[tagIdx]
          }
          // Add tag to list if not already in the list to which results will be appended
          if (!tagsValuesMap.hasOwnProperty(tag.value as string)) { tagValues.push(tag) }
        }
      }
      return tagValues
    }
    function getInsertionIdx(selectedRowsByIdx: number[]): number {
      const hasSelected = !!selectedRowsByIdx.length
      const lastSelectedIdx = selectedRowsByIdx.length - 1
      return hasSelected
        ? selectedRowsByIdx[lastSelectedIdx] + 1
        : state.draftBomItems.length
    }
    function checkForMatches(columns: ImportColumns[], defaultSupplier?: Supplier|null): boolean {
      const values = columns.map(it => it.value)
      const item = i18n.global.t('itemAlt') as string
      const partNumber = i18n.global.t('partNumber') as string
      const supplier = i18n.global.tc('supplier', 1) as string
      const hasItem = values.includes(item)
      const hasPartNumber = values.includes(partNumber)
      const hasSupplier = values.includes(supplier) || !!defaultSupplier
      return hasItem && hasPartNumber && hasSupplier
    }

    function getItemPartMap(draftBomItems: DraftBomItem[], importedBomList: DraftBomItem[]): StringToNumberMap {
      const itemPartToDraftBomIdxMap: StringToNumListMap = {}
      draftBomItems.forEach((it: DraftBomItem, idx: number) => {
        const key = getItemPartNumberSupplierAsString(it)
        if (itemPartToDraftBomIdxMap.hasOwnProperty(key)) {
          itemPartToDraftBomIdxMap[key].push(idx)
        } else {
          itemPartToDraftBomIdxMap[key] = [idx]
        }
      })
      // Iterate over importedBomList and identify matches
      const itemPartToImportMap: StringToNumberMap = {}
      importedBomList.forEach((it: DraftBomItem, i: number) => {
        const key = getItemPartNumberSupplierAsString(it)
        if (itemPartToDraftBomIdxMap.hasOwnProperty(key)) {
          itemPartToImportMap[key] = i
        }
      })
      return itemPartToImportMap
    }
    function getBomWithInPlaceUpdates(draftBomItems: DraftBomItem[], itemPartToImportMap: StringToNumberMap, importedBomList: DraftBomItem[]): DraftBomItem[] {
      return draftBomItems.map((it: DraftBomItem) => {
        const itemPart = getItemPartNumberSupplierAsString(it)
        // Return the existing draftBOMItem if there is no import match for it
        if (!itemPartToImportMap.hasOwnProperty(itemPart)) return it
        // Else start with the draftBOMItem, and update its fields based off
        // results of lookup API request & user import --> importedBomList
        const matchingPartIdx = itemPartToImportMap[itemPart]
        const lookedUpPart = importedBomList[matchingPartIdx]
        const updatedBomItem = { ...it, ...lookedUpPart }
        // Publisher creates a new part on changes to part code translations
        // So, deleting partId ensures "NEW" pill is displayed
        const hasLookedUpPartCode = lookedUpPart.partNumberCodeId
        const hasEditedTranslation = !hasMatchingTranslations(it.partTranslations, lookedUpPart.partTranslations)
        if (hasLookedUpPartCode && hasEditedTranslation) delete updatedBomItem.partId
        return updatedBomItem
      })
    }
    function getItemPartNumberSupplierAsString(it: DraftBomItem): string {
      return `${it.item}-${it?.partNumberPartCode ?? it.partNumber}-${it.supplierId}`
    }
  }
}

const mutations: MutationTree <DraftPage> = {
  [MutationTypes.SET_DRAFT_PAGE] (state, payload) {
    state.created = payload.created || null
    state.draftSheets = payload.draftSheets || []
    state.draftTagValueItems = payload.draftTagValueItems || []
    state.hashKey = payload.hashKey || ''
    state.id = payload.id || null
    state.targetPageId = payload.targetPageId || null
    state.tenantId = payload.tenantId || null
    state.translations = payload.translations || null
    state.updated = payload.updated || null
    state.useLibvips = payload.useLibvips || null
  },
  [MutationTypes.SET_DRAFT_BOM_ITEMS] (state, payload) {
    // Reactive replacement of data
    state.draftBomItems = [...payload]
  },
  [MutationTypes.UPDATE_DRAFT_BOM_ITEM] (state, { index, type, value }: IndexTypeValue) {
    // If emptying out item value, then delete field
    // This results in it being saved as NULL in DB
    if (type === EditTypes.item && !value) {
      delete state.draftBomItems[index].item
      return
    }

    // Ensure part numbers & translations are trimmed
    if (type === EditTypes.partNumber || type === EditTypes.partTranslations) {
      value = recursivelyTrim(value)
    }

    // @ts-ignore
    state.draftBomItems[index][type] = value
    if (type === EditTypes.partNumber && state.draftBomItems[index].hasOwnProperty('partNumberCodeId')) {
      delete state.draftBomItems[index].partNumberCodeId
      delete state.draftBomItems[index].partNumberPartCode
      delete state.draftBomItems[index].partId
    }
    if (state.draftBomItems[index].partNumberHasBeenSuperseded) {
      state.draftBomItems[index].partNumberHasBeenSuperseded = false
      delete state.draftBomItems[index].supersededPartNumber
    }
    // Ensure component data reactivity due to deeply nested object structure
    // @ts-ignore
    state.draftBomItems.splice(index, 1, cloneDeep(state.draftBomItems[index]))
  },
  [MutationTypes.ADD_PART] (state, { part, index }) {
    const oldList = state.draftBomItems.slice(0)
    const firstHalf = oldList.slice(0, index).concat(part)
    const secondHalf = oldList.slice(index)
    state.draftBomItems = firstHalf.concat(secondHalf)
  },
  [MutationTypes.DELETE_PART_ID] (state, index) {
    delete state.draftBomItems[index].partId
    state.draftBomItems = state.draftBomItems.slice(0)
  },
  [MutationTypes.BULK_DRAFT_BOM_ITEM_UPDATE] (state, { index, partData, supplierId }: IndexPartDataSupplier) {
    state.draftBomItems[index] = {
      ...state.draftBomItems[index],
      partId: partData.id,
      partNumber: partData.partNumber,
      partNumberCodeId: partData.partNumberCodeId,
      partNumberPartCode: partData?.partNumberPartCode,
      partTranslations: partData.translations,
      supplierId: supplierId,
      unitOfMeasure: partData.uom,
      partTagValues: partData.partTagValues,
      // Agreed to use part data for page part attributes
      orderableForPart: partData.orderable,
      orderableForPagePart: partData.orderable,
      partNumberVisibleForPart: partData.partNumberVisible,
      partNumberVisibleForPagePart: partData.partNumberVisible,
      retailPrice: partData?.retailPrice,
      wholesalePrice: partData?.wholesalePrice,
      discountedPrice: partData?.discountedPrice
    }
    state.draftBomItems = state.draftBomItems.slice(0)
  },
  [MutationTypes.UPDATE_DRAFT_BOM_ORDER] (state, updatedDraftBomItems) {
    state.draftBomItems = updatedDraftBomItems
  },
  [MutationTypes.SHOW_ALL_CHILDREN] (state, childIdxList: number[]) {
    childIdxList.forEach(idx => {
      state.draftBomItems[idx].visible = true
    })
  },
  [MutationTypes.HIDE_ALL_CHILDREN] (state, childIdxList: number[]) {
    childIdxList.forEach(idx => {
      state.draftBomItems[idx].visible = false
      state.draftBomItems[idx].expanded = false
    })
  },
  [MutationTypes.UPDATE_ORPHANED_PARTS] (state, orphansList: IdxParentIndent[]) {
    orphansList.forEach(orphan => {
      state.draftBomItems[orphan.idx].parent = orphan.updatedParent
      state.draftBomItems[orphan.idx].indentation = orphan.updatedIndent
    })
  },
  [MutationTypes.DELETE_DRAFT_BOM_ITEMS] (state, partsIdxList: number[]) {
    partsIdxList.forEach(idx => {
      // If part has been added but not saved to DB, go ahead and remove it
      // Otherwise, flag it for deletion to allow component to line through it
      if (!state.draftBomItems[idx].id) {
        state.draftBomItems.splice(idx, 1)
      } else {
        state.draftBomItems[idx].deleted = true
      }
    })
  },
  [MutationTypes.BULK_PART_UPDATES] (state, childUpdatesList: IndexTypeValue[]) {
    childUpdatesList.forEach(obj => {
      if (obj.type !== 'partName') { // @ts-ignore
        state.draftBomItems[obj.index][obj.type] = obj.value
      }
    })
  },
  [MutationTypes.REMOVE_DELETED_BOM_ITEMS] (state) {
    state.draftBomItems = state.draftBomItems
      .filter(it => !it.deleted)
      .map(bom => {
        bom.hotpointLinks = bom.deleteHotpointLinks? [] : bom.hotpointLinks
        return {...bom}
      })

  },
  [MutationTypes.SET_PART_AS_PART_CODE] (state, { index, partCode, partCodeId, desc }) {
    state.draftBomItems[index].orderableForPart = false
    state.draftBomItems[index].orderableForPagePart = false
    state.draftBomItems[index].partNumberCodeId = partCodeId
    state.draftBomItems[index].partNumberPartCode = partCode
    state.draftBomItems[index].partNumberPartCodeDescription = desc
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    draftBomItemUpdating,
    publishing
  }
}
