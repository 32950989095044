<template>
  <div
    class="modal is-active"
    data-unit="modal is-active"
    @dblclick.stop
  >
    <div class="modal-background"/>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <strong>{{ $t('add') }}</strong>
        </p>
        <button
          class="delete"
          @click="cancel"
        />
      </header>
      <section class="modal-card-body">
        <div
          class="title is-size-5"
          data-int="info-name"
        >
          <label>{{ bomItem.name }}</label>
        </div>
        <div
          class="subtitle is-6"
          data-int="info-identifier"
        >
          <label>{{ bomItem.partNumber }}</label>
        </div>
        <label class="label">{{ $t('qty') }}</label>
        <add-to-cart v-model:quantity="quantity"
                     :expanded="true"
                     :include-add-btn="false"/>
      </section>
      <footer class="modal-card-foot">
        <button
          :disabled="quantity < 1"
          class="button is-primary"
          data-unit="add-btn"
          @click="addToCart"
        >
          {{ $t('add') }}
        </button>
        <button
          class="button"
          data-unit="cancel-btn"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import AddToCart from '../../../../info/AddToCart.vue'

export default {
  name: 'BomAddToCartModal',
  props: {
    bomItem: {
      type: Object,
      required: true
    }
  },
  components: {AddToCart},
  data() {
    return {
      quantity: 1
    }
  },
  computed: {
    ...mapState({
      addToCartQtyBehavior: state => state.user.preferences.addToCartQtyBehavior
    })
  },
  methods: {
    addToCart() {
      this.$emit('addToCart', this.quantity)
    },
    cancel() {
      this.$emit('cancel')
    },
    getInitialQuantity() {
      switch (this.addToCartQtyBehavior) {
        case 'qtyZero':
          return 0
        case 'qtyFromBom':
          return this.bomItem.quantity ? parseInt(this.bomItem.quantity, 10) : 1
        default:
          return 1
      }
    }
  },
  mounted() {
    this.$store.dispatch('content/updateTocOpen')
    this.quantity = this.getInitialQuantity()
  }
}
</script>

<style scoped>
.modal-card {
  max-width: 60%;
  min-width: 10rem;
}

.modal-card-body {
  flex-direction: column;
  min-height: 12rem;
}

.input {
  min-width: 10rem;
}
</style>
