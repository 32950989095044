import http from '@/http'
import { Page } from '@/interfaces/library'
import { PageDtoExtended } from '@/interfaces/global'

export const getPage = async (pageId: number):Promise<Page> => {
  return http.get(`/pages/${pageId}`).then(res => res.data)
}

export async function getPageByHashKey(pageHashKey: string): Promise<PageDtoExtended> {
  const { data } = await http.get(`/pages/by-hashkey/${pageHashKey}`)
  return data
}