import http from '@/http';

/**
 * @todo add second argument for format when we need
 * to support PDF downloads. Currently setup just for PLZ.
 *
 * @param {string|number} id
 * @return {*} something...
 */
export async function exportIllustration(id) {
  const formData = new FormData();

  formData.set('pageId', id);

  return await http.post('/printer/zip', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json, text/plain, */*'
    }
  }).then((res) => res.data);
}
