import http from "@/http";
import {v4 as uuid} from "uuid";

interface DeleteReferences {
  rows: Map<string, HeaderRow>,
  columns: Map<string, HeaderColumn>,
  items: Map<string, HeaderColumnItem>
}

interface HeaderRow {
  id?: number
  refKey: string // Reference for all rows, including new rows
  created: Date | null
  updated: Date | null
  displayOrder: number
  ecommerceTemplateId: number
  columns: HeaderColumn[]
}

export interface HeaderColumn {
  id?: number
  refKey: string // Reference for all columns, including new rows
  created?: Date
  updated?: Date
  displayOrder: number
  ecommerceTemplateHeaderRowId?: number
  items: HeaderColumnItem[]
}

export interface HeaderColumnItem {
  id?: number
  refKey: string // Reference for column items, including new columns
  created?: Date
  updated?: Date
  isStyleDirty: boolean // Track individual styles to update
  applyBoldStyle?: boolean
  applyItalicStyle?: boolean
  applyUnderlineStyle?: boolean
  fontColor?: string
  fontFamily?: string
  fontSize?: number
  justification: number // left, right, center (ordinals)
  displayOrder: number
  headerFieldId?: number
  headerColumnId?: number
}

export interface HeaderFieldOption {
  id: number,
  defaultValue: any,
  name: string,
  translatedName: string,
  type: string
}

/**
 * User can delete in any order, including from children to parent headers. This can create invalid
 * deletes upon saving. Hence, delete failures are silenced.
 *
 * @param deleteReferences
 */
const deleteHeaderReferences = async function(deleteReferences: DeleteReferences) {
  async function deleteHeaderRows(rows: HeaderRow[], deleteFailures: any) {
    for (const r of Object.values(rows)) {
      try {
        await http.delete(`/admin/orders/templates/header-row/${r.id}`)
      } catch (e) {
        deleteFailures.rows.push(JSON.parse(JSON.stringify(r)))
      }
    }
  }

  async function deleteHeaderColumns(columns: HeaderColumn[], deleteFailures: any) {
    for (const c of Object.values(columns)) {
      try {
        await http.delete(`/admin/orders/templates/header-column/${c.id}`)
      } catch (e) {
        deleteFailures.rows.push(JSON.parse(JSON.stringify(c)))
      }
    }
  }
  async function deleteHeaderColumnItems(items: HeaderColumnItem[], deleteFailures: any) {
    for (const i of Object.values(items)) {
      try {
        await http.delete(`/admin/orders/templates/column-item/${i.id}`)
      } catch (e) {
        deleteFailures.rows.push(JSON.parse(JSON.stringify(i)))
      }
    }
  }

  // For debugging
  const deleteFailures = {
    rows: [],
    columns: [],
    items: []
  }

  if (deleteReferences.rows.size > 0) {
    await deleteHeaderRows(Array.from(deleteReferences.rows.values()), deleteFailures)
  }
  if (deleteReferences.columns.size > 0) {
    await deleteHeaderColumns(Array.from(deleteReferences.columns.values()), deleteFailures)
  }
  if (deleteReferences.items.size > 0) {
    await deleteHeaderColumnItems(Array.from(deleteReferences.items.values()), deleteFailures)
  }

  return deleteFailures
}

export const loadTemplateHeaders = async function(templateId: number): Promise<HeaderRow[]> {
  return await http.get<HeaderRow[]>(`/admin/orders/templates/tree/${templateId}`)
    .then((response) => {
      response.data.forEach((row) => {
        row.refKey = uuid()

        row.columns.forEach((col) => {
          col.refKey = uuid()
          col.items.forEach((item) => { item.refKey = uuid() })
        })
      })

      return response.data
    })
}

export const loadHeaderFieldOptions = async function(): Promise<HeaderFieldOption[]> {
  const itemOptions = []
  const params = {
    limit: 100,
    offset: 0
  }
  let count: number = 0
  let fieldsResponse

  do {
    fieldsResponse = await http.get('/admin/orders/templates/fields', { params })
    count = fieldsResponse.headers['x-count']
    itemOptions.push(...(fieldsResponse.data))
    params.offset = params.offset + params.limit
  } while (params.offset < count)

  // Ascending sort
  itemOptions.sort((a, b) => {
    const aName = a.name
    const bName = b.name
    if (aName < bName) { return -1 }
    if (bName < aName) { return 1 }
    return 0
  })

  return itemOptions
}

export const saveHeaders = async function(
  deleteRefs: DeleteReferences,
  rows: HeaderRow[],
  templateId: number
) {
  await deleteHeaderReferences(deleteRefs)
  await http.put(`/admin/orders/templates/tree/${templateId}`, Object.values(rows))
}

/**
 * Update any existing, dirty header item overrides. Any new header item overrides should
 * be handled in the 'saveHeaders' prior.
 *
 * @param rows
 */
export const saveDirtyHeaderItems = async function(rows: HeaderRow[]) {
  for (const r of Object.values(rows)) {
    for (const c of Object.values(r.columns)) {
      for (const i of Object.values(c.items)) {
        if (i.isStyleDirty && i.id !== undefined) {
          await http.put(`/admin/orders/templates/column-item`, i)
        }
      }
    }
  }
}
