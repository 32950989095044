import { Translation } from "@/interfaces/admin"

type TrMap = Map<string, Translation>

export function hasMatchingTranslations(prevTr: Translation[], updatedTr: Translation[]): boolean {
  if (areBothArraysEmpty(prevTr, updatedTr)) return true
  else if (!areBothArraysSameLength(prevTr, updatedTr)) return false

  const prevTrMap = getTrMap(prevTr)
  return updatedTr.every((tr: Translation) => {
    const prevTr = prevTrMap.get(tr.lang)
    return !!prevTr
      ? prevTr?.desc === tr?.desc && prevTr.name === tr.name
      : false
  })

  function getTrMap(translations: Translation[]): TrMap {
    return translations.reduce((map: TrMap, tr: Translation) => {
      map.set(tr.lang, tr)
      return map
    }, new Map())
  }
  function areBothArraysEmpty(list1: any[], list2: any[]): boolean {
    return (list1.length === 0) && (list2.length === 0) 
  }
  function areBothArraysSameLength(list1: any[], list2: any[]): boolean {
    return list1.length === list2.length
  }
}