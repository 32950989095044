<template>
  <div>
    <b-table v-model:selected="selected"
             :data="filteredData"
             default-sort="name"
             focusable
             hoverable
             sort-icon="chevron-up"
             sort-icon-size="is-small"
             @update:selected="$emit('update:selected', selected)">
      <b-table-column :label="$t('name')"
                      field="name"
                      sortable>
        <template v-slot:name="props">
          <div v-if="props">
            {{ props.row.name }}
          </div>
        </template>
      </b-table-column>
      <b-table-column :label="$t('numberOf', {item: $tc('part', 2)})"
                      field="size"
                      header-class="header"
                      sortable
                      width="100">
        <template v-slot:size="props">
          <div v-if="props">
            {{ props.row.size }}
          </div>
        </template>
      </b-table-column>
      <b-table-column :label="$t('updated')"
                      field="updated"
                      sortable>
        <template v-slot:updated="props">
          <div v-if="props">
            {{ $d(props.row.updated, 'long') }}
          </div>
        </template>
      </b-table-column>
      <b-table-column width="100" field="buttons">
        <template #header/>
        <template v-slot:buttons="props">
          <div v-if="props">
            <div v-if="isSharedCartMaintainer"
                 class="buttons is-pulled-right">
              <router-link :to="formEditorLink(props.row.id)"
                           target="_blank">
                <b-button icon-right="external-link-alt"
                          size="is-small"/>
              </router-link>
            </div>
          </div>
        </template>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>

export default {
  name: 'SharedCartTable',
  props: {
    data: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: null
    }
  },
  computed: {
    isSharedCartMaintainer() {
      return this.$store.getters['user/isSharedCartMaintainer']
    },
    filteredData() {
      if (!this.filter) return this.data
      const f = this.filter.toLocaleLowerCase()
      return this.data.filter((item) => item.name.toLocaleLowerCase().includes(f))
    }
  },
  methods: {
    formEditorLink(cartId) {
      return {
        name: 'LibrarySharedCartEditor',
        params: {
          id: cartId
        }
      }
    }
  }
}
</script>
