import { DraftPageVersion } from '@/interfaces/admin/draftPageVersion'
import { getDraftPageVersion } from '@/controllers/admin/draftPage'

const SET_DRAFT_PAGE_VERSION = 'SET_DRAFT_PAGE_VERSION'

const state: DraftPageVersion = {
  created: null,
  draftPageId: null,
  id: null,
  json: '{}',
  tenantId: null,
  userId: null,
  versionNumber: null
}

const actions = {
  async loadDraftPageVersion ({ commit }: { commit: Function }, id: number) {
    const draftPageVersion = await getDraftPageVersion(id)
    commit('SET_DRAFT_PAGE_VERSION', draftPageVersion)
  }
}

const mutations = {
  [SET_DRAFT_PAGE_VERSION] (state: DraftPageVersion, payload: DraftPageVersion) {
    state.created = payload.created
    state.draftPageId = payload.draftPageId
    state.id = payload.id
    state.json = payload.json
    state.tenantId = payload.tenantId
    state.userId = payload.userId
    state.versionNumber = payload.versionNumber
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
