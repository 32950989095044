export interface LoadAnalyticsProps {
  ok: string | undefined,
  tk: string | undefined
}

export enum AnalyticsHTMLTag {
  script = 'script', // default
  noscript = 'noscript', // google tag manager needs noscript tag
}

export enum AnalyticsParentHTMLTag {
  body = 'body',
  head = 'head'
}

export interface AnalyticsScript {
  contents?: string // the body or contents of the script tag
  src?: string // script src attribute. ie. https://www.googletagmanager.com/gtag/js
  parent: AnalyticsParentHTMLTag // parent HTML tag
  tag: AnalyticsHTMLTag
}
