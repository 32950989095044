export const handlePartInfoRedirect = {
  handlePartInfoRedirect() {
    if (this.isWidget) {
      this.$emit('openWidgetPartInfo', this.bomItem)
    } else if (this.isLibraryBetaUser) {
      // Prevent briefly rendering Page Landing Page
      // before Part content is set in Vuex
      // for opening part Landing Page
      this.$store.dispatch('content/setContentLoading')
    }
  }
}