import Home from '@/components/library/home/Home'
import Browse from '@/components/library/browse/Browse'
import BrowseSearch from '@/components/library/browse/BrowseSearch'
import Search from '@/components/library/search/Search'
import Info from '@/components/library/content/info/InfoWrapper'
import Asset from '@/components/library/content/asset/Asset.vue'
import Details from '@/components/library/content/details/Details.vue'
import ContentRelated from '@/components/library/content/related/Related'
import ContentWhereUsed from '@/components/library/content/whereused/WhereUsed'
import ContentSuggestions from '@/components/library/content/suggestions/Suggestions'
import ContentComments from '@/components/library/content/comments/Comments'
import Notification from '@/components/library/notifications/Notification'
import Notifications from '@/components/library/notifications/Notifications'
import Preferences from '@/components/library/settings/Preferences'
import Profile from '@/components/library/settings/Profile'
import Cart from '@/components/library/cart/Cart'
import PurchaseOrder from '@/components/library/cart/orders/PurchaseOrder'
import RequestForQuote from '@/components/library/cart/orders/RequestForQuote'
import CartOrderSubmitted from '@/components/library/cart/orders/OrderSubmitted'
import Order from '@/components/library/orders/Order'
import Orders from '@/components/library/orders/Orders'
import Shelf from '@/components/library/shelves/Shelf'
import Shelves from '@/components/library/shelves/Shelves'

const LibraryRoutes = [{
  path: '/',
  redirect: { name: 'Home' },
  component: () => import('@/components/library/header/Header'),
  meta: { requiresAuth: true },
  children: [{
    path: 'home',
    name: 'Home',
    component: Home
  }, {
    path: 'favorites-list',
    name: 'Favorites',
    component: Shelves
  }, {
    path: 'favorites/:id',
    name: 'FavoritesItem',
    component: Shelf
  }, {
    path: 'browse/:id*',
    props: true,
    component: () => import('@/components/library/browse/BrowseHeader'),
    children: [{
      path: 'search',
      name: 'BrowseSearch',
      props: true,
      component: BrowseSearch
    }, {
      path: '',
      name: 'Browse',
      props: true,
      component: Browse
    }]
  }, {
    path: 'search',
    name: 'Search',
    component: Search
  }, {
    path: 'content/:id+',
    name: 'Content',
    alias: [
      'book/:id+',
      'document/:id',
      'external/:id',
      'image/:id',
      'microsite/:id',
      'page/:id+',
      'part/:id',
      'video/:id'
    ],
    props: true,
    component: () => import('@/components/library/content/Content'),
    children: [{
      path: 'details',
      name: 'Details',
      component: Details
    }, {
      path: 'info',
      name: 'Info',
      component: Info
    }, {
      path: 'related',
      name: 'Related',
      component: ContentRelated
    }, {
      path: 'where-used',
      name: 'WhereUsed',
      component: ContentWhereUsed
    }, {
      path: 'suggestions',
      name: 'Suggestions',
      component: ContentSuggestions
    }, {
      path: 'comments',
      name: 'Comments',
      component: ContentComments
    }, {
      path: '',
      name: 'Asset',
      component: Asset
    }]
  }, {
    path: 'news-items',
    component: () => import('@/components/library/notifications/NotificationsHeader'),
    children: [{
      path: ':id',
      name: 'Notification',
      props: true,
      component: Notification
    }, {
      path: '',
      name: 'Notifications',
      component: Notifications
    }]
  }, {
    path: 'settings',
    component: () => import('@/components/library/settings/Settings'),
    children: [{
      path: 'preferences',
      name: 'Preferences',
      component: Preferences
    }, {
      path: '',
      name: 'Profile',
      component: Profile
    }]
  }, {
    path: 'job-manager',
    name: 'JobManager',
    component: () => import('@/components/library/jobManager/JobManager')
  }, {
    path: 'cart',
    component: () => import('@/components/library/cart/CartHeader'),
    children: [{
      path: 'purchase-order',
      name: 'PurchaseOrder',
      component: PurchaseOrder
    }, {
      path: 'request-for-quote',
      name: 'RequestForQuote',
      component: RequestForQuote
    }, {
      path: 'submitted',
      name: 'OrderSubmitted',
      component: CartOrderSubmitted
    }, {
      path: '',
      name: 'Cart',
      component: Cart
    }]
  }, {
    path: 'orders',
    component: () => import('@/components/library/orders/OrdersHeader'),
    children: [{
      path: ':id',
      name: 'Order',
      props: true,
      component: Order
    }, {
      path: '',
      name: 'Orders',
      component: Orders
    }]
  }, {
    path: 'mediaIdentifierRedirect/:id',
    component: {
      created () {
        window.location = `/api/mediaIdentifierRedirect/${this.$route.params.id}${window.location.search}`
      }
    }
  }, {
    name: 'LibraryReports',
    path: 'reports',
    component: () => import('@/components/library/reports/LibraryReports')
  },
  {
    name: 'LibraryReportViewer',
    path: 'reports/:id',
    component: () => import('@/components/library/reports/LibraryReportViewer')
  },
  {
    name: 'LibrarySharedCarts',
    path: 'shared-carts',
    component: () => import('@/components/admin/storefront/shared-cart/LibrarySharedCartsManager')
  },
  {
    name: 'LibrarySharedCartCreator',
    path: 'shared-carts/new',
    component: () => import('@/components/admin/storefront/shared-cart/LibrarySharedCartEditor')
  },
  {
    name: 'LibrarySharedCartEditor',
    path: 'shared-carts/:id',
    component: () => import('@/components/admin/storefront/shared-cart/LibrarySharedCartEditor')
  },
  {
    name: 'Exporter',
    path: 'exporter',
    component: () => import('@/components/library/exporter/Exporter')
  },
  {
    path: 'reporting',
    component: () => import('@/components/library/reports/ReportsLibraryWrapper'),
    children: [{
      name: 'ReportsManager',
      path: '',
      component: () => import('@/components/library/reports/ReportsManager.vue'),
      meta: {
        saveRevertEnabled: false,
        label () {
          return this.$tc('report', 2)
        }
      }
    },
    {
      name: 'DashboardsManager',
      path: 'dashboards',
      component: () => import('@/components/library/reports/DashboardsManager.vue'),
      meta: {
        saveRevertEnabled: false,
        label () {
          return this.$tc('dashboard', 2)
        }
      }
    },
    {
      name: 'ReportParameters',
      path: 'report/parameters/:id',
      component: () => import('@/components/library/reports/ReportParameters.vue'),
      meta: {
        saveRevertEnabled: false,
        label () {
          return this.$tc('report', 2)
        }
      }
    },
    {
      name: 'ReportViewer',
      path: 'report/:id',
      component: () => import('@/components/library/reports/ReportViewer.vue'),
      meta: {
        saveRevertEnabled: false,
        label () {
          return this.$tc('report', 2)
        }
      }
    }
    ]
  }]
}]

export default LibraryRoutes
