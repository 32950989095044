import http from '@/http'
import { Hotpoint } from '@/interfaces/admin/hotpoint'

export interface BlobHotPointsParams {
  minArea: number,
  maxArea: number,
  bomItems: String
}

export interface SvgHotPointsParams {
  bomItems: String
}

export async function detectDraftSheetImageHotpoints (draftSheetId: number, params: BlobHotPointsParams): Promise<Hotpoint[]> {
  const { data } = await http.get(`/admin/draft-sheets/detect-image-hotpoints/${draftSheetId}`, { params })
  return data
}

export async function detectSvgDraftSheetImageHotpoints (draftSheetId: number, params: SvgHotPointsParams): Promise<Hotpoint[]> {
  const { data } = await http.get(`/admin/draft-sheets/detect-svg-image-hotpoints/${draftSheetId}`, { params })
  return data
}
