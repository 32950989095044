import http from '@/http';
import i18n from '@/locales';
import qs from 'qs';
import { postError } from '@/helpers/notification'

const STYLES_RECIEVED = 'STYLES_RECIEVED';
const STYLES_LOADING = 'STYLES_LOADING';

const state = {
  logos: {},
  images: {},
  colors: {},
  isLoaded: false
};

const actions = {
  async getBranding({rootState, commit, dispatch}) {
    try {
      commit(STYLES_LOADING);
      const context = rootState.admin.selected;
      let json = {};

      if (context) {
        if (context.isTenant) {
          const {data: tenant} = await http.get(`styles/tenant/${context.id}.json`);
          json = tenant || {};
        } else {
          const {data: org} = await http.get(`styles/organization/${context.key}.json`);
          json = org || {};
        }
        commit(STYLES_RECIEVED, json);
      }
      dispatch('setOrganizationTreeProperty', '');
    } catch (err) {
      commit(STYLES_RECIEVED, {});
    }
  },
  async uploadAndSaveProperty({dispatch, rootState}, {property, file}) {
    try {
      const context = rootState.admin.selected;
      const formData = new FormData();
      formData.append('file', file, file.name);

      if (context) {
        if (context.isTenant) {
          await http.post(`/tenants/${context.id}/properties/${property}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        } else {
          await http.post(`/organizations/${context.id}/properties/${property}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        }
        dispatch('getBranding');
      }
    } catch (err) {
      const response = err.response;
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      });
    }
  },
  async saveProperty({dispatch, rootState}, {property, value}) {
    try {
      const context = rootState.admin.selected;
      const formData = {
        value
      };

      if (context) {
        if (context.isTenant) {
          await http.put(`/tenants/${context.id}/properties/${property}`,
              qs.stringify(formData, {indices: false}));
        } else {
          await http.put(`/organizations/${context.id}/properties/${property}`,
              qs.stringify(formData, {indices: false}));
        }
        await dispatch('getBranding');
      }
    } catch (err) {
      const response = err.response;
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      });
    }
  },
  async removeProperty({dispatch, rootState}, property) {
    try {
      const context = rootState.admin.selected;

      if (context) {
        if (context.isTenant) {
          await http.delete(`/tenants/${context.id}/properties/${property}`);
        } else {
          await http.delete(`/organizations/${context.id}/properties/${property}`);
        }
        await dispatch('getBranding');
      }
    } catch (err) {
      const response = err.response;
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      });
    }
  }
};

const mutations = {
  [STYLES_RECIEVED](state, properties = {}) {
    const propertyMap = {};
    properties.map(prop => { //eslint-disable-line
      propertyMap[prop.property] = {
        inherited: prop.inherited,
        value: prop.propertyValue && prop.propertyValue.length ? prop.propertyValue[0] : null
      };
    });
    state.logos = {
      WEB_LOGO: propertyMap.WEB_LOGO || {},
      ECOMMERCE_LOGO: propertyMap.ECOMMERCE_LOGO || {},
      PRINT_LOGO: propertyMap.PRINT_LOGO || {}
    };
    state.images = {
      HERO_IMAGE: propertyMap.HERO_IMAGE || {},
      LOGIN_BACKGROUND_LOGO: propertyMap.LOGIN_BACKGROUND_LOGO || {}
    };
    state.colors = {
      CSS_BRAND_PRIMARY: propertyMap.CSS_BRAND_PRIMARY || {},
      CSS_HOTPOINT_HIGHLIGHTED: propertyMap.CSS_HOTPOINT_HIGHLIGHTED || {},
      CSS_LINKS: propertyMap.CSS_LINKS || {},
      CSS_HOTPOINT: propertyMap.CSS_HOTPOINT || {}
    };
    state.isLoaded = true;
  },
  [STYLES_LOADING](state) {
    state.isLoaded = false;
  }
};

export default {
  state,
  actions,
  mutations
};
