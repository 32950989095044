import http from '@/http'

export async function standardizeImage (img: File) {
  let api = '/admin/draft-sheets/image/standardize'
  let formData = new FormData()
  formData.append('files', img, img.name)
  const { data } = await http.put(api, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/base64'
    }
  })
  return data
}
