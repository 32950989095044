<template>
  <td
    class="quantity-cell"
    :class="{'has-text-info': bomItem.highlight}"
    @dblclick.stop="open"
  >
    {{ bomItem.quantity }} {{ bomItem.uom }}
  </td>
</template>

<script>
export default {
  name: 'BomCellQuantity',
  props: {
    bomItem: {
      type: Object,
      required: true
    }
  },
  methods: {
    open () {
      this.$emit('open', {
        content: this.bomItem,
        route: ''
      })
    }
  }
}
</script>

<style scoped>
.quantity-cell {
  text-align: center;
}
.table tr.is-selected td {
  color: currentColor !important;
}
</style>
