<template>
  <div class="modal is-active">
    <div class="modal-background"
         @click="close" />
    <div class="modal-card"
         data-int="search-info-modal">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <strong data-int="search-info-modal-title">{{ $t('information') }}</strong>
        </p>
        <button class="delete"
                data-int="search-info-modal-close"
                @click="close" />
      </header>
      <section v-if="isLoaded"
               class="modal-card-body">
        <info :add-to-cart-qty-behavior="addToCartQtyBehavior"
              :content="content"
              :content-loaded="true"
              :display-availability="displayAvailability"
              :display-discounted="displayDiscounted"
              :display-eta="displayEta"
              :display-retail="displayRetail"
              :display-whole-sale="displayWholeSale"
              :is-cart-enabled="isCartEnabled"
              :is-cart-or-erp-cart-enabled="isCartOrErpCartEnabled"
              :pricing="pricing"
              :tags="tags"
              @addToCart="addToCart" />
      </section>
      <section v-else
               class="modal-card-body loading">
        <loading-icon />
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary"
                @click="close">
          {{ $t('close') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import Info from '@/components/library/content/info/Info'
import LoadingIcon from '@/components/common/LoadingIcon'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ContentInfoModal',
  props: {
    searchResult: {
      type: Object,
      required: true
    },
    addToCartQtyBehavior: {
      type: String,
      default: ''
    }
  },
  components: {
    Info,
    LoadingIcon
  },
  data () {
    return {
      content: null,
      tags: [],
      pricing: null,
      isLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      isCartOrErpCartEnabled: "user/isCartOrErpCartEnabled",
      displayRetail: "user/displayRetail",
      displayWholeSale: "user/displayWholeSale",
      displayDiscounted: "user/displayDiscounted",
      displayEta: "user/displayEta",
      displayAvailability: "user/displayAvailability",
      isCartEnabled: 'user/isCartEnabled'
    })
  },
  methods: {
    ...mapActions({
      addToCart: 'cart/addToCart',
      getContent: 'content/getContentWithReturn',
      getTags: 'content/getTags',
      getPricing: 'content/getPricingWithReturn'
    }),
    close () {
      this.$emit('close')
    }
  },
  async created () {
    this.isLoaded = false
    this.content = await this.getContent(this.searchResult)

    if (this.content) {
      await this.getTags({ filter: '', id: this.searchResult.entityId, type: this.searchResult.entityType })
      this.pricing = this.content.pricing

      this.getPricing(this.content).then((pricing) => {
        this.pricing = pricing || this.content.pricing
      })
      this.isLoaded = true
    }
  }
}
</script>

<style scoped>
.modal {
  text-align: left;
}
.modal-card-head {
  padding: .5rem;
}
.modal-card-title {
  font-size: 1rem;
}
.modal-card {
  min-width: 60%;
  max-width: 80%;
}
.modal-card-foot {
  padding: .5rem;
}
.loading {
  align-items: center;
  justify-content: center;
  height: 8rem;
}
hr {
  margin: .25rem 0;
}
@media only screen and (max-width: 769px) {
  .modal-card {
    min-width: 95%;
    width: 95%;
    height: 100%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .modal-card {
    height: 50vh !important;
  }
}
</style>
