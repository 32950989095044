export default {
  install (Vue, options) {
    Vue.mixin({
      data () {
        return {
          localStorageUpdateCount: 0
        }
      },
      methods: {
        __onStorageUpdated__ () {
          this.localStorageUpdateCount++
          this.localStorageUpdateCount %= 100
        }
      },
      beforeCreate () {
        const config = this.$options.persistentData
        if (!config) return

        Object.keys(config).forEach((target) => {
          const conf = config[target]
          const key = conf.key || `__localStoragePlugin__${target}`
          const defaultValue = typeof conf.default === 'function'
            ? conf.default
            : () => conf.default

          this.$options.computed[target] = {
            get () {
              const json = localStorage[key]
              const value = json && JSON.parse(json)
              /* eslint-disable-next-line no-unused-expressions */
              this.localStorageUpdateCount
              return typeof value === 'undefined' ? defaultValue() : value
            },
            set (value) {
              const json = JSON.stringify(value)
              if (json !== localStorage[key]) {
                localStorage.setItem(key, json)
                this.__onStorageUpdated__()
              }
            }
          }
        })
      },
      created () {
        window.addEventListener('storage', this.__onStorageUpdated__)
      },
      beforeUnmount () {
        window.removeEventListener('storage', this.__onStorageUpdated__)
      }
    })
  }
}
