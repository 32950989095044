<template>
  <main data-int="browse" class="browse-container">
    <hero :button-label="$t('goToSearch')"/>
    <slot name="breadcrumbs">
      <breadcrumb />
    </slot>
    <slot name="content">
      <router-view/>
    </slot>
  </main>
</template>

<script>
import Hero from '@/components/common/Hero';
import Breadcrumb from './Breadcrumb';

export default {
  name: 'BrowseHeader',
  components: {
    Breadcrumb,
    Hero
  }
}
</script>

<style scoped>
.browse-container {
  flex: 1;
  display: flex;
  flex-flow: column;
}
</style>
