import { Translation } from './translation'
import { DraftTagValue } from './draftTags'
import { DraftHotpointLink } from './draftHPL'

export interface DraftBomItem extends Record<string, any> {
    level?: number
    children?: string[],
    created?: Date|null,
    deleted?: boolean,
    deleteHotpointLinks?: boolean|null,
    discountedPrice?: number|null,
    draftPageId: number,
    expanded: boolean,
    hashKey: string|null,
    hotpointCount?: number|null,
    hotpointLinks: DraftHotpointLink[],
    id?: number|null,
    indentation: number,
    instanceId: number|null,
    item?: string,
    orderableForPagePart: boolean,
    orderableForPart: boolean,
    pagePartTagValues: DraftTagValue[],
    parent?: null | string,
    partId?: number,
    partNumber?: string,
    partNumberCodeId?: number,
    partNumberPartCode?: string,
    partNumberPartCodeDescription?: string,
    partCode?: string,
    partNumberHasBeenSuperseded?: boolean,
    partNumberVisibleForPagePart: boolean,
    partNumberVisibleForPart: boolean,
    partTagValues: DraftTagValue[],
    partTranslations: Translation[],
    quantity: string,
    refKey?: string,
    retailPrice?: number|null,
    supersededPartNumber?: string,
    supplierId: number,
    tenantId?: number,
    unitOfMeasure?: string,
    updated?: Date|null,
    visible?: boolean,
    wholesalePrice?: number|null
}

export enum EditTypes {
  children = 'children',
  deleteHotpointLinks = 'deleteHotpointLinks',
  discountedPrice = 'discountedPrice',
  expanded = 'expanded',
  hotpointLinks = 'hotpointLinks',
  indentation = 'indentation',
  item = 'item',
  orderableForPart = 'orderableForPart',
  orderableForPagePart = 'orderableForPagePart',
  parent = 'parent',
  partId = 'partId',
  partName = 'partName',
  partNumber = 'partNumber',
  partNumberVisibleForPart = 'partNumberVisibleForPart',
  partNumberVisibleForPagePart = 'partNumberVisibleForPagePart',
  partTranslations = 'partTranslations',
  quantity = 'quantity',
  retailPrice = 'retailPrice',
  supplierId = 'supplierId',
  unitOfMeasure = 'unitOfMeasure',
  visible = 'visible',
  wholesalePrice = 'wholesalePrice'
}

export interface IndexType {
    index: number,
    type: EditTypes
}

export interface IndexTypeValue {
    index: number,
    type: EditTypes,
    value: any
}

// JavaScript friendly -- for use within components
export const draftBomColumns = Object.freeze({
  item: 'item',
  partNumber: 'partNumber',
  supplierId: 'supplierId',
  partName: 'partName',
  quantity: 'quantity',
  unitOfMeasure: 'unitOfMeasure'
})

const DRAFT_BOM_ITEMS = 'draftPage/draftBomItemUpdating'
const DRAFT_BOM = 'draftBom'
const DRAFT_PAGE = 'draftPage'
const DRAFT_TAGS = `${DRAFT_BOM_ITEMS}/draftTags`
const DRAFT_HPLS = `${DRAFT_BOM_ITEMS}/draftHotpointLinks`
export const draftBomStorePaths = Object.freeze({
  // draftPage module
  draftPageIsDirty: `${DRAFT_PAGE}/isDirty`,
  hasParts: `${DRAFT_PAGE}/hasParts`,
  importDraftBom: `${DRAFT_PAGE}/importDraftBom`,
  loadDraftPage: `${DRAFT_PAGE}/loadDraftPage`,

  // draftBomItems module
  addPart: `${DRAFT_BOM_ITEMS}/addPart`,
  bulkDraftBomItemUpdate: `${DRAFT_BOM_ITEMS}/bulkDraftBomItemUpdate`,
  closeDropDown: `${DRAFT_BOM_ITEMS}/closeDropDown`,
  draftBomItemsCanSave: `${DRAFT_BOM_ITEMS}/canSave`, // Only BOM tab has canSave state
  draftBomItemsIsDirty: `${DRAFT_BOM_ITEMS}/isDirtyBomList`,
  deleteDraftBomItem: `${DRAFT_BOM_ITEMS}/deleteDraftBomItem`,
  openDropDown: `${DRAFT_BOM_ITEMS}/openDropDown`,
  refKeyToMasterListIdx: `${DRAFT_BOM_ITEMS}/refKeyToMasterListIdx`,
  selectDropdownItem: `${DRAFT_BOM_ITEMS}/selectDropdownItem`,
  setIsExporting: `${DRAFT_BOM_ITEMS}/setIsExporting`,
  setIsImporting: `${DRAFT_BOM_ITEMS}/setIsImporting`,
  showPartAndAllParents: `${DRAFT_BOM_ITEMS}/showPartAndAllParents`,
  updateDraftBomItem: `${DRAFT_BOM_ITEMS}/updateDraftBomItem`,
  updateDraftBomOrder: `${DRAFT_BOM_ITEMS}/updateDraftBomOrder`,

  // draftBom module
  getItemNumberMatches: `${DRAFT_BOM}/getItemNumberMatches`,
  addToSelected: `${DRAFT_BOM}/addToSelectedRows`,
  clearSelectedRows: `${DRAFT_BOM}/clearSelectedRows`,
  closeDraftBomDetailEditor: `${DRAFT_BOM}/closeDraftBomDetailEditor`,
  columnTitlesMap: `${DRAFT_BOM}/columnTitlesMap`,
  decrementCurrentSearchResult: `${DRAFT_BOM}/decrementCurrentSearchResult`,
  hasDetailEditorOpen: `${DRAFT_BOM}/hasDetailEditorOpen`,
  incrementCurrentSearchResult: `${DRAFT_BOM}/incrementCurrentSearchResult`,
  refKeyToVisibleListIdx: `${DRAFT_BOM}/refKeyToVisibleListIdx`,
  removeSelectedRow: `${DRAFT_BOM}/removeSelectedRow`,
  resetSearch: `${DRAFT_BOM}/resetSearch`,
  search: `${DRAFT_BOM}/search`,
  selectBomByArrowKeyDirection: `${DRAFT_BOM}/selectBomByArrowKeyDirection`,
  selectedIndicesMap: `${DRAFT_BOM}/selectedIndicesMap`,
  setBomNodeToDelete: `${DRAFT_BOM}/setBomNodeToDelete`,
  setCellInEditMode: `${DRAFT_BOM}/setCellInEditMode`,
  setIsDraftBomUpdate: `${DRAFT_BOM}/setIsDraftBomUpdate`,
  setIsUpdating: `${DRAFT_BOM}/setIsUpdating`,
  setMappedColumnTitles: `${DRAFT_BOM}/setMappedColumnTitles`,
  setPartInDetailEditor: `${DRAFT_BOM}/setPartInDetailEditor`,
  setSearchSuggestions: `${DRAFT_BOM}/setSearchSuggestions`,
  setSelectedRow: `${DRAFT_BOM}/setSelectedRow`,
  setShowDeleteModal: `${DRAFT_BOM}/setShowDeleteModal`,
  tabOverEditMode: `${DRAFT_BOM}/tabOverEditMode`,
  tabBackward: `${DRAFT_BOM}/tabBackward`,
  updateMappedColumnTitle: `${DRAFT_BOM}/updateMappedColumnTitle`,
  visibleRowsCount: `${DRAFT_BOM}/visibleRowsCount`,
  willAddRowAtBottom: `${DRAFT_BOM}/willAddRowAtBottom`,

  // draftTags module
  addTags: `${DRAFT_TAGS}/addTags`,
  checkedTagsByType: `${DRAFT_TAGS}/checkedTagsByType`,
  deleteTags: `${DRAFT_TAGS}/deleteTags`,
  editTags: `${DRAFT_TAGS}/editTags`,
  hasCheckedPagePartTags: `${DRAFT_TAGS}/hasCheckedPagePartTags`,
  hasCheckedPartTags: `${DRAFT_TAGS}/hasCheckedPartTags`,
  hasCheckedTags: `${DRAFT_TAGS}/hasCheckedTags`,
  hasCheckedTagsByType: `${DRAFT_TAGS}/hasCheckedTagsByType`,
  loadDraftTags: `${DRAFT_TAGS}/loadDraftTags`,
  pagePartTags: `${DRAFT_TAGS}/pagePartTags`,
  pagePartTagValues: `${DRAFT_TAGS}/pagePartTagValues`,
  partTags: `${DRAFT_TAGS}/partTags`,
  partTagValues: `${DRAFT_TAGS}/partTagValues`,
  setCheckedTags: `${DRAFT_TAGS}/setCheckedTags`,
  partTagValuesForModal: `${DRAFT_TAGS}/partTagValuesForModal`,
  pagePartTagValuesForModal: `${DRAFT_TAGS}/pagePartTagValuesForModal`,
  tagIdToName: `${DRAFT_TAGS}/tagIdToName`,

  // draftHPLs module
  deleteHotpointLinks: `${DRAFT_HPLS}/deleteHotpointLinks`,
  getDraftHotpointLinks: `${DRAFT_HPLS}/getDraftHotpointLinks`,
  hasCheckedHotpointLinks: `${DRAFT_HPLS}/hasCheckedHotpointLinks`,
  setCheckedHotpointLinks: `${DRAFT_HPLS}/setCheckedHotpointLinks`
})

export enum BomListEmits {
  updateVisibleList = 'update:visibleList',
  syncToMasterList = 'syncToMasterList',
  scroll = 'scroll:list',
  isSelectingDD = 'isSelectingDD'
}

export enum DropZoneClasses {
  above = 'aboveClasses',
  into = 'intoClasses',
  below = 'belowClasses'
}

export enum BomDataType {
  part = "part",
  pagePart = "pagePart"
}
