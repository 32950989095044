<template>
  <main
    v-if="hasAccessToOrders"
    class="orders-container"
  >
    <h1
      class="title is-size-4 is-marginless"
      data-unit="orders-title"
    >
      {{ $tc('order', 2) }}
    </h1>
    <orders-filter-bar data-unit="orders-filter-bar" />
    <section
      v-if="items.length"
      class="orders-results"
    >
      <table
        class="table is-fullwidth"
        data-unit="orders-table"
      >
        <thead>
          <tr class="has-background-light">
            <th
              v-for="col in columns"
              :key="col.key"
              class="has-pointer"
              @click="sortHeaderClicked(col.key)"
            >
              <span>{{ translated(col.name) }}</span>
              <template v-if="sortingContains(col.key, 'asc')">
                <i class="fa fa-sm fa-sort-up" />
                <sup>{{ sortingPosition(col.key, 'asc') }}</sup>
              </template>
              <template v-else-if="sortingContains(col.key, 'dsc')">
                <i class="fa fa-sm fa-sort-down" />
                <sub>{{ sortingPosition(col.key, 'dsc') }}</sub>
              </template>
              <template v-else>
                <i class="fa fa-sm fa-sort has-text-grey-light" />
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="result in items"
            :key="result.id"
          >
            <td
              v-for="col in columns"
              :key="col.key"
            >
              <router-link
                v-if="col.key === 'orderNumber'"
                :to="{ name: 'Order', params: { id: result.id } }"
              >
                {{ result[col.key] }}
              </router-link>
              <template v-else-if="col.key === 'dateModified'">
                {{ $d(result.dateModified, 'long') }}
              </template>
              <template v-else-if="col.key === 'status'">
                {{ translateStatus(result.status) }}
              </template>
              <template v-else-if="col.key === 'type'">
                {{ translated('%%' + result[col.key]) }}
              </template>
              <template v-else>
                {{ result[col.key] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section
      class="no-data"
      v-else
    >
      <h1 class="subtitle has-text-centered">
        {{ $t('assetsNotFound', {type: $tc('order', 2)}) }}
      </h1>
    </section>
    <pagination v-if="items.length"
                :offset="offset"
                :limit="limit"
                :total="total"
                @changePage="changePage"
                @changePerPage="changePerPage"/>
  </main>
  <Error v-else />
</template>

<script>
import Pagination from '@/components/common/Pagination'
import OrdersFilterBar from './OrdersFilterBar'
import Error from '@/components/Error'
import { mapState, mapGetters, mapActions } from 'vuex'
import { postError } from '@/helpers/notification'

export default {
  name: 'Orders',
  components: {
    Pagination,
    OrdersFilterBar,
    Error
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        if (this.filtersLoaded) {
          this.updateSelectedFilters()
        }
        this.scrollToTop()
      }
    },
    filters (newFilter, oldFilter) {
      if (newFilter !== oldFilter) {
        this.updateSelectedFilters()
      }
    },
    offset () {
      this.scrollToTop()
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.orders.items,
      total: (state) => state.orders.total,
      offset: (state) => state.orders.offset,
      filters: (state) => state.orders.filters.items,
      filtersLoaded: (state) => state.orders.filters.isLoaded,
      columns: (state) => state.orders.columns,
      sorting: (state) => state.orders.sorting,
      limit: (state) => state.orders.limit,
    }),
    ...mapGetters({
      hasAccessToOrders: 'user/hasAccessToOrders'
    })
  },
  methods: {
    ...mapActions({
      getFilters: 'orders/getFilters',
      getOrderHistoryColumns: 'orders/getOrderHistoryColumns',
      setSelectedFiltersAndOptions: 'orders/setSelectedFiltersAndOptions',
      search: 'orders/search',
      toggleSortHeader: 'orders/toggleSortHeader',
      backToFirstPage: 'orders/backToFirstPage',
      changePage: 'orders/setOrdersPage',
      changePerPage: 'orders/setOrderResultsPerPage'
    }),
    scrollToTop () {
      const results = document.getElementsByClassName('orders-results')
      if (results.length) {
        results[0].scrollTo(0, 0)
      }
    },
    open (order) {
      this.$emit('navigateToOrder', order)
    },
    translated (text) {
      if (text.indexOf('%%') === 0) {
        return this.$i18n.tc(text.replace(/%%/g, ''), 1)
      }
      return text
    },
    translateStatus (status) {
      switch (status) {
        case 'OPEN':
          return this.$i18n.t('open')
        case 'SUBMITTED':
          return this.$i18n.t('submitted')
        case 'CANCELLED':
          return this.$i18n.t('canceled')
        default: {
          return status
        }
      }
    },
    sortingContains (field, direction) {
      return this.sorting && this.sorting.indexOf(`${field}:${direction}`) !== -1
    },
    sortingPosition (field, direction) {
      if (!this.sorting) {
        return undefined
      }
      return this.sorting.indexOf(`${field}:${direction}`) + 1
    },
    sortHeaderClicked (field) {
      this.backToFirstPage()
      this.toggleSortHeader({ field })
    },
    updateSelectedFilters () {
      this.setSelectedFiltersAndOptions(this.$route.query)
      this.search()
    }
  },

  mounted () {
    document.title = this.$tc('order', 2)
    if (!this.filtersLoaded) {
      this.getFilters()
      this.getOrderHistoryColumns()
    }
    if (!this.hasAccessToOrders) {
      postError({
        title: this.$t('error'),
        text: this.$t('unauthorized'),
      })
    }
  }
}
</script>

<style scoped>
.orders-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-top: .5rem;
}

.title {
  padding-left: 1rem;
}

.orders-results {
  flex: 1;
  overflow: auto;
  border-top: lightgray 1px solid;
  border-bottom: lightgray 1px solid;
}

.no-data, .loading {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.has-pointer {
  cursor: pointer;
}

.has-pointer:hover span {
  text-decoration: underline;
}
</style>
