<template>
  <div class="control">
    <div
      :class="{'is-active': hasSuggestions}"
      class="dropdown"
    >
      <div class="dropdown-trigger">
        <input
          data-testid="filter-type-ahead-input"
          data-int="filter-type-ahead-input"
          v-model="query"
          autocomplete="off"
          :placeholder="name"
          class="filter-input input"
          @keydown.up="up"
          @keydown.down="down"
          @keyup.enter="enter"
        >
      </div>
      <div
        class="dropdown-menu"
        role="menu"
      >
        <div
          v-show="hasSuggestions"
          v-on-clickaway="close"
          class="dropdown-content"
        >
          <a
            v-for="(item, index) in suggestions"
            :key="item.id"
            data-testid="item"
            class="dropdown-item"
            :class="{'is-active': index === selectedSuggestionIndex}"
            @click="select(item)"
          >
            {{ item }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue3-click-away'
import debounce from 'lodash.debounce'

export default {
  name: 'FiltersTypeAhead',
  props: {
    propertyField: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    }
  },
  directives: {
    onClickaway
  },
  data () {
    return {
      input: this.value || '',
      suggestions: [],
      suggestionsOpen: false,
      selectedSuggestionIndex: -1,
      populateSuggestionsDebouncer: debounce(() => this.populateSuggestionsImmediate(), 500)
    }
  },
  watch: {
    value () {
      this.input = this.value || ''
    }
  },
  computed: {
    query: {
      get () {
        return this.input
      },
      set (value) {
        if (this.input !== value) {
          this.input = value

          if (this.input.length > 0) {
            this.populateSuggestions(this)
          } else {
            this.suggestions = []
          }
          this.$emit('change', this.input)
          this.suggestionsOpen = true
        }
      }
    },
    hasSuggestions () {
      return this.suggestions.length > 0 && this.suggestionsOpen
    }
  },
  methods: {
    populateSuggestions () {
      this.populateSuggestionsDebouncer()
    },
    async populateSuggestionsImmediate () {
      this.suggestions = await this.$store.dispatch('search/searchSuggestionsWithReturn', {
        field: this.propertyField,
        q: this.input
      })
    },
    select (value) {
      this.query = value
      this.suggestionsOpen = false
    },
    up () {
      this.selectedSuggestionIndex = Math.max(-1, this.selectedSuggestionIndex - 1)
    },
    down () {
      this.selectedSuggestionIndex = Math.min(this.suggestions.length - 1, this.selectedSuggestionIndex + 1)
    },
    enter () {
      if (this.selectedSuggestionIndex >= 0) {
        this.query = this.suggestions[this.selectedSuggestionIndex]
        document.activeElement.blur()
        this.suggestionsOpen = false
      }
    },
    close () {
      this.suggestionsOpen = false
    }
  }
}
</script>

<style scoped>
filter-input {
  box-shadow: none;
}
</style>
