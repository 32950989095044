import * as types from './mutations-types'

export default {

  [types.SET_REF_ID] (state, id) {
    state.refId = id
  },

  [types.DATA_REQUEST] (state) {
    Object.assign(state, { loading: true, error: null })
  },

  [types.CREATE_REQUEST] (state) {
    Object.assign(state, { loading: true, error: null })
  },
  [types.DELETE_REQUEST] (state) {
    Object.assign(state, { loading: true, error: null })
  },
  [types.UPDATE_REQUEST] (state) {
    Object.assign(state, { loading: true, error: null })
  },

  [types.DATA_LOADING] (state, status = true) {
    Object.assign(state, { loading: status, error: null })
  },

  [types.DATA_LOADING] (state, status) {
    Object.assign(state, { loading: status, error: null })
  },
  [types.UPDATE_LOADING] (state, status) {
    Object.assign(state, { loading: status, error: null })
  },
  [types.DELETE_LOADING] (state, status) {
    Object.assign(state, { loading: status, error: null })
  },
  [types.CREATE_LOADING] (state, status) {
    Object.assign(state, { loading: status, error: null })
  },

  [types.DATA_SUCCESS] (state, payload) {
    Object.assign(state, { loading: false, data: payload })
  },

  [types.UPDATE_SUCCESS] (state, payload) {
    Object.assign(state, { loading: false, data: payload })
  },
  [types.DELETE_SUCCESS] (state, payload) {
    Object.assign(state, { loading: false, data: payload })
  },
  [types.CREATE_SUCCESS] (state, payload) {
    Object.assign(state, { loading: false, data: payload })
  },

  [types.DATA_ERROR] (state, payload) {
    Object.assign(state, {
      loading: false,
      error: payload || true
    })
  },
  [types.SET_PAGINATION] (state, payload) {
    Object.assign(state.pagination, payload)
  },
  [types.RESET_PAGINATION] (state, payload) {
    Object.assign(state.pagination, {
      count: 0,
      limit: 0,
      offset: 0,
      pageCount: 0,
      currentPage: 0
    })
  },
  [types.DATA_RESET] (state) {
    Object.assign(state, {
      loading: false,
      data: null,
      error: false
    })
  }
}
