import http from '@/http';

import banners from './banners';
import branding from './branding';
import taxonomy from './taxonomy';

const ORGANIZATION_TREE_LOADING = 'ORGANIZATION_TREE_LOADING';
const ORGANIZATION_TREE_RECEIVED = 'ORGANIZATION_TREE_RECEIVED';
const ORGANIZATION_TREE_PROPERTY_RECEIVED = 'ORGANIZATION_TREE_PROPERTY_RECEIVED';
const CONTEXT_SELECT = 'CONTEXT_SELECT';

const state = {
  hasDefinedProperty: '',
  organizations: [],
  organizationsLoaded: false,
  selected: null
};

const getters = {
  organizationTreeWithTenant: (state, getters, rootState) => [{
    id: rootState.user.tenantKey,
    name: rootState.user.tenantKey,
    isTenant: true,
    childOrganizations: state.organizations
  }]
};

const actions = {
  async getOrganizationTree({commit, rootState}) {
    try {
      commit(ORGANIZATION_TREE_LOADING);
      const {data} = await http.get('organizations/tree');
      commit(ORGANIZATION_TREE_RECEIVED, {organizations: data});

      if (rootState.user.accessPrivileges.indexOf('TENANT_ADMINISTRATION') >= 0) {
        commit(CONTEXT_SELECT, {
          id: rootState.user.tenantKey,
          name: rootState.user.tenantKey,
          isTenant: true
        });
      } else if (rootState.user.accessPrivileges.indexOf('ADMINISTRATION') >= 0 && data.length) {
        commit(CONTEXT_SELECT, data[0]);
      }
    } catch (err) {
      // na
    }
  },
  selectContext({commit}, item) {
    commit(CONTEXT_SELECT, item);
  },
  setOrganizationTreeProperty({commit}, property) {
    commit(ORGANIZATION_TREE_PROPERTY_RECEIVED, {property});
  }
};

const mutations = {
  [ORGANIZATION_TREE_RECEIVED](state, {organizations}) {
    state.organizations = organizations;
    state.hasDefinedProperty = '';
    state.organizationsLoaded = true;
  },
  [ORGANIZATION_TREE_LOADING](state) {
    state.organizationsLoaded = false;
  },
  [ORGANIZATION_TREE_PROPERTY_RECEIVED](state, {property}) {
    state.hasDefinedProperty = property;
  },
  [CONTEXT_SELECT](state, item) {
    state.selected = item;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    banners,
    branding,
    taxonomy
  }
};
