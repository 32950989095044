<template>
  <div class="modal is-active">
    <div class="modal-background"/>
    <div data-int="export-modal" class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <strong data-int="export-modal-title">{{ $t('exportSearch') }}</strong>
        </p>
        <button data-int="export-modal-cancel" class="delete" aria-label="cancel" @click="cancel"/>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <div class="columns is-multiline is-mobile">
            <div class="column is-half">
              <div>
                <label class="label column-label">{{ $t('size') }}</label>
                <div>
                  <div class="select is-fullwidth">
                    <select v-model="size">
                      <option v-for="size in pageSizes" :key="size.id" :value="size.id">
                        {{ size.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div>
                <label class="label column-label"> {{ $t('orientation') }} </label>
                <div>
                  <div class="control" @click="orientation = 'PORTRAIT'">
                    <input type="radio" :checked="orientation === 'PORTRAIT'" class="is-checkradio"/>
                    <label class="radio">{{ $t('portrait') }} </label>
                  </div>
                </div>
                <div>
                  <div class="control" @click="orientation = 'LANDSCAPE'">
                    <input type="radio" :checked="orientation === 'LANDSCAPE'" class="is-checkradio"/>
                    <label class="radio">{{ $t('landscape') }} </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-half">
              <div>
                <div class="control">
                  <label class="label column-label"> {{ $t('locale') }} </label>
                  <div class="select is-fullwidth">
                    <select v-model="locale">
                      <option v-for="locale in locales" :key="locale.id" :value="locale">
                        {{ locale.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click="submit" >
          {{ $t('submit') }}
        </button>
        <button class="button" @click="cancel">
          {{ $t('cancel') }}
        </button>
        <router-link to="/job-manager" exact data-int="job-manager-link" class="button">
          <span>{{ $t('goToJobManager') }}</span>
          <span class="icon">
            <i class="fa fa-long-arrow-alt-right"/>
          </span>
        </router-link>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  name: 'SearchExportModal',
  props: {
    book: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      locale: undefined,
      size: undefined,
      includeMedia: true,
      includeBooks: true,
      orientation: undefined,
      pageSizes: [
        {id: 'LETTER', name: this.$i18n.t('letter')},
        {id: 'LEGAL', name: this.$i18n.t('legal')},
        {id: 'TABLOID', name: this.$i18n.t('tabloid')},
        {id: 'LEDGER', name: this.$i18n.t('ledger')},
        {id: 'A3', name: 'A3'},
        {id: 'A4', name: 'A4'},
        {id: 'A5', name: 'A5'}
      ]
    };
  },
  watch: {
    locales() {
      this.init();
    },
    content() {
      this.init();
    }
  },
  computed: {
    ...mapState({
      locales: (state) => state.locales.items,
      searchTotal: (state) => state.search.total
    })
  },
  methods: {
    ...mapActions({
      exportSearchContent: 'content/exportSearchContent',
      getLocales: 'locales/getLocales'
    }),
    init() {
      this.size = this.pageSizes[0].id;
      this.orientation = 'PORTRAIT';

      if (this.locales) {
        this.locale = this.locales.find((locale) => locale.code4 === this.$i18n.locale);
      }
    },
    async submit() {
      const payload = {
        orientation: this.orientation,
        pageSize: this.size,
        locale: this.locale.code4
      };
      const route = this.$route;
      await this.exportSearchContent({ route, payload });
      this.cancel();
    },
    cancel() {
      this.$emit('cancel');
    }
  },
  async created() {
    await this.getLocales();
  },
  async mounted() {
    this.init();
  },
};
</script>


<style scoped>
.modal-card-body {
  flex-direction: column;
  padding-top: 10px;
}
.column {
  padding-top: .1rem;
  padding-bottom: .1rem;
}
.options {
  padding-bottom: .75rem;
}
.column-label {
  padding-top: .5rem;
}
.button {
  margin-right: .5rem;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .modal-card {
    height: 40rem !important;
  }
}
@media only screen and (max-width: 769px) {
  .modal-card {
    width: 95%;
  }
}
</style>
