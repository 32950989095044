const accessControlLevels = {
  all: {
    value: {global: true},
    label: 'All Users'
  },
  myOrg: {
    value: {global: false, publicBelowOrg: false},
    label: 'My Organization Only'
  },
  myOrgAndChildren: {
    value: {global: false, publicBelowOrg: true},
    label: 'My Organization and All Below'
  }
};

function readFromServer(comment) {
  const acl = comment.global ? 'all'
      : comment.publicBelowOrg ? 'myOrgAndChildren'
      : 'myOrg';

  return {
    ...comment,
    global: undefined,
    publicBelowOrg: undefined,
    acl
  };
}

/* eslint-disable no-unused-vars */
function writeToServer(comment) {
  return {
    ...comment,
    acl: undefined,
    ...accessControlLevels[comment.acl].value
  };
}

export {writeToServer, readFromServer, accessControlLevels};
