import http from '@/http'

export async function getMediaByIdentifier(identifer: string) {
  const encoded = encodeURIComponent(identifer)
  const { data } = await http.get(`/medias/by-identifier/${encoded}`)
  return data
}

export async function getMediaById(id: number) {
  const { data } = await http.get(`/medias/${id}`)
  return data
}