enum MediaIdTypes {
    mediaId = 'mediaId',
    pageId = 'pageId',
    partId = 'partId',
    chapterId = 'chapterId',
    bulletinId = 'bulletinId',
    pagePartId = 'pagePartId'
}

enum UserAction {
  VIEW_MEDIA = 0,
  VIEW_PAGE = 1,
  VIEW_CHAPTER = 2,
  VIEW_BULLETIN = 3,
  VIEW_PRODUCT = 4,
  VIEW_PART = 5
}

export enum MediaTypes {
    book = 'book',
    bulletin = 'bulletin',
    chapter = 'chapter',
    document = 'document',
    external = 'external',
    image = 'image',
    microsite = 'microsite',
    page = 'page',
    part = 'part',
    video = 'video'
}

export interface UserActionMap extends Record<string, number|undefined> {
    userAction?: number,
    [MediaIdTypes.mediaId]?: number,
    [MediaIdTypes.pageId]?: number,
    [MediaIdTypes.partId]?: number,
    [MediaIdTypes.bulletinId]?: number,
    [MediaIdTypes.chapterId]?: number,
    [MediaIdTypes.pagePartId]?: number
}

export const Content: Record<string, number> = Object.freeze({
  [MediaTypes.book]: UserAction.VIEW_MEDIA,
  [MediaTypes.bulletin]: UserAction.VIEW_BULLETIN,
  [MediaTypes.document]: UserAction.VIEW_MEDIA,
  [MediaTypes.chapter]: UserAction.VIEW_CHAPTER,
  [MediaTypes.external]: UserAction.VIEW_MEDIA,
  [MediaTypes.image]: UserAction.VIEW_MEDIA,
  [MediaTypes.microsite]: UserAction.VIEW_MEDIA,
  [MediaTypes.page]: UserAction.VIEW_PAGE,
  [MediaTypes.part]: UserAction.VIEW_PART,
  [MediaTypes.video]: UserAction.VIEW_MEDIA
})

export const MediaTypesToIds: Record<string, string> = Object.freeze({
  [MediaTypes.book]: MediaIdTypes.mediaId,
  [MediaTypes.bulletin]: MediaIdTypes.bulletinId,
  [MediaTypes.chapter]: MediaIdTypes.chapterId,
  [MediaTypes.document]: MediaIdTypes.mediaId,
  [MediaTypes.external]: MediaIdTypes.mediaId,
  [MediaTypes.image]: MediaIdTypes.mediaId,
  [MediaTypes.microsite]: MediaIdTypes.mediaId,
  [MediaTypes.page]: MediaIdTypes.pageId,
  [MediaTypes.part]: MediaIdTypes.partId,
  [MediaTypes.video]: MediaIdTypes.mediaId
})
