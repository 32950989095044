export interface PrintSetting {
  alternatePartRowColors: boolean
  autoRotateImages: boolean
  bottomMargin: number
  created: Date
  excludePartCodesFromPartsLists: boolean
  hideDuplicatePages: boolean
  id: number
  includeComments: boolean
  includeCommentsAuthorAndDate: boolean
  includeImageBorder: boolean
  includeMiniTocForHiddenDuplicatePages: boolean
  includePagesIndex: boolean
  includePartsIndex: boolean
  keepBomOnRight: boolean
  keepImagesOnLeft: boolean
  printHotpointsOnImages: boolean
  leftMargin: number
  marginUnit: number // actually an enum
  name: string
  numEndPages: number
  pageLayout: number // actually an enum
  rightMargin: number
  updated: Date
  useAsDefault: boolean
  useSequentialPageNumbering: boolean
  version: number
  overrideNamingStandard: boolean
  namingStandardType: string
}

export enum ApplicableFontApplication {
  CHAPTER_HEADER,
  PAGE_HEADER,
  PARTS_LIST_GRID_HEADER,
  PARTS_LIST_DATA
}

export enum PrintSettingCoverStitchApplication {
  FRONT_COVER = 'FRONT_COVER',
  FRONT_STITCH = 'FRONT_STITCH',
  BACK_COVER = 'BACK_COVER',
  BACK_STITCH = 'BACK_STITCH'
}

export interface CreatePrintSettingPartColumnDto {
  columnWidth: number
  displayOrder: number
  tagNameId?:number
  tenantPrintSettingId: number
}

export interface PrintSettingPartColumn {
  columnWidth: number
  created:Date
  displayOrder: number
  id: number
  tagNameId?: number
  tagName?: any // need TagName interface
  tenantPrintSettingId: number
  updated: Date
  version: number
}
