export default {
  install (Vue) {
    Vue.mixin({
      /*
       * WARNING: Vue3 dropped filters; translate method is a replacement
       * Ref. vue documentation for more: https://v3.vuejs.org/guide/migration/filters.html#overview
       */
      filters: {
        otherwise (original, fallback) {
          return original || fallback
        },
        translate (key, $t, $tc) {
          const tr = $t(key)
          if (tr.indexOf('|') !== -1) {
            return $tc(key, 1)
          }
          return tr || key
        }
      },
      methods: {
        translate (key) {
          const tr = this.$t(key)
          if (tr.indexOf('|') !== -1) {
            return this.$tc(key, 1)
          }
          return tr || key
        }
      }
    })
  }
}
