import { AxiosProgressEvent } from 'axios'

type UploadProgress = {
  elapsedTime: number|null, // seconds
  percentage: number,
}

/**
 * Common computation to support progress bar visuals. `progressEvent` comes
 * from within the Axios POST request call. Provide startTime (`Date.now()`)
 * if you wish to track elapsed time.
 *
 * @param progressEvent
 * @param startTime
 */
export function useUploadProgressComputation (
  progressEvent: AxiosProgressEvent,
  startTime?: number,
): UploadProgress {
  const totalBytes = progressEvent.total ?? 1
  const bytesUploaded = progressEvent.loaded

  let elapsedTime = null
  if (!!startTime) {
    const currentTime = Date.now()
    elapsedTime = (currentTime - startTime) / 1000
  }

  return {
    elapsedTime,
    percentage: Math.round(bytesUploaded / totalBytes * 100)
  }
}
