<template>
  <section class="section" v-if="shelf">
    <h4 data-int="shelf-section" class="title is-4">
      <router-link :to="{name: 'FavoritesItem', params: {id: shelfId}}">
        {{ shelf.name }}
      </router-link>
    </h4>
    <div class="columns is-mobile" :class="{'is-multiline': showMore}" v-if="!!headItems.length">
      <div v-for="(item, index) in headItems"
           :key="index"
           class="column is-2-desktop is-3-tablet is-4-mobile">
        <card data-int="shelf-item" :item="item" @click="select(item)" />
      </div>
    </div>
    <div v-else>
      {{ $t('noItemsFound') }}
    </div>
  </section>
</template>

<script>
import http from '@/http';
import Card from '@/components/common/Card';
import { mapActions } from "vuex";

export default {
  components: {Card},
  props: {
    shelfId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      showMore: false,
      shelf: null,
      items: []
    };
  },
  created() {
    this.fetchShelf();
    this.fetchShelfItems();
  },
  computed: {
    headItems() {
      return this.items.slice(0, 6);
    }
  },
  methods: {
    ...mapActions({
      resetBrowseState: 'browse/resetBrowseState'
    }),
    async fetchShelf() {
      try {
        const {data} = await http.get(`media-shelves/${this.shelfId}`);
        this.shelf = data;
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    async fetchShelfItems() {
      try {
        const {data} = await http.get(`media-shelves/${this.shelfId}/items`);
        this.items = data;
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    async select(item) {
      await this.resetBrowseState()
      this.$router.push({
        path: `/${item.mediaType}/${item.entityId}`
      });
    }
  }
};
</script>

<style scoped>
.section {
  padding: 0.5rem 1rem 1rem 1rem;
  height: auto;
  overflow: hidden;
  border-bottom: lightgray 1px solid;
}

.is-pulled-right {
  align-items: right;
}
</style>
