import { ActionContext, GetterTree, MutationTree } from 'vuex'
import {
  CuttingPlaneType,
  HoopsConfigs,
  HoopsModelProperties,
  HoopsModelView,
  HoopsModelViewOptions,
  HoopsPerspective,
  HoopsPerspectiveType,
  HoopsWebViewer,
  projectionDefault,
  toProjectionStringValue,
  toViewOrientationStringValue,
} from '@/plugins/hoops'
import { cloneDeep } from 'lodash'
import { DrawMode, Projection, ViewOrientation } from '@/plugins/hoops/types/web-viewer'

class Types {
  static CHANGE_EXPLODE_MAGNITUDE = 'CHANGE_EXPLODE_MAGNITUDE'
  static CHANGE_DRAW_MODE = 'CHANGE_DRAW_MODE'
  static CHANGE_MODEL_VIEW = 'CHANGE_MODEL_VIEW'
  static CHANGE_PERSPECTIVE = 'CHANGE_PERSPECTIVE'
  static CLEAR_HOOPS_VIEWER = 'CLEAR_HOOPS_VIEWER'
  static CREATE_HOOPS_VIEWER = 'CREATE_HOOPS_VIEWER'
  static MARK_VIEWER_AS_READY = 'MARK_VIEWER_AS_READY'
  /**
   * Internal helper to persist a value during UPDATE_STATE_AFTER_CAMERA_CHANGE,
   */
  static PERSIST_TOOLBAR_ACTION = 'PERSIST_TOOLBAR_ACTION'
  static REMOVAL_ALL_CUTTING_PLANES = 'REMOVE_ALL_CUTTING_PLANES'
  static TOGGLE_CUTTING_PLANE_SECTION = 'TOGGLE_CUTTING_PLANE_SECTION'
  static TOGGLE_CUTTING_PLANE_VISIBILITY = 'TOGGLE_CUTTING_PLANE_VISIBILITY'
  static TOGGLE_CUTTING_PLANE_X = 'TOGGLE_CUTTING_PLANE_X'
  static TOGGLE_CUTTING_PLANE_Y = 'TOGGLE_CUTTING_PLANE_Y'
  static TOGGLE_CUTTING_PLANE_Z = 'TOGGLE_CUTTING_PLANE_Z'
  /**
   * Internal watcher to reset applicable state(s) when camera changes,
   * such as user panning clears orientation or model view.
   */
  static UPDATE_STATE_AFTER_CAMERA_CHANGE = 'UPDATE_STATE_AFTER_CAMERA_CHANGE'
}

/**
 * Limited subset of toolbar actions that needs to hold state when fired.
 * `UPDATE_STATE_AFTER_CAMERA_CHANGE` watch camera changes and can
 * clear state of applicable variables.
 */
enum PersistingToolbarAction {
  MODEL_VIEW,
  ORIENTATION,
  PROJECTION
}

/**
 * In respective order to click states: OFF > POSITIVE > NEGATIVE > (repeat)
 */
export enum CuttingPlaneState {
  OFF = 0,
  POSITIVE = 1,
  NEGATIVE = -1,
}

const state: HoopsState = {
  cuttingPlaneSectionEnabled: false, // Opposite of default
  cuttingPlaneVisibilityEnabled: false,
  cuttingPlaneXState: CuttingPlaneState.OFF,
  cuttingPlaneYState: CuttingPlaneState.OFF,
  cuttingPlaneZState: CuttingPlaneState.OFF,
  drawMode: DrawMode.WireframeOnShaded,
  explodeMagnitude: 0,
  isViewerReady: false,
  modelProperties: null,
  modelViewOptions: null,
  modelViewSelected: null,
  persistingToolbarAction: null,
  perspective: { projection: <Projection>projectionDefault.value, viewOrientation: null },
  viewer: null,
}

type Context = ActionContext<HoopsState, any>

interface HoopsState {
  cuttingPlaneSectionEnabled: boolean,
  cuttingPlaneVisibilityEnabled: boolean,
  cuttingPlaneXState: CuttingPlaneState,
  cuttingPlaneYState: CuttingPlaneState,
  cuttingPlaneZState: CuttingPlaneState,
  drawMode: DrawMode,
  explodeMagnitude: number,
  isViewerReady: boolean,
  modelProperties: HoopsModelProperties | null,
  modelViewOptions: HoopsModelViewOptions | null,
  modelViewSelected: HoopsModelView | null,
  perspective: { projection: Projection, viewOrientation: ViewOrientation | null },
  /**
   * Helps distinguish camera changes between the toolbar and
   * user interaction (drag, pan, pinch, etc.)
   */
  persistingToolbarAction: PersistingToolbarAction | null,
  viewer: HoopsWebViewer | null,
}

const getters: GetterTree<HoopsState, any> = {
  cuttingPlaneSectionEnabled: (state: HoopsState) => state.cuttingPlaneSectionEnabled,
  cuttingPlaneVisibilityEnabled: (state: HoopsState) => state.cuttingPlaneVisibilityEnabled,
  cuttingPlaneXState: (state: HoopsState) => state.cuttingPlaneXState,
  cuttingPlaneYState: (state: HoopsState) => state.cuttingPlaneYState,
  cuttingPlaneZState: (state: HoopsState) => state.cuttingPlaneZState,
  cuttingPlanesCount: (state: HoopsState) => {
    return [
      state.cuttingPlaneXState,
      state.cuttingPlaneYState,
      state.cuttingPlaneZState
    ].filter((item) => item !== CuttingPlaneState.OFF).length
  },
  currentDrawMode: (state: HoopsState) => state.drawMode,
  currentExplodeMagnitude: (state: HoopsState) => state.explodeMagnitude,
  currentModelView: (state: HoopsState) => state.modelViewSelected,
  currentOrientation: (state: HoopsState) => {
    return state.perspective.viewOrientation != null ?
      toViewOrientationStringValue(state.perspective.viewOrientation) :
      null
  },
  currentProjection: (state: HoopsState) => toProjectionStringValue(state.perspective.projection),
  currentViewerInstance: (state: HoopsState) => state.viewer,
  isViewerReady: (state: HoopsState) => state.isViewerReady,
  modelProperties: (state: HoopsState) => cloneDeep(state.modelProperties),
  modelViewOptions: (state: HoopsState) => state.modelViewOptions ?? [],
}

const actions = {
  captureSnapshot (context: Context) {
    state.viewer?.captureSnapshot()
  },
  changeDrawMode (context: Context, mode: DrawMode) {
    state.viewer?.changeDrawMode(mode)
    context.commit(Types.CHANGE_DRAW_MODE, mode)
  },
  async changeExplodeMagnitude (context: Context, magnitude: number) {
    await state.viewer?.changeExplode(magnitude)
    context.commit(Types.CHANGE_EXPLODE_MAGNITUDE, magnitude)
  },
  async changeModelView (context: Context, modelView: HoopsModelView) {
    context.commit(Types.PERSIST_TOOLBAR_ACTION, PersistingToolbarAction.MODEL_VIEW)
    await state.viewer?.changeModelView(modelView)
    context.commit(Types.CHANGE_MODEL_VIEW, modelView)
  },
  async changePerspective (context: Context, perspective: HoopsPerspective) {
    switch (perspective.type) {
      case HoopsPerspectiveType.Projection:
        context.commit(Types.PERSIST_TOOLBAR_ACTION, PersistingToolbarAction.PROJECTION)
        await state.viewer?.changeProjection(<Projection>perspective.value)
        break
      case HoopsPerspectiveType.ViewOrientation:
        context.commit(Types.PERSIST_TOOLBAR_ACTION, PersistingToolbarAction.ORIENTATION)
        await state.viewer?.changeViewOrientation(<ViewOrientation>perspective.value)
        break
    }
    context.commit(Types.CHANGE_PERSPECTIVE, perspective)
  },
  clearViewer (context: Context) {
    state.viewer?.onBeforeDestroy()
    context.commit(Types.CLEAR_HOOPS_VIEWER)
  },
  async createViewer (context: Context, configs: HoopsConfigs) {
    const viewer = new HoopsWebViewer(
      configs,
      () => context.dispatch('updateStateAfterCameraChange'),
      () => context.dispatch('markViewerAsReady'),
    )

    if (state.viewer !== null) {
      await context.dispatch('clearViewer')
    }

    context.commit(Types.CREATE_HOOPS_VIEWER, viewer)
  },
  markViewerAsReady (context: Context) {
    context.commit(Types.MARK_VIEWER_AS_READY)
  },
  async removeAllCuttingPlanes (context: Context) {
    await state.viewer?.removeAllCuttingPlanes()
    context.commit(Types.REMOVAL_ALL_CUTTING_PLANES)
  },
  async resetCameraView (context: Context) {
    await state.viewer?.resetCameraView()
    context.commit(Types.CHANGE_PERSPECTIVE, null)
    context.commit(Types.CHANGE_MODEL_VIEW, null)
  },
  async toggleCuttingPlaneSection (context: Context) {
    await state.viewer?.toggleCuttingPlaneSection()
    context.commit(Types.TOGGLE_CUTTING_PLANE_SECTION)
  },
  async toggleCuttingPlaneVisibility (context: Context) {
    await state.viewer?.toggleCuttingPlaneVisibility()
    context.commit(Types.TOGGLE_CUTTING_PLANE_VISIBILITY)
  },
  async toggleCuttingPlaneX (context: Context) {
    // Cycle: positive > negative > off
    switch (state.cuttingPlaneXState) {
      case CuttingPlaneState.NEGATIVE:
        await state.viewer?.removeCuttingPlane(CuttingPlaneType.PLANE_X)
        break
      default:
        await state.viewer?.activateCuttingPlane(CuttingPlaneType.PLANE_X)
    }
    context.commit(Types.TOGGLE_CUTTING_PLANE_X)
  },
  async toggleCuttingPlaneY (context: Context) {
    // Cycle: positive > negative > off
    switch (state.cuttingPlaneYState) {
      case CuttingPlaneState.NEGATIVE:
        await state.viewer?.removeCuttingPlane(CuttingPlaneType.PLANE_Y)
        break
      default:
        await state.viewer?.activateCuttingPlane(CuttingPlaneType.PLANE_Y)
    }
    context.commit(Types.TOGGLE_CUTTING_PLANE_Y)
  },
  async toggleCuttingPlaneZ (context: Context) {
    // Cycle: positive > negative > off
    switch (state.cuttingPlaneZState) {
      case CuttingPlaneState.NEGATIVE:
        await state.viewer?.removeCuttingPlane(CuttingPlaneType.PLANE_Z)
        break
      default:
        await state.viewer?.activateCuttingPlane(CuttingPlaneType.PLANE_Z)
    }
    context.commit(Types.TOGGLE_CUTTING_PLANE_Z)
  },
  /**
   * This is meant for internal usage. Updates certain state after the camera changes.
   * @param context
   */
  updateStateAfterCameraChange (context: Context) {
    context.commit(Types.UPDATE_STATE_AFTER_CAMERA_CHANGE)
  },
  zoomIn (context: Context) {
    state.viewer?.zoomIn()
  },
  zoomOut (context: Context) {
    state.viewer?.zoomOut()
  },
}

const mutations: MutationTree<HoopsState> = {
  [Types.CHANGE_DRAW_MODE] (state: HoopsState, mode: DrawMode) {
    state.drawMode = mode
    state.persistingToolbarAction = null
  },
  [Types.CHANGE_EXPLODE_MAGNITUDE] (state: HoopsState, magnitude: number) {
    state.explodeMagnitude = magnitude
  },
  [Types.CHANGE_MODEL_VIEW] (state: HoopsState, modelView: HoopsModelView) {
    state.modelViewSelected = modelView
    state.persistingToolbarAction = null
  },
  [Types.CHANGE_PERSPECTIVE] (state: HoopsState, perspective: HoopsPerspective|null) {
    switch (perspective?.type) {
      case HoopsPerspectiveType.Projection:
        state.perspective.projection = <Projection>perspective.value
        break
      case HoopsPerspectiveType.ViewOrientation:
        state.perspective.viewOrientation = <ViewOrientation>perspective.value
        break
      default:
        state.perspective.projection = <Projection>projectionDefault.value
        state.perspective.viewOrientation = null
    }
    state.persistingToolbarAction = null
  },
  [Types.CLEAR_HOOPS_VIEWER] (state: HoopsState) {
    state.cuttingPlaneSectionEnabled = false
    state.cuttingPlaneVisibilityEnabled = false
    state.cuttingPlaneXState = CuttingPlaneState.OFF
    state.cuttingPlaneYState = CuttingPlaneState.OFF
    state.cuttingPlaneZState = CuttingPlaneState.OFF
    state.drawMode = DrawMode.WireframeOnShaded
    state.explodeMagnitude = 0
    state.isViewerReady = false
    state.modelProperties = null
    state.modelViewOptions = null
    state.modelViewSelected = null
    state.perspective = { projection: <Projection>projectionDefault.value, viewOrientation: null }
    state.viewer = null
  },
  [Types.CREATE_HOOPS_VIEWER] (state: HoopsState, viewer: HoopsWebViewer) {
    state.viewer = viewer
  },
  [Types.PERSIST_TOOLBAR_ACTION] (state: HoopsState, toolbarAction: PersistingToolbarAction) {
    state.persistingToolbarAction = toolbarAction
  },
  [Types.MARK_VIEWER_AS_READY] (state: HoopsState) {
    state.isViewerReady = true
    state.modelProperties = state.viewer ? cloneDeep(state.viewer.modelProperties) : null
    state.modelViewOptions = state.viewer ? cloneDeep(state.viewer.modelViewOptions) : null
  },
  [Types.REMOVAL_ALL_CUTTING_PLANES] (state: HoopsState) {
    state.cuttingPlaneSectionEnabled = false
    state.cuttingPlaneVisibilityEnabled = false
    state.cuttingPlaneXState = CuttingPlaneState.OFF
    state.cuttingPlaneYState = CuttingPlaneState.OFF
    state.cuttingPlaneZState = CuttingPlaneState.OFF
  },
  [Types.TOGGLE_CUTTING_PLANE_SECTION] (state: HoopsState) {
    state.cuttingPlaneSectionEnabled = !state.cuttingPlaneSectionEnabled
  },
  [Types.TOGGLE_CUTTING_PLANE_VISIBILITY] (state: HoopsState) {
    state.cuttingPlaneVisibilityEnabled = !state.cuttingPlaneVisibilityEnabled
  },
  [Types.TOGGLE_CUTTING_PLANE_X] (state: HoopsState) {
    switch (state.cuttingPlaneXState) {
      // In click order
      case CuttingPlaneState.OFF:
        state.cuttingPlaneXState = CuttingPlaneState.POSITIVE
        break
      case CuttingPlaneState.POSITIVE:
        state.cuttingPlaneXState = CuttingPlaneState.NEGATIVE
        break
      case CuttingPlaneState.NEGATIVE:
        state.cuttingPlaneXState = CuttingPlaneState.OFF
        break
    }
  },
  [Types.TOGGLE_CUTTING_PLANE_Y] (state: HoopsState) {
    switch (state.cuttingPlaneYState) {
      // In click order
      case CuttingPlaneState.OFF:
        state.cuttingPlaneYState = CuttingPlaneState.POSITIVE
        break
      case CuttingPlaneState.POSITIVE:
        state.cuttingPlaneYState = CuttingPlaneState.NEGATIVE
        break
      case CuttingPlaneState.NEGATIVE:
        state.cuttingPlaneYState = CuttingPlaneState.OFF
        break
    }
  },
  [Types.TOGGLE_CUTTING_PLANE_Z] (state: HoopsState) {
    switch (state.cuttingPlaneZState) {
      // In click order
      case CuttingPlaneState.OFF:
        state.cuttingPlaneZState = CuttingPlaneState.POSITIVE
        break
      case CuttingPlaneState.POSITIVE:
        state.cuttingPlaneZState = CuttingPlaneState.NEGATIVE
        break
      case CuttingPlaneState.NEGATIVE:
        state.cuttingPlaneZState = CuttingPlaneState.OFF
        break
    }
  },
  [Types.UPDATE_STATE_AFTER_CAMERA_CHANGE] (state: HoopsState) {
    switch (state.persistingToolbarAction) {
      case PersistingToolbarAction.MODEL_VIEW:
        const projection = state.viewer?.hoopsWebViewer.view.getCamera().getProjection()
        state.perspective.projection = <Projection>projection
        state.perspective.viewOrientation = null
        break
      case PersistingToolbarAction.ORIENTATION:
      case PersistingToolbarAction.PROJECTION:
        state.modelViewSelected = null
        break
      default:
        state.modelViewSelected = null
        state.perspective.viewOrientation = null
    }
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}
