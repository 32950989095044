<template>
  <div class="card" @click="click">
    <div class="card-image">
      <thumbnail
        :thumbnail-url="item.thumbnailUrl"
        :thumbnail-type="entityType"
        data-int="card-thumbnail"
        class="is-square"
      />
    </div>
    <div class="card-content">
      <div class="content">
        {{ item.uri }}
        <p data-int="card-name"
           class="has-text-centered truncate is-marginless"
           data-toggle="tooltip"
           :title="item.name">
          <template v-if="isWidget && item.uri">
            <a  class="has-text-link">
              <strong>{{ item.name }}</strong>
            </a>
          </template>
          <template v-else-if="isWidget && item.contentUrl">
            <p>
              <strong class="has-text-link">{{ item.name }}</strong>
            </p>
          </template>
          <template v-else-if="!!item.type">
            <strong class="has-text-link">{{ item.name }}</strong>
          </template>
          <template v-else>
            <a :href="item.contentUrl" target="_blank">
              <strong>{{ item.name }}</strong>
            </a>
          </template>
        </p>
        <p data-int="card-identifier"
           class="subtitle is-size-6 has-text-centered truncate-subtitle"
           data-toggle="tooltip"
           :title="item.identifier">
          {{ item.identifier }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Thumbnail from '@/components/common/Thumbnail';

export default {
  name: 'Card',
  emits: ['navToWidget', 'click'],
  components: {
    Thumbnail
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    isWidget: Boolean
  },
  computed: {
    uri() {
      if (this.entityType && (this.item.entityId || this.item.id)) {
        return `/${this.entityType}/${this.item.entityId || this.item.id}`;
      }
      return '';
    },
    entityType() {
      return (
        this.item.contentType || this.item.mediaType || this.item.entityType
      );
    }
  },
  methods: {
    click() {
      if (this.isWidget) {
        this.$emit('navToWidget', this.item)
      } 
      else if (this.uri.length) {
        this.$emit('click', this.item)
      } 
      else {
        window.open(this.item.contentUrl, '_blank');
      }
    }
  }
};
</script>

<style scoped>
.card {
  height: 100%;
  max-width: 200px;
  cursor: pointer;
}
.card-content {
  padding: 0.5rem;
  line-height: normal;
}
.card-image {
  padding: 1rem;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  min-height: 2rem;
}
.truncate-subtitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
}
</style>
