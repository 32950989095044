import http from '@/http'
import { APIKeyDTO } from '@/interfaces/admin'

const WIDGET_KEY = '/widget-key'

export const getWidgetKeys = async function(): Promise<APIKeyDTO[]> {
  const { data } = await http.get(WIDGET_KEY)
  return data
}

export const postWidgetKey = async function (dto: APIKeyDTO): Promise<APIKeyDTO> {
  const { data } = await http.post(WIDGET_KEY, dto)
  return data
}

export const refreshWidgetKey = async function (id: number): Promise<APIKeyDTO> {
  const { data } = await http.put(`${WIDGET_KEY}/${id}`)
  return data
}

export const deleteWidgetKey = async function (id: number): Promise<APIKeyDTO> {
  const { data } = await http.delete(`${WIDGET_KEY}/${id}`)
  return data
}

export const widgetLoginLog = async function(accessToken: string, widgetConfig: string) {
  await http.post('/auth/widget-login-log', { accessToken, widgetConfig })
}

export const refreshWidgetTokens = async function(refreshToken: string, accessToken: string) {
  const path = "/auth/widget-token-refresh"
  const queryString = `?refreshToken=${refreshToken}&accessToken=${accessToken}`
  const { data } = await http.get(path + queryString)
  return data
}