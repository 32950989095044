import http from '@/http';
import i18n from '@/locales';
import { notify } from "@kyvg/vue3-notification"
import { postError } from '@/helpers/notification'

const actions = {
  async exportContent({dispatch}, payload) {
    try {
      switch (payload.format) {
        case 'archive':
          dispatch('exportArchive', payload);
          break;
        case 'csv':
          dispatch('exportCsv', payload);
          break;
        case 'pdf':
          dispatch('exportPdf', payload);
          break;
        default:
      }
    } catch (err) {
      // na
    }
  },
  async exportArchive(context, payload) {
    try {
      const formData = new FormData();
      if (payload.mediaId) {
        formData.append('mediaId', payload.mediaId);
      }
      if (payload.contentId) {
        formData.append('pageId', payload.contentId);
      }
      await http.post('printer/zip', formData);

      notify({
        title: i18n.global.t('export'),
        text: i18n.global.t('exportMessage'),
        type: 'success'
      });
    } catch (err) {
      const response = err.response;
      postError({
        title: i18n.global.t('download'),
        text: response ? response.data.message : err,
      });
    }
  },
  async exportCsv(context, payload) {
    try {
      const formData = new FormData();
      formData.append('mediaId', payload.mediaId);
      if (payload.contentId) {
        formData.append('pageId', payload.contentId);
      }
      await http.post('printer/csv', formData);

      notify({
        title: i18n.global.t('export'),
        text: i18n.global.t('exportMessage'),
        type: 'success'
      });
    } catch (err) {
      const response = err.response;
      postError({
        title: i18n.global.t('download'),
        text: response ? response.data.message : err,
      });
    }
  },
  async exportPdf(context, payload) {
    try {
      const formData = new FormData();
      if (payload.mediaId) {
        formData.append('mediaId', payload.mediaId);
      }
      if (payload.contentId) {
        if (payload.contentType === 'chapter') {
          formData.append('chapterId', payload.contentId);
        }
        if (payload.contentType === 'illustration') {
          formData.append('pageId', payload.contentId);
        }
      }
      formData.append('locale', payload.localeLanguageCode || 'en-US');
      formData.append('orientation', payload.orientation || 'PORTRAIT');
      formData.append('pageSize', payload.pageSize || 'LETTER');
      formData.append('tocPref', payload.tocPref || 'ALL');
      formData.append('twoSidedPrinting', payload.twoSidedPrinting.toString());
      formData.append('includeImages', payload.includeImages.toString());
      formData.append('includePartsList', payload.includePartsList.toString());
      formData.append('generateChildChapters', payload.generateChildChapters.toString());
      await http.post('printer/pdf', formData);

      notify({
        title: i18n.global.t('export'),
        text: i18n.global.t('exportMessage'),
        type: 'success'
      });
    } catch (err) {
      const response = err.response;
      postError({
        title: i18n.global.t('download'),
        text: response ? response.data.message : err,
      });
    }
  }
};

export default {
  actions
};
