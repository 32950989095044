<template>
  <section class="info-tags-container">
    <nav
      v-if="tags.unfilteredTotal > 0"
      class="navbar filterContainer"
    >
      <input
        type="text"
        placeholder="Filter"
        :value="filter"
        class="input"
        :class="{'is-primary': filter}"
        @input="debounceInput"
      >
    </nav>
    <span
      v-if="tags.unfilteredTotal > 100"
      class="display-more-text"
    >
      {{ $t('tagsDisplayMore') }}
    </span>
    <template v-if="!tags.isLoaded && !tags.items.length">
      <utility-block />
    </template>
    <template v-else-if="tags.isLoaded && tags.items.length">
      <div
        class="table-container"
      >
        <table
          data-int="info-tag-table"
          class="table is-fullwidth is-narrow"
        >
          <tbody>
            <info-tags-item
              v-for="tag in tags.items"
              :key="tag.id"
              :tag="tag"
              data-int="info-tag"
            />
          </tbody>
        </table>
      </div>
    </template>
    <template v-else="tags.isLoaded && !tags.items.length">
      <div class="block-center text-center p-2">
        <h1 class="subtitle">{{$t('noTagsFound')}}</h1>
      </div>
    </template>

  </section>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'
import InfoTagsItem from './InfoTagsItem'
import UtilityBlock from '@/components/common/UtilityBlock'

export default {
  name: 'InfoTags',
  props: {
    id: {
      type: Number,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  components: {
    UtilityBlock,
    InfoTagsItem
  },
  data () {
    return {
      filter: ''
    }
  },
  computed: {
    ...mapState({
      tags: ({ content }) => content.tags,
      isLoaded: ({ content }) => content.tags.isLoaded
    })
  },
  methods: {
    getTags () {
      this.$store.dispatch('content/getTags', {
        filter: this.filter,
        id: this.id,
        type: this.type
      })
    },
    debounceInput: debounce(function (e) {
      this.filter = e.target.value
      this.getTags()
    }, 500)
  }
}
</script>

<style scoped>
.info-tags-container {
  padding: .5rem .25rem;
  display: block;
}
.filterContainer {
  z-index: unset;
}
.display-more-text {
  font-style: oblique;
}
</style>
