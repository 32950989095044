import ar from './ar-SA';
import cs from './cs-CZ';
import de from './de-DE';
import en from './en-US';
import es from './es-ES';
import fi from './fi-FI';
import fr from './fr-FR';
import hu from './hu-HU';
import id from './id-ID';
import it from './it-IT';
import ja from './ja-JP';
import ko from './ko-KR';
import nl from './nl-NL';
import no from './nn-NO';
import pl from './pl-PL';
import pt from './pt-PT';
import ro from './ro-RO';
import ru from './ru-RU';
import sr from './sr-SP';
import sv from './sv-SE';
import tr from './tr-TR';
import uk from './uk-UA';
import zh from './zh-CN';

export default {
  'ar-SA': ar,
  'cs-CZ': cs,
  'de-DE': de,
  'en-US': en,
  'es-ES': es,
  'fi-FI': fi,
  'fr-FR': fr,
  'hu-HU': hu,
  'id-ID': id,
  'it-IT': it,
  'ja-JP': ja,
  'ko-KR': ko,
  'nl-NL': nl,
  'nn-NO': no,
  'pl-PL': pl,
  'pt-PT': pt,
  'ro-RO': ro,
  'ru-RU': ru,
  'sr-SP': sr,
  'sv-SE': sv,
  'tr-TR': tr,
  'uk-UA': uk,
  'zh-CN': zh
};
