import { ActionContext, GetterTree } from 'vuex'
import { arrowKey, DraftBomItem, moveDirection } from '@/interfaces/admin'
import bomActions from '@/helpers/bomActions'
import { cloneDeep } from "lodash";

export interface DraftEditorStore {
  bomItems: Array<DraftBomItem>,
  selectedBomItems: Array<DraftBomItem>,
  bomForView: Array<DraftBomItem>
}

type Context = ActionContext<DraftEditorStore, any>
/*
  @bomItems => original set of bom items retrieved from the api for a page
  @selectedBomItems => Any items that are in a selected state
  @bomForView => a flat array showing only the topmost parent heirarchal items, and any of their children if they are in an expanded state
  This expanded state can have an infinite number of children that can also be in an expanded state and therefore be present in this list
 */
const state: DraftEditorStore = {
  bomItems: [],
  selectedBomItems: [],
  bomForView: []
}

const getters: GetterTree<DraftEditorStore, any> = {
  getSelectedBomItems: (state: DraftEditorStore) => state.selectedBomItems,
  getBomItems: (state: DraftEditorStore) => state.bomItems,
  getBomForView: (state: DraftEditorStore, getters, rootState) => {
    return state.bomForView.map(it => {
      const { locale } = rootState.user
      return {
        ...it,
        partName: it.partTranslations?.find(tr => tr.lang === locale)?.name ?? "",
      }
    })
  }
}

const actions = {
  makeBomForView ({ state, commit }: Context) {
    if (state.bomItems && state.bomItems.length > 0) {
      const bom = Array.from(state.bomItems)
      const toTree = (pKey: any, depth = 0): Array<DraftBomItem> => {
        return bom.filter(({ parent }) => {
          return (!pKey && !parent) || (pKey && pKey === parent)
        }).map((children, index) => ({
          ...children,
          index: index,
          level: depth,
          expanded: false,
          selected: false,
          isDigabitNumber: children.partNumber? children.partNumber.includes('DIGABIT') : false,
          child: children.children!.length > 0 ? toTree(children.refKey, depth + 1) : null
        }))
      }
      const bomForView = toTree(null)
      commit('setBomForView', bomForView)
    } else {
      commit('setBomForView', [])
    }
  },
  async setSelectedBomByText ({ dispatch, state, commit }: Context, payload: Array<any>) {
    const bomForView = cloneDeep(state.bomForView)
    const bomItems = cloneDeep(state.bomItems)
    const bomMutations = await bomActions.getBomSelectionsArray( payload, bomForView, bomItems )
    if(bomMutations.selections.length < 1) return
    commit('setBomForView', bomMutations.view)
    commit('setAllSelectedStates', bomMutations.selections)
  },
  async setDraftBomItems ({ dispatch, state, commit, rootState }: Context) {
    const bomItems = rootState?.draftPage?.draftBomItems ?? []
    await commit('setBomItems', bomItems)
    await dispatch('makeBomForView')
  },
  updateSelectedArray ({ dispatch, state, commit }: Context, params: {item: DraftBomItem, itemSelected: boolean}) {
    if (params.itemSelected) {
      commit('selectBomItem', params.item)
    } else {
      commit('removeBomItem', params.item)
    }
  },
  setSelectedBomState ({ dispatch, state, commit }: Context, payload: any) {
    commit('setSelectedItemState', payload)
  },
  clearSelections ({ dispatch, state, commit }: Context) {
    commit('clearSelections')
  },
  resetBomForView ({ dispatch, state, commit }: Context, payload: any) {
    commit('setBomForView', payload)
  },
  itemSelected ({ dispatch, state, commit }: Context, payload: { index: number, state: boolean}) {
    commit('setSelectedItemState', payload)
  },
  expandItemProperty ({ dispatch, state, commit }: Context, payload: any) {
    commit('expandItemProp', payload)
  },
  async testIfBomItemExists ({ dispatch, state, commit }: Context, hotpoints: any) {
    let exists = false
    state.bomItems.forEach(bomItem => {
      const bomText = bomItem.text || bomItem.item
      hotpoints.forEach((point: any) => {
        if(bomText === point.text || bomText === point.item) exists = true
      })
    })
    return exists
  },
  async updateBomForView ({ dispatch, state, commit }: Context, payload: { item: DraftBomItem, index: number }) {
    const bomForView = cloneDeep(state.bomForView)
    const bom = await bomActions.toggleExpandState({
      bomForView: bomForView,
      item: cloneDeep(payload.item),
      index: payload.index })
    commit('setBomForView', bom)
  },
  setSelectedBomItems ({commit}: Context, payload: Array<DraftBomItem>) {
    commit('setSelectedBom', payload)
  },
  async selectBomByArrowKeyDirection (context: Context, data: string) {
    const hasSelection = context.state.selectedBomItems
    let selection
    if (data === moveDirection.up) {
      selection = await bomActions[arrowKey.arrowUp](hasSelection, context.state.bomForView, context.state.bomItems)
    }
    if (data === moveDirection.down) {
      selection = await bomActions[arrowKey.arrowDown](hasSelection, context.state.bomForView, context.state.bomItems)
    }
    if(!selection) return
    await context.commit('clearSelections')
    await context.dispatch('setSelectedBomByText', selection)
  }
}

const mutations = {
  expandItemProp (state: DraftEditorStore, payload: {item: DraftBomItem, expanded: boolean}) {
    state.bomForView[payload.item.index].expanded = payload.expanded
  },
  setBomForView (state: DraftEditorStore, bomItems: Array<DraftBomItem>) {
    const bomItemsIndexed = bomItems.map((item, index) => {
      item.index = index
      return item
    })
    state.bomForView = bomItemsIndexed
  },
  setBomItems (state: DraftEditorStore, bomItems: Array<DraftBomItem>) {
    let indexList = Array.from(bomItems)
    indexList = indexList.map((item, index) => {
      item.index = index
      return item
    })
    state.bomItems = indexList
  },
  setSelectedItemState (state: DraftEditorStore, payload: {index: number, state: boolean}) {
    state.bomForView[payload.index].selected = payload.state
  },
  setAllSelectedStates (state: DraftEditorStore, selections: Array<DraftBomItem>) {
    state.selectedBomItems = selections
  },
  clearSelections (state: DraftEditorStore) {
    state.bomForView = state.bomForView.map(item => {
      return { ...item, selected: false }
    })
    state.selectedBomItems = []
  },
  selectBomItem (state: DraftEditorStore, item: any) {
    state.selectedBomItems.push(item)
  },
  removeBomItem (state: DraftEditorStore, item: DraftBomItem) {
    const i = state.selectedBomItems.findIndex(arrItem => arrItem.id === item.id)
    state.selectedBomItems.splice(i, 1)
  },
  setSelectedBom (state: DraftEditorStore, selected: Array<DraftBomItem>) {
    state.selectedBomItems = selected
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
