export function recursivelyTrim(value: any): any {
  switch(typeof value) {
    case "string":
      return value.trim()
    case "object":
      if (Array.isArray(value)) {
        for (let index = 0; index < value.length; index++) {
          value[index] = recursivelyTrim(value[index])
        }
      } else {
        for (let key in value) {
          value[key] = recursivelyTrim(value[key])
        }
      }
    default:
      return value
  }
}