<template>
  <div>
    <cart-table
      :items="items"
      :is-loaded="isLoaded"
      :columns="columns"
      :has-pricing="hasPricing"
      :total-discounted-price="totalDiscountedPrice"
      :total-retail-price="totalRetailPrice"
      :total-wholesale-price="totalWholesalePrice"
      @updateQuantity="updateQuantity($event)"
      @removeFromCart="removeFromCart($event)"
    >
      <div class="level-left">
        <button
          v-if="isQuickAddEnabled"
          class="button level-item is-info"
          @click="quickAddModalOpen = true"
        >
          {{ $t('add') }}
        </button>
      </div>
    </cart-table>
    <cart-quick-add-modal-wrapper
      v-if="quickAddModalOpen && isQuickAddEnabled"
      @cancel="quickAddModalOpen = false"
    />
  </div>
</template>

<script>
import CartTable from '@/components/library/cart/CartTable'
import CartQuickAddModalWrapper from '../CartQuickAddModalWrapper'
import { mapActions, mapGetters, mapState } from 'vuex'

/**
 * Shared cart table implementation for Order and Request for Quote views.
 * The most up-to-date view of cart before submitting request.
 */
export default {
  name: 'CartTableReview',
  components: {
    CartTable,
    CartQuickAddModalWrapper
  },
  data () {
    return {
      quickAddModalOpen: false
    }
  },
  computed: {
    ...mapGetters({
      isQuickAddEnabled: 'user/isQuickAddEnabled'
    }),
    ...mapState({
      columns: (state) => state.cart.columns.items,
      hasPricing: (state) => state.cart.columns.hasPricing,
      isLoaded: (state) => state.cart.isLoaded,
      items: (state) => state.cart.items,
      totalDiscountedPrice: (state) => state.cart.totalDiscountedPrice,
      totalRetailPrice: (state) => state.cart.totalRetailPrice,
      totalWholesalePrice: (state) => state.cart.totalWholesalePrice
    })
  },
  methods: {
    ...mapActions({
      removeFromCart: 'cart/removeFromCart',
      updateQuantity: 'cart/updateQuantity'
    })
  }
}
</script>
