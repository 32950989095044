export async function checkSubDomain () {
  const host = window.location.hostname
  if (
    host.endsWith(".documoto.com") ||
    host.endsWith(".documoto.com.localhost") ||
    host.endsWith(".documoto.localhost")
  ) {
    return host.substring(0, host.indexOf("."))
  }
  return null
}
