<template>
  <tr>
    <td
      data-testid="tag-name"
      class="tagName"
      data-int="tag-name"
    >
      <strong>{{ name }}:</strong>
    </td>
    <td
      data-testid="tag-value"
      data-int="tag-value"
      class="value"
    >
      {{ value }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'InfoTagsItem',
  props: {
    tag: {
      type: Object,
      required: true
    }
  },
  computed: {
    name () {
      return this.tag.friendlyName || this.tag.name
    },
    value () {
      const lowerBound = this.tag.lowerBound
        ? `${this.tag.prefix || ''}${this.pad(this.tag.lowerBound)}${this.tag.suffix || ''}`
        : ''
      const upperBound = this.tag.upperBound
        ? `${this.tag.prefix || ''}${this.pad(this.tag.upperBound)}${this.tag.suffix || ''}`
        : ''

      if (lowerBound || upperBound) {
        return lowerBound + (upperBound ? ` - ${upperBound}` : '')
      }
      return this.tag.value
    }
  },
  methods: {
    pad (value) {
      const numericSize = this.tag.numericSize && this.tag.numericSize > value.toString().length
        ? this.tag.numericSize
        : 0
      return new Array(numericSize).concat([Math.abs(value)]).join('0').slice(-numericSize)
    }
  }
}
</script>

<style scoped>
.value {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
</style>
