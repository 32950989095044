<template>
  <div class="field">
    <label class="label">
      {{ item.name }}
      <span v-if="canSaveLocal"
            class="is-pulled-right">
        <label class="has-text-weight-normal mr-1">{{ $t('save') }}</label>
        <input v-model="saveLocal"
               type="checkbox">
      </span>
    </label>
    <div class="control">
      <input
        :class="{'is-danger': !isValid}"
        :disabled="!isEditable"
        :value="inputValue"
        class="input"
        type="text"
        maxlength="255"
        @input="updateInput"
      >
    </div>
    <p
      v-if="!isValid"
      class="help is-danger"
    >
      {{ $t('invalidField', {field: item.name}) }}
    </p>
  </div>
</template>

<script>
import getSaveLocalState from '@/components/library/orders/cells/getSaveLocalState'

export default {
  name: 'OrderCellTextInput',
  props: {
    item: {
      type: Object,
      required: true
    },
    data: {
      type: String,
      default: ''
    },
    canSaveLocal: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: this.data || ''
    }
  },
  watch: {
    data () {
      this.value = this.data || ''
    },
    saveLocal () {
      this.change()
    }
  },
  computed: {
    inputValue: {
      get () {
        return this.value || this.data
      },
      set (value) {
        if (this.value !== value) {
          this.value = value
          this.change()
        }
      }
    },
    isValid () {
      if (this.item.required) {
        return Boolean(this.inputValue)
      }
      return true
    },
    isEditable () {
      return this.item.editable && this.canEdit
    }
  },
  methods: {
    /* eslint-disable no-invalid-this */
    // TODO - fix this if you happen to be working in this file.  The value of 'this' is potentially
    // ambuguous
    updateInput(e) {
      this.inputValue = e.target.value
    },
    /* eslint-enable no-invalid-this */
    change () {
      this.$emit('change', {
        field: this.item,
        value: this.value,
        updateLocal: this.saveLocal && this.isValid
      })
    }
  },
  setup (props) {
    const { saveLocal } = getSaveLocalState(props)
    return { saveLocal }
  }
}
</script>

<style scoped>
.field {
  padding: .5rem 1rem;
  width: 100%;
}
</style>
