import http from '@/http';

const PSPDF_KEY = 'PSPDF_KEY';

const state = {
  key: ''
};

const actions = {
  async getPSPDFKey({commit}) {
    try {
      const {data} = await http.get('/pspdf/key');
      commit(PSPDF_KEY, {key: data});
    } catch (err) {
      // na
    }
  }
};

const mutations = {
  [PSPDF_KEY](state, {key}) {
    state.key = key;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
