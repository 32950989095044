<template>
  <div class="field">
    <label class="label">{{ item.name }}</label>
    <div class="control">
      <label class="value">{{ data }} </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderCellTag',
  props: {
    item: {
      type: Object,
      required: true
    },
    data: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.field {
  padding: .5rem 1rem;
  width: 100%;
}
.control {
  min-height: 1.5rem;
}
</style>
