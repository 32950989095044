export function isTrue(value: boolean | string): boolean {
  if (typeof value === 'boolean') {
    return value
  } else {
    switch (value.toLowerCase()) {
      case 'true':
        return true
      case 'false':
        return false
      default: {
        console.warn('Invalid boolean value; defaulting to false')
        return false
      }
    }
  }
}
