import http from '@/http';
import router from '@/router';

import {entityTypePluralize} from './contentHelpers';
import {
  CLEAR_RELATED,
  CONTENT_RECEIVED, RELATED_RECEIVED, RELATED_UPDATE_LIMIT, RELATED_UPDATE_OFFSET
} from './contentTypes';
import { getRoute } from "@/router/getRoute";

const state = getDefaultState();

const actions = {
  async getRelated({commit, state, rootState}) {
    try {
      if (rootState.content.isLoaded && !state.isLoaded) {
        if (rootState.user.accessPrivileges.indexOf('VIEW_RELATED_ITEMS') >= 0 ||
            rootState.user.accessPrivileges.indexOf('VIEW_ATTACHMENTS') >= 0) {
          const entityTypePlural = entityTypePluralize(rootState.content.type);
          const entityId = rootState.content.id;

          const params = {
            limit: state.limit,
            offset: state.offset * state.limit
          };

          const {headers, data} = await http.get(`${entityTypePlural}/${entityId}/related`, {params});
          commit(RELATED_RECEIVED, {related: data, meta: headers});
        } else {
          const currentRoute = getRoute()
          const contentPath = currentRoute.path.substring(0, currentRoute.path.indexOf('related'));
          router.replace({path: `${contentPath}details`});
        }
      }
    } catch (err) {
      commit(RELATED_RECEIVED, {related: []});
    }
  },
  setRelatedPage({commit, dispatch}, page) {
    commit(RELATED_UPDATE_OFFSET, {
      offset: Math.max(page, 0)
    });
    dispatch('getRelated');
  },
  setRelatedResultsPerPage({commit, dispatch}, limit) {
    commit(RELATED_UPDATE_LIMIT, {limit});
    dispatch('getRelated');
  }
};

const mutations = {
  [RELATED_RECEIVED](state, {related, meta}) {
    state.items = related;
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0;
  },
  [CONTENT_RECEIVED](state, {content}) {
    state.items = [];
    state.offset = 0;
    state.limit = 20;
    state.total = content.relatedCount || 0;
  },
  [RELATED_UPDATE_OFFSET](state, {offset}) {
    state.offset = offset;
  },
  [RELATED_UPDATE_LIMIT](state, {limit}) {
    state.limit = limit;
    state.offset = 0;
  },
  [CLEAR_RELATED] (state) {
    Object.assign(state, getDefaultState())
  }
};

export default {
  state,
  actions,
  mutations
};

function getDefaultState() {
  return {
    items: [],
    offset: 0,
    limit: 20,
    total: 0
  }
}
