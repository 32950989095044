type FetchImageDto = {
  data?: string,
  error?: any
}

export async function fetchImageObjectUrl(url: string): Promise<FetchImageDto> {
  if (!url) return {}
  try {
    const res = await fetch(url)
    const blob = await res.blob()
    const data = URL.createObjectURL(blob)
    return { data }
  } catch(error) {
    return { error }
  }
}