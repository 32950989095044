import { ActionContext, GetterTree, MutationTree } from 'vuex'
import { Pagination } from '@/interfaces/global/index'
import { isEqual } from "lodash";

// Interfaces & Types
interface GridMetaData extends Record<string, any> {
  activeColumn: string,
  sortInvert: Boolean,
  searchFilters: searchFilters,
  pagination: Pagination
}
class Grid implements GridMetaData {
  activeColumn: string
  searchFilters: searchFilters
  sortInvert: Boolean
  pagination: Pagination
  constructor(defaultColumn: string = '', defaultSort = false, pagination = {}) {
    this.activeColumn = defaultColumn
    this.sortInvert = defaultSort,
    this.searchFilters = {},
    this.pagination = pagination
  }
}
interface searchFilters {
  [key:string]: string
}
interface GridsState {
  grids: {
    [key: string]: GridMetaData
  },
  isLoading: boolean
}
type Context = ActionContext<GridsState, any>
export enum Mutations {
  ADD_GRID = 'ADD_GRID',
  CLEAR_FILTER_PROPS = 'CLEAR_FILTER_PROPS',
  REMOVE_FILTER_PROP = 'REMOVE_FILTER_PROP',
  SET_ACTIVE_COLUMN = 'SET_ACTIVE_COLUMN',
  SET_FILTER_TERM = 'SET_FILTER_TERM',
  SET_FILTER_PROP = 'SET_FILTER_PROP',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_OFFSET = 'SET_OFFSET',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_SORT_INVERT = 'SET_SORT_INVERT',
  SET_TOTAL_COUNT = 'SET_TOTAL_COUNT',
  REMOVE_GRID = 'REMOVE_GRID'
}
interface addGridPayload {
  name: string,
  defaultColumn: string,
  defaultSort?: boolean
}
interface GridSetter {
  grid: string,
  field?: keyof GridMetaData,
}
interface SetGridPayload extends GridSetter {
  value: string
}
interface SetSortPayload extends GridSetter {
  value: Boolean
}
interface SetPaginationPayload extends GridSetter {
  value: number
}
interface setFilterPropPayload  extends GridSetter {
  value: string,
  property: string
}
interface GridSortPayload {
  grid: string,
  activeColumn: string,
  sortInvert: Boolean
}

// MODULE STATE
const state: GridsState = {
  grids: {},
    // {
    //     [gridName]: {
    //          activeColumn: string,
    //          sortInvert: boolean
    //     }
    // }
  isLoading: false
}

const getters: GetterTree<GridsState, any> = {
  sortInvert: (state) => (grid: string): Boolean | null =>  {
    return state.grids[grid]?.sortInvert ?? null
  },
  activeColumn: (state) => (grid: string): String | null => {
    return state.grids[grid]?.activeColumn ?? null
  },
  filterTerm: (state) => (grid: string): String | '' => {
    return state.grids[grid]?.searchFilters?.filterTerm ?? ''
  },
  searchFilters: (state) => (grid: string): String | {} => {
    return state.grids[grid]?.searchFilters ?? {}
  },
  offset: (state) => (grid: string): number | undefined =>  {
    return state.grids[grid]?.pagination?.offset ?? 0
  },
  hasQuery: (state) => (grid: string): Boolean => {
    return Object.keys(state.grids[grid]?.searchFilters ?? '').length > 0
  },
  totalCount: (state) => (grid: string): Number => {
    return state.grids[grid]?.pagination.total ?? 0
  },
  pagination: (state) => (grid: string): Pagination => {
    return state.grids[grid]?.pagination ?? {}
  },
  stateHasGrid(state) {
    return function(gridName: string) {
      return !!state.grids[gridName]
    }
  }
}

// MODULE ACTIONS
const actions = {
  async addGrid({ commit, state  }: Context, payload: addGridPayload) {
    // Do not overwrite existing grid
    if (!!state.grids[payload.name]) return
    commit(Mutations.ADD_GRID, payload)
  },
  async clearFilterProps ({ commit }: Context, payload: String) {
    commit(Mutations.CLEAR_FILTER_PROPS, payload)
  },
  async resetOffset ({ commit, getters }: Context, payload: string) {
    if (!getters.stateHasGrid(payload)) return
    const newOffset = {grid: payload, value: 0}
    commit(Mutations.SET_OFFSET, newOffset)
  },
  async setGridFilter({ commit, state, dispatch }: Context, payload: SetGridPayload) {
    if(state.grids[payload.grid].filterTerm === payload.value) return
    commit(Mutations.SET_FILTER_TERM, payload)
    await dispatch('resetOffset', payload.grid)
  },
  async setGridSort({ commit, state, dispatch }: Context, { grid, activeColumn, sortInvert }: GridSortPayload) {
    if(state.grids[grid].activeColumn === activeColumn && state.grids[grid].sortInvert === sortInvert) return
    commit(Mutations.SET_ACTIVE_COLUMN, { grid, value: activeColumn })
    commit(Mutations.SET_SORT_INVERT, { grid, value: sortInvert})
    await dispatch('resetOffset', grid)
  },
  async setIsLoading({ commit }: Context, payload: boolean) {
    commit(Mutations.SET_IS_LOADING, payload)
  },
  async setPagination({ commit, getters }: Context, payload: { grid: string, pagination: Pagination }) {
    if (!getters.stateHasGrid(payload.grid)) return
    commit (Mutations.SET_PAGINATION, payload)
  },
  async setOffset({ commit, getters }: Context, payload: SetPaginationPayload) {
    if (!getters.stateHasGrid(payload.grid)) return
    commit(Mutations.SET_OFFSET, payload)
  },
  async setFilterProp({commit, state, dispatch}: Context, payload: {grid: string, values: searchFilters}) {
    if(isEqual(state.grids[payload.grid].searchFilters, payload.values)) return
    const searchObj = payload.values
    for (const prop in searchObj) {
      let properties: setFilterPropPayload = {
        grid: payload.grid,
        property: prop,
        value: searchObj[prop]
      }
      commit(Mutations.SET_FILTER_PROP, properties)
    }
    // remove any not found that currently exist
    for (const valName in state.grids[payload.grid].searchFilters) {
      if(!searchObj[valName]) {
        commit(Mutations.REMOVE_FILTER_PROP, {grid: payload.grid, property: valName})
      }
    }
    await dispatch('resetOffset', payload.grid)
  },
  setTotalCount ({ commit, getters } : Context, payload: SetPaginationPayload) {
    if (!getters.stateHasGrid(payload.grid)) return
    commit(Mutations.SET_TOTAL_COUNT, payload)
  },
  async removeGrid({ commit }: Context, payload: string) {
    commit(Mutations.REMOVE_GRID, payload)
  }
}

// MODULE MUTATIONS
const mutations: MutationTree<GridsState> = {
  // Always copy state.grids to ensure component reactivity
  [Mutations.ADD_GRID] (state, { name, defaultColumn, defaultSort = false }: addGridPayload) {
    state.grids = { ...state.grids }
    state.grids[name] = new Grid(defaultColumn, defaultSort)
  },
  [Mutations.CLEAR_FILTER_PROPS] (state, gridName: string) {
    state.grids[gridName].searchFilters = {}
  },
  [Mutations.SET_PAGINATION] (state, {grid, pagination}) {
    state.grids[grid].pagination = pagination
  },
  [Mutations.SET_ACTIVE_COLUMN] (state, { grid, value }: SetGridPayload) {
    state.grids = { ...state.grids }
    state.grids[grid].activeColumn = value
  },
  [Mutations.SET_SORT_INVERT] (state, { grid, value }: SetSortPayload) {
    state.grids = { ...state.grids }
    state.grids[grid].sortInvert = value
  },
  [Mutations.SET_FILTER_TERM] (state, { grid, value }: SetGridPayload) {
    state.grids = { ...state.grids }
    if (!value) {
      delete state.grids[grid].searchFilters.filterTerm
    } else {
      state.grids[grid].searchFilters.filterTerm = value
    }
  },
  [Mutations.SET_OFFSET] (state, { grid, value }: SetPaginationPayload) {
    state.grids = { ...state.grids }
    state.grids[grid].pagination.offset = value
  },
  [Mutations.SET_IS_LOADING] (state, payload: boolean) {
    state.isLoading = payload
  },
  [Mutations.SET_FILTER_PROP] (state, {grid, property, value}: setFilterPropPayload) {
    state.grids = { ...state.grids }
    state.grids[grid].searchFilters[property] = value
  },
  [Mutations.REMOVE_FILTER_PROP] (state, {grid, property}) {
    state.grids = { ...state.grids }
    delete state.grids[grid].searchFilters[property]
  },
  [Mutations.SET_TOTAL_COUNT] (state, { grid, value }: SetPaginationPayload) {
    state.grids = { ...state.grids }
    state.grids[grid].pagination.total = value
  },
  [Mutations.REMOVE_GRID] (state, grid: string) {
    delete state.grids[grid]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
