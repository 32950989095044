<template>
  <figure :class="thumbnailClass" class="image thumbnail" @click="$emit('click')">
    <img  v-if="!errorLoading && isWidget && !widgetOverride"
          ref="img"
          data-int="thumbnail"
          @error="errorLoading = true" />
    <img  v-else-if="!errorLoading"
          :src="thumbnail"
          :class="{'no-positioning': widgetOverride}"
          ref="img"
          data-int="thumbnail"
          @error="errorLoading = true" />
  </figure>
</template>

<script>
export default {
  name: 'Thumbnail',
  props: {
    thumbnailUrl: {
      type: String,
      default: ''
    },
    thumbnailType: {
      type: String,
      default: ''
    },
    widgetOverride: Boolean
  },
  computed: {
    thumbnail() {
      return !this.errorLoading ? this.thumbnailUrl : '';
    },
    thumbnailClass() {
      return this.errorLoading ? `${this.thumbnailType}-thumbnail` : '';
    },
    isWidget() {
      return this.$store.state.auth.isWidget
    }
  },
  data() {
    return {
      errorLoading: !this.thumbnailUrl
    };
  },
  methods: {
    async fetchImage() {
      if (!this.thumbnailUrl) return
      try {
        const res = await fetch(this.thumbnailUrl)
        const blob = await res.blob()
        this.$refs.img.src = URL.createObjectURL(blob)
      } catch(e) {
        console.error(e)
        this.errorLoading = true
      }
    }
  },
  async created() {
    if (this.isWidget) {
      await this.fetchImage()
    }
  },
  mounted() {
    this.errorLoading = !this.thumbnailUrl;
  },
  watch: {
    thumbnailUrl() {
      this.errorLoading = !this.thumbnailUrl;
    },
    errorLoading: {
      immediate: true,
      handler() {
        this.$emit('errorLoading', this.errorLoading);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.image {
  margin: auto;
}
img {
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  width: auto !important;
  height: auto !important;
  -webkit-user-drag: none;
  &:not(.no-positioning) {
    position: absolute;
  }
}
</style>
