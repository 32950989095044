<template>
  <basic-modal ref="modal"
               :can-submit="canDelete"
               :is-loading="isLoading"
               :submit-options="submitOptions"
               disable-keys
               hide-discard
               @close="$emit('close')"
               @submit="$emit('submit')">
    <template #title>
      <slot name="title">
        <i18n-t keypath="deleteContent" tag="span">
          <template #type>
            <span>{{ item.type }}</span>
          </template>
          <template #name>
            <span v-if="item.name"
                  class="tag is-primary">
              {{ item.name }}
            </span>
          </template>
        </i18n-t>
      </slot>
    </template>
    <template #content>
      <slot name="content"/>
    </template>
  </basic-modal>
</template>

<script>
import BasicModal from '@/components/common/modal/BasicModal'

export default {
  props: {
    canDelete: {
      type: Boolean,
      default: true
    },
    isLoading: Boolean,
    /*
      Default translation block slots: type, name
      Use the follow as name-reference for different data
       - Names, usernames, labels, identifiers, etc.
       - First translation if no "name" variety is available/applicable
     */
    item: Object,
    submitOptions: {
      type: Object,
      default: () => ({
        label: 'delete',
        type: 'is-danger'
      })
    }
  },
  components: {
    BasicModal
  },
  methods: {
    open () {
      this.$refs.modal.open()
    },
    close () {
      this.$refs.modal.close()
    }
  }
}
</script>
