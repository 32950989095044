<template>
  <browse-header>
    <template #breadcrumbs>
      <breadcrumb>
        <template v-slot:browseCrumbs="{ crumbs, text }">
          <widget-breadcrumbs :breadcrumbs="[{  name: $t('home'), 
                                                type: 'home' }, 
                                                ...crumbs, 
                                                { name: text }]"
                              class="is-flex widget-browse-breadcrumbs"
                              @openAsset="navToTaxon"/>
        </template>
      </breadcrumb>
    </template>
    <template v-slot:content="{ isMounting, itemsLoaded }">
      <component  :is="browseOrBrowseSearch"
                  :id="toRaw(taxonIdList)"
                  :class="[{  'widget-browse-search-top': isBrowseSearch && !isMounting && itemsLoaded }, 
                           {  'widget-browse-flows-padding': !isBrowseSearch },
                              'widget-browse-flows']"
                  style="padding: 0.5rem 0;"/>
    </template>
  </browse-header>
</template>

<script setup>
import { toRaw, computed } from "vue"
import BrowseHeader from "@/components/library/browse/BrowseHeader"
import Browse from "@/components/library/browse/Browse"
import WidgetBreadcrumbs from "../WidgetBreadcrumbs"
import Breadcrumb from "@/components/library/browse/Breadcrumb"
import WidgetSearch from "./WidgetSearch"
import { useBrowseFlows } from "@/components/widgets/composables/useBrowseFlows"
import { useWidgetNavigator } from "@/components/widgets/composables/useWidgetNavigator"

const { 
  taxonIdList, 
  isBrowseSearch,
  navigateToBrowse } = useBrowseFlows()
const { goHome } = useWidgetNavigator()

const browseOrBrowseSearch = computed(() => {
  return isBrowseSearch.value ? WidgetSearch 
    : Browse
})

function navToTaxon(dto) {
  if (dto.type === 'home') {
    goHome()
  } else {
    navigateToBrowse(dto)
  }
}
</script>
<style>
.widget-browse-search-top {
  border-top: 1px solid lightgrey;
}
.widget-browse-flows > .taxon-container {
  overflow-x: hidden;
  padding: 1rem;
  width: 100%;
}
.widget-browse-flows-padding > * {
  padding: 0 1rem;
}
.widget-browse-breadcrumbs {
  border-bottom: 1px solid lightgrey; 
  min-height: fit-content;
}
</style>