<template>
  <td data-int="cart-cell-book">
    {{ item.bookName }}
  </td>
</template>

<script>
export default {
  name: 'CartCellBook',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
