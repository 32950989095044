import http from '@/http'
import { AxiosResponse } from 'axios'

export const searchSystemFields = async (params: any): Promise<AxiosResponse> => {
  return http.get('/admin/system-fields', { params })
}

export const updateSystemField = async (fieldId: number, dto: any): Promise<AxiosResponse> => {
  return http.put(`/admin/system-fields/${fieldId}`, dto)
}

export const reindexTenant = async (): Promise<AxiosResponse> => {
  return http.get('/admin/system-fields/reindex')
}
