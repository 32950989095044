<template>
  <th class="actions-header" />
</template>

<script>
export default {
  name: 'BomHeaderActions'
}
</script>

<style scoped>
.actions-header {
  min-width: 1.5rem;
}
</style>
