<template>
  <td :data-int=className />
</template>

<script>
export default {
  name: 'CartFooterEmpty',
  props: {
    column: {
      type: Object,
      required: true
    }
  },
  computed: {
    className() {
      return `cart-footer-${this.column.property}`;
    }
  }
};
</script>

<style scoped>
td {
  height: 28px;
}
</style>
