export enum EntityType {
  page = 'page',
  part = 'part',
  media = 'media',
  book = 'book'
}

export type MediaDto = {
  contentType: string,
  created: Date,
  description: string,
  id: number,
  identifier: string,
  name: string,
  type: string,
  updated: Date
}

export type ContentDto = {
  children: ContentDto[],
  commentCount: number|null,
  contentUrl: string|null,
  description: string|null,
  id: number,
  indentation: number,
  name: string,
  openZoomUrl: string|null,
  relatedCount: number|null,
  tagCount: number|null,
  tags: any|null,
  thumbnailUrl: string|null,
  thumbnailUrlLarge: string|null,
  type: string,
  whereUsedCount: number|null
}

export type PartDto = {
  commentCount?: number,
  created: Date,
  description?: string,
  discountAmount?: number,
  discountCurrency?: string,
  discountPrice?: string,
  id: number,
  identifier?: string,
  locale?: string,
  name: string,
  orderable?: boolean,
  pagePartId?: number,
  partNumber?: string,
  relatedCount?: number,
  retailAmount?: number,
  retailCurrency?: string,
  retailPrice?: string,
  suggestedCount?: number,
  supplierKey?: string,
  tagCount?: number,
  type: string,
  uom?: string,
  updated: Date,
  whereUsedCount?: number,
  wholesaleAmount?: number,
  wholesaleCurrency?: string,
  wholesalePrice?: string
}
export type PageDto = PartDto & {
  hashKey?: string
}

export type PageDtoExtended = PageDto & {
  commentCount: number,
  contentType: string,
  contentUrl: string,
  excludeFromPrint: boolean,
  relatedCount: number,
  sheets: { 
    contentType: string, 
    contentUrl: string, 
    openZoomUrl: string}[],
  tagCount: number,
  thumbnailUrlLarge: string,
  type: string,
  updated: Date,
  whereUsedCount: number
}

export type TocFlatListDto = {
  chapters: Set<number>,
  index: number,
  item: ContentDto
}