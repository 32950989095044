<template>
  <Multipane class="illustration-container"
             :layout="bomLayout === 'horizontal' ? 'vertical' : 'horizontal'">
    <open-zoom-viewer class="illustration"/>
    <multipane-resizer class="is-hidden-touch" />
    <bom class="bom"/>
  </Multipane>
</template>

<script>
import { mapState } from 'vuex'
import { Multipane, MultipaneResizer } from 'vue-multipane'

import Bom from './bom/Bom'
import OpenZoomViewer from './openZoomViewer/OpenZoomViewer'

export default {
  name: 'IllustrationViewer',
  components: {
    Multipane,
    MultipaneResizer,
    Bom,
    OpenZoomViewer
  },
  computed: {
    ...mapState({
      bomLayout: state => state.content.bom.layout
    })
  },
  watch: {
    bomLayout () {
      this.updatePaneCss()
    }
  },
  methods: {
    updatePaneCss () {
      const elements = document.getElementsByClassName('illustration')

      if (elements && elements.length) {
        if (this.bomLayout === 'horizontal') {
          elements[0].style.height = '100%'
        } else {
          elements[0].style.width = '100%'
        }
      }
    }
  },
  mounted () {
    this.updatePaneCss()
  }
}
</script>

<style lang="scss" scoped>
.illustration-container {
  width: 100%;
  height: 99.5%;
}

.layout-h .illustration {
  min-height: 30%;
  height: 50%;
  max-height: 80%;
  width: 100%;
}
.layout-v .illustration {
  min-width: 30%;
  width: 50%;
  max-width: 70%;
  height: 100%;
}
.bom {
  flex-grow: 1;
}
.multipane-resizer {
  margin: 0;
  position: inherit;
  margin-right: 2px;
  margin-bottom: 2px;
}
.layout-h > .multipane-resizer:before {
  display: block;
  content: "";
  width: 40px;
  height: 3px;
  position: relative;
  top: 50%;
  left: 50%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.layout-v > .multipane-resizer:before {
  display: block;
  content: "";
  width: 3px;
  height: 40px;
  position: relative;
  top: 50%;
  left: 50%;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.illustration-container > .multipane-resizer:hover:before {
  border-color: #999;
}

@media only screen and (max-width: 1024px) {
  .illustration-container {
    flex-direction: column !important;
  }
  .illustration {
    min-width: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    height: 60% !important;
  }
  .bom {
    width: 100% !important;
  }
}
</style>
