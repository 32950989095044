import { ActionContext, GetterTree, MutationTree } from 'vuex'
import {
  deleteCurrentByPartId,
  getCurrentScsFile,
  getFilenameUsedCount,
  uploadFile
} from '@/controllers/admin'
import { Part3dFile } from '@/interfaces/global'
import { SchedulerJobState } from '@/interfaces/admin'

type Context = ActionContext<State, any>
const MAX_FILE_SIZE = 25 * 1024 * 1024 // 25 MB
interface State {
  currentFile: Part3dFile,
  partId: number|null,
  pendingSave: {
    files: File[],
    hasCompletedSaveRequest: boolean|null, // null means no save in-progress; better handle long uploads
    hasExceededFileSize: boolean, // for alerts when selecting too large of a file
    otherFilenameUsedCount: number, // for alerts before save
    topAssemblyFileName: string, // default to first file
    uploadProgress: number|null, // null outside of upload requests
  },
  promptUserForFilenameConflict: boolean
}
enum Types {
  CHECK_USAGE_AND_SAVE_NEW_PART_3D_FILE = 'CHECK_USAGE_AND_SAVE_NEW_PART_3D_FILE',
  CLEANUP_BEFORE_DESTROY = 'CLEANUP_BEFORE_DESTROY',
  DELETE_PART_3D_FILE = 'DELETE_PART_3D_FILE',
  DISMISS_FILENAME_CONFLICT_MODAL = 'DISMISS_FILENAME_CONFLICT_MODAL',
  DISMISS_INVALID_FILE_SIZE_MODAL = 'DISMISS_INVALID_FILE_SIZE_MODAL',
  LOAD_PART_3D_FILE = 'LOAD_PART_3D_FILE',
  REVERT_PART_3D_CHANGES = 'REVERT_PART_3D_CHANGES',
  SELECT_NEW_PART_3D_FILE = 'SELECT_NEW_PART_3D_FILE',
  SELECT_TOP_ASSEMBLY_FILENAME = 'SELECT_TOP_ASSEMBLY_FILENAME',
  UPDATE_UPLOAD_PROGRESS = 'UPDATE_UPLOAD_PROGRESS',
}

const state = {
  currentFile: {
    jobId: null,
    jobStatus: null,
    originalTopLevelFilename: null,
    scsFileUrl: '',
  },
  partId: null,
  pendingSave: {
    files: [],
    hasCompletedSaveRequest: null,
    hasExceededFileSize: false,
    otherFilenameUsedCount: 0,
    topAssemblyFileName: '',
    uploadProgress: null,
  },
  promptUserForFilenameConflict: false,
}

const actions = {
  /**
   * checkUsageAndSaveNewPart3dFile may have to be called twice. The first attempt to save the
   * selected file(s). If there are file conflicts found, there should be a second attempt to save
   * with the desired outcome.
   *  - Replace = true: update all other parts with this new part 3D file(s) using the same filename
   *  - Replace = false: apply new part 3D file(s) to this part only
   *
   * @param commit
   * @param dispatch
   * @param replace
   */
  async checkUsageAndSaveNewPart3dFile ({ commit, dispatch }: Context, replace: boolean|null = null): Promise<number> {
    if (state.pendingSave.files.length === 0) return 0

    const filename: string = state.pendingSave.topAssemblyFileName
    const filenameCount = await getFilenameUsedCount(filename)
    const otherFilenameCount = state.currentFile.originalTopLevelFilename === filename ?
      Math.max(filenameCount-1, 0) :
      filenameCount
    let requestPromise = null

    switch (otherFilenameCount) {
      case 0:
        await dispatch('updateUploadProgress', 0)
        requestPromise = uploadFile({
          files: state.pendingSave.files,
          partId: state.partId!!,
          topAssemblyFileName: state.pendingSave.topAssemblyFileName,
          uploadProgressCallback: (progress: number) => dispatch('updateUploadProgress', progress),
          useForAllFilenameOccurrences: false
        })
        commit(Types.CHECK_USAGE_AND_SAVE_NEW_PART_3D_FILE, {
          otherFilenameCount: otherFilenameCount,
          replace: null,
          request: requestPromise,
        })
        break
      default:
        if (replace !== null) {
          await dispatch('updateUploadProgress', 0)
          requestPromise = uploadFile({
            files: state.pendingSave.files,
            partId: state.partId!!,
            topAssemblyFileName: state.pendingSave.topAssemblyFileName,
            uploadProgressCallback: (progress: number) => dispatch('updateUploadProgress', progress),
            useForAllFilenameOccurrences: replace
          })
        }
        commit(Types.CHECK_USAGE_AND_SAVE_NEW_PART_3D_FILE, {
          otherFilenameCount: otherFilenameCount,
          replace: replace,
          request: requestPromise,
        })
        break
    }

    return otherFilenameCount
  },
  cleanupBeforeDestroy ({ commit }: Context) {
    commit(Types.CLEANUP_BEFORE_DESTROY)
  },
  async deletePart3dFile ({ commit }: Context) {
    await deleteCurrentByPartId(state.partId!!)
    commit(Types.DELETE_PART_3D_FILE)
  },
  async dismissFilenameConflictModal ({ commit }: Context) {
    if (state.promptUserForFilenameConflict) {
      commit(Types.DISMISS_FILENAME_CONFLICT_MODAL)
    }
  },
  async dismissFileSizeModal ({ commit }: Context) {
    if (state.pendingSave.hasExceededFileSize) {
      commit(Types.DISMISS_INVALID_FILE_SIZE_MODAL)
    }
  },
  async loadAnyPart3dFile ({ commit }: Context, partId: number) {
    /*
     * To better handle long uploads, skip this load until the previous upload is finished.
     */
    if (state.pendingSave.hasCompletedSaveRequest === false) {
      return
    }

    const part3dFile = await getCurrentScsFile(partId)
    commit(Types.LOAD_PART_3D_FILE, { part3dFile: part3dFile, partId: partId })
  },
  async revertPart3dChanges ({ commit }: Context) {
    commit(Types.REVERT_PART_3D_CHANGES)
  },
  async selectNewPart3dFile ({ commit }: Context, files: File[]) {
    commit(Types.SELECT_NEW_PART_3D_FILE, files)
  },
  selectTopAssemblyFilename ({ commit }: Context, filename: string) {
    commit(Types.SELECT_TOP_ASSEMBLY_FILENAME, filename)
  },
  /**
   * Internal helper between this module and POST requests in the controller
   *
   * @param commit
   * @param uploadProgress
   */
  updateUploadProgress ({ commit }: Context, uploadProgress: number) {
    commit(Types.UPDATE_UPLOAD_PROGRESS, uploadProgress)
  }
}

const getters: GetterTree<State, any> = {
  current3dFile (state: State) {
    if (!!state.currentFile.scsFileUrl) {
      return `${window.location.protocol}//${window.location.host}${state.currentFile.scsFileUrl}`
    }

    return ''
  },
  hasLoadedWithError: (state: State) => state.currentFile.jobStatus === SchedulerJobState.COMPLETED_WITH_ERROR,
  hasPending3dFiles: (state: State) => state.currentFile.scsFileUrl === '' || state.pendingSave.files.length > 0,
  isDirty: (state: State) => state.pendingSave.files.length > 0,
  isProcessing3dFile: (state: State) => {
    if (state.pendingSave.hasCompletedSaveRequest === false) return true

    if (!!state.currentFile.jobId && !!state.currentFile.jobStatus) {
      return [SchedulerJobState.SUBMITTED.toString(), SchedulerJobState.INPROCESS.toString()]
          .includes(state.currentFile.jobStatus)
    }

    return false
  },
  otherFilenameUsedCount: (state: State) => state.pendingSave.otherFilenameUsedCount,
  pending3dFilenames: (state: State) => Array.from(state.pendingSave.files).map((item) => item.name),
  pendingTopAssemblyFilename: (state: State) => state.pendingSave.topAssemblyFileName,
  showFilenameConflictModal: (state: State) => state.promptUserForFilenameConflict,
  showFileSizeModal: (state: State) => state.pendingSave.hasExceededFileSize,
  uploadProgress: (state: State) => state.pendingSave.uploadProgress,
}

const mutations: MutationTree<State> = {
  async [Types.CHECK_USAGE_AND_SAVE_NEW_PART_3D_FILE] (
    state: State,
    payload: {
      otherFilenameCount: number,
      replace: boolean | null,
      request: Promise<number>,
    }) {
    state.pendingSave.otherFilenameUsedCount = payload.otherFilenameCount

    /**
     * Pending save should remain the same; if the operation fails, we should see the original
     * dirty state.
     */
    if (payload.otherFilenameCount === 0 || payload.replace !== null) {
      payload.request.then(jobId => {
        state.currentFile.jobId = jobId
        state.pendingSave.hasCompletedSaveRequest = true
      })

      state.currentFile.jobStatus = SchedulerJobState.SUBMITTED
      state.pendingSave.hasCompletedSaveRequest = false
      state.promptUserForFilenameConflict = false
      // upload progress will be determined by the controller; if longer than a second, it updates progress
    } else {
      /*
       * If there are other filenames using the filename, the user must choose how to resolve
       * this. 'checkUsageAndSaveNewPart3dFile' should ultimately be called again with a 'replace' value.
       */
      state.promptUserForFilenameConflict = true
    }
  },
  [Types.CLEANUP_BEFORE_DESTROY] (state: State) {
    /*
     * Update by property maintains reactivity
     */
    state.currentFile = {
      jobId: null,
      jobStatus: null,
      originalTopLevelFilename: null,
      scsFileUrl: '',
    }
    state.partId = null
    state.pendingSave = {
      files: [],
      hasCompletedSaveRequest: null,
      hasExceededFileSize: false,
      otherFilenameUsedCount: 0,
      topAssemblyFileName: '',
      uploadProgress: null,
    }
    state.promptUserForFilenameConflict = false
  },
  [Types.DELETE_PART_3D_FILE] (state: State) {
    state.currentFile.scsFileUrl = ''
    state.pendingSave = {
      files: [],
      hasCompletedSaveRequest: null,
      hasExceededFileSize: false,
      otherFilenameUsedCount: 0,
      topAssemblyFileName: '',
      uploadProgress: null,
    }
  },
  [Types.DISMISS_FILENAME_CONFLICT_MODAL] (state: State) {
    state.pendingSave.otherFilenameUsedCount = 0
    state.promptUserForFilenameConflict = false
  },
  [Types.DISMISS_INVALID_FILE_SIZE_MODAL] (state: State) {
    state.pendingSave.hasExceededFileSize = false
  },
  [Types.LOAD_PART_3D_FILE] (state: State, payload: { part3dFile: Part3dFile, partId: number }) {
    state.partId = payload.partId ?? ''
    state.currentFile = payload.part3dFile

    switch (payload.part3dFile.jobStatus) {
      case SchedulerJobState.SUBMITTED:
      case SchedulerJobState.INPROCESS:
        // Nothing
        break;
      case SchedulerJobState.COMPLETED:
        state.pendingSave = {
          files: [],
          hasCompletedSaveRequest: null,
          hasExceededFileSize: false,
          otherFilenameUsedCount: 0,
          topAssemblyFileName: '',
          uploadProgress: null,
        }
        break
      case SchedulerJobState.COMPLETED_WITH_ERROR:
      case SchedulerJobState.CANCELED:
        state.pendingSave = {
          files: [],
          hasCompletedSaveRequest: null,
          hasExceededFileSize: false,
          otherFilenameUsedCount: 0,
          topAssemblyFileName: '',
          uploadProgress: null,
        }
        break
    }
  },
  [Types.REVERT_PART_3D_CHANGES] (state: State) {
    state.pendingSave = {
      files: [],
      hasCompletedSaveRequest: null,
      hasExceededFileSize: false,
      otherFilenameUsedCount: 0,
      topAssemblyFileName: '',
      uploadProgress: null,
    }
  },
  [Types.SELECT_NEW_PART_3D_FILE] (state: State, files: File[]) {
    const hasValidFileSize = Array.from(files).every(f => f.size <= MAX_FILE_SIZE)

    switch (hasValidFileSize) {
      case true:
        state.pendingSave = {
          files: files,
          hasCompletedSaveRequest: null,
          hasExceededFileSize: false,
          otherFilenameUsedCount: 0,
          topAssemblyFileName: files[0].name,
          uploadProgress: null,
        }
        break
      case false:
        state.pendingSave = {
          files: [],
          hasCompletedSaveRequest: null,
          hasExceededFileSize: true,
          otherFilenameUsedCount: 0,
          topAssemblyFileName: '',
          uploadProgress: null,
        }
    }
  },
  [Types.SELECT_TOP_ASSEMBLY_FILENAME] (state: State, filename: string) {
    state.pendingSave.topAssemblyFileName = filename
  },
  [Types.UPDATE_UPLOAD_PROGRESS] (state: State, uploadProgress: number) {
    state.pendingSave.uploadProgress = uploadProgress
  }
}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations
}
