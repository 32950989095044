<script setup lang="ts">
import DrawMode = Communicator.DrawMode
import {
  CuttingPlaneType,
  getRawValue,
  HoopsDrawModeOptions,
  HoopsOrientationAndProjectionOptions,
  HoopsPerspective,
  HoopsSlice,
  HoopsSliceOptions,
  HoopsToolbarIcon,
} from '@/plugins/hoops'
import { computed, ref } from 'vue'
import { CuttingPlaneState } from '@/store/modules/hoopsWebViewer'
import SliderInput from '@/components/common/SliderInput.vue'
import { useStore } from 'vuex'

const props = defineProps ({
  disabled: Boolean
})

const store = useStore()

const currentDrawMode = computed(() => {
  return store.getters['hoopsWebViewer/currentDrawMode']
})
const currentExplodeMagnitude = computed({
  get () {
    return store.getters['hoopsWebViewer/currentExplodeMagnitude']
  },
  set (newMagnitude: number) {
    store.dispatch('hoopsWebViewer/changeExplodeMagnitude', newMagnitude)
  }
})
const currentProjection = computed(() => {
  return store.getters['hoopsWebViewer/currentProjection']
})
const currentOrientation = computed(() => {
  return store.getters['hoopsWebViewer/currentOrientation']
})

const changeDrawMode = (mode: DrawMode) => {
  store.dispatch('hoopsWebViewer/changeDrawMode', mode)
}
const changePerspective = (projection: HoopsPerspective) => {
  store.dispatch('hoopsWebViewer/changePerspective', projection)
}
const changeSliceMode = (option: HoopsSlice) => {
  switch (option.type) {
    case CuttingPlaneType.PLANE_SECTION:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneSection')
      break
    case CuttingPlaneType.PLANE_VISIBILITY:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneVisibility')
      break
    case CuttingPlaneType.PLANE_X:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneX')
      break
    case CuttingPlaneType.PLANE_Y:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneY')
      break
    case CuttingPlaneType.PLANE_Z:
      store.dispatch('hoopsWebViewer/toggleCuttingPlaneZ')
      break
    case CuttingPlaneType.RESET:
      store.dispatch('hoopsWebViewer/removeAllCuttingPlanes')
      break
    default:
    // CuttingPlaneType.PLANE_FACE is unused for part
  }
}
const resetCameraView = () => {
  store.dispatch('hoopsWebViewer/resetCameraView')
}
const zoomIn = () => {
  store.dispatch('hoopsWebViewer/zoomIn')
}
const zoomOut = () => {
  store.dispatch('hoopsWebViewer/zoomOut')
}

/*
 * Explode requires special handling. Dragging the slider locks the menu open.
 * Hence, this requires programmatic handling.
 */
const explodeActive = ref(false)
const explodeHovering = () => explodeActive.value = !props.disabled
const explodeLeave = () => explodeActive.value = false

/**
 * Slice options can have two-states and three-states. :|
 * @param option
 */
const getSliceStyleClass = (option: HoopsSlice) => {
  function getClassForThreeState (state: CuttingPlaneState) {
    switch (state) {
      case CuttingPlaneState.OFF:
        return null
      case CuttingPlaneState.POSITIVE:
        return 'has-background-primary'
      case CuttingPlaneState.NEGATIVE:
        return 'has-background-primary-dark'
    }
  }

  switch (option.type) {
    case CuttingPlaneType.PLANE_SECTION:
      return store.getters['hoopsWebViewer/cuttingPlaneSectionEnabled'] ?
        'has-background-primary' : null
    case CuttingPlaneType.PLANE_VISIBILITY:
      return store.getters['hoopsWebViewer/cuttingPlaneVisibilityEnabled'] ?
        'has-background-primary' : null
    case CuttingPlaneType.PLANE_X:
      return getClassForThreeState(store.getters['hoopsWebViewer/cuttingPlaneXState'])
    case CuttingPlaneType.PLANE_Y:
      return getClassForThreeState(store.getters['hoopsWebViewer/cuttingPlaneYState'])
    case CuttingPlaneType.PLANE_Z:
      return getClassForThreeState(store.getters['hoopsWebViewer/cuttingPlaneZState'])
    case CuttingPlaneType.RESET:
      return // No color classes for RESET
    default:
      // PLANE_FACE is unused for part
  }
}

const isDisabledSliceOption = (option: HoopsSlice) => {
  switch (option.type) {
    case CuttingPlaneType.PLANE_SECTION:
      return store.getters['hoopsWebViewer/cuttingPlanesCount'] < 2 ? true : null
    case CuttingPlaneType.PLANE_VISIBILITY:
    case CuttingPlaneType.RESET:
      return store.getters['hoopsWebViewer/cuttingPlanesCount'] === 0 ? true : null
    default:
      return null // PLANE_FACE is unused for part
  }
}
</script>

<template>
  <div class="is-fullwidth navbar-menu">
    <div class="navbar-start">
      <a class="is-arrowless navbar-item normal-icon"
         :class="{ 'disabled': disabled }"
         @click="resetCameraView">
        <span class="icon fa-lg fas fa-home mb-1" />
      </a>
      <div class="hoops-icon navbar-item"
           :class="{ 'has-dropdown-up': !disabled, 'is-hoverable': !disabled }">
        <a class="is-arrowless navbar-link"
           :class="{ 'custom-disabled': disabled }">
          <hoops-toolbar-icon type="wireframeshaded" />
        </a>
        <div class="force-dropdown-styling is-boxed is-flex is-flex-direction-column is-align-items-center navbar-dropdown">
          <template v-for="option in HoopsDrawModeOptions"
                    :key="`${option.iconType}-${currentDrawMode}`">
            <b-tooltip position="is-right"
                     :delay="500"
                     :label="option.label">
              <hoops-toolbar-icon class="gapped-options navbar-item"
                                  :class="{ 'has-background-primary': currentDrawMode === option.value }"
                                  :type="`${option.iconType}`"
                                  @click="changeDrawMode(option.value)" />
            </b-tooltip>
          </template>
        </div>
      </div>
      <div class="hoops-icon navbar-item hoops-icon"
           :class="{ 'has-dropdown-up': !disabled, 'is-active': explodeActive }"
           @mouseover="explodeHovering"
           @mouseleave="explodeLeave">
        <a class="is-arrowless navbar-link"
           :class="{ 'custom-disabled': disabled }">
          <hoops-toolbar-icon type="explode" />
        </a>
        <div class="force-dropdown-styling is-boxed navbar-dropdown"
             :style="{ 'height': '150px' }">
          <slider-input v-model="currentExplodeMagnitude"
                        class="py-2"
                        is-vertical />
        </div>
      </div>
      <div class="hoops-icon navbar-item"
           :class="{ 'has-dropdown-up': !disabled, 'is-hoverable': !disabled }">
        <a class="is-arrowless navbar-link"
           :class="{ 'custom-disabled': disabled }">
          <hoops-toolbar-icon type="cuttingplane" />
        </a>
        <div class="force-dropdown-styling is-boxed navbar-dropdown">
          <template v-for="optionRow in HoopsSliceOptions">
            <div class="gapped-options is-flex is-flex-direction-row">
              <template v-for="option in optionRow">
                <b-tooltip position="is-right"
                         :delay="500"
                         :label="option.label">
                  <hoops-toolbar-icon class="navbar-item"
                                      :class="getSliceStyleClass(option)"
                                      :disabled="isDisabledSliceOption(option)"
                                      :id="`${option.elementId}`"
                                      :type="`${option.iconType}`"
                                      @click="isDisabledSliceOption(option) ? null : changeSliceMode(option)"/>
                </b-tooltip>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="hoops-icon navbar-item"
           :class="{ 'has-dropdown-up': !disabled, 'is-hoverable': !disabled }">
        <a class="is-arrowless navbar-link"
           :class="{ 'custom-disabled': disabled }">
          <hoops-toolbar-icon type="camera" />
        </a>
        <div class="force-dropdown-styling is-boxed navbar-dropdown">
          <template v-for="projectionRow in HoopsOrientationAndProjectionOptions.get('projection')">
            <div class="gapped-options is-flex is-flex-direction-row">
              <template v-for="projectionOption in projectionRow">
                <b-tooltip position="is-right"
                           :delay="500"
                           :label="projectionOption.label">
                  <hoops-toolbar-icon class="navbar-item"
                                      :class="{ 'has-background-primary': [currentProjection, currentOrientation].includes(getRawValue(projectionOption)) }"
                                      :type="`${projectionOption.iconType}`"
                                      @click="changePerspective(projectionOption)" />
                </b-tooltip>
              </template>
            </div>
          </template>
          <div class="dropdown-divider m-2" />
          <template v-for="orientationRow in HoopsOrientationAndProjectionOptions.get('orientation')">
            <div class="gapped-options is-flex is-flex-direction-row">
              <template v-for="orientationOption in orientationRow">
                <b-tooltip position="is-right"
                         :delay="500"
                         :label="orientationOption.label">
                  <hoops-toolbar-icon class="navbar-item"
                                      :class="{ 'has-background-primary': [currentProjection, currentOrientation].includes(getRawValue(orientationOption)) }"
                                      :type="`${orientationOption.iconType}`"
                                      @click="changePerspective(orientationOption)" />
                </b-tooltip>
              </template>
            </div>
          </template>
        </div>
      </div>
      <a class="force-item-size navbar-item normal-icon"
         :class="{ 'disabled': disabled }"
         @click="zoomIn">
        <span class="icon fa-lg fas fa-search-plus mb-1" />
      </a>
      <a class="force-item-size navbar-item normal-icon"
         :class="{ 'disabled': disabled }"
         @click="zoomOut">
        <span class="icon fa-lg fas fa-search-minus mb-1" />
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
a:hover {
  color: var(--grey-dark)
}

// HOOPS icons require custom styling
.custom-disabled {
  cursor: default;
  opacity: 0.2;
  pointer-events: none;
}

.disabled {
  color: #dbdbdb;
  cursor: default;
  pointer-events: none;
}

// Modified from bulma navbar dropdown
.force-dropdown-styling {
  border: 1px solid hsl(0, 0%, 86%) !important;
  border-radius: 4px !important;
  padding: 4px;
}

.gapped-options {
  gap: 0.5rem;
}

.gapped-options:not(:last-child) {
  margin-bottom: 0.5rem;
}

a.hoops-icon {
  border-radius: 4px;
  padding: 0.25rem;
}
div.hoops-icon {
  padding: 0;
}
div.hoops-icon > a {
  border-radius: 4px;
  padding: 0.25rem;
}

a.normal-icon {
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
}
div.normal-icon {
  padding: 0;
}
div.normal-icon > a {
  border-radius: 4px;
}
.normal-icon:hover {
  color: unset;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no-user-select {
  user-select: none;
}
</style>
