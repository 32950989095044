<template>
  <th
    data-unit="part-number-header"
    class="part-number-header"
  >
    {{ $t('partNumber') }}
  </th>
</template>

<script>
export default {
  name: 'BomHeaderPartNumber'
}
</script>
