<template>
  <div class="modal is-active">
    <div class="modal-background"/>
    <div data-int="report-an-issue-modal" class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <strong data-int="report-an-issue-modal-title">{{ $t('reportAnIssue') }}</strong>
        </p>
        <button data-int="report-an-issue-modal-cancel" class="delete" aria-label="cancel" @click="cancel"/>
      </header>
      <section class="modal-card-body">
        <section class="contact">
          <div class="field">
            <label class="label">{{ $t('subject') }}</label>
            <div class="control">
              <input v-model="subject"
                     type="text"
                     :placeholder="$t('subject')"
                     :class="{'is-danger': subjectInvalid}"
                     class="input"/>
            </div>
            <p v-if="subjectInvalid" class="help is-danger">{{ $t('subjectMessage') }}</p>
          </div>
          <div class="field">
            <label class="label">{{ $t('from') }}</label>
            <div class="control">
              <input v-model="from"
                     type="email"
                     :placeholder="$t('email')"
                     :class="{'is-danger': fromInvalid}"
                     class="input"/>
            </div>
            <p v-if="fromInvalid" class="help is-danger">{{ $t('emailMessage') }}</p>
          </div>
          <div class="field">
            <div class="control">
              <label class="checkbox">
                <input v-model="ccUser" type="checkbox"/> {{ $t('copyMeMessage') }}
              </label>
            </div>
          </div>
          <div class="field">
            <label class="label">{{ $t('message') }}</label>
            <div class="control">
              <textarea v-model="message" :class="{'is-danger': messageInvalid}" class="textarea"/>
            </div>
            <p v-if="messageInvalid" class="help is-danger">{{ $t('messageMessage') }}</p>
          </div>
          <div class="field">
            <div class="file is-info has-name is-fullwidth">
              <label class="file-label">
                <input class="file-input" type="file" @change="attachmentChanged"/>
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fa fa-upload"/>
                  </span>
                  <span class="file-label">
                    {{ $tc('attachment', 2) }}
                  </span>
                </span>
                <span class="file-name">
                  {{ attachmentName }}
                </span>
              </label>
            </div>
          </div>
        </section>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" :disabled="isInvalid" @click="send">
          {{ $t('send') }}
        </button>
        <button class="button" @click="cancel">
          {{ $t('cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  name: 'ReportAnIssueModal',
  data() {
    return {
      subject: "",
      from: null,
      message: '',
      ccUser: true,
      attachment: undefined
    };
  },
  computed: {
    ...mapState({
      content: (state) => state.content,
      book: (state) => state.content.toc.book,
      illustration: (state) => state.content.bom.illustration,
      email: (state) => !state.user.generic ? state.user.email : null
    }),
    subjectInvalid() {
      return this.subject.trim().length === 0;
    },
    fromInvalid() {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      return !reg.test(this.from);
    },
    messageInvalid() {
      return this.message.trim().length === 0;
    },
    isInvalid() {
      return this.subjectInvalid || this.fromInvalid || this.messageInvalid;
    },
    attachmentName() {
      return this.attachment ? this.attachment.name : '';
    }
  },
  methods: {
    ...mapActions({
      reportAnIssue: 'content/reportAnIssue'
    }),
    cancel() {
      this.$emit('cancel');
    },
    attachmentChanged(event) {
      if (event.target.files.length > 0) {
        [this.attachment] = event.target.files;
      }
    },
    async send() {
      let contentType;
      let mediaId;
      let chapterId;
      let pageId;
      let partId;
      let pagePartId;

      switch (this.content.contentType) {
        case 'chapter':
          mediaId = this.book ? this.book.id : null;
          contentType = this.book ? this.book.contentType : null;
          chapterId = this.content.id;
          break;
        case 'illustration':
          mediaId = this.book ? this.book.id : null;
          contentType = this.book ? this.book.contentType : null;
          pageId = this.content.id;
          break;
        case 'part': {
          if (this.content.pagePartId) {
            mediaId = this.book ? this.book.id : null;
            contentType = this.book ? this.book.contentType : null;
            pageId = this.illustration ? this.illustration.id : null;
            pagePartId = this.content.pagePartId;
          } else {
            partId = this.content.id;
          }
          break;
        }
        default: {
          mediaId = this.content.id;
          contentType = this.content.contentType || this.content.type;
        }
      }

      const payload = {
        contentType,
        mediaId,
        chapterId,
        pageId,
        partId,
        pagePartId,
        ccUser: this.ccUser,
        subject: this.subject,
        body: this.message,
        from: this.from
      };
      await this.reportAnIssue({
        payload,
        attachment: this.attachment
      });
      this.cancel();
    }
  },
  mounted() {
    this.subject = this.content?.name ?? ""
    this.from = this.email
  }
};
</script>


<style scoped>
.modal-card-body {
  flex-direction: column;
}
.button {
  margin-right: .5rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .modal-card {
    height: 40rem !important;
  }
}
@media only screen and (max-width: 769px) {
  .modal-card {
    width: 95%;
    height: 75%;
  }
}
</style>
