import { toRaw } from 'vue'
import http from '@/http'
import router from '@/router'
import i18n from '@/locales'
import { getRoute } from "@/router/getRoute"

import filters from './filters'
import content from "@/store/modules/content/content"
import { SearchCallTypes } from '@/helpers/SearchCallType'

const BROWSE_FROM_QUERY = 'BROWSE_FROM_QUERY'
const BROWSE_SEARCH = 'BROWSE_SEARCH'
const BROWSE_SEARCH_LOADING = 'BROWSE_SEARCH_LOADING'
const BROWSE_UPDATE_OFFSET = 'BROWSE_UPDATE_OFFSET'
const BROWSE_UPDATE_LIMIT = 'BROWSE_UPDATE_LIMIT'
const BROWSE_UPDATE_SORT = 'BROWSE_UPDATE_SORT'
const BROWSE_UPDATE_PATH = 'BROWSE_UPDATE_PATH'
const BROWSE_UPDATE_FROM = 'BROWSE_UPDATE_FROM'
const BROWSE_USER_EVENT = 'BROWSE_USER_EVENT'
const CLEAR_BROWSE_SEARCH_TRACKING_ID = 'CLEAR_BROWSE_SEARCH_TRACKING_ID'
const SET_BROWSE_SEARCH_TRACKING_ID = 'SET_BROWSE_SEARCH_TRACKING_ID'
const SET_PREVIOUS_SEARCH_REQUEST = 'SET_PREVIOUS_SEARCH_REQUEST'
const BROWSE_IS_SEARCHING = 'BROWSE_IS_SEARCHING'
const SET_KEEP_OFFSET = 'SET_KEEP_OFFSET'

const state = {
  items: [],
  offset: 0,
  limit: 20,
  total: 0,
  sort: '',
  direction: '',
  isLoaded: false,
  path: '',
  from: '',
  userEvent: false,
  query: null,
  searchTrackingId: null,
  previousSearchRequest: null,
  isSearching: false,
  keepOffset: false
}

const getters = {
  backToBrowseRouteDestination: (state) => {
    /**
     * Apparently, this is the determinant between browse-flow and normal-search.
     */
    if (!state.path) {
      return null
    } else {
      const queryParams = Object.assign({}, state.filters.selected)
      queryParams.q = state.filters.query || undefined
      queryParams.exact = state.filters.exact || undefined
      /*
       * `state.from` and `state.path` seem redundant,
       * but browse flows is a dangerous area to touch
       * during the Vue3 upgrade.
       *
       * This should ensure Breadcrumb restore the
       * existing query params to the browse-search URI.
       */
      if (state.from) {
        return {
          path: state.from,
          query: queryParams ? queryParams : ''
        }
      } else {
        return {
          path: `/browse/${state.path}/search`,
          query: queryParams ? queryParams : ''
        }
      }
    }
  },
  isWidgetListView(state, getters, rootState, rootGetters) {
    return rootGetters['search/isWidgetListView']
  },
  getSearchTrackingId(state) {
    return state.searchTrackingId
  }
}

const actions = {
  async search({ commit, state, getters, dispatch }) {
    try {
      const route = getRoute()
      commit(BROWSE_SEARCH_LOADING)
      let last
      const ids = route.params.id
      
      if(typeof route.params.id === 'string') {
        last = ids
      } else {
        last = ids[ids.length - 1]
      }

      const limit = getters.isWidgetListView ? 100 : state.limit
      const params = {
        limit: limit,
        offset: state.offset * limit,
        browse_id: last // browse flows has fixed search-criteria, pulled-in by Turbo
      }

      if (state.sort) {
        params[`sort_${state.direction || 'asc'}`] = state.sort
      }
      params.entity_type = '-Chapter'

      const prefixes = []
      const suffixes = []
      Object.keys(state.filters.selected).forEach((key) => {
        const prefixIndex = key.indexOf('_prefix')
        const suffixIndex = key.indexOf('_suffix')
        if (prefixIndex > -1) {
          prefixes.push(state.filters.selected[key])
        } else if (suffixIndex > -1) {
          suffixes.push(state.filters.selected[key])
        } else {
          params[key] = state.filters.selected[key]
        }
      })

      if (prefixes.length > 0) {
        params.tag_range_prefix = [prefixes.toString()]
      }
      if (suffixes.length > 0) {
        params.tag_range_suffix = [suffixes.toString()]
      }

      // We only want to use callType when hitting the first
      // search page. Leaving callType empty implies that we
      // do not want to insert a new record in the search log
      // table
      if (state.offset === 0) {
        params.callType = SearchCallTypes.BROWSEFLOW
      }

      // Prevent poor component architecture from doubling calls
      const previousRequest = toRaw(state.previousSearchRequest)
      const isMatchingRequest = JSON.stringify(previousRequest) === JSON.stringify(params)

      if (isMatchingRequest && state.isSearching) return

      commit(BROWSE_IS_SEARCHING)
      commit(SET_PREVIOUS_SEARCH_REQUEST, params)

      const { headers, data } = await http.get('search', { params })

      const items = getters.isWidgetListView && !!params.offset
      ? state.items.concat(data.items)
      : data.items

      commit(BROWSE_SEARCH, { items, meta: headers, facets: data.facets })
      if (!!data.searchLogId) {
        dispatch('setBrowseSearchTrackingId', data.searchLogId)
      }
      commit(BROWSE_UPDATE_PATH, { path: route.params.id.join('/') })

      // navigation from node with multiple items
      if (data.items.length > 1 && state.from) {
        commit(BROWSE_UPDATE_FROM, route.path)
      }
      // navigation from single product page using back button or breadcrumb
      if (data.items.length === 1 && (!route.from.includes('browse') && !route.from.includes('home'))) {
        // do not replace the path if it is already correct
        // suppresses double firing of search function and getting out of sync with userEvent
        if(route.path !== state.from) {
          await router.replace({ path: state.from })
        }
      }

      // selected node has one data item returned
      else if (data.items.length === 1 && (route.from.includes('browse') || route.from.includes('home'))) {
        await commit(BROWSE_UPDATE_FROM, route.from)
        // suppress navigation if user is setting filters.  user can still be interacting with filters
        // suppress navigation if code is firing circularly && from and current path are matching
        if (!state.userEvent && route.from !== route.path) {
          content.actions.navigateToContent('navigateToContent', {
            content: data.items[0],
            query: null,
            replace: true
          })
        }
      }

    // reset userEvent detection
      if (data.items.length) {
        commit(BROWSE_USER_EVENT, false)
      }
    } catch (err) {
      // na
    }
  },
  async setSearchPage({commit, dispatch}, page) {
    commit(BROWSE_UPDATE_OFFSET, {
      offset: Math.max(page, 0)
    })
    await dispatch('search')
  },
  setSearchResultsPerPage({commit, dispatch}, limit) {
    commit(BROWSE_UPDATE_LIMIT, {limit})
    dispatch('search')
  },
  setSort({commit, dispatch}, sort) {
    let sortWithLocale = ''
    if (sort.locale && sort.value) {
      sortWithLocale = `${sort.value}_sort_${sort.locale}`
    } else if (sort.noModifier) {
      sortWithLocale = sort.value
    } else if (sort.value) {
      sortWithLocale = `${sort.value}_sort`
    }

    commit(BROWSE_UPDATE_SORT, {sort: sortWithLocale, direction: sort.direction})
    dispatch('search')
  },
  setDefaultSort({commit}, defaultSortMode) {
    let sort = ''
    let direction = ''
    switch (defaultSortMode) {
      case 'name_asc':
        sort = `name_sort_${i18n.locale.replace('-', '_')}`
        direction = 'asc'
        break
      case 'name_desc':
        sort = `name_sort_${i18n.locale.replace('-', '_')}`
        direction = 'desc'
        break
      case 'identifier_asc':
        sort = 'identifier_sort'
        direction = 'asc'
        break
      case 'identifier_desc':
        sort = 'identifier_sort'
        direction = 'desc'
        break
      case 'description_asc':
        sort = `description_${i18n.locale.replace('-', '_')}`
        direction = 'asc'
        break
      case 'description_desc':
        sort = `description_${i18n.locale.replace('-', '_')}`
        direction = 'desc'
        break
      case 'searchable_type_asc':
        sort = 'searchable_type'
        direction = 'asc'
        break
      case 'searchable_type_desc':
        sort = 'searchable_type'
        direction = 'desc'
        break
      case 'updated_asc':
        sort = 'updated'
        direction = 'asc'
        break
      case 'updated_desc':
        sort = 'updated'
        direction = 'desc'
        break
      default:
        sort = ''
        direction = ''
    }
    commit(BROWSE_UPDATE_SORT, {sort, direction})
  },
  async navigateToSearch({ state, commit, rootGetters }, forceRouterPush = false) {
    const route = getRoute()
    // this is called by the filters that user interacts with.
    // Filters can also be set by node properties or product properties. - those init filters do not pass through here
    try {
      const queryParams = Object.assign({}, state.filters.selected)
      // Query objects will never match as one is observer so converting to string for comparison
      let queryParamsStringified = JSON.stringify(queryParams)
      const oldParams = JSON.stringify(state.query)

      if ((oldParams === queryParamsStringified) 
        && (`/browse/${state.path}/search` === route.path)
        && !forceRouterPush) {
        return
      }

      await commit(BROWSE_USER_EVENT, true)

      if (rootGetters["widgets/isWidget"] && state.keepOffset === true) {
        commit(SET_KEEP_OFFSET, false)
      } else {
        await commit(BROWSE_UPDATE_OFFSET, {offset: 0})
      }

      await commit(BROWSE_FROM_QUERY, queryParams)

      await router.push({
        path: `/browse/${state.path}/search`,
        query: queryParams
      })
    } catch (e) {
      console.log(e)
    }
  },
  resetBrowseState ({commit}) {
    commit(BROWSE_UPDATE_FROM, '')
    commit(BROWSE_UPDATE_PATH, '')
    commit(BROWSE_FROM_QUERY, null)
  },
  resetOffset({ commit }) {
    commit(BROWSE_UPDATE_OFFSET, { offset: 0 })
  },
  clearSearchTrackingId({ commit }) {
    commit(CLEAR_BROWSE_SEARCH_TRACKING_ID)
  },
  setBrowseSearchTrackingId({ commit, rootGetters, dispatch }, id ) {
    if (!id) return
    
    if (!!rootGetters['search/getSearchTrackingId']) {
      dispatch('search/clearSearchTrackingId', null, { root: true })
    }
    commit(SET_BROWSE_SEARCH_TRACKING_ID, id)
  },
  keepPagination({ commit, state }) {
    if (state.offset >= 1) {
      commit(SET_KEEP_OFFSET, true)
    }
  }
}

const mutations = {
  [SET_BROWSE_SEARCH_TRACKING_ID] (state, id) {
    state.searchTrackingId = id
  },
  [BROWSE_FROM_QUERY](state, val) {
    state.query = val
  },
  [BROWSE_SEARCH](state, { items, facets, meta }) {
    state.items = items
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0
    
    state.filters.items.forEach((filter) => {
      if (filter.type === 'facet'
        || filter.type === 'list'
        || filter.type === 'category'
        || filter.type === 'type'
        || filter.type === 'mapped') {
        const values = facets[filter.field] || []
        // Vue.set(filter, 'values', values)   --  deprecated
        filter['values'] = values
      }
    })
    state.isLoaded = true
    state.isSearching = false
  },
  [BROWSE_UPDATE_FROM](state, path) {
    state.from = path
  },
  [BROWSE_UPDATE_OFFSET](state, {offset}) {
    state.offset = offset
  },
  [BROWSE_UPDATE_LIMIT](state, {limit}) {
    state.limit = limit
    state.offset = 0
  },
  [BROWSE_UPDATE_SORT](state, {sort, direction}) {
    state.sort = sort
    state.direction = direction
  },
  [BROWSE_SEARCH_LOADING](state) {
    state.isLoaded = false
  },
  [BROWSE_UPDATE_PATH](state, {path}) {
    state.path = path
  },
  [BROWSE_USER_EVENT] (state, val) {
    state.userEvent = val
  },
  [CLEAR_BROWSE_SEARCH_TRACKING_ID] (state) {
    state.searchTrackingId = null
  },
  [BROWSE_IS_SEARCHING] (state) {
    state.isSearching = true
  },
  [SET_PREVIOUS_SEARCH_REQUEST] (state, params) {
    state.previousSearchRequest = params
  },
  [SET_KEEP_OFFSET] (state, value) {
    state.keepOffset = value
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  modules: {
    filters
  }
}
