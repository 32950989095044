<template>
  <div class="button-container">
    <button
      class="button is-primary"
      @click="clear"
    >
      <span data-testid="clear-filters">{{ $t('clearFilters') }}</span>
      <span class="icon">
        <i class="fa fa-eraser" />
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'FiltersClear',
  methods: {
    clear () {
      this.$emit('clear')
    }
  }
}
</script>

<style scoped>
.button-container {
  display: inline-flex;
  position: relative;
  vertical-align: top;
}
.icon {
  padding-top: .25rem;
}
</style>
