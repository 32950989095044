import http from '@/http';
import actions from '../actions';
import mutations from '../mutations';

export default {
  namespaced: true,
  state: {
    data: null,
    loading: false,
    error: null,
    refId: null
  },

  mutations,
  actions: {
    ...actions,
    async getProperty(context, id) {
      function processOrganizationResponse(data) {
        if (!data.parentOrgId) data.parentOrgId = null;
        return data;
      }

      try {
        context.commit('DATA_REQUEST');
        const {data} = await http.get(`org-admin/${id}`);

        context.commit('DATA_SUCCESS', processOrganizationResponse(data));
      } catch (error) {
        console.log('error', error);
        context.commit('DATA_ERROR', error);
      }
    }
  }
};
