import { getSsoLoginHost, getSsoLoginSystem, getSsoLoginBounce } from "@/components/login/getSsoLoginSystem"

export const ssoLogin = async (tenantKey) => {
  tenantKey = tenantKey.toUpperCase()
  try {
    localStorage.ssoQueryParams = window.location.href.split('?').slice(1)[0]
    
    const { data } = await getSsoLoginSystem(tenantKey)
    const { loginHost } = await getSsoLoginHost()

    console.log('ssoLogin getSsoLoginSystem data', data)
    console.log('ssoLogin loginHost', loginHost)

    if (data?.toString()?.toLowerCase() === "turbo") {
      console.log('url:', window.location.protocol +
        "//" +
        loginHost +
        `/saml/login/${tenantKey}/${window.location.hostname}`)

      window.location.replace(
        window.location.protocol +
        "//" +
        loginHost +
        `/saml/login/${tenantKey}/${window.location.hostname}`
      )
    } else {
      window.location.replace(`/Portal/saml/?sso=true&tk=${tenantKey}`)
    }
  } catch (e) {
    window.location.replace(`/Portal/saml/?sso=true&tk=${tenantKey}`)
  }
}

export const previousHostLogin = async (to) => {
  window.location.replace(
    window.location.protocol +
    "//" +
    to.query.previousHost +
    "/ui/login?cookieKey=" +
    to.query.cookieKey
  )
}

export const cookieKeyQueryLogin = async (to) => {
  await getSsoLoginBounce(to.query.cookieKey)
  window.location.replace(
    window.location.protocol + "//" + window.location.hostname + "/ui/home?cookieRefresh=true"
  )
}
