export enum HotpointLinkType {
    Media = 'Media',
    Chapter = 'Chapter',
    Page = 'Page'
}

export interface DraftHotpointLink extends Record<string, any> {
  displayName?: string,
  draftBomItemId?: number,
  hotpointLinkType: HotpointLinkType,
  id?: number,
  pendingId?: string,
  targetChapterId?: number,
  targetEntityName?: string,
  targetEntityNameLocaleId?: number,
  targetFileName?: string,
  targetPageId?: number,
  targetMediaId?: number,
  targetMediaIdentifier?: string,
  tenantId?: number,
  valid?: boolean
}

export interface ModalHotpointLink {
  bookCount?: number,
  contentType?: string,
  created: number,
  description: string,
  entityId: number,
  entityType: string,
  favorited?: boolean,
  hashKey?: string,
  identifier: string,
  mediaType?: string,
  name: string,
  pendingId?: string,
  thumbnailUrl?: string,
  unindexed: boolean,
  updated: number
}

// Enum-like object used to conditionally populate targetMediaId,
// targetChapterId, or targetPageId field depending on provided type
export const targetEntity: Record<string, string> = Object.freeze({
  [HotpointLinkType.Media]: 'targetMediaId',
  [HotpointLinkType.Chapter]: 'targetChapterId',
  [HotpointLinkType.Page]: 'targetPageId'
})
