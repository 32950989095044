<template>
  <pagination :offset="offset"
              :limit="limit"
              :total="total"
              @changePage="changePage"
              @changePerPage="changePerPage"/>
</template>

<script>
import { mapState, mapActions } from "vuex"
import Pagination from "@/components/common/Pagination"
export default {
  name: "BrowsePaginationWrapper",
  components: { Pagination },
  computed: {
    ...mapState({
      offset: ({ browse }) => browse.search.offset,
      limit: ({ browse }) => browse.search.limit,
      total: ({ browse }) => browse.search.total
    })
  },
  methods: {
    ...mapActions({
      changePage: 'browse/setSearchPage',
      changePerPage: 'browse/setSearchResultsPerPage'
    })
  }
}
</script>