import { ActionContext } from 'vuex'
import { getTenantPrivileges } from '@/controllers/global'
import { loadTenantProperties } from '@/controllers/admin'

const SET_TENANT_ACCESS_PRIVILEGES = 'SET_TENANT_ACCESS_PRIVILEGES'
const SET_TENANT_PROPERTIES = 'SET_TENANT_PROPERTIES'

// Interfaces and Types
interface TenantProps {
  [key: string]: string
}
interface TenantState {
    tenantAccessPrivileges: string[],
    tenantProperties: TenantProps
}
type Context = ActionContext<TenantState, any>

// Store module
const state = {
  tenantAccessPrivileges: [],
  tenantProperties: []
}

const getters = {
  /*
     * This check examines the tenant-level API_KEY_ADMIN permission is set
     * and, correspondingly, whether the user can provision the user group-level
     * API_KEY_ADMIN permission stored in the user module. If true, the user
     * will see the "API Key Admin" checbox in Admin UserGroups and be able to update it.
     */
  hasTenantAPIKeyAdmin (state: TenantState): boolean {
    return state.tenantAccessPrivileges.includes('API_KEY_ADMIN')
  },
  hasEnabledWidgets(state: TenantState): boolean {
    return state.tenantAccessPrivileges.includes('WIDGET_KEY_ADMIN')
  },
  hasEnabledWidgetLogo(state: TenantState): boolean {
    return state.tenantProperties.ENABLE_DOCUMOTO_WIDGET_LOGO === 'true'
  },
  hasTenantProperties(state: TenantState): boolean {
    return !!Object.keys(state.tenantProperties).length
  },
  hasEnabledIframeSupport(state: TenantState) {
    return state.tenantProperties['ENABLE_IFRAME_SUPPORT'] === "true"
  },
  hasDisabledBanner(state: TenantState) {
    return state.tenantProperties["DISABLE_LIBRARY_BANNER_IMAGE"] === "true"
  }
}

const actions = {
  async loadTenantPrivileges (context: Context, tenantKey: string): Promise<void> {
    try {
      const tenantAccessPrivileges = await getTenantPrivileges(tenantKey)
      context.commit('SET_TENANT_ACCESS_PRIVILEGES', tenantAccessPrivileges)
    } catch (error) {
      console.log('error :>> ', error)
    }
  },
  async loadTenantProperties (context: Context, tenantKey: string = context.rootState.user.tenantKey) {
    try {
      const tenantProps = await loadTenantProperties(tenantKey)
      context.commit(SET_TENANT_PROPERTIES, tenantProps)
    } catch(e) {
      console.error(e)
    }
  }
}

const mutations = {
  [SET_TENANT_ACCESS_PRIVILEGES] (state: TenantState, payload: string[]) {
    state.tenantAccessPrivileges = payload
  },
  [SET_TENANT_PROPERTIES] (state: TenantState, payload: TenantProps) {
    state.tenantProperties = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
