<template>
  <figure :class="[!thumbnailUrl && `${thumbnailType}-thumbnail`]" 
          class="image thumbnail" 
          @click="$emit('click')">
    <img  v-if="isWidget" 
          ref="img"
          data-int="thumbnail" 
          @error="emitError" />
    <img  v-else 
          :src="thumbnailUrl" 
          data-int="thumbnail" 
          @error="emitError" />
  </figure>
</template>

<script>
export default {
  name: 'Thumbnail',
  props: {
    thumbnailUrl: {
      type: String,
      default: ''
    },
    thumbnailType: {
      type: String,
      default: ''
    },
    isWidget: Boolean
  },
  methods: {
    async fetchImage() {
      if (!this.thumbnailUrl) return
      try {
        const res = await fetch(this.thumbnailUrl)
        const blob = await res.blob()
        if (!!this.$refs.img) {
          this.$refs.img.src = URL.createObjectURL(blob)
        }
      } catch(e) {
        console.error(e)
        this.emitError()
      }
    },
    emitError() {
      this.$emit('errorLoading', true)
    }
  },
  async created() {
    if (this.isWidget) {
      await this.fetchImage()
    }
  }
}
</script>

<style scoped>
.image {
  margin: auto;
}
img {
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
}
</style>
