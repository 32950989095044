<template>
  <div class="field">
    <label class="label">
      {{ item.name }}
      <span v-if="canSaveLocal"
            class="is-pulled-right">
        <label class="has-text-weight-normal mr-1">
          {{ $t('save') }}
        </label>
        <input v-model="saveLocal"
               type="checkbox"/>
      </span>
    </label>
    <div class="control is-expanded">
      <div class="select is-fullwidth" :class="{'is-danger': !isValid}">
        <select v-model="selectedOptionKey"
                :disabled="!isEditable"
                @change="change">
          <option :value="null">{{ $t('notSelected') }}</option>
          <option v-for="option in item.options"
                  :key="option.key"
                  :value="option.key">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
    <p v-if="!isValid"
       class="help is-danger">
      {{ $t('invalidField', {field: item.name}) }}
    </p>
  </div>
</template>

<script>
import getSaveLocalState from '@/components/library/orders/cells/getSaveLocalState'
import { ref } from 'vue'

export default {
  name: 'OrderCellListBox',
  props: {
    canEdit: Boolean,
    canSaveLocal: Boolean,
    data: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    isValid() {
      if (this.item.required && this.canEdit) {
        return !!this.selectedOptionKey
      }
      return true;
    },
    isEditable() {
      return this.item.editable && this.canEdit;
    }
  },
  watch: {
    saveLocal() {
      this.change();
    }
  },
  methods: {
    change() {
      this.$emit('change', {
        field: this.item,
        value: this.selectedOptionKey,
        updateLocal: this.saveLocal && this.isValid
      });
    }
  },
  setup (props) {
    const { saveLocal } = getSaveLocalState(props)
    const selectedOptionKey = ref(null)

    if (!!props.data) {
      selectedOptionKey.value = props.data
    }

    return {
      saveLocal,
      selectedOptionKey,
    }
  }
};
</script>

<style scoped>
.field {
  padding: .5rem 1rem;
  width: 100%;
}
</style>
