<template>
  <router-link :to="{ path: url }" :title="breadcrumb.name" class="breadcrumb-item">
    {{ displayName ? breadcrumb.name : '...' }}
  </router-link>
</template>

<script>
export default {
  name: 'BreadcrumbItem',
  props: {
    path: {
      type: String,
      required: true
    },
    breadcrumb: {
      type: Object,
      required: true
    },
    displayName: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    url() {
      const index = this.path.indexOf(this.breadcrumb.id);
      return `${this.path.substring(0, index)}${this.breadcrumb.id}`;
    }
  }
};
</script>

<style scoped>
.breadcrumb-item {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
