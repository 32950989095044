import { EntityType } from '@/interfaces/global'

export * from './cart'
export * from './userAction'
export * from './part'
export * from './browseFlow'
export * from './searchLog'

export enum SheetType {
  page = 'page'
}

export interface Sheet {
  contentType: SheetType
  contentUrl: string
  openZoomUrl: string
}

export interface Page {
  commentCount: number
  contentType: string
  contentUrl: string
  created: Date
  excludeFromPrint: boolean
  id: number
  name: string
  relatedCount: number
  tagCount: number
  thumbnailUrlLarge: string
  type: EntityType.page
  updated: Date
  whereUsedCount: number
  sheets?: Sheet[]
}
