import { StringToStringMap } from '@/interfaces/global'
import i18n from '@/locales'

export function importColumnTitlesList() {
  return [
    i18n.global.t('itemAlt') as string,
    i18n.global.t('partNumber') as string,
    i18n.global.t('quantity') as string,
    i18n.global.t('uom') as string,
    i18n.global.tc('supplier', 1),
    i18n.global.t('retailPrice') as string,
    i18n.global.t('wholesalePrice') as string,
    i18n.global.t('discountPrice') as string,
    i18n.global.t('partOrderable') as string,
    i18n.global.t('pagePartOrderable') as string,
    i18n.global.t('partNumberVisible') as string,
    i18n.global.t('pagePartNumberVisible') as string,
    i18n.global.t('englishName') as string,
    i18n.global.t('englishDescription') as string,
    i18n.global.t('bahasaName') as string,
    i18n.global.t('bahasaDescription') as string,
    i18n.global.t('chineseName') as string,
    i18n.global.t('chineseDescription') as string,
    i18n.global.t('czechName') as string,
    i18n.global.t('czechDescription')  as string,
    i18n.global.t('dutchName') as string,
    i18n.global.t('dutchDescription')  as string,
    i18n.global.t('finnishName') as string,
    i18n.global.t('finnishDescription')  as string,
    i18n.global.t('frenchName') as string,
    i18n.global.t('frenchDescription') as string,
    i18n.global.t('germanName') as string,
    i18n.global.t('germanDescription') as string,
    i18n.global.t('hungarianName') as string,
    i18n.global.t('hungarianDescription')  as string,
    i18n.global.t('italianName') as string,
    i18n.global.t('italianDescription')  as string,
    i18n.global.t('japaneseName') as string,
    i18n.global.t('japaneseDescription') as string,
    i18n.global.t('koreanName') as string,
    i18n.global.t('koreanDescription') as string,
    i18n.global.t('norwegianName') as string,
    i18n.global.t('norwegianDescription')  as string,
    i18n.global.t('polishName') as string,
    i18n.global.t('polishDescription') as string,
    i18n.global.t('portugueseName') as string,
    i18n.global.t('portugueseDescription') as string,
    i18n.global.t('romanianName') as string,
    i18n.global.t('romanianDescription')  as string,
    i18n.global.t('russianName') as string,
    i18n.global.t('russianDescription') as string,
    i18n.global.t('serbianName') as string,
    i18n.global.t('serbianDescription')  as string,
    i18n.global.t('swedishName') as string,
    i18n.global.t('swedishDescription') as string,
    i18n.global.t('spanishName') as string,
    i18n.global.t('spanishDescription') as string,
    i18n.global.t('turkishName') as string,
    i18n.global.t('turkishDescription')  as string,
    i18n.global.t('ukraineName') as string,
    i18n.global.t('ukraineDescription')  as string,
  ]
}

export function importColumnTitlesMap() {
    const list = importColumnTitlesList()
    const map: StringToStringMap = {}
    list.forEach(it => {
        map[it] = it
    })
    return map
}

export function getImportColumnsToBomItems() {
  return {
    [i18n.global.t('itemAlt') as string]: 'item',
    [i18n.global.t('partNumber') as string]: 'partNumber',
    [i18n.global.t('quantity') as string]: 'quantity',
    [i18n.global.t('uom') as string]: 'unitOfMeasure',
    [i18n.global.tc('supplier', 1)]: 'supplier',
    [i18n.global.t('retailPrice') as string]: 'retailPrice',
    [i18n.global.t('wholesalePrice') as string]: 'wholesalePrice',
    [i18n.global.t('discountPrice') as string]: 'discountedPrice',
    [i18n.global.t('partOrderable') as string]: 'orderableForPart',
    [i18n.global.t('pagePartOrderable') as string]: 'orderableForPagePart',
    [i18n.global.t('partNumberVisible') as string]: 'partNumberVisibleForPart',
    [i18n.global.t('pagePartNumberVisible') as string]: 'partNumberVisibleForPagePart'
  }
}


export function getTrNamesToTrCodes() {
  return {
    [i18n.global.t('englishName') as string]: 'en-US',
    [i18n.global.t('bahasaName') as string]: 'id-ID',
    [i18n.global.t('chineseName') as string]: 'zh-CN',
    [i18n.global.t('czechName') as string]: 'cs-CZ',
    [i18n.global.t('dutchName') as string]: 'nl-NL',
    [i18n.global.t('finnishName') as string]: 'fi-FI',
    [i18n.global.t('frenchName') as string]: 'fr-FR',
    [i18n.global.t('germanName') as string]: 'de-DE',
    [i18n.global.t('hungarianName') as string]: 'hu-HU',
    [i18n.global.t('italianName') as string]: 'it-IT',
    [i18n.global.t('japaneseName') as string]: 'ja-JP',
    [i18n.global.t('koreanName') as string]: 'ko-KR',
    [i18n.global.t('norwegianName') as string]: 'nn-NO',
    [i18n.global.t('polishName') as string]: 'pl-PL',
    [i18n.global.t('portugueseName') as string]: 'pt-PT',
    [i18n.global.t('romanianName') as string]: 'ro-RO',
    [i18n.global.t('russianName') as string]: 'ru-RU',
    [i18n.global.t('serbianName') as string]: 'sv-SE',
    [i18n.global.t('swedishName') as string]: 'sv-SE',
    [i18n.global.t('spanishName') as string]: 'es-ES',
    [i18n.global.t('turkishName') as string]: 'tr-TR',
    [i18n.global.t('ukraineName') as string]: 'uk-UA'
  }
}

export function getTrDescToTrCodes() {
  return {
    [i18n.global.t('englishDescription') as string]: 'en-US',
    [i18n.global.t('bahasaDescription') as string]: 'id-ID',
    [i18n.global.t('chineseDescription') as string]: 'zh-CN',
    [i18n.global.t('czechDescription')  as string]: 'cs-CZ',
    [i18n.global.t('dutchDescription')  as string]: 'nl-NL',
    [i18n.global.t('finnishDescription')  as string]: 'fi-FI',
    [i18n.global.t('frenchDescription') as string]: 'fr-FR',
    [i18n.global.t('germanDescription') as string]: 'de-DE',
    [i18n.global.t('hungarianDescription')  as string]: 'hu-HU',
    [i18n.global.t('italianDescription')  as string]: 'it-IT',
    [i18n.global.t('japaneseDescription') as string]: 'ja-JP',
    [i18n.global.t('koreanDescription') as string]: 'ko-KR',
    [i18n.global.t('norwegianDescription') as string]: 'nn-NO',
    [i18n.global.t('polishDescription') as string]: 'pl-PL',
    [i18n.global.t('portugueseDescription') as string]: 'pt-PT',
    [i18n.global.t('romanianDescription') as string]: 'ro-RO',
    [i18n.global.t('russianDescription') as string]: 'ru-RU',
    [i18n.global.t('serbianDescription') as string]: 'sv-SE',
    [i18n.global.t('swedishDescription') as string]: 'sv-SE',
    [i18n.global.t('spanishDescription') as string]: 'es-ES',
    [i18n.global.t('turkishDescription') as string]: 'tr-TR',
    [i18n.global.t('ukraineDescription') as string]: 'uk-UA'
  }
}


export function getTrCodesToNamesMap() {
  const namesToCodes = getTrNamesToTrCodes()
  const map: StringToStringMap = {}
  for (let key in namesToCodes) {
    const value = namesToCodes[key]
    map[value] = key
  }
  return map
}

export function getTrCodesToDescMap() {
  const descToCodes = getTrDescToTrCodes()
  const map: StringToStringMap = {}
  for (let key in descToCodes) {
    const value = descToCodes[key]
    map[value] = key
  }
  return map
}
