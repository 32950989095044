import { store } from '../store'

export default function setUpXmlInterceptors() {
  const oldXHROpen = window.XMLHttpRequest.prototype.open
  window.XMLHttpRequest.prototype.open = function() {
    const extended = oldXHROpen.apply(this, arguments)
    const { accessToken, hostedDomain } = store.state.auth
    if (!store.getters['auth/hasAccessTokenExpired']()) {
      this.setRequestHeader('Authorization', `Bearer ${accessToken}`)
      this.setRequestHeader('Hosted-Domain', hostedDomain) 
    }
    return extended
  }
}