import http from '@/http'

/* Query will be a stringified value of userAction and media Id type and
 * values corresponding to viewed content
 * E.g. '?userAction=0&mediaId=14'
 * Mapping of userAction values & typings
 * can be found in userAction.ts at '@/interface/library'
*/
export function saveUserAction (query: string) {
  http.post(`/user-action-log${query}`)
}
