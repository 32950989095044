import { createRouter, createWebHistory } from 'vue-router';

import AdminRoutes from './AdminRoutes';
import LoginRoutes from './LoginRoutes';
import LibraryRoutes from './LibraryRoutes';
import qs from 'qs';

/**
 * Disable vue-router while testing to allow us
 * to mock $route and $router globals and add Jest spies
 * to router functions. This lets us assert things like,
 * "$router.push() was called with the correct arguments"
 *
 */


const router = createRouter({
  history: createWebHistory('/ui/'),
  mode: 'history',
  // hash: false,
  linkActiveClass: 'is-active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {left: 0, top: 0};
  },
  parseQuery: qs.parse,
  /*
   * The default settings would add extra characters to the visible
   * URI in the address bar (e.g. mediaCategory_id[0]=13...). We do not
   * want these indices. Hence, we need to these veryyy intuitive configs.
   *
   * @param query
   * @returns {string}
   */
  stringifyQuery: function stringifyQuery(query) {
    return qs.stringify(query, { indices: false })
  },
  routes: [
    ...LoginRoutes,
    ...LibraryRoutes,
    ...AdminRoutes,
    {
      path: '/locale-bundle-tool',
      name: 'LocaleBundleTool',
      component: () => import('@/components/locales/LocaleBundleTool')
    },
    {
      path: "/:catchAll(.*)",
      name: 'Error',
      component: () => import('@/components/Error')
    }]
});

export default router
