<template>
  <div :class="{'is-disabled': disabled}" class="calendar-date">
    <button :class="{'is-today': isToday, 'is-active': isActive}" class="date-item" @click="selectDate">
      {{ day }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CalendarDay',
  props: {
    date: {
      type: Date,
      required: true
    },
    selectedDate: {
      type: Date,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    day() {
      return this.date.getDate();
    },
    isToday() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return this.date && this.date.valueOf() === today.valueOf();
    },
    isActive() {
      return this.date && this.selectedDate &&
        this.date.valueOf() === this.selectedDate.valueOf();
    }
  },
  methods: {
    selectDate() {
      this.$emit('selectDate', this.day);
    }
  }
};
</script>

<style scoped>
.calendar-date {
  padding: 0;
}
.date-item {
  padding: 0;
}
</style>
