import { Hotpoint } from '@/interfaces/admin/hotpoint'

export enum Shapes {
  rectangle = 'rectangle',
  circle = 'circle'
}

export type opacityRange = 0 | 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90 | 100

export enum Fonts {
  'times new roman' = 'times new roman',
  'andale mono' = 'andale mono',
  'arial' = 'arial',
  'arial black' = 'arial black',
  'comic sans ms' = 'comic sans ms',
  'courier new' = 'courier new',
  'georgia' = 'georgia',
  'impact' = 'impact',
  'tahoma' = 'tahoma',
  'trebuchet ms' = 'trebuchet ms',
  'verdana' = 'verdana',
  'webdings' = 'webdings'
}

export type FontSizes = 'default' | 6 | 8 | 10 | 12 | 14 | 16 | 18 | 20 | 24

export enum FontStylings {
  default = 'default',
  bold = 'bold',
  italic = 'italic',
  underline = 'underline'
}

export enum FontAlignment {
  'align-left' = 'align-left',
  'align-justify' = 'align-justify',
  'align-right' = 'align-right'
}

export interface DraftImageStore {
  font: Fonts,
  fontAlignment: FontAlignment,
  fontBold: boolean,
  fontColor: string,
  fontItalic: boolean,
  fontSize: FontSizes,
  fontUnderline: boolean,
  autoHotpointing: Boolean[],
  images: DraftImage[],
  isDirty: boolean,
  loading: Boolean,
  lineColor: string,
  lineStroke: number,
  opacity: opacityRange,
  originalRadius: Array<number> | null,
  pageId: string,
  selected: DraftImage | null,
  selectedHotpointImg: DraftImage | null,
  softDeletedImages?: DraftImage[],
  shape: Shapes,
  shapeBorderColor: string,
  shapeFillColor: string,
  shapeStroke: number,
  saving: Boolean
}

export interface DraftImage {
  id?: string
  refKey?: string
  draftPageId?: number
  created?: Date
  updated?: Date
  svgFileName?: string | null
  imageFileName: string|null
  fileLogId?: number | null
  filePath?: string
  displayOrder?: number
  imageHotPoints?: Hotpoint[]
  data: File|null
  streamData?: ReadableStream
  imageUrl?: string,
  svgElement?: SVGElement,
  width?: number,
  height?: number
}

export interface ImageDTO {
  imageFileName: string
  displayOrder?: number
}

export enum ImageEditorAttributes {
  font = 'font',
  fontAlignment = 'fontAlignment',
  fontBold = 'fontBold',
  fontItalic = 'fontItalic',
  fontColor = 'fontColor',
  fontSize = 'fontSize',
  fontUnderline = 'fontUnderline',
  lineColor = 'lineColor',
  lineStroke = 'lineStroke',
  opacity = 'opacity',
  shapeBorderColor = 'shapeBorderColor',
  shapeFillColor = 'shapeFillColor',
  shapeStroke = 'shapeStroke',
  shape = 'shape'
}

export interface ImageEditorAttributesDTO {
  type: ImageEditorAttributes,
  value: string | number | opacityRange | null | Fonts | FontSizes | boolean
}

export enum LineAttributes {
  lineColor = 'lineColor',
  lineStroke = 'lineStroke'
}

export interface LineAttributesDTO {
  type: LineAttributes,
  value: string | number
}

export enum ShapeAttributes {
  opacity = 'opacity',
  shapeBorderColor = 'shapeBorderColor',
  shapeFillColor = 'shapeFillColor',
  shapeStroke = 'shapeStroke',
  shape = 'shape'
}

export interface ShapeAttributesDTO {
  type: ShapeAttributes,
  value: string | number | opacityRange | null
}

export enum EventListeners {
  handleZoom = 'handleZoom',
  handleDraw = 'handleDraw'
}

export enum eventTypes {
  press = 'canvas-press',
  click = 'canvas-click',
  release = 'canvas-release',
  drag = 'canvas-drag',
  keydown = 'onkeydown',
  keyup = 'onkeyup',
  pan = 'pan'
}

export enum hotpointStates {
  select = 'selectHotpoints',
  move = 'moveHotpoints',
  deselect = 'deselectHotpoints',
  delete = 'deleteCallouts',
  draw = 'drawHotpoints',
  bomSelect = 'bomSelect',
  update = 'updateHotpoints',
  shapeSizing = 'shapeSizing'
}

export enum keyPressStates {
  selectAll = 'selectAll',
  deselectAll = 'deselectAll',
  moveByOne = 'moveByOne'
}

export enum moveDirection {
  up = 'up',
  down = 'down',
  right = 'right',
  left = 'left'
}

export enum arrowKey {
  arrowUp = 'arrowUp',
  arrowDown = 'arrowDown',
  arrowRight = 'arrowRight',
  arrowLeft = 'arrowLeft'
}
