<template>
  <th class="name"
      data-int="cart-header-name">
    {{ $t('name') }}
  </th>
</template>

<script>
export default {
  name: 'CartHeaderName'
};
</script>

<style scoped>
.name {
  min-width: 30vw;
}
</style>
