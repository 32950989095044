<template>
  <search-type-ahead  class="navbar-item is-marginless search-nav"
                      :placeholder="$t('searchPrompt')"
                      :value="input"
                      :clear-on-enter="false"
                      @enter="emit('update:input', $event)">
    <p class="control">
      <search-operator  :value="isExactMatch"
                        @change="emit('update:isExactMatch', $event)"/>
    </p>
  </search-type-ahead>
</template>

<script setup>
import SearchTypeAhead from '@/components/common/search/SearchTypeAhead'
import SearchOperator from '@/components/common/search/SearchOperator'

defineProps({
  input: {
    type: String,
    default: ''
  },
  isExactMatch: Boolean
})

const emit = defineEmits(['update:input', 'update:isExactMatch'])
</script>

<style lang="scss" scoped>
.search-nav {
  min-width: 50%;
  @media screen and (max-width: 640px) {
      padding-bottom: 1rem;
    }
}

</style>
