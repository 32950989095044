import http from '@/http'

const REPORT_SEARCH_TERM = 'REPORT_SEARCH_TERM'
const REPORT_SORT_FIELD = 'REPORT_SORT_FIELD'
const REPORT_SORT_DIR = 'REPORT_SORT_DIR'
const REPORT_SEARCH_LIMIT = 'REPORT_SEARCH_LIMIT'
const REPORT_SEARCH_OFFSET = 'REPORT_SEARCH_OFFSET'
const REPORTS_TOTAL = 'REPORTS_TOTAL'
const RESET_SEARCH_OFFSET = 'RESET_SEARCH_OFFSET'
const REPORT_SEARCH_NEXT_OFFSET = 'REPORT_SEARCH_NEXT_OFFSET'
const HAS_REPORTS = 'HAS_REPORTS'
const HAS_DASHBOARDS = 'HAS_DASHBOARDS'
const LOGI_USER = 'LOGI_USER'

export async function getLogiReports (reportType) {
  const params = {
    reportFolder: '/Documoto',
    reportType: reportType
  }
  return await http.get('/admin/reports/logi/all', { params }).then((res) => ({ data: res.data }))
}
export async function getAllReports (params) {
  return await http.get('/admin/reports/all', { params }).then((res) => ({ data: res.data, headers: res.headers }))
}
export async function getReport (reportId) {
  return await http.get(`/admin/reports/report/${reportId}`).then((res) => ({ data: res.data, headers: res.headers }))
}
export async function runReport (params) {
  return await http.get(`/admin/reports/logi/runReport`, { params }).then((res) => ({ data: res.data, headers: res.headers }))
}
export async function getUserReports (params) {
  return await http.get('/admin/reports', { params }).then((res) => ({ data: res.data }))
}
export async function addLoggedInUserToLogiGroup (groupName) {
  // user info taken from usercontext in api endpoint
  await http.post(`/admin/reports/logi/group/adduser/${groupName}`)
}
export async function createLogiSession () {
  return await http.get('/admin/reports/logi/session').then((res) => ({ data: res.data }))
}
export async function deleteLogiSession () {
  return await http.delete('/admin/reports/logi/logout').then((res) => ({ data: res.data }))
}
export async function getLogiUser () {
  // user info taken from usercontext in api endpoint
  return await http.get('/admin/reports/logi/user').then((res) => ({ data: res.data }))
}
export async function getLogiGroup (groupName, params) {
  return await http.get(`/admin/reports/logi/group/${groupName}`, { params }).then((res) => ({ data: res.data }))
}
export async function getReportParameters (params) {
  return await http.get('/admin/reports/logi/parameters', { params }).then((res) => ({ data: res.data }))
}

export default {
  namespaced: true,
  state: {
    isLoading: false,
    reports: null,
    searchTerm: '',
    sortField: '',
    sortDir: 'asc',
    searchOffset: 0,
    searchNextOffset: null,
    searchLimit: 10000,
    reportsTotal: 0,
    hasReports: false,
    hasDashboards: false,
    reportsLoaded: false,
    logiUser: null
  },
  actions: {
    async loadReports ({ commit, state, type = '' }) {
      try {
        commit('setLoading', {
          isLoading: true
        })
        const { data } = await http.get('/admin/reports')

        commit('reportsLoaded', {
          reports: data.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        })
      } finally {
        commit('setLoading', {
          isLoading: false
        })
      }
    },
    async loadReportsForSuperAdmin ({ commit, state }) {
      try {
        commit('setLoading', {
          isLoading: true
        })
        const sortDir = state.sortDir || 'asc'
        const sortField = state.sortField || 'name'
        const params = {
          filter: state.searchTerm || '',
          limit: state.searchLimit,
          offset: state.searchOffset,
          sort: `${sortField}:${sortDir}`
        }
        const { data, headers } = await getAllReports(params)
        commit('reportsLoaded', { reports: data })
        commit('setLoading', { isLoading: true })
        commit(REPORTS_TOTAL, { value: headers['x-count'] })
        commit(REPORT_SEARCH_NEXT_OFFSET, { value: parseInt(headers['x-offset']) + parseInt(headers['x-limit']) })
      } finally {
        commit('setLoading', {
          isLoading: false
        })
      }
    },
    setSearchTerm ({ commit }, data) {
      commit(REPORT_SEARCH_TERM, data)
      commit(RESET_SEARCH_OFFSET)
    },
    setSortField ({ commit }, data) {
      commit(REPORT_SORT_FIELD, data)
    },
    setSortDir ({ commit }, data) {
      commit(REPORT_SORT_DIR, data)
    },
    setSearchLimit ({ commit }, data) {
      commit(REPORT_SEARCH_LIMIT, data)
    },
    setSearchOffset ({ commit }, data) {
      commit(REPORT_SEARCH_OFFSET, data)
    },
    setSearchNextOffset ({ commit }, data) {
      commit(REPORT_SEARCH_NEXT_OFFSET, data)
    },
    setReportsTotal ({ commit }, data) {
      commit(REPORTS_TOTAL, data)
    },
    resetSearchOffset ({ commit }) {
      commit(RESET_SEARCH_OFFSET)
    },
    setHasReports ({ commit }, data) {
      commit(HAS_REPORTS, data)
    },
    setHasDashboards ({ commit }, data) {
      commit(HAS_DASHBOARDS, data)
    },
    setLogiUser ({ commit }, data) {
      commit(LOGI_USER, data)
    }
  },
  mutations: {
    reportsLoaded (state, { reports }) {
      state.reports = reports
      state.reportsLoaded = true
    },
    setLoading (state, { isLoading }) {
      state.isLoading = isLoading
    },
    [RESET_SEARCH_OFFSET] (state) {
      state.searchOffset = '0'
      state.searchNextOffset = null
    },
    [REPORT_SEARCH_TERM] (state, value) {
      state.searchTerm = value
    },
    [REPORT_SORT_FIELD] (state, value) {
      state.sortField = value
    },
    [REPORT_SORT_DIR] (state, value) {
      state.sortDir = value
    },
    [REPORT_SEARCH_LIMIT] (state, value) {
      state.searchLimit = value
    },
    [REPORT_SEARCH_OFFSET] (state, value) {
      state.searchOffset = value
    },
    [REPORT_SEARCH_NEXT_OFFSET] (state, value) {
      state.searchNextOffset = value
    },
    [REPORTS_TOTAL] (state, { value }) {
      state.reportsTotal = value
    },
    [HAS_REPORTS] (state, value) {
      state.hasReports = value
    },
    [HAS_DASHBOARDS] (state, value) {
      state.hasDashboards = value
    },
    [LOGI_USER] (state, value) {
      state.logiUser = value
    }
  }
}
