<template>
  <th :data-int="className">
    {{ name }}
  </th>
</template>

<script>
export default {
  name: 'CartHeaderTag',
  props: {
    column: {
      type: Object,
      required: true
    }
  },
  computed: {
    className() {
      return `cart-header-tag-${this.column.property}`;
    },
    name() {
      if (this.column.name.indexOf('%%') === 0) {
        return this.$i18n.tc(this.column.name.replace(/%%/g, ''), 2);
      }
      return this.column.name;
    }
  }
};
</script>

<style scoped>

</style>
