<template>
  <td class="cell"
      data-int="cart-cell-qty">
    <input v-if="canEdit"
           v-model="quantity"
           autocomplete="off"
           class="input"
           data-int="cart-cell-qty-input"
           max="999999999"
           min="0"
           type="number"
           :data-key="uuid"
           :key="uuid">
    <div v-else
         class="has-text-centered">
      {{ quantity }}
    </div>
  </td>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import debounce from 'lodash.debounce'
export default {
  name: 'CartCellQty',
  props: {
    item: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    quantity: {
      get: function () {
        return this.item.qty
      },
      set: debounce(function (value) {
        if (value !== '') {
          this.$emit('update', {
            item: this.item,
            qty: value
          })
        }
      }, 1000)
    }
  },
  setup () {
    return {
      uuid: uuidv4()
    }
  }
}
</script>

<style scoped>
.cell, .input {
  width: 5rem;
}
</style>
