<template>
  <hr />
</template>

<script>
export default {
  name: 'OrderCellHorizontalLine'
};
</script>

<style scoped>
hr {
  border-width: 2px;
  width: 100%;
  margin: .5rem;
}
</style>
