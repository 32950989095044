<template>
  <div class="is-flex number-input-wrap">
    <bulma-button-icon  iconClass="fa fa-minus"
                        stylingClass="no-border-radius-right"
                        @click="decrementValue"/>
    <input  :value="value"
            :min="min"
            :max="max"
            :data-int="dataInt"
            class="input center-text number-input no-border-radius"
            type="number"
            @input="updateValue"/>
    <bulma-button-icon  iconClass="fa fa-plus"
                        stylingClass="no-border-radius"
                        @click="incrementValue"/>
  </div>
</template>

<script setup lang="ts">
import BulmaButtonIcon from "@/components/common/BulmaButtonIcon.vue"
const props = defineProps({
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 999999
  },
  value: {
    type: Number,
    default: 0
  },
  dataInt: {
    type: String,
    default: ""
  }
})

const emits = defineEmits(['input'])

function updateValue(e: Event) {
  const { value }  = e.target as HTMLInputElement
  const numValue = Number(value)
  const isValidUpdate = (numValue !== props.value)
    && (numValue >= props.min)
    && (numValue <= props.max)

  if (isValidUpdate) {
    emits('input', numValue)
  }
}

function incrementValue() {
  emits('input', Math.min(props.value + 1, props.max))
}

function decrementValue() {
  emits('input', Math.max(props.value - 1, props.min))
}

</script>

<style scoped>
 /* Chrome, Safari, Edge, Opera */
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input-wrap {
  width: 100%;
}

/* Firefox */
.number-input[type=number] {
  -moz-appearance: textfield;
}
</style>
