<template>
  <th class="indicator-header" />
</template>

<script>
export default {
  name: 'BomHeaderIndicator'
}
</script>

<style scoped>
.indicator-header {
  border-right: none;
  padding: .25rem !important;
  width: .25rem !important;
}
</style>
