<template>
  <section class="columns">
    <div    v-for="(title, idx) in columns"
            :key="idx"
            :data-unit="title.field"
            :class="[ 'has-text-weight-bold no-flex-wrap',
                      title.columnClass,
                    { 'active': activeColumn === title.field,
                      'inactive': activeColumn !== title.field}]">
      <span v-if="title.field === 'checkbox'" class="center" >
        <!--  Using visibility styling rather than v-if or v-show
              keeps title columns aligned when there is no multi-select box -->
        <b-tooltip  position="is-right"
                    type="is-danger"
                    :label="$t('tooManySelections', { limit: multiSelectLimit })"
                    :style="{ 'visibility': (disableMultiSelect && !isLoading && isMultiSelect) ? 'visible' : 'hidden' }">
          <b-checkbox   data-unit="select-all"
                        size='is-small'
                        v-model="checked"
                        :style="{ 'visibility': isMultiSelect ? 'visible' : 'hidden' }"
                        :disabled="disableMultiSelect || isLoading || wrapperDisableSelectAll"
                        :indeterminate="hasIndeterminateSelection"
                        @input="$emit('toggleSelectAll', $event)"/>
        </b-tooltip>
      </span>
      <span v-else-if="title.type === 'thumbnailWithText'" class="header-thumbnail is-row"
            :style="`max-width: 90%; ${title.isSortable && 'overflow-wrap: break-word;'}`"
            :class="{ 'mr-1': title.isSortable, 'hover': !isLoading && title.isSortable }"
            @click="title.isSortable && updateSort(title.field)">
        <thumbnail class="is-square is-32x32"
                   :thumbnailUrl="title.url"
                   :thumbnailType="title.thumbnailType">
        </thumbnail>
        <div class="title-label">{{ title.translation }}</div>
        <span v-if="title.isSortable">
          <sort-toggle :active="title.field === activeColumn"
                     :invert="sortInvert"
                     :icon-large="true"
                     @click="updateSort(title.field)"/>
        </span>
      </span>
      <span v-else-if="title.field === 'icon'"
            :style="`max-width: 90%; ${title.isSortable && 'overflow-wrap: break-word;'}`"
            :class="{ 'mr-1': title.isSortable, 'hover': !isLoading && title.isSortable }"
            @click="title.isSortable && updateSort(title.field)">
            {{ title.translation }}
      </span>
      <span v-else
            :style="`max-width: 90%; ${title.isSortable && 'overflow-wrap: break-word;'}`"
            :class="{ 'mr-1': title.isSortable, 'hover': !isLoading && title.isSortable }"
            @click="title.isSortable && updateSort(title.field)">
            {{ title.translation }}
      </span>
      <span v-if="title.isSortable && title.type != 'thumbnailWithText'">
        <sort-toggle :active="title.field === activeColumn"
                     :invert="sortInvert"
                     :icon-large="true"
                     @click="updateSort(title.field)"/>
      </span>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import Thumbnail from '@/components/common/Thumbnail'
import SortToggle from '@/components/admin/SortToggle'
export default {
  name: 'VirtualScrollTitles',
  components: { Thumbnail, SortToggle },
  props: {
    columns: {
        type: Array,
        default: () => []
    },
    gridName: {
      type: String,
      default: ''
    },
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    multiSelectLimit: {
      type: Number,
      default: 250
    },
    selectedItemsMap: {
      type: Object,
      default: () => {}
    },
    wrapperDisableSelectAll: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    checked: false
  }),
  computed: {
    sortInvert () {
      return this.$store.getters['grids/sortInvert'](this.gridName) ?? true
    },
    activeColumn() {
      return this.$store.getters['grids/activeColumn'](this.gridName) ?? ''
    },
    isLoading() {
      return this.$store.state.grids.isLoading
    },
    disableMultiSelect() {
      if (this.multiSelectLimit === null) {
        return false;
      } else {
        return this.currentItemsCount > this.multiSelectLimit
      }
    },
    currentItemsCount() {
      return this.$store.getters['grids/pagination'](this.gridName)?.count ?? 0
    },
    hasSelectedAll() {
      return !this.selectedItemsCount
        ? false
        : (this.selectedItemsCount === this.currentItemsCount) && this.checked
    },
    hasIndeterminateSelection() {
      return this.checked && !this.hasSelectedAll
    },
    selectedItemsCount() {
      return this.selectedItemsMap? Object.keys(this.selectedItemsMap).length : 0
    }
  },
  watch: {
    selectedItemsCount () {
      if(this.selectedItemsCount === 0) {
        this.checked = false
      }
      if(this.selectedItemsCount === this.currentItemsCount) {
        this.checked = true
      }
    }
  },
  methods: {
    ...mapActions({
      setGridSort: 'grids/setGridSort'
    }),
    async updateSort(titleField) {
      // Do not update sort if currently loading grid
      if (this.isLoading) return
      // Ensure sort is ascending if selecting a different column
      const isDifferentColumn = titleField !== this.activeColumn
      const sortOrder = isDifferentColumn ? false : !this.sortInvert
      await this.setGridSort({ grid: this.gridName, activeColumn: titleField, sortInvert: sortOrder })
    }
  }
}
</script>
<style scoped lang="scss">
.columns {
  padding: 1em 1em 0.5em 1em;

  & > div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start
  }
}
.hover:hover {
    cursor: pointer;
}

.active {
    border-bottom: 2px solid var(--grey-darker);
}
.inactive {
    border-bottom: 2px solid rgb(219, 219, 219);
}
.no-flex-wrap {
    display: flex;
    flex-wrap: nowrap;
}
.borders {
    /* border: solid 1px var(--grey-darker--25--rgba); */
    border-right: solid 1px var(--grey-darker--25--rgba);
    border-bottom: solid 1px var(--grey-darker--25--rgba);
    padding: .5em 0 .5em .5em;
}
.bom-columns {
    margin-top: 0;
    margin-bottom: 0;
    border-top: solid 1px var(--grey-darker--25--rgba);
}
.bom-columns:first-child {
    border-left: solid 1px var(--grey-darker--25--rgba);
}
.is-row {
  display: flex;
  align-items: center;
  .image {
    margin-right: 10px;
    padding-top: 0px;
  }
  .title-label {
    margin-right: 5px;
  }
}
</style>
