<template>
  <main v-if="shelf" data-int="shelf" class="section is-paddingless">
    <hero :button-label="$t('goToSearch')" />

    <section class="shelf-container">
      <div>
        <router-link :to="{name: 'Favorites'}">
          {{ $t('favoritesLists') }}
        </router-link>
      </div>
      <div class="pad-bottom">
        <h1 class="title is-size-3 is-marginless header-bar">
          <template v-if="!editable">
            <label>{{ shelf.name }}</label>
            <button class="button" @click="editClicked()">
              <i class="fas fa-edit" />
            </button>
            <button class="button" @click="deleteClicked()" :class="{'is-danger': deleteSure}">
              <i class="fas fa-trash" />
              <template v-if="deleteSure">
                &nbsp; {{ $t('deleteMessage') }}
              </template>
            </button>
          </template>
          <template v-else>
            <input type="text"
                   class="input"
                   v-model="shelfName"
                   @keydown.enter="editClicked()"
                   @keydown.esc="cancelClicked" />
            <button class="button is-primary" @click="editClicked()">
              {{ $t('save') }}
            </button>
            <button class="button" @click="cancelClicked()">
              {{ $t('cancel') }}
            </button>
          </template>
        </h1>
      </div>
      <div class="columns is-mobile is-multiline" v-if="!!items.length">
        <div v-for="(item, index) in items"
             :key="index"
             class="column is-2-desktop is-3-tablet is-4-mobile">
          <card data-int="shelf-item" :item="item" @click="openItem(item)" />
        </div>
      </div>
      <div v-else>
        {{ $t('noItemsFound') }}
      </div>
    </section>
  </main>
</template>

<script>
import http from '@/http';

import Card from '@/components/common/Card';
import Hero from '@/components/common/Hero';

export default {
  components: {
    Card,
    Hero
  },
  data() {
    return {
      deleteSure: false,
      editable: false,
      shelf: null,
      shelfName: null,
      items: []
    };
  },
  created() {
    this.fetchShelf();
    this.fetchShelfItems();
  },
  computed: {
    shelfId() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchShelf() {
      try {
        const {data} = await http.get(`media-shelves/${this.shelfId}`);
        this.shelf = data;
        document.title = data.name
        this.shelfName = this.shelf.translations['en-US'];
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    async fetchShelfItems() {
      try {
        const {data} = await http.get(`media-shelves/${this.shelfId}/items`);
        this.items = data;
      } catch (error) {
        console.log('error :>> ', error);
      }
    },
    openItem(item) {
      this.$router.push({
        path: `/${item.mediaType}/${item.entityId}`
      });
    },
    async editClicked() {
      if (this.editable) {
        if (this.shelfName != this.shelf.name) {
          try {
            await http.put(`media-shelves/${this.shelfId}`, {
              ...this.shelf,
              translations: {
                'en-US': this.shelfName
              }
            });
            await this.fetchShelf();
          } catch (error) {
            console.log('error :>> ', error);
          }
        }
      }

      this.editable = !this.editable;
    },
    async cancelClicked() {
      await this.fetchShelf();
      this.editable = false;
    },
    async deleteClicked() {
      if (this.deleteSure) {
        try {
          await http.delete(`media-shelves/${this.shelfId}`);
          this.$router.push({name: 'Favorites'});
        } catch (error) {
          console.log('error :>> ', error);
        }
      } else {
        this.deleteSure = true;
        await this.$wait(10000);
        this.deleteSure = false;
      }
    }
  }
};
</script>

<style scoped>
.shelf-container {
  padding: 1rem;
}
.pad-bottom {
  padding-bottom: 1rem;
}
.header-bar {
  display: flex;
}
.header-bar > * {
  margin: 0 0.125rem;
}
.input {
  flex-basis: 25vw;
}
</style>
