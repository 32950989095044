import { ActionContext, MutationTree } from 'vuex'
import { fetchAllPartComments } from '@/controllers/library'

type PartCommentState = {
  items: Array<unknown>
}
type Context = ActionContext<any, any>
enum MutationTypes {
  SET_PART_COMMENTS = 'SET_PART_COMMENTS'
}

const state: PartCommentState = {
  items: []
}

const actions = {
  async getComments({ commit }: Context, id: number) {
    try {
      const comments = await fetchAllPartComments(id)
      commit(MutationTypes.SET_PART_COMMENTS, comments)
    } catch(e) {
      console.error(e)
    }
  }
}

const mutations: MutationTree<PartCommentState> = {
  [MutationTypes.SET_PART_COMMENTS] (state, payload: Array<unknown>) {
    state.items = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}