<template>
  <section class="section">
    <a :href="content.contentUrl" download>
      <thumbnail :thumbnail-url="content.thumbnailUrl"
                 :thumbnail-type="entityType"
                 data-int="download-thumbnail"
                 class="is-128x128"/>
      <label class="is-size-5 has-text-centered">{{ $t('clickToDownload') }}</label>
    </a>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Thumbnail from '@/components/common/Thumbnail';

export default {
  name: 'DownloadViewer',
  components: {
    Thumbnail
  },
  computed: {
    ...mapState({
      content: ({ content }) => content
    }),
    entityType() {
      return this.content.mediaType || this.content.entityType;
    }
  }
};
</script>

<style scoped>
.section {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.is-128x128 {
  width: initial;
}
</style>
