<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t('delete') }}
        </p>
      </header>
      <section class="modal-card-body">
        {{ $t(deleteMessageOverride || 'deleteMessage') }}
      </section>
      <footer class="modal-card-foot">
        <b-field
          grouped
          position="is-right"
          class="is-full-width"
        >
          <b-button
            @click="cancel"
            tabindex="0"
          >
            {{ $t('cancel') }}
          </b-button>
          <b-button
            @click="deleteConfirm"
            tabindex="1"
            type="is-danger"
          >
            {{ $t('delete') }}
          </b-button>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    deleteMessageOverride: {
      type: String,
      default: ''
    },
    /* eslint-disable */
    // TODO set a type and test it
    item: {
      required: true
    }
    /* eslint-enable */
  },
  methods: {
    deleteConfirm () {
      this.$emit('deleteConfirm', this.item)
    },
    cancel () {
      this.$emit('cancel')
    }
  },
  mounted () {
    const deleteButton = document.getElementsByClassName('delete-button')

    if (deleteButton.length) {
      document.getElementsByClassName('delete-button')[0].focus()
    }
  }
}
</script>

<style scoped>
.modal-card {
  width: auto;
  max-width: 60%;
}
.modal-card-body {
  color: #4a4a4a;
  min-height: 5rem;
}
</style>
