<template>
  <section class="section is-paddingless">
    <iframe v-if="isWidget" 
            ref="iframe"/>
    <iframe v-else
            :src="content.micrositeEntryFile" />
  </section>
</template>

<script>
import { mapState, useStore } from 'vuex'
import { ref } from 'vue'
import { useFetchImage } from '@/components/widgets/composables/useFetchImage'

export default {
  name: 'MicrositeViewer',
  setup() {
    const iframe = ref()

    const store = useStore()
    const { imgSrc, isWidget } = useFetchImage(
      store.state.content.micrositeEntryFile, 
      iframe)
    
    return { imgSrc, isWidget, iframe }
  },
  computed: {
    ...mapState({
      content: (state) => state.content
    })
  }
}
</script>

<style scoped lang="scss">
.section {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  position: relative;
}
.image-centered {
  margin: auto;
}
iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>