<template>
  <nav role="navigation" class="pagination is-marginless">
    <span class="pagination-next is-paddingless">
      <div data-int="page-limit" class="dropdown is-right is-hoverable is-up">
        <div class="dropdown-trigger">
          <button data-int="page-limit-dropdown-trigger" class="button">
            <span>{{ limit }}</span>
            <span class="icon is-small"><i class="fa fa-angle-up"/></span>
          </button>
        </div>
        <div id="dropdown-menu" role="menu" class="dropdown-menu">
          <div class="dropdown-content">
            <a v-for="l in limits"
               :key="l"
               data-int='page-limit-value'
               :class="{'is-active': l === limit}"
               class="dropdown-item"
               @click="changePerPage(l)">
              {{ l }}
            </a>
          </div>
        </div>
      </div>
    </span>
    <span>
      <slot/>
    </span>
    <ul v-if="hasMultiplePages" class="pagination-list">
      <li v-if="showFirstPage">
        <a data-int="first-page" class="pagination-link first-page" @click="changePage(firstPage)">
          {{ firstPage }}
        </a>
      </li>
      <li v-if="showPrevEllipsis">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li v-if="showPrevPage">
        <a data-int="prev-page" class="pagination-link prev-page" @click="changePage(prevPage)">
          {{ prevPage }}
        </a>
      </li>
      <li>
        <a data-int="current-page" class="pagination-link is-current">
          {{ currentPage }}
        </a>
      </li>
      <li v-if="showNextPage">
        <a data-int="next-page" class="pagination-link next-page" @click="changePage(nextPage)">
          {{ nextPage }}
        </a>
      </li>
      <li v-if="showNextEllipsis">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li v-if="showLastPage">
        <a data-int="last-page" class="pagination-link last-page" @click="changePage(lastPage)">
          {{ lastPage }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default ({
  name: 'Pagination',
  props: {
    limit: {
      type: Number,
      required: true
    },
    offset: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      limits: [5, 10, 20, 50]
    };
  },
  computed: {
    hasMultiplePages() {
      return this.total > this.limit;
    },
    firstPage() {
      return 1;
    },
    showFirstPage() {
      return this.prevPage > 1;
    },
    showPrevEllipsis() {
      return this.prevPage > 2;
    },
    prevPage() {
      return this.currentPage - 1;
    },
    showPrevPage() {
      return this.currentPage > 1;
    },
    currentPage() {
      return Math.ceil(this.offset + 1);
    },
    nextPage() {
      return this.currentPage + 1;
    },
    showNextPage() {
      return this.currentPage < this.lastPage;
    },
    showNextEllipsis() {
      return this.nextPage < this.lastPage - 1;
    },
    lastPage() {
      return Math.ceil(this.total / this.limit);
    },
    showLastPage() {
      return this.currentPage < this.lastPage - 1;
    }
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page - 1);
    },
    changePerPage(limit) {
      this.$emit('changePerPage', limit);
    }
  }
});
</script>

<style scoped>
.pagination {
  padding: .5rem 1rem;
  background-color: white;
}
.pagination-next {
  border: none;
  justify-content: flex-start;
}
@media only screen and (max-width: 768px) {
  .dropdown.is-right .dropdown-menu {
    right: unset;
  }
}
</style>
