<script>
import { h } from 'vue'
export default {
    name: 'BFieldBody',
    props: {
        message: {
            type: [String, Array]
        },
        type: {
            type: [String, Object]
        }
    },
    render() {
        let first = true
        return h(
          'div',
    { class: 'field-body' },
          this.$slots.default.map((element) => {
            // skip returns and comments
            if (!element.tag) {
                return element
            }
            let message
            if (first) {
                message = this.message
                first = false
            }
            return h('b-field', { attrs: { type: this.type, message } }, [element])
        }))
    }
}
</script>
