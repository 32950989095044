import { UserActionMap, Content, MediaTypesToIds } from '@/interfaces/library'

interface IdMap extends Record<string, number|undefined>{
  id?: number,
  chapter?: number,
  page?: number,
  pagepart?: number
}

// Returns a mapped object of media types and id values from URL
export function getParsedIds (url: string): IdMap {
  const idMap: IdMap = {}
  const entityIds = url.split('/')
  entityIds.forEach(entity => {
    const entityAndId = entity.split(':')
    if (entityAndId.length === 1) {
      idMap.id = Number(entityAndId[0])
    } else if (entityAndId.length === 2) {
      idMap[entityAndId[0]] = Number(entityAndId[1])
    }
  })
  return idMap
}

export function getUserActionQuery (url: string, type: string): string|null {
  // Get object map containing proper values for userAction and all media Ids
  const userActionLog = getUserActionMap(url, type)

  // Build and return stringified query param of key/value pairs in userActionLog
  let query = ''
  let prefaceValue = '?'
  for (const key in userActionLog) {
    query = query.concat(`${prefaceValue}${key}=${userActionLog[key]}`)
    prefaceValue = '&'
  }
  // Per discussion with Product: invalid query will be logged in the console
  if (query.includes('undefined')) {
    return null
  }
  return query
}

export function getUserActionMap (url: string, type: string): UserActionMap {
  // Get naive mapping of media key/value pairs from URL
  const idMap = getParsedIds(url)

  /* Build 2nd mapping object to store userAction type and media Ids
   * that can be derived from idMap
   * On initialization, type can be used to determine whether the first
   * id value in URL is a mediaId, bookId, partId, etc.
   * E.g., with url of '14/chapter:65/page:801/pagepart:16181',
   * 14 would be set as value of bookId
   */
  const log: UserActionMap = {
    userAction: Content[type],
    [MediaTypesToIds[type]]: idMap.id
  }

  // Check idMap for additional key/value pairs of media and Ids
  // & conditionally update log as appropriate to accomodate
  // complex URL paths such as '14/chapter:65/page:801/pagepart:16181'
  if (idMap?.chapter) {
    log.chapterId = idMap.chapter
    log.userAction = Content.chapter
  }
  if (idMap?.page) {
    log.pageId = idMap.page
    log.userAction = Content.page
  }
  if (idMap?.pagepart) {
    log.pagePartId = idMap.pagepart
    log.userAction = Content.part
  }

  return log
}
