<template>
  <td data-int="cart-cell-uom">
    {{ item.uom }}
  </td>
</template>

<script>
export default {
  name: 'CartCellUom',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
