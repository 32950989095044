import http from '@/http'

export async function getSsoLoginSystem (tenantKey) {
  return await http.get(`/auth/sso-login-system/${tenantKey}`).then((res) => ({ data: res.data }))
}

export async function getSsoLoginHost () {
  return await http.get(`/auth/sso-login-host`).then((res) => ({ loginHost: res.data }))
}

export async function getSsoLoginBounce (cookieKey) {
  return await http.get(`/auth/sso-login-bounce/${cookieKey}`)
}
