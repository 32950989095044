import { Translation } from '@/interfaces/admin'
import { cloneDeep } from "lodash";

export function getUpdatedTranslations (value: string, locale: string, partTranslations: Translation[]): Translation[] {
  const translationIndex = partTranslations
    .findIndex((it: Translation) => it.lang === locale)
  const updatedTranslations = cloneDeep(partTranslations)
  if (translationIndex === -1) {
    updatedTranslations.push({
      desc: '',
      lang: locale,
      name: value
    })
  } else {
    updatedTranslations[translationIndex].name = value
  }
  return updatedTranslations
}
