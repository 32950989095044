<template>
  <th
    class="price-header"
    :class="className"
    :data-int="className"
    @click="showHidePrice"
  >
    <label :data-unit="className[0]">{{ label }}</label>
    <a
      v-if="hasHidePrice"
      data-toggle="tooltip"
      :title="label"
      class="is-pulled-right"
    >
      <span class="icon is-small has-text-link">
        <i
          class="fa"
          :class="icon"
        />
      </span>
    </a>
  </th>
</template>

<script>
export default {
  name: 'BomHeaderPrice',
  props: {
    column: {
      type: Object,
      required: true
    },
    hasHidePrice: Boolean,
    hideRetailPrice: Boolean,
    hideDiscountPrice: Boolean,
    hideWholeSalePrice: Boolean
  },
  computed: {
    className () {
      return [`bom-price-${this.column.property}`, this.isHidden ? 'bom-price-hidden' : '']
    },
    isHidden () {
      switch (this.column.property) {
        case 'retail':
          return this.hideRetailPrice
        case 'wholesale':
          return this.hideWholeSalePrice
        case 'discounted':
          return this.hideDiscountPrice
        default:
          return false
      }
    },
    label () {
      let label = ''
      switch (this.column.property) {
        case 'retail':
          label = this.$i18n.t('retail')
          break
        case 'wholesale':
          label = this.$i18n.t('wholesale')
          break
        case 'discounted':
          label = this.$i18n.t('discounted')
          break
        default:
          break
      }
      return !this.isHidden ? label : ''
    },
    icon () {
      return !this.isHidden ? 'fa-eye' : 'fa-eye-slash'
    }
  },
  methods: {
    showHidePrice () {
      this.$emit('togglePrice', { property: this.column.property })
    }
  }
}
</script>

<style scoped>
.bom-price-hidden {
  width: 1.5rem;
}
</style>
