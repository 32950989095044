<template>
  <th data-int="cart-header-availability">
    {{ $t('availability') }}
  </th>
</template>

<script>
export default {
  name: 'CartHeaderAvailability'
};
</script>

<style scoped>
</style>
