<template>
  <div class="is-active modal">
    <div class="modal-background"
         @click="close" />
    <div class="modal-card rounded-borders">
      <div class="modal-card-body is-flex-direction-column">
        <div class="mb-2">
          <button class="is-pulled-right delete"
                  aria-label="close"
                  @click="close" />
          <div class="title-five">
            <slot name="title" />
          </div>
        </div>

        <slot name="body" />
      </div>
      <footer v-show="!hideActions"
              class="modal-card-foot justify-to-end">
          <slot name="actions">
            <button class="button" @click="close">
              <span>{{ i18n.global.t('close') }}</span>
            </button>
          </slot>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import i18n from '@/locales'

const emit = defineEmits(['close'])
defineProps({
  hideActions: Boolean
})
const close = () => emit('close')
</script>

<style scoped lang="scss">
.justify-to-end {
  justify-content: end;
}

.rounded-borders {
  border-radius: 6px;
}
</style>
