<template>
  <div class="cart-table">
    <div class="table-wrapper">
      <table
        data-int="cart-title"
        class="table is-bordered is-striped is-narrow is-marginless"
        :class="{'is-hoverable': items.length}"
      >
        <thead>
          <tr>
            <cart-header
              v-for="column in columns"
              :key="column.property"
              :can-edit="canEdit"
              :column="column"
            />
          </tr>
        </thead>
        <tfoot v-if="items.length && hasPricing">
          <tr>
            <cart-footer
              v-for="column in columns"
              :key="column.property"
              :can-edit="canEdit"
              :column="column"
              :total-retail-price="totalRetailPrice"
              :total-wholesale-price="totalWholesalePrice"
              :total-discounted-price="totalDiscountedPrice"
            />
          </tr>
        </tfoot>
        <tbody v-if="!isLoaded">
          <tr>
            <td
              class="no-data"
              :colspan="numOfColumns"
            >
              <loading-icon />
            </td>
          </tr>
        </tbody>
        <tbody
          v-else-if="items.length && isLoaded"
          class="table-body"
        >
          <tr
            v-for="item in items"
            :key="item.id"
            :class="{'is-selected': selected === item}"
            class="cart-item"
            @click="select(item)"
          >
            <cart-cell
              v-for="column in columns"
              :key="column.property"
              :column="column"
              :item="item"
              :can-edit="canEdit"
              @updateQuantity="updateQuantity"
              @removeFromCart="removeFromCart"
            />
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              class="no-data subtitle"
              :colspan="numOfColumns"
            >
              {{ $t('noItemsFound') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <nav class="level is-mobile">
      <slot />
    </nav>
  </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon'
import CartHeader from './headers/CartHeader'
import CartCell from './cells/CartCell'
import CartFooter from './footers/CartFooter'

/**
 * Shared component between cart and order view of their cart items via their
 * respective wrappers
 */
export default {
  name: 'CartTable',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isLoaded: Boolean,
    columns: {
      type: Array,
      default: () => []
    },
    hasPricing: Boolean,
    selected: {
      type: Object,
      default: () => {}
    },
    totalRetailPrice: {
      type: String,
      default: ''
    },
    totalWholesalePrice: {
      type: String,
      default: ''
    },
    totalDiscountedPrice: {
      type: String,
      default: ''
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },
  components: {
    CartHeader,
    CartCell,
    CartFooter,
    LoadingIcon
  },
  computed: {
    numOfColumns () {
      return this.columns.length
    }
  },
  methods: {
    updateQuantity (updateObject) {
      this.$emit('updateQuantity', updateObject)
    },
    removeFromCart (item) {
      this.$emit('removeFromCart', item)
    },
    select (item) {
      this.$emit('select', { item })
    }
  }
}
</script>

<style scoped>
  .table-wrapper {
    overflow: auto;
    flex-grow: 1;
  }
  .cart-table {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    vertical-align: middle;
    min-width: 100%;
  }
  .table {
    min-width: 100%;
  }
  .level {
    margin-top: .5rem;
    margin-bottom: 1rem;
  }
  .no-data {
    text-align: center;
    vertical-align: middle;
    height: calc(100vh - 22rem);
  }
  @media only screen and (min-width: 769px) {
    thead th {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      background: white;
      box-shadow: 1px 0 1px 0 #CCCCCC;
      z-index: 10;
    }
    tfoot td {
      position: sticky;
      position: -webkit-sticky;
      bottom: 1px;
      background: white;
      box-shadow: 0 1px 1px 0 #CCCCCC;
      z-index: 10;
    }
    .no-data {
      text-align: center;
      vertical-align: middle;
      height: calc(100vh - 37rem);
    }
  }
</style>
