// this sorts grid passed in as array by the property name: 'sortField' ascending or descending: 'sortInvert'
// sorts string types and date types, but can be extended as needed
export function SortGrid (grid: Array<any>, params: { sortField: string, sortInvert: boolean}) {
  let copyGrid = [...grid]
  const isDate = params.sortField.toLowerCase() === 'created' || params.sortField.toLowerCase() === 'updated' || params.sortField.toLowerCase() === 'contentupdated'
  copyGrid.sort((a: any, b: any) => {
    if(typeof a[params.sortField] !== 'undefined' &&  typeof b[params.sortField] !== 'undefined') {
      let fieldA = isDate
        ? new Date(a[params.sortField])
        : isNaN(a[params.sortField])
          ? a[params.sortField].toLowerCase()
          : a[params.sortField]
      let fieldB = isDate
        ? new Date(b[params.sortField])
        : isNaN(b[params.sortField])
          ? b[params.sortField].toLowerCase()
          : b[params.sortField]
      if(params.sortInvert) {
        return fieldA > fieldB
          ? -1
          : fieldA < fieldB
            ? 1
            : 0
      } else {
        return fieldA < fieldB
          ? -1
          : fieldA > fieldB
            ? 1
            :0
      }
    } else {
      if(params.sortInvert) {
        if(!a[params.sortField]) return 1
        if(!b[params.sortField]) return -1
        return 0
      } else {
        if(!a[params.sortField]) return -1
        if(!b[params.sortField]) return 1
        return 0
      }
    }

  })
  return copyGrid
}
