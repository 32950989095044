import { AnalyticsHTMLTag, AnalyticsParentHTMLTag, AnalyticsScript, LoadAnalyticsProps } from '@/interfaces/global'
import http from '@/http'
/**
 * Loads user provided analytics script
 */
export const loadAnalytics = async (payload: LoadAnalyticsProps): Promise<void> => {
  if ( (!payload?.ok?.length) && (!payload?.tk?.length) ) return
  const reqUrl = `/analytics?tk=${payload.tk || ''}&ok=${payload.ok || ''}`
  const results:AnalyticsScript[] = await http.get(reqUrl).then(res => res.data)

  if (results) {
    results.forEach(field => {
      const parentTag: HTMLHeadElement | HTMLBodyElement | null = document.querySelector(field.parent)

      // Cannot have both src and contents. src attribute takes precedence
      if ((field.src && field.contents) || !['body', 'head'].includes(field.parent) || !['script', 'noscript'].includes(field.tag) || (field.parent === AnalyticsParentHTMLTag.head && field.tag === AnalyticsHTMLTag.noscript)) {
        throw new Error('Invalid Analytics Script Provided')
      }

      let el

      if (field.tag === AnalyticsHTMLTag.script) {
        el = document.createElement('script')

        el.type = 'text/javascript'
        el.defer = true

        if (field.contents) {
          el.innerText = field.contents
        }

        if (field.src) {
          el.src = field.src
        }
      } else {
        el = document.createElement('noscript')

        if (field.contents) {
          el.innerHTML = field.contents
        }
      }

      if (parentTag) {
        parentTag.appendChild(el)
      }
    })
  }
}
