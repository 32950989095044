<template>
  <div class="view-container">
    <div class="dropdown is-right is-hoverable"
         data-int="view-dropdown">
      <div class="dropdown-trigger">
        <button class="button"
                data-int="view-dropdown-trigger">
          <span data-testid="displayed-option">{{ selectedViewMode }}</span>
          <span class="icon is-small"><i class="fa fa-angle-down"/></span>
        </button>
      </div>
      <div id="dropdown-menu"
           class="dropdown-menu"
           role="menu">
        <div class="dropdown-content">
          <a v-for="(option, index) in options"
             :key="index"
             :class="{'is-active': viewModeKey === option.value}"
             class="dropdown-item"
             data-int="view-value"
             data-testid="view-value"
             @click="$emit('setMode', option.value)">
            {{ option.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    viewModeKey: {
      type: String,
      required: true
    }
  },
  computed: {
    options () {
      return [
        {
          value: 'list',
          name: this.$i18n.t('viewModeList')
        },
        {
          value: 'table',
          name: this.$i18n.t('viewModeTable')
        }
      ]
    },
    selectedViewMode () {
      return this.options.find(({ value }) => value === this.viewModeKey).name
    }
  }
}
</script>
