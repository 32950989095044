import * as types from '../mutations/mutations-types'
import http from '@/http'

export default {

  async getProperty (context, payload) {
    context.commit(types.DATA_REQUEST)

    let url

    if (typeof payload === 'string') {
      url = payload
    } else {
      url = payload.url
    }

    try {
      const { data, headers } = await http.get(url)

      if (context.state.pagination) {
        context.commit(types.SET_PAGINATION, {
          count: Number(headers['x-count']),
          limit: Number(headers['x-limit']),
          offset: Number(headers['x-offset']),
          pageCount: Number(headers['x-page-count']),
          currentPage: Number(headers['x-page-num'])
        })
      }

      if (payload.key) {
        context.commit(types.DATA_SUCCESS, data[payload.key])
      } else {
        context.commit(types.DATA_SUCCESS, data)
      }
    } catch (error) {
      context.commit(types.DATA_ERROR, error)
    } finally {
      context.commit(types.DATA_LOADING, false)
    }
  },
  async createProperty (context, { url, payload, key = '' }) {
    context.commit(types.CREATE_REQUEST)

    try {
      const result = await http.post(url, payload).then((res) => res.data)
      if (key) {
        context.commit(types.CREATE_SUCCESS, result[key])
      } else {
        context.commit(types.CREATE_SUCCESS, result)
      }
      /**
       * refresh with another get here?
       */
    } catch (error) {
      context.commit(types.CREATE_ERROR, error)
    }
  },
  async updateProperty (context, { url, payload, key = '' }) {
    context.commit(types.UPDATE_REQUEST)

    try {
      const result = await http.put(url, payload).then((res) => res.data)
      if (key) {
        context.commit(types.UPDATE_SUCCESS, result[key])
      } else {
        context.commit(types.UPDATE_SUCCESS, result)
      }
      /**
       * refresh with another get here?
       */
    } catch (error) {
      context.commit(types.UPDATE_ERROR, error)
    }
  },
  async deleteProperty (context, url) {
    context.commit(types.DELETE_LOADING)
    try {
      const result = await http.delete(url).then((res) => res.data)
      if (result) {
        context.commit(types.DELETE_SUCCESS, result)
      /**
       * refresh with another get here?
       */
      }
    } catch (error) {
      context.commit(types.DELETE_ERROR, error)
    }
  },
  setRefId (context, data) {
    context.commit(types.SET_REF_ID, data)
  },
  resetPagination (context) {
    context.commit(types.RESET_PAGINATION)
  },
  setPagination (context, data) {
    context.commit(types.SET_PAGINATION, data)
  }
}
