<template>
  <article class="media" @dblclick.stop="open">
    <figure class="media-left" @click="open">
      <span class="icon is-large">
        <i :class="iconClass" class="fa fa-3x"/>
      </span>
    </figure>
    <div class="media-content">
      <div class="content">
        <p class="is-marginless">
          <router-link :to="{ name: 'Notification', params: { id: notification.id }}">
            <strong>{{ notification.name }}</strong>
          </router-link>
        </p>
      </div>
    </div>
    <div class="media-right">
      {{ $d(notification.startDate, 'short') }}
    </div>
  </article>
</template>

<script>
export default {
  name: 'NotificationResult',
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  computed: {
    iconClass() {
      const unreadAndRequired =
        this.notification.required &&
        !this.notification.viewed;
      const unread =
        !this.notification.required &&
        !this.notification.viewed;
      return {
        'has-text-success': this.notification.viewed,
        'has-text-warning': unread,
        'has-text-danger': unreadAndRequired,
        'fa-check-circle': this.notification.viewed,
        'fa-exclamation-circle': unread || unreadAndRequired
      };
    }
  },
  methods: {
    open() {
      this.$emit('open', this.notification);
    }
  }
};
</script>

<style scoped>
figure {
  cursor: pointer;
}
.media {
  margin: 0 !important;
  padding: .5rem;
}
.media:hover {
  background: #F3F2F3;
}
.media:nth-child(even) {
  background: #fafafa;
}
</style>
