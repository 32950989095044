<template>
  <main class="order-container">
    <section class="order-submitted">
      <h1 class="subtitle is-size-5 is-marginless">
        {{ message || $t('orderSubmittedMessage') }}
      </h1>
      <router-link :to="{ name: 'Order', params: { id: order.id } }">
        <h1 class="is-size-5 is-marginless ">
          #{{ order.orderNumber }}
        </h1>
      </router-link>
      <br><br>
      <a
        :href="downloadURL"
        download
        class="download"
      >
        <figure class="is-128x128">
          <span class="icon is-xlarge">
            <i class="fa fa-5x fa-download" />
          </span>
        </figure>
        <label>{{ $t('clickToDownload') }}</label>
      </a>
    </section>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  name: 'OrderSubmitted',
  computed: {
    ...mapState({
      order: ({ order }) => order.info,
      message: ({ order }) => order.message
    }),
    downloadURL () {
      return `/api/orders/${this.order.id}/print/pdf`
    }
  },
  methods: {
    ...mapActions({
      navigateToCart: 'order/navigateToCart'
    })
  },
  async created () {
    if (!this.order) {
      this.navigateToCart()
    }
  }
}
</script>

<style scoped>
.order-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-top: .5rem;
}
.download {
  text-align: center;
}
.order-submitted {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.is-xlarge {
  width: 5rem;
  height: 5rem;
}
</style>
