import http from '@/http'

export const SEARCH_FILTERS_RECIEVED = 'SEARCH_FILTERS_RECIEVED'
export const SEARCH_FILTERS_SET_SELECTED = 'SEARCH_FILTERS_SET_SELECTED'
export const SEARCH_FILTERS_SET_EXACT = 'SEARCH_FILTERS_SET_EXACT'
export const SEARCH_FILTERS_CLEAR_SELECTED = 'SEARCH_FILTERS_CLEAR_SELECTED'
export const SEARCH_FILTERS_REMOVE_FILTER = 'SEARCH_FILTERS_REMOVE_FILTER'
export const SEARCH_FILTERS_UPDATE_SELECTED_OPTIONS = 'SEARCH_FILTERS_UPDATE_SELECTED_OPTIONS'
export const SEARCH_FILTERS_SET_SELECTED_AND_OPTIONS = 'SEARCH_FILTERS_SET_SELECTED_AND_OPTIONS'
export const SEARCH_FILTERS_CLEAR_QUERY = 'SEARCH_FILTERS_CLEAR_QUERY'

const state = {
  items: [],
  isLoaded: false,
  selected: {},
  query: '',
  exact: false
}

const getters = {
  selectedFilters: (state) => state.items.filter((filter) => state.selected[filter.field])
}

const actions = {
  async getFilters ({ commit }) {
    try {
      const { data } = await http.get('search/metadata')
      commit(SEARCH_FILTERS_RECIEVED, { filters: data })
    } catch (err) {
      // na
    }
  },
  setSelectedFilters ({ commit }, selected) {
    commit(SEARCH_FILTERS_SET_SELECTED, { selected })
  },
  removeSelectedFilter ({ commit, dispatch }, filter ) {
    commit(SEARCH_FILTERS_REMOVE_FILTER, { filter })
    dispatch('navigateToSearch')
  },
  clearSelectedFilters ({ commit, dispatch }) {
    commit(SEARCH_FILTERS_CLEAR_SELECTED)
    dispatch('navigateToSearch')
  },
  updateSelectedFilterOptions ({ commit, dispatch }, { filter, options }) {
    commit(SEARCH_FILTERS_UPDATE_SELECTED_OPTIONS, { filter, options })
    let query
    const isPartNumber = filter.field === 'part_number'
    if (isPartNumber) {
      query = { input: options[0] }
    }
    dispatch('navigateToSearch', query)
  },
  setSelectedFiltersAndOptions ({ commit, dispatch }, options) {
    commit(SEARCH_FILTERS_SET_SELECTED_AND_OPTIONS, { options })
    dispatch('navigateToSearch')
  },
  setExact ({ commit }, defaultSearchMode) {
    let exact = false
    switch (defaultSearchMode) {
      case 'exact': // Value from /whoami
      case true: // Value commonly used in Rocket
        exact = true
        break
      default:
        exact = false
    }
    commit(SEARCH_FILTERS_SET_EXACT, { exact })
  },
  clearSearchQuery({ commit }) {
    commit(SEARCH_FILTERS_CLEAR_QUERY)
  }
}

const mutations = {
  [SEARCH_FILTERS_RECIEVED] (state, { filters }) {
    state.items = filters
    state.isLoaded = true
    //if we saved out filter fields these need to be restored as selected fields on entering screen
    state.items.forEach((filter) => {
      const savedField = localStorage.getItem("userSearchFilter_" + filter.field)
      if (savedField) {
        state.selected[filter.field] = true
      }
      if (filter.type === 'range') {
        const prefix = `${filter.field}_prefix`
        const prefixValue = localStorage.getItem("userSearchFilter_" + prefix)
        if (prefixValue?.length > 0) {
          state.selected[prefix] = prefixValue
        }
        const suffix = `${filter.field}_suffix`
        const suffixValue = localStorage.getItem("userSearchFilter_" + suffix)
        if (suffixValue?.length > 0) {
          state.selected[suffix] = suffixValue
        }
      }
    })
  },
  [SEARCH_FILTERS_SET_SELECTED] (state, { selected }) {
    const filterFields = selected.map((filter) => filter.field)
    const selectedFilters = {}
    filterFields.forEach((field) => {
      selectedFilters[field] = state.selected[field]
        ? state.selected[field]
        : []
    })
    state.selected = selectedFilters
    //look for any others that were previously saved
    state.items.forEach((filter) => {
      if (!state.selected[filter.field]) {
        const savedField = localStorage.getItem("userSearchFilter_" + filter.field)
        if (savedField) {
          state.selected[filter.field] = true
          if (filter.type === 'range') {
            const prefix = `${filter.field}_prefix`
            const prefixValue = localStorage.getItem("userSearchFilter_" + prefix)
            if (prefixValue?.length > 0) {
              state.selected[prefix] = prefixValue
            }
            const suffix = `${filter.field}_suffix`
            const suffixValue = localStorage.getItem("userSearchFilter_" + suffix)
            if (suffixValue?.length > 0) {
              state.selected[suffix] = suffixValue
            }
          }
        }
      }
    })
  },
  [SEARCH_FILTERS_CLEAR_SELECTED] (state) {
    const selectedFilters = {}
    Object.keys(state.selected).forEach((key) => {
      selectedFilters[key] = []
      localStorage.removeItem("userSearchFilter_" + key)
      localStorage.removeItem("userSearchFilter_" + key + "_suffix")
      localStorage.removeItem("userSearchFilter_" + key + "_prefix")
    })
    state.selected = selectedFilters
  },
  [SEARCH_FILTERS_REMOVE_FILTER] (state, { filter }) {
    if (filter.type === 'range') {
      // Vue.delete(state.selected, `${filter.field}_prefix`)
      // Vue.delete(state.selected, `${filter.field}_suffix`)
      delete state.selected[`${filter.field}_prefix`]
      delete state.selected[`${filter.field}_suffix`]
    }
    // Vue.delete(state.selected, filter.field)
    delete state.selected[filter.field]
    localStorage.removeItem("userSearchFilter_" + filter.field)
    localStorage.removeItem("userSearchFilter_" + filter.field + "_suffix")
    localStorage.removeItem("userSearchFilter_" + filter.field + "_prefix")
    if (filter.pin)
      filter.pin = false
  },
  [SEARCH_FILTERS_UPDATE_SELECTED_OPTIONS] (state, { filter, options }) {
    if (filter.type === 'range') {
      const value = options[0]
      state.selected[filter.field] = value.value
      if (value.prefix) {
        state.selected[`${filter.field}_prefix`] = value.prefix
      } else if (value.suffix) {
        state.selected[`${filter.field}_suffix`] = value.suffix
      }
    } else {
      state.selected[filter.field] = options
    }
  },
  [SEARCH_FILTERS_SET_SELECTED_AND_OPTIONS] (state, { options }) {
    const query = {}
    Object.keys(state.selected).forEach((key) => {
      const selectedValues = state.selected[key]
      if (!selectedValues.length) {
        query[key] = []
      } else {
        query[key] = selectedValues
      }
    })
    Object.keys(options).forEach((key) => {
      const value = options[key]
      const values = Array.isArray(value) ? value : value && value.length ? [value] : []; // eslint-disable-line
      const filter = state.items.find((f) => f.field === key)
      if (filter) {
        if (filter.storeFilterValues) {
          if (values?.length > 0) {
            localStorage.setItem("userSearchFilter_" + key, values)
          } else {
            localStorage.removeItem("userSearchFilter_" + key)
            localStorage.removeItem("userSearchFilter_" + key + "_suffix")
            localStorage.removeItem("userSearchFilter_" + key + "_prefix")
          }
        }
        if (filter.type === 'range') {
          query[key] = values
          const prefix = `${key}_prefix`
          if (options[prefix]) {
            query[prefix] = options[prefix]
            if (filter.storeFilterValues && values?.length > 0) {
              localStorage.setItem("userSearchFilter_" + prefix, query[prefix])
            }
          }
          const suffix = `${key}_suffix`
          if (options[suffix]) {
            query[suffix] = options[suffix]
            if (filter.storeFilterValues && values?.length > 0) {
              localStorage.setItem("userSearchFilter_" + suffix, query[suffix])
            }
          }
        } else {
          query[key] = values
        }
      } else if (key === 'q') {
        state.query = options[key]
      } else if (key === 'exact') {
        state.exact = options[key] ? String(options[key]) === 'true' : false
      }
    })
    state.items.forEach((filter) => {
      if ((filter.pin) && (!query[filter.field])) {
        query[filter.field] = []
      }
    })
    Object.keys(query).forEach((key) => {
      const filter = state.items.find((f) => f.field === key)
      if (filter && filter.storeFilterValues) {
        const savedValue = localStorage.getItem("userSearchFilter_" + key)
        if (query[key].length === 0 && savedValue !== null && savedValue.length > 0) {
          query[key] = savedValue.split(",")
        }
        if (filter.type === 'range') {
          const prefix = `${key}_prefix`
          const prefixValue = localStorage.getItem("userSearchFilter_" + prefix)
          if (query[prefix]?.length === 0 && prefixValue?.length > 0) {
            query[prefix] = prefixValue
          }
          const suffix = `${key}_suffix`
          const suffixValue = localStorage.getItem("userSearchFilter_" + suffix)
          if (query[suffix]?.length === 0 && suffixValue?.length > 0) {
            query[suffix] = suffixValue
          }
        }
      }
    })
    //console.log(JSON.stringify(query))
    state.selected = query
  },
  [SEARCH_FILTERS_SET_EXACT] (state, { exact }) {
    state.exact = exact
  },
  [SEARCH_FILTERS_CLEAR_QUERY] (state) {
    state.query = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
