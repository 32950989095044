import http from '@/http';

export const getOrgCommerce = async (orgId) => {
  return http.get(`/org-commerce-system/org/${orgId}`).then((res) => res.data);
};

export const createOrgCommerce = async (orgId, data) => {
  return http.post(`/org-commerce-system/org/${orgId}`, data).then((res) => res.data);
};

export const updateCommerce = async (commerceId, data) => {
  return http.put(`/org-commerce-system/${commerceId}`, data).then((res) => res.data);
};
