import http from '@/http'
import {
  OrderField,
  OrderFieldDetail
} from '@/interfaces/admin/orderField'

export const getOrderFields = async (): Promise<OrderField[]> => {
  return http.get('/admin/orders/templates/fields').then(res => res.data)
}

export const copyOrderField = async (id: number, newHeaderFieldName: string): Promise<number> => {
  return http.post(`/admin/orders/templates/fields/copy/${id}`, newHeaderFieldName, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => res.data)
}

export const createOrderField = async (orderField: OrderField): Promise<String> => {
  return http.post('/admin/orders/templates/fields', orderField).then(res => res.data)
}

export const deleteOrderField = async (id: number): Promise<void> => {
  return http.delete(`/admin/orders/templates/fields/${id}`).then(res => res.data)
}

export const getOrderField = async (id: number): Promise<OrderFieldDetail> => {
  return http.get(`/admin/orders/templates/fields/${id}`).then(res => res.data)
}

export const updateOrderField = async (orderField: OrderField): Promise<OrderField> => {
  return http.put(`/admin/orders/templates/fields/${orderField.id}`, orderField).then(res => res.data)
}

export const orderFieldWhereUsed = async (orderFieldId:number): Promise<{id:number, name:string}[]> => {
  return http.get(`/admin/orders/templates/fields/${orderFieldId}/where-used`).then(res => res.data.orderTemplates)
}
