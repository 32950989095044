<template>
  <component  v-if="isPageContentLoaded" 
              :is="componentInViewer"
              style="margin-top: 0;"
              class="widget-media-viewer"/>
</template>

<script setup>
import { onMounted, watch } from "vue"
import { useStaticContent } from "@/components/widgets/composables/useStaticContent.js" 
import { useFetch } from "@/components/widgets/composables/useFetchContent"
import { useWidgetNavigator} from "@/components/widgets/composables/useWidgetNavigator"
import { useOpenToContent } from "@/components/widgets/composables/useOpenToContent"
import { useSearch } from '@/components/widgets/composables/useSearch'

const { componentInViewer } = useStaticContent()
const { setIsLoading, fetchDecorator } = useFetch()
const { page, isPageContentLoaded } = useWidgetNavigator()
const { willPreFetchBomItems, 
  fetchBomContentForVuex } = useOpenToContent()
const { logSearchClickThrough } = useSearch()

async function fetchForVuexWithSpinners() {
  await fetchDecorator(fetchBomContentForVuex)()
}

onMounted(async () => {
  // Prevent double fetching of BOM when
  // useOpenToContent fetches the BOM before mounting
  // Order of operations is tricky here
  // Pre-fetching of BOM will have kicked off, 
  // but BOM items won't yet be available in Vuex.
  if (!!willPreFetchBomItems.value) {
    setIsLoading(false)
  } else {
    await fetchForVuexWithSpinners()
  }
  await logSearchClickThrough()
})
watch(page, fetchForVuexWithSpinners)
</script>