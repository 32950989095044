/**
   * Enforces a minimum load time to help avoid "flashy"
   * loading indicators in the UI.
   *
   * @param startTime the timestamp for when API call is invoked
   * @param endTime the timestamp for when the API call is resolved/rejected
   * @reference https://github.com/documoto/enterprise/issues/188
   */
export const minLoadCheck = async (startTime:Date, endTime = new Date()):Promise<void> => {
  const minimumLoadTime = 250
  const timeDiff = (startTime.getTime() - endTime.getTime()) * -1

  if (timeDiff < minimumLoadTime) {
    await new Promise(resolve => setTimeout(resolve, (minimumLoadTime - timeDiff)))
  }
}
