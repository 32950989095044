<template>
  <section  class="m-2"
            style="height: 90%;">
    <widget-content-header v-if="!!page && isPageContentLoaded">
      <template v-if="!hasInfoLandingPage && !isMobile"
                v-slot="slotProps">
        <h3 class="title-five ml-1 mb-1">
          {{ slotProps.title }}
        </h3>
      </template>
    </widget-content-header>
    <widget-loading-icon v-show="isLoading"/>
    <widget-info-page v-if="hasInfoPageOpen"
                      style="height: 100% !important;"/>
    <widget-page  v-else
                  style="height: 100% !important;"/>
  </section>
</template>

<script setup>
import {computed, onMounted} from "vue"
import WidgetPage from "../components/WidgetPage"
import WidgetContentHeader from '@/components/widgets/components/WidgetContentHeader'
import WidgetLoadingIcon from "@/components/widgets/components/WidgetLoadingIcon"
import { useOpenToContent } from "@/components/widgets/composables/useOpenToContent"
import { useWidgetNavigator } from "@/components/widgets/composables/useWidgetNavigator"
import { useFetch } from '@/components/widgets/composables/useFetchContent'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import WidgetInfoPage from '@/components/widgets/components/WidgetInfoPage'
import { useBreakpoints } from "@/helpers"
const { outerWidth } = useBreakpoints()
const { setLandingPage } = useOpenToContent()
const { page, isPageContentLoaded } = useWidgetNavigator()
const { hasInfoPageOpen, hasInfoLandingPage } = useInfoLandingPage()
const { isLoading } = useFetch()
const isMobile = computed(() => {
  return outerWidth.value <= 768
})

onMounted(async () => {
  await setLandingPage()
})
</script>
