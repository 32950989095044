<template>
  <slot name="browseCrumbs"
        :text="name"
        :crumbs="crumbs">
    <nav  style="min-height: fit-content;"
          class="breadcrumb">
      <ul>
        <li>
          <router-link :to="{ path: '/' }">Home</router-link>
        </li>
        <template v-if="browseLoaded">
          <li v-for="crumb in crumbs"
              :key="crumb.id"
              class="breadcrumb-li is-hidden-touch">
            <breadcrumb-item  :path="path"
                              :breadcrumb="crumb" />
          </li>
        </template>
        <li v-if="previousCrumb" class="breadcrumb-li is-hidden-desktop">
          <breadcrumb-item  :path="path"
                            :breadcrumb="previousCrumb"
                            :display-name="false" />
        </li>
        <li v-if="browseLoaded" class="is-active breadcrumb-active">
          <a class="breadcrumb-item">{{ name }}</a>
        </li>
      </ul>
    </nav>
  </slot>
</template>

<script>
import { mapState } from 'vuex';
import BreadcrumbItem from './BreadcrumbItem';
import { path } from '@/router/compositionObjects';

export default {
  name: 'Breadcrumb',
  components: {
    BreadcrumbItem
  },
  computed: {
    ...mapState({
      breadcrumb: ({ browse }) => browse.breadcrumb,
      browseLoaded: ({ browse }) => browse.isLoaded
    }),
    ...path,
    name() {
      const {[this.breadcrumb.length - 1]: last} = this.breadcrumb;
      return last ? last.name : ''
    },
    crumbs() {
      if (this.breadcrumb.length >= 2) {
        return this.breadcrumb.slice(1, this.breadcrumb.length - 1)
      }
      return [];
    },
    previousCrumb() {
      return this.crumbs.length ? this.crumbs[this.crumbs.length - 1] : null
    }
  }
};
</script>

<style scoped>
.breadcrumb {
  margin: 0;
  padding: .5rem;
  border-bottom: lightgrey 1px solid;
}
.breadcrumb ul {
  flex-wrap: nowrap !important;
}
.breadcrumb a {
  padding: 0 .4rem;
}
.breadcrumb-li {
  min-width: 0;
  max-width: 14rem;
}
.breadcrumb li:last-child {
  display: inline-flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
