<template>
  <div class="pdfContainer">
    <div>
      <span class="error">{{ errorMsg }}</span>
    </div>
    <div id="viewerContainer"
         class="pdfViewerWrapper">
      <pspdf-kit-container v-if="pspdfkey"
                           :fileSize="content.fileSize"
                           :license-key="pspdfkey"
                           :pdf-url="asset"
                           :query="query" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LoadingIcon from '@/components/common/LoadingIcon'
import PspdfKitContainer from '@/components/library/content/asset/viewer/pdfViewer/PspdfKitContainer.vue'

export default {
  name: 'PdfViewer',
  components: {
    LoadingIcon,
    PspdfKitContainer
  },
  data() {
    return {
      errorMsg: ''
    };
  },
  computed: {
    ...mapState({
      asset: ({ content }) => content.contentUrl,
      content: ({ content }) => content,
      pspdfkey: ({ pspdfkey }) => pspdfkey.key
    }),
    query () {
      return this.content.toc.query || this.$route.query.q
    }
  },
  methods: {
    ...mapActions({
      getPSPDFKey: 'pspdfkey/getPSPDFKey'
    })
  },
  async created() {
    await this.getPSPDFKey()
  }
};
</script>

