import { arrowKey, DraftBomItem, moveDirection } from '@/interfaces/admin'
import { DraftEditorStore } from '@/store/modules/draftPage/draftEditor'
import { cloneDeep } from "lodash";

const state: DraftEditorStore = {
  bomItems: [],
  selectedBomItems: [],
  bomForView: []
}

interface BomMutations {
  view: Array<DraftBomItem>,
  selections: Array<DraftBomItem>
  bomItems: Array<DraftBomItem>
}

export default {
  parent: this,
  // this value is refKey of specific bom object
  returnSingleSelection: '',
  [arrowKey.arrowUp]: async function (selection: DraftBomItem[], bomForView: Array<DraftBomItem>, bomItems : Array<DraftBomItem> ) {
    let currentSelection: DraftBomItem, newSelection: DraftBomItem | null, index: number
    if(selection && selection.length > 0) {
      currentSelection = selection.reduce(function(prev, curr) {
        return prev.index < curr.index ? prev : curr
      });
      index = bomForView.findIndex(bom => {
        return bom.refKey === currentSelection.refKey
      })
      if(index !== 0) {
        newSelection = bomForView[(index - 1)]
      } else {
        newSelection = null
      }
    }
    else {
      newSelection = bomForView[0]
    }
    if(newSelection!) this.returnSingleSelection = newSelection.refKey!
    return newSelection!? this.returnObject(newSelection) : null
  },
  // determines what the current selection is and then selects the next bom row down
  [arrowKey.arrowDown]: async function (selection: Array<DraftBomItem>, bomForView: Array<DraftBomItem>, bomItems : Array<DraftBomItem> ) {
    let currentSelection: any, newSelection: DraftBomItem, index: number
    // If there are items selected, extract out the last selected item; otherwise, select the last item in BomForView
    if (selection && selection.length > 0) {
      currentSelection = selection[selection.length - 1]
    } else {
      newSelection = bomForView[bomForView.length - 1]
    }

    //  if the current selection has children, set the newSelection to first child; otherwise, get the next bom row
    if (currentSelection && currentSelection.children.length > 0) {
      newSelection = this.getBomItem(currentSelection.children[0], bomItems)!
    } else if (currentSelection) {
      index = bomForView.findIndex(bom => {
        return bom.refKey === currentSelection.refKey
      })
      newSelection = bomForView[(index + 1)]
    }
    if(newSelection!) this.returnSingleSelection = newSelection.refKey!
    return newSelection!? this.returnObject(newSelection) : null
  },
  returnObject (selection: DraftBomItem) {
    return [{
      text: selection.item,
      id: selection.id,
      oId: selection.id
    }]
  },
  getBomItem (refKey: string, bomItems : Array<DraftBomItem> ) {
    return bomItems.find(bom => bom.refKey === refKey)
  },

  //  Entry point for passing in selectedItems Array
  getBomSelectionsArray: async function (payload: Array<any>, bomForView: Array<DraftBomItem>, bomItems: Array<DraftBomItem>): Promise<BomMutations> {
    const _this = this
    let accumulator: Array<DraftBomItem> = []
    state.bomForView = bomForView
    state.bomItems = bomItems
    state.selectedBomItems = []
    async function loop () {
      for (let i = 0; i < payload.length; i++) {
        const selectedBomInView = bomForView.filter(item => item.item === payload[i].text)
        //  Selects all matching items in original bomList
        const itemsInOriginal = bomItems.filter(item => item.item === payload[i].text)
        //  Determines if any of the selected items are not in the viewable flat array
        if ((selectedBomInView && (selectedBomInView.length !== itemsInOriginal!.length)) || !selectedBomInView) {
          await _this.expandParentItems(itemsInOriginal)
        }
        //  Once all parents are expanded and child rows are in the bomForView, add the selected rows to accumulator
        let allTextRows = state.bomForView.filter((item: DraftBomItem) => item.item === payload[i].text)
        accumulator = [...accumulator, ...allTextRows]
      }
    }
    if (payload && payload.length > 0) await loop()
    await _this.setBatchSelectedState(accumulator)
    if(this.returnSingleSelection) await this.getSingleSelection()
    return { view: state.bomForView, selections: state.selectedBomItems, bomItems: state.bomItems }
  },
  async getSingleSelection() {
    let refKey = this.returnSingleSelection
    let singleSelection: DraftBomItem
    state.selectedBomItems.forEach(bom => {
      if(bom.refKey === refKey) {
        singleSelection = bom
      }
      else {
        bom.selected = false
      }
    })
    state.selectedBomItems = [singleSelection!]
    this.returnSingleSelection = ''
  },
  async expandItemProps (payload: {parent: DraftBomItem, selectedBom: DraftBomItem}) {
    const _this = this
    //  topmost parent in view plus it's bomForView index passed to update
    //  this is sending wrong index
    await _this.updateExpandState({ item: payload.parent, index: payload.parent.index })
    if (!state.bomForView.find((item: DraftBomItem) => item.item === payload.selectedBom!.item)) {
      const parent = await _this.getParentToExpand(payload.selectedBom!)
      //  which it is getting from here
      await _this.expandItemProps ({ parent: parent, selectedBom: payload.selectedBom })
    }
  },
  async expandParentItems (itemsInOriginal: Array<DraftBomItem>) {
    let parentToExpand: DraftBomItem
    const _this = this
    //  all selected items from the original bom list passed in.  They have not been passed to the selectedBomItems yet
    for (const selectedBom of itemsInOriginal!) {

      const bomInView = async function () {
        //  First expand parent item.  Parent may not be in view so topmost parent in bomForView returned
        //  Expecting that each bom item has one unique parent
        parentToExpand = await _this.getParentToExpand( selectedBom!)
        await _this.expandItemProps ({ parent: parentToExpand!, selectedBom: selectedBom })
        //  If the parent is not in bomForView, run this function again.  Goes up the hierarchy until all the parents are expanded
        if (!state.bomForView.find((item: DraftBomItem) => item.refKey === selectedBom.refKey)) await bomInView()
      }
      //  Test if the selected item is in bomView array
      const tester = state.bomForView.find((item: DraftBomItem) => item.refKey === selectedBom.refKey)
      if (!tester) await bomInView()
    }
  },
  async getParentToExpand (child: DraftBomItem): Promise<DraftBomItem> {
    const _this = this
    const searchResult = state.bomItems.find((item: DraftBomItem) => item.refKey === child.parent)
    const foundParentInView = state.bomForView.find((item: DraftBomItem) => item.refKey === searchResult!.refKey)
    return (!foundParentInView) ? await _this.getParentToExpand(searchResult!) : foundParentInView
  },
  indexBom (bomItems: Array<DraftBomItem>) {
    const bomItemsIndexed = bomItems.map((item, index) => {
      item.index = index
      return item
    })
    return bomItemsIndexed
  },
  updateExpandState (payload: { item: DraftBomItem, index: number }) {
    const index = payload.index
    const item = payload.item
    state.bomForView[index].expanded = true
    if(item.child && item.child.length > 0 ) {
      item.child.forEach((child: DraftBomItem, i: number) => {
        const removeReference = cloneDeep(child)
        state.bomForView.splice(index + 1 + i, 0, removeReference)
      })
    }
    state.bomForView = this.indexBom(state.bomForView)
  },
  async toggleExpandState (payload: { bomForView: Array<DraftBomItem>, item: DraftBomItem, index: number }): Promise<Array<DraftBomItem>> {
    const bomForView = cloneDeep(payload.bomForView)
    const index = payload.index
    const item = cloneDeep(payload.item)
    const adjustChildren = async (item: DraftBomItem, index: number) => {
      for (let i = 0; i < item.child.length; i++) {
        //  if item is expanded, need to remove all children and grandhchildren
        if (item.expanded) {
          //  Test each child state on bomForView
          if (bomForView[index + 1].expanded) {
            await adjustChildren(bomForView[index + 1], index + 1)
          }
          await bomForView.splice(index + 1, 1)
        } else {
          //  Add in children to bomForView
          await bomForView.splice(index + 1 + i, 0, item.child[i])
        }
      }
      //  After all operations complete, switch expansion state
      bomForView[index].expanded = !item.expanded
    }

    if(item.children && item.children.length > 0) {
      await adjustChildren(item, index)
    } else {
      bomForView[index].expanded = !item.expanded
    }
    return bomForView
  },
  setBatchSelectedState (selectedBomInView: Array<DraftBomItem>) {
    selectedBomInView.forEach(selectedBom => {
      state.bomForView[selectedBom!.index].selected = true
      selectedBom.selected = true
      state.selectedBomItems.push(selectedBom)
    })
  },
}
