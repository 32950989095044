import { ActionContext, MutationTree, GetterTree } from 'vuex'
import { getPartCodes } from '@/controllers/admin'
import { PartCodeDto } from '@/interfaces/admin'

interface PartCodesMap { [key: string]: PartCodeDto }
interface PartCodesState { partCodesMap: PartCodesMap }

type Context = ActionContext<PartCodesState, any>
export enum MutationTypes {
    SET_PART_CODES_MAP = 'SET_PART_CODES_MAP'
}

const state: PartCodesState = {
    partCodesMap: {}
}

const getters: GetterTree<PartCodesState, any> = {
    isAPartCode: (state) => (value: string): boolean => {
        return !!state.partCodesMap[value]
    }
}

const actions = {
    async setPartCodesMap({ commit }: Context) {
        const map: PartCodesMap = {}
        try {
            const partCodes: PartCodeDto[] = await getPartCodes()
            partCodes.forEach(dto => {
                if (typeof dto?.partcode === 'string') {
                    map[dto.partcode] = dto
                }
            })
        } catch(e) {
            console.log(e)
        } finally {
            commit(MutationTypes.SET_PART_CODES_MAP, map)
        }
    }
}

const mutations: MutationTree <PartCodesState> = {
    [MutationTypes.SET_PART_CODES_MAP] (state, payload: PartCodesMap) {
        state.partCodesMap = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}