import http from '@/http'
import { Part3dFile } from '@/interfaces/global'

const API_BASE_URL = '3d-thumbnails/parts'

/**
 * Provides a URL path to content or an empty string if no file is present
 *
 * @param partId
 */
export const getCurrentScsFile = async (partId: number): Promise<Part3dFile> => {
  return http.get(`${API_BASE_URL}/current-scs-library/${partId}?includePng=true`)
    .then(res => {
      const data = res.data
      const part3dFile: Part3dFile = {
        jobId: parseInt(data['jobId']) ?? null,
        jobStatus: data['jobStatus'] ?? null,
        originalTopLevelFilename: data['originalTopLevelFilename'] ?? null,
        pngFileUrl: data['pngFileUrl'] ?? '',
        scsFileUrl: data['scsFileUrl'] ?? '',
      }

      return part3dFile
    })
}
