<template>
  <figure class="image orderLogo"/>
</template>

<script>
export default {
  name: 'OrderCellLogo',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
figure {
  height: 6rem;
  min-width: 18rem;
  margin: .5rem 1rem;
  background-position: left;
}
</style>
