/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} of {b}',
  about: 'About',
  aboutThisItem: 'About This item',
  accessApiKeyManager: 'Access the API Key Manager for this tenant.',
  accessControls: 'Access Controls',
  accessControlsCopiedText: 'Access controls are updated immediately. Media security privileges will be updated over the next few minutes.',
  accessControlsCopiedTitle: 'Access controls successfully copied',
  accessControlsCopyConfirmation: 'This will remove all existing access controls from {to} and replace them with access controls from {from}. This action cannot be undone.',
  accessControlsDescription: 'Access controls define who can see this media, based on selected Organizations and Media Categories.',
  accessControlsIndeterminance: 'Note: Parent organizations always have access to content assigned to any of their children.',
  account: 'Account',
  accountErrorMessage: 'Account Error Message',
  accountErrorMessageWarning: 'The accountErrorMessage field in the database will be set to "Unauthorized" for the tenant.',
  action: 'Action | Actions',
  actionDelete: 'delete',
  actionLoad: 'load',
  actionPaste: 'Paste',
  actionSave: 'Save',
  actionRun: 'run',
  actionUndoWarning: 'This action cannot be undone.',
  actionUpload: 'upload',
  actualSize: 'Actual Size',
  add: 'Add',
  addAPIKey: 'Add an API Key',
  addItem: 'Add {item}',
  addABookText: 'Add a book',
  addASharedCart: 'Add a shared cart',
  addATemplate: 'Add a template',
  addATag: 'Add a Tag',
  addABookToStart: '{addABookText} to get started.',
  addAForm: 'Add a Form to get started',
  addAFormOnly: 'Add a Form',
  addAMedia: 'Add a Media',
  addAnOrderQuoteEmailFormat: 'Add an Order/Quote Email Format',
  addAPrintSettingText: 'Add a Print Setting',
  addAPrintSettingToStart: '{addAPrintSettingText} to get started.',
  addBooks: 'Add Books',
  addChildBrowseFlow: 'Add Child Browse Flow',
  addChapter: 'Add Chapter',
  addComment: 'Add a comment',
  addExistingPart: 'Add Existing Part',
  addGroupsToUsers: 'Add {groups} groups to {users} users',
  additionalInformation: 'Additional Information',
  additionalNotification: 'Additional Notification',
  additionalPartNumberDescription: 'Use as additional part number field',
  addBrowseFlow: 'Add a Browse Flow',
  addMedia: 'Add Media',
  addPages: 'Add Pages',
  addPart: 'Add a Part',
  addReports: 'Add Reports',
  addRootBrowseFlow: 'Add Root Browse Flow',
  addRow: 'Add Row',
  addTemplates: 'Add Templates',
  addToCartMessage: 'Successfully added {partNumber}',
  addToUserGroup: 'Add to Groups',
  address: 'Address | Addresses',
  addressInUse: 'An address is currently set as the default address and cannot be deleted',
  addressInUseSubtitle: 'To delete this address, you must first remove this address as the default.',
  addressLine: 'Address Line {number}',
  addWidgetKey: 'Add a Widget Key',
  admin: 'Admin',
  adminCenter: 'Admin Center',
  adminCenterBanner: {
    bodyLabel: 'Banner Body',
    enableLabel: 'Enable Admin Banner',
    headerGreenOption: 'Green',
    headerLabel: 'Banner Header',
    headerRedOption: 'Red',
    headerYellowOption: 'Yellow',
    title: 'Admin Center Banner',
    typeLabel: 'Banner Type'
  },
  adminCenterMessage: 'Administration center for content, users, library settings, reporting and exporting',
  adminMessage: 'Features added as they become available',
  administration: 'Administration',
  affectedBookMedia: '{count} Book Media will be updated.',
  affectedTemplateMedia: '{count} Book Templates will be updated.',
  after: 'After',
  aggregatedFromChildren: 'Aggregated from Children',
  aiAlgorithm: 'AI Scanner',
  all: 'All',
  alphanumeric: 'Alphanumeric',
  algorithm: 'Algorithm',
  alignHorizontal: 'Align Horizontal',
  alignVertical: 'Align Vertical',
  allUsersDisabled: 'All users that belong to the tenant will be disabled.',
  analyticsJSON: 'Analytics JSON',
  andCountMore: 'and {count} more...',
  any: 'Any',
  apiKey: 'API Key | API Keys',
  apiKeyAdmin: 'API Key Admin',
  apiAuthHeader: 'API Authorization Header',
  apiKeyCopied: 'API Authorization Header value copied to Clipboard',
  apiKeyDeleted: 'API Key deleted',
  apiKeyDeletedError: 'Error deleting API Key',
  apiKeyDeleteMsg: 'Deleting this API Key will disable its use by integrations',
  apiKeyDeleteTitle: 'Are you sure you want to delete this API Key?',
  apiKeyInstructions: 'Key will be generated when Saved',
  apiKeyManager: 'API Key Manager',
  apiKeyNameEmpty: 'Name must be unique and is required',
  apiKeyRegenError: 'Error regenerating API Key',
  apiKeyRegenMsg: 'Regenerating this API Key may disable existing integrations',
  apiKeyRegenTitle: 'Are you sure you want to regenerate this API Key?',
  apiKeySaved: 'API Key Saved',
  apiKeyType: 'API Key Type',
  appliesTo: 'Applies To',
  appliesToEmpty: 'Require at least one entity type selected',
  appliesToPopulated: 'Set what entities the tag can be applied to',
  applyItem: 'Apply {item}',
  applyToChildItems: 'Apply to Child {item}',
  applyToPartOnly: 'Apply to this Part Only',
  april: 'April',
  area: 'Area',
  areYouSure: 'Are you sure you want to delete {item}?',
  areYouSureCopy: 'Are you sure you want to copy {item}?',
  areYouSureYouWantToDisableTheTenant: 'Are you sure you want to disable the tenant?',
  assembly: 'Assembly | Assemblies',
  assemblyCreatorSuccessTitle: 'Create assembly job submitted',
  assemblyCreatorSuccessSubtitle: 'Hotpoint links and book table of content changes will be made immediately. Search results will be reflected once indexing completes.',
  assemblyConfirmationTitle: 'Do you want to complete the create assembly?',
  assemblyCreatorHelpText1: 'This feature allows you to insert a selected page into books wherever this part {partName} is found, automatically connecting the part and the selected page through a hotpoint link. You can optionally restrict the insertion to only selected books.',
  assemblyCreatorHelpText2: 'The selected page is inserted once per book after the first occurrence of {partName}.',
  assemblyStepOne: 'Step 1: Select the page to be inserted',
  assemblyStepTwo: 'Step 2: Choose the books where you want the page to be inserted',
  asset: 'Asset | Assets',
  assetsNotFound: 'No {type} Found',
  assignBrowseFlow: 'Assign Browse Flow',
  assignedOrganizations: 'Assigned Organizations',
  atLeastFiveError: 'Please select a value that is no less than 5.',
  attachment: 'Attachment | Attachments',
  attachmentEditorDescription: 'Attach images, videos and documents directly to this entity. Note, they will be found in the "Related" tab.',
  attachmentView: 'View Attachments',
  attachRelatedPDFs: 'Attach PDFs of related parts pages',
  attributions: 'Attributions',
  august: 'August',
  automaticZoom: 'Automatic Zoom',
  autoHotpoint: 'Auto Hotpoint',
  autoPopulate: 'Auto-Populate',
  availability: 'Availability',
  back: 'Back',
  backCover: 'Back Cover',
  backStitch: 'Back Stitch',
  backToLibrary: 'Back to Library',
  backToSearch: 'Back to Search',
  badCredentials: 'Bad Credentials',
  bahasaDescription: 'Bahasa Indonesian Description',
  bahasaName: 'Bahasa Indonesian Name',
  banner: 'Banner',
  before: 'Before',
  beginTypingToFindAnything: 'Begin typing to find anything',
  betaAccessKeys: 'Beta Access Keys',
  betaResources: 'Beta Resources',
  betaReleaseVideo: 'Beta Release Video',
  blobFinder: 'BLOB Finder',
  body: 'Body',
  bomDefaultSupplier: 'BOM Default Supplier',
  bomItem: 'BOM Item',
  bom: 'BOM',
  bomExportSuccess: 'BOM Export Successful',
  bomLayout: 'Layout of Bill of Materials',
  bomListOptions: 'BOM List Options',
  bomListSettings: 'BOM List Settings',
  both: 'Both',
  book: 'Book | Books',
  bookInserts: 'Book Inserts',
  bookLabel: 'Book | Books',
  bookPrintingDisable: 'Disable Book Printing',
  booksAndPagesDisplay: 'Books and Pages Display',
  boost: 'Search Boost',
  boostDescription: 'Add a value over 1.5 to boost search relevancy of this tag over part numbers, media identifiers, and names',
  boostRangeInvalid: 'Boost must be between 0.5 and 100.0',
  border: 'Border',
  bottom: 'Bottom',
  branding: 'Branding',
  browse: 'Browse',
  browseComputer: 'Browse Computer',
  browseFlow: 'Browse Flow | Browse Flows',
  browseFlowAssigned: 'Browse Flow Assigned',
  browseFlowCopyMessage: 'Enter a unique name for the new Browse Flow.',
  browseFlowDeleted: 'Browse Flow Deleted',
  browseFlowDeleteMessage: 'Deleting a browse flow not only deletes the browse flow but all of its child nodes.',
  browseFlowEmptyState: 'No Browse Flows defined. {new} to get started.',
  browseFlowHeirarchy: 'Browse Flow Hierarchy',
  browseFlowNoExistingCriteria: 'No search criteria has been set for this browse flow.  Use the controls above to select search criteria.',
  browseFlowMoveToRoot: 'Drop node here to make first-level child',
  browseFlowNoAssignedOrgs: 'No assigned organizations',
  browseFlowSaveFailed: 'Browse Flow could not be updated',
  browseFlowSetDefaultSuccess: 'Default Browse Flow set',
  browseFlowThumbnailDeleteFailTitle: 'Thumbnail Delete Failed',
  browseFlowThumbnailDeleteSuccessTitle: 'Thumbnail Delete Successful',
  browseFlowThumbnailUploadFailTitle: 'Thumbnail Upload Failed',
  browseFlowThumbnailUploadSuccessTitle: 'Thumbnail Upload Successful',
  browseFlowTooManyOrgs: 'You have too many organizations to use the current browse flow editor. Please contact Documoto Support at {email} for help with editing your Documoto browse flows.',
  browserPartiallySupport: 'You may see aesthetic differences and other changes in this browser.',
  browseFlowSaved: 'Browse Flow saved',
  browserDeprecation: {
    title: 'Browser Deprecation Notice',
    text: 'In line with Microsoft\'s phasing out support for Internet Explorer (all versions) and related security concerns, we will no longer support user access to this site via Internet Explorer (all versions) after September 30, 2021.'
  },
  buildHotpointLinks: 'Build Hotpoint Links',
  buildHotpointLinksInfo: 'This action is additive and will not remove any existing hotpoint links. Executing this action will add new hotpoint links between parts and any matching assembly pages if a hotpoint link does not already exist.',
  buildHotpointLinksTitle: 'Do you want to build hotpoint links on this book?',
  bulkIndexingLane: 'Bulk Indexing Lane',
  bulkInsert: 'Bulk Insert',
  bulkExportConfirm: 'Do you want to export {n} {unit}?',
  bulkExportLimit: 'Limit is {limit} books per export job',
  bulkPageInsertJob: 'Bulk Page Insert',
  business: 'Business',
  cancel: 'Cancel',
  canceled: 'Canceled',
  cancelAssemblyCreatorTitle: 'Discard your create assembly?',
  cancelAssemblyCreatorSubtitle: 'Your create assembly will not be processed.',
  cannotExceed100: 'Cannot exceed 100%',
  cart: 'Cart | Carts',
  cartBehaviorEnabled: 'Enable Add to Cart Quantity Prompt',
  cartBehaviorDisabled: 'Disable Add to Cart Quantity Prompt',
  cartBehaviorLabel: 'Add to Cart Quantity Behavior',
  cartColumn: 'Cart Column | Cart Columns',
  cartColumn1: '1st Shopping Cart Price Column',
  cartColumn2: '2nd Shopping Cart Price Column',
  cartColumn3: '3rd Shopping Cart Price Column',
  cartColumnPartLocationContent1: 'Enabling this feature will have the following implications:',
  cartColumnPartLocationContent2: 'Part row uniqueness in the shopping cart also will be based on its location within a piece of content, if applicable.',
  cartColumnPartLocationContent3: 'This setting cannot be disabled at the organization-level and will apply to all users.',
  cartColumnPartLocationContent4: 'This setting should not be enabled if you have a Submit Cart integration without first contacting your Documoto Customer Success Manager.',
  cartColumnPartLocationOption: 'Enable Part-Location Data in Cart',
  cartColumnPartLocationTitle: 'Are you sure you want to enable Part-Location Data in Cart?',
  cartDisable: 'Disable Shopping Cart',
  cartEmailFailed: 'Cart Email failed',
  cartEmailSent: 'Cart Email sent',
  cartEnable: 'Enable Shopping Cart',
  cartSaveEnable: 'Enable Save Shopping Cart',
  cartErpMessage: 'Successfully sent to Erp',
  cartImportError: 'Error importing cart',
  cartLoadedWithItemsRemoved: '{count} item(s) removed because you do not have permission to order them at this time',
  cartSharedAddEdit: 'Add / Edit Shared Shopping Carts',
  cartUpdated: 'Cart Updated',
  cartUpdatedAndRemovedCount: 'Cart Updated; {count} unorderable parts removed',
  category: 'Category | Categories',
  categoryCreated: 'Category Created',
  categoryUpdated: 'Category Updated',
  centimeter: 'Centimeter | Centimeters',
  changeNotification: 'Change Notification',
  changeScope: '{n} {type} will be affected',
  changeUserOrganization: 'Change Organization',
  chapter: 'Chapter | Chapters',
  chapterHeader: 'Chapter Header',
  chaptersOnly: 'Chapters Only',
  chineseDescription: 'Chinese Description',
  chineseName: 'Chinese Name',
  circle: 'Circle',
  city: 'City',
  classicAdminMessage: 'This will direct you back to Flex to use the admin modules.',
  classicAdmins: 'Classic Admins',
  classicExporter: 'Classic Exporter',
  classicExporterMessage: 'Legacy Flash-based content export module',
  classicOrganizationAdmin: 'Classic Organization Admin',
  classicOrganizationAdminMessage: 'Legacy Flash-based user administration module for managing orgs and users',
  classicPublisher: 'Classic Publisher',
  classicPublisherMessage: 'Legacy Flash-based publisher module for content creation and print settings',
  classicReporting: 'Classic Reporting',
  classicReportingMessage: 'Legacy Flash-based reporting module',
  classicTenantAdmin: 'Classic Tenant Admin',
  classicTenantAdminMessage: 'Legacy Flash-based administration module for tenant wide settings',
  className: 'Class Name',
  clear: 'Clear',
  clearItemNumber: 'Clear Item Number',
  clearItemNumberTitle: 'Do you want to clear item number?',
  clearItemNumberMessage: 'Clearing the item number will result in removing the following data:',
  clearFilters: 'Clear Filters',
  clearHotpoints: 'Clear Hotpoints',
  clearSearch: 'Clear Search',
  clickHereToUpload: 'Drop file or click to upload an override.',
  clickMagnifyingGlass: 'Click the magnifying glass to go directly to Advanced Search.',
  clickOkToContinue: 'Click "OK" to continue.',
  clickToDownload: 'Click to Download',
  clickToEnlarge: 'Click to Enlarge',
  clickToOpen: 'Click to Open',
  clipboard: 'Clipboard',
  clipboardAccessError: 'Please adjust your browser settings to allow Clipboard access',
  close: 'Close',
  closed: 'Closed',
  collapseAll: 'Collapse All',
  color: 'Color | Colors',
  column: 'Column | Columns',
  columnName: 'Column Name',
  columnItemType: {
    customText: 'Custom Text',
    documentTitle: 'Document Title',
    horizontalLine: 'Horizontal Line',
    pageName: 'Page Name',
    pageNumber: 'Page Number',
    printDate: 'Print Date',
    tenantName: 'Tenant Name',
    userName: 'User Name'
  },
  combinedPage: 'Combined Page',
  combinedPageMessage: 'Diagram on top, parts list on bottom',
  comingSoon: 'Coming Soon',
  commaDelimited: 'Comma-delimited',
  comment: 'Comment | Comments',
  commentAdded: 'Comment added',
  commentDeleted: 'Comment deleted',
  commentEdit: 'Add / Edit Comments',
  commentEditAll: 'Edit All Comments',
  commentUpdated: 'Comment updated',
  commentView: 'View Comments',
  commerceSystem: 'Commerce System',
  completed: 'Completed',
  confirm: 'Confirm',
  confirmBrowseFlowApplyToChildren: 'Do you want to apply this browse flow to child organizations?',
  confirmBrowseFlowOverrideToChildren: 'This will remove any browse flow overrides set at the child organizations.',
  confirmPrintSettingsApplyToChildren: 'Do you want to apply this print setting to child organizations?',
  confirmPrintSettingsOverrideToChildren: 'This will remove any print setting overrides set at the child organizations.',
  contains: 'Contains',
  containsHeaderRow: 'Contains Header Row',
  content: 'Content | Contents',
  contentAccessControls: '{content} Access Controls',
  contentEntity: 'Content Entity | Content Entities',
  contentNotFound: 'Content not found',
  contentUpdatedQueueJob: 'Content Updated Queue',
  contourShape: 'Contour Shape',
  convertEntity: 'Convert {entity}',
  convertToEntity: 'Convert to {entity}',
  copyAccessControls: 'Copy Access Controls',
  copyAccessControlsWarning: 'This will copy all assigned access controls from a selected Organization to this current Organization',
  copy: 'Copy',
  copyAndRetain: 'Copy & Retain, this is the only time API Authorization Header value will be visible for you in the application',
  copyAndRetainWidget: 'Copy & Retain, this is the only time Widget Authorization value will be visible for you in the application',
  copyAtoB: 'Copy {a} to {b}',
  copyContent: 'Copy {type}? {name}',
  copyingItem: 'Copying {item}',
  copyItem: 'Copy {item}',
  copyItemQuestion: 'Copy {item}?',
  copyTo: 'Copy to {target}',
  copyMeMessage: 'Copy me on this message',
  copyrightMessage: '© 2010-{year} Documoto Inc. All rights reserved.',
  count: 'Count',
  country: 'Country | Countries',
  coverPages: 'Cover Pages',
  coverAmpStitchPages: 'Cover & Stitch Pages',
  createAssembly: 'Create Assembly',
  created: 'Created',
  createdBy: 'Created by {name}',
  createdDate: 'Created Date',
  createdOn: 'Created on',
  currentPassword: 'Current Password',
  custom: 'Custom',
  czechDescription: 'Czech Description',
  czechName: 'Czech Name',
  dashboard: 'Dashboard | Dashboards',
  dashboardLibrary: 'Dashboard Library',
  dataInsights: 'Data Insights',
  dataInsightsInfoMessage: 'Reporting and Dashboard Center for content, user and library data insights.',
  dataMapping: 'Data Mapping/Sample Data (up to 10 rows)',
  dataOptions: 'Data Options',
  date: 'Date',
  dateModified: 'Date Modified',
  debugPageBuilderPLZ: 'Debug Page Builder PLZ',
  december: 'December',
  decreaseHotpoint: 'Decrease Hotpoint',
  default: 'Default',
  defaultAddress: 'Default Address',
  defaultBrowseFlow: 'Default Browse Flow',
  defaultCurrencyCode: 'Default Currency Code',
  defaultCurrencySymbolOn: 'Default Currency Symbol On',
  defaultEmailRecipient: 'Default Email Recipient | Default Email Recipients',
  defaultImage: 'Default Image | Default Images',
  defaultImagesManager: 'Default Images Manager',
  defaultItemImage: '{item} Default Image',
  defaultPartsListLayout: 'Default Parts List Layout',
  defaultPartsListLayoutHelp: 'Default parts layout to the left or below the illustration',
  defaultSearchMode: 'Default Search Mode',
  defaultSearchModeHelp: 'Default global search method to \'contains\' or \'exact\'',
  defaultSortMode: 'Default Sort Mode',
  defaultSupplier: 'Default Supplier',
  defaultTocStateOpen: 'Default TOC State Open',
  defaultTocStateOpenHelp: 'Determines if the TOC should automatically open when viewing a book',
  defaultUom: 'Default UOM',
  defaultValue: 'Default Value',
  defaults: 'Defaults',
  defaultsAndCapabilities: 'Defaults and Capabilities',
  delete: 'Delete',
  deleteAll: 'Inherit From Parent',
  deleteAnyPartnerApiKeys: 'Delete any Partner API Keys that were provided to SmartEquip.',
  deleteContent: 'Are you sure you want to delete {type}? {name}',
  deletePart: {
    infoOne: 'Part deletes will take effect immediately in Library and Content Admin. The deleted part will remain searchable until reindexing has occurred within a few minutes.',
    deletingPartNumber: '{partNumber} will be deleted.',
    affectedCarts: 'All carts containing this part will have the part permanently removed.',
    infoTwo: 'This action cannot be undone without Documoto Support assistance. After 90 days the deleted record and all associations will be permanently purged.'
  },
  deletePartInUse: {
    title: 'This part belongs to page(s) and cannot be deleted.',
    body: 'Documoto currently only supports deleting parts that do not belong to pages. Please remove this part from any published or draft pages to proceed with your delete.',
    submitLabel: 'Go to Page Where Used'
  },
  deleteRelated: 'Are you sure you want to delete {count} {type}?',
  deletedItem: '{item} deleted',
  deleteFilesMessage: 'Are you sure you want to delete {count} file(s)?',
  deleteHotpointLinksTitle: 'Are you sure you want to delete hotpoint link(s)?',
  deleteMessage: 'Are you sure you want to delete?',
  deleteSelectedCriteria: 'Delete Selected Criteria',
  deleteTagsTitle: 'Are you sure you want to delete tag(s)?',
  deleteTaxonMessage: 'This will permanently delete this taxon along with any of it\'s children. Are you sure you want to proceed?',
  deleteTaxonomyMessage: 'This will permanently delete all taxons for this Org or Tenant. Are you sure you want to proceed?',
  deleteTenantSupplier: 'Are you sure you want to delete this supplier for the tenant?',
  demo: 'Demo',
  description: 'Description',
  deselectAll: 'Deselect All',
  deselectCriteria: 'Deselect Criteria',
  detail: 'Detail | Details',
  diagonal: 'Diagonal',
  diameter: 'Diameter',
  disable: 'Disable',
  disableAll: 'Disable All',
  disableDocumotoTenant: 'Disable Documoto Tenant',
  disableFwdReindex: 'Disable Forwarding Reindex Target Media for DSW',
  disableLibraryBannerImage: 'Disable Library Banner Image',
  disableSmartEquipModalTitle: 'Are you sure you want to disable this license?',
  disableTEK: 'Disable Direct URL Login',
  disabled: 'Disabled',
  discard: 'Discard',
  discardChanges: 'Discard changes',
  discardQ: 'Discard changes?',
  discounted: 'Discounted',
  discountedPrice: 'Discounted Price',
  discountedTotal: 'Discounted Total',
  discountPrice: 'Discount Price',
  displayInOrdersList: 'Display in Orders List',
  displayOrder: 'Display Order',
  displayOrderDescription: 'Sets the display order of the Filters drop-down for content type tags',
  document: 'Document | Documents',
  documentLabel: 'Document | Documents',
  documentSettings: 'Document Settings',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Documoto License Package',
  domain: 'Domain',
  domainEmptyMessage: 'Allowed Domain cannot be empty',
  domainInvalidMessage: 'A valid domain name is required',
  download: 'Download',
  downloadBrowser: 'Download one of these great browsers.',
  downloadFailed: 'Error downloading {name}',
  downloadStarted: 'Download in progress for {name}',
  draft: 'Draft | Drafts',
  draftBomDeleteTitle: 'Are you sure you want to delete this part from the page?',
  draftBomMultipleDeleteTitle: 'Are you sure you want to delete these parts from the page?',
  draftBomDropError: 'Cannot nest parts more than 5 levels deep',
  draftBomEmptyField: 'All fields in Parts List must be completed',
  draftBomQuantityError: 'Quantity must be a number',
  draftBomError: 'Error manipulating draft Bill of Materials',
  draftPage: 'Draft Page',
  draftPageDelete: 'Delete Draft',
  draftPageDeleteMessage: 'Draft page deleted successfully.',
  draftPageDeleteWarning: 'Deleting Page draft will remove updates in progress.',
  draftPageLastUpdated: 'Draft - Last Updated {date} by {username}',
  draftPageLoadError: 'Draft page failed to be loaded.',
  draftPageProcessingPlz: 'Page being loaded from PLZ file',
  draftPagePublishInProgress: 'Page Publishing Job in progress',
  draftPageSaveSuccess: 'Draft page saved successfully.',
  draftPageSaveUnsuccessful: 'Draft page save unsuccessful.',
  draftSheetImageHotpointsFailure: 'Unable to generate {algorithm} hotpoints for draftsheet image.',
  draftValidationMessage: {
    item: 'WARNING: BOM Part Number {partNumber} has no Item Number specified',
    empty: 'No Validation Errors/Warnings',
    hotpointOrHotpointLink: 'WARNING: BOM item {item} has no hotpoint(s)/hotpoint link(s) specified',
    quantity: 'BOM item {item} has no quantity specified',
    tag: 'BOM item {item} tag {tagName} missing tag value',
    translationDescription: 'BOM item {item} missing {lang} translation description',
    uom: 'BOM item {item} has no unit of measure specified',
    supersede: 'Part {partNumber} replaced {supersededPartNumber} due to supersession.'
  },
  drawAutohotpointRegion: 'Draw {shape} size',
  drawMinimumArea: 'Draw minimum area',
  dropFileMessage: 'Drop file here or click to upload.',
  duplicate: 'Duplicate',
  duplicateSaveError: 'Duplicate entry. Cannot save.',
  dutchDescription: 'Dutch Description',
  dutchName: 'Dutch Name',
  dynamic: 'Dynamic',
  dynamicNaming: 'Dynamic Naming',
  ecommerce: 'eCommerce',
  ecommerceEmailFormatCreated: 'ECommerce Email Format created',
  ecommerceEmailFormatSaved: 'ECommerce Email Format saved',
  ecommerceLogo: 'eCommerce Logo',
  ecommerceUseExtOrder: 'Ecommerce Use External Order Number',
  edit: 'Edit',
  editItem: 'Edit {item}',
  email: 'Email',
  emailFormat: 'Email Format',
  emailFormatTemplate: 'Email Format Template | Email Format Templates',
  emailList: 'Email List',
  emailMessage: 'An email is required',
  emailRecipient: 'Email Recipient | Email Recipients',
  emailRecipientMessage: 'These email addresses will receive an email when the order status changes to',
  emptyMessage: {
    orderSuggestions: 'Looks like there are no Order Suggestions yet for this part.'
  },
  emptyBomState: 'No BOM Items defined for page. Click +{add} to get started',
  enable: 'Enable',
  enable3d: 'Enable 3D',
  enableAll: 'Enable All',
  enableAPIKeyAdmin: 'Enable API Key Admin',
  enabled: 'Enabled',
  enableCommerce: 'Enable Commerce',
  enableEcommerceOrderManagement: 'Enable Order Management',
  enableExporter: 'Enable Exporter',
  enableExportToLinkOne: 'Enable Export to LinkOne',
  enableExportToSmartEquip: 'Enable Export to SmartEquip',
  enablePDFDownloadWarning: 'Enable Large PDF Download Warning',
  largePDFDownloadWarning: 'Due to the large size of the PDF file, it may take longer than usual to load and display the document within the application - please be patient as the file downloads for viewing purposes.',
  enableIframeSupport: 'Enable iFrame Support',
  enableLibraryBetaFeatures: 'Enable Library Beta Features',
  enablePerformanceLogging: 'Enable Performance Logging',
  enablePLZToDraft: 'Enable PLZ Process to Draft',
  enableQuickAddToCart: 'Enable Quick Add to Cart',
  enableQuickAddToCartHelpContext: 'Setting to \'Enable\' will enable the Quick Add to Cart feature above the content viewer for this content.',
  enableRocketUi: 'Enable Rocket User Experience',
  enableSmartEquipApiIntegration: 'Enable SmartEquip API Integration',
  enableWidgetLogo: 'Enable Documoto Widget Logo',
  enableWidgets: 'Enable Widgets',
  encryptedKey: 'Encrypted Key',
  endDate: 'End Date',
  endDateBad: 'The start date must be equal or before the end date.',
  enforceNumericSize: 'Enforce Numeric Size',
  englishDescription: 'English Description',
  englishName: 'English Name',
  enterprise: 'Enterprise',
  enterReportParameters: 'Enter Report Parameters',
  enterUniqueNameForNewItem: 'Enter a unique name for the new {item}',
  enterValidNumber: 'Please enter a valid number',
  enterValidEmail: 'Please enter a valid email',
  entityType: 'Entity Type | Entity Types',
  erp: 'ERP',
  erpEndpointConfig: 'ERP-Endpoint Configuration',
  erpPartInfo: 'ERP Part Info Cache Duration Minutes',
  erpSystem: 'ERP System',
  erpSystems: 'ERP Systems',
  erpSystemSaved: 'ERP System Saved',
  error: 'Error',
  errorDeletePageMessage: 'An error has occurred while deleting the page.',
  errorInputParsing: 'Problem parsing input file.',
  errorPageMessage: 'An error has occurred.',
  errorPageTitle: 'Something\'s wrong here...',
  errorType: {
    error: 'Error',
    warning: 'Warning'
  },
  essential: 'Essential | Essentials',
  eta: 'ETA',
  etlJob: 'ETL',
  exact: 'Exact',
  excelFile: 'Excel File',
  expandAll: 'Expand All',
  export: 'Export',
  exportSearch: 'Export Search',
  exportAsCsv: 'Export as CSV (Required Format for Import)',
  exportAsCSVDropdown: 'Export as CSV',
  exportAsHtml: 'Export as HTML',
  exportAsPdf: 'Export as PDF',
  exportAsPlz: 'Export as PLZ',
  exportAsXlsx: 'Export as XLSX',
  exportBasicPartInfo: 'Export Basic Part Information',
  exportCartToXlsxDisabled: 'Disable Export Cart to XLSX',
  exportCartToXlsxEnabled: 'Enable Export Cart to XLSX',
  exportDisplayedCartColumns: 'Export Displayed Cart Columns',
  exportJob: 'Export Job',
  exportLane: 'Export Lane',
  exportMessage: 'Successfully submitted export job.',
  exporter: 'Exporter',
  exporterMessage: 'Allows you to export content',
  exportParts: 'Export Parts',
  external: 'External | Externals',
  externalUrl: 'External URL',
  faceted: 'Faceted',
  facetedTag: 'Filter values should be faceted',
  facetedTagTooMany: 'Tag exceeds 1,000 unique tag value limit',
  failedAction: '{content} failed to {action}.',
  failedProcessContentSql: 'Process Content failed due to unauthorized text. Please re-upload supported content.',
  failedToCopy: '{content} failed to copy.',
  failedToDelete: '{content} failed to delete.',
  failedToLoad: '{content} failed to load.',
  failedToSave: '{content} failed to save.',
  failedToSubmitItem: 'Failed to submit {item}.',
  failedToUpdate: '{content} failed to update.',
  false: 'False',
  faq: 'FAQ',
  favorites: 'Favorites',
  favoritesLists: 'Favorites Lists',
  february: 'February',
  feedbackForm: 'Submit Feedback',
  feedbackFormMessage: 'Provide input on features you would like to see',
  fieldDefinition: 'Field Definition',
  field: 'Field | Fields',
  fieldName: 'Field Name',
  file: 'File | Files',
  filename: 'Filename',
  filesSubmitted: 'File(s) submitted for processing',
  fileUpload: 'File Upload',
  fill: 'Fill',
  filter: 'Filter | Filters',
  filterOrgNameOnly: 'Filter: Org Name Only',
  filterOrgParentNames: 'Filter: Org & Parent Names',
  filterable: 'Filterable',
  filterableTag: 'Filterable Tag',
  filterBy: 'Filter By',
  filterBoundsError: 'Filter must contain 3 or more characters',
  finnishDescription: 'Finnish Description',
  finnishName: 'Finnish Name',
  firstName: 'First Name',
  firstNameEmpty: 'First name cannot be empty',
  fixedHotpointSize: 'Fixed Hotpoint Size',
  font: 'Font | Fonts',
  footer: 'Footer',
  forgotPassword: 'Forgot Password',
  forgotPasswordMessage: 'An email with instructions to reset your password will be sent to the username provided.',
  forgotPasswordSuccess: 'If the username provided is valid, an email with instructions to reset your password will be sent to the address on file.',
  form: 'Form | Forms',
  format: 'Format',
  formattingOptions: 'Formatting Options',
  formCount: 'Form Count',
  formName: 'Form Name',
  formType: 'Form Type',
  freezeImage: 'Freeze Image',
  frenchDescription: 'French Description',
  frenchName: 'French Name',
  fri: 'Fri',
  from: 'From',
  fromDate: 'From Date',
  frontCover: 'Front Cover',
  frontStitch: 'Front Stitch',
  fullScreen: 'Full Screen',
  gdprFaqs: 'GDPR FAQS',
  gdprMessage: 'By logging into this section of our website you understand that we use essential cookies. These cookies are essential to enable you to navigate our Services. Without these cookies, we cannot provide services you have requested. For more information, see our {policy}.',
  general: 'General',
  generalSearch: 'General Search',
  generic: 'Generic',
  germanDescription: 'German Description',
  germanName: 'German Name',
  gotIt: 'Got It',
  goTo: 'Go To',
  goToHome: 'Go to Home',
  goToJobManager: 'Go to Job Manager',
  goToSearch: 'Go to Search',
  hashKey: 'Hash Key',
  headerAmpFooter: 'Header & Footer',
  header: 'Header',
  help: 'Help',
  helpCenter: 'Help Center',
  highlightDescription: 'Highlight parts in parts list with this tag',
  history: 'History',
  home: 'Home',
  horizontal: 'Horizontal',
  host: 'Host',
  hotpoint: 'Hotpoint | Hotpoints',
  hotpointCalloutBehavior: 'Hotpoint Callout Behavior',
  hotpointLinksWillBeCreated: 'Hotpoint links will be created in {count} {content}',
  hotpointLink: 'Hotpoint Link | Hotpoint Links',
  hotpointsGenerated: '{count} hotpoints found on the image.',
  hotpointShift: 'Hotpoint (Shift H)',
  hourlyDWSRequestLimit: 'Hourly DWS Request Limit',
  hourlyRESTRequestLimit: 'Hourly REST Request Limit',
  htmlUiUserSwitchEnabled: 'HTML UI User Switch Enabled',
  hungarianDescription: 'Hungarian Description',
  hungarianName: 'Hungarian Name',
  icon: 'Icon',
  id: 'ID',
  identifier: 'Identifier',
  ignore: 'IGNORE',
  ignoreAccessControls: 'Ignore Access Controls',
  ignoreMatchingTranslations: 'Ignore Translations with matching locales on Import',
  illustration: 'Illustration | Illustrations',
  image: 'Image | Images',
  imageDeleteMessage: 'Are you sure you want to delete this Page Image?',
  imageUploadFailure: 'Image Failed to Upload',
  imageSaveFail: 'Image Failed to Save',
  imageOptimizer: 'Image Optimizer',
  imageSizeExceededTitle: 'Maximum Image Size Exceeded',
  imageSizeExceededMessage: 'The maximum image file size allowed in Documoto is {size} megabytes. The image you are attempting to upload has exceeded the maximum and cannot be loaded. Please resize the image to add to this page.',
  imageDimensionsExceededMessage: 'The maximum image size in Documoto is 65.5 megapixels (8,191 X 8,191 pixels).  The image you are attempting to upload has exceeded the maximum and cannot be loaded. Click "Resize" to allow Documoto to resize and insert the image, or "Cancel" to edit the image and insert manually',
  import: 'Import',
  importBOM: 'Import BOM',
  importBOMError: 'Failed to process sample data, recheck input source',
  importParts: 'Import Parts',
  inCart: 'In Cart',
  inCartDescription: 'Display tags in cart',
  includePagePartDetails: 'Include Page Part-level details',
  includePartDetails: 'Include Part-level details',
  indexQueueJob: 'Index Queue',
  inPartsList: 'In Parts List',
  inPartsListDescription: 'Display tags in parts list',
  inputSource: 'Input Source',
  inch: 'Inch | Inches',
  includeComments: 'Include Comments',
  includeCommentsAuthorAndDate: 'Include Comment Author and Date',
  includeHeaderRow: 'Include Header Row',
  includeImages: 'Include Images',
  includePagesIndex: 'Include Pages Index',
  includePartsIndex: 'Include Parts Index',
  includePartsList: 'Include Parts List',
  includeSubChapters: 'Include Sub Chapters',
  indexLane: 'Index Lane',
  useSequentialPageNumbering: 'Use Sequential Page Numbering',
  hideBookInSearchAndRecent: 'Hide Books in Search Results & Recently Added',
  hideDuplicatePages: 'Hide Duplicate Pages',
  hidePageInSearchAndRecent: 'Hide Pages in Search Results & Recently Added',
  hidePartInSearchAndRecent: 'Hide Parts in Search Results & Recently Added',
  homePageSettings: 'Home Page Settings',
  hotpointZoomBehavior: 'Hotpoint Zoom Behavior',
  includeNewParts: 'Include only "new" parts (not in Documoto)',
  includeWatermark: 'Include Watermark?',
  increaseHotpoint: 'Increase Hotpoint',
  indexed: 'Indexed',
  indexingConfiguration: 'Indexing Configuration',
  indexIncludeTimestamp: 'Index Include Timestamp',
  info: 'Info',
  information: 'Information',
  inherit: 'Inherit',
  inheritedParent: 'Inherited from Parent.',
  inputSpecification: 'Input Specification',
  inputType: 'Input Type',
  inputTypeListboxWarning: 'Sorting configurations will display upon save',
  integration: 'Integration | Integrations',
  integrationsAddPartToErpCart: '{action} Add Part to ERP Cart',
  integrationsAddToShoppingCartPricingFromErp: '{action} Add to Shopping Cart Pricing from ERP',
  integrationsDisableErp: 'Disable ERP',
  integrationsDoErpForQuotes: 'Do ERP for Quotes',
  integrationsEnableErp: 'Enable ERP',
  integrationsEnableFetchPartInfoFromErp: 'Enable Fetch Part Info from ERP',
  integrationsEnableScopeErp: 'Enable {scope} ERP',
  integrationsEnableSendShoppingCartToErp: 'Enable Send Shopping Cart to ERP',
  integrationsEnableSyncPoAddressWithErp: 'Enable Sync PO Address with ERP',
  integrationsErpAvailabilityMappedFrom: 'ERP Availability Mapped From',
  integrationsErpCurrencyCodeMappedFrom: 'ERP Currency Code Mapped From',
  integrationsErpDiscountedPriceMappedFrom: 'ERP Discounted Price Mapped From',
  integrationsErpEtaMappedFrom: 'ERP ETA Mapped From',
  integrationsErpMappings: 'ERP Mappings',
  integrationsErpRetailPriceMappedFrom: 'ERP Retail Price Mapped From',
  integrationsErpWholesalePriceMappedFrom: 'ERP Wholesale Price Mapped From',
  integrationsInvokeGetPartInfoFromClient: 'Invoke GetPartInfo from Client',
  integrationsParseAddressForType: 'Parse Address for Type',
  integrationsShoppingCartAndPartsTitle: 'Shopping Cart and Parts',
  integrationsShoppingCartAvailability: '{action} Shopping Cart Availability',
  integrationsShoppingCartEtaFromErp: '{action} Shopping Cart ETA from ERP',
  internalApiKey: 'Internal API Key',
  internalDescription: 'Internal Description',
  internalName: 'Internal Name',
  internalNameOptional: 'Your identifier for the tag',
  internalNameRequired: 'A name is required',
  invalidDecimals: 'Only two decimal places are allowed',
  invalidField: '{field} is invalid',
  invalidFileType: 'Unsupported file type. Please see the Documoto Support Center for a complete list of supported file extensions.',
  isSearchable: 'Is Searchable',
  insertDataField: 'Insert Data Field',
  isSystem: 'Is System',
  italianDescription: 'Italian Description',
  italianName: 'Italian Name',
  item: '#',
  itemAlt: 'Item',
  itemCopied: '{item} copied',
  itemDeleted: '{item} deleted',
  itemInProgress: '{item} in progress',
  itemInformation: '{item} information',
  itemMustBeLteItem: 'The {itemA} must be equal or before the {itemB}.',
  itemMustBeUnique: '{item} must be unique',
  itemMustBeUniqueAndNotEmpty: '{item} must be unique and is required',
  itemName: '{item} Name',
  itemNotShown: '',
  itemSaved: '{item} saved',
  itemSpecification: 'Item Specification',
  showChapterTOCForHiddenPages: 'Show Chapter TOC for Hidden Pages',
  january: 'January',
  japaneseDescription: 'Japanese Description',
  japaneseName: 'Japanese Name',
  job: 'Job | Jobs',
  jobManager: 'Job Manager',
  jobManagerMessage: 'Export, publish, and print/download jobs',
  july: 'July',
  june: 'June',
  koreanDescription: 'Korean Description',
  koreanName: 'Korean Name',
  label: 'Label | Labels',
  labelKey: 'Label Key',
  landscape: 'Landscape',
  laneConfiguration: 'Lane Configuration',
  language: 'Language',
  lastModifiedDate: 'Last Modified Date',
  lastGenerated: 'Last Generated',
  lastName: 'Last Name',
  lastNameEmpty: 'Last name cannot be empty',
  lastUpdatedOn: 'Last updated on',
  layout: 'Layout',
  layoutOptions: 'Layout Options',
  ledger: 'Ledger',
  left: 'Left',
  legal: 'Legal',
  letter: 'Letter',
  library: 'Library',
  libraryAdmin: 'Library Admin',
  libraryBannerLogo: 'Library Banner Logo',
  librarySettings: 'Library Settings',
  librarySettingsDescription: 'These settings control various Library elements and options. Use care when making changes. Contact Documoto Services for further information.',
  licenseConfiguration: 'License Configuration',
  line: 'Line',
  lineTool: 'Line Tool',
  link: 'Link | Links',
  listBox: 'List Box',
  listBoxValue: 'List Box Value | List Box Values',
  listValue: 'List Value | List Values',
  load: 'Load',
  loadMore: 'Load more',
  loading: 'Loading',
  locale: 'Locale',
  login: 'Login',
  loginBackground: 'Login Background',
  loginGDPRMessageBeginning: 'This website uses cookies. By continuing to use this website you are giving consent to cookies being used. For more information, see our ',
  loginGDPRMessageEnd: 'and our',
  loginSSO: 'Login SSO',
  logiReportName: 'Logi Report Name',
  logiReportToggle: 'Logi Report?',
  logo: 'Logo | Logos',
  logout: 'Logout',
  lookupLoadParts: 'Lookup/Load Parts from Documoto on Import',
  lowerBound: 'Lower Bound',
  makePagesDivisibleBy: 'Make Pages Divisible By:',
  mainInfo: 'Main Info',
  mainLogo: 'Main Logo',
  manageItem: 'Manage {item}',
  management: 'Management',
  march: 'March',
  margin: 'Margin | Margins',
  markdown: 'Markdown',
  may: 'May',
  media: 'Media | Media',
  mediaCategory: 'Media Category | Media Categories',
  mediaCategoryEditor: 'Media Category Editor',
  mediaCategoryName: 'Media Category Name',
  mediaCategoryNameMissing: 'All media categories require a name',
  mediaBookDeleteMessage: 'Deleting this media will remove access to this content in both the Library and Content Admin and it will no longer be available for users or Content Administrators. Any parts or pages found in this book that are not found in other books will also no longer be available in the Library.',
  mediaDeleteMessage: 'Deleting this template will remove it from Content Admin and it will no longer be available for copying to new Media.',
  mediaDeleteSuccessText: 'It will be removed from lists once indexing is completed.',
  mediaDescription: 'Media Description',
  mediaIdentifier: 'Media Identifier',
  mediaName: 'Media Name',
  mediaPrintSettingsSaveSuccess: 'Media Print Settings saved',
  mediaWhereUsed: 'Media Where Used',
  menu: 'Menu',
  merge: 'Merge',
  mergeOrOverrideMessage: 'Would you like to overwrite or merge these items?',
  mergeWarning: 'Importing data will update existing parts and add the new entries',
  message: 'Message',
  messageLevel: 'Message Level',
  microsite: 'Microsite | Microsites',
  micrositeAdmin: {
    cardSubtitle: 'The compressed content file and HTML entry information',
    cardTitle: 'Site Content',
    errorRequiredFile: 'A Site Content ZIP file is required',
    fieldFile: 'Site Content ZIP File',
    fieldPoint: 'HTML Entry Point'
  },
  minArea: 'Minimum Area',
  minAreaMessage: 'Minimum value is required. Value may not exceed a range of 1 to 1500.  Must be an integer value.',
  moreInfo: 'More Info',
  multipleAlternatingPages: 'Multiple Alternating Pages',
  multipleAlternatingPagesMessage: 'Diagram on pages 1 and 3, parts list on pages 2 and 4.',
  mToM: 'M2M',
  multiplePages: 'Multiple Pages',
  multiplePagesMessage: 'Diagram on it\'s own page followed by parts list pages',
  requiredField: 'Required Field',
  messageMessage: 'A message is required',
  metadata: 'Metadata',
  mine: 'Mine',
  miscellaneous: 'Miscellaneous',
  mon: 'Mon',
  myCart: 'My Cart | My Carts',
  navigation: 'Navigation',
  nItemsCreated: '{n} {items} Created',
  nItemsDeleted: '{n} {items} Deleted',
  nOfMItems: '{n} of {m} {items}',
  name: 'Name',
  nameDescriptionHelpText: 'The name and optional description',
  new: 'New',
  newAPIKey: 'New API Key',
  newERPSystem: 'New ERP System',
  newItem: 'New {item}',
  newKeyGenerated: 'New Integration Key Generated, Copy & Retain for future use',
  newMediaCategory: 'New Media Category',
  newPassword: 'New Password',
  newsItem: 'News Item | News Items',
  newTenant: 'New Tenant',
  newWidgetKey: 'New Widget Key',
  newWidgetKeyGenerated: 'New Widget Key Generated, Copy & Retain for future use',
  noTenantSuppliers: 'No Suppliers assigned to Tenant.',
  noAPIKeys: 'No API Keys defined.',
  node: 'Node',
  noData: 'No Data',
  noFilesSelected: 'No Files Selected',
  noIllustrationFound: 'No Illustrations Found',
  noImageFound: 'No Image Found',
  noItemAvailable: 'No {item} available',
  noItemFound: 'No {item} found.',
  noItemSelected: 'No {item} selected',
  noItemAddedToTarget: 'No {item} added to the {target}. ',
  noItemsFound: 'No Items Found',
  noMoreResults: 'No more results',
  noFormsMessage: 'No Forms defined.',
  noMediaDefined: 'No Media Defined',
  nonSearchableFieldsSuccessfullyUpdated: 'Non-Searchable Fields Successfully Updated.',
  noPartsSelected: 'No parts selected.',
  noPrice: 'Call for Price',
  noResults: 'No results',
  norwegianDescription: 'Norwegian Description',
  norwegianName: 'Norwegian Name',
  noSearchableTags: 'No searchable tags',
  noSuggestions: 'No suggestions for search term',
  notOrderable: 'Not Orderable',
  notSelected: 'Not selected',
  noTagsFound: 'No Tags Found',
  noUnsavedChanges: 'No Unsaved Changes',
  none: 'None',
  notImplemented: 'Not Implemented',
  notification: 'Notification | Notifications',
  notificationsMessage: 'Important announcements and news',
  notMapped: 'Not Mapped',
  november: 'November',
  noWidgetKeys: 'No Widget Keys defined.',
  numeric: 'Numeric',
  numberOf: '# of {item}',
  october: 'October',
  of: 'of',
  off: 'Off',
  offlineContent: 'Offline Content',
  ok: 'Ok',
  oldPassword: 'Old Password',
  on: 'On',
  opacity: 'Opacity',
  open: 'Open',
  openItem: 'Open {item}',
  optimizeIndexJob: 'Optimize Index',
  options: 'Options',
  openTab: 'Open link in a new tab',
  order: 'Order | Orders',
  orderable: 'Orderable',
  orderableDescription: 'Part can be added to the cart and purchased',
  orderableLocal: 'Orderable on this page',
  orderableLocalBlocked: 'Part not Orderable Globally',
  orderDisable: 'Disable Order History',
  orderEmailRecipient: 'Order Email Recipient | Order Email Recipients',
  orderEntity: 'Order Entity | Order Entities',
  orderFieldUneditableRequired: 'Setting an Order/Quote Field to Required and not User Editable can make your Order/Quote Form impossible to complete. Please verify your Order/Quote Forms can be completed.',
  orderFieldsEmptyMessage: 'No Order/Quote Fields defined.',
  orderFieldsEmptyPrompt: 'Add an Order/Quote Field',
  orderField: {
    nameMessage: 'If you change the Order/Quote Field name, be sure to update any Order/Quote Forms or Email Formats that use this field.'
  },
  orderFieldCopyMessage: 'Enter a unique name for the new Order/Quote Field',
  orderForm: {
    uniqueNameInputHint: 'Enter a unique name for the new Form.'
  },
  orderFormField: 'Order/Quote Field | Order/Quote Fields',
  orderFormLayout: 'Order/Quote Form Layout | Order/Quote Form Layouts',
  orderFormSetDefault: 'Default Order/Quote Form set',
  orderFormType: 'Order Form',
  orderLayout: 'Order Layout | Order Layouts',
  orderManagementApprover: 'Order Management Approver',
  orderManagementDisable: 'Disable Order Management Admin',
  orderManagementViewer: 'Order Management Viewer',
  orderNumber: 'Order Number',
  orderQuoteEmail: 'Order/Quote Email | Order/Quote Emails',
  orderQuoteEmailFormat: 'Order/Quote Email Format | Order/Quote Email Formats',
  orderQuoteEmailFormatCopyMessage: 'Select a language for the new Order/Quote Email Format.',
  orderQuoteFetchError: 'Error getting Order/Quote Form data',
  orderQuoteForm: 'Order/Quote Form | Order/Quote Forms',
  orderQuoteSaveConfirmation: 'Save your changes to Organization Order/Quote forms?',
  orderQuoteSaveError: 'Error saving Order/Quote Form data',
  orderQuoteFormSaved: 'Order/Quote Form Saved',
  orderStatus: 'Order/Quote Status | Order/Quote Statuses',
  orderStatusDeleteMessage: 'This status has been used on orders and cannot be deleted.',
  orderSubmittedMessage: 'Successfully Submitted',
  orderSuggestion: 'Order Suggestion | Order Suggestions',
  orderSuggestionEnable: 'Enable Order Suggestions',
  orderSuggestionRemovalWarning: 'The part will no longer show as an order suggestion. | The parts will no longer show as order suggestions.',
  orderSuggestionThreshold: 'Order Suggestion Threshold',
  ordersLogo: 'Orders Logo',
  ordersMessage: 'Order submission history',
  orientation: 'Orientation',
  org: 'Org',
  orgEmailRecipients: 'Org Email Recipients',
  organization: 'Organization | Organizations',
  organizationAdmin: 'Organization Admin',
  organizationAdminBrowseFlow: 'An Organization can use the default browse flow, or you can override and use any other browse flow.',
  organizationAdminMessage: 'Controls user management and org settings',
  organizationEmpty: 'Organization must be selected',
  organizationEmailRecipients: 'Organization Email Recipients',
  organizationEnforceAccessControlsForQuickAdd: 'Enforce Access Controls for Quick Add',
  organizationKey: 'Organization Key',
  organizationLibrarySettingsDescription: 'These settings control various Organization-level Library elements and options. Use care when making changes. Contact Documoto Services for further information.',
  organizationMoveToRoot: 'Drop here to move to root',
  organizationName: 'Organization Name',
  organizationRearrange: 'Rearrange Organizations',
  organizationRearrangeDescription: 'The parent-child Organizational structure defines many inheritable attributes of an Organization such as Print Settings, Styles, and others. Drag and drop Organizations to reflect the desired hierarchy.',
  organizationReportSettingsSuccessMessage: 'Report settings saved',
  organizationSelect: 'Select Organization',
  organizationTags: 'Organization Tags',
  organizationsEmpty: 'Looks like you haven\'t created any organizations yet',
  orgNameOnly: 'Org Name Only',
  other: 'Other',
  otherSettings: 'Other Settings',
  outboundCustomerIdParameter: 'Outbound Customer ID Parameter Name',
  outboundSessionIdParameter: 'Outbound Session ID Parameter Name',
  outlineMode: 'Outline Mode (Shift O)',
  output: 'Output',
  outputCatalog: 'Output Catalog',
  outputDefinition: 'Output Definition | Output Definitions',
  outputDefinitionDeleted: 'Output Definition deleted',
  outputDefinitionSaved: 'Output Definition Saved',
  outputFileName: 'Output File Name',
  outputName: 'Output Name',
  outputDestination: 'Output Destination',
  outputSpecification: 'Output Specification',
  outputSubType: 'Output Subtype',
  outputType: 'Output Type',
  overridden: 'Overridden',
  overriddenByOrg: 'Overridden by Org',
  override: 'Override',
  overrideBrowseFlow: 'Override Browse Flow',
  overrideFacetLimitValue: 'Override Facet Limit Value',
  overrideHotpointScale: 'Override Hotpoint Scale',
  overridePdfNamingStandard: 'Override PDF Filenaming Standard',
  overridePrintSettings: 'Override Print Settings',
  overrideFontSettings: 'Override Font Settings',
  overrideOrderForm: 'Override Order Form',
  overrideQuoteForm: 'Override Quote Form',
  overrideSvgOpacity: 'Override SVG Opacity',
  overrideWarning: 'Importing data will replace your current BOM list',
  owner: 'Owner | Owners',
  page: 'Page | Pages',
  pageBulkInsertConfirmTitle: 'Do you want to complete the bulk insert?',
  pageBulkInsertConfirmInsertAfter: 'This will insert {replacement} after {original} in {whereUsedBookCount} {bookUnits} and {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'This will insert {replacement} before {original} in {whereUsedBookCount} {bookUnits} and {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'This will insert {replacement} and remove {original} in {whereUsedBookCount} {bookUnits} and {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'This will insert {replacement} after {original} in {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'This will insert {replacement} before {original} in {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'This will insert {replacement} and remove {original} in {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'This will insert {replacement} after {original} in {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'This will insert {replacement} before {original} in {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'This will insert {replacement} and remove {original} in {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'Your bulk insert will not be processed.',
  pageBulkInsertHelpText: 'This feature allows you to insert this source page {thisDisplayName} into selected parts books where another page {originalDisplayName} already exists. Optionally, you can also remove {originalDisplayName} from those books.',
  pageBulkInsertInsertAfter: 'Insert {replacement} AFTER {original}',
  pageBulkInsertInsertBefore: 'Insert {replacement} BEFORE {original}',
  pageBulkInsertInsertReplace: 'Insert {replacement} and REMOVE {original}',
  pageBulkInsertScopeAll: 'All {type} that have the target page {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'None of the {type} that have the target page {originalDisplayName}',
  pageBulkInsertScopeSome: 'Some of the {type} that have the target page {originalDisplayName}',
  pageBulkInsertStep1: 'Step 1: Find a desired target page',
  pageBulkInsertStep2: 'Step 2: Decide how to insert or replace the pages',
  pageBulkInsertStep3: 'Step 3: Choose the books where you want the bulk insert to be processed.',
  pageBulkInsertStep4: 'Step 4: Choose the templates where you want the bulk insert to be processed.',
  pageBulkInsertSuccessTitle: 'Bulk insert job submitted',
  pageBulkInsertSuccessBody: 'Book table of contents changes will be made immediately. Search results will be reflected once indexing completes.',
  pageBulkErrorTooManyBooks: 'Limit the selected number of books to no more than 1000',
  pageBuilder: 'Page Builder',
  pageComposer: 'Page Composer',
  pageCreated: 'Page Created',
  pageDeleteMessage: 'Page deletes will take effect immediately in Library and Content Admin. The deleted page will remain searchable until reindexing has occurred within a few minutes.',
  pageDeleteMessage2: 'This action cannot be undone without Documoto Support assistance. After 90 days the deleted record and all associations will be permanently purged.',
  pageDescription: 'Page Description',
  pageFile: 'Page File',
  pageFilename: 'Page Filename',
  pageFilenameDeleted: '{filename} will be deleted.',
  pageFileRequired: 'A page file is required.',
  pageBomPartsDeleted: '{count} parts on page BOM will no longer be accessible via {filename}.',
  pageDraftsDeleted: 'Draft pages associated to this page will be permanently deleted.',
  pageFit: 'Page Fit',
  pageHeader: 'Page Header',
  pageLabel: 'Page | Pages',
  pageLayout: 'Page Layout',
  pageName: 'Page Name',
  pagePart: 'Page Part | Page Parts',
  pagePartTag: 'Page Part Tag | Page Part Tags',
  pagePartData: 'Page Part Data',
  pageUsageDataCouldNotBeLoaded: 'Page Usage Data Could Not Be Loaded',
  pagePartLevelDetails: 'Page Part Orderable/Visible, Page Part Tags',
  pagePartNumberVisible: 'Page Part Number Visible',
  pagePartOrderable: 'Page Part Orderable',
  pagePartOrderableDescription: 'Part Orderable from this page',
  pagePartVisibleDescription: 'Part Number visible on this page',
  pageUpdated: 'Page Updated',
  pageViewer: 'Page Viewer',
  pageWidth: 'Page Width',
  pagesWhereUsed: 'Pages Where Used',
  parentName: 'Parent Name | Parent Names',
  part: 'Part | Parts',
  partCode: 'Part Code | Part Codes',
  partCodeAddError: 'Cannot add Part Code(s)',
  partCodeErrorEmpty: 'Could not save: Part code cannot be empty',
  partCodeErrorInUse: 'This part code is being used by parts in Documoto and cannot be deleted. Contact Documoto Support at support{\'@\'}documoto.com for assistance.',
  partCodeErrorNotFound: 'This part codes could not be deleted because it could not be found, please make sure all changes are saved.',
  partCodesManager: 'Part Codes Manager',
  partCodesMessage: 'Part codes can be used in place of part numbers, and are often used to indicate when BOM items are obsolete, not available, or are reference only items (ex. \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Part Created',
  partData: 'Part Data',
  partUpdated: 'Part Updated',
  partDebouncerEmailQueueJob: 'Email Queue',
  partDescription: 'Part Description',
  partLabel: 'Part | Parts',
  partLevelDetails: 'Part Pricing, Orderable/Visible, Additional Translations, Part Tags',
  partName: 'Part Name',
  partnerApiKey: 'Partner API Key',
  partnerRESTRequestLimit: 'Partner REST Request Limit',
  partNumber: 'Part Number',
  partNumberDescription: 'The internal part number for end users to search',
  partNumberVisible: 'Part Number Visible',
  partNumberVisibleDescription: 'Part number is visible to end users',
  partNumberVisibleLocal: 'Visible on this page',
  partNumberVisibleLocalBlocked: 'Part Number not Visible Globally',
  partOrderable: 'Part Orderable',
  partRequired: 'At least one part is required',
  partTagProperty: 'Part Tag Property | Part Tag Properties',
  partTag: 'Part Tag | Part Tags',
  partThumbnailDescription: '',
  partialSupport: 'Partial Support',
  partsListColumn: 'Parts List Column | Parts List Columns',
  partsBook: 'Parts Book | Parts Books',
  partsColumns: 'Parts Columns',
  partsNotSelected: 'Default image used for part thumbnails. If a custom thumbnail is not applied, Documoto will display a standard default thumbnail.',
  partsPage: 'Parts Page | Parts Pages',
  partsPages: 'Parts Pages',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Exclude Part Codes from Parts Lists',
    highlightAlternateRows: 'Highlight Alternate Rows',
    rotateImagesAutomatically: 'Rotate Images Automatically',
    printBorderAroundImages: 'Print Border Around Images',
    keepImagesOnLeft: 'Keep Images on Left',
    keepBOMOnRight: 'Keep BOM on Right',
    columnsUpdateErrorMsg: 'An error occurred when trying to update Parts Columns',
    columnsUpdateSuccessMsg: 'Part Columns updated',
    columnsLoadErrorMsg: 'Failed to load parts columns',
    printHotpointsOnImages: 'Print Hotpoints on Images'
  },
  partsList: 'Parts List',
  partsListData: 'Parts List Data',
  partsListGridHeader: 'Parts List Grid Header',
  password: 'Password',
  passwordExpiredHeader: 'Password Expired',
  passwordExpiredInfo: 'Your password has expired. Your new password must meet the following criteria:',
  passwordCriteriaInfo: 'Your new password must meet the following criteria:',
  passwordBlankMessage: 'Password cannot be blank',
  passwordCannotBeSame: 'May not be the same as your current password',
  passwordCasingMassage: 'Must contain at least one lower case and one upper case character',
  passwordLowerCaseMessage: 'Password must contain at least one lower case character',
  passwordMinLengthMessage: 'Passwords must be at least 8 characters in length',
  passwordNotMatchingMessage: 'Passwords are not the same',
  passwordSpecialCharactersMessage: 'Password must contain at least one special character',
  passwordUpperCaseMessage: 'Password must contain at least one upper case character',
  passwordWillExpire1Day: 'Login Password will expire in less than 1 day',
  passwordWillExpire5Days: 'Login Password will expire in less than 5 days',
  passwordsMatch: 'Passwords Match',
  permission: 'Permission | Permissions',
  phoneNumber: 'Phone Number',
  phoneNumberExt: 'Ext',
  phonePrimary: 'Primary Phone Number',
  phoneSecondary: 'Secondary Phone Number',
  pinFilter: 'Pin filter to search results page',
  pinned: 'Pinned',
  plusMoreOrgs: '+ {totalOrgCount} additional Organizations',
  polishDescription: 'Polish Description',
  polishName: 'Polish Name',
  populatePdfNameWith: 'Populate PDF Filename with',
  populatePdfNameSelection: 'Populate PDF Filename Selection',
  port: 'Port',
  portrait: 'Portrait',
  portugueseDescription: 'Portuguese Description',
  portugueseName: 'Portuguese Name',
  postComment: 'Post Comment',
  postalCode: 'Postal Code',
  poweredBy: 'Powered By',
  preferences: 'Preferences',
  preferencesDisable: 'Disable User Preferences',
  prefix: 'Prefix | Prefixes',
  preview: 'Preview',
  previewOfName: 'Preview of {name}',
  pricing: 'Pricing',
  pricingDiscountEnabled: 'Display Discount Price',
  pricingDisplayInCart: 'Display Prices in Shopping Cart',
  pricingDisplayInPartsList: 'Display Prices in Parts List',
  pricingGlobal: 'Global Pricing',
  pricingGlobalDescription: 'The normal price when there is no Organization override',
  pricingHidePricingButton: 'Enable Hide Pricing Button',
  pricingHidePricingButtonDisable: 'Disable Hide Pricing Button',
  pricingOrganization: 'Organization Pricing',
  pricingOrganizationDeleteConfirm: 'Are you sure you want to delete this Organization pricing?',
  pricingOrganizationDescription: 'Organization pricing overrides global pricing for all users within an Organization and its child Organizations.',
  pricingOrganizationDuplicate: 'This organization is already being overridden',
  pricingOrganizationEmptyState: 'No organization overrides defined. Click {add} to get started.',
  pricingRetailEnabled: 'Display Retail Price',
  pricingWholesaleEnabled: 'Display Wholesale Price',
  primary: 'Primary',
  print: 'Print',
  printerLane: 'Printer Lane',
  printJob: 'Print Job',
  printLogo: 'Print Logo',
  printSetting: 'Print Setting',
  printSettingDeleteFail: 'Failed to Delete Print Setting',
  printSettingSaveFail: 'Failed to Save Print Setting',
  printSettingEmptyMessage: 'No Print Settings defined. Add a Print Setting to get started.',
  printSettingSetDefaultSuccess: 'Default Print Setting set',
  printSettingUpdateFail: 'Failed to Update Print Setting',
  printSettingCopyMessage: 'Enter a unique name for the new Print Setting.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Print Setting created',
  printSettings: 'Print Settings',
  printSettingsLogo: 'Print Settings Logo',
  printing: 'Printing',
  printingDisabled: 'Disable All Printing',
  privacyPolicy: 'Privacy Policy',
  proceed: 'Proceed',
  process: 'Process',
  processContent: 'Process Content',
  processing: 'Processing',
  processToDraft: 'Process to Draft',
  processedPlzToDraft: 'Processed PLZ(s) to Draft(s)',
  production: 'Production',
  professional: 'Professional',
  profile: 'Profile',
  prohibitExportDownloadOfContent: 'Prohibit Export/Download of Content',
  prohibitExportDownloadOfContentHelpText: 'Setting to \'Enable\' will prevent any user (with privileges) from exporting or downloading this content.',
  property: 'Property | Properties',
  province: 'Province',
  public: 'Public',
  publish: 'Publish',
  publisherLane: 'Publisher Lane',
  publisherManualLane: 'Publisher Manual Lane',
  publishThumbnailJob: 'Publish Thumbnail',
  publishDocuments: 'Publish Documents',
  publishDraft: 'Publish Draft',
  publisher: 'Publisher | Publishers',
  publishingErrors: 'Publishing Errors',
  publishingErrorsWithName: 'Publishing Errors {name}',
  publishingInProgress: 'Publishing in progress, cannot submit',
  publisherJob: 'Publisher Job',
  publisherMessage: 'Content creation, management, and print settings',
  purchaseOrder: 'Purchase Order',
  purchaseOrderTemplateEnabled: 'Enable Order Templates',
  purchaseOrderTemplateDisabled: 'Disable Order Templates',
  qty: 'Qty',
  quantity: 'Quantity',
  query: 'Query',
  quickAdd: 'Quick Add',
  quickAddHelpText: 'Start typing to find parts to add to the shopping cart.',
  quickAddInCartEnabled: 'Enable Quick Add in Cart',
  quickLinks: 'Quick Links',
  quote: 'Quote | Quotes',
  quoteFormType: 'Quote Form',
  quoteTemplateDisabled: 'Disable Quote Templates',
  quoteTemplateEnabled: 'Enable Quote Templates',
  range: 'Range',
  rearrange: 'Rearrange',
  rebuildIndexJob: 'Rebuild Index',
  recentSearches: 'Recent Searches',
  recentlyAdded: 'Recently Added',
  recentlyEdited: 'Recently Edited',
  recentlyViewed: 'Recently Viewed',
  rectangle: 'Rectangle',
  redirect: 'Redirect',
  refresh: 'Refresh',
  related: 'Related',
  relatedDescription: 'Relate media that exists in the library to this {type}. Note, related media must have access controls applied for users to see it in the \'Related\' tab.',
  relatedInfo: 'Related Info',
  relatedView: 'View Related Media',
  relationship: 'Relationship | Relationships',
  releaseNotes: 'Release Notes',
  rememberMe: 'Remember me',
  remainingWidth: 'Remaining Width',
  remove: 'Remove',
  removeAll: 'Remove All',
  removeItemConfirmation: 'Are you sure you want to remove {item}?',
  removeItemCount: 'Are you sure you want to delete {count} {type}?',
  replace: 'Replace',
  replaceExisting: 'Replace Existing',
  report: 'Report | Reports',
  reportAnIssue: 'Report an Issue',
  reportAnIssueMessage: 'Successfully sent email.',
  reportCatalogName: 'Report Catalog Name',
  reportCatalogNotSet: 'Report catalog has not been defined for the report.',
  reportDefinition: 'Report Definition | Report Definitions',
  reportDefinitionDeleteError: 'Report definition failed to be deleted',
  reportsEmptyState: 'No Reports',
  reportExportTimeWarning: 'Depending on the record count and the selected format the export may take several minutes to complete.',
  reporting: 'Reporting',
  reportingMessage: 'Access to various reports and metrics',
  reportSettings: 'Report Settings',
  reportsLibrary: 'Report Library',
  requestForQuote: 'Request for Quote',
  requestFormType: 'Quote Form',
  required: 'Required',
  requiredItem: '{item} is required.',
  requiredMessage: 'A {item} is required.',
  requiredMessageAndUnique: 'A {item} is required and must be unique.',
  requireAuthentication: 'Require Authentication',
  requiredReading: 'Required Reading',
  requiredReadingMessage: 'Please review the following:',
  requiredUrlMessage: 'A well-formed URL is required.',
  reset: 'Reset',
  resetPassword: 'Reset Password',
  resetPasswordError: 'There was a problem resetting your password, please try again.',
  resetPasswordTokenError: 'Password reset token is invalid.',
  resetZoom: 'Reset Zoom',
  resource: 'Resource | Resources',
  resize: 'Resize',
  resizeThumbnails: 'Automatically resize thumbnails',
  result: 'Result | Results',
  resultFor: 'Result for {item} | Results for {item}',
  retail: 'Retail',
  retailPrice: 'Retail Price',
  retailTotal: 'Retail Total',
  return: 'Return',
  returnToItem: 'Return to {item}',
  returnToPartList: 'Return to Parts List',
  returnToMedia: 'Return to Media',
  returnToTemplate: 'Return to Template',
  returnToViewer: 'Return to Viewer',
  revertChanges: 'Revert Changes',
  right: 'Right',
  romanianDescription: 'Romanian Description',
  romanianName: 'Romanian Name',
  rootLevelBrowseFlow: 'Root Level',
  row: 'Row',
  russianDescription: 'Russian Description',
  russianName: 'Russian Name',
  sampleData: 'Sample Data',
  samplePreview: 'Sample Preview',
  sandbox: 'Sandbox',
  sat: 'Sat',
  save: 'Save',
  saveChanges: 'Save your changes to {item}? {name}',
  saveChangesToItem: 'Save your changes to this {item}?',
  saved: 'Saved',
  saveNewAPIKey: 'New Integration Key not Saved, Return to complete setup',
  saveNewWidgetKey: 'New Widget Key not saved. Return to complete setup.',
  search: 'Search',
  searchableFieldsSuccessfullyUpdated: 'Searchable Fields Successfully Updated.',
  searchAllContent: 'Search All Content',
  searchConfiguration: 'Search Configuration',
  searchCriteria: 'Search Criteria',
  searchDocumoto: 'Search Documoto',
  searchFeatures: 'Search Features',
  searchFieldConfigDescription: 'The following system field search settings only apply to Library general search, the search results page and Quick Add in the Cart. Other search features (such as Admin Center search and Library Where Used) will not be affected.',
  searchInContent: 'Search in {contentName}...',
  searchPlaceholder: 'Search all content...',
  searchPrompt: 'Search all content...',
  searchProperty: 'Search Property | Search Properties',
  searchResultsConfiguration: 'Search Results Configuration',
  searchSettings: 'Search Settings',
  searchSettingsDescription: 'These settings control various Library search behavior, features and results.',
  searchTerm: 'Search Term',
  searchTips: 'Search Tips:',
  searchable: 'Searchable',
  searchableTag: 'Searchable Tag',
  section: 'Section',
  select: 'Select',
  selectAField: 'Select a {field}',
  selectAll: 'Select All',
  selectAndClose: 'Select and Close',
  selected: 'Selected',
  selectedTargetPage: 'Selected Target Page',
  selectChapters: 'Select Chapter(s)',
  selectChildren: 'Select Children',
  selectFields: 'Select {fields}',
  selectItem: 'Select {item}',
  selectMedia: 'Select Media',
  selectPage: 'Select Page',
  selectPages: 'Select Page(s)',
  selectTargetPage: 'Select Target Page',
  send: 'Send',
  september: 'September',
  serbianDescription: 'Serbian Description',
  serbianName: 'Serbian Name',
  serviceUri: 'Service URI',
  sessionLog: 'Session Log',
  set: 'Set',
  setAsDefault: 'Set as Default',
  settings: 'Settings',
  settingsMessage: 'Update your user preferences and password',
  sequence: 'Sequence',
  shape: 'Shape',
  shapeTool: 'Shape Tool',
  shared: 'Shared',
  sharedCart: 'Shared Cart | Shared Carts',
  sharedCartDeleteContent: 'This will remove this Shared Cart for all Organizations where it has been shared.',
  sharedCartOrganizationContent: 'Select the Organizations that can use this Shared Cart.',
  sharedShoppingCartsEnabled: 'Enable Shared Shopping Carts',
  shoppingCartExportJob: 'Shopping Cart Export',
  shoppingCartLoadingLongTime: 'The shopping cart is loading. The cart icons will become available once the process is complete. If the issue persists, please contact your Documoto Administrator for support.',
  showExportFromSearch: 'Allow Export from Search Results',
  showExportFromSearchHelp: 'Allow exporting the list of books resulting from a search',
  showHelp: 'Show Help',
  showIdentifierInSearch: 'Show Media Identifier in Search',
  showItem: 'Show {item}',
  showLess: 'Show Less',
  showMediaDescriptionFilter: 'Show Media Description Filter',
  showMediaIdentifierFilter: 'Show Media Identifier Filter',
  showMediaNameFilter: 'Show Media Name Filter',
  showMore: 'Show More',
  showOrganizationFilter: 'Show Organization Filter',
  showPartNameFilter: 'Show Part Name Filter',
  showPartNumberFilter: 'Show Part Number Filter',
  showRecentlyAdded: 'Show Recently Added',
  showRecentlyViewed: 'Show Recently Viewed',
  showSearchButton: 'Show Home Page Search Button',
  showSearchButtonHelp: 'Show the \'Go to Search\' button in the Library header',
  signature: 'Signature',
  size: 'Size',
  snapshot: 'Snapshot | Snapshots',
  solrSlaveSvrOverride: 'SOLR Slave Servers Base URLs Override',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Most Relevant',
  sortType: 'Sort Type',
  spanishDescription: 'Spanish Description',
  spanishName: 'Spanish Name',
  square: 'Square',
  ssoConfiguration: 'SSO Configuration',
  ssoIdpMetadataUrl: 'SSO IDP Metadata URL',
  ssoLoginMaxAuthenticationAgeSeconds: 'SSO Login Max Authentication Age Seconds',
  ssoSamlDnAttributeName: 'SSO SAML DN Attribute Name',
  ssoSamlEmailAttributeName: 'SSO SAML Email Attribute Name',
  ssoSamlMemberofAttributeName: 'SSO SAML MemberOf Attribute Name',
  ssoSniForSsoMetadataRetrieval: 'Use SNI For SSO Metadata Retrieval',
  standard: 'Standard',
  standardUserGroup: 'Standard User Group',
  startDate: 'Start Date',
  startDateRequired: 'A start date is required.',
  state: 'State',
  static: 'Static',
  status: 'Status',
  statusCode: 'Status Code | Status Codes',
  stitchPages: 'Stitch Pages',
  storeFrontAdmin: 'Storefront Admin',
  storefront: 'Storefront',
  storefrontIntegration: 'Storefront Integration | Storefront Integrations',
  storefrontIntegrationSubtitle: 'These settings control various Storefront Integration elements and options. Use care when making changes. Contact Documoto Support for further information.',
  storefrontSettings: 'Storefront Settings',
  storefrontSettingsDescription: 'These settings control various Storefront elements and options. Use care when making changes. Contact Documoto Services for further information.',
  storefrontSettingsOrgDescription: 'These settings control various Organization-level Storefront elements and options. Use care when making changes. Contact Documoto Services for further information.',
  storeFilterValues: 'Store Filter Values',
  storeFilterValuesDescription: 'Enabling this setting stores values set by users in their search page filters for this tag.',
  stroke: 'Stroke',
  style: 'Style | Styles',
  styles: {
    colorHotpoint: 'Hotpoint Default',
    invalidHexcode: 'Invalid hexcode',
    inheritedFromOrg: 'Inherited from Organization {org}',
    inheritedFromSystem: 'Inherited from System Default',
    inheritedFromTenant: 'Inherited from Tenant',
    organization: 'Organization Style | Organization Styles',
    organizationSaved: 'Organization Styles Saved',
    sampleNavigation: 'Navigation Tabs',
    samplePrimary: 'Navigation/Buttons',
    sampleSelectedHotpoint: 'Selected Hotpoints',
    tenant: 'Tenant Style | Tenant Styles',
    tenantSaved: 'Tenant Styles Saved'
  },
  subChapter: 'Sub Chapter | Sub Chapters',
  subject: 'Subject',
  subjectMessage: 'A Subject is required',
  submit: 'Submit',
  submitPurchaseOrder: 'Purchase Order',
  submitRequestForQuote: 'Request for Quote',
  submitted: 'Submitted',
  submitter: 'Submitter',
  submittedBy: 'Submitted by',
  submitterEmail: 'Submitter Email',
  submitterFirstName: 'Submitter First Name',
  submittedFilesMessage: 'Submitted {fileCount} file(s).',
  submitterLastName: 'Submitter Last Name',
  submitterOrgName: 'Submitter Org Name',
  submitterUserName: 'Submitter User Name',
  success: 'Success',
  imageSaveSuccess: 'Successfully Updated Images',
  successNotificationDuration: 'Add to Cart - Success Toast Notification Duration',
  suffix: 'Suffix | Suffixes',
  suggestedKeywords: 'Suggested Keywords',
  suggestedResults: 'Suggested Results',
  suggestion: 'Suggestion | Suggestions',
  sun: 'Sun',
  superadmin: 'Super Admin',
  superAdminSettings: "Super Admin Settings",
  supersede: 'Supersede',
  supersedeComplete: 'Do you want to complete the supersession?',
  supersedeDiscardChanges: 'Discard your supersession?',
  supersedeMainText: 'Supersession replaces an existing part with one or more other parts, in every Parts Page where the old part is found. The old part number is deleted but remains searchable via tags so that content can be found using the old number or the new number(s).',
  supersedeMessage: 'Part updates take effect immediately. Searching will reflect the updates within a few minutes.',
  supersedeNotProcessed: 'Your supersession will not be processed.',
  supersedeReplacePart: 'Find one or more parts that will replace part',
  supersedeSuccessful: 'Supersede Successful',
  supersedeWith: 'Supersede Part With...',
  supplier: 'Supplier | Suppliers',
  supplierKey: 'Supplier Key',
  supplierDescription: 'The company that supplies this part',
  supportedImageFormats: 'Image formats supported: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'Unsupported file; draft files supported: MDZ, PLZ',
  surroundWithQuotes: 'Surround multiple search terms with quotes for an exact match.',
  svgFinder: 'SVG Finder',
  swedishDescription: 'Swedish Description',
  swedishName: 'Swedish Name',
  system: 'System',
  systemAdmin: 'System Admin',
  systemFields: 'System Fields',
  systemFieldSearchAndFiltering: 'System Field Search & Filtering',
  systemStatus: 'System Status',
  systemName: 'System Name',
  systemVersion: 'System Version',
  tabDelimited: 'Tab-delimited',
  tableOfContents: 'Table of Contents',
  tabloid: 'Tabloid',
  tag: 'Tag | Tags',
  tagName: 'Tag Name',
  tagEditor: {
    rangeBoundLengthInvalid: 'Bound cannot have more than {limit} digits.',
    tagEditorModal: 'Tag Editor Modal',
    filterPrompt: 'Displaying first 100 values. Use filter to view more.'
  },
  tagSearchAndFiltering: 'Tag Search & Filtering',
  tagSearchAndFilteringHelpText: 'Hint: Some search and filter settings are also configurable in Tag Manager tag editors, found here: ',
  tagSubmitAffected: 'Any {type} content will automatically drop the tag {tag} in addition to the changes.',
  tagDeleteAffectedCount: 'This will delete the tag and remove the tag information from {count} items.',
  tagDeleteAffectedNone: 'No items currently use this tag.',
  tagManagerDeleteWarning: 'Any existing media with {deleteCategoryName} will lose this category after deleting.',
  tagsDisplayMore: 'Displaying first 100 values. Use filter to view more.',
  tagsManager: 'Tags Manager',
  tagValue: 'Tag Value | Tag Values',
  tagValueMissing: 'You must enter a tag value',
  tagValueMultiple: 'Semi-colon delimited for multiple entries',
  tagValueTooLong: 'Tag value exceeds the 25 character limit | Tag values exceed the 25 character limit',
  taxon: 'Taxon',
  taxonomy: 'Taxonomy',
  template: 'Template | Templates',
  templateEmailRecipients: 'Template Email Recipients',
  templateLayout: {
    addRow: 'Add Row',
    addItem: 'Add Item',
    deleteRow: 'Delete Row',
    deleteColumn: 'Delete Column',
    fullWidth: 'Full Width',
    oneColumn: 'One Column',
    threeColumns: 'Three Columns',
    twoColumns: 'Two Columns'
  },
  tenant: 'Tenant | Tenants',
  tenantAdmin: 'Tenant Admin',
  tenantAdminMessage: 'Controls tenant wide settings',
  tenantCleanerJob: 'Tenant Maintenance',
  tenantConfiguration: 'Tenant Configuration',
  tenantEmailRecipients: 'Tenant Email Recipients',
  tenantEmailRecipientsOverrideMessage: 'These will be overridden if Organization Email Recipients are specified',
  tenantIndexQueueJob: 'Tenant Index Queue',
  tenantKey: 'Tenant Key',
  tenantKeyError: 'Tenant Key is not unique',
  tenantKeyMessage: 'Tenant Key must be unique',
  tenantLabelDeleteMessage: 'Deleting this label will revert to the default Documoto name.',
  tenantReindexModulus: 'Tenant Reindex Modulus',
  tenantSettings: {
    collapseTOCLabel: 'Collapse TOC on Matching Page',
    defaultLocaleLabel: 'Default Locale',
    defaultLocaleMessage: 'The tenant default locale.',
    deleteMissingTranslationsOnPublishLabel: 'Delete Missing Translations on Publish?',
    deleteMissingTranslationsOnPublishMessage: 'Enable to Delete Part Translations on Publishing',
    defaultSupplierLabel: 'Default Supplier',
    defaultSupplierMessage: 'The tenant default supplier.',
    enableChapterIndexingMessage: 'Index Chapters',
    enableChapterIndexingLabel: 'Turns indexing on for Media Chapters',
    enableIndexingLabel: 'Index on Publishing',
    enableIndexingMessage: 'Turns indexing on for publishing.',
    helpUrlLabel: 'Default Help URL',
    helpUrlMessage: 'The URL of an alternative help system.',
    hotPointLinkPageInBookOnlyLabel: 'Page Hotpoint Links in Book Only',
    hotPointLinkPageInBookOnlyMessage: 'Hotpoint Links from Pages in Book Only',
    mediaTimestampLabel: 'Update Media Timestamp on Access Control Change',
    mediaTimestampMessage: 'Enable if a change in access controls should update the media\'s timestamp.',
    publisherDefaultUom: 'Publisher Default UOM',
    publishingContactLabel: 'Publishing Contact Email',
    publishingContactMessage: 'The default email address that will receive emails when a content issue is reported.',
    replaceHotpointLabel: 'Always Replace Hotpoint Links on Publishing',
    replaceHotpointMessage: 'Only for Docustudio publishing.',
    ssoLoginSystemLabel: 'SSO Login System',
    ssoLoginSystemMessage: 'Identity Provider system for SSO.',
    userPasswordExpirationLabel: 'User Password Expiration Days',
    userPasswordExpirationMessage: 'Number of Days after which passwords must be reset; leave blank to never expire',
    lockPartTrOnPublishLabel: 'Lock Part Translations on Publish',
    lockPartTrOnPublishMessage: 'Prevents Part Translations from being modified during Publishing',
    exportPgAsHashLabel: 'Export Page File Name as Hash Key',
    exportPgAsHashMessage: 'Use Page Hash Keys as Page File Names in Exported content'
  },
  tenantSettingsTitle: 'Tenant Settings',
  tenantSettingsSaved: 'Tenant Settings Saved',
  tenantSettingsSubtitle: 'These settings control tenant-wide properties and behaviors. Use care when making changes. Contact Documoto Services for further information.',
  tenantSuppliers: 'Tenant Suppliers',
  tenantLabelsPageText: 'Labels are used to customize the names of many objects found throughout Documoto\'s Library and Administration screens.',
  tenantPrintSettingsDescription: 'Choose the tenant-wide default print setting. This will be used for all book printing unless overridden at the Organization or Media.',
  tenantType: 'Tenant Type',
  tenantWebserviceUser: 'Tenant Webservice User',
  text: 'Text',
  textArea: 'Text Area',
  textField: 'Text Field',
  textFile: 'Text File (CSV, TXT)',
  textTool: 'Text Tool',
  threeD: {
    addFile: 'Add a 3D Part File',
    beforeSaveModal: {
      title: 'A CAD file already exists within Documoto with this filename.',
      message: 'Would you like to replace this file everywhere it exists, or attach it to only this part? If you choose to replace the file, the following will occur:',
      messageBulletOne: '{count} part(s) will be updated with this new CAD file',
      messageBulletTwo: 'The prior version of this file will be removed from the Documoto file system',
      tip: 'Tip: To prevent any file overwrites during the attachment process, you can change the filename of the file you are trying to attach before importing it.'
    },
    deleteTitle: 'Are you sure you want to delete this 3D view?',
    drawMode: {
      label: 'Draw Mode',
      hiddenLines: 'Hidden lines',
      shaded: 'Shaded',
      wireframe: 'Wireframe',
      wireframeShaded: 'Wireframe Shaded',
    },
    explode: 'Explode',
    fileSizeModal: {
      message: 'The maximum file size allowed in Documoto is 25 megabytes. The file you are attempting to upload has exceeded the maximum and cannot be loaded. Click "OK" to continue.',
      title: 'Maximum File Size Exceeded',
    },
    orientationAndProjection: {
      back: 'Back',
      bottom: 'Bottom',
      front: 'Front',
      isometric: 'Isometric',
      left: 'Left',
      orthographic: 'Orthographic',
      projection: 'Projection',
      perspective: 'Perspective',
      right: 'Right',
      top: 'Top'
    },
    resetCamera: 'Reset Camera',
    slice: {
      label: 'Slice',
      planeX: 'Cutting Plane X',
      planeY: 'Cutting Plane Y',
      planeZ: 'Cutting Plane Z',
      reset: 'Cutting Plane Reset',
      section: 'Cutting Plane Section Toggle',
      visibility: 'Cutting Plane Visibility Toggle',
    },
    supportedFilesMessage: 'Documoto supports many CAD file formats. Check the Support Center if you are unsure if your CAD file format is supported.',
    toolsLabel: '3D Tools',
    topAssemblyFilename: 'Top Assembly Filename',
    topAssemblyFilenameRequired: 'Top Assembly Filename is required',
    viewerTitle: '3D Viewer',
  },
  thu: 'Thu',
  thumbnail: 'Thumbnail',
  thumbnailDeleted: 'Thumbnail Deleted Successfully',
  thumbnailDeletedError: 'Thumbnail Delete Failed',
  thumbnailUpdated: 'Thumbnail Updated Successfully',
  thumbnailUpdatedError: 'Thumbnail Update Failed',
  thumbnailReapplyWarning: 'The system may re-apply a thumbnail based on the contents of the media. This may take a few minutes.',
  thumbnailResizeTooltip: 'Resizes thumbnails to no larger than 500x500 pixels, if checked',
  title: 'Title',
  to: 'To',
  toastNotifications: 'Toast Notifications',
  toDate: 'To Date',
  toc: 'Toc',
  tocClipBoardSuccessTitle: 'Copied to Clipboard',
  tocClipBoardWarningText: 'WARNING: Unsaved changes cannot be copied to your clipboard',
  tocClipBoardWarningTitle: 'Copied to Clipboard',
  tocDisplayingFirstValues: 'Displaying first 100 values. Use filter to view more.',
  tocDuplicateWarningText: 'Duplicate page was detected in a chapter. The duplicate has been removed.',
  tocEmptyMessage: 'Looks like you haven\'t created a Table of Contents yet.',
  toGetStarted: 'to get started',
  tocMissingChapterName: 'Missing Chapter Name',
  tocModalTableCaption: 'Tag Editor Modal',
  tocNothingSelected: 'Nothing Selected',
  tocOpenEditor: 'Open Editor',
  tocSaveRequired: 'Save required before editing chapter details',
  tocState: 'Initial State of Table of Contents',
  tocThumbnailDeleteFailTitle: 'Thumbnail Delete Failed',
  tocThumbnailDeleteSuccessTitle: 'Thumbnail Delete Successful',
  tocThumbnailUploadFailTitle: 'Thumbnail Upload Failed',
  tocThumbnailUploadSuccessTitle: 'Thumbnail Upload Successful',
  tocTranslationMessage: 'The name and optional description of this chapter',
  tocUntitledPage: 'Untitled Page',
  tooManySelections: 'List exceeds {limit} selection limit',
  togglePage: 'Toggle Page',
  top: 'Top',
  total: 'Total',
  totalItems: 'Total Items',
  translation: 'Translation | Translations',
  translationHelpTextItem: 'The name and optional description of this {item}',
  true: 'True',
  tryAgain: 'Please try again.',
  tue: 'Tue',
  turkishDescription: 'Turkish Description',
  turkishName: 'Turkish Name',
  twoSided: '2-sided',
  type: 'Type | Types',
  typeAtLeastTwoChars: 'Type at least 2 characters to see a list of matches.',
  typeToConfirm: 'Type {value} to confirm',
  typography: 'Typography',
  ukraineDescription: 'Ukraine Description',
  ukraineName: 'Ukraine Name',
  unableToRetrieveResults: 'Unable to retrieve results for your query/search',
  unauthorized: 'Unauthorized',
  unknownError: 'Unknown error, please contact Documoto support at support{\'@\'}documoto.com for assistance',
  unknownPart: 'Unknown Part',
  unknownType: 'Unknown Type',
  unsavedAPIKeyWarning: 'Your API Key will be lost if you do not Return',
  unsavedChanges: 'You have unsaved changes. Continue?',
  unsavedChangesWarning: 'Your changes will be lost if you do not save them.',
  unsavedWidgetKeyWarning: 'Your Widget Key will be lost if you do not return.',
  unsupportedBrowserMessage: 'We built this application with the latest technology. This makes the application faster and easier to use. Unfortunately, your browser does not support those technologies.',
  uom: 'UOM',
  update: 'Update',
  updateFailed: 'Update failed',
  updateIndex: 'Update Index',
  updatePassword: 'Update Password',
  updatePasswordMessage: 'Successfully updated password',
  updatePasswordPending: 'Password change pending save.',
  updated: 'Updated',
  upgradeBrowser: 'Please upgrade your browser.',
  upload: 'Upload',
  uploadComplete: 'Upload Complete',
  uploadInProgress: 'Upload in Progress',
  uponSavingDisabledTenant: 'Upon saving this page with this setting enabled, the following will occur:',
  uponSavingThisLicense: 'Upon saving this page with this setting, you MUST DO THE FOLLOWING:',
  upperBound: 'Upper Bound',
  untitledOrg: 'Untitled Organization',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Use Default Cart Columns',
  useDefaultPartsListColumns: 'Use Default Parts List Columns',
  user: 'User',
  userAddress: 'User Addresses | User Addresses',
  userCount: 'User Count',
  userEditable: 'User Editable',
  userExpirationDate: 'User Expiration Date',
  userExpirationDateInvalid: 'User Expiration Date Must Be Entered',
  userGroup: 'User Group | User Groups',
  userGroupEmpty: 'User Groups cannot be empty',
  userGroupType: 'User Group Type',
  userGroupUniqueMessage: 'This name must be unique from all other User Group names for this tenant',
  userGroupUsers: 'User Group Users',
  username: 'Username',
  usernameEmpty: 'Username cannot be empty',
  users: 'Users',
  usersWillReceiveAnUnauthorizedError: 'Users will receive an "Unauthorized" error when attempting to login to the tenant.',
  useSniForSsoMetadataRetrieval: 'Use SNI For SSO Metadata Retrieval',
  value: 'Value',
  validate: 'Validate',
  validateTenantDirectories: {
    buttonLabel: 'Validate Content Directories',
    error: 'Failed to validate directories',
    success: 'Validated tenant directories'
  },
  variantSKU: 'Variant SKU',
  validationMessage: 'Validation Message | Validation Messages',
  valueGteError: 'Value must be less than or equal to {max}',
  valueLteError: 'Value must be greater than or equal to {min}',
  vendorName: 'Vendor Name',
  verifyPassword: 'Verify Password',
  vertical: 'Vertical',
  version: 'Version',
  video: 'Video | Videos',
  view: 'View',
  viewAll: 'View All',
  viewAllPagesAndBooks: 'View: All Pages and Books',
  viewAllResults: 'View All Results',
  viewBooks: 'View: Books',
  viewMore: 'View More',
  viewPages: 'View: Pages',
  viewModeList: 'List View',
  viewModeTable: 'Table View',
  visibility: 'Visibility',
  visibilityDescription: 'Who can view this {entity}',
  visible: 'Visible',
  waitForUpload: 'Please wait for upload to complete.',
  watermark: 'Watermark',
  watermarkFont: 'Watermark Font',
  watermarkOpacityPercentage: 'Watermark Opacity %',
  watermarkOrientation: 'Watermark Orientation',
  watermarkSaved: 'Watermark Saved',
  watermarkSaveFailed: 'Failed to save watermark.',
  watermarkSelectionRequired: 'Username or Text selection required.',
  watermarkText: 'Watermark Text',
  wed: 'Wed',
  whereUsed: 'Where Used',
  whoCanViewComment: 'Who can view this comment?',
  wholesale: 'Wholesale',
  wholesalePrice: 'Wholesale Price',
  wholesaleTotal: 'Wholesale Total',
  widget: 'Widget',
  widgetAuthHeader: 'Widget Authorization Value',
  widgetKey: 'Widget Key | Widget Keys',
  widgetKeyAdmin: 'Widget Key Admin',
  widgetKeyCopied: 'Widget Authorization value copied to Clipboard',
  widgetKeyDeleted: 'Widget Key Deleted',
  widgetKeyDeletedError: 'Error deleting Widget Key',
  widgetKeyDeleteMsg: 'Deleting Widget Key will disable its use',
  widgetKeyManager: 'Widget Key Manager',
  widgetKeyNameEmpty: 'Name must be unique and is required. This value will also be used for reporting and logging purposes as a username for any Widget activity',
  widgetKeyRegenError: 'Error regenerating Widget Key',
  widgetKeyRegenMsg: 'Regenerating this Widget Key may disable existing integrations',
  widgetKeyRegenTitle: 'Are you sure you want to regenerate this Widget Key?',
  widgetKeySaved: 'Widget Key Saved',
  widgetPermissions: 'Widget users are read-only, and feature access is managed by Widget-level configuration. Standard user group permissions do not apply to Widget users. Media Category access privileges do. Please proceed to assigning media category access controls.',
  widgetRefreshErrorMsg: "Please refresh your page to view the requested content",
  widgetUserGroup: 'Widget User Group',
  widgetUserGroupDeleteMsg: 'Cannot delete Widget group with users',
  willBeAffected: 'will be affected.',
  willBeDeleted: '{content} will be deleted.',
  willBeInsertedIntoItem: '{name} will be inserted into {item}',
  willBeUpdated: 'will be updated.',
  zipCode: 'Zip Code',
  zoomHotpointToCanvas: 'Zoom Hotpoint to Canvas size',
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out'
}
/* eslint-enable max-len */
