<template>
  <div>
    <div v-if="detailsLayoutTemplate && content && content.id"
         class="details-wrapper">
        <div v-if="noRightColumnAccess"
             class="single-column">
          <column-layout :content="content"
                         :column-info="detailsLayoutTemplate.leftColumn">
          </column-layout>
        </div>
        <div v-else
             class="double-column">
          <template v-for="(columnInfo, colName) in detailsLayoutTemplate"
                    :key="colName">
            <column-layout :content="content"
                           :column-info="columnInfo"></column-layout>
          </template>
        </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ColumnLayout from "@/components/library/content/details/ColumnLayout.vue"
import { computed, watch, onMounted, toRaw } from "vue"
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { EntityType } from "@/interfaces/global"

const store = useStore()
const route = useRoute()

const content = computed(() => store.state['content'])
const isPart = computed(() => content.value.type === EntityType.part)
const detailsLayoutTemplate =  computed(() => store.getters['detailPageLayouts/getDetailPageLayoutState'])

const canViewComments =  computed(() => store.getters['user/canViewComments'])
const canViewRelated = computed(() => store.getters['user/canViewRelated'])
const canViewSuggestions = computed(() => {
  return store.getters['user/hasPartOrderSuggestions'] && isPart.value
})

const noRightColumnAccess = computed(() => {
  return !canViewRelated.value && !canViewSuggestions.value && !canViewComments.value
})

async function fetchLayoutTemplate () {
  if(!content.value.type) return
  await store.dispatch('detailPageLayouts/fetchDetailPageLayout',
    {type: content.value.type, id: content.value.id})
}

function scrollToContent (): void {
  if(route.fullPath.includes('related')) {
    document.getElementById("Related")?.scrollIntoView()
  }
  if(route.fullPath.includes('comments')) {
    document.getElementById("Comments")?.scrollIntoView()
  }
  if(route.fullPath.includes('infoWrapper')) {
    document.getElementById("InfoWrapper")?.scrollIntoView()
  }
}
// using a watcher to detect when content is being evaluated as the content object is not always available on mount event
// setting a watcher on content or its properties never fires, but on a dependent computed property, it does
watch(isPart, async val => {
  if(detailsLayoutTemplate.value) return
  await fetchLayoutTemplate()
  await scrollToContent()
})
// @ts-ignore
onMounted(async () => {
  await fetchLayoutTemplate()
  await scrollToContent()
})

</script>

<style scoped lang="scss">
.details-wrapper {
  display: flex;
  flex-direction: row;

  .single-column, .double-column {
    width: 100%;
  }
}
</style>
