<template>
  <div class="sort-toggle has-text-grey-light"
       :class="{'large-icon': iconLarge}"
       @click.stop="$emit('click')">
    <div :class="ascClasses" />
    <div :class="descClasses" />
  </div>
</template>

<script>
export default {
  props: {
    invert: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    iconLarge: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    baseClasses() {
      return {
        'sort-arrow': true,
        'fas': true
      };
    },
    ascClasses() {
      return {
        ...this.baseClasses,
        'fa-sort-up': true,
        'has-text-grey-dark': !this.invert && this.active
      };
    },
    descClasses() {
      return {
        ...this.baseClasses,
        'fa-sort-down': true,
        'has-text-grey-dark': this.invert && this.active
      };
    }
  }
};
</script>

<style scoped lang="scss">
.sort-toggle {
  cursor: pointer;
  display: inline-block;
  height: 1rem;
  position: relative;
}

.sort-arrow {
  position: absolute;
  top: 0.25rem;
}
.large-icon {
  bottom: .25rem;
  .fa-sort-up, .fa-sort-down {
    font-size: 1.25em;
    line-height: 1.25em;
    bottom: .25rem;
  }
}
</style>
