import {MetadataTag} from "@/interfaces/admin/metadataTag";


export interface OrderTemplate {
  id: number,
  name: string,
  type: string,
  default?: boolean,
  orgCount: number,
  orderCount: number,
  created: Date,
  updated: Date,

  // Pre-computations & metadata
  deleteDisabled: boolean,
  tags: MetadataTag[]
}

export enum TableFields {
    name = 'name',
    type = 'type',
    assignedOrgs = 'assignedOrgs',
    created = 'created',
    updated = 'updated'
}

export interface OrderForm {
    assignedOrgs: number,
    created: string,
    name: string,
    type: string,
    updated: string,
    id: number
}
