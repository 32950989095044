<template>
  <section v-if="list && list.length" class="section">
    <h4 data-int="shelf-list-title" class="title is-4">
      <router-link :to="{name: 'Favorites'}">
        {{ $t('favoritesLists') }}
      </router-link>
    </h4>
    <div class="columns is-mobile">
      <div v-for="item in listHead"
           :key="item.id"
           class="column is-2-desktop is-3-tablet is-4-mobile">
        <div class="card" @click="openShelf(item)">
          <div class="card-image">
            <i class="far fa-folder" />
            <i class="fas fa-star" />
          </div>
          <div class="card-content">
            <div class="content">
              <p data-int="card-name"
                 class="has-text-centered truncate is-marginless"
                 data-toggle="tooltip"
                 :title="item.name">
                <router-link :to="getShelfRoute(item)" @click.stop>
                  <strong>{{ item.name }}</strong>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Thumbnail from '@/components/common/Thumbnail';
import { mapActions } from "vuex";

export default {
  components: {
    Thumbnail
  },
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showMore: false
    };
  },
  computed: {
    listHead() {
      return this.list.slice(0, 6);
    },
    moreLinkClasses() {
      return {
        'is-pulled-right': true,
        'is-hidden-desktop': this.list.length <= (12 / 2),
        'is-hidden-tablet': this.list.length <= (12 / 3),
        'is-hidden-mobile': this.list.length <= (12 / 4)
      };
    }
  },
  methods: {
    ...mapActions({
      resetBrowseState: 'browse/resetBrowseState'
    }),
    getShelfRoute(item) {
      return {
        name: 'FavoritesItem',
        params: {
          id: item.id
        }
      };
    },
    async openShelf(item) {
      await this.resetBrowseState()
      await this.$router.push(this.getShelfRoute(item));
    },
    showMoreClicked() {
      this.showMore = !this.showMore;
    }
  }
};
</script>

<style scoped>
.section {
  padding: .5rem 1rem 1rem 1rem;
  height: auto;
  overflow: hidden;
  border-bottom: lightgray 1px solid;
}
.is-pulled-right {
  align-items: right;
}

.card {
  height: 100%;
  max-width: 200px;
  cursor: pointer;
  overflow: hidden;
}
.card:not(:hover) {
  border: 1px solid transparent;
}
.card-content {
  padding: 0.5rem;
  line-height: normal;
}
.card-image {
  height: 160px;
  width: 198px;
  padding: 1rem;
  position: relative;
  overflow: visible;
}
.card-image > .fa-star {
  font-size: 40px;
  position: absolute;
  bottom: 10px;
  right: 25px;
}
.card-image > .fa-folder {
  color: lightgray;
  font-size: 160px;
  width: 100%;
  height: 160px;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  min-height: 2rem;
}
</style>
