<template>
  <th data-int="cart-header-eta">
    {{ $t('eta') }}
  </th>
</template>

<script>
export default {
  name: 'CartHeaderEta'
};
</script>

<style scoped>

</style>
