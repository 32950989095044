import { WidgetEvent } from '@/classes/WidgetEvent'
import { WidgetEvents, 
  WidgetEventSuccessMessages,
  WidgetEventErrors, WidgetTypes } from '@/interfaces/global'
import { useStore } from 'vuex'

export const FALLBACK_MSG = 'Failed to Initialize Documoto Widget'

export function useWidgetEvents() {
  const store = useStore()

  function emitLoginEvent() {
    window.parent.postMessage(
      new WidgetEvent(
        { action: WidgetEvents.login,
          message: WidgetEventSuccessMessages.login }), 
      store.getters['widgets/targetUri'])
  }

  function emitErrorEvent({ 
    error = WidgetEventErrors.init, 
    origin = store.getters['widgets/targetUri'],
    type = store.getters['widgets/widgetType'] as WidgetTypes }: { 
      error?: any, 
      origin?: string,
      type?: WidgetTypes }) {

    const WidgetEventErrMsgMap = new Map([
      [WidgetEventErrors.config, 'Widget could not initialize due to a bad widget configuration'],
      [WidgetEventErrors.init, FALLBACK_MSG],
      [WidgetEventErrors.location, 'Failed to locate the specified content within the widget']
    ])

    const errMessage = WidgetEventErrMsgMap.get(error)
      ?? FALLBACK_MSG

    window.parent.postMessage(
      new WidgetEvent({ 
        action: WidgetEvents.error,
        message: errMessage,
        widgetType: type }), 
      origin)
  }

  return { emitLoginEvent, emitErrorEvent }
}