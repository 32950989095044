import http from '@/http'

function followPath (parent, pathEl) {
  return parent.items.find((cc) => cc.id === pathEl)
}

function injectEmptyItems (org) {
  return {
    ...org,
    items: []
  }
}

export default {
  namespaced: true,
  state: {
    items: [],
    expanded: {},
    rootItemCount: 0,
    selectedOrg: null
  },
  actions: {
    async loadMore ({ commit, state }, path) {
      try {
        if (path.length === 0) {
          const params = {
            limit: 100,
            offset: state.items.length
          }
          const { data } = await http.get('org-admin', { params })
          commit('receivedOrganizationTreeElements', { path, orgs: data })
        } else {
          const parent = path.reduce(followPath, { items: state.items })
          const params = {
            limit: 100,
            offset: parent.items.length
          }
          const url = `org-admin/${parent.id}/children`
          const { data } = await http.get(url, { params })
          commit('receivedOrganizationTreeElements', { path, orgs: data })
        }
      } catch (err) {
        console.error(err)
      }
    },
    setSingleSelection ({ commit }, id) {
      commit('setOrgTreeSelection', { id })
    },
    setExpansionState ({ commit }, { id, expanded }) {
      commit('setExpansionState', { id, expanded })
    }
  },
  mutations: {
    receivedOrganizationTreeElements (state, { path, orgs }) {
      const isRoot = path.length === 0
      const parent = isRoot ? state : path.reduce(followPath, { items: state.items })
      const parentChildren = parent.items
      const newChildren = [...parentChildren, ...orgs.map(injectEmptyItems)]

      if (isRoot) {
        state.items = newChildren
      } else {
        // Vue.set(parent, 'items', newChildren) -- deprecated
        parent.items = newChildren
      }
    },
    setOrgTreeSelection (state, { id }) {
      state.selectedOrg = id
    },
    setExpansionState (state, { id, expanded }) {
      // Vue.set(state.expanded, id, expanded)  -- deprecated
      state.expanded[id] = expanded
    }
  }
}
