<template>
  <td
    class="actions-cell"
    @dblclick.stop="open"
  >
    <a
      v-if="bomItem.isOrderable"
      data-unit="bom-add-to-cart-button"
      @click="addToCart"
      :class="{'is-disabled-cart': !isWidgetCartEnabled && !isCartLoaded}"
    >
      <badged-icon
        badge="file"
        :class="{'has-text-info': bomItem.highlight, 'has-text-danger': bomItem.isInCart}"
        icon="cart-plus"
        :show-badge="bomItem.isExactInCart"
      />
    </a>
    <bom-add-to-cart-modal
      v-if="addToCartModalOpen"
      :bom-item="bomItem"
      @addToCart="addToCartModal"
      @cancel="addToCartModalOpen = false"
    />
  </td>
</template>

<script>
import BomAddToCartModal from '../BomAddToCartModal'
import BadgedIcon from '@/components/common/BadgedIcon.vue'
import { mapState } from 'vuex';

export default {
  name: 'BomCellActions',
  persistentData: {
    showNewAdmin: {
      default: false
    }
  },
  props: {
    bomItem: {
      type: Object,
      required: true
    },
    hasCartPromptForQtyEnabled: Boolean,
    isWidgetCartEnabled: Boolean
  },
  components: {
    BadgedIcon,
    BomAddToCartModal
  },
  data () {
    return {
      addToCartModalOpen: false
    }
  },
  computed: {
    ...mapState({
      isCartLoaded: ({cart}) => cart.isLoaded
    })
  },
  methods: {
    open () {
      this.$emit('open', {
        content: this.bomItem,
        route: ''
      })
    },
    addToCart () {
      const event = this.isWidgetCartEnabled 
        ? 'widgetAddToCart'
        : 'addToCart'
        if (this.isWidgetCartEnabled || this.isCartLoaded) {
          if (this.hasCartPromptForQtyEnabled) {
            this.addToCartModalOpen = true
          } else {   
            this.$emit(event)
          }
      }
    },
    addToCartModal (quantity) {
      const event = this.isWidgetCartEnabled 
        ? 'widgetAddToCart'
        : 'addToCart'
      this.addToCartModalOpen = false
      this.$emit(event, quantity)
    }
  }
}
</script>

<style>
.actions-cell {
  text-align: center;
}
.table tr.is-selected span {
  color: currentColor !important;
}
.is-disabled-cart {
  color: lightgray;
  :hover {
    color: lightgray;
  }
}
</style>
