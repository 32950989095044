<template>
  <td data-int="cart-cell-page">
    {{ item.pageName }}
  </td>
</template>

<script>
export default {
  name: 'CartCellPage',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>

</style>
