import { ref, Ref, onMounted, computed, readonly } from 'vue'
import { useStore } from 'vuex'
import { fetchImageObjectUrl } from '@/helpers'

export function useFetchImage(url: string, htmlRef: any) {
  const store = useStore()
  const isWidget = computed(() => {
    return store.getters['widgets/isWidget']
  })
  const imgSrc = ref('')
  const error: Ref<any> = ref(null)

  async function fetchImage() {
    const { data, error } = await fetchImageObjectUrl(url)
    if (!!data) {
      imgSrc.value = data
    } else if (!!error) {
      error.value = error
    }
  }

  function setImage(ref: any) {
    if (!!ref?.value) {
      ref.value.src = imgSrc.value
    }
  }

  onMounted(async() => {
    await fetchImage()
    setImage(htmlRef)
  })

  return { 
    imgSrc, 
    error: readonly(error), 
    isWidget,
    setImage }
}