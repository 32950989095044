<template>
  <documoto-logo v-if="hasEnabledWidgetLogo"/>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import DocumotoLogo from './DocumotoLogo.vue'

export default {
  name: "WidgetDocumotoLogo",
  components: { DocumotoLogo },
  watch: {
    tenantKey: {
      immediate: true,
      async handler(val) {
        if (!!val && !this.hasTenantProperties) {
          await this.loadTenantProperties()
        }
      }
    }
  },
  computed: {
    ...mapState({
      tenantKey: ({ user }) => user.tenantKey
    }),
    ...mapGetters({
      hasEnabledWidgetLogo: 'user/hasEnabledWidgetLogo',
      hasTenantProperties: 'user/hasTenantProperties'
    })
  },
  methods: {
    ...mapActions({
      loadTenantProperties: 'user/loadTenantProperties'
    })
  }
}
</script>