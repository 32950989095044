interface Buttons {
  hasSetAsDefault: boolean,
  hasCopy: boolean,
  hasDelete: boolean,
  hasDetails: boolean,
  canDisable: boolean
}

export class VirtualListButtons {
  hasSetAsDefault: boolean
  hasCopy: boolean
  hasDelete: boolean
  hasDetails: boolean
  canDisable: boolean
  constructor({ hasSetAsDefault, hasCopy, hasDelete, hasDetails, canDisable }: Buttons) {
      this.hasSetAsDefault = hasSetAsDefault,
      this.hasCopy = hasCopy,
      this.hasDelete = hasDelete,
      this.hasDetails = hasDetails
      this.canDisable = canDisable
  }
}

interface FilterBar {
  tagsMatching: {indexed: Function, applicableTypes: String}
  disableAdd: boolean | null
  hasAddBtn: boolean | null
  hasPartCode: boolean | null
  hasSupplier: boolean | null
  hasCreated: boolean | null
  hasUpdated: boolean | null
  hasMediaType: { [key:string]: Boolean } | null,
  hasSearchTerm: boolean | null
  newMediaRedirect: Object | null
  hasDeleteBtn: boolean | null
  selectedDropdown: string | null
  dropdownOptions: string[] | null
}

export class VirtualListFilters {
  tagsMatching: Object
  disableAdd: boolean | null
  hasAddBtn: boolean | null
  hasPartCode: boolean | null
  hasSupplier: boolean | null
  hasCreated: boolean | null
  hasUpdated: boolean | null
  hasMediaType: { [key:string]: Boolean } | null
  hasSearchTerm: boolean | null
  newMediaRedirect: Object | null
  hasDeleteBtn: boolean | null
  selectedDropdown: string | null
  dropdownOptions: string[] | null
  constructor ({ hasDeleteBtn, tagsMatching, disableAdd, hasAddBtn, hasPartCode, hasSupplier, hasCreated, hasUpdated, hasMediaType, hasSearchTerm, newMediaRedirect, selectedDropdown, dropdownOptions }: FilterBar) {
    this.tagsMatching = tagsMatching || null
    this.disableAdd = disableAdd || null
    this.hasAddBtn = hasAddBtn || null
    this.hasPartCode = hasPartCode || null
    this.hasSupplier = hasSupplier || null
    this.hasCreated = hasCreated || null
    this.hasUpdated = hasUpdated || null
    this.hasMediaType = hasMediaType || null
    this.hasSearchTerm = hasSearchTerm ||  null
    this.newMediaRedirect = newMediaRedirect || null
    this.hasDeleteBtn = hasDeleteBtn || null
    this.selectedDropdown = selectedDropdown || null
    this.dropdownOptions = dropdownOptions ? Array.from(dropdownOptions) : null
  }
}
