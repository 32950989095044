<template>
  <div class="calendar">
    <div class="calendar-nav">
      <div class="calendar-nav-left">
        <button class="button is-text previous-month" @click="navigateToPrevMonth">
          <i class="fa fa-chevron-left"/>
        </button>
      </div>
      <div>{{ activeMonthName }} {{ activeYear }}</div>
      <div class="calendar-nav-right">
        <button class="button is-text next-month" @click="navigateToNextMonth">
          <i class="fa fa-chevron-right"/>
        </button>
      </div>
    </div>
    <div class="calendar-container">
      <div class="calendar-header">
        <div v-for="name in dayNames" :key="name" class="calendar-date">
          {{ name }}
        </div>
      </div>
      <div class="calendar-body">
        <calendar-day v-for="day in prevMonthWeekDays"
                      :key="'' + prevMonth + day"
                      :date="new Date(prevMonthDate.getFullYear(), prevMonth, day)"
                      :selected-date="selectedDate"
                      :disabled="true"
                      @selectDate="selectDate"/>
        <calendar-day v-for="day in daysInActiveMonth"
                      :key="'' + activeMonth + day"
                      :date="new Date(activeYear, activeMonth, day)"
                      :selected-date="selectedDate"
                      @selectDate="selectDate"/>
        <calendar-day v-for="day in nextMonthWeekDays"
                      :key="'' + nextMonth + day"
                      :date="new Date(nextMonthDate.getFullYear(), nextMonth, day)"
                      :selected-date="selectedDate"
                      :disabled="true"
                      @selectDate="selectDate"/>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarDay from './CalendarDay';

export default {
  name: 'Calendar',
  components: {
    CalendarDay
  },
  computed: {
    activeMonth() {
      return this.activeDate.getMonth();
    },
    activeMonthName() {
      return this.monthNames[this.activeMonth];
    },
    activeYear() {
      return this.activeDate.getFullYear();
    },
    daysInActiveMonth() {
      return this.monthDays[this.activeMonth];
    },
    firstDayInActiveMonth() {
      return new Date(this.activeYear, this.activeMonth).getDay();
    },
    lastDayInActiveMonth() {
      return new Date(this.activeYear, this.activeMonth, this.daysInActiveMonth).getDay();
    },
    prevMonthDate() {
      const activeDateClone = new Date(this.activeDate.valueOf());
      return new Date(activeDateClone.setMonth(this.activeMonth - 1));
    },
    nextMonthDate() {
      const activeDateClone = new Date(this.activeDate.valueOf());
      return new Date(activeDateClone.setMonth(this.activeMonth + 1));
    },
    prevMonth() {
      return this.prevMonthDate.getMonth();
    },
    nextMonth() {
      return this.nextMonthDate.getMonth();
    },
    daysInPrevMonth() {
      return this.monthDays[this.prevMonth];
    },
    prevMonthWeekDays() {
      const week = [];
      for (let i = 0; i < this.firstDayInActiveMonth; i += 1) {
        week.push(this.daysInPrevMonth - i);
      }
      return week;
    },
    nextMonthWeekDays() {
      const week = [];
      for (let i = 0; i < 6 - this.lastDayInActiveMonth; i += 1) {
        week.push(i + 1);
      }
      return week;
    }
  },
  data() {
    return {
      activeDate: new Date(),
      selectedDate: new Date(),
      monthNames: [
        this.$t('january'),
        this.$t('february'),
        this.$t('march'),
        this.$t('april'),
        this.$t('may'),
        this.$t('june'),
        this.$t('july'),
        this.$t('august'),
        this.$t('september'),
        this.$t('october'),
        this.$t('november'),
        this.$t('december')
      ],
      dayNames: [
        this.$t('sun'),
        this.$t('mon'),
        this.$t('tue'),
        this.$t('wed'),
        this.$t('thu'),
        this.$t('fri'),
        this.$t('sat')
      ],
      monthDays: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    };
  },
  methods: {
    navigateToPrevMonth() {
      this.activeDate = this.prevMonthDate;
    },
    navigateToNextMonth() {
      this.activeDate = this.nextMonthDate;
    },
    selectDate(day) {
      this.selectedDate = new Date(this.activeYear, this.activeMonth, day);
      this.$emit('selectDate', this.selectedDate);
    }
  }
};
</script>

<style scoped>
.calendar {
  border: solid 1px hsl(0, 0%, 96%);
  min-width: 15rem;
  max-width: 15rem;
}
</style>
