import http from '@/http'
import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"
import router from '@/router'
import qs from 'qs'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { checkSubDomain } from "@/helpers"
import user from "@/store/modules/user/user"
import { nextTick } from "vue"
import { widgetLoginLog, refreshWidgetTokens } from '@/controllers/admin'
import { store } from '@/store'
import { getRoute } from "@/router/getRoute"
import { clearCookieAcceptance } from "@/checks"
import { isValidWidgetConfig } from './widgetHelpers'
import setUpXmlInterceptors from '@/http/xmlHttp'
import registerFetchInterceptor from '@/http/fetchInterceptor'
import { isValidHex } from '@/helpers'
import { useWidgetEvents } from '@/components/widgets/composables/useWidgetEvents'
import { postError } from '@/helpers/notification'

const AUTHENTICATING = 'AUTHENTICATING'
const AUTHENTICATED = 'AUTHENTICATED'
const SET_IS_WIDGET = 'SET_IS_WIDGET'
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
const SET_IS_REFRESH_TOKEN_VALID = 'SET_IS_REFRESH_TOKEN_VALID'
const SET_ACCESS_TOKEN_EXPIRATION = 'SET_ACCESS_TOKEN_EXPIRATION'
const SET_IS_REFRESHING_TOKEN = 'SET_IS_REFRESHING_TOKEN'
const SET_HOSTED_DOMAIN = 'SET_HOSTED_DOMAIN'

const state = {
  authenticated: false,
  authenticating: false,
  authenticatingError: '',
  isWidget: false,
  accessToken: '',
  refreshToken: '',
  accessTokenExpiration: null,
  isRefreshTokenValid: true,
  isRefreshingToken: false,
  hostedDomain: null
}

const getters = {
  isAuthenticated: (state) => state.authenticated,
  hasAccessTokenExpired(state) {
    return function() {
      const now = Date.parse(new Date())
      const expiration = Date.parse(state.accessTokenExpiration)
      return now > expiration
    }
  }
}

const actions = {
  async login ({ commit, dispatch, rootState }, { username, password, locale, remember, tenantKey }) {
    const route = getRoute()
    const { query } = route
    try {
      clearCookieAcceptance()
      commit(AUTHENTICATING, { authenticating: true })

      const formData = {}
      Object.keys(query).forEach((key) => {
        formData[key] = query[key]
      })

      if (typeof username !== 'undefined') {
        formData.username = username
      }
      if (typeof password !== 'undefined') {
        formData.password = password
      }
      if (locale) {
        formData.locale = locale
      }
      const uuid = uuidv4()
      formData.csrfToken = uuid
      if(tenantKey) {
        formData.tenantKey = tenantKey
      }

      /**
       * Cookies may persist in future transactions despite being expired. This endpoint creates a window
       * for any lingering cookie to expire prior to /login.
       *
       * This endpoint is necessary for the phaseout of un-partitioned third-party cookies driven by Chrome.
       */
      await http.get('auth/before-login-partitioned')
      await http.get('auth/before-login-unpartitioned')
      await http.post(
        'auth/login',
        qs.stringify(formData, { indices: false })
      )
      await dispatch('setLocalStorage', {
        key: 'credentials',
        value: {
          username: remember ? username : '',
          remember,
          locale,
          tk: query.tk || '',
          ok: query.ok || ''
        }
      }, { root: true })

      await dispatch('setLocalStorage', { key: 'csrfToken', value: uuid }, { root: true })
      if (!rootState.app.analyticsLoaded) {
        dispatch('app/initializeAnalytics', {}, { root: true })
      }
      await dispatch('theme/getTheme', {}, { root: true })
      await dispatch('user/getUser', 'login')
      await dispatch('passwordExpiryToast')
      await dispatch('redirectAfterLogin', { query })
    } catch (error) {
      const message = error && error.response ? error.response.data.message : ''
      const errorMsg =  i18n.global.t('unauthorized')
      switch (message) {
        case 'HTTP 401 Unauthorized':
          await commit(AUTHENTICATING, { authenticating: false })
          await router.replace({ path: '/expired-password', query: query})
          break
        default:
          await commit(AUTHENTICATING, {
            authenticating: false,
            error: errorMsg
          })
          break
      }
      commit(AUTHENTICATING, { authenticating: false })
    }
  },
  async loginTEK ({ commit, dispatch, rootState }) {
    try {
      const route = getRoute()
      clearCookieAcceptance()
      // Using undefined check so that we can use empty passwords for local development
      if (route.query.tek || route.query.password !== undefined) {
        commit(AUTHENTICATING, { authenticating: true })

        const formData = {}
        const { query } = route
        Object.keys(query).forEach((key) => {
          formData[key] = query[key]
        })

        if (formData.uek) {
          formData.username = formData.uek
        }
        const uuid = uuidv4()
        formData.csrfToken = uuid
        const domain = await checkSubDomain()
        if (domain) {
          formData.tenantKey = domain
        } else if (route.query.tk) {
          formData.tenantKey = route.query.tk
        }

        /**
         * Cookies may persist in future transactions despite being expired. This endpoint creates a window
         * for any lingering cookie to expire prior to /login.
         *
         * This endpoint is necessary for the phaseout of un-partitioned third-party cookies driven by Chrome.
         */
        await http.get('auth/before-login-partitioned')
        await http.get('auth/before-login-unpartitioned')
        await http.post('auth/login', qs.stringify(formData, { indices: false }))

        await dispatch('setLocalStorage', { key: 'csrfToken', value: uuid }, { root: true })
        await dispatch('theme/getTheme', {}, { root: true })
        await dispatch('user/getUser', 'login')
        await dispatch('passwordExpiryToast')
        await dispatch('redirectAfterLogin', { query })
      }
    } catch (error) {
      commit(AUTHENTICATING, {
        authenticating: false,
        error: error.response.status === 401 ? i18n.global.t('unauthorized') : error
      })
    }
  },
  async loginWidget({ dispatch, commit, state }, e) {
    commit(AUTHENTICATING, { authenticating: true })
    try {
      if (!isValidWidgetConfig(e.data)) throw 'config'

      // Set up interceptors for fetch API & XML
      registerFetchInterceptor()
      setUpXmlInterceptors()

      // Update theme here via direct DOM manipulation
      if (isValidHex(e.data.theme?.brand_primary ?? '')) {
        await dispatch("theme/checkThemeAndUpdate", e.data.theme, { root:true })
      }
      const config = {
        ...e.data,
        targetUri: e.origin
      }
      const uuid = uuidv4()

      commit(SET_IS_WIDGET, true)
      commit(SET_ACCESS_TOKEN, config.accessToken)
      commit(SET_REFRESH_TOKEN, config.refreshToken)
      commit(SET_IS_REFRESH_TOKEN_VALID, true)
      commit(SET_HOSTED_DOMAIN, config.hostedDomain)

      //@ts-ignore: set Auth Headers for OpenSeadragon image loader
      Object.defineProperties(OpenSeadragon.Tile.prototype, {
        loadWithAjax: {
          value: true
        },
        ajaxHeaders: {
          writable: true,
          value: {
            'Authorization': `Bearer ${state.accessToken}`,
            'Hosted-Domain': config.hostedDomain
          }
        },
      })
      // Get user & set authenticated to true
      await dispatch('user/getUser', { root: true })
      await dispatch('setLocalStorage', { key: 'csrfToken', value: uuid }, { root: true })

      // Log config, save it in widgets module, & refresh to get expiration timestamp
      await widgetLoginLog(config.accessToken, JSON.stringify(e.data))
      await dispatch("widgets/initialize", config, { root: true })

      // Refresh token periodically to maintain session
      // Interceptor approach on getting 401s didn't work w/XML requests
      // & Library Widget
      setInterval(async() => {
        await dispatch('refreshAccessToken')
      }, 1000 * 60 * 10)
    } catch(error) {
      const { emitErrorEvent } = useWidgetEvents()
      emitErrorEvent({ error, origin: e.origin,
        type: e.data.widgetType })
    } finally {
      commit(AUTHENTICATING, { authenticating: false })
    }
  },
  async refreshAccessToken({ commit, state, rootState, dispatch }) {
    if (state.isRefreshingToken) return
    try {
      commit(SET_IS_REFRESHING_TOKEN, true)
      const { accessToken, refreshToken, hostedDomain } = state
      const res = await refreshWidgetTokens(refreshToken, accessToken)
      commit(SET_ACCESS_TOKEN, res.accessToken)
      commit(SET_ACCESS_TOKEN_EXPIRATION, res.accessTokenExpiration)
      OpenSeadragon.Tile.prototype.ajaxHeaders = {
        'Authorization': `Bearer ${state.accessToken}`,
        'Hosted-Domain': hostedDomain
      }
      dispatch('widgets/setConfig', {
        ...rootState.widgets.widgetConfig ?? {},
        accessToken: res.accessToken,
        refreshToken: res.refreshToken }, { root: true })

      await new Promise(resolve => setTimeout(resolve, 250))
    } catch(e) {
      console.error('refresh error', e)
    } finally {
      commit(SET_IS_REFRESHING_TOKEN, false)
    }
  },
  async checkAccessToken({ dispatch, getters }) {
    if (getters.hasAccessTokenExpired()) {
      await dispatch('refreshAccessToken')
    }
  },
  passwordExpiryToast ({ state, rootState }) {
    if (rootState.user.generic) return
    const tenantExpiredPasswordDays = rootState.user.daysUntilPasswordExpires
    if (state.generic || tenantExpiredPasswordDays === undefined || tenantExpiredPasswordDays === null) return
    if (tenantExpiredPasswordDays < 5 && tenantExpiredPasswordDays > 1) {
      notify({
        text: i18n.global.t('passwordWillExpire5Days'),
        duration: 5000,
        type: 'success'
      })
    }
    if (tenantExpiredPasswordDays <= 1) {
      postError({
        text: i18n.global.t('passwordWillExpire1Day'),
      });
    }

  },
  async logout ({ rootState }) {
    try {
      await store.dispatch('admin/banners/clearAll')
      if (rootState.user.generic) {
        localStorage.removeItem("searchHistory_" + rootState.user.id)
      }
      await http.post('auth/logout')
    } catch (err) {
      console.log(err)
    }
    try {
      const Portal = axios.create({
        baseURL: '/'
      })
      // If on subdomained url, add redirect false query param
      if (window.location.host.endsWith('.documoto.com') || window.location.host.endsWith('.documoto.com.localhost')) {
        await Portal.post('/Portal/api/logout?redirect=false')
      } else {
        await Portal.post('/Portal/api/logout')
      }
    } catch (err) {
      console.log(err)
    }
    await nextTick()
    const ok = rootState.user.organizationKey ? `ok=${rootState.user.organizationKey}` : ''
    const tk = !ok && rootState.user.tenantKey ? `tk=${rootState.user.tenantKey}` : ''

    window.location.replace(`/ui/login?${ok}${tk}`)
  },
  async redirectAfterLogin (context, { query }) {
    const { redirect = '/' } = query
    const q = Object.assign({}, query)

    await store.dispatch('admin/banners/clearAll')

    delete q['tek']
    delete q['tk']
    delete q['ok']
    delete q['username']
    delete q['redirect']
    delete q['cno']
    delete q['rqid']
    delete q['t']
    delete q['uek']
    delete q['password']

    router.replace({ path: redirect || '/', query: q })
  },
  async setIsRefreshTokenValid(context, value) {
    context.commit(SET_IS_REFRESH_TOKEN_VALID, value)
  },
  async setAccessToken(context, accessToken) {
    context.commit(SET_ACCESS_TOKEN, accessToken)
  },
  async setAuthenticated(context, payload) {
    context.commit(AUTHENTICATED, payload)
  },
  setAuthenticating(context, payload) {
    context.commit(AUTHENTICATING, payload)
  }
}

const mutations = {
  [AUTHENTICATING] (state, { authenticating, error }) {
    state.authenticating = authenticating
    if (!error) return
    state.authenticatingError = error
  },
  [AUTHENTICATED] (state, { authenticated }) {
    state.authenticated = authenticated
    state.authenticating = false
    state.authenticatingError = ''
  },
  [SET_IS_WIDGET] (state, value) {
    state.isWidget = value
  },
  [SET_ACCESS_TOKEN] (state, value) {
    state.accessToken = value
  },
  [SET_REFRESH_TOKEN] (state, value) {
    state.refreshToken = value
  },
  [SET_IS_REFRESH_TOKEN_VALID] (state, value) {
    state.isRefreshTokenValid = value
  },
  [SET_ACCESS_TOKEN_EXPIRATION] (state, value) {
    state.accessTokenExpiration = value
  },
  [SET_IS_REFRESHING_TOKEN] (state, value) {
    state.isRefreshingToken = value
  },
  [SET_HOSTED_DOMAIN] (state, value) {
    state.hostedDomain = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    user
  }
}
