import http from '@/http';

const CART_COLUMNS_RECEIVED = 'CART_COLUMNS_RECEIVED';

const state = {
  items: [],
  hasPricing: false,
  isLoaded: false
};

const actions = {
  async getCartColumns({commit, state}) {
    try {
      if (!state.isLoaded) {
        const {data} = await http.get('cart/metadata');
        commit(CART_COLUMNS_RECEIVED, {items: data});
      }
    } catch (err) {
      // na
    }
  }
};

const mutations = {
  [CART_COLUMNS_RECEIVED](state, {items}) {
    state.hasPricing = false;

    const columns = [];
    if (items.length) {
      items.forEach((col) => {
        switch (col.field) {
          case 'partNumber':
          case 'supplier':
            break;
          // PART DATA
          case 'part':
            columns.push({property: 'name'});
            break;
          case 'name':
            columns.push({property: 'name'});
            break;
          case 'qty':
            columns.push({property: 'qty'});
            break;
          case 'uom':
            columns.push({property: 'uom'});
            break;
          // BOOK DATA
          case 'book':
            columns.push({property: 'book'});
            break;
          case 'chapter':
            columns.push({property: 'chapter'});
            break;
          case 'subChapter':
            columns.push({property: 'sub-chapter'});
            break;
          case 'page':
            columns.push({property: 'page'});
            break;
          // PRICING
          case 'retailPrice':
            state.hasPricing = true;
            columns.push({property: 'retailPrice', type: 'price'});
            break;
          case 'totalRetailPrice':
            columns.push({property: 'totalRetailPrice', type: 'total'});
            break;
          case 'discountedPrice':
            state.hasPricing = true;
            columns.push({property: 'discountedPrice', type: 'price'});
            break;
          case 'totalDiscountedPrice':
            columns.push({property: 'totalDiscountedPrice', type: 'total'});
            break;
          case 'wholesalePrice':
            state.hasPricing = true;
            columns.push({property: 'wholesalePrice', type: 'price'});
            break;
          case 'totalWholesalePrice':
            columns.push({property: 'totalWholesalePrice', type: 'total'});
            break;
          // OTHER
          case 'eta':
            columns.push({property: 'eta'});
            break;
          case 'availability':
            columns.push({property: 'availability'});
            break;
          default: {
            const fields = col.field.split(':');
            const property = fields.length > 2 ? fields[2] : fields.length > 1 ? fields[1] : col.fields; // eslint-disable-line
            columns.push({property, type: 'tag', name: col.name});
          }
        }
      });
    }
    columns.push({property: 'actions'});

    state.items = columns;
    state.isLoaded = true;
  }
};

export default {
  state,
  actions,
  mutations
};
