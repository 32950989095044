import { store } from '../store'

export default function registerFetchInterceptor() {
  const { fetch: originalFetch } = window
  window.fetch = async (resource, config = {}) => {
    let response
    try {
      const { accessToken, hostedDomain } = store.state.auth
      config.headers = {
        ['Authorization']: `Bearer ${accessToken}`,
        ['Hosted-Domain']: hostedDomain
      }   
      response = await originalFetch(resource, config)
    } catch(e) {
      console.error(e)
    }
    return response
  }
}