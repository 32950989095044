<template>
  <main class="request-for-quote-container">
    <h1
      v-if="orderLoaded"
      class="title is-size-4 is-marginless"
    >
      {{ $t('requestForQuote') }}
    </h1>
    <section
      v-if="orderLoaded"
      class="request-for-quote"
    >
      <div
        v-for="(row, orderIndex) in orderLayout"
        :key="orderIndex"
        class="columns"
      >
        <div
          v-for="(column, rowIndex) in row"
          :key="rowIndex"
          class="column"
        >
          <order-cell
            v-for="item in column"
            :key="item"
            :item="orderFields[item]"
            :data="orderData[item] || null"
            :can-save-local="!isGenericUser"
            :can-edit="true"
          />
        </div>
      </div>
      <div class="items">
        <label class="label">{{ $tc('part', 2) }}</label>
        <cart-table-review />
      </div>
    </section>
    <section
      v-else
      class="request-for-quote-loading"
    >
      <loading-icon />
    </section>
    <nav
      v-if="orderLoaded && orderLayout.length"
      class="level is-mobile"
    >
      <div class="level-left">
        <div class="level-item">
          <button
            class="button is-primary"
            :disabled="!canSubmitOrder"
            @click="submitOrder({ type: 'rfq' })"
          >
            {{ $t('submit') }}
          </button>
        </div>
        <div class="level-item">
          <button
            class="button cancel-button"
            @click="navigateToCart"
          >
            {{ $t('cancel') }}
          </button>
        </div>
      </div>
    </nav>
  </main>
</template>

<script>
import CartTableReview from '@/components/library/cart/orders/CartTableReview'
import LoadingIcon from '@/components/common/LoadingIcon'
import OrderCell from '@/components/library/orders/cells/OrderCell'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'RequestForQuote',
  components: {
    CartTableReview,
    OrderCell,
    LoadingIcon
  },
  data () {
    return {
      quickAddModalOpen: false
    }
  },
  computed: {
    ...mapGetters({
      isQuickAddEnabled: 'user/isQuickAddEnabled',
      isGenericUser: 'user/isGenericUser',
      canSubmitOrder: 'order/canSubmitOrder'
    }),
    ...mapState({
      orderData: (state) => state.order.data,
      orderFields: (state) => state.order.fields,
      orderLayout: (state) => state.order.layout,
      orderLoaded: (state) => state.order.isLoaded
    })
  },
  methods: {
    ...mapActions({
      navigateToCart: 'order/navigateToCart',
      submitOrder: 'order/submitOrder'
    })
  },
  created () {
    this.$store.dispatch('order/newOrder', { type: 'rfq' })
  }
}
</script>

<style scoped>
.request-for-quote-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  background-color: darkgray;
}
.request-for-quote-loading {
  flex: 1;
  display: flex;
  flex-flow: column;
  background-color: white;
  justify-content: center;
  align-items: center;
}
.title {
  padding-left: 1rem;
  padding-top: .5rem;
  padding-bottom: 1rem;
  border-bottom: lightgray 1px solid;
  background-color: white;
}
.request-for-quote {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  background-color: white;
}
.level {
  border-top: lightgray 1px solid;
  padding: 1rem;
  background-color: white;
}
.button {
  margin-right: .5rem;
}
.items {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  padding: 0 1rem;
}
@media only screen and (min-width: 1024px) {
  .request-for-quote {
    position: relative;
    margin: auto;
    min-width: 64rem;
  }
}
</style>
