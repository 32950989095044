import i18n from '@/locales'
import * as BluebirdPromise from 'bluebird'
import { ActionContext, GetterTree } from 'vuex'
import { DraftPage, DraftBomItem } from '@/interfaces/admin'
import { StringToStringMap, StringToNumberMap } from '@/interfaces/global'
import { Mutations, LocalMutations } from './draftBomItemUpdating'
import { formatBomJson } from './helpers'
import { updateIndentsOutsideDrag, updateDraggedPartIndents } from './helpers/dragAndDrop'
import { cloneDeep } from "lodash"
import { postError } from '@/helpers/notification'

// Interfaces and Types
interface DragAndDropGetters {
  draftBomItems: DraftBomItem[],
  draggedItems: DraftBomItem[],
  draggedItemRefs: StringToStringMap,
  orphanedPartsOutsideDraggedItems: DraftBomItem[],
  orphanedPartsOutsideDraggedItemsMap: StringToStringMap,
  draggedItemsIndentations: number[],
  smallestIndentInDragged: number,
  // These 2 getters are accessible via draftPage
  // due to dragAndDrop being a module of draftPage
  selected: number[],
  refKeyToMasterListIdx: StringToNumberMap,
}

type Context = ActionContext<DraftPage, any>

enum DropZone {
  above = 'above',
  below = 'below',
  into = 'into',
  last = 'last'
}

interface InitialActionPayload {
  dropZone: DropZone,
  index: number,
  updatedParentRefKey?: string
}

const getters: GetterTree<DraftPage, any> = {
  draftBomItems (state, getters, rootState): DraftBomItem[] {
    return rootState?.draftPage?.draftBomItems ?? []
  },
  draggedItems (state, getters: DragAndDropGetters): DraftBomItem[] {
    return getters.selected
      .map((idx: number) => getters.draftBomItems[idx])
  },
  draggedItemRefs (state, getters: DragAndDropGetters): StringToStringMap {
    const draggedItemRefs: StringToStringMap = {}
    getters.draggedItems.forEach((item: DraftBomItem) => {
      if (item.refKey) {
        draggedItemRefs[item.refKey] = item.refKey
      }
    })
    return draggedItemRefs
  },
  orphanedPartsOutsideDraggedItems (state, getters: DragAndDropGetters): DraftBomItem[] {
    const result: DraftBomItem[] = []

    getters.draggedItems.forEach((item: DraftBomItem) => {
      const children: string[] = item?.children ?? []
      children.forEach((childRef: string) => {
        if (!getters.draggedItemRefs[childRef]) {
          const childIdx: number = getters.refKeyToMasterListIdx[childRef]
          const child = getters.draftBomItems[childIdx]
          result.push(child)
        }
      })
    })
    return result
  },
  orphanedPartsOutsideDraggedItemsMap (state, getters: DragAndDropGetters): StringToStringMap {
    const orphanRefs: StringToStringMap = {}
    getters.orphanedPartsOutsideDraggedItems.forEach((item: DraftBomItem) => {
      const refKey = item?.refKey ?? ''
      orphanRefs[refKey] = refKey
    })
    return orphanRefs
  },
  draggedItemsIndentations (state, getters: DragAndDropGetters): number[] {
    return getters.draggedItems.map((item: DraftBomItem) => item.indentation)
  },
  smallestIndentInDragged (state, getters: DragAndDropGetters): number {
    return Math.min.apply(null, getters.draggedItemsIndentations)
  }
}

const actions = {
  async updateDraftBomOrder ({ getters, dispatch, commit, rootState }: Context, payload: InitialActionPayload): Promise<void> {
    try {
      const { dropZone, index } = payload // Index refers to drop/insertion point in master part list

      // Guard clause to handle dropping dragged item on itself
      if (dropZone === DropZone.into && getters.selected[0] === index) {
        return
      }

      // 2) Clone draftPage & draftBom from master list -- rootState.draftPage.draftBomItems
      const clonedDraftPage = cloneDeep(rootState.draftPage)
      // 3) Remove Dragged Items from list & then update indents of list:
      // In certain use cases removing the dragged parts orphans non-selected parts
      // It's then necessary to hoist such parts to be indented +1 to their new parent
      const partsWithoutDragged: DraftBomItem[] = clonedDraftPage.draftBomItems
        .filter((it: DraftBomItem) => !getters.draggedItemRefs[it?.refKey ?? ''])
      const partsWithUpdatedIndents = updateIndentsOutsideDrag(partsWithoutDragged, getters.orphanedPartsOutsideDraggedItemsMap, getters.draggedItemRefs, getters.refKeyToMasterListIdx, rootState.draftPage.draftBomItems)

      // 4) Update indents of dragged items relative to indent of the part where they'll be dropped:
      // partsWithUpdatedIndents may have updated the indentation value of this part.
      // Thus, it's necessary to look up the part's indentation there rather than the master list.
      // dropZone's value -- above, below, etc. -- then determines whether the highest-level parts in
      // draggedItems are indented equal to or +1 relative to the updated dropZone part's indent.
      // If dropZone is "last", the highest-level parts are set to indent of 0 (root).
      const dropZoneItem = getters.draftBomItems[index]
      const dropZoneUpdatedIndent: number = partsWithUpdatedIndents.find((it: DraftBomItem) => it.refKey === dropZoneItem.refKey)?.indentation ?? 0
      const topLevelIndent = dropZone === DropZone.last
        ? 0
        : dropZone === DropZone.into
          ? dropZoneUpdatedIndent + 1
          : dropZoneUpdatedIndent
      const draggedWithIndentUpdates = updateDraggedPartIndents(getters.draggedItems, topLevelIndent, getters.smallestIndentInDragged)
      // 5) Slice Dragged items back into list and re-order indents
      const idx = dropZone === DropZone.last
        ? rootState.draftPage.draftBomItems.length -1
        : partsWithUpdatedIndents.findIndex((it: DraftBomItem) => {
          return dropZoneItem.refKey === it.refKey
        })
      const dropBefore = dropZone === DropZone.above
      const beforeInsertion = partsWithUpdatedIndents.slice(0, idx)
      const afterInsertion = partsWithUpdatedIndents.slice(idx + 1)
      // ** Empty list fallback supports use case where dropZone is 'last' **
      const reOrderedList = beforeInsertion
        .concat(dropBefore ? draggedWithIndentUpdates : partsWithUpdatedIndents[idx] || [])
        .concat(dropBefore ? partsWithUpdatedIndents[idx] || [] : draggedWithIndentUpdates)
        .concat(afterInsertion)
      clonedDraftPage.draftBomItems = reOrderedList

      // 6) Guard clause for too deep of an indentation
      const updatedIndentations: number[] = reOrderedList.map((it: DraftBomItem) => it.indentation)
      const isTooDeeplyNested = Math.max.apply(null, updatedIndentations) >= 5
      if (isTooDeeplyNested) throw new Error('tooDeeplyNested')

      // 1) Set translucent spinners to run & dirty state to true. Moved from item 1 to here so as to prevent dirty state if too deeply nested
      commit(Mutations.SET_IS_UPDATING, true, { root: true })
      commit(LocalMutations.SET_IS_DIRTY, true)
      await (BluebirdPromise as any).delay(1)

      // 7) Update parent & child relationships based off re-order & indent updates
      const updatedDraftPage = formatBomJson(clonedDraftPage)
      await dispatch('draftPage/setDraftPage', updatedDraftPage, { root: true })
      // 8) Clear selected rows
      commit(Mutations.CLEAR_SELECTED_ROWS, '', { root: true })
    } catch (e: any) {
      const msg = e.message === 'tooDeeplyNested' ? 'draftBomDropError' : 'draftBomError'
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t(msg),
      })
    } finally {
      // 9) Stop spinners
      commit(Mutations.SET_IS_UPDATING, false, { root: true })
    }
  }
}

export default {
  getters,
  actions
}
