import DOMPurify from 'dompurify'

export function sanitizeHtml(html: string) {
  // Ensure a tag links open in new tab
  // https://github.com/cure53/DOMPurify/tree/main/demos#what-is-this
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    if ('target' in node) {
      node.setAttribute('target', '_blank')
    }
  })
  return DOMPurify.sanitize(html)
}