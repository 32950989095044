<template>
  <section v-if="items.length" class="section">
    <h4 data-int="recently-viewed-title" class="title is-4">
      {{ $t('recentlyViewed') }}
    </h4>
    <div class="columns is-mobile" :class="{'is-multiline': showMore}">
      <div v-for="item in items" :key="item.id" class="column is-2-desktop is-3-tablet is-4-mobile">
        <card data-int="recently-viewed-card" :item="item" @click="select"/>
      </div>
    </div>
    <div data-int="recently-viewed-more-less-link"
         :class="{'is-hidden-desktop': items.length < 7,
                  'is-hidden-tablet': items.length < 5,
                  'is-hidden-mobile': items.length < 4}"
         @click="toggleShowMore">
      <a v-if="showMore" class="is-pulled-right">{{ $t('showLess') }}</a>
      <a v-else class="is-pulled-right">{{ $t('showMore') }}</a>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Card from '@/components/common/Card';

export default {
  name: 'RecentlyViewed',
  components: {
    Card
  },
  data() {
    return {
      showMore: false
    };
  },
  computed: {
    ...mapState({
      items: ({ recentlyviewed }) => recentlyviewed.items
    })
  },
  methods: {
    ...mapActions({
      navigateToContent: 'content/navigateToContent',
      getRecentlyViewed: 'recentlyviewed/getRecentlyViewed',
      resetBrowseState: 'browse/resetBrowseState'
    }),
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    async select(content) {
      await this.resetBrowseState()
      this.navigateToContent({ content });
    }
  },
  async created() {
    await this.getRecentlyViewed();
  }
};
</script>

<style scoped>
.section {
  padding: .5rem 1rem 1rem 1rem;
  height: auto;
  overflow: hidden;
  border-bottom: lightgray 1px solid;
}
</style>
