<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div
      data-int="order-cell-address-modal"
      class="modal-card"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">
          <strong data-int="order-cell-address-modal-title">{{ title || $tc('address', 2) }}</strong>
        </p>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">{{ $tc('country', 1) }}</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-model="countryCode"
                :class="{'is-danger': !isValid}"
              >
                <option disabled>
                  {{ $t('selectAField', {field: $t('country')}) }}
                </option>
                <option
                  v-for="country in countryCodes"
                  :key="country.key"
                  :value="country.key"
                >
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="field">
          <label class="label">{{ $t('line') }}</label>
          <div class="control">
            <input
              v-model="line1"
              class="input"
              type="text"
              maxlength="255"
            >
          </div>
          <div class="control">
            <input
              v-model="line2"
              class="input"
              type="text"
              maxlength="255"
            >
          </div>
          <div class="control">
            <input
              v-model="line3"
              class="input"
              type="text"
              maxlength="255"
            >
          </div>
        </div>
        <div class="field">
          <label class="label">{{ $t('city') }}</label>
          <div class="control">
            <input
              v-model="city"
              class="input"
              type="text"
              maxlength="255"
            >
          </div>
        </div>
        <div class="field">
          <label class="label">{{ !isUSA ? $t('province'): $t('state') }}</label>
          <div class="control">
            <input
              v-model="state"
              class="input"
              type="text"
              maxlength="255"
            >
          </div>
        </div>
        <div class="field">
          <label class="label">{{ !isUSA ? $t('postalCode') : $t('zipCode') }}</label>
          <div class="control">
            <input
              v-model="zipCode"
              class="input"
              type="text"
              maxlength="255"
            >
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          :disabled="!isValid"
          data-int="order-cell-address-modal-save-button"
          class="button is-primary"
          @click="save"
        >
          {{ $t('save') }}
        </button>
        <button
          data-int="order-cell-address-modal-cancel-button"
          class="button cancel-button"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
export default {
  name: 'OrderCellAddressModal',
  props: {
    title: {
      type: String,
      default: ''
    },
    address: {
      type: Object,
      default: () => {}
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      countryCode: '',
      line1: '',
      line2: '',
      line3: '',
      city: '',
      state: '',
      zipCode: ''
    }
  },
  computed: {
    ...mapState({
      countryCodes: (state) => state.countrycodes.items
    }),
    isValid () {
      if (this.isRequired) {
        return this.line1 && this.city && this.state && this.zipCode && this.countryCode
      }
      return true
    },
    isUSA () {
      return this.countryCode === 'USA'
    }
  },
  methods: {
    ...mapActions({
      getCountryCodes: 'countrycodes/getCountryCodes'
    }),
    save () {
      if (this.isValid) {
        const address = {
          countryCode: this.countryCode,
          line1: this.line1,
          line2: this.line2,
          line3: this.line3,
          city: this.city,
          state: this.state,
          zipCode: this.zipCode
        }
        this.$emit('save', address)
        this.cancel()
      }
    },
    cancel () {
      this.$emit('close')
    }
  },
  async created () {
    await this.getCountryCodes()
  },
  mounted () {
    if (this.address) {
      this.countryCode = this.address.countryCode
      this.line1 = this.address.line1
      this.line2 = this.address.line2
      this.line3 = this.address.line3
      this.city = this.address.city
      this.state = this.address.state
      this.zipCode = this.address.zipCode
    }

    if (!this.countryCode) {
      const locale = this.$i18n.locale

      if (locale === 'en-US') {
        this.countryCode = 'USA'
      }
    }
  }
}
</script>

<style scoped>
.modal-card-body {
  flex-direction: column;
  min-height: 12rem;
}
.field {
  flex-shrink: 0;
}
@media print, screen and (min-width: 769px) {
  [data-int="order-cell-address-modal"] {
    top: -2em;
  }
}
</style>
