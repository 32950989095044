import { DraftBomItem } from './draftBomItem'
import { Translation } from './translation'
import { DraftTagValue } from '@/interfaces/admin/draftTags'

interface DraftTagValueItem {
    id: number,
    tagNameId: number,
    targetEntity: number,
    tenantId: number,
    value: string,
    pendingId?: string
}

export interface Params {
    autoGenerateDraftPage: boolean,
    loadDraftSheets?: boolean,
    loadDraftBomItems?: boolean,
    loadDraftPageTagItems?: boolean
}

export enum SchedulerJobState {
    SUBMITTED = 'SUBMITTED',
    INPROCESS = 'INPROCESS',
    COMPLETED = 'COMPLETED',
    COMPLETED_WITH_ERROR = 'COMPLETED_WITH_ERROR',
    CANCELED = 'CANCELED'
}

export enum SchedulerJobType {
  PUBLISH_ARCHIVED_DRAFT_PARENT = 'PUBLISH_ARCHIVED_DRAFT_PARENT',
  PUBLISH_DOCUMENTS_FOR_DRAFT_PAGE = 'PUBLISH_DOCUMENTS_FOR_DRAFT_PAGE'
}

export interface DraftPage {
    created: number|null,
    draftBomItems: DraftBomItem[],
    draftSheets: any[],
    draftTagValueItems: DraftTagValueItem[],
    hashKey: string,
    id: number|null,
    schedulerJobErrors?: string[],
    schedulerJobId?: number,
    schedulerJobState?: SchedulerJobState,
    targetPageId: number|null,
    tenantId: number|null,
    translations: Translation[],
    updated: number|null,
    draftBomItemUpdating?: any,
    useLibvips?: boolean|null
}

export interface IndexPartDataSupplier {
  index: number,
  partData: {
    id: number,
    partNumber: string,
    translations: Translation[],
    uom: 'string',
    partTagValues: DraftTagValue[],
    partNumberCodeId?: number,
    partNumberPartCode?: string
  },
  supplierId: number
}

export interface IDAndParams {
  id: number,
  params: {
    autoGenerateDraftPage: boolean
  }
}

export interface IdxParentIndent {
  idx: number,
  updatedParent: string,
  updatedIndent: number
}
