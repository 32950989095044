import http from '@/http'
import { Organization } from '@/interfaces/admin'
import { TurboError } from '@/interfaces/global'

export interface OrganizationDetail {
  id?: number,
  created?: Date,
  updated?: Date,
  name?: string,
  description?: string | null
}

interface OrgSearchParams {
  offset: number,
  filter: string,
  sort: string,
  includeParentOrgNameInFilter: boolean
}

export const getOrganizations = async function (): Promise<Array<Organization> | TurboError> {
  const { data } = await http.get('organizations/list')
  return data
}

export const getSuperAdminOrganizations = async function (tenantId: number): Promise<Array<Organization> | TurboError> {
  const params = {
    tenantId: tenantId
  }
  const { data } = await http.get('organizations/super-admin/list', { params })
  return data
}

interface OrgResponse {
  data: Organization[],
  total: number,
  offset: number,
  pageCount: number,
  pageNum: number,
  count: number
}

export const searchOrganizations = async function(params: OrgSearchParams): Promise<OrgResponse> {
  const { data, headers } = await http.get('/org-admin/all', { params })
  const total = parseInt(headers['x-total'])
  const offset = parseInt(headers['x-offset']) + parseInt(headers['x-limit'])
  const pageCount = parseInt(headers['x-page-count'])
  const pageNum = parseInt(headers['x-page-num'])
  const count = parseInt(headers['x-count'])

  return { data, total, offset, pageCount, pageNum, count }
}

export const getOrganizationsByOrderTemplate = async function (orderTemplateId: number, limit: number, offset: number): Promise<any> {
  const params = {
    limit: limit,
    offset: offset
  }
  return http.get(`/org-admin/order-template/${orderTemplateId}`, { params })
}

export const getOrganizationsByQuoteTemplate = async function (orderQuoteId: number, limit: number, offset: number): Promise<any> {
  const params = {
    limit: limit,
    offset: offset
  }
  return http.get(`/org-admin/quote-template/${orderQuoteId}`, { params })
}
