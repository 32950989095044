import { ref, toRef } from 'vue'
import { useStore } from 'vuex'

// Not TS because props would require an interface-type across the many OrderCell* types
export default function getSaveLocalState (props) {
  const item = toRef(props, 'item').value
  const saveLocal = ref(false)
  const store = useStore();

  if (store.state.order.localData?.hasOwnProperty(item.key) ?? false) {
    saveLocal.value = true
  }

  return { saveLocal }
}
