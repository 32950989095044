const HIDE_PRICE_FROM_BOM = 'HIDE_PRICE_FROM_BOM';

const state = {
  hideRetailPrice: false,
  hideDiscountPrice: false,
  hideWholeSalePrice: false
};

const actions = {
  toggleHidePriceFromBom({commit, rootState}, {property}) {
    if (rootState.user.accessPrivileges.indexOf('HIDE_PRICE_VALUES_ENABLED') >= 0) {
      commit(HIDE_PRICE_FROM_BOM, {property});
    }
  }
};

const mutations = {
  [HIDE_PRICE_FROM_BOM](state, {property}) {
    if (property.indexOf('retail') >= 0) {
      state.hideRetailPrice = !state.hideRetailPrice;
    } else if (property.indexOf('wholesale') >= 0) {
      state.hideWholeSalePrice = !state.hideWholeSalePrice;
    } else if (property.indexOf('discount') >= 0) {
      state.hideDiscountPrice = !state.hideDiscountPrice;
    }
  }
};

export default {
  state,
  actions,
  mutations
};
