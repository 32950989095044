import http from '@/http'
import { AxiosResponse } from 'axios'

export const deleteTag = async (tagId: number): Promise<void> => {
  await http.delete(`/admin/tag-name/${tagId}`)
}

export const getUsedCountForTag = async (tagId: number): Promise<number> => {
  const params = {
    limit: 1001
  }
  return http.get(`/admin/tag-name/${tagId}/used`, { params })
    .then(res => res.data)
}

export const loadTag = async (tagId: number): Promise<AxiosResponse> => {
  return http.get(`/admin/tag-name/${tagId}`)
}

export const searchTags = async (params: any): Promise<AxiosResponse> => {
  return http.get('/admin/tag-name', { params })
}

export const updateTag = async (tagId: number, dto: any): Promise<AxiosResponse> => {
  return http.put(`/admin/tag-name/${tagId}`, dto)
}
