<template>
  <aside class="filters-container">
    <filters-add :filters="filters" :selected-filters="selectedFilters" @selectFilters="setSelectedFilters" />
    <filters-clear @clear="clear" />
    <filter-item v-for="filter in selectedFilters"
                 :key="filter.field"
                 :filter="filter"
                 :selected-options="selectedOptions"
                 @remove="removeSelectedFilter"
                 @updateOption="updateSelectedFilterOptions" />
  </aside>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FiltersAdd from '@/components/common/filters/FiltersAdd';
import FilterItem from '@/components/common/filters/FilterItem';
import FiltersClear from '@/components/common/filters/FiltersClear';

export default {
  name: 'OrdersFilterBar',
  components: {
    FiltersClear,
    FilterItem,
    FiltersAdd
  },
  computed: {
    ...mapState({
      filters: (state) => state.orders.filters.items,
      selectedOptions: (state) => state.orders.filters.selected
    }),
    ...mapGetters({
      selectedFilters: 'orders/selectedFilters'
    })
  },
  methods: {
    ...mapActions({
      setSelectedFilters: 'orders/setSelectedFilters',
      removeSelectedFilter: 'orders/removeSelectedFilter',
      updateSelectedFilterOptions: 'orders/updateSelectedFilterOptions',
      clear: 'orders/clearSelectedFilters'
    })
  }
};
</script>

<style scoped>
.filters-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: .5rem 1rem;
}

.filters-container > div {
  margin: 0 .15rem .15rem .15rem !important;
}
</style>
