
/**
  * Used to help split up components by logical concern.
  *
  * @param {Vue[]} providedRefs array of child components
  * @param {string} methodName to invoke on chile. i.e. 'load', 'save'
  * @returns {void}
  */
export const invokeOnRef = async (providedRefs, methodName) => {
  const refs = Object.keys(providedRefs).flatMap((refName) =>
    Array.isArray(providedRefs[refName])
      ? providedRefs[refName]
      : [providedRefs[refName]]
  )
    .filter((ref) => typeof ref[methodName] === 'function')
    .sort((a, b) => (a.invocationOrder || 0) - (b.invocationOrder || 0))

  for (const ref of refs) {
    if (ref[methodName]) {
      await ref[methodName]()
    }
  }
}
