<template>
  <b-field
    expanded
    :type="{'is-danger': formMessage.length > 0}"
    :message="formMessage"
  >
    <b-input
      expanded
      v-model="formValue"
    />
  </b-field>
</template>

<script>
/**
 * Configurable properties:
 *  - errorFieldKey: Customize field name with a translation key of your choosing when field is
 *  invalid (<errorFieldKey> is invalid).
 *  - fieldExtension: Specify an explicit valid filename extension. Defaults with accepting any
 *  3-4 alphanumeric ending.
 *
 * Emit events:
 *  - @change: Emit field value. If fieldExtension specified, it will automatically append to the
 *  value.
 *  - @invalid: Emit state as value changes
 */
export default {
  name: 'FileNameField',
  props: {
    errorFieldKey: {
      type: String,
      default: 'pageFile'
    },
    fieldExtension: {
      type: String,
      default: null
    },
    initialValue: {
      type: String,
      default: null
    },
    required: Boolean
  },
  data: () => ({
    formValue: ''
  }),
  watch: {
    formValue(value) {
      if (this.fieldExtension == null) {
        this.$emit('change', value)
        return
      }

      value.endsWith(this.fieldExtension)
        ? this.$emit('change', value)
        : this.$emit('change', `${value}.${this.fieldExtension}`)
    }
  },
  computed: {
    formMessage () {
      let regex = /^[\w\s\-(),.]+$/ig;
      if (this.required && this.formValue.length === 0) {
        this.$emit('invalid', true)
        return this.$t('requiredField')
      } else if (this.formValue.length > 0 && regex.test(this.formValue) === false) {
        this.$emit('invalid', true)
        return this.$t('invalidField', { field: this.translate(this.errorFieldKey) })
      } else {
        this.$emit('invalid', false)
        return ''
      }
    },
  },
  mounted () {
    if (this.initialValue) {
      this.formValue = this.initialValue
    }
  }
}
</script>

<style scoped>

</style>
