export enum Locales {
  'en-US' = 'en-US',
  'id-ID' = 'id-ID',
  'zh-CN' = 'zh-CN',
  'cs-CZ' = 'cs-CZ',
  'nl-NL' = 'nl-NL',
  'fi-FI' = 'fi-FI',
  'fr-FR' = 'fr-FR',
  'de-DE' = 'de-DE',
  'hu-HU' = 'hu-HU',
  'it-IT' = 'it-IT',
  'ja-JP' = 'ja-JP',
  'ko-KR' = 'ko-KR',
  'nn-NO' = 'nn-NO',
  'pl-PL' = 'pl-PL',
  'pt-PT' = 'pt-PT',
  'ro-RO' = 'ro-RO',
  'ru-RU' = 'ru-RU',
  'sv-SE' = 'sv-SE',
  'sr-SP' = 'sr-SP',
  'es-ES' = 'es-ES',
  'tr-TR' = 'tr-TR',
  'uk-UA' = 'uk-UA'
}
