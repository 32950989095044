export function entityTypePluralize(type) {
  switch (type) {
    case 'chapter':
      return 'chapters';
    case 'page':
      return 'pages';
    case 'pagepart':
    case 'part':
      return 'parts';
    default:
      return 'medias';
  }
}

export function routeToEntityTypeAndId(id, type) {
  const ids = id.toString().split('/');
  const entities = [];

  ids.forEach((segment) => {
    const entity = segment.split(':');

    if (entity.length > 1) {
      entities.push({
        id: parseInt(entity[1], 10),
        type: entity[0]
      });
    } else {
      entities.push({
        id: parseInt(segment, 10),
        type
      });
    }
  });
  return entities;
}
