<template>
  <div class="field">
    <label class="label">
      {{ item.name }}
      <span v-if="canSaveLocal" class="is-pulled-right">
        <label class="has-text-weight-normal mr-1">{{ $t('save') }}</label>
        <input v-model="saveLocal" type="checkbox"/>
      </span>
    </label>
    <div v-if="isEditable" class="field is-fullwidth has-addons is-marginless is-paddingless">
      <p class="control is-expanded">
        <input v-model="emailInput"
               class="input"
               type="email"
               required
               :class="{'is-danger': !isEmailInputValid}"
               @blur="add"
               @keyup.enter="add"/>
      </p>
      <p class="control">
        <button class="button has-text-info" :disabled="!isEmailInputValid" @click="add">
          <span class="icon">
            <i class="fa fa-plus-circle"/>
          </span>
        </button>
      </p>
    </div>
    <p v-if="!isEmailInputValid" class="help is-danger">Email is invalid.</p>
    <p v-else-if="!isValid" class="help is-danger">At least one email address is required.</p>
    <div class="field email-fields">
      <article v-for="(email, index) in value" :key="email" class="media is-marginless">
        <div class="media-content">
          <div class="content">
            {{ email }}
          </div>
        </div>
        <div v-if="isEditable" class="media-right" @click="remove(index)">
          <button class="delete has-text-danger"/>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import getSaveLocalState from '@/components/library/orders/cells/getSaveLocalState'

export default {
  name: 'OrderCellEmailList',
  props: {
    item: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      default: () => []
    },
    canSaveLocal: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: this.data ? this.data.slice() : []
    };
  },
  computed: {
    ...mapGetters({
      getEmailInput: "order/getEmailInput",
      emailsValidityObj: "order/emailValidityChecks"
    }),
    emailInput: {
      get() {
        return this.getEmailInput(this.item.key)
      },
      set(value) {
        this.setEmailInput({ key: this.item.key, value })
      }
    },
    isEmailInputValid() {
      return this.emailsValidityObj[this.item.key] ?? true
    },
    isValid() {
      if (this.item.required && !this.emailInput.length) {
        return Boolean(this.value.length);
      }
      return true;
    },
    isEditable() {
      return this.item.editable && this.canEdit;
    }
  },
  watch: {
    data() {
      this.value = this.data ? this.data.slice() : [];
    },
    saveLocal() {
      this.change();
    }
  },
  methods: {
    ...mapActions({
      setEmailInput: "order/setEmailInput"
    }),
    change() {
      this.$emit('change', {
        field: this.item,
        value: this.value,
        updateLocal: this.saveLocal && this.isValid
      });
    },
    add() {
      const valueHasEmail = this.value.includes(this.emailInput)
      if (!valueHasEmail && this.isEmailInputValid && this.emailInput.trim().length > 0) {
        this.value.push(this.emailInput);
        this.emailInput = '';
        this.change();
      }
    },
    remove(index) {
      this.value.splice(index, 1);
      this.change();
    }
  },
  setup (props) {
    const { saveLocal } = getSaveLocalState(props)
    return { saveLocal }
  }
};
</script>

<style scoped>
.field {
  padding: .5rem 1rem;
  width: 100%;
}
.media {
  border: 1px solid lightgrey;
  border-bottom: none;
  padding: .5rem;
}
.media:last-child {
  border-bottom: 1px solid lightgrey;;
}
.icon {
  margin-top: .15rem;
  margin-left: .5rem;
}
.email-fields {
  margin-top: .5rem;
  padding: 0;
}
</style>
