import { DraftBomItem, draftBomColumns, EditTypes } from '@/interfaces/admin'

export function hasRequiredFields (part: DraftBomItem): boolean {
  // Check required columns, excluding partName, UOM, and Quantity
  const RequiredFields = Object.freeze({
    [draftBomColumns.partNumber]: draftBomColumns.partNumber,
    [draftBomColumns.supplierId]: draftBomColumns.supplierId
  })
  for (const key in RequiredFields) {
    if (!part[key]) {
      return false
    }
  }

  // Check for bad pricing data
  const prices = [ EditTypes.retailPrice, EditTypes.discountedPrice, EditTypes.wholesalePrice]
  for (const price of prices) {
    if (!part.hasOwnProperty(price)) continue
    
    const priceAsString = typeof part[price] === "number"
      ? part[price].toString() 
      : ""
    if (!priceAsString.includes(".")) continue

    const decimals = priceAsString.split(".").pop() || ""
    if (decimals.length >= 3) return false
  }
  
  // Ensure there is at least one valid translation
  // and no translations with empty name
  if (!part.partTranslations.length) return false
  return part.partTranslations.every(it => !!it.name)
}
