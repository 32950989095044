import http from "@/http";
import {v4 as uuidv4} from "uuid";
import localforage from "localforage";
import qs from 'qs'
import auth from './auth'
import { getRoute } from "@/router/getRoute"

const RESETTING_PASSWORD = 'RESETTING_PASSWORD'
const SET_LOCAL_STORAGE = 'SET_LOCAL_STORAGE'
const EXPIRED_PASSWORD_ERROR = 'EXPIRED_PASSWORD_ERROR'

const state = {
  resettingExpiredPassword: false,
  expiredPasswordError: ''
}

const actions = {
  async expiredPasswordReset ({ dispatch, commit, rootState }, { newPassword, username, oldPassword, tenantKey }) {
    try {
      const route = getRoute()
      const { query } = route
      const locale = localStorage.getItem('locale') || 'en-US'
      const uuid = uuidv4()
      const formData = {}

      formData.username = username
      formData.oldPassword = oldPassword
      formData.newPassword = newPassword
      formData.tenantKey = tenantKey
      formData.locale = locale
      formData.csrfToken = uuid

      await commit(RESETTING_PASSWORD, { resettingExpiredPassword: true })

      await http.post('auth/login-expired-password', qs.stringify(formData, { indices: false }))

      await commit('SET_LOCAL_STORAGE', { key: 'csrfToken', value: uuid }, { root: true })

      await dispatch('auth/redirectAfterLogin', { query })
    } catch (error) {
      const message = error && error.response ? error.response.data.message : ''
      await commit(EXPIRED_PASSWORD_ERROR, { message })
    } finally {
      await commit(RESETTING_PASSWORD, { resettingExpiredPassword: false })
    }
  }
}

const mutations = {
  async ['RESETTING_PASSWORD'] (state, { resettingExpiredPassword }) {
    state.resettingExpiredPassword = resettingExpiredPassword
  },
  async ['SET_LOCAL_STORAGE'] (context, { key, value }) {
    await localforage.setItem(key, value)
  },
  async ['EXPIRED_PASSWORD_ERROR'] (state, { message }) {
    state.expiredPasswordError = message
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  modules: {
    auth
  }
};
