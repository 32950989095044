<template>
  <td
    v-if="!isHidden"
    :data-int="className"
  >
    {{ total }}
  </td>
</template>

<script>
export default {
  name: 'CartCellTotal',
  props: {
    column: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    hideRetailPrice: Boolean,
    hideDiscountPrice: Boolean,
    hideWholeSalePrice: Boolean
  },
  computed: {
    className () {
      return `cart-cell-${this.column.type}`
    },
    isHidden () {
      switch (this.column.property) {
        case 'totalRetailPrice':
          return this.hideRetailPrice
        case 'totalWholesalePrice':
          return this.hideWholeSalePrice
        case 'totalDiscountedPrice':
          return this.hideDiscountPrice
        default:
          return false
      }
    },
    total () {
      function formatPrice (amount, qty, currency, locale) {
        if (amount === undefined || amount === 0.0) return ''
        const value = amount * qty

        return new Intl.NumberFormat(
          locale,
          { style: 'currency', currency: currency }
        ).format(value)
      }

      const locale = this.$store.state.user.locale
      if (this.column.property === 'totalRetailPrice' && !this.hideRetailPrice) {
        return formatPrice(
          this.item.retailAmount,
          this.item.qty,
          this.item.retailCurrency,
          locale
        )
      } else if (this.column.property === 'totalWholesalePrice' && !this.hideWholeSalePrice) {
        return formatPrice(
          this.item.wholesaleAmount,
          this.item.qty,
          this.item.wholesaleCurrency,
          locale
        )
      } else if (this.column.property === 'totalDiscountedPrice' && !this.hideDiscountPrice) {
        return formatPrice(
          this.item.discountAmount,
          this.item.qty,
          this.item.discountCurrency,
          locale
        )
      } else {
        return ''
      }
    }
  }
}
</script>
