export const vDClickOutside = {
  hasBindingValue: false,
  beforeMount(el, binding) {
    if(!binding.value) return
    binding.dir.hasBindingValue = true
    el.clickOutsideEvent = function(event) {
      // Check if the clicked element is neither the element
      // to which the directive is applied nor its child
      if (!(el === event.target || el.contains(event.target))) {
        // Invoke the provided method
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  beforeUnmount(el, binding) {
    if (!binding.dir.hasBindingValue) return
    // Remove the event listener when the bound element is unmounted
    document.removeEventListener('click', el.clickOutsideEvent);
    binding.dir.hasBindingValue = false
  },
}
