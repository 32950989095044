export interface UserGroup {
    accessPrivilegeIds: Array<number>,
    accessPrivileges: Array<string>,
    name: string
    created: number,
    id: number,
    mediaCategories?: Array<number>,
    updated: number,
    userCount: number,
    userGroupType: UserGroupTypes
}

export interface NewUserGroupRequest {
    accessPrivileges: Array<string>,
    name: string,
    userGroupType: UserGroupTypes
}

export enum UserGroupTypes {
  "standard",
  "widget"
}