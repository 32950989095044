<template>
  <pagination :offset="offset"
              :limit="limit"
              :total="total"
              @changePage="changePage"
              @changePerPage="changePerPage"/>
</template>

<script>
import { mapState, mapActions } from "vuex"
import Pagination from "@/components/common/Pagination"
export default {
  name: "NotificationsPaginationWrapper",
  components: { Pagination },
  computed: {
    ...mapState({
      offset: ({ notifications }) => notifications.offset,
      limit: ({ notifications }) => notifications.limit,
      total: ({ notifications }) => notifications.total
    })
  },
  methods: {
    ...mapActions({
      changePage: "notifications/setNotificationsPage",
      changePerPage: "notifications/setNotificationsResultsPerPage"
    })
  }
}
</script>