import rest from '@/http';

export default {
  install(Vue, options) {
    Vue.mixin({
      computed: {
        $rest() {
          return rest;
        }
      }
    });
  }
};
