export default {
  colorText (colorOrNone, darkColor, lightColor) {
    const useDarkText = darkColor ? darkColor : '#000000'
    const useLightText = lightColor ? lightColor :'#ffffff'

    // Get RGB Values & convert into Grayscale per equation found here:
    // https://goodcalculators.com/rgb-to-grayscale-conversion-calculator/
    const color = (colorOrNone.charAt(0) === '#')
      ? colorOrNone.substring(1, 7)
      : colorOrNone
    const red = parseInt(color.substring(0, 2), 16)
    const green = parseInt(color.substring(2, 4), 16)
    const blue = parseInt(color.substring(4, 6), 16)
    const greyscaleValue = (red * 0.299) + (green * 0.587) + (blue * 0.114)

    // Evaluate greyscaleValue on 255 scale against a threshold pivot point
    // Per testing, 186 looks good aesthetically as this blog suggests:
    // https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
    const greyscaleThreshold = 186
    const hasLightBackground = greyscaleValue > greyscaleThreshold
    return hasLightBackground ? useDarkText : useLightText
  }
}
