import http from '@/http'

const USER_PREFERENCES_RECEIVED = 'USER_PREFERENCES_RECEIVED'

const state = {
  addToCartQtyBehavior: 'qtyOne',
  bomLayout: 'vertical',
  overrideHotpointScaleValue: false,
  staticHotpointSize: null,
  tocState: true
}

const actions = {
  async getUserPreferences ({ commit, dispatch, state }) {
    try {
      const { data } = await http.get('user-preferences')
      await commit(USER_PREFERENCES_RECEIVED, { preferences: data })

      // Set Initial Bom Layout
      dispatch('content/updateBomLayout', state.bomLayout, { root: true })
    } catch (err) {
      // na
    }
  },
  async updatePreferences ({ dispatch }, preference) {
    try {
      await http.put('user-preferences', preference)
      dispatch('getUserPreferences')

      if (preference.locale) {
        dispatch('localeChanged', null, { root: true })
      }
    } catch (err) {
      console.log(err)
    }
  }
}

const mutations = {
  [USER_PREFERENCES_RECEIVED] (state, { preferences }) {
    state.addToCartQtyBehavior = preferences.addToCartQtyBehavior || 'qtyOne'
    state.bomLayout = preferences.bomLayout || 'vertical'
    state.overrideHotpointScaleValue = preferences.overrideHotpointScaleValue || 1
    state.staticHotpointSize = preferences.staticHotpointSize
    state.tocState = preferences.tocState
  }
}

export default {
  state,
  actions,
  mutations
}
