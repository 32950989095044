<template>
  <th v-if="!isHidden" :data-int=className>
    <label>{{ label }}</label>
  </th>
</template>

<script>
export default {
  name: 'CartHeaderTotal',
  props: {
    column: {
      type: Object,
      required: true
    },
    hideRetailPrice: {
      type: Boolean,
      default: false
    },
    hideDiscountPrice: {
      type: Boolean,
      default: false
    },
    hideWholeSalePrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      return `cart-header-${this.column.type}`;
    },
    isHidden() {
      switch (this.column.property) {
        case 'totalRetailPrice':
          return this.hideRetailPrice;
        case 'totalWholesalePrice':
          return this.hideWholeSalePrice;
        case 'totalDiscountedPrice':
          return this.hideDiscountPrice;
        default:
          return false;
      }
    },
    label() {
      switch (this.column.property) {
        case 'totalRetailPrice':
          return this.$t('retailTotal');
        case 'totalWholesalePrice':
          return this.$t('wholesaleTotal');
        case 'totalDiscountedPrice':
          return this.$t('discountedTotal');
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>

</style>
