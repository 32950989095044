<template>
  <div class="sort-toggle has-text-grey-light" @click.stop="toggle()">
    <div :class="ascClasses" />
    <div :class="descClasses" />
  </div>
</template>

<script>
export default {
  inject: {
    sourceStore: {
      default: 'search',
      from: 'sourceStore'
    },
    targetStore: {
      default: 'search',
      from: 'targetStore'
    }
  },
  props: {
    field: {
      type: String,
      required: true
    },
    locale: {
      type: String,
      default: () => undefined
    },
    noModifier: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    sourceStoreResolved() {
      return this.sourceStore.split('/').reduce((acc, el) => acc[el], this.$store.state);
    },
    active() {
      return this.sourceStoreResolved.sort.startsWith(this.field);
    },
    direction() {
      return this.sourceStoreResolved.direction;
    },
    iconClass() {
      if (!this.active) return ['fas', 'fa-sort-up', 'inactive'];
      if (this.direction === 'asc') return ['fas', 'fa-sort-up'];
      if (this.direction === 'desc') return ['fas', 'fa-sort-down'];
    },
    baseClasses() {
      return {
        'sort-arrow': true,
        'fas': true
      };
    },
    ascClasses() {
      return {
        ...this.baseClasses,
        'fa-sort-up': true,
        'has-text-grey-dark': this.direction === 'asc' && this.active
      };
    },
    descClasses() {
      return {
        ...this.baseClasses,
        'fa-sort-down': true,
        'has-text-grey-dark': this.direction === 'desc' && this.active
      };
    }
  },
  methods: {
    toggle() {
      const {direction} = this.sourceStoreResolved;
      if (this.active) {
        this.$store.dispatch(this.targetStore + '/setSort', {
          value: this.field,
          locale: this.locale,
          noModifier: this.noModifier,
          direction: this.invertDirection(direction)
        });
      } else {
        this.$store.dispatch(this.targetStore + '/setSort', {
          value: this.field,
          locale: this.locale,
          noModifier: this.noModifier,
          direction: 'asc'
        });
      }
    },
    invertDirection(dir) {
      switch (dir) {
        case 'asc':
          return 'desc';

        case 'desc':
        default:
          return 'asc';
      }
    }
  }
};
</script>

<style scoped>
.sort-toggle {
  position: relative;
  display: inline-block;
  height: 1rem;
}

.sort-arrow {
  position: absolute;
  top: 0.25rem;
}
</style>
