/*
 * Reusable component to determine part location data derived from the path
 */

export default {
  getPartLocationData (route) {
    const context = route.split('/')
    const result = {}
    let isBook = false
    let isPage = false

    // This can be greatly simplified if the path is parameterized
    context.forEach((item) => {
      const data = item.split(':')
      if (data[0] === 'book') {
        isBook = true
      } else if (Number.isInteger(Number.parseInt(data[0]))) {
        if (isBook) result.bookId = Number.parseInt(data[0])
        else if (isPage) result.pageId = Number.parseInt(data[0])
      } else if (data[0] === 'chapter') {
        if (result.chapterId == null) result.chapterId = Number.parseInt(data[1]) // Take the first chapter
        result.subChapterId = Number.parseInt(data[1]) // Take the last chapter
      } else if (data[0] === 'page') {
        if (isBook) result.pageId = Number.parseInt(data[1])
        else isPage = true
      }
    })
    return result
  }
}
