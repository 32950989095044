<template>
  <section v-if="items.length" class="section">
    <h4 data-int="recently-added-title" class="title is-4">
      {{ $t('recentlyAdded') }}
    </h4>
    <div class="columns is-mobile" :class="{'is-multiline': showMore}">
      <div v-for="item in items" :key="item.id" class="column is-2-desktop is-3-tablet is-4-mobile">
        <card data-int="recently-added-card" 
              :item="item" 
              :is-widget="isWidget"
              @click="select"
              @navToWidget="mapDtoAndNavToWidgetContent(item, dtoMap, true)"/>
      </div>
    </div>
    <div data-int="recently-added-more-less-link"
         class="is-pulled-right"
         :class="{'is-hidden-desktop': items.length < 7,
                  'is-hidden-tablet': items.length < 5,
                  'is-hidden-mobile': items.length < 4}"
         @click="toggleShowMore">
      <a v-if="showMore" class="is-pulled-right">{{ $t('showLess') }}</a>
      <a v-else class="is-pulled-right">{{ $t('showMore') }}</a>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, useStore } from 'vuex'
import Card from '@/components/common/Card'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'

export default {
  name: 'RecentlyAdded',
  components: {
    Card
  },
  setup() {
    const { mapDtoAndNavToWidgetContent,
      entityIdToIdDtoMap } = useWidgetNavigator()

    const store = useStore()
    const isWidget = store.getters['widgets/isWidget']
    
    return { mapDtoAndNavToWidgetContent, dtoMap: entityIdToIdDtoMap, isWidget }
  },
  data() {
    return {
      showMore: false
    };
  },
  computed: {
    ...mapState({
      items: ({ recentlyadded }) => recentlyadded.items
    })
  },
  methods: {
    ...mapActions({
      navigateToContent: 'content/navigateToContent',
      getRecentlyAdded: 'recentlyadded/getRecentlyAdded',
      resetBrowseState: 'browse/resetBrowseState'
    }),
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    async select(content) {
      await this.resetBrowseState()
      this.navigateToContent({ content })
    }
  },
  async created() {
    await this.getRecentlyAdded()
  }
};
</script>

<style scoped>
.section {
  padding: .5rem 1rem 1rem 1rem;
  height: auto;
  overflow: hidden;
  border-bottom: lightgray 1px solid;
}
  .is-pulled-right {
    align-items: right;
  }
</style>
