export default {
  methods: {
    s2ab(input) {
      const buffer = new ArrayBuffer(input.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i !== input.length; i++) {
        view[i] = input.charCodeAt(i) & 0xFF;
      }
      return buffer;
    },
    downloadCsvFrom(data) {
      const url = window.URL.createObjectURL(new Blob([data], {type: 'text/csv'}));
      this.downloadUrl(url);
    },
    downloadXlsxFrom(data) {
      const url = window.URL.createObjectURL(new Blob([this.s2ab(atob(data))],
          {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'}));
      this.downloadUrl(url);
    },
    downloadUrl(url) {
      const a = document.createElement('a');
      a.href = url;
      a.download = `cart.${this.exportFormat}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }
};
