// Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)

/**
  @param {Function} func - The function will be called after it stops being called for
  N milliseconds.
  @param {Integer} wait - N milliseconds
  @param {Boolean} immediate - If `immediate` is passed, trigger the function on the
  leading edge, instead of the trailing.
  @return {Function} - a function, that, as long as it continues to be invoked, will not
  be triggered.
*/
function debounce (func, wait, immediate) {
  let timeout

  // This is the function that is actually executed when
  // the DOM event is triggered.
  return function executedFunction (...args) {
    // Store the context of this and any
    // parameters passed to executedFunction

    // The function to be called after
    // the debounce time has elapsed
    const later = function () {
      // null timeout to indicate the debounce ended
      timeout = null

      // Call function now if you did not on the leading end
      if (!immediate) func(...args)
    }

    // Determine if you should call the function
    // on the leading or trail end
    const callNow = immediate && !timeout

    // This will reset the waiting every function execution.
    // This is the step that prevents the function from
    // being executed because it will never reach the
    // inside of the previous setTimeout
    clearTimeout(timeout)

    // Restart the debounce waiting period.
    // setTimeout returns a truthy value (it differs in web vs node)
    timeout = setTimeout(later, wait)

    // Call immediately if you're dong a leading
    // end execution
    if (callNow) func(...args)
  }
}

/*
  Vue mixin that adds support for debounced methods.
  Injects itself before the owning components created lifecycle hook and replaces
  the marked methods with debounced versions of themselves.

  Can either set a number of milliseconds to wait, or an object {wait, immediate};
  using the same parameters that the function defined above uses.
*/
export default {
  created () {
    const debounceMethods = this.$options.debounceMethods
    if (debounceMethods) {
      Object.keys(debounceMethods).forEach((methodName) => {
        const baseFunction = this[methodName]
        const debouncedMethod = (...args) => baseFunction.apply(this, args)
        const configData = debounceMethods[methodName]

        if (typeof configData === 'number') {
          this[methodName] = debounce(debouncedMethod, configData)
        } else if (typeof configData === 'object') {
          const wait = configData.wait || 1000
          const immediate = configData.immediate || false
          this[methodName] = debounce(debouncedMethod, wait, immediate)
        } else {
          const err = { error: 'invalid configuration for debounce methods', config: configData }
          throw err
        }
      })
    }
  }
}
