export function agreeToCookieUse () {
  document.cookie = "cookie_acceptance_cookie=true; SameSite=None; Secure; Path=/; Partitioned;";
}

export function clearCookieAcceptance () {
  document.cookie = "cookie_acceptance_cookie=; SameSite=None; Secure; Path=/; Partitioned; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
  document.cookie = "cookie_acceptance_cookie=; SameSite=None; Secure; Path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;"
}

function checkCookie () {
  let cookieEnabled = navigator.cookieEnabled;

  if (!cookieEnabled) {
    document.cookie = "docuCookie=; SameSite=None; Secure; Path=/; Partitioned;";
    cookieEnabled = document.cookie.indexOf("docuCookie=") !== -1;
    document.cookie = "docuCookie=; SameSite=None; Secure; Path=/; Partitioned; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  }

  return cookieEnabled;
}

function checkLocalStorage () {
  const doc = 'docuLS'
  try {
    localStorage.setItem(doc, doc)
    localStorage.removeItem(doc)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Use for non-widgets only (ie accessing documoto directly)
 */
export function checkForCookieAndLocalStorage () {
  if (!checkCookie() || !checkLocalStorage()) {
    console.log('Check failed!!!!')
    window.location.replace('/Portal/htmlErrors/cookieLocalStorageRequired.html')
    throw new Error('Redirecting to cookie error page')
  }
}
