export const DATA_ERROR = 'DATA_ERROR';
export const DATA_LOADING = 'DATA_LOADING';
export const DATA_RESET = 'DATA_RESET';
export const DATA_SUCCESS = 'DATA_SUCCESS';
export const DATA_REQUEST = 'DATA_REQUEST';

export const UPDATE_ERROR = 'UPDATE_ERROR';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';

export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_LOADING = 'DELETE_LOADING';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';

export const CREATE_ERROR = 'CREATE_ERROR';
export const CREATE_LOADING = 'CREATE_LOADING';
export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';

export const SET_PAGINATION = 'SET_PAGINATION';
export const RESET_PAGINATION = 'RESET_PAGINATION';
export const SET_REF_ID = 'SET_REF_ID';
