<template>
  <b-dropdown   aria-role="list" 
                :close-on-click="false">
    <template #trigger>
        <b-tooltip  position="is-bottom"
                    :label="$t('editItem', { item: $tc('column', 2) })">
            <b-button icon-left="columns"/>
        </b-tooltip>
    </template>
    <b-dropdown-item    v-for="(col, i) in columns"
                        aria-role="listitem"
                        :key="i"
                        @click="$emit('updateColumn', col.field)">
        <div style="display: flex; justify-content: space-between">
            <span>{{ col.translation }}</span>
            <b-icon v-if="!col.isHidden" 
                    icon="check"/>    
        </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
    name: 'ColumnsFilter',
    props: {
        columns: {
            type: Array,
            default: () => []
        }
    }
}
</script>