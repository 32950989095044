<template>
  <b-modal v-model="isActive"
           has-modal-card
           trap-focus
           :can-cancel="canCancel"
           @close="close"
           @keydown.enter="disableKeys ? '' : $emit('submit')">
    <div class="modal-card"
         :style="{'width': width }"
         :data-unit="dataUnit">
      <section class="modal-card-body">
        <div class="is-full-width">
          <button v-if="!hideX"
                  class="is-pulled-right delete"
                  aria-label="close"
                  :disabled="isLoading"
                  @click.exact="close"/>
          <span class="title-five is-flex align-center">
            <slot name="title" />
          </span>
          <br>
          <slot name="content" />
        </div>
      </section>
      <slot name="footer">
        <footer class="modal-card-foot">
          <b-button v-if="!hideDiscard"
                    :disabled="isLoading"
                    :type="discardOptions.type"
                    data-unit="discard-trigger"
                    @click.exact="$emit('discard')">
            {{ translate(discardOptions.label) }}
          </b-button>
        <div :style="{flex: '1 0 0'}" />
        <b-button v-if="!hideClose"
                  data-unit="close-trigger"
                  :disabled="isLoading"
                  :type="closeOptions.type"
                  @click.exact="close">
            {{ translate(closeOptions.label) }}
          </b-button>
        <b-button v-bind="submitOptions.bind || {}"
                  v-if="!hideSubmit"
                  data-unit="submit-trigger"
                  :disabled="isLoading || !canSubmit"
                  :loading="isLoading"
                  :type="submitOptions.type"
                  @click.exact="$emit('submit')">
            {{ translate(submitOptions.label) }}
          </b-button>
        </footer>
      </slot>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    canCancel: {
      type: [Array, Boolean],
      default () {
        return ['escape', 'x', 'outside']
      }
    },
    canSubmit: {
      type: Boolean,
      default: true
    },
    closeOptions: {
      type: Object,
      default: () => ({
        label: 'cancel',
        type: ''
      })
    },
    discardOptions: {
      type: Object,
      default: () => ({
        label: 'discard',
        type: 'is-danger is-light'
      })
    },
    disableKeys: Boolean,
    hideDiscard: Boolean,
    hideClose: Boolean,
    hideSubmit: Boolean,
    hideX: Boolean,
    isLoading: Boolean,
    isWider: Boolean,
    isFullScreen: Boolean,
    submitOptions: {
      type: Object,
      default: () => ({
        label: 'save',
        type: 'is-success'
      })
    },
    dataUnit: {
      type: String,
      default: 'modal'
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    width() {
      if (this.isFullScreen) return '1000px'
      else return (this.isWider) ? '750px' : null
    }
  },
  methods: {
    open () {
      this.$emit('open')
      this.isActive = true
    },
    close () {
      this.$emit('close')
      this.isActive = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/abstracts/_breakpoints.scss";
.text-wrapper {
  @include bulma-breakpoint('mobile') {
    max-width: 90%;
  }
  @include bulma-breakpoint('tablet') {
    max-width: 75%;
  }
}

.modal-card-body {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.is-flex > span {
  display: flex;
}
</style>
