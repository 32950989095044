<template>
  <div class="icon is-small">
    <i :class="iconClass" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconClass() {
      return {
        'fas': true,
        'fa-times': !this.value,
        'fa-check': this.value,
        'fa-inverse': this.selected,
        'has-text-danger': !this.value && !this.selected,
        'has-text-success': this.value && !this.selected
      };
    }
  }
};
</script>
