<template>
  <article class="media">
    <div class="media-content">
      <div v-if="!editing"
           class="content">
        <p>
          <strong>{{ comment.displayName }}</strong>
        </p>
        <p class="text">{{ comment.text }}</p>
      </div>
      <div v-else>
        <p><strong>{{ $t('lastUpdatedOn') + ': ' + createdDate }}</strong></p>
        <p class="text">{{ $t('createdBy', { name: comment.displayName }) }}</p>
      </div>
      <slot/>
      <nav v-if="!editing"
           class="level is-mobile">
        <div class="level-left">
          <a v-if="canEditOrRemove"
             data-int="comment-edit-button"
             class="level-item comment-edit-button"
             @click="edit">
            <span class="icon">
              <i class="fa fa-lg fa-edit"/>
            </span>
          </a>
          <a v-if="canEditOrRemove"
             data-int="comment-remove-button"
             class="level-item has-text-danger comment-remove-button"
             @click="remove">
            <span class="icon">
              <i class="fa fa-lg fa-trash-alt"/>
            </span>
          </a>
        </div>
      </nav>
    </div>
    <div v-if="!editing"
         class="media-right">
      {{ $d(comment.timestamp, 'short') }}
    </div>
  </article>
</template>

<script>
import { format } from "date-fns"
export default {
  name: 'CommentsItem',
  props: {
    comment: {
      type: Object,
      required: true
    },
    username: {
      type: String,
      required: true
    },
    canAddAndEditComments: {
      type: Boolean,
      default: false
    },
    canEditAllComments: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canEditOrRemove() {
      if (this.canEditAllComments) {
        return this.canEditAllComments;
      } else if (this.comment.username === this.username) {
        return this.canAddAndEditComments;
      }
      return false;
    },
    createdDate () {
      const date =  new Date(this.comment.timestamp)
      return format(date, 'MM/DD/YYYY')
    }
  },
  methods: {
    edit() {
      this.$emit('edit', this.comment);
    },
    remove() {
      this.$emit('remove', this.comment);
    }
  }
};
</script>

<style scoped>
.text {
  white-space: pre-wrap
}
</style>
