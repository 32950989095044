export default [
  {
    path: '/admin',
    component: () => import('@/components/admin/AdminPageWrapper'),
    meta: { requiresAuth: true },
    children: [
      {
        name: 'Admin',
        path: '',
        redirect: { name: 'AdminDashboard' }
      },
      {
        name: 'AdminDashboard',
        path: 'dashboard',
        component: () => import('@/components/admin/admin-home-page/AdminHomePage')
      },
      {
        path: 'content',
        component: () => import('@/components/admin/content/ContentPageWrapper'),
        children: [
          {
            name: 'ContentManager',
            path: '',
            redirect: { name: 'PartsManager' }
          },
          {
            name: 'PartsManager',
            path: 'parts',
            component: () => import('@/components/admin/content/PartsManager'),
            meta: {
              label () {
                return this.$tc('part', 2)
              }
            }
          },
          {
            name: 'PagesManager',
            path: 'pages',
            component: () => import('@/components/admin/content/PagesManager'),
            meta: {
              label () {
                return this.$tc('page', 2)
              }
            }
          },
          {
            name: 'MediasManager',
            path: 'medias',
            component: () => import('@/components/admin/content/MediasManager'),
            meta: {
              label () {
                return this.$tc('media', 2)
              }
            }
          },
          {
            name: 'TemplatesManager',
            path: 'templates',
            props: { title: 'Templates', template: true },
            component: () => import('@/components/admin/content/MediasManager'),
            meta: {
              label () {
                return this.$tc('template', 2)
              }
            }
          },
          {
            name: 'MediaCategoryEditNew',
            path: 'media-categories/new',
            component: () => import('@/components/admin/content/editor/MediaCategoryEditor'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'MediaCategoryEditId',
            path: 'media-categories/:id',
            component: () => import('@/components/admin/content/editor/MediaCategoryEditor'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'MediaCategoriesManager',
            path: 'media-categories',
            component: () => import('@/components/admin/content/MediaCategoriesManager'),
            meta: {
              label () {
                return this.$tc('mediaCategory', 2)
              }
            }
          },
          {
            name: 'PartCodesManager',
            path: 'part-codes',
            component: () => import('@/components/admin/content/PartCodesManager'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'DefaultImagesManager',
            path: 'default-images',
            component: () => import('@/components/admin/content/DefaultImagesManager'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'ProcessContent',
            path: 'process-content',
            component: () => import('@/components/admin/content/ProcessContent')
          },
          {
            name: 'TenantPrintSettings',
            path: 'print-settings/tenant',
            meta: {
              label () {
                return this.$t('printSettings')
              }
            },
            component: () => import('@/components/admin/content/print-settings/PrintSettings')
          }
        ]
      },
      {
        name: 'TenantPrintSettingsEditor',
        path: 'print-settings/tenant/editor/:id',
        component: () => import('@/components/admin/content/print-settings/PrintSettingsEditor'),
        meta: { saveRevertEnabled: true },
        redirect: {
          name: 'TenantPrintSettingsInfo'
        },
        children: [
          {
            path: 'info',
            name: 'TenantPrintSettingsInfo',
            component: () => import('@/components/admin/content/print-settings/components/PrintSettingInfo.vue'),
            meta: { saveRevertEnabled: true }
          },
          {
            path: 'header-footer',
            name: 'TenantPrintSettingsHeaderFooter',
            meta: { saveRevertEnabled: true },
            component: () => import('@/components/admin/content/print-settings/components/layout-editor/LayoutEditor.vue')
          },
          {
            path: 'layout',
            name: 'TenantPrintSettingsLayout',
            meta: { saveRevertEnabled: true },
            component: () => import('@/components/admin/content/print-settings/components/PrintSettingLayout.vue')
          },
          {
            path: 'parts-pages',
            name: 'TenantPrintSettingsPartsPages',
            meta: { saveRevertEnabled: true },
            component: () => import('@/components/admin/content/print-settings/components/PrintSettingPartsPages.vue')
          }
        ]
      },
      {
        name: 'TenantPrintSettingsCreator',
        path: 'print-settings/tenant/new',
        meta: { saveRevertEnabled: true },
        component: () => import('@/components/admin/content/print-settings/PrintSettingsCreator')
      },
      {
        name: 'PageCreator',
        path: 'pages/new',
        component: () => import('@/components/admin/content/editor/page/PageCreator'),
        meta: { saveRevertEnabled: true }
      },
      {
        path: 'pages/:id',
        component: () => import('@/components/admin/content/editor/page/PageWrapper'),
        meta: {
          title: '...'
        },
        children: [
          {
            name: 'PageEditor',
            path: 'info',
            component: () => import('@/components/admin/content/editor/page/PageEditor'),
            meta: {
              saveRevertEnabled: true,
              label () {
                return this.$tc('page', 2)
              }
            }
          },
          {
            name: 'PageEditorComments',
            path: 'comments',
            component: () => import('@/components/admin/content/editor/comments-editor/CommentsEditor'),
            meta: { type: 'page'}
          },
          {
            name: 'PageEditorAttachments',
            path: 'attachments',
            component: () => import('@/components/admin/content/editor/page/PageAttachments')
          },
          {
            name: 'PageEditorRelated',
            path: 'related',
            component: () => import('@/components/admin/content/editor/VirtualRelatedEditor')
          },
          {
            name: 'PageBulkInsert',
            path: 'bulk-insert',
            component: () => import('@/components/admin/content/editor/page/PageBulkInsert.vue')
          },
          {
            name: 'PageIllustration',
            path: 'illustration',
            meta: { saveRevertEnabled: true },
            component: () => import('@/components/admin/content/editor/page/page-illustration/PageIllustration')
          },
          {
            name: 'PageWhereUsed',
            path: 'where-used',
            component: () => import('@/components/admin/content/editor/page/VirtualPageWhereUsed')
          },
          {
            name: 'PageExport',
            path: 'export',
            component: () => import('@/components/admin/content/editor/page/PageExport')
          }
        ]
      },
      {
        name: 'DraftPages',
        path: 'pages/:id/draft-pages',
        component: () => import('@/components/admin/content/editor/page/draft-page/DraftPageWrapper'),
        meta: {
          title: '...'
        },
        redirect: {
          name: 'BomPageEditor'
        },
        children: [
          {
            name: 'BomPageEditor',
            path: 'bom-editor',
            component: () => import('@/components/admin/content/editor/page/draft-page/bom-tab/DraftBomEditor'),
            meta: { saveRevertEnabled: true, disableVuexDirtyManagement: true }
          },
          {
            name: 'ImageUploadEditor',
            path: 'image-upload',
            component: () => import('@/components/admin/content/editor/page/draft-page/illustration-tab/ImageUploadEditor'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'HotpointEditor',
            path: 'hotpoint-editor',
            component: () => import('@/components/admin/content/editor/page/draft-page/hotpointsTab/HotpointsEditor'),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        name: 'PartCreator',
        path: 'parts/new',
        component: () => import('@/components/admin/content/editor/part/PartCreator'),
        meta: { saveRevertEnabled: true }
      },
      {
        name: 'Part',
        path: 'part',
        component: () => import('@/components/admin/content/editor/part/Part.vue'),
        meta: { wrapperHeaderDisabled: true },
        children: [
          {
            name: 'PartSupersede',
            path: 'supersede/:id',
            component: () => import('@/components/admin/content/editor/part/PartSupersede.vue'),
            meta: { wrapperHeaderDisabled: true }
          },
          {
            name: 'PartOrderSuggestions',
            path: 'order-suggestions/:id',
            component: () => import('@/components/admin/content/editor/part/part-order-suggestions/PartOrderSuggestions'),
            meta: { wrapperHeaderDisabled: true },
          },
        ]
      },
      {
        name: 'Part3dTools',
        path: 'parts/:id/three-d-tools',
        component: () => import('@/components/admin/content/editor/part/three-d/Part3dViewer.vue'),
        meta: { wrapperHeaderDisabled: true },
      },
      {
        path: 'parts/:id',
        component: () => import('@/components/admin/content/editor/part/PartWrapper'),
        meta: {
          title: '...'
        },
        children: [
          {
            name: 'PartEditor',
            path: 'info',
            component: () => import('@/components/admin/content/editor/part/PartEditor'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'PartEditorComments',
            path: 'comments',
            component: () => import('@/components/admin/content/editor/comments-editor/CommentsEditor'),
            meta: { type: 'part'}
          },
          {
            name: 'PartEditorAttachments',
            path: 'attachments',
            component: () => import('@/components/admin/content/editor/part/PartAttachments')
          },
          {
            name: 'PartEditorRelated',
            path: 'related',
            component: () => import('@/components/admin/content/editor/VirtualRelatedEditor')
          },
          {
            name: 'PartPageWhereUsed',
            path: 'where-used-pages',
            component: () => import('@/components/admin/content/editor/part/VirtualPartPageWhereUsed')
          },
          {
            name: 'PartMediaWhereUsed',
            path: 'where-used-medias',
            component: () => import('@/components/admin/content/editor/part/VirtualPartMediaWhereUsed')
          },
          {
            name: 'AssemblyCreator',
            path: 'assembly/new',
            component: () => import('@/components/admin/content/editor/part/AssemblyCreator')
          },
          {
            name: 'PartPricing',
            path: 'pricing',
            component: () => import('@/components/admin/content/editor/part/PartPricing')
          }
        ]
      },
      {
        name: 'ProcessToDraft',
        path: 'content/process-to-draft',
        component: () => import('@/components/admin/content/ProcessToDraft'),
        meta: { wrapperHeaderDisabled: true }
      },
      {
        name: 'MediaCreator',
        path: 'medias/new',
        component: () => import('@/components/admin/content/editor/media/MediaCreator'),
        meta: { saveRevertEnabled: true }
      },
      {
        name: 'Theme',
        path: 'theme',
        component: () => import('@/components/admin/Theme.vue'),
        meta: { saveRevertEnabled: true }
      },
      {
        path: 'medias/:id',
        component: () => import('@/components/admin/content/editor/media/MediaWrapper'),
        meta: {
          title: '...'
        },
        children: [
          {
            name: 'MediaEditor',
            path: 'info',
            component: () => import('@/components/admin/content/editor/media/MediaEditor'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'MediaEditorComments',
            path: 'comments',
            component: () => import('@/components/admin/content/editor/comments-editor/CommentsEditor'),
            meta: { type: 'media'}
          },
          {
            name: 'MediaEditorAttachments',
            path: 'attachments',
            component: () => import('@/components/admin/content/editor/media/MediaAttachments')
          },
          {
            name: 'MediaEditorRelated',
            path: 'related',
            component: () => import('@/components/admin/content/editor/VirtualRelatedEditor')
          },
          {
            name: 'MediaEditorToc',
            path: 'toc',
            component: () => import('@/components/admin/content/editor/toc/TocEditor'),
            meta: { saveRevertEnabled: true, disableVuexDirtyManagement: true }
          },
          {
            name: 'MediaEditorAcl',
            path: 'acl',
            component: () => import('@/components/admin/content/editor/media/MediaAccessControls'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'MediaEditorPrintSettings',
            path: 'print-settings',
            component: () => import('@/components/admin/content/editor/media/MediaPrintSettings')
          }
        ]
      },
      {
        name: 'TemplateCreator',
        path: 'templates/new',
        component: () => import('@/components/admin/content/editor/media/MediaCreator'),
        props: { template: true },
        meta: { saveRevertEnabled: true }
      },
      {
        path: 'templates/:id',
        component: () => import('@/components/admin/content/editor/media/MediaWrapper'),
        props: { template: true },
        meta: {
          title: '...'
        },
        children: [
          {
            name: 'TemplateEditor',
            path: 'info',
            component: () => import('@/components/admin/content/editor/media/MediaEditor'),
            props: { template: true },
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'TemplateEditorComments',
            path: 'comments',
            component: () => import('@/components/admin/content/editor/comments-editor/CommentsEditor'),
            meta: { type: 'media'}
          },
          {
            name: 'TemplateEditorAttachments',
            path: 'attachments',
            component: () => import('@/components/admin/content/editor/media/MediaAttachments')
          },
          {
            name: 'TemplateEditorRelated',
            path: 'related',
            component: () => import('@/components/admin/content/editor/VirtualRelatedEditor')
          },
          {
            name: 'TemplateEditorToc',
            path: 'toc',
            component: () => import('@/components/admin/content/editor/toc/TocEditor'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'TemplateEditorAcl',
            path: 'acl',
            component: () => import('@/components/admin/content/editor/media/MediaAccessControls'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'TemplateEditorPrintSettings',
            path: 'print-settings',
            component: () => import('@/components/admin/content/editor/media/MediaPrintSettings')
          }
        ]
      },
      {
        path: 'user/new',
        name: 'UserCreator',
        component: () => import('@/components/admin/users/UserCreator'),
        meta: { saveRevertEnabled: true }
      },
      {
        path: 'user/:id',
        component: () => import('@/components/admin/users/UserEditor'),
        children: [
          {
            path: 'info',
            name: 'UserEditorInfo',
            component: () => import('@/components/admin/users/components/UserInfoEditor'),
            meta: { saveRevertEnabled: true }
          },
          {
            path: 'addresses',
            name: 'UserEditorAddresses',
            component: () => import('@/components/admin/users/components/UserEditorAddresses')
          },
          {
            path: 'groups',
            name: 'UserEditorGroups',
            component: () => import('@/components/admin/users/components/UserEditorGroups'),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        path: 'users',
        name: 'UsersManager',
        component: () => import('@/components/admin/users/UsersManager'
        ),
        children: [
          {
            path: 'users-table',
            name: 'UsersTable',
            component: () => import('@/components/admin/users/components/UsersTable'),
            meta: {
              label () {
                return this.$t('users')
              }
            }
          },
          {
            path: 'user-groups',
            name: 'UserGroups',
            component: () => import('@/components/admin/users/components/UserGroups'),
            meta: {
              label () {
                return this.$tc('userGroup', 2)
              }
            }
          },
          {
            path: 'organizations',
            name: 'UserOrganizations',
            component: () => import('@/components/admin/users/components/UserOrganizations'),
            meta: {
              label () {
                return this.$tc('organization', 2)
              }
            }
          },
          {
            path: 'organizations/rearrange',
            name: 'UserOrganizationsRearrange',
            component: () => import('@/components/admin/users/components/UserOrganizationsRearrange'
            ),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        path: 'user-groups/new',
        component: () => import('@/components/admin/users/components/groups/UserGroupWrapper'),
        children: [
          {
            name: 'UserGroupCreator',
            path: '',
            component: () => import('@/components/admin/users/components/groups/UserGroupInfo'),
            meta: { saveRevertEnabled: true },
            props: { isNew: true }
          }
        ]
      },
      {
        path: 'user-groups/widgets/new',
        component: () => import('@/components/admin/users/components/groups/UserGroupWrapper'),
        children: [
          {
            name: 'WidgetUserGroupCreator',
            path: '',
            component: () => import('@/components/admin/users/components/groups/WidgetUgInfo'),
            meta: { saveRevertEnabled: true },
            props: { isNew: true }
          }
        ]
      },
      {
        path: 'user-groups/widgets/:id',
        component: () => import('@/components/admin/users/components/groups/UserGroupWrapper'),
        children: [
          {
            name: 'WidgetUgInfo',
            path: 'info',
            component: () => import('@/components/admin/users/components/groups/WidgetUgInfo'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'WidgetUgMediaCategories',
            path: 'media-categories',
            component: () => import('@/components/admin/users/components/groups/UserGroupMediaCategories'),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        path: 'user-groups/:id',
        component: () => import('@/components/admin/users/components/groups/UserGroupWrapper'),
        children: [
          {
            name: 'UserGroupInfo',
            path: 'info',
            component: () => import('@/components/admin/users/components/groups/UserGroupInfo'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'UserGroupUsers',
            path: 'users',
            component: () => import('@/components/admin/users/components/groups/UserGroupUsers')
          },
          {
            name: 'UserGroupMediaCategories',
            path: 'media-categories',
            component: () => import('@/components/admin/users/components/groups/UserGroupMediaCategories'),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        path: 'organizations/new',
        component: () => import('@/components/admin/users/components/organizations/OrganizationCreator'),
        children: [
          {
            name: 'OrganizationCreator',
            path: '',
            component: () => import('@/components/admin/users/components/organizations/OrganizationInfo'),
            meta: { saveRevertEnabled: true },
            props: { isNew: true }
          }
        ]
      },
      {
        path: 'organizations/:id',
        component: () => import('@/components/admin/users/components/organizations/OrganizationWrapper'
        ),
        children: [
          {
            name: 'OrganizationInfo',
            path: 'info',
            component: () => import('@/components/admin/users/components/organizations/OrganizationInfo'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'OrganizationAccess',
            path: 'access',
            component: () => import('@/components/admin/users/components/organizations/OrganizationAccess'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'OrganizationUsers',
            path: 'users',
            component: () => import('@/components/admin/users/components/organizations/OrganizationUsers')
          },
          {
            name: 'OrganizationReports',
            path: 'reports',
            component: () => import('@/components/admin/users/components/organizations/OrganizationReports'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'OrganizationAddresses',
            path: 'addresses',
            component: () => import('@/components/admin/users/components/organizations/OrganizationAddresses')
          },
          {
            name: 'OrganizationPrintSettings',
            path: 'print-settings',
            component: () => import('@/components/admin/users/components/organizations/OrganizationPrintSettings')
          },
          {
            name: 'OrganizationLibrarySettings',
            path: 'settings',
            component: () => import('@/components/admin/users/components/organizations/OrganizationLibrarySettings'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'OrganizationOrderForms',
            path: 'order-forms',
            component: () => import('@/components/admin/users/components/organizations/OrganizationOrderForms'),
            meta: {saveRevertEnabled: false }
          },
          {
            name: 'OrganizationStorefrontIntegrations',
            path: 'integrations',
            component: () => import('@/components/admin/storefront/OrganizationStorefrontIntegrations'),
            meta: { saveRevertEnabled: true, canSave: true }
          },
          {
            name: 'OrganizationStorefrontSettings',
            path: 'storefront-settings',
            component: () => import('@/components/admin/users/components/organizations/OrganizationStorefrontSettings'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'OrganizationPartsListColumns',
            path: 'parts-list-columns',
            component: () => import('@/components/admin/users/components/organizations/OrganizationPartsListColumns'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'OrganizationCartColumns',
            path: 'cart-columns',
            component: () => import('@/components/admin/users/components/organizations/OrganizationCartColumns'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'OrganizationPlaceholder',
            path: 'placeholder',
            component: () => import('@/components/admin/PlaceholderPage')
          },
          {
            name: 'OrganizationBrowseFlow',
            path: 'browse-flow',
            component: () => import('@/components/admin/users/components/organizations/OrganizationBrowseFlow')
          },
          {
            name: 'OrganizationEmail',
            path: 'email',
            component: () => import('@/components/admin/users/components/organizations/OrganizationEmail'),
            meta: {
              label () {
                return this.$tc('orderStatus', 2)
              }
            }
          },
          {
            name: 'OrganizationEmailDetail',
            path: 'detail/:statusId',
            component: () => import('@/components/admin/users/components/organizations/OrganizationEmailDetail'),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        path: 'site',
        component: () => import('@/components/admin/site/SitePageWrapper'),
        children: [
          {
            name: 'SiteManager',
            path: '',
            redirect: { name: 'TagsManager' }
          },
          {
            name: 'TagsManager',
            path: 'tags',
            component: () => import('@/components/admin/site/TagsManager'),
            meta: {
              label () {
                return this.$tc('tag', 2)
              }
            }
          },
          {
            name: 'TenantLabelsEditor',
            path: 'tenant-labels-editor',
            component: () => import('@/components/admin/site/TenantLabelsEditor'),
            meta: {
              label () {
                return this.$tc('label', 2)
              }
            }
          },
          {
            name: 'TenantLabelDetail',
            path: 'tenant-label-detail/:id?',
            component: () => import('@/components/admin/site/TenantLabelDetail'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'NewsItemsManager',
            path: 'news-items',
            component: () => import('@/components/admin/site/NewsItemsManager'),
            meta: {
              label () {
                return this.$tc('newsItem', 2)
              }
            }
          },
          {
            name: 'BrowseFlowManager',
            path: 'browse-flow',
            component: () => import('@/components/admin/site/BrowseFlowIFrame')
          },
          {
            name: 'BrowseFlowsManager',
            path: 'browse-flows',
            component: () => import('@/components/admin/site/BrowseFlowManager'),
            meta: {
              saveRevertEnabled: true,
              label () {
                return this.$tc('browseFlow', 2)
              }
            }
          },
          {
            name: 'TenantPartsListColumns',
            path: 'parts-list-columns',
            component: () => import('@/components/admin/site/TenantPartsListColumns'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'LibrarySettings',
            path: 'settings',
            component: () => import('@/components/admin/site/LibrarySettings'),
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'SearchSettings',
            path: 'search-settings',
            component: () => import('@/components/admin/site/SearchSettings'),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        name: 'BrowseFlowCreator',
        path: 'browse-flows/new',
        component: () => import('@/components/admin/site/editor/browse-flow/BrowseFlowCreator'),
        meta: {
          saveRevertEnabled: true,
          label () {
            return this.$tc('browseFlow', 2)
          }
        }
      },
      {
        name: 'BrowseFlowNavigation',
        path: 'browse-flows/navigation/:id?',
        component: () => import('@/components/admin/site/editor/browse-flow/BrowseFlowNavigation'),
        meta: {
          saveRevertEnabled: true,
          label () {
            return this.$tc('browseFlow', 2)
          }
        }
      },
      {
        path: 'browse-flows/:id',
        component: () => import('@/components/admin/site/editor/browse-flow/BrowseFlowWrapper'),
        children: [
          {
            name: 'BrowseFlowEditor',
            path: '',
            component: () => import('@/components/admin/site/editor/browse-flow/BrowseFlowEditor'),
            meta: {
              saveRevertEnabled: true,
              label () {
                return this.$tc('browseFlow', 2)
              }
            }
          }
        ]
      },
      {
        name: 'TagCreator',
        path: 'tags/new',
        component: () => import('@/components/admin/site/editor/tag/TagCreator'),
        meta: { saveRevertEnabled: true }
      },
      {
        path: 'tags/:id',
        component: () => import('@/components/admin/site/editor/tag/TagWrapper'),
        children: [
          {
            name: 'TagEditor',
            path: '',
            component: () => import('@/components/admin/site/editor/tag/TagEditor'),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        path: 'news-item',
        component: () => import('@/components/admin/site/editor/news/NewsItemWrapper'),
        children: [
          {
            name: 'NewsItemCreator',
            path: 'new',
            component: () => import('@/components/admin/site/editor/news/NewsItemEditor'),
            props: { isNew: true },
            meta: { saveRevertEnabled: true }
          },
          {
            name: 'NewsItemEditor',
            path: ':id',
            component: () => import('@/components/admin/site/editor/news/NewsItemEditor'),
            meta: { saveRevertEnabled: true }
          }
        ]
      },
      {
        name: 'OrderEmailFormatCreate',
        path: 'storefront/order-emails/new',
        component: () => import('@/components/admin/storefront/order-email/OrderEmailFormatCreate'),
        meta: {
          wrapperHeaderDisabled: true,
          label () {
            return this.$tc('orderQuoteEmail', 2)
          }
        }
      },
      {
        name: 'OrderEmailFormatEditor',
        path: 'storefront/order-emails/:id',
        component: () => import('@/components/admin/storefront/order-email/OrderEmailFormatEditor'),
        meta: {
          wrapperHeaderDisabled: true,
          label () {
            return this.$tc('orderQuoteEmail', 2)
          }
        }
      },
      {
        name: 'OrderEmailFormatManager',
        path: 'storefront/order-email-formats',
        component: () => import('@/components/admin/storefront/order-email/OrderEmailFormatManager'),
        meta: {
          wrapperHeaderDisabled: true,
          label () {
            return this.$tc('orderQuoteEmail', 2)
          }
        }
      },
      {
        name: 'OrderFormDetailCreate',
        path: 'storefront/order-form-detail/new',
        component: () => import('@/components/admin/storefront/order-form/OrderFormDetailCreate'),
        meta: {
          wrapperHeaderDisabled: true,
          label () {
            return this.$tc('orderQuoteForm', 2)
          }
        }
      },
      {
        name: 'OrderFormDetailEditor',
        path: 'storefront/order-form-detail/:id',
        component: () => import('@/components/admin/storefront/order-form/OrderFormDetailEditor'),
        meta: {
          wrapperHeaderDisabled: true,
          label () {
            return this.$tc('orderQuoteForm', 2)
          }
        },
      },
      {
        name: 'OrderFormHeaderLayout',
        path: 'storefront/order-form-detail/:id/header',
        component: () => import('@/components/admin/storefront/order-form/OrderFormHeaderLayout'),
        meta: { wrapperHeaderDisabled: true }
      },
      {
        path: 'storefront',
        component: () => import('@/components/admin/storefront/StorefrontWrapper'),
        children: [
          {
            name: 'OrderFieldsManager',
            path: 'order-fields-manager',
            component: () => import('@/components/admin/storefront/order-fields/OrderFieldsManager.vue'),
            meta: {
              label () {
                return this.$tc('orderFormField', 2)
              }
            }
          },
          {
            name: 'OrderForms',
            path: 'order-forms',
            component: () => import('@/components/admin/storefront/order-form/OrderForms.vue'),
            meta: {
              label () {
                return this.$tc('orderQuoteForm', 2)
              }
            }
          },
          {
            name: 'OrderStatusManager',
            path: 'order-status-manager',
            component: () => import('@/components/admin/storefront/order-status/OrderStatusManager'),
            meta: {
              label () {
                return this.$tc('orderStatus', 2)
              }
            }
          },
          {
            name: 'OrderStatusDetail',
            path: 'order-status-detail/:id',
            component: () => import('@/components/admin/storefront/order-status/OrderStatusDetail'),
            meta: {
              saveRevertEnabled: true
            }
          },
          {
            name: 'OrderStatusCreator',
            path: 'order-status-creator',
            component: () => import('@/components/admin/storefront/order-status/OrderStatusCreator'),
            meta: {
              saveRevertEnabled: true
            }
          },
          {
            name: 'OrderTemplateEditor',
            path: 'order-template/:id',
            component: () => import('@/components/admin/storefront/order-template/OrderTemplateEditor'),
            meta: {
              saveRevertEnabled: true
            }
          },
          {
            name: 'TenantStorefrontIntegrations',
            path: 'integrations',
            component: () => import('@/components/admin/storefront/TenantStorefrontIntegrations'),
            meta: {
              saveRevertEnabled: true
            }
          },
          {
            name: 'StorefrontSettings',
            path: 'settings',
            component: () => import('@/components/admin/storefront/StorefrontSettings'),
            meta: {
              saveRevertEnabled: true
            }
          },
          {
            name: 'AdminSharedCartsManager',
            path: 'shared-carts',
            component: () => import('@/components/admin/storefront/shared-cart/AdminSharedCartsManager'),
            meta: {
              label () {
                return this.$tc('sharedCart', 2)
              }
            }
          },
          {
            name: 'TenantCartColumns',
            path: 'cart-columns',
            component: () => import('@/components/admin/storefront/TenantCartColumns'),
            meta: {
              saveRevertEnabled: true
            }
          }
        ]
      },
      {
        name: 'AdminSharedCartCreator',
        path: 'shared-carts/new',
        component: () => import('@/components/admin/storefront/shared-cart/AdminSharedCartEditor'),
        meta: { saveRevertEnabled: true }
      },
      {
        name: 'AdminSharedCartEditor',
        path: 'shared-carts/:id',
        component: () => import('@/components/admin/storefront/shared-cart/AdminSharedCartEditor'),
        meta: { saveRevertEnabled: true }
      },

      {
        name: 'OrderFieldCreator',
        path: 'order-field-creator',
        component: () => import('@/components/admin/storefront/order-fields/OrderFieldDetail'),
        meta: { saveRevertEnabled: true }
      },
      {
        name: 'OrderFieldDetail',
        path: 'order-field-detail/:id',
        component: () => import('@/components/admin/storefront/order-fields/OrderFieldDetail'),
        meta: { saveRevertEnabled: true }
      },
      {
        name: 'OrganizationStyles',
        path: 'organizations/:id/styles',
        component: () => import('@/components/admin/users/components/organizations/OrganizationStyles'),
        meta: { wrapperHeaderDisabled: true }
      },
      {
        path: 'bulletins',
        name: 'BulletinAdmin',
        component: () => import('@/components/admin/PlaceholderPage')
      },
      {
        path: 'system',
        component: () => import('@/components/admin/settings/System'
        ),
        children: [
          {
            name: 'System',
            path: '',
            redirect: { name: 'Settings' }
          },
          {
            path: 'settings',
            name: 'Settings',
            component: () => import('@/components/admin/settings/Settings'),
            meta: {
              label () {
                return this.$t('tenantSettingsTitle')
              },
              saveRevertEnabled: true
            }
          },
          {
            path: 'key-manager',
            name: 'APIKeyManager',
            component: () => import('@/components/admin/settings/api-keys/APIKeyManager'),
            meta: {
              label () {
                return this.$tc('apiKey', 2)
              }
            }
          },
          {
            path: 'key-manager/new',
            name: 'APIKeyDetailEditor',
            component: () => import('@/components/admin/settings/api-keys/APIKeyDetailEditor'),
            meta: { saveRevertEnabled: true }
          },
          {
            path: 'widget-key-manager',
            name: 'WidgetKeyManager',
            component: () => import('@/components/admin/settings/widget-keys/WidgetKeyManager'),
            meta: {
              label () {
                return this.$tc('widgetKey', 2)
              }
            }
          }
        ]
      },
      {
        path: 'system/widget-key-manager/new',
        name: 'WidgetKeyEditor',
        component: () => import('@/components/admin/settings/widget-keys/WidgetKeyEditor'),
        meta: { saveRevertEnabled: true,
          wrapperHeaderDisabled: true }
      },
      {
        path: 'settings',
        name: 'PreviousSystem',
        component: () => import('@/components/admin/settings/services/PreviousSystem'),
        meta: {
          label () {
            return this.$t('tenantSettingsTitle')
          },
          saveRevertEnabled: true
        }
      },
      {
        name: "Tenants",
        path: "tenants",
        component: () => import('@/components/admin/super-admin/tenants/Tenants.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "NewTenantWrapper",
        path: "tenants/new",
        component: () => import('@/components/admin/super-admin/tenants/tenant-info/NewTenantWrapper.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "TenantInfoWrapper",
        path: "tenants/:id/info",
        component: () => import('@/components/admin/super-admin/tenants/tenant-info/TenantInfoWrapper.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "SuperAdminSettings",
        path: "tenants/:id/settings",
        component: () => import('@/components/admin/super-admin/tenants/super-admin-settings/SuperAdminSettings.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "SuperAdminTenantSettings",
        path: "tenants/:id/tenant-settings",
        component: () => import('@/components/admin/super-admin/tenants/SideNavLayoutWrapper.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "SuperAdminStorefrontIntegrations",
        path: "tenants/:id/storefront-integrations",
        component: () => import('@/components/admin/super-admin/tenants/SideNavLayoutWrapper.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "SuperAdminCartColumn",
        path: "tenants/:id/cart-column",
        component: () => import('@/components/admin/super-admin/tenants/SideNavLayoutWrapper.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "SuperAdminStorefrontSettings",
        path: "tenants/:id/storefront-settings",
        component: () => import('@/components/admin/super-admin/tenants/SideNavLayoutWrapper.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "SuperAdminLibrarySettings",
        path: "tenants/:id/library-settings",
        component: () => import('@/components/admin/super-admin/tenants/SideNavLayoutWrapper.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: "SuperAdminApiKeyManager",
        path: "tenants/:id/key-settings",
        component: () => import('@/components/admin/super-admin/tenants/SideNavLayoutWrapper.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        path: 'tenants/:id/key-manager/new',
        name: 'SuperAdminAPIKeyDetailEditor',
        component: () => import('@/components/admin/super-admin/tenants/api-key-settings/SuperAdminAPIKeyDetailEditor.vue'),
        meta: {
          saveRevertEnabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: 'TenantStyles',
        path: 'site/site-styles',
        component: () => import('@/components/admin/site/styles/TenantStyles.vue'),
        meta: { wrapperHeaderDisabled: true },
      },
      {
        name: 'ErpSystems',
        path: 'erp-systems',
        component: () => import('@/components/admin/super-admin/erp/ErpSystems.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: 'NewErpEditor',
        path: 'erp-systems/new',
        component: () => import('@/components/admin/super-admin/erp/NewErpEditor.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        name: 'ErpDetailEditor',
        path: 'erp-systems/:id',
        component: () => import('@/components/admin/super-admin/erp/ErpDetailEditor.vue'),
        meta: {
          wrapperHeaderDisabled: true,
          requiresSuperAdmin: true
        }
      },
      {
        path: 'output-definitions',
        component: () => import('@/components/admin/super-admin/SuperAdminWrapper'),
        children: [{
          name: 'OutputDefinitionsManager',
          path: '',
          component: () => import('@/components/admin/super-admin/OutputDefinitions.vue'),
          meta: {
            saveRevertEnabled: false,
            label () {
              return this.$tc('outputDefinition', 2)
            }
          }
        },
        {
          name: 'AddOutputDefinitions',
          path: 'new',
          component: () => import('@/components/admin/super-admin/AddOutputDefinitions.vue'),
          meta: {
            saveRevertEnabled: true,
            label () {
              return this.$tc('outputDefinition', 2)
            }
          }
        },
        {
          name: 'EditOutputDefinitions',
          path: 'output-definition/:id',
          component: () => import('@/components/admin/super-admin/EditOutputDefinitions.vue'),
          meta: {
            saveRevertEnabled: true,
            label () {
              return this.$tc('outputDefinition', 2)
            }
          }
        }
        ]
      }
    ]
  }
]
