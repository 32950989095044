export const path = {
  path() {
    return this.$route.path
  }
}

export const tk = {
  tk() {
    return this.$route.query?.tk?.toUpperCase()
  }
}

export const query = {
  query() {
    return this.$route.query?.q ?? ''
  }
}