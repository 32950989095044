<template>
  <cart-table :items="items"
              :isLoaded="isLoaded"
              :canEdit="canEdit"
              :columns="columns"
              :hasPricing="hasPricing"
              :selected="selected"
              :totalRetailPrice="totalRetailPrice"
              :totalWholesalePrice="totalWholesalePrice"
              :totalDiscountedPrice="totalDiscountedPrice"
              @updateQuantity="updateQuantity"
              @removeFromCart="removeFromCart">
    <slot />
  </cart-table>
</template>

<script>
import { mapState, mapActions } from "vuex"
import CartTable from "@/components/library/cart/CartTable"
export default {
  name: "OrderPartTableWrapper",
  props: {
    canEdit: Boolean
  },
  components: { CartTable },
  computed: {
    ...mapState({
      items: ({ order }) => order.parts.items,
      isLoaded: () => true,
      columns: ({ order }) => order.partColumns,
      hasPricing: ({ order }) => order.hasPricing,
      totalRetailPrice: ({ order }) => order.parts.totalRetailPrice || '',
      totalWholesalePrice: ({ order }) => order.parts.totalWholesalePrice || '',
      totalDiscountedPrice: ({ order }) => order.parts.totalDiscountedPrice || ''
    })
  },
  methods: {
    ...mapActions({
      updateQuantity: 'order/updateOrderItemQuantity',
      removeFromCart: 'order/removeOrderItem'
    })
  }
}
</script>