<template>
  <div class="field">
    <label class="label">
      <span>{{ item.name }}</span>
      <span v-if="canSaveLocal"
           class="is-pulled-right">
        <label class="has-text-weight-normal mr-1">{{ $t('save') }}</label>
        <input v-model="saveLocal"
               type="checkbox" />
      </span>
    </label>
    <div v-if="canEdit"
         class="field has-addons p-0">
      <p class="control is-expanded">
      <span class="select is-fullwidth">
        <select v-model="selectedAddressRefKey"
                :class="{'is-danger': !isValid}"
                @change="onSelectAddressOption">
          <option :value="null">
            {{ $t('notSelected') }}
          </option>
          <option v-for="(address, index) in addressOptions"
                  :key="index"
                  :value="address.refKey">
            {{ toString(address) }}
          </option>
        </select>
      </span>
      </p>
      <p class="control flex-button">
        <button class="button"
                :disabled="isEditDisabled"
                @click="onAddressEdit">
        <span class="icon">
          <i class="fa fa-edit" />
        </span>
        </button>
      </p>
      <p class="control">
        <button class="button"
                :disabled="!(this.item.editable ?? false)"
                @click="onAddressAdd">
        <span class="icon">
          <i class="fa fa-plus-circle"/>
        </span>
        </button>
      </p>
    </div>
    <div class="address">
      <p v-if="selectedAddress" v-html="toParagraphString(selectedAddress)" />
    </div>
    <p v-if="!isValid" class="help is-danger is-marginless">
      {{ $t('invalidField', {field: item.name}) }}
    </p>
    <order-cell-address-modal v-if="editAddressModalOpen"
                              :title="item.name"
                              :address="isNewAddress ? null : selectedAddress"
                              :is-required="item.required ?? false"
                              @save="onCustomAddress"
                              @close="editAddressModalOpen = false" />
  </div>
</template>

<script>
import getSaveLocalState from '@/components/library/orders/cells/getSaveLocalState'
import OrderCellAddressModal from './OrderCellAddressModal'
import { v4 as uuid } from 'uuid'

export default {
  name: 'OrderCellAddress',
  components: {
    OrderCellAddressModal
  },
  props: {
    /**
     * There are three states today. This is used for visibility of the dropdown field,
     * edit button, and add button.
     *  - Purchase Orders > canEdit = true
     *  - Orders > canEdit = false
     *  - Orders + isOrderApprover + statusCode === 'OPEN' > canEdit = true
     *
     * The last state is a rare edge-case. When an order fails, the order would be open.
     * Order Approvers/Managers theoretically can edit order fields today. (Orders are
     * a messy design. :c)
     */
    canEdit: Boolean,
    canSaveLocal: Boolean,
    data: { // Overloaded value of field, including locally-stored address
      type: Object,
      default: () => {}
    },
    item: { // Field metadata
      type: Object,
      required: true
    }
  },
  computed: {
    isEditDisabled () {
      if (this.item.editable ?? false) {
        return !this.addressOptions.length || !this.selectedAddressRefKey
      } else {
        return true
      }
    },
    isValid () {
      if (this.item.required) {
        return !!this.selectedAddress
      }
      return true
    }
  },
  data () {
    return {
      editAddressModalOpen: false,
      isNewAddress: false, // Controls data passed to modal
      selectedAddress: null, // Address-object data
      selectedAddressRefKey: null // Value for dropdown menu
    }
  },
  watch: {
    saveLocal () {
      this.change()
    }
  },
  methods: {
    change () {
      this.$emit('change', {
        field: this.item,
        value: this.selectedAddress,
        updateLocal: this.saveLocal && this.isValid
      })
    },
    onAddressAdd () {
      this.isNewAddress = true
      this.editAddressModalOpen = true
    },
    onAddressEdit () {
      this.isNewAddress = false
      this.editAddressModalOpen = true
    },
    onCustomAddress(address) {
      this.isNewAddress = false
      this.selectedAddress = address
      this.selectedAddressRefKey = null
      this.change()
    },
    // Update displayed address below input field
    onSelectAddressOption () {
      this.selectedAddress = this.addressOptions.find(
        (item) => item.refKey === this.selectedAddressRefKey
      ) ?? null
      this.change()
    },
    toParagraphString(address) {
      let formatedAddress = `${address.line1}`
      if (address.line2) {
        formatedAddress += `<br>${address.line2}`
      }
      if (address.line3) {
        formatedAddress += `<br>${address.line3}`
      }
      formatedAddress += `<br>${address.city}, ${address.state} ${address.zipCode}`
      return formatedAddress
    },
    toString(address) {
      if (!address) {
        return ''
      }
      return  [address.line1, address.line2, address.line3, address.city + ',', address.state, address.zipCode].filter(Boolean).join(" ")
    }
  },
  mounted () {
    let initialAddressId = null

    // Locally-stored data takes higher priority than default
    if (!!this.data) {
      this.selectedAddress = this.data
      initialAddressId = this.data?.id ?? null
    } else if (!!this.item.defaultValue) {
      this.selectedAddress = this.item.defaultValue
      initialAddressId = this.item.defaultValue.id ?? null
    }

    if (!!initialAddressId) {
      this.selectedAddressRefKey = this.addressOptions.find(
        (item) => item.id === initialAddressId
      ).refKey
    }
  },
  setup (props) {
    const { saveLocal } = getSaveLocalState(props)
    /**
     * ERP addresses are externally-sourced and is missing ID.
     * Ref keys are the unique id when referencing most address options.
     */
    const addressOptions = props.item.options?.map(item => {
        return {
          ...item,
          refKey: uuid()
        }
      }) ?? []

    return {
      addressOptions,
      saveLocal
    }
  }
}
</script>

<style scoped lang="scss">
select.is-danger {
  border-color: #ff3860;
}
.field {
  padding: .5rem 1rem;
  width: 100%;
}
// Prevent loss of button size in different window widths
.flex-button {
  flex-shrink: 0;
}
.address {
  margin-top: .5rem;
}
</style>
