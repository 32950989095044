import http from '@/http';
import router from '@/router';

const NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED';
const NOTIFICATION_MARK_READ = 'NOTIFICATION_MARK_READ';

const state = {
  item: {}
};

const actions = {
  async getNotification({commit, dispatch, rootState}, id) {
    try {
      if (rootState.user.accessPrivileges.indexOf('BULLETINS') >= 0) {
        const {data} = await http.get(`bulletins/${id}`);
        await commit(NOTIFICATION_RECEIVED, {item: data});

        dispatch('notifications/getNotificationCounts', true, {root: true});
      } else {
        router.replace({name: 'Home'});
      }
    } catch (err) {
      router.replace({name: 'Notifications'});
    }
  },
  navigateToNotifications() {
    router.push({name: 'Notifications'});
  }
};

const mutations = {
  [NOTIFICATION_RECEIVED](state, {item}) {
    state.item = item;
  },
  [NOTIFICATION_MARK_READ](state) {
    state.item.viewed = true;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
