import i18n from '@/locales'
import { notify } from "@kyvg/vue3-notification"
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from "lodash"
import { ActionContext, GetterTree, MutationTree } from 'vuex'

interface Component {
  name: string,
  canSave?: boolean,
  isDirty?: boolean
}

interface DirtyManager {
  components: Component[]
  saveTriggered: 0,
  revertTriggered: 0,
  refreshTriggered: 0,
  saveEnabled: boolean,
  isDirty: boolean,
  canSaveOverride: boolean
}
type Context = ActionContext<DirtyManager, any>

const state: DirtyManager = {
  components: [],
  saveTriggered: 0,
  revertTriggered: 0,
  refreshTriggered: 0,
  saveEnabled: false,
  isDirty: false,
  canSaveOverride: false
}

const getters: GetterTree<DirtyManager, any> = {
  isComponentDirty(state) {
    return function(name: string) {
      return state.components.some(component => {
        return (component.name === name) && (!!component.isDirty)
      })
    }
  }
}

const actions = {
  // some components have multiple subcomponents that each register in this store.
  // When one becomes both dirty and the canSave state equals true then the Admin wrapper enables the save button; however, this is not always desirable.
  // In some cases, there are other child components that have validations that need to pass before enabling the save button
  // This method sets the canSaveOverride which is used to suppress the save button from enabling
  // !!!! warning !!!!  If this flag is set, be sure to unset it on component unmount as it will break dirty state in admin
  // ex... this.setCanSaveOverride(false)
  async setCanSaveOverride (context: Context, data: Boolean) {
    await context.commit('SET_CAN_SAVE_OVERRIDE', data)
  },
  async setDirtyComponent (context: Context, data: Component) {
    if (!data.name || data.isDirty === undefined || data.isDirty === null) return
    await context.commit('SET_DIRTY_COMPONENT', data)
    await context.dispatch('checkDirtyState')
    await context.dispatch('checkSaveState')
  },
  async setCanSaveComponent (context: Context, data: Component) {
    if (!data.name || data.canSave === undefined || data.canSave === null) return
    await context.commit('SET_CAN_SAVE_COMPONENT', data)
    await context.dispatch('checkDirtyState')
    await context.dispatch('checkSaveState')
  },
  async unsetDirtyComponent (context: Context, data: string) {
    if (!data) return
    await context.commit('UNSET_DIRTY_COMPONENT', data)
    await context.dispatch('checkDirtyState')
    await context.dispatch('checkSaveState')
  },
  async checkSaveState (context: Context) {
    // Not knowing when components set dirty or canSave and in what order, doing a check here
    if (context.state.components.filter( component => component.isDirty && component.canSave).length > 0) {
      await context.commit('SET_SAVE_ENABLED', true)
    } else {
      await context.commit('SET_SAVE_ENABLED', false)
    }
  },
  async checkDirtyState (context: Context) {
    if (context.state.components.filter( component => component.isDirty).length > 0) {
      await context.commit('SET_IS_DIRTY', true)
    } else {
      await context.commit('SET_IS_DIRTY', false)
    }
  },
  async triggerSave (context: Context) {
    await context.commit('SAVE_TRIGGERED')
    await context.dispatch('checkDirtyState')
    await context.dispatch('checkSaveState')
  },
  async triggerRevert (context: Context) {
    await context.dispatch('resetAllDirtyComponents')
    await context.commit('REVERT_TRIGGERED')
    await context.dispatch('checkDirtyState')
    await context.dispatch('checkSaveState')
  },
  async resetAllDirtyComponents (context: Context) {
    await context.commit('RESET_ALL_DIRTY_COMPONENTS')
    await context.dispatch('checkDirtyState')
    await context.dispatch('checkSaveState')
  },
  async refreshTriggered (context: Context) {
    await context.commit('REFRESH_TRIGGERED')
    await context.dispatch('checkDirtyState')
    await context.dispatch('checkSaveState')
  }
}

const mutations: MutationTree <DirtyManager> = {
  async 'SET_CAN_SAVE_OVERRIDE' (state, payload: boolean) {
    state.canSaveOverride = payload
  },
  async 'SET_DIRTY_COMPONENT' (state, payload: Component) {
    const index = state.components.findIndex((item) => item.name === payload.name)

    if (index === -1) {
      state.components.push(payload)
    } else {
      Object.assign(state.components[index], payload)
    }
  },
  async 'SET_CAN_SAVE_COMPONENT' (state, payload: Component) {
    const index = state.components.findIndex((item) => item.name === payload.name)

    if (index === -1) {
      state.components.push(payload)
    } else {
      Object.assign(state.components[index], payload)
    }
  },
  async 'SAVE_TRIGGERED' (state) {
    state.saveTriggered += 1
  },
  async 'REFRESH_TRIGGERED' (state) {
    state.refreshTriggered += 1
  },
  async 'REVERT_TRIGGERED' (state) {
    state.revertTriggered += 1
  },
  async 'UNSET_DIRTY_COMPONENT' (state, payload: string) {
   let index
   for (let i = 0; i < state.components.length; i++) {
     if (state.components[i].name === payload) {
       index = i
     }
   }
    if (index !== undefined) {
      state.components.splice(index, 1)
    }
  },
  async 'RESET_ALL_DIRTY_COMPONENTS' (state) {
    state.components = []
  },
  async 'SET_SAVE_ENABLED' (state, payload: boolean) {
    state.saveEnabled = payload
  },
  async 'SET_IS_DIRTY' (state, payload) {
    state.isDirty = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
