<template>
  <article class="media"
           @dblclick.stop="open">
    <figure class="media-left is-hidden-mobile"
            @click.stop="open">
      <thumbnail  :thumbnail-url="source.thumbnailUrl"
                  :thumbnail-type="source.mediaType 
                  || source.entityType"
                  :isWidget="isWidget"
                  data-int="search-result-thumbnail"
                  class="is-48x48" />
    </figure>
    <div class="media-content">
      <div class="content">
        <p>
          <i v-if="source.favorited"
             class="fas fa-star" />
            <span v-if="isWidget"
                  class="has-text-link has-text-weight-bold widget-search-result-link"
                  @click="widgetNavToContent">
            {{ source.name }}
          </span>
          <router-link  v-else
                        :to="{ path: uri, query: source.query }"
                        :target="getTargetAttributeForMedia()">
            <strong data-testid="result-name">{{ source.name }}</strong>
          </router-link>
          <br v-if="source.description">
          <span v-if="source.description"
                class="search-description"
                data-testid="description">
            {{ source.description }}
          </span>
          <br v-if="source.identifierWithSubtitle">
          <span v-if="source.identifierWithSubtitle"
                class="identifier-text"
                data-testid="identifier">
            {{ source.identifierWithSubtitle }}
          </span>
        </p>
      </div>
    </div>
    <div class="media-right">
      <a class="icon is-medium"
         @click.stop.prevent="info">
        <i class="fa fa-2x fa-info-circle" />
      </a>
    </div>
  </article>
</template>

<script>
import Thumbnail from "@/components/common/StatelessThumbnail"
import emitToComponent from '@/components/common/emitToComponentMixin.js'

export default {
  name: "SearchResultLayout",
  components: { Thumbnail },
  mixins: [emitToComponent],
  props: {
    source: {
      type: Object,
      required: true
    },
    isWidget: Boolean
  },
  methods: {
    getTargetAttributeForMedia () {
      if (this.source.mediaType === 'microsite' && this.source.openNewWindow) {
        return '_blank'
      } else {
        return ''
      }
    },
    info () {
      this.$emit('info', this.source)
      this.emitToComponent('SearchResultsList', 'info', this.source)
    },
    open () {
      this.$emit('open', this.source)
      this.emitToComponent('SearchResultsList', 'open', this.source)
    },
    widgetNavToContent() {
      this.$emit('widgetNavToContent', this.source)
      this.emitToComponent('SearchResultsList', 'widgetNavToContent', this.source)
    }
  }
}
</script>
<style scoped>
.image {
  cursor: pointer;
}
.media {
  margin: 0 !important;
}
.media:nth-child(even) {
  background: #fafafa;
}

.search-description {
  margin-top: .5rem;
}
.identifier-text {
  font-style: oblique;
}
.widget-search-result-link:hover {
  cursor: pointer;
}
</style>