import http from '@/http'
import i18n from '@/locales'

const TENANT_LABELS_RECEIVED = 'TENANT_LABELS_RECEIVED'

const actions = {
  async getTenantLabels ({ commit }) {
    try {
      const { data } = await http.get('labels')

      commit(TENANT_LABELS_RECEIVED, { labels: data })
    } catch (err) {
      // na
    }
  }
}

const mutations = {
  /*
  This mutation will override some i18n translations. This can lead to
  questions about why specific translations are not coming through properly.
  */
  [TENANT_LABELS_RECEIVED] (state, { labels }) {
    if (Object.keys(labels).length) {
      const availability = labels?.availability ?? i18n.global.t('availability')
      const book = labels?.book ?? i18n.global.tc('book', 1)
      const books = labels?.books ?? i18n.global.tc('book', 2)
      const cart = labels?.cart ?? i18n.global.tc('cart', 1)
      const carts = labels?.carts ?? i18n.global.tc('cart', 2)
      const chapter = labels?.chapter ?? i18n.global.tc('chapter', 1)
      const chapters = labels?.chapters ?? i18n.global.tc('chapter', 2)
      const comment = labels?.comment ?? i18n.global.tc('comment', 1)
      const comments = labels?.comments ?? i18n.global.tc('comment', 2)
      const discounted = labels?.discountedPrice ?? i18n.global.t('discounted')
      const discountedTotal = labels?.totalDiscountedPrice ?? i18n.global.t('discountedTotal')
      const document = labels?.document ?? i18n.global.tc('document', 1)
      const documents = labels?.documents ?? i18n.global.tc('document', 2)
      const erp = labels?.erp ?? i18n.global.t('erp')
      const eta = labels?.eta ?? i18n.global.t('eta')
      const external = labels?.external ?? i18n.global.tc('external', 1)
      const externals = labels?.externals ?? i18n.global.tc('external', 2)
      const identifier = labels?.identifier ?? i18n.global.t('identifier')
      const image = labels?.image ?? i18n.global.tc('image', 1)
      const images = labels?.images ?? i18n.global.tc('image', 2)
      const info = labels?.info ?? i18n.global.t('info')
      const item = labels?.item ?? i18n.global.t('item')
      const itemNotShown = labels?.itemNotShown ?? i18n.global.t('itemNotShown')
      const noPrice = labels?.noPrice ?? i18n.global.t('noPrice')
      const order = labels?.order ?? i18n.global.tc('order', 1)
      const orders = labels?.orders ?? i18n.global.tc('order', 2)
      const orderNumber = labels?.orderNumber ?? i18n.global.t('orderNumber')
      const page = labels?.page ?? i18n.global.tc('page', 1)
      const pages = labels?.pages ?? i18n.global.tc('page', 2)
      const part = labels?.part ?? i18n.global.tc('part', 1)
      const parts = labels?.parts ?? i18n.global.tc('part', 2)
      const partNumber = labels?.partNumber ?? i18n.global.t('partNumber')
      const purchaseOrder = labels?.purchaseOrder ?? i18n.global.t('purchaseOrder')
      const qty = labels?.qty ?? i18n.global.t('quantity')
      const requestForQuote = labels?.requestForQuote ?? i18n.global.t('requestForQuote')
      const retail = labels?.retailPrice ?? i18n.global.t('retail')
      const retailTotal = labels?.totalRetailPrice ?? i18n.global.t('retailTotal')
      const related = labels?.related ?? i18n.global.t('related')
      const searchPrompt = labels?.searchPrompt ?? i18n.global.t('searchPrompt')
      const searchPlaceholder = labels?.searchPrompt ?? i18n.global.t('searchPlaceholder')
      const subChapter = labels?.subChapter ?? i18n.global.tc('subChapter', 1)
      const subChapters = labels?.subChapters ?? i18n.global.tc('subChapter', 2)
      const submitPurchaseOrder = labels?.submitPurchaseOrder ?? i18n.global.t('submitPurchaseOrder')
      const submitRequestForQuote = labels?.submitRequestForQuote ?? i18n.global.t('submitRequestForQuote')
      const suggestion = labels?.suggestion ?? i18n.global.tc('suggestion', 1)
      const suggestions = labels?.suggestions ?? i18n.global.tc('suggestion', 2)
      const supplier = labels?.supplier ?? i18n.global.tc('supplier', 1)
      const suppliers = labels?.suppliers ?? i18n.global.tc('supplier', 2)
      const uom = labels?.uom ?? i18n.global.t('uom')
      const video = labels?.video ?? i18n.global.tc('video', 1)
      const videos = labels?.videos ?? i18n.global.tc('video', 2)
      const whereUsed = labels?.whereUsed ?? i18n.global.t('whereUsed')
      const wholesale = labels?.wholesalePrice ?? i18n.global.t('wholesale')
      const wholesaleTotal = labels?.totalWholesalePrice ?? i18n.global.t('wholesaleTotal')
      const microsite = labels?.microsite ?? i18n.global.tc('microsite', 1)
      const microsites = labels?.microsites ?? i18n.global.tc('microsite', 2)


      i18n.global.mergeLocaleMessage(i18n.global.locale, {
        book: `${book} | ${books}`,
        cart: `${cart} | ${carts}`,
        chapter: `${chapter} | ${chapters}`,
        comment: `${comment} | ${comments}`,
        document: `${document} | ${documents}`,
        external: `${external} | ${externals}`,
        image: `${image} | ${images}`,
        order: `${order} | ${orders}`,
        part: `${part} | ${parts}`,
        page: `${page} | ${pages}`,
        subChapter: `${subChapter} | ${subChapters}`,
        suggestion: `${suggestion} | ${suggestions}`,
        supplier: `${supplier} | ${suppliers}`,
        video: `${video} | ${videos}`,
        microsite: `${microsite} | ${microsites}`,

        availability,
        discounted,
        discountedTotal,
        erp,
        eta,
        identifier,
        info,
        item,
        itemNotShown,
        noPrice,
        orderNumber,
        partNumber,
        purchaseOrder,
        qty,
        related,
        requestForQuote,
        retail,
        retailTotal,
        searchPlaceholder,
        searchPrompt,
        submitPurchaseOrder,
        submitRequestForQuote,
        uom,
        whereUsed,
        wholesale,
        wholesaleTotal
      })
    }
  }
}

export default {
  actions,
  mutations
}
