import http from '@/http';
import i18n from '@/locales';
import { notify } from "@kyvg/vue3-notification"
import { postError } from '@/helpers/notification'

const SELECTION_SET = 'SELECTION_SET';
const SELECTION_TO_CLIPBOARD = 'SELECTION_TO_CLIPBOARD';
const TAXON_DELETE = 'TAXON_DELETE';
const TAXON_SELECT = 'TAXON_SELECT';
const TAXON_CRITERIA_RECIEVED = 'TAXON_CRITERIA_RECIEVED';
const TAXON_CRITERIA_REMOVE = 'TAXON_CRITERIA_REMOVE';
const TAXON_CRITERIA_UPDATE = 'TAXON_CRITERIA_UPDATE';
const TAXON_SYNC_SELECTED = 'TAXON_SYNC_SELECTED';
const TAXON_THUMBNAIL_RECIEVED = 'TAXON_THUMBNAIL_RECIEVED';
const TAXON_TRANSLATION_REMOVE = 'TAXON_TRANSLATION_REMOVE';
const TAXON_TRANSLATION_UPDATE = 'TAXON_TRANSLATION_UPDATE';
const TAXONOMY_LOADING = 'TAXONOMY_LOADING';
const TAXONOMY_RECEIVED = 'TAXONOMY_RECEIVED';

let updateTaxonomyTimeout;

const state = {
  categoryCriteria: null,
  clipboard: '',
  isLoaded: false,
  items: {},
  selected: null,
  selection: [],
  tagCriteria: [],
  typeCriteria: []
};

const actions = {
  /**
   * Blocks taxonomy modifications for the duration
   * @param  {function} options.commit - the vuex commit operation
   */
  blockTaxonomy({commit}) {
    commit(TAXONOMY_LOADING);
  },
  /**
   * Copy the selection array to the clipboard via SELECTION_TO_CLIPBOARD
   * @param  {function} options.commit - the vuex commit operation
   */
  copySelection({commit}) {
    commit(SELECTION_TO_CLIPBOARD);
  },
  /**
   * Set the selection (both the selected and selection) of what is currently set in taxonomy.
   * @param  {function} options.commit       - the vuex commit operation
   * @param  {function} options.dispatch     - the vuex dispatcher operation
   * @param  {object[]} options.newSelection - the collection of nodes (with branches and leaves)
   * to be selected
   * @param  {number[]} path                 - the index array to select
   */
  setSelection({commit, dispatch}, {newSelection, path}) {
    commit(SELECTION_SET, newSelection);
    commit(TAXON_SELECT, getNodeFromPath(state.items, path));
  },
  /**
   * Retrieve the root taxonomy tree, and pass the results to TAXONOMY_RECEIVED
   * @param  {function} options.commit    - the vuex commit operation
   * @param  {function} options.dispatch  - the vuex dispatcher operation
   * @param  {object}   options.rootState - the vuex root state context
   * @throws {Exception} if the http request results in anything other than 2xx
   */
  async getTaxonomy({commit, dispatch, rootState}) {
    let json = {};
    try {
      commit(TAXONOMY_LOADING);
      commit(TAXON_SELECT);
      commit(SELECTION_SET);
      const context = rootState.admin.selected;
      if (context) {
        if (context.isTenant) {
          const {data: tenant} = await http.get(`tenants/${context.id}/taxonomy`);
          json = tenant || {};
        } else {
          const {data: org} = await http.get(`organizations/${context.id}/taxonomy`);
          json = org || {};
        }
        dispatch('getTaxonSearchCriteria');
        commit(TAXONOMY_RECEIVED, json);
      }
    } catch (err) {
      commit(TAXONOMY_RECEIVED, {});
      throw err;
    }
    if (Object.keys(json).length) {
      dispatch('setOrganizationTreeProperty', 'hasTaxonomyDefined');
    }
  },
  async debouncedUpdateTaxonomy({dispatch, state}, newTaxonomy) {
    const taxonomyTimeout = Math.max(JSON.stringify(newTaxonomy || state.items).length / 500000, 500);
    clearTimeout(updateTaxonomyTimeout);
    updateTaxonomyTimeout = setTimeout(() => {
      dispatch('updateTaxonomy', newTaxonomy);
    }, taxonomyTimeout);
  },
  /**
   * Update the taxonomy via put request, submitting the full tree of taxonomy prepared and ready
   * for the back end. Causes a load state to persist until a successful response is received
   * @param  {function} options.commit    - the vuex commit operation
   * @param  {function} options.dispatch  - the vuex dispatcher operation
   * @param  {object}   options.rootState - the vuex root state context
   * @param  {object}   options.state     - the vuex factual state context
   * @param  {object}   [newTaxonomy]     - the new taxonomy tree. If this is omitted,
   * the underlying state will be used (useful for dispatching)
   * @throws {Exception} if the http request results in anything other than 2xx
   */
  async updateTaxonomy({commit, dispatch, rootState, state}, newTaxonomy) {
    const context = rootState.admin.selected;
    const taxonomy = newTaxonomy || state.items;
    if (context) {
      if (!taxonomy.hasOwnProperty('visible')) {
        // if the root has no taxonomy, we're here because we're adding the first taxon.
        taxonomy.visible = true;
        // TODO - there is a way to clear the taxonomy defined flag for organizations, so there
        // must be a way to set it without reloading.  Fix this here, perhaps someday.
      }
      const tenOrg = context.isTenant ? 'tenants' : 'organizations';
      try {
        const {data: response} = await http.put(`${tenOrg}/${context.id}/taxonomy`, taxonomy);
        commit(TAXONOMY_RECEIVED, response || {});
      } catch (error) {
        console.log('error :>> ', error);
      }
    }
  },
  /**
   * Deletes the taxonomy via delete request, destroying the full taxonomy for the org / tenant and
   * forcing it to rely on the parent taxonomy (if any).
   * @param  {function} options.commit    - the vuex commit operation
   * @param  {function} options.dispatch  - the vuex dispatcher operation
   * @param  {object}   options.rootState - the vuex root state context
   * @throws {Exception} if the http request results in anything other than 2xx
   */
  async deleteTaxonomy({commit, dispatch, rootState}) {
    commit(TAXONOMY_LOADING);
    const context = rootState.admin.selected;
    if (context) {
      const tenOrg = context.isTenant ? 'tenants' : 'organizations';
      try {
        await http.delete(`${tenOrg}/${context.id}/taxonomy`);
      } catch (error) {
        console.log('error :>> ', error);
      }
      await dispatch('getOrganizationTree', false);
      await dispatch('setOrganizationTreeProperty', 'hasTaxonomyDefined');
      commit(TAXONOMY_RECEIVED, {});
      commit(TAXON_SELECT);
      commit(SELECTION_SET);
    }
  },
  /**
   * Delete the indicated taxon (probably the selected taxon)
   * @param  {function} options.commit    - the vuex commit operation
   * @param  {function} options.dispatch  - the vuex dispatcher operation
   * @param  {object}   options.state     - the vuex factual state context
   * @param  {[type]}   item              - the item to delete
   */
  async deleteTaxon({commit, dispatch, state}, item) {
    const path = getPathById(state.items, item.id);
    commit(TAXON_DELETE, path);
    commit(TAXON_SELECT);
    commit(SELECTION_SET);
    commit(TAXONOMY_LOADING);
    if (state.items.children && state.items.children.length) {
      dispatch('debouncedUpdateTaxonomy', state.items);
    } else {
      dispatch('deleteTaxonomy');
    }
  },
  /**
   * Updates the criteria on the selected taxon
   * @param  {function} options.commit   - the vuex commit operation
   * @param  {function} options.dispatch - the vuex dispatcher operation
   * @param  {object}   options.state    - the vuex factual state context
   * @param  {object}   criteria         - the criteria object, containing the criteria 'key' and
   * 'value' to be set at the key
   */
  updateCriteria({commit, dispatch, state}, criteria) {
    commit(TAXON_CRITERIA_UPDATE, criteria);
    commit(TAXON_SYNC_SELECTED);

    dispatch('debouncedUpdateTaxonomy');
  },
  /**
   * Removes the criteria on the selected taxon
   * @param  {function} options.commit   - the vuex commit operation
   * @param  {function} options.dispatch - the vuex dispatcher operation
   * @param  {object}   options.state    - the vuex factual state context
   * @param  {object}   criteria         - the criteria object, containing the criteria 'key' and
   * 'value' to be removed at the key
   */
  removeCriteria({commit, dispatch}, criteria) {
    commit(TAXON_CRITERIA_REMOVE, criteria);
    commit(TAXON_SYNC_SELECTED);
    dispatch('debouncedUpdateTaxonomy');
  },
  /**
   * Updates the translation on the selected taxon
   * @param  {function} options.commit   - the vuex commit operation
   * @param  {function} options.dispatch - the vuex dispatcher operation
   * @param  {object}   translation      - the translation object, containing the locale and value
   */
  updateTranslation({commit, dispatch}, translation) {
    commit(TAXON_TRANSLATION_UPDATE, translation);
    commit(TAXON_SYNC_SELECTED);
    dispatch('debouncedUpdateTaxonomy');
  },
  /**
   * Removes the specific translation on the selected taxon
   * @param  {function} options.commit   - the vuex commit operation
   * @param  {function} options.dispatch - the vuex dispatcher operation
   * @param  {string}   localeCode4      - the locale to remove
   */
  removeTranslation({commit, dispatch}, localeCode4) {
    commit(TAXON_TRANSLATION_REMOVE, localeCode4);
    commit(TAXON_SYNC_SELECTED);
    dispatch('debouncedUpdateTaxonomy');
  },
  /**
   * Updates the thumbnail on the selected taxon
   * @param  {function} options.commit    - the vuex commit operation
   * @param  {object}   options.rootState - the vuex root state context
   * @param  {object}   options.state     - the vuex factual state context
   * @param  {object}   file              - the file instance from the browser upload
   */
  async updateThumbnail({commit, rootState, state}, file) {
    try {
      const context = rootState.admin.selected;
      const formData = new FormData();
      formData.append('file', file, file ? file.name : '');
      if (context) {
        let json = {};
        if (context.isTenant) {
          const {data: tenant} = await http.post(
              `tenants/${context.id}/taxonomy/${state.selected.id}/thumbnail`,
              formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          );
          json = tenant;
        } else {
          const {data: org} = await http.post(
              `organizations/${context.id}/taxonomy/${state.selected.id}/thumbnail`,
              formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          );
          json = org;
        }
        await commit(TAXON_THUMBNAIL_RECIEVED, json);
        commit(TAXON_SYNC_SELECTED);
      }
    } catch (err) {
      const response = err.response;
      postError({
        title: i18n.global.t('error'),
        text: response ? response.data.message : err,
      });
    }
  },
  /**
   * Removes the thumbnail from the selected taxon
   * @param  {function} options.commit   - the vuex commit operation
   * @param  {function} options.dispatch - the vuex dispatcher operation
   * @param  {object}   options.state    - the vuex factual state context
   */
  async removeThumbnail({commit, dispatch, state}) {
    const taxonThumbnailInforamation = {
      thumbnailName: null,
      thumbnailPath: null,
      thumbnailUrl: null
    };
    commit(TAXON_THUMBNAIL_RECIEVED, taxonThumbnailInforamation);
    commit(TAXON_SYNC_SELECTED);
    dispatch('debouncedUpdateTaxonomy');
  },
  /**
   * Retrieve the root taxon search criteria from the back end, and pass the results to
   * TAXON_CRITERIA_RECIEVED
   * @param  {object} options.commit - the vuex commit operation
   * @param  {object} options.state  - the vuex factual state context
   * @throws {Exception} if the http request results in anything other than 2xx
   */
  async getTaxonSearchCriteria({commit, state}) {
    if (!state.tagCriteria.length || !state.categoryCriteria) {
      try {
        const {data: tags} = await http.get('tag-names');
        const {data: categories} = await http.get('medias/categories');
        commit(TAXON_CRITERIA_RECIEVED, {tags, categories});
      } catch (error) {
        console.log('error :>> ', error);
      }
    }
  }
};

const mutations = {
  /**
   * Set the selection array indicating the highlighted branches and leaves
   * @param  {object}   state     - the vuex factual state context
   * @param  {object[]} selection - the nodes that are currently selected
   */
  [SELECTION_SET](state, selection) {
    state.selection = selection || [];
  },
  /**
   * Copy the selected nodes from selection to the clipboard, removing their selected property once
   * they're in the clipboard.
   * @param  {object} state - the vuex factual state context
   */
  [SELECTION_TO_CLIPBOARD](state) {
    const traverse = (acc, key, value) => {
      acc[key] = value;
      if (acc.children && acc.children.length) {
        for (let n = 0; n < acc.children.length; n++) {
          traverse(acc.children[n], key, value);
        }
      }
      return acc;
    };
    state.clipboard = JSON.stringify(
        state.selection.map((el) => traverse(el, 'isSelected', false))
    );
  },
  /**
   * Delete the taxon at the supplied path
   * @param  {object} state  - the vuex factual state context
   * @param  {number[]} path - the index array to select
   */
  [TAXON_DELETE](state, path) {
    getNodeFromPath(state.items, path.slice(0, -1)).children.splice(path.slice(-1)[0], 1);
  },
  /**
   * Set the selected element to be what was passed in, or deselect if the same element is passed in
   * @param  {object} state - the vuex factual state context
   * @param  {object} item  - the item to select
   */
  [TAXON_SELECT](state, item) {
    state.selected = item || null;
  },
  /**
   * Establishes the taxon criteria for the local state
   * @param  {object} state              - the vuex factual state context
   * @param  {object} options.tags       - the tag names received from the back end
   * @param  {object} options.categories - the medias categories received from the back end
   */
  [TAXON_CRITERIA_RECIEVED](state, {tags, categories}) {
    state.tagCriteria = tags;
    state.typeCriteria = ['book', 'assembly', 'part', 'document', 'external', 'video', 'image'];
    state.categoryCriteria = categories;
  },
  /**
   * For the selected Taxon, remove the stated criteria.
   * @param  {object} state    - the vuex factual state context
   * @param  {object} criteria - the key and value (and possibly prefix and suffix) for the criteria
   * to be removed
   */
  [TAXON_CRITERIA_REMOVE](state, criteria) {
    if (state.selected && state.selected.criteria) {
      const values = state.selected.criteria[criteria.key];
      if (values.length > 1) {
        const filtered = values.filter((value) => value !== criteria.value);
        // Vue.set(state.selected.criteria, criteria.key, filtered); -- deprecated
        state.selected.criteria[criteria.key] = filtered
      } else {
        // Vue.delete(state.selected.criteria, criteria.key); -- deprecated
        delete state.selected.criteria[criteria.key]
      }
      if (criteria.prefix) {
        // Vue.set(state.selected, 'tag_range_prefix', undefined); -- deprecated
        state.selected['tag_range_prefix'] = undefined
      }
      if (criteria.suffix) {
        // Vue.set(state.selected, 'tag_range_suffix', undefined); -- deprecated
        state.selected['tag_range_suffix'] = undefined
      }
    }
  },
  /**
   * For the selected taxon, update the existing criteria or create it if it doesn't exist.  In the
   * case of 'q' this does an overwrite, otherwise an append on any existing values for that
   * keyed criteria.
   * @param  {object} state    - the vuex factual state context
   * @param  {object} criteria - the key and value (and possibly prefix and suffix) for the criteria
   * to be modified
   */
  [TAXON_CRITERIA_UPDATE](state, criteria) {
    let values = state.selected.criteria[criteria.key] || [];
    if (criteria.key === 'q') {
      values = [criteria.value];
    } else {
      values.push(criteria.value);
    }
    // Vue.set(state.selected.criteria, criteria.key, values); -- deprecated
    state.selected.criteria[criteria.key] = values
    if (criteria.prefix) {
      // Vue.set(state.selected, 'tag_range_prefix', [criteria.prefix]); -- deprecated
      state.selected['tag_range_prefix'] = [criteria.prefix]
    }
    if (criteria.suffix) {
      // Vue.set(state.selected, 'tag_range_suffix', [criteria.suffix]); -- deprecated
      state.selected['tag_range_suffix'] = [criteria.suffix]
    }
  },
  /**
   * Applies the thumbnail data to the selected taxon
   * @param  {object} state - the vuex factual state context
   * @param  {object} taxon - the object containing the url, path, and name of the image to be
   * applied to the selected taxon
   */
  [TAXON_THUMBNAIL_RECIEVED](state, taxon) {
    // Vue.set(state.selected, 'thumbnailName', taxon.thumbnailName); -- deprecated
    state.selected['thumbnailName'] = taxon.thumbnailName
    // Vue.set(state.selected, 'thumbnailPath', taxon.thumbnailPath); -- deprecated
    state.selected['thumbnailPath'] = taxon.thumbnailPath
    // Vue.set(state.selected, 'thumbnailUrl', taxon.thumbnailUrl); -- deprecated
    state.selected['thumbnailUrl'] = taxon.thumbnailUrl
  },
  /**
   * For the selected taxon, update the existing translation or create it if it doesn't exist.
   * @param  {object} state       - the vuex factual state context
   * @param  {object} translation - the locale and value for the tranlation to be modified
   */
  [TAXON_TRANSLATION_UPDATE](state, translation) {
    // Vue.set(state.selected.translations, translation.locale, translation.value); -- deprecated
    state.selected.translations[translation.locale] = translation.value

  },
  /**
   * For the selected taxon, remove the existing translation.
   * @param  {object} state       - the vuex factual state context
   * @param  {object} localeCode4 - the locale to be deleted
   */
  [TAXON_TRANSLATION_REMOVE](state, localeCode4) {
    // Vue.delete(state.selected.translations, localeCode4);  --   deprecated
    delete state.selected.translations[localeCode4]
  },
  /**
   * Synchronize the selection array to the selected taxon.  This should only be called when the
   * selected taxon is modified, thus preserving the translated data for this single use case.
   * @param  {object} state - the vuex factual state context
   */
  [TAXON_SYNC_SELECTED](state) {
    if (state.selection.length === 1) {
      state.selection[0].data = {
        criteria: state.selected.criteria,
        id: state.selected.id,
        name: state.selected.name,
        thumbnailName: state.selected.thumbnailName,
        thumbnailPath: state.selected.thumbnailPath,
        thumbnailUrl: state.selected.thumbnailUrl,
        translations: state.selected.translations,
        visible: state.selected.visible
      }
    }

  },
  /**
   * Sets the application to be loading. This is cleared by a variety of other mutations, mostly
   * those that would require a loading state initially (XHRs).
   * @param  {object} state - the vuex factual state context
   */
  [TAXONOMY_LOADING](state) {
    state.isLoaded = false;
  },
  /**
   * Applies the taxonomy tree to the underlying vuex state, including flattening it. BE WARNED
   * that the top level element of the tree is a "hidden eve," that is, it should be sliced off
   * by anything that would render to a user.
   * @param  {object} state    - the vuex factual state context
   * @param  {object} taxonomy - the taxonomy tree from the back end or at least an empty object
   */
  [TAXONOMY_RECEIVED](state, taxonomy) {
    const selectedNodes = state.selection.map((s) => getNodeFromPath(taxonomy, s.path));
    selectedNodes.forEach((acc) => {
      if (typeof acc === 'object') acc.isSelected = true;
    });
    state.items = taxonomy;
    state.selected = selectedNodes.length === 1 ? selectedNodes[0] : null;
    state.isLoaded = true;
  }
};

const getNodeFromPath = (node, path) => {
  if (node && path) {
    return path.reduce((acc, cur) => {
      if (acc.children && acc.children.length) return acc.children[cur];
      else return acc;
    }, node);
  }
};

const getPathById = (node, id, currentPath = []) => {
  if (node.id === id) return currentPath;
  if (node.children && node.children.length) {
    for (let n = 0; n < node.children.length; n++) {
      const nextPath = getPathById(node.children[n], id, [...currentPath, n]);
      if (nextPath) return nextPath;
    }
  }
};

export default {
  state,
  actions,
  mutations
};
