/**
 * Bulma Color Tools
 *
 * Helper functions to produce color values which
 * are inline with Bulma's color system.
 *
 * We've copied these functions from bulma-css-vars to
 * avoid having to adjust webpack configuration to transpile
 * node_modules for IE.
 *
 * @reference https://github.com/wtho/bulma-css-vars
 *
 */

// temp
/* eslint-disable new-cap */

const Color = require('color');

function lightness(color) {
  return color.hsl().color[2] / 100;
}

function getHsl(color) {
  return color.hsl();
}

function lumForChannel(chanVal) {
  let val = chanVal / 255;
  if (val < 0.03928) {
    val = val / 12.92;
  } else {
    val = (val + 0.055) / 1.055;
    val = val * val;
  }
  return val;
}

function colorLuminance(color) {
  const {r, g, b} = color.rgb().object();
  return (lumForChannel(r) * 0.2126 +
    lumForChannel(g) * 0.7152 +
    lumForChannel(b) * 0.0722);
}

export const bulmaColorTools = {
  adjusthue(col, deg) {
    const color = Color(col);
    const degAsNumber = Number(deg.split('deg')[0]);
    return color
        .rotate(degAsNumber)
        .rgb()
        .toString();
  },
  saturate(col, perc) {
    const color = Color(col);
    const percAsNumber = Number(perc) / 100;
    const hsl = getHsl(color);
    hsl.color[1] = Math.min(100, hsl.color[1] + percAsNumber);
    return hsl.rgb().toString();
  },
  desaturate(col, perc) {
    const color = Color(col);
    // percentage was previously multiplied by 100 to avoid dots
    // but is required now as per-one value
    // 2.5% => 250 => 0.025: / 10000
    const percAsNumber = Number(perc) / 100;
    const hsl = getHsl(color);
    hsl.color[1] = Math.max(0, hsl.color[1] - percAsNumber);
    return hsl.rgb().toString();
  },
  /**
   *
   * @param {string} col color value to adjust
   * @param {number} perc percentage to adjust color
   * @return {string}
   */
  darken(col, perc) {
    const color = Color(col);
    const hsl = getHsl(color);
    hsl.color[2] = Math.max(0, hsl.color[2] - perc);
    return hsl.hex().toString();
  },
  lighten(col, perc) {
    const color = Color(col);
    const percAsNumber = Number(perc) / 100;
    const hsl = getHsl(color);
    hsl.color[2] = Math.min(100, hsl.color[2] + percAsNumber);
    return hsl.hex().toString();
  },
  rgba(col, alphaVal) {
    const color = Color(col).rgb();
    const valAsNumber = Number(alphaVal) / 100;
    return color
        .alpha(valAsNumber)
        .toString();
  },
  'color-invert'(col) {
    const color = Color(col).rgb();
    if (colorLuminance(color) > 0.55) {
      return Color('#000')
          .alpha(0.7)
          .toString();
    } else {
      return 'rgb(255, 255, 255)';
    }
  },
  'light-color'(col) {
    const color = Color(col);
    let light = 0.96;
    if (lightness(color) > 0.96) {
      light = lightness(color);
    }
    return color
        .lightness(light * 100)
        .hex()
        .toString();
  },
  'dark-color'(col) {
    const color = Color(col);
    const baseLum = 29;
    const luminance = colorLuminance(color);
    const luminanceDelta = 0.53 - luminance;
    const targetLum = Math.round(baseLum + luminanceDelta * 53);
    // is a value between 0 and 100, what lightness also takes as arg
    const changeLum = Math.max(baseLum, targetLum);
    return color
        .lightness(changeLum)
        .hex()
        .toString();
  }
};
