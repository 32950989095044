<template>
  <slot :title="title">
    <library-content-header v-if="hasInfoLandingPage"
                            class="library-content-header"
                            :title="title"
                            :style="contentHeaderStyle"
                            :content="content">
    <template #navigation>
      <div class="is-flex justify-space-between full-width">
        <div class="is-flex">
          <h3 v-if="showTitle"
              class="title-five ml-1">
            {{ title }}
          </h3>
          <bulma-button-icon  v-if="!hasInfoPageOpen"
                              iconClass="fas fa-info-circle links-color"
                              class="pointer-on-hover mr-1 info-btn"
                              :disable="noDetailInfo()"
                              :text="$t('moreInfo')"
                              @click="openInfoPage"/>
          <bulma-button-icon  v-if="canShowBackButton"
                              iconClass="fas fa-arrow-left links-color"
                              class="pointer-on-hover mr-1 return-btn"
                              :text="$t('returnToViewer')"
                              @click="backToContentFromInfoPage"/>
        </div>
        <div v-if="showQuickAdd">
          <widget-quick-add-button />
        </div>
      </div>
    </template>
    <template #contentActions>
    <!-- Override default content with nothing -->
      <div  style="display: none"></div>
    </template>
  </library-content-header>
  </slot>
</template>

<script setup>
import { computed, toRaw } from 'vue'
import { useStore } from 'vuex'
import i18n from '@/locales'
import WidgetQuickAddButton from "@/components/widgets/components/WidgetQuickAddButton.vue"
import LibraryContentHeader from '@/components/library/content/header/LibraryContentHeader'
import BulmaButtonIcon from '@/components/common/BulmaButtonIcon.vue'
import { useInfoLandingPage } from '@/components/widgets/composables/useInfoLandingPage'
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator.ts'
import { useBreakpoints } from "@/helpers/responsiveBreakpoints"
import { useWidgetAddToCart } from "@/components/widgets/composables/useWidgetAddToCart"
import { WidgetTypes } from '@/interfaces/global/widgets'

const store = useStore()
const { hasInfoPageOpen,
  hasInfoLandingPage,
  openInfoPage,
  backToContentFromInfoPage } = useInfoLandingPage()
const { isBook, page }  = useWidgetNavigator()
const { outerWidth } = useBreakpoints()
const { hasWidgetCart } = useWidgetAddToCart()

const canShowBackButton = computed(() => {
  return hasInfoPageOpen.value
    ? type.value === 'part'
      ? (isBook.value) || (page.value !== null)
      : true
    : false
})

const content = computed(() => store.state.content)

const type = computed(() => (content.value.type === 'media')
  ? content.value.contentType
  : content.value.type)

const title = computed(() => content.value?.isLoaded
  ? content.value.partNumber || content.value.name
  : i18n.global.tc(type.value, 1))

const contentHeaderStyle = computed(() => {
  const justify = hasInfoPageOpen.value ? 'space-between' : 'flex-start'
  return `justify-content: ${justify}; ` +
    'padding: 0.5rem; ' +
    'max-height: 13%;'
})
// computed property isn't calculating proper value. Converting this to function does the trick
const noDetailInfo = () => {
  const contentRaw = toRaw(content.value)
  return contentRaw.type !== 'page'
    ? !contentRaw.tags?.total && !contentRaw.tagCount && !contentRaw.comments?.total && !contentRaw.related?.total
    : false
}
// there is a difference in breakpoint returned for widgets vs normal ui for window.innerWidth
// in the browser emulator, the iframe outerWidth is the normal ui equivalent of window.innerWidth
// widgets should be using this outerWidth as the media breakpoint
const isMobile = computed(() => {
  return outerWidth.value <= 768
})
const widgetType = computed(() => {
  return store.getters['widgets/widgetType']
})
const showTitle = computed(() => {
  return (widgetType.value === WidgetTypes.page)
    || !isMobile.value
})

const showQuickAdd = computed(() => {
  return hasWidgetCart.value && content.value.enableQuickAddForContent && (widgetType.value === WidgetTypes.library)
})
</script>
