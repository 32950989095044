<template>
  <td :data-int="className">
    <label v-if="priceAmount === 0">{{ $t('noPrice') }}</label>
    <label v-else-if="price">{{ price }}</label>
  </td>
</template>

<script>
export default {
  name: 'CartCellPrice',
  props: {
    column: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    hideRetailPrice: Boolean,
    hideDiscountPrice: Boolean,
    hideWholeSalePrice: Boolean
  },
  computed: {
    className () {
      return `cart-cell-${this.column.type}`
    },
    price () {
      if (this.priceAmount > 0) {
        const locale = this.$store.state.user.locale
        const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: this.currency })

        return formatter.format(this.priceAmount)
      } else {
        return ''
      }
    },
    priceAmount () {
      let price = -1
      if (this.column.property === 'retailPrice' && !this.hideRetailPrice) {
        price = this.item.retailAmount
      } else if (this.column.property === 'wholesalePrice' && !this.hideWholeSalePrice) {
        price = this.item.wholesaleAmount
      } else if (this.column.property === 'discountedPrice' && !this.hideDiscountPrice) {
        price = this.item.discountAmount
      }
      return price
    },
    currency () {
      let currency = 'USD'
      if (this.column.property === 'retailPrice' && !this.hideRetailPrice) {
        currency = this.item.retailCurrency
      } else if (this.column.property === 'wholesalePrice' && !this.hideWholeSalePrice) {
        currency = this.item.wholesaleCurrency
      } else if (this.column.property === 'discountedPrice' && !this.hideDiscountPrice) {
        currency = this.item.discountCurrency
      }
      return currency
    }
  }
}
</script>
