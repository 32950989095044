import http from '@/http';

export const ORDER_FILTERS_RECEIVED = 'ORDER_FILTERS_RECEIVED';
export const ORDER_FILTERS_SET_SELECTED = 'ORDER_FILTERS_SET_SELECTED';
export const ORDER_FILTERS_CLEAR_SELECTED = 'ORDER_FILTERS_CLEAR_SELECTED';
export const ORDER_FILTERS_REMOVE_FILTER = 'ORDER_FILTERS_REMOVE_FILTER';
export const ORDER_FILTERS_UPDATE_SELECTED_OPTIONS = 'ORDER_FILTERS_UPDATE_SELECTED_OPTIONS';
export const ORDER_FILTERS_SET_SELECTED_AND_OPTIONS = 'ORDER_FILTERS_SET_SELECTED_AND_OPTIONS';

const state = {
  items: [],
  isLoaded: false,
  selected: {}
};

const getters = {
  selectedFilters: (state) => state.items.filter((filter) => state.selected[filter.field])
};

const actions = {
  async getFilters({commit}) {
    try {
      const {data} = await http.get('orders/search/metadata');
      commit(ORDER_FILTERS_RECEIVED, {filters: data});
    } catch (err) {
      // na
    }
  },
  setSelectedFilters({commit}, selected) {
    commit(ORDER_FILTERS_SET_SELECTED, {selected});
  },
  removeSelectedFilter({commit, dispatch}, filter) {
    commit(ORDER_FILTERS_REMOVE_FILTER, {filter});
    dispatch('navigateToOrders');
  },
  clearSelectedFilters({commit, dispatch}) {
    commit(ORDER_FILTERS_CLEAR_SELECTED);
    dispatch('navigateToOrders');
  },
  updateSelectedFilterOptions({commit, dispatch}, {filter, options}) {
    commit(ORDER_FILTERS_UPDATE_SELECTED_OPTIONS, {filter, options});
    dispatch('navigateToOrders');
  },
  setSelectedFiltersAndOptions({commit}, options) {
    commit(ORDER_FILTERS_SET_SELECTED_AND_OPTIONS, {options});
  }
};

const mutations = {
  [ORDER_FILTERS_RECEIVED](state, {filters}) {
    state.items = filters;
    state.isLoaded = true;
  },
  [ORDER_FILTERS_SET_SELECTED](state, {selected}) {
    const filterFields = selected.map((filter) => filter.field);
    const selectedFilters = {};
    filterFields.forEach((field) => {
      selectedFilters[field] = state.selected[field] ?
        state.selected[field] : [];
    });
    state.selected = selectedFilters;
  },
  [ORDER_FILTERS_CLEAR_SELECTED](state) {
    const selectedFilters = {};
    Object.keys(state.selected).forEach((key) => {
      selectedFilters[key] = [];
    });
    state.selected = selectedFilters;
  },
  [ORDER_FILTERS_REMOVE_FILTER](state, {filter}) {
    // Vue.delete(state.selected, filter.field);  --  deprecated
    delete state.selected[filter.field]
  },
  [ORDER_FILTERS_UPDATE_SELECTED_OPTIONS](state, {filter, options}) {
    state.selected[filter.field] = options;
  },
  [ORDER_FILTERS_SET_SELECTED_AND_OPTIONS](state, {options}) {
    const query = {};
    Object.keys(state.selected).forEach((key) => {
      const selectedValues = state.selected[key];
      if (!selectedValues.length) {
        query[key] = [];
      } else {
        query[key] = selectedValues;
      }
    });
    Object.keys(options).forEach((key) => {
      const values = Array.isArray(options[key]) ? options[key] : [options[key]];
      const filter = state.items.find((f) => f.field === key);
      if (filter) {
        query[key] = values;
      }
    });
    if (Object.keys(query).length === 0) {
      query.status = [];
      query.type = [];
    }
    state.selected = query;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
