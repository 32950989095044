import { createI18n } from "vue-i18n"

import messages from './messages'
import dateTimeFormats from './dates'

// import translatedBlock from '@/plugins/translatedBlock'

// Vue.use(VueI18n)
// Vue.use(translatedBlock)

const i18n = createI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages,
  datetimeFormats: dateTimeFormats,
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})

export default i18n
