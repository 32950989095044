<template>
  <div
    class="confirmation-modal-v2 modal is-active"
    :data-unit="dataUnit"
    v-if="isActive"
  >
    <div
      class="modal-background"
      @click.exact="$emit('cancel')"
    />
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="is-full-width">
          <button
            class="is-pulled-right delete"
            aria-label="close"
            @click.exact="$emit('cancel')"
          />
          <div class="text-wrapper">
            <slot name="title">
              <h5
                class="title-five mb-1"
                v-if="title"
              >
                {{ title }}
              </h5>
              <h5
                class="title-five mb-1"
                v-else
              >
                {{ this.$t('unsavedChanges') }}
              </h5>
            </slot>
            <slot name="subtitle">
              <h6
                class="subtitle-six mt-4"
                v-if="subtitle"
              >
                {{ subtitle }}
              </h6>
              <h6
                class="subtitle-six mt-4"
                v-else
              >
                {{ this.$t('unsavedChangesWarning') }}
              </h6>
            </slot>
            <slot name="content" />
          </div>
        </div>
      </section>
      <footer
        :class="['modal-card-foot', 'd-flex',
                 hideDiscard || isAlertModal ? 'justify-end' : 'justify-space-between']"
      >
        <b-button
          v-if="!hideDiscard && !isAlertModal"
          data-unit="discard-trigger"
          :disabled="loading"
          :type="tertiaryType"
          @click.exact="$emit('discard')"
          @keydown.enter="$emit('discard')"
        >
          {{ tertiaryText }}
        </b-button>
        <div v-if="isAlertModal">
          <b-button @click.exact="handleClose">
            {{ $t('close') }}
          </b-button>
        </div>
        <div v-else>
          <b-button
            @click.exact="$emit('cancel')"
            @keydown.enter="$emit('cancel')"
            data-unit="cancel-trigger"
            :disabled="loading"
            :type="secondaryType"
            v-if="!hideCancel"
          >
            {{ $t('cancel') }}
          </b-button>
          <b-button
            :type="primaryType"
            :loading="loading"
            :disabled="primaryDisabled"
            data-unit="confirm-trigger"
            @click.exact="handlePrimaryClick"
            @keydown.enter="$emit('save')"
          >
            {{ primaryText }}
          </b-button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationModalV2',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    dataUnit: {
      type: String,
      default: 'confirmation-modal-v2'
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    primaryText: {
      type: String,
      required: false,
      default: 'Save'
    },
    primaryType: {
      type: String,
      required: false,
      default: 'is-success'
    },
    primaryDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    secondaryType: {
      type: String,
      required: false,
      default: ''
    },
    tertiaryType: {
      type: String,
      required: false,
      default: ''
    },
    tertiaryText: {
      type: String,
      required: false,
      default: 'Discard'
    },
    hideDiscard: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false
    },
    // Modal message with zero requests; simply close
    isAlertModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    isActive: false
  }),
  methods: {
    handleClose () {
      this.isActive = false
    },
    handleOpen () {
      this.isActive = true
    },
    open () {
      this.isActive = true
    },
    handlePrimaryClick () {
      // @todo refactor all components which listen for @save
      this.$emit('save')

      this.$emit('primary-click')
    }
  },
  mounted () {
    this.$root.$on('escPressed', this.handleClose)
  },
  beforeUnmount () {
    this.$root.$off('escPressed', this.handleClose)
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/abstracts/_breakpoints.scss";

.confirmation-modal-v2 {
  .text-wrapper {
    @include bulma-breakpoint('mobile') {
      max-width: 90%;
    }
    @include bulma-breakpoint('tablet') {
      max-width: 80%;
    }
    @include bulma-breakpoint('desktop') {
      max-width: 75%;
    }
  }

  .modal-card-body {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
</style>
