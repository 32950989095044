<template>
  <div  v-d-click-outside="closePopover">
    <popover  :isBottom="isBottom"
              :popoverPosition="popoverPosition"
              arrowPosition="right"
              style="padding: 8px;">
        <thumbnail  :isWidget="isWidget"
                    :thumbnail-url="bomItemUrl"
                    :thumbnail-type="type"
                    style="height: 250px; width: 250px;"/>
    </popover>
  </div>
</template>
<script>
import Thumbnail from '@/components/common/StatelessThumbnail.vue'
import Popover from "@/components/common/popover/BOMPopover.vue";
export default {
  name: 'BomImagePopover',
  components: {Popover, Thumbnail },
  props: {
    bomItemUrl: {
      type: Object,
      required: true
    },
    type: String,
    isBottom: Boolean,
    bomItemId: String,
    popoverPosition: Number,
    isWidget: Boolean
  },
  methods: {
    closePopover (event) {
      if(event.target.id.includes(`imagePopoverIcon` + this.bomItemId)) return
      this.$emit('closeThumbnailPopover')
    },
    keyPress({ key }) {
      if (key === 'Escape' || key === 'Esc') {
        this.$emit('closeThumbnailPopover')
      }
    }
  },
  created() {
    if (!window) return
    document.addEventListener('keyup', this.keyPress)
  },
  beforeDestroy() {
    if (!window) return
    document.removeEventListener('keyup', this.keyPress)
  }
}
</script>

<style scoped>
</style>
