<template>
  <li class="toc-item"  @click.stop.prevent="select(itemPath)">
    <span v-if="isWidget"
          :id="'toc-item-' + flatListIdx"
          :class="{ 'is-active': isWidget && (itemPath === widgetContentPath),
                    'toc-menu-list': isWidget }"
          :href="'/ui' + itemPath">
      <toc-item :item="item"
                :highlighted="highlighted"
                :open="open"
                @toggle="toggle"/>
    </span>
    <router-link  v-else
                  :to="{ path: itemPath }"
                  :id="item.type + ':' + item.id"
                  exact>
      <toc-item :item="item"
                :highlighted="highlighted"
                :open="open"
                @toggle="toggle"/>
    </router-link>
    <template v-if="item.children">
      <ul v-show="open"
          v-for="(child, childIdx) in item.children"
          :key="child.id"
          :class="{'menu-list-highlighted': isHighlighted(child)}">
        <table-of-contents-item :item="child"
                                :item-path="itemPath + '/' + child.type + ':' + child.id"
                                :highlighted="highlighted"
                                :force-open="forceOpen"
                                :is-widget="isWidget"
                                :flat-list-idx="flatListIdx + childIdx"
                                :widget-content-path="widgetContentPath"
                                @click.stop.prevent="select"
                                @widgetNav="$emit('widgetNav', $event)"/>
      </ul>
    </template>
  </li>
</template>

<script>
import TocItem from './TocItem.vue'

export default {
  name: 'TableOfContentsItem',
  components: { TocItem },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    itemPath: {
      type: String,
      default: ''
    },
    highlighted: {
      type: Array,
      default: () => []
    },
    forceOpen: {
      type: Number,
      default: 0
    },
    isWidget: Boolean,
    flatListIdx: {
      type: Number,
      required: true
    },
    widgetContentPath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      open: true
    };
  },
  watch: {
    forceOpen(newValue, oldValue) {
      this.open = newValue > oldValue;
    }
  },
  methods: {
    isHighlighted(item) {
      return this.highlighted.some((tocItem) => tocItem.entityId === item.id && tocItem.entityType === item.type);
    },
    toggle() {
      this.open = !this.open;
    },
    select(itemPath) {
      const event = this.isWidget ? 'widgetNav': 'select'
      this.$emit(event, itemPath);
    }
  }
};
</script>

<style scoped lang="scss">

a {
  width: 100%;
}

.menu-list li ul {
  margin: 0 0 0 1rem;
  padding: 0 0 0 .1rem;
  border-left-width: .25rem;
}

.toc-menu-list:hover {
  cursor: pointer;
  background-color: hsl(0, 0%, 96%);
}

.toc-menu-list {
  border-radius: 2px;
  padding: 0.5em 0.75em;
  color: #4a4a4a;
  display: block;
  width: 100%;

  &.is-active {
    background-color: var(--primary);
    color: var(--primary--color-invert);
    border-radius: 4px;
  }
  &.is-active:hover {
    background-color: var(--primary);
  }
}

</style>
