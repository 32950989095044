import { successNotification, errorNotification } from '@/helpers'

export const serviceDecorator = {
  ...errorNotification,
  ...successNotification,
  async serviceDecorator(fun, errorToastTr = null, successToastTr = null) {
    this.isLoading = true
    if (Object.prototype.hasOwnProperty.call(this, "dirty")) {
      this.dirty = false
      // Next tick ensures NavAwayModal doesn't fire
      await this.$nextTick()
    }
    try {
      await fun()
      successToastTr && this.successNotification(successToastTr)
    } catch(e) {
      this.errorNotification(errorToastTr)
      console.error(e)
    }
    // Ensure spinners show for >= 250 ms
    await new Promise(resolve => {
      setTimeout(resolve, 250)
    })
    this.isLoading = false
  }
}