/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a}/{b}',
  about: '关于',
  aboutThisItem: '有关此条目',
  accessControls: '访问控制',
  accessControlsCopiedText: '访问控制会立即更新。媒体安全权限将在接下来的几分钟内更新。',
  accessControlsCopiedTitle: '访问控制已成功复制',
  accessControlsCopyConfirmation: '这将从{to}中删除所有现有的访问控制，并将其替换为{from}中的访问控制。此操作无法撤消。',
  accessControlsDescription: '访问控制根据选定的组织和媒体类别定义谁可看到此媒体。',
  accessControlsIndeterminance: '注意：父级组织始终有权访问分配给其任何子级的内容。',
  account: '帐户',
  accountErrorMessage: '帐户错误消息',
  action: '操作 | 操作',
  actionDelete: '删除',
  actionLoad: '加载',
  actionPaste: '粘贴',
  actionSave: '保存',
  actionRun: '运行',
  actionUndoWarning: '此操作无法撤消。',
  actionUpload: '上传',
  actualSize: '实际大小',
  add: '添加',
  addAPIKey: '添加一个 API 秘钥',
  addItem: '添加{item}',
  addABookText: '添加手册',
  addASharedCart: '添加共享购物车',
  addATemplate: '添加一个模板',
  addATag: '添加标签',
  addABookToStart: '{addABookText}以开始。',
  addAForm: '添加表单以开始',
  addAFormOnly: '添加表单',
  addAMedia: '添加一个媒体',
  addAnOrderQuoteEmailFormat: '添加订购/报价电子邮件格式',
  addAPrintSettingText: '添加打印设置',
  addAPrintSettingToStart: '{addAPrintSettingText}以开始。',
  addBooks: '添加手册',
  addChildBrowseFlow: '添加子浏览流',
  addChapter: '添加章节',
  addComment: '添加评论',
  addExistingPart: '添加现有零件',
  addGroupsToUsers: '添加 {groups} 个分组到 {users} 名用户',
  additionalInformation: '附加信息',
  additionalNotification: '附加通知',
  additionalPartNumberDescription: '用作附加零件编号字段',
  addBrowseFlow: '添加浏览流',
  addMedia: '添加媒体',
  addPages: '添加页面',
  addPart: '添加零件',
  addReports: '添加报告',
  addRootBrowseFlow: '添加根浏览流',
  addRow: '添加行',
  addTemplates: '添加模板',
  addToCartMessage: '成功添加{partNumber}',
  addToUserGroup: '添加至分组',
  address: '地址 | 地址',
  addressInUse: '当前地址已设置为默认地址，无法删除',
  addressInUseSubtitle: '要删除此地址，必须先将此地址作为默认地址移除。',
  addressLine: '地址行 {number}',
  addWidgetKey: '增添一个小工具键',
  admin: '管理员',
  adminCenter: '管理中心',
adminCenterBanner: {
    bodyLabel: '横幅主体',
    enableLabel: '启用管理横幅',
    headerGreenOption: '绿色',
    headerLabel: '横幅标题',
    headerRedOption: '红色',
    headerYellowOption: '黄色',
    title: '管理中心横幅',
    typeLabel: '横幅类别'
  },
  adminCenterMessage: '内容、用户、库设置、报告和导出的管理中心',
  adminMessage: '已在可用时添加功能',
  administration: '管理',
  affectedBookMedia: '将会更新 {count} 个图书式媒体。',
  affectedTemplateMedia: '将会更新 {count} 个图书式模板。',
  after: '之后',
  aggregatedFromChildren: '从子级汇总',
  aiAlgorithm: '人工智能扫描仪',
  all: '所有',
  alphanumeric: '字母数字',
  algorithm: '算法',
  alignHorizontal: '水平对齐',
  alignVertical: '垂直对齐',
  analyticsJSON: '分析 JSON',
  andCountMore: '以及另外 {count} 个...',
  any: '任何',
  apiKey: 'API 秘钥 | API 秘钥',
  apiKeyAdmin: 'API 秘钥管理员',
  apiAuthHeader: 'API 授权标头',
  apiKeyCopied: '已将 API 授权标头值复制到剪贴板',
  apiKeyDeleted: 'API 秘钥已删除',
  apiKeyDeletedError: '删除 API 秘钥时发生错误',
  apiKeyDeleteMsg: '删除此 API 秘钥将禁用其在集成中的应用',
  apiKeyDeleteTitle: '是否确实要删除此 API 秘钥？',
  apiKeyInstructions: '保存时即生成秘钥',
  apiKeyManager: 'API 秘钥管理器',
  apiKeyNameEmpty: '名称不能为空',
  apiKeyRegenError: '重新生成 API 秘钥时发生错误',
  apiKeyRegenMsg: '重新生成此 API 秘钥将禁用已存在的集成',
  apiKeyRegenTitle: '是否确实要重新生成此 API 秘钥？',
  apiKeySaved: 'API 秘钥已保存',
  appliesTo: '应用于',
  appliesToEmpty: '要求至少选择一种实体类型',
  appliesToPopulated: '设置标签可应用于哪些实体',
  applyItem: '应用{item}',
  applyToChildItems: '应用到子级{item}',
  april: '四月',
  area: '区域',
  areYouSure: '是否确实要删除{item}？',
  areYouSureCopy: '是否确实要复制{item}？',
  assembly: '组件 | 组件',
  assemblyCreatorSuccessTitle: '创作汇编工作已提交',
  assemblyCreatorSuccessSubtitle: '热点链接和手册目录的更改将立即进行。索引编制完成后，将反映搜索结果。',
  assemblyConfirmationTitle: '是否要完成创建组件？',
  assemblyCreatorHelpText1: '利用此功能，您可以将选定的页面插入到有此零件{partName}的任何手册中，并通过热点链接自动将零件和选定的页面链接起来。您可以选择限制仅向选定的手册插入。',
  assemblyCreatorHelpText2: '{partName}第一次出现之后，在每本手册中插入一次选定的页面。',
  assemblyStepOne: '步骤 1：选择要插入的页面',
  assemblyStepTwo: '步骤 2：选择要插入该页面的手册',
  asset: '资产 | 资产',
  assetsNotFound: '未找到{type}',
  assignBrowseFlow: '分配浏览流',
  assignedOrganizations: '已分配组织',
  atLeastFiveError: '请选择一个不小于 5 的值。',
  attachment: '附件 | 附件',
  attachmentEditorDescription: '将图像、视频和文档直接附加到该实体。请注意，您将在“相关”选项卡中找到附加的内容。',
  attachmentView: '查看附件',
  attachRelatedPDFs: '附上相关零件页面 PDF',
  attributions: '属性',
  august: '八月',
  automaticZoom: '自动缩放',
  autoHotpoint: '自动热点',
  autoPopulate: '自动填充',
  availability: '可用性',
  back: '返回',
  backCover: '封底',
  backStitch: '后拼接',
  backToLibrary: '返回库',
  backToSearch: '返回至搜索',
  badCredentials: '无效凭据',
  bahasaDescription: '印尼语描述',
  bahasaName: '印尼语名称',
  banner: '横幅',
  before: '之前',
  betaAccessKeys: 'Beta 访问秘钥',
  betaResources: '测试版资源',
  betaReleaseVideo: '测试版视频',
  blobFinder: 'BLOB 查找器',
  body: '主体',
  bomDefaultSupplier: 'BOM 默认供应商',
  bomItem: '物料清单项目',
  bom: '物料清单',
  bomExportSuccess: 'BOM 导出成功',
  bomLayout: '物料清单版式',
  bomListOptions: 'BOM 清单选项',
  bomListSettings: 'BOM 清单设置',
  both: '两种',
  book: '手册 | 手册',
  bookInserts: '手册插页',
  bookLabel: '手册 | 手册',
  bookPrintingDisable: '禁用手册打印',
  booksAndPagesDisplay: '手册和页面显示',
  boost: '搜索提升',
  boostDescription: '添加一个大于 1.5 的值，以提升该标签在零件编号、媒体标识符和名称上的搜索相关性',
  boostRangeInvalid: '增量必须在 0.5 和 100.0 之间',
  border: '边框',
  bottom: '底部',
  branding: '品牌信息',
  browse: '浏览',
  browseComputer: '浏览计算机',
  browseFlow: '浏览流 | 浏览流',
  browseFlowAssigned: '已分配浏览流',
  browseFlowCopyMessage: '为新浏览流输入唯一的名称。',
  browseFlowDeleted: '浏览流程已删除',
  browseFlowDeleteMessage: '删除浏览流不仅会删除该浏览流，也会删除其所有子浏览流。',
  browseFlowEmptyState: '未定义浏览流。{new}以开始。',
  browseFlowHeirarchy: '浏览流等级',
  browseFlowNoExistingCriteria: '未为此浏览流设置搜索条件。  请使用上方控制选项选择搜索条件。',
  browseFlowMoveToRoot: '将节点放置在此处以创建第一级子级',
  browseFlowNoAssignedOrgs: '没有分配的组织',
  browseFlowSaveFailed: '无法更新浏览流',
  browseFlowSetDefaultSuccess: '已设置默认浏览流',
  browseFlowThumbnailDeleteFailTitle: '缩略图删除失败',
  browseFlowThumbnailDeleteSuccessTitle: '缩略图删除成功',
  browseFlowThumbnailUploadFailTitle: '缩略图上传失败',
  browseFlowThumbnailUploadSuccessTitle: '缩略图上传成功',
  browseFlowTooManyOrgs: '您的组织太多，无法使用当前的浏览流编辑器。请发送电子邮件至：{email}，与 Documoto 支持人员联系，以获取有关编辑 Documoto 浏览流的帮助。',
  browserPartiallySupport: '您可能会在本浏览器中看到外观差异及其他变化。',
  browseFlowSaved: '已保存浏览流',
  browserDeprecation: {
    title: '浏览器弃用通知',
    text: '由于微软逐步取消对 Internet Explorer（所有版本）的支持以及出于相关安全问题的考虑，我们在 2021 年 9 月 30 日后将不再支持用户通过 Internet Explorer（所有版本）访问本网站。'
  },
  buildHotpointLinks: '构建热点链接',
  buildHotpointLinksInfo: '此操作为叠加型，其不会移除任何已存在的热点链接。若先前不存在热点链接，执行此操作将在零件和任何匹配的组件页面之间创建新的热点链接。',
  buildHotpointLinksTitle: '是否要在这本手册上建立热点链接？',
  bulkIndexingLane: '批量索引信道',
  bulkInsert: '批量插入',
  bulkExportConfirm: '是否要导出 {n} 个 {unit}？',
  bulkExportLimit: '每次导出任务上限为 {limit} 本手册',
  bulkPageInsertJob: '批量页面插入',
  business: '业务',
  cancel: '取消',
  canceled: '已取消',
  cancelAssemblyCreatorTitle: '放弃您的创作汇编？',
  cancelAssemblyCreatorSubtitle: '将不会处理您的创作汇编。',
  cannotExceed100: '不能超过 100%',
  cart: '购物车 | 购物车',
  cartBehaviorEnabled: '启用添加至购物车数量提示',
  cartBehaviorDisabled: '禁用添加至购物车数量提示',
  cartBehaviorLabel: '添加至购物车数量行为',
  cartColumn: '购物车栏 | 购物车栏',
  cartColumn1: '第一个购物车价格栏',
  cartColumn2: '第二个购物车价格栏',
  cartColumn3: '第三个购物车价格栏',
  cartColumnPartLocationContent1: '启用此功能将会导致以下后果：  ',
  cartColumnPartLocationContent2: '购物车中的零件行唯一性也将基于其在一块内容中的位置（如适用）。',
  cartColumnPartLocationContent3: '此设置无法在组织级别禁用且将应用于所有用户。',
  cartColumnPartLocationContent4: '如果您未事先联系 Documoto 客户成功经理便集成了提交购物车，则不应启用此设置。',
  cartColumnPartLocationOption: '启用购物车中的零件位置数据',
  cartColumnPartLocationTitle: '是否确实要启用购物车中的零件位置数据？',
  cartDisable: '禁用购物车',
  cartEmailFailed: '购物车电子邮件失败',
  cartEmailSent: '购物车电子邮件已发送',
  cartEnable: '启用购物车',
  cartSaveEnable: '启用保存购物车',
  cartErpMessage: '已成功发送到 ERP',
  cartImportError: '导入购物车时出错',
  cartLoadedWithItemsRemoved: '{count} 项目已删除，因为您目前没有订购这些项目的权限',
  cartSharedAddEdit: '添加/编辑共享购物车',
  cartUpdated: '购物车已更新',
  cartUpdatedAndRemovedCount: '购物车已更新；已删除 {count} 个不可订购的零件',
  category: '类别 | 类别',
  categoryCreated: '类别已创建',
  categoryUpdated: '类别已更新',
  centimeter: '厘米 | 厘米',
  changeNotification: '更改通知',
  changeScope: '{n} 个{type}将受影响',
  changeUserOrganization: '更改组织',
  chapter: '章节 | 章节',
  chapterHeader: '章节页眉',
  chaptersOnly: '仅章节',
  chineseDescription: '中文描述',
  chineseName: '中文名称',
  circle: '圆形',
  city: '城市',
  classicAdminMessage: '这将带您返回 Flex，以使用管理员模块。',
  classicAdmins: '典型管理员',
  classicExporter: '典型导出程序',
  classicExporterMessage: '基于 Flash 的旧版内容导出模块',
  classicOrganizationAdmin: '典型组织管理员',
  classicOrganizationAdminMessage: '基于 Flash 的旧版用户管理模块，用于管理组织和用户',
  classicPublisher: '典型发行商',
  classicPublisherMessage: '基于 Flash 的旧版发行商模块，用于内容创建和打印设置',
  classicReporting: '典型报告',
  classicReportingMessage: '基于 Flash 的旧版报告模块',
  classicTenantAdmin: '典型租户管理员',
  classicTenantAdminMessage: '基于 Flash 的旧版管理模块，用于整个租户的设置',
  className: '类名',
  clear: '清除',
  clearItemNumber: '清除项目编号',
  clearItemNumberTitle: '您是否想要清除项目编号？',
  clearItemNumberMessage: '清除项目编号将导致删除以下数据:',
  clearFilters: '清除过滤条件',
  clearHotpoints: '清除热点',
  clearSearch: '清除搜索',
  clickHereToUpload: '拖放文件或点击上传替代文件。',
  clickOkToContinue: '点击 "OK" 以继续。',
  clickToDownload: '点击以下载',
  clickToOpen: '点击以打开',
  clipboard: '剪贴板',
  clipboardAccessError: '请调整浏览器设置以允许使用剪贴板',
  close: '关闭',
  closed: '已关闭',
  collapseAll: '全部折叠',
  color: '颜色 | 颜色',
  column: '栏 | 栏',
  columnName: '列名',
  columnItemType: {
    customText: '自定义文本',
    documentTitle: '文档名称',
    horizontalLine: '水平线',
    pageName: '页面名称',
    pageNumber: '页码',
    printDate: '打印日期',
    tenantName: '租户姓名',
    userName: '用户名'
  },
  combinedPage: '合并页面',
  combinedPageMessage: '图表在顶部，零件列表在底部',
  comingSoon: '即将推出',
  commaDelimited: '逗号分隔',
  comment: '注释 | 注释',
  commentAdded: '评论已添加',
  commentDeleted: '评论已删除',
  commentEdit: '添加/编辑评论',
  commentEditAll: '编辑所有评论',
  commentUpdated: '评论已更新',
  commentView: '查看评论',
  commerceSystem: '商务系统',
  completed: '已完成',
  confirm: '确认',
  confirmBrowseFlowApplyToChildren: '是否要将此浏览流应用于子级组织？',
  confirmBrowseFlowOverrideToChildren: '这将删除在子级组织上设置的所有替代浏览流。',
  confirmPrintSettingsApplyToChildren: '是否要将此打印设置应用于子级组织？',
  confirmPrintSettingsOverrideToChildren: '这将删除在子级组织上设置的所有替代打印设置。',
  contains: '包含',
  containsHeaderRow: '包含标题行',
  content: '内容 | 内容',
  contentAccessControls: '{content}访问控制',
  contentEntity: '内容实体 | 内容实体',
  contentNotFound: '未找到内容',
  contentUpdatedQueueJob: '更新内容队列',
  contourShape: '轮廓形状',
  convertEntity: '转换 {entity}',
  convertToEntity: '转换为 {entity}',
  copyAccessControls: '复制访问控制',
  copyAccessControlsWarning: '这将使所有已分配的访问控制从所选组织复制到当前组织',
  copy: '复制',
  copyAndRetain: '复制并保留，以便集成使用',
  copyAndRetainWidget: '复制并保留，仅在此时可在应用中看到小工具授权值',
  copyAtoB: '复制{a}到{b}',
  copyContent: '复制{type}？{name}',
  copyingItem: '复制 {item}',
  copyItem: '复制{item}',
  copyItemQuestion: '复制 {item}？',
  copyTo: '复制到{target}',
  copyMeMessage: '将此信息抄送给我',
  copyrightMessage: '©  2010-{year} Documoto Inc. 保留所有权利。',
  count: '数量',
  country: '国家/地区 | 国家/地区',
  coverPages: '封面',
  coverAmpStitchPages: '封面和拼接页',
  createAssembly: '创建组件',
  created: '已创建',
  createdBy: '创建者：{name}',
  createdDate: '编写日期',
  createdOn: '创建日期',
  currentPassword: '当前密码',
  custom: '定制',
  czechDescription: '捷克语描述',
  czechName: '捷克语名称',
  dashboard: '仪表板 | 多个仪表板',
  dashboardLibrary: '控制面板资料库',
  dataInsights: '数据洞察',
  dataInsightsInfoMessage: '针对内容、用户和资料库数据洞察的报告和控制面板中心。',
  dataMapping: '数据映射/样本数据（最多 10 行）',
  dataOptions: '数据选项',
  date: '日期',
  dateModified: '修改日期',
  debugPageBuilderPLZ: 'Debug 页面生成器 PLZ',
  december: '十二月',
  decreaseHotpoint: '减少热点',
  default: '默认',
  defaultAddress: '默认地址',
  defaultBrowseFlow: '默认浏览流',
  defaultCurrencyCode: '默认货币代码',
  defaultCurrencySymbolOn: '默认货币符号开启',
  defaultEmailRecipient: '默认收件人 | 默认收件人',
  defaultImage: '默认图像 | 默认图像',
  defaultImagesManager: '默认图像管理器',
  defaultItemImage: '{item}默认图像',
  defaultPartsListLayout: '默认零件列表版式',
  defaultPartsListLayoutHelp: '将零件版式默认设置为插图左侧或下方',
  defaultSearchMode: '默认搜索模式',
  defaultSearchModeHelp: '将全局搜索方法默认设置为\'包含\'或\'精确\'',
  defaultSortMode: '默认排序模式',
  defaultSupplier: '默认供应商',
  defaultTocStateOpen: '目录状态默认打开',
  defaultTocStateOpenHelp: '确定是否应在查看手册时自动打开目录',
  defaultUom: '默认计量单位',
  defaultValue: '默认值',
  defaults: '默认值',
  defaultsAndCapabilities: '默认值和功能',
  delete: '删除',
  deleteAll: '从父项继承',
  deleteContent: '是否确实要删除{type}？{name}',
deletePart: {
    infoOne: '部件删除将立即在库和内容管理中生效。在数分钟内重新索引进行之前，这些已删除的部件将保持可搜索状态。',
    deletingPartNumber: '{partNumber} 将被删除。',
    affectedCarts: '所有包含此部件的购物车都将永久删除该部件。',
    infoTwo: '如果没有 Documoto 支持人员的帮助，则无法撤消此操作。90 天后，删除的记录和所有关联将被永久清除。'
  },
deletePartInUse: {
    title: '此部件属于页面，无法删除。',
    body: 'Documoto 目前仅支持删除不属于页面的部件。请从任何已发布或草稿页面中删除此部件，然后才能继续删除。',
    submitLabel: '转至“使用过页面”'
  },
  deleteRelated: '是否确实要删除{count} {type}？',
  deletedItem: '{item} 已删除',
  deleteFilesMessage: '是否确实要删除 {count} 个文件？',
  deleteHotpointLinksTitle: '您确定要删除热点链接吗？',
  deleteMessage: '是否确实要删除？',
  deleteSelectedCriteria: '删除选定的搜索条件',
  deleteTagsTitle: '您确定要删除标签吗？',
  deleteTaxonMessage: '这将永久删除此分类及其任何子级。是否确实要继续？',
  deleteTaxonomyMessage: '这将永久删除此组织或租户的所有分类。是否确实要继续？',
  deleteTenantSupplier: '是否确定要将租户的此供应商删除？',
  demo: '样本',
  description: '说明',
  deselectAll: '取消全选',
  deselectCriteria: '取消选择搜索条件',
  detail: '详细信息 | 详细信息',
  diagonal: '对角线',
  diameter: '直径',
  disable: '禁用',
  disableAll: '禁用所有',
  disableFwdReindex: '禁用 DSW 转发重新索引目标媒体',
  disableTEK: '禁用直接 URL 登录',
  disabled: '已禁用',
  discard: '舍弃',
  discardChanges: '舍弃更改',
  discardQ: '要舍弃更改？',
  discounted: '折扣',
  discountedPrice: '折扣价',
  discountedTotal: '折扣合计',
  discountPrice: '折扣价',
  displayInOrdersList: '在订单列表中显示',
  displayOrder: '显示订单',
  displayOrderDescription: '设置内容类型标签的“过滤器”下拉列表的显示顺序',
  document: '文档 | 文档',
  documentLabel: '文档 | 文档',
  documotoAcademy: 'Documoto 学院',
  documotoLicensePackage: 'Documoto 许可证包',
  domain: '网域',
  download: '下载',
  downloadBrowser: '下载其中一款优秀的浏览器。',
  downloadFailed: '下载时出错 {name}',
  downloadStarted: '正在下载 {name}',
  draft: '设计图 | 设计图',
  draftBomDeleteTitle: '您确定要将此部件从该页面删除吗？',
  draftBomMultipleDeleteTitle: '您确定要将这些部件从该页面删除吗？',
  draftBomDropError: '无法嵌套超过 5 层的部件',
  draftBomEmptyField: '部件列表中的所有字段必须填写',
  draftBomQuantityError: '数量必须是数字',
  draftBomError: '处理物料清单草稿出错',
  draftPage: '草稿页面',
  draftPageDelete: '删除草稿',
  draftPageDeleteMessage: '草稿页面已成功删除。',
  draftPageDeleteWarning: '删除页面草稿将删除正在进行的更新。',
  draftPageLastUpdated: '草稿 - 上次于 {date} 由 {username} 进行更新',
  draftPageLoadError: '设计图页面上传失败。',
  draftPageProcessingPlz: '正在从 PLZ 文件加载页面',
  draftPagePublishInProgress: '正在进行页面发布工作',
  draftPageSaveSuccess: '草稿页面已成功保存。',
  draftPageSaveUnsuccessful: '草稿页面保存失败。',
  draftSheetImageHotpointsFailure: '无法为草稿图像生成 {algorithm} 热点。',
  draftValidationMessage: {
    item: '警告：BOM 部件编号 {partNumber} 没有指定的项目编号',
    empty: '无验证错误/警告',
    hotpointOrHotpointLink: 'BOM 货品 {item} 未指明热点/热点链接',
    quantity: 'BOM 货品 {item} 未指明数量',
    tag: 'BOM 货品 {item} 标签 {tagName} 缺少标签值',
    translationDescription: 'BOM 货品 {item} 缺少 {lang} 翻译描述',
    uom: 'BOM 货品 {item} 未指明计量单位',
    supersede: '由于进行替换，部件 {partNumber} 替换了 {supersededPartNumber}。'
  },
  drawAutohotpointRegion: '绘制{shape}尺寸',
  drawMinimumArea: '绘制最小面积',
  dropFileMessage: '拖放文件到此处或点击上传。',
  duplicate: '复制',
  duplicateSaveError: '重复条目。无法保存。',
  dutchDescription: '荷兰语描述',
  dutchName: '荷兰语名称',
  dynamic: '动态',
  dynamicNaming: '动态命名',
  ecommerce: '电子商务',
  ecommerceEmailFormatCreated: '电子商务电子邮件格式已创建',
  ecommerceEmailFormatSaved: '电子商务电子邮件格式已保存',
  ecommerceLogo: '电子商务标志',
  ecommerceUseExtOrder: '电子商务使用外部订单编号',
  edit: '编辑',
  editItem: '编辑{item}',
  email: '电子邮箱',
  emailFormat: '电子邮件格式',
  emailFormatTemplate: '电子邮件格式模板 | 电子邮件格式模板',
  emailList: '电子邮件列表',
  emailMessage: '邮箱是必填项',
  emailRecipient: '收件人 | 收件人',
  emailRecipientMessage: '这些电子邮箱地址将会收到电子邮件：如果订单状态更改为',
  emptyMessage: {
    orderSuggestions: '此零件似乎还没有订购建议。'
  },
  emptyBomState: '页面无定义的物料清单项目。点击 +{add} 以开始操作',
  enable: '启用',
  enableAll: '启用所有',
  enableAPIKeyAdmin: '启用 API 秘钥管理员',
  enabled: '已启用',
  enableCommerce: '启用商务',
  enableEcommerceOrderManagement: '启用电子商务订单管理',
  enableExporter: '启用导出程序',
  enableExportToLinkOne: '启用导出至 LinkOne',
  enableExportToSmartEquip: '启用导出至 SmartEquip',
  enableLibraryBetaFeatures: '启用库测试版功能',
  enablePerformanceLogging: '启用性能日志记录',
  enablePLZToDraft: '启用要起草的 PLZ 流程',
  enableRocketUi: '启用 Rocket 用户体验',
  enableWidgetLogo: '启用 Documoto 小工具徽标',
  enableWidgets: '启用小工具',
  encryptedKey: '已加密密钥',
  endDate: '结束日期',
  endDateBad: '开始日期必须等于或早于结束日期。',
  enforceNumericSize: '强制执行数字大小',
  englishDescription: '英文描述',
  englishName: '英文名称',
  enterprise: '企业单位',
  enterReportParameters: '输入报表参数',
  enterUniqueNameForNewItem: '为新的{item}输入唯一的名称',
  enterValidNumber: '请输入一个有效数字',
  enterValidEmail: '请输入有效的电子邮箱',
  entityType: '实体类型 | 实体类型',
  erp: 'ERP',
  erpEndpointConfig: 'ERP 端点配置',
  erpPartInfo: 'ERP 部件信息缓存持续时间分钟数',
  erpSystem: 'ERP 系统',
  erpSystems: 'ERP 系统',
  erpSystemSaved: 'ERP 系统已保存',
  error: '错误',
  errorDeletePageMessage: '删除本页面时出错。',
  errorInputParsing: '问题解析输入文件。',
  errorPageMessage: '发生错误。',
  errorPageTitle: '出错了……',
  errorType: {
    error: '错误',
    warning: '警告'
  },
  essential: '必要组成部分 | 必要组成部分',
  eta: '预计到达时间',
  etlJob: 'ETL',
  exact: '精确',
  excelFile: 'Excel 文件',
  expandAll: '全部展开',
  export: '导出',
  exportSearch: '导出搜索结果',
  exportAsCsv: '以 CSV 格式导出（导入所需的格式）',
  exportAsCSVDropdown: '以 CSV 格式导出',
  exportAsHtml: '以 HTML 格式导出',
  exportAsPdf: '以 PDF 格式导出',
  exportAsPlz: '以 PLZ 格式导出',
  exportAsXlsx: '以 XLSX 格式导出',
  exportBasicPartInfo: '导出基本零件信息',
  exportCartToXlsxDisabled: '禁用将购物车导出至 XLSX',
  exportCartToXlsxEnabled: '启用将购物车导出至 XLSX',
  exportDisplayedCartColumns: '导出显示的购物车栏',
  exportJob: '导出任务',
  exportLane: '导出通道',
  exportMessage: '已成功提交的导出任务。',
  exporter: '导出程序',
  exporterMessage: '允许您导出内容',
  exportParts: '导出零件',
  external: '外部 | 外部',
  externalUrl: '外部网址',
  faceted: '已刻面',
  facetedTag: '过滤器值应多元化',
  facetedTagTooMany: '标签超过 1,000 个唯一标签值限制',
  failedAction: '{content}{action}失败。',
  failedProcessContentSql: '由于未经授权的文本，处理内容失败。请重新上传受支持的内容。',
  failedToCopy: '{content} 复制失败。',
  failedToDelete: '{content}删除失败。',
  failedToLoad: '{content}加载失败。',
  failedToSave: '{content}保存失败。',
  failedToSubmitItem: '提交{item}失败',
  failedToUpdate: '{content} 更新失败。',
  false: '错误',
  faq: '常见问题解答',
  favorites: '收藏夹',
  favoritesLists: '收藏夹清单',
  february: '二月',
  feedbackForm: '提交反馈',
  feedbackFormMessage: '您希望看到哪些功能，欢迎提供宝贵意见',
  fieldDefinition: '字段定义',
  field: '字段 | 字段',
  fieldName: '字段名称',
  file: '文件 | 文件',
  filename: '文件名',
  fill: '填充',
  filter: '过滤器 | 过滤器',
  filterOrgNameOnly: '筛选：仅组织名称',
  filterOrgParentNames: '筛选：组织和母公司',
  filterable: '可筛选',
  filterableTag: '可过滤的标签',
  filterBy: '过滤条件',
  filterBoundsError: '过滤器必须包含 3 个或以上字符',
  finnishDescription: '芬兰语描述',
  finnishName: '芬兰语名称',
  firstName: '名字',
  firstNameEmpty: '名字不能为空',
  fixedHotpointSize: '固定热点大小',
  font: '字体 | 字体',
  footer: '页脚',
  forgotPassword: '忘记密码',
  forgotPasswordMessage: '我们会通过电子邮件将重置密码的说明发送到所提供的用户名。',
  forgotPasswordSuccess: '如果所提供的用户名有效，则会向存档的地址发送一封带有重置密码说明的电子邮件。',
  form: '表单 | 表单',
  format: '格式',
  formattingOptions: '格式化选项',
  formCount: '表格计数',
  formName: '表单名称',
  formType: '表单类型',
  freezeImage: '冻结图像',
  frenchDescription: '法语描述',
  frenchName: '法语名称',
  fri: '周五',
  from: '从',
  fromDate: '起始日期',
  frontCover: '封面',
  frontStitch: '前拼接',
  fullScreen: '全屏',
  gdprFaqs: '《一般数据保护条例》常见问题解答',
  gdprMessage: '登录我们网站的这个部分即表明您了解我们使用必需的 Cookie。这些 Cookie 对于您能够顺利使用我们的服务是必需的。若没有这些 Cookie，我们无法提供您所请求的服务。欲获取更多信息，请参阅我们的{policy}。',
  general: '常规',
  generalSearch: '一般搜索',
  generic: '通用',
  germanDescription: '德语描述',
  germanName: '德语名称',
  goToHome: '前往“首页”',
  goToJobManager: '前往“任务管理器”',
  goToSearch: '前往“搜索”',
  hashKey: '哈希键',
  headerAmpFooter: '页眉与页脚',
  header: '页眉',
  help: '帮助',
  helpCenter: '帮助中心',
  highlightDescription: '用此标签突出显示零件列表中的零件',
  history: '历史记录',
  home: '主页',
  horizontal: '水平',
  host: '主机',
  hotpoint: '热点 | 热点',
  hotpointCalloutBehavior: '热点呼叫行为',
  hotpointLinksWillBeCreated: '热点链接将在 {count} 个{content}中创建',
  hotpointLink: '热点链接 | 热点链接',
  hotpointsGenerated: '图像上找到{count}个热点。',
  hotpointShift: '热点 (Shift H)',
  hourlyDWSRequestLimit: '每小时 DWS 请求限制',
  hourlyRESTRequestLimit: '每小时 REST 请求限制',
  htmlUiUserSwitchEnabled: 'HTML UI 用户开关已启用',
  hungarianDescription: '匈牙利语描述',
  hungarianName: '匈牙利语名称',
  icon: '图标',
  id: '标识符',
  identifier: '标识符',
  ignore: '忽略',
  ignoreAccessControls: '忽略访问控制',
  ignoreMatchingTranslations: '忽略导入部分具有匹配区域设置的翻译',
  illustration: '插图 | 插图',
  image: '图像 | 图像',
  imageDeleteMessage: '您确定要删除此页面图像吗？',
  imageUploadFailure: '图像上传失败',
  imageSaveFail: '图像保存失败',
  imageOptimizer: '图像优化器',
  imageSizeExceededTitle: '已超过最大图像尺寸',
  imageSizeExceededMessage: 'Documoto 中允许的最大图像文件大小为 {size} 兆字节。您正在试图上传的图像已超过最大限制，无法加载。请调整图像大小以添加到此页面。',
  imageDimensionsExceededMessage: 'Documoto 中的最大图像尺寸为 65.5 兆像素（8,191 X 8,191 像素）。  您正在试图上传的图像已超过最大限制，无法加载。点击“调整大小”可使 Documoto 调整图像大小并插入，或点击“取消”以手动编辑图像并插入',
  import: '导入',
  importBOM: '导入 BOM',
  importBOMError: '处理样本数据失败，请重新检查输入源',
  importParts: '导入零件',
  inCart: '在购物车中',
  inCartDescription: '在购物车中显示标签',
  includePagePartDetails: '包含页面部件级别的详细信息',
  includePartDetails: '包含部件级别的详细信息',
  indexQueueJob: '索引队列',
  inPartsList: '在零件列表中',
  inPartsListDescription: '在零件列表中显示标签',
  inputSource: '输入源',
  inch: '英寸 | 英寸',
  includeComments: '包括评论',
  includeCommentsAuthorAndDate: '包括评论作者和日期',
  includeHeaderRow: '包含标题行',
  includeImages: '包括图像',
  includePagesIndex: '包括页面索引',
  includePartsIndex: '包括零件索引',
  includePartsList: '包括零件列表',
  includeSubChapters: '包括子章节',
  indexLane: '索引通道',
  useSequentialPageNumbering: '使用连续页码',
  hideBookInSearchAndRecent: '在搜索结果和最近添加中隐藏手册',
  hideDuplicatePages: '隐藏重复页面',
  hidePageInSearchAndRecent: '在搜索结果和最近添加中隐藏页面',
  hidePartInSearchAndRecent: '在搜索结果和最近添加中隐藏零件',
  hotpointZoomBehavior: '热点缩放行为',
  includeNewParts: '仅包含“新”部件（不在 Documoto 中）',
  includeWatermark: '包含水印？',
  increaseHotpoint: '增加热点',
  indexed: '已编入索引',
  indexingConfiguration: '索引配置',
  indexIncludeTimestamp: '索引包括时间戳',
  info: '信息',
  information: '信息',
  inherit: '继承',
  inheritedParent: '从父项继承。',
  inputSpecification: '输入规格',
  inputType: '输入类型',
  inputTypeListboxWarning: '排序配置将在保存后显示',
  integration: '集成 | 集成',
  integrationsAddPartToErpCart: '{action} 添加零件到 ERP 购物车',
  integrationsAddToShoppingCartPricingFromErp: '{action} 从 ERP 添加至购物车定价',
  integrationsDisableErp: '禁用 ERP',
  integrationsDoErpForQuotes: '用 ERP 报价',
  integrationsEnableErp: '启用 ERP',
  integrationsEnableFetchPartInfoFromErp: '启用从 ERP 获取零件信息',
  integrationsEnableScopeErp: '启用 {scope} ERP',
  integrationsEnableSendShoppingCartToErp: '启用发送购物车到 ERP',
  integrationsEnableSyncPoAddressWithErp: '启用与 ERP 同步 PO 地址',
  integrationsErpAvailabilityMappedFrom: 'ERP 可用性映射自',
  integrationsErpCurrencyCodeMappedFrom: 'ERP 货币代码映射自',
  integrationsErpDiscountedPriceMappedFrom: 'ERP 折扣价格映射自',
  integrationsErpEtaMappedFrom: 'ERP 预计到达时间映射自',
  integrationsErpMappings: 'ERP 映射',
  integrationsErpRetailPriceMappedFrom: 'ERP 零售价格映射自',
  integrationsErpWholesalePriceMappedFrom: 'ERP 批发价格映射自',
  integrationsInvokeGetPartInfoFromClient: '从客户端调用 GetPartInfo',
  integrationsParseAddressForType: '解析类型地址',
  integrationsShoppingCartAndPartsTitle: '购物车及零件',
  integrationsShoppingCartAvailability: '{action} 购物车可用性',
  integrationsShoppingCartEtaFromErp: '{action} 来自 ERP 的购物车预计到达时间',
  internalDescription: '内部说明',
  internalName: '内部名称',
  internalNameOptional: '您的标签标识符',
  internalNameRequired: '名称为必填项',
  invalidDecimals: '小数点后仅可保留两位',
  invalidField: '{field}无效',
  invalidFileType: '不支持的文件类型。请参阅 Documoto 支持中心获取支持的文件扩展名的完整列表。',
  isSearchable: '可搜索',
  insertDataField: '插入数据字段',
  isSystem: '为系统',
  italianDescription: '意大利语描述',
  italianName: '意大利语名称',
  item: '#',
  itemAlt: '项目',
  itemCopied: '{item}已复制',
  itemDeleted: '{item}已删除',
  itemInProgress: '{item}进行中',
  itemInformation: '{item}信息',
  itemMustBeLteItem: '{itemA}必须等于或早于{itemB}。',
  itemMustBeUnique: '{item}必须为唯一',
  itemMustBeUniqueAndNotEmpty: '{item}必须为唯一且必填',
  itemName: '{item}名称',
  itemNotShown: '',
  itemSaved: '{item}已保存',
  itemSpecification: '货品规格',
  showChapterTOCForHiddenPages: '显示隐藏页面的章节目录',
  january: '一月',
  japaneseDescription: '日语描述',
  japaneseName: '日语名称',
  job: '任务 | 任务',
  jobManager: '任务管理器',
  jobManagerMessage: '导出、发布和打印/下载任务',
  july: '七月',
  june: '六月',
  koreanDescription: '韩语描述',
  koreanName: '韩语名称',
  label: '标签 | 标签',
  labelKey: '标签键',
  landscape: '横向',
  laneConfiguration: '通道配置',
  language: '语言',
  lastModifiedDate: '最后修改日期',
  lastGenerated: '最新生成的',
  lastName: '姓氏',
  lastNameEmpty: '姓氏不能为空',
  lastUpdatedOn: '最新更新日期',
  layout: '版式',
  layoutOptions: '布局选项',
  ledger: ' Ledger ',
  left: '左侧',
  legal: ' Legal ',
  letter: ' Letter ',
  library: '库',
  libraryAdmin: '库管理员',
  libraryBannerLogo: '图书馆横幅标志',
  librarySettings: '库设置',
  librarySettingsDescription: '此类设置控制各种库元素和选项。更改时请多加注意。有关更多信息，请联系 Documoto 服务部门。',
  licenseConfiguration: '许可证配置',
  line: '线条',
  lineTool: '画线工具',
  link: '链接 | 链接',
  listBox: '列表框',
  listBoxValue: '列表框的值 | 列表框的值',
  listValue: '列表值 | 列表值',
  load: '加载',
  loadMore: '加载更多',
  loading: '正在加载',
  locale: '区域设置',
  login: '登录',
  loginBackground: '登录背景',
  loginGDPRMessageBeginning: '此网站使用 Cookie。继续使用此网站即表明您同意使用 Cookie。欲获取更多信息，请参阅我们的 ',
  loginGDPRMessageEnd: '以及我们的',
  loginSSO: '单点登录',
  logiReportName: 'Logi 报告名称',
  logiReportToggle: 'Logi 报告？',
  logo: '徽标 | 徽标',
  logout: '退出',
  lookupLoadParts: '导入时从 Documoto 查找/加载零件',
  lowerBound: '下限',
  makePagesDivisibleBy: '按下列分割页面：  ',
  mainInfo: '主要信息',
  mainLogo: '主要徽标',
  manageItem: '管理{item}',
  management: '管理',
  march: '三月',
  margin: '边距 | 边距',
  markdown: '降价',
  may: '五月',
  media: '媒体 | 媒体',
  mediaCategory: '媒体类别 | 媒体类别',
  mediaCategoryEditor: '媒体类别编辑器',
  mediaCategoryName: '媒体类别名称',
  mediaCategoryNameMissing: '所有媒体类别都需要名称',
  mediaBookDeleteMessage: '删除此媒体将同时在“库”和“内容管理”中删除对此内容的访问权限 且用户或内容管理员将无法再使用此内容。 如果此手册中的任何零件或页面未出现在其他手册中，则这些零件或页面也将无法再在库中使用。',
  mediaDeleteMessage: '删除此模板会将其从“内容管理”中删除，并且 将再也无法复制到新媒体。',
  mediaDeleteSuccessText: '建立索引后，模板将从列表中删除。',
  mediaDescription: '媒体描述',
  mediaIdentifier: '媒体标识符',
  mediaName: '媒体名称',
  mediaPrintSettingsSaveSuccess: '媒体打印设置已保存',
  mediaWhereUsed: '媒体使用位置',
  menu: '菜单',
  merge: '合并',
  mergeOrOverrideMessage: '是否要覆盖或合并这些项目？',
  mergeWarning: '导入数据将更新现有零件并添加新条目',
  message: '消息',
  messageLevel: '消息级',
  microsite: '微型网站 | 微型网站',
  micrositeAdmin: {
    cardSubtitle: '压缩内容文件和 HTML 条目信息',
    cardTitle: '网站内容',
    errorRequiredFile: '需要网站内容 ZIP 文件',
    fieldFile: '网站内容 ZIP 文件',
    fieldPoint: 'HTML 入口点',
  },
  minArea: '最小面积',
  minAreaMessage: '最小值为必填项。值不能超过 1 至 1500 的范围。  必须为整数值。',
  multipleAlternatingPages: '多个交替页面',
  multipleAlternatingPagesMessage: '图表位于第 1 页和第 3 页，零件列表位于第 2 页和第 4 页。',
  mToM: 'M2M',
  multiplePages: '多页',
  multiplePagesMessage: '图表在其自己的页面上，随后是零件列表页面',
  requiredField: '必填字段',
  messageMessage: '消息为必填项',
  metadata: '元数据',
  mine: '我的',
  miscellaneous: '杂项',
  mon: '周一',
  myCart: '我的购物车 | 我的购物车',
  navigation: '导航',
  nItemsCreated: '{n} 个{items}已创建',
  nItemsDeleted: '{n} 个{items}已删除',
  nOfMItems: '{n} 个{items}，共 {m} 个',
  name: '名称',
  nameDescriptionHelpText: '名称和可选说明',
  new: '新建',
  newAPIKey: '新的 API 秘钥',
  newERPSystem: '新 ERP 系统',
  newItem: '新建{item}',
  newKeyGenerated: '新集成秘钥已生成，复制并保留以备未来使用',
  newMediaCategory: '新媒体类别',
  newPassword: '新密码',
  newsItem: '新闻内容 | 新闻内容',
  newTenant: '新的租户',
  newWidgetKey: '新小工具键',
  newWidgetKeyGenerated: '已生成新的小工具键，复制并保留以供以后使用',
  noTenantSuppliers: '无分配给该租户的供应商。',
  noAPIKeys: '未定义 API 秘钥',
  node: '节点',
  noData: '无数据',
  noFilesSelected: '未选择文件',
  noIllustrationFound: '未查找到图例',
  noImageFound: '未查找到图像',
  noItemAvailable: '无{item}可用',
  noItemFound: '未找到 {item}。',
  noItemSelected: '未选择任何{item}',
  noItemAddedToTarget: '{target} 中未添加 {item}。',
  noItemsFound: '未找到任何项目',
  noMoreResults: '没有更多结果',
  noFormsMessage: '未定义表单。',
  noMediaDefined: '无确定的媒体',
  nonSearchableFieldsSuccessfullyUpdated: '不可搜索字段已成功更新。',
  noPartsSelected: '未选择零件。',
  noPrice: '具体价格欢迎来电咨询',
  noResults: '无结果',
  norwegianDescription: '挪威语描述',
  norwegianName: '挪威语名称',
  noSearchableTags: '没有可搜索的标签',
  notOrderable: '不可订购',
  notSelected: '未选定',
  noTagsFound: '未找到标签',
  noUnsavedChanges: '没有未保存的更改',
  none: '无',
  notImplemented: '未实施',
  notification: '通知 | 通知',
  notificationsMessage: '重要公告和新闻',
  notMapped: '未映射',
  november: '十一月',
  noWidgetKeys: '未找到小工具键。',
  numeric: '数字',
  numberOf: '{items}数量',
  october: '十月',
  of: '/',
  off: '关闭',
  offlineContent: '离线内容',
  ok: '确定',
  oldPassword: '旧密码',
  on: '打开',
  opacity: '透明度',
  open: '打开',
  openItem: '打开{item}',
  optimizeIndexJob: '优化索引',
  options: '选项',
  openTab: '在新标签页中打开链接',
  order: '订单 | 订单',
  orderable: '可订购',
  orderableDescription: '零件可添加到购物车并购买',
  orderableLocal: '可在此页面上订购',
  orderableLocalBlocked: '零件非全球可订购',
  orderDisable: '禁用订单历史',
  orderEmailRecipient: '订购电子邮件收件人 | 订购电子邮件收件人',
  orderEntity: '订单实体 | 订单实体',
  orderFieldUneditableRequired: '将订单/报价字段同时设置为必填和用户无法编辑将导致您的订单/报价表格无法完成。请验证您的订单/报价表格是否可以填写。',
  orderFieldsEmptyMessage: '未定义订单/报价字段。',
  orderFieldsEmptyPrompt: '添加订单/报价字段',
  orderField: {
    nameMessage: '如果您要更改订单/报价字段名称，请确保更新使用该字段的任何订单/报价表格或电子邮件。',
  },
  orderFieldCopyMessage: '为新订单/报价字段输入唯一名称',
  orderForm: {
    uniqueNameInputHint: '为新表单输入唯一名称。'
  },
  orderFormField: '订单/报价字段 | 订单/报价字段',
  orderFormLayout: '订单/报价表格布局 | 订单/报价表格布局',
  orderFormSetDefault: '默认订单/报价单集',
  orderFormType: '订购表单',
  orderLayout: '订单版式 | 订单版式',
  orderManagementApprover: '订单管理审批者',
  orderManagementDisable: '禁用订单管理管理员',
  orderManagementViewer: '订单管理查看器',
  orderNumber: '订单编号',
  orderQuoteEmail: '订购/报价电子邮件 | 订购/报价电子邮件',
  orderQuoteEmailFormat: '订购/报价电子邮件格式 | 订购/报价电子邮件格式',
  orderQuoteEmailFormatCopyMessage: '为新的订购/报价电子邮件格式选择一种语言。',
  orderQuoteFetchError: '获取订购/报价表单数据时出错',
  orderQuoteForm: '订单/报价表 | 订单/多个报价表',
  orderQuoteSaveConfirmation: '保存您对组织订购/报价表单的更改？',
  orderQuoteSaveError: '保存订购/报价表单数据时出错',
  orderQuoteFormSaved: '订单/报价已保存',
  orderStatus: '订单/报价状态 | 订单/报价状态',
  orderStatusDeleteMessage: '此状态已用于订单，无法删除。',
  orderSubmittedMessage: '成功提交',
  orderSuggestion: '订单建议 | 订单建议',
  orderSuggestionEnable: '启用订单建议',
  orderSuggestionRemovalWarning: '该零件将不再显示为订单建议。| 零件将不再显示为订单建议。',
  orderSuggestionThreshold: '订单建议阈值',
  ordersLogo: '订单徽标',
  ordersMessage: '订单提交记录',
  orientation: '方向',
  org: '组织',
  orgEmailRecipients: '组织电子邮件收件人',
  organization: '组织 | 组织',
  organizationAdmin: '组织管理员',
  organizationAdminBrowseFlow: '组织可以使用默认浏览流，您也可以覆盖并使用任何其他浏览流。',
  organizationAdminMessage: '控制用户管理和组织设置',
  organizationEmpty: '必须选择组织',
  organizationEmailRecipients: '组织电子邮件收件人',
  organizationEnforceAccessControlsForQuickAdd: '执行快速添加的访问控制',
  organizationKey: '组织键',
  organizationLibrarySettingsDescription: '这些设置控制各种组织层级的库元素和选项。更改时请多加注意。有关更多信息，请联系 Documoto 服务部门。',
  organizationMoveToRoot: '拖放至此处以移动到根级别',
  organizationName: '机构名称',
  organizationRearrange: '重新安排组织',
  organizationRearrangeDescription: '父级-子级组织结构定义了组织的许多可继承属性，例如“打印设置”、“样式”等。拖放组织以反映所需的层次结构。',
  organizationReportSettingsSuccessMessage: '报告设置已保存',
  organizationSelect: '选择组织',
  organizationTags: '组织标签',
  organizationsEmpty: '您似乎尚未创建任何组织',
  orgNameOnly: '仅组织名称',
  other: '其他',
  otherSettings: '其他设置',
  outboundCustomerIdParameter: '出站客户 ID 参数名称',
  outboundSessionIdParameter: '出站会话 ID 参数名称',
  outlineMode: '轮廓图模式 (Shift O)',
  output: '输出',
  outputCatalog: '输出目录',
  outputDefinition: '输出定义 | 输出定义',
  outputDefinitionDeleted: '已删除输出定义',
  outputDefinitionSaved: '已保存输出定义',
  outputFileName: '输出文件名称',
  outputName: '输出名称',
  outputDestination: '输出目的地',
  outputSpecification: '输出规格',
  outputSubType: '输出子类型',
  outputType: '输出类型',
  overridden: '已覆盖',
  overriddenByOrg: '由组织覆盖',
  override: '替代',
  overrideBrowseFlow: '覆盖浏览流',
  overrideFacetLimitValue: '覆盖 Facet 限值',
  overrideHotpointScale: '替代热点比例',
  overridePdfNamingStandard: '覆盖 PDF 文件命名标准',
  overridePrintSettings: '替代打印设置',
  overrideFontSettings: '覆盖字体设置',
  overrideOrderForm: '覆盖订购表单',
  overrideQuoteForm: '覆盖报价表单',
  overrideSvgOpacity: '替代 SVG 不透明度',
  overrideWarning: '导入数据将替换当前的 BOM 清单',
  owner: '所有者 | 所有者',
  page: '页面 | 页面',
  pageBulkInsertConfirmTitle: '是否要完成批量插入？',
  pageBulkInsertConfirmInsertAfter: '在 {whereUsedBookCount} {bookUnits} 的 {original} 和 {whereUsedTemplateCount} {templateUnits} 之后将插入 {replacement}。',
  pageBulkInsertConfirmInsertBefore: '在 {whereUsedBookCount} {bookUnits} 的 {original} 和 {whereUsedTemplateCount} {templateUnits} 之前将插入 {replacement}。',
  pageBulkInsertConfirmInsertReplace: '将插入 {replacement}并删除 {whereUsedBookCount} {bookUnits} 的 {original} 和 {whereUsedTemplateCount} {templateUnits}。',
  pageBulkInsertBooksConfirmInsertAfter: '在 {whereUsedBookCount} {bookUnits} 的 {original} 之后将插入 {replacement}。',
  pageBulkInsertBooksConfirmInsertBefore: '在 {whereUsedBookCount} {bookUnits} 的 {original} 之前将插入 {replacement}。',
  pageBulkInsertBooksConfirmInsertReplace: '将插入 {replacement} 并删除 {whereUsedBookCount} {bookUnits} 的 {original}。',
  pageBulkInsertTemplatesConfirmInsertAfter: '在 {whereUsedTemplateCount} {templateUnits} 的 {original} 之后将插入 {replacement}。',
  pageBulkInsertTemplatesConfirmInsertBefore: '在 {whereUsedTemplateCount} {templateUnits} 的 {original} 之前将插入 {replacement}。',
  pageBulkInsertTemplatesConfirmInsertReplace: '将插入 {replacement} 并删除 {whereUsedTemplateCount} {templateUnits} 的 {original}。',
  pageBulkInsertDiscard: '将不会处理您的批量插入。',
  pageBulkInsertHelpText: '利用此功能，您可以将此源页面 {thisDisplayName} 插入到已存在另一个页面 {originalDisplayName} 的选定零件手册中。您还可以选择从这些手册中删除{originalDisplayName}。',
  pageBulkInsertInsertAfter: '在{original}之后插入{replacement}',
  pageBulkInsertInsertBefore: '在{original}之前插入{replacement}',
  pageBulkInsertInsertReplace: '插入{replacement}并删除{original}',
  pageBulkInsertScopeAll: '拥有目标页面 {originalDisplayName} ({whereUsedCount} {units}) 的所有{type}',
  pageBulkInsertScopeNone: ' {type} 均无目标页面 {originalDisplayName}',
  pageBulkInsertScopeSome: '部分 {type} 拥有目标页面 {originalDisplayName}',
  pageBulkInsertStep1: '步骤 1：查找所需的目标页',
  pageBulkInsertStep2: '步骤 2：决定如何插入或替换页面',
  pageBulkInsertStep3: '步骤 3：选择要完成批量插入的手册。',
  pageBulkInsertStep4: '步骤 4：选择要完成批量插入的模板。',
  pageBulkInsertSuccessTitle: '批量插入任务已提交',
  pageBulkInsertSuccessBody: '手册目录的更改将立即进行。索引编制完成后，将反映搜索结果。',
  pageBulkErrorTooManyBooks: '限制所选预订数量不超过 1000',
  pageBuilder: '页面生成器',
  pageComposer: 'Page Composer',
  pageCreated: '页面已创建',
  pageDeleteMessage: '页面删除将在库和内容管理中立即生效。在数分钟内重新索引进行之前，这些已删除的页面将保持可搜索状态。',
  pageDeleteMessage2: '如果没有 Documoto 支持人员的帮助，则无法撤消此操作。90 天后，删除的记录和所有关联将被永久清除。',
  pageDescription: '页面描述',
  pageFile: '页面文件',
  pageFilename: '页面文件名',
  pageFilenameDeleted: '{filename} 将被删除。',
pageBomPartsDeleted: 'BOM 页面中的 {count} 个部件将不再可在{filename} 上读取了。',
  pageDraftsDeleted: '与此页面相关的草稿页面将被永久删除。',
  pageFit: '页面匹配',
  pageHeader: '页眉',
  pageLabel: '页面 | 页面',
  pageLayout: '页面布局',
  pageName: '页面名称',
  pagePart: '页面部件 | 页面部件',
  pagePartTag: '零件标签页面 | 零件标签页面',
  pagePartData: '页面部件数据',
  pageUsageDataCouldNotBeLoaded: '无法加载页面使用情况数据',
  pagePartLevelDetails: '页面部件可订购/可见，页面部件标签',
  pagePartNumberVisible: '显示零件编号页面',
  pagePartOrderable: '可订购零件页面',
  pagePartOrderableDescription: '可在本页面订购零件',
  pagePartVisibleDescription: '本页面显示零件编号',
  pageUpdated: '页面已更新',
  pageViewer: '页面查看器',
  pageWidth: '页面宽度',
  pagesWhereUsed: '页面使用位置',
  parentName: '父级名称',
  part: '零件 | 零件',
  partCode: '零件代号 | 零件代号',
  partCodeAddError: '无法添加零件代码',
  partCodeErrorEmpty: '无法保存：零件代码不可为空',
  partCodeErrorInUse: 'Documoto 中的零件正在使用此零件代码，无法删除。请通过 support{\'@\'}documoto.com 与 Documoto 支持团队联系，以获取帮助。',
  partCodeErrorNotFound: '找不到此零件代码，因此无法删除，请确保所有更改都已保存。',
  partCodesManager: '零件代码管理器',
  partCodesMessage: '零件代码可以代替零件编号，并且通常用于指示 BOM 项目何时过时、不可用或仅是参考项（例如“PNNA”、“-”、“REF”）。',
  partCreated: '零件已创建',
  partData: '部件数据',
  partUpdated: '部件已更新',
  partDebouncerEmailQueueJob: '电子邮件队列',
  partDescription: '部件描述',
  partLabel: '零件 | 零件',
  partLevelDetails: '部件定价、可订购/可见、附加翻译、部件标签',
  partName: '零件名称',
  partNumber: '零件编号',
  partNumberDescription: '内部零件编号供最终用户搜索',
  partNumberVisible: '显示零件编号',
  partNumberVisibleDescription: '零件编号对最终用户显示',
  partNumberVisibleLocal: '在此页面上显示',
  partNumberVisibleLocalBlocked: '零件编号不在全球显示',
  partOrderable: '可订购零件',
  partRequired: '至少需要一个零件。',
  partTagProperty: '零件标签属性 | 零件标签属性',
  partTag: '零件标签 | 零件标签',
  partThumbnailDescription: '',
  partialSupport: '部分支持',
  partsListColumn: '零件列表栏 | 零件列表栏',
  partsBook: '零件手册',
  partsColumns: '零件栏',
  partsNotSelected: '用于零件缩略图的默认图像。如果未应用自定义缩略图，则 Documoto 将显示标准的默认缩略图。',
  partsPage: '部件页面 | 部件页面 ',
  partsPages: '零件页面',
  partsPageGroup: {
    excludePartCodesFromPartsLists: '从零件列表中排除零件代码',
    highlightAlternateRows: '突出显示交替行',
    rotateImagesAutomatically: '自动旋转图像',
    printBorderAroundImages: '打印图像周围的边框',
    keepImagesOnLeft: '保持图像在左侧',
    keepBOMOnRight: '保持 BOM 在右侧',
    columnsUpdateErrorMsg: '尝试更新零件栏时出错',
    columnsUpdateSuccessMsg: '零件栏已更新',
    columnsLoadErrorMsg: '未能加载零件栏',
    printHotpointsOnImages: '在图像上打印热点'
  },
  partsList: '部件列表',
  partsListData: '零件列表数据',
  partsListGridHeader: '零件列表网格页眉',
  password: '密码',
  passwordExpiredHeader: '密码已过期',
  passwordExpiredInfo: '您的密码已过期。您的新密码必须满足以下标准：',
  passwordCriteriaInfo: '您的新密码必须满足以下标准：',
  passwordBlankMessage: '密码不能为空',
  passwordCannotBeSame: '不得与您当前密码相同',
  passwordCasingMassage: '必须包含至少一个小写字母和一个大写字母',
  passwordLowerCaseMessage: '密码必须包含至少一个小写字符',
  passwordMinLengthMessage: '密码必须至少包含 8 个字符',
  passwordNotMatchingMessage: '密码不相同',
  passwordSpecialCharactersMessage: '密码必须包含至少一个特殊字符',
  passwordUpperCaseMessage: '密码必须包含至少一个大写字符',
  passwordWillExpire1Day: '登录密码将在 1 天内过期',
  passwordWillExpire5Days: '登录密码将在 5 天内过期',
  passwordsMatch: '密码匹配',
  permission: '权限 | 权限',
  phoneNumber: '电话号码',
  phoneNumberExt: '分机',
  phonePrimary: '主要电话号码',
  phoneSecondary: '次要电话号码',
  pinFilter: '将过滤器固定到搜索结果页面',
  pinned: '大头针标记',
  plusMoreOrgs: '+ {totalOrgCount} 个附加组织',
  polishDescription: '波兰语描述',
  polishName: '波兰语名称',
  populatePdfNameWith: 'PDF 文件名填充为',
  populatePdfNameSelection: '填充 PDF 文件名选择',
  port: '端口',
  portrait: '竖向',
  portugueseDescription: '葡萄牙语描述',
  portugueseName: '葡萄牙语名称',
  postComment: '发表评论',
  postalCode: '邮政编码',
  poweredBy: '支持方',
  preferences: '偏好设置',
  preferencesDisable: '禁用用户偏好',
  prefix: '前缀 | 前缀',
  preview: '预览',
  previewOfName: '{name} 预览',
  pricing: '定价',
  pricingDiscountEnabled: '显示折扣价',
  pricingDisplayInCart: '在购物车内显示价格',
  pricingDisplayInPartsList: '在零件列表上显示价格',
  pricingGlobal: '全球定价',
  pricingGlobalDescription: '没有组织替代时的正常价格',
  pricingHidePricingButton: '启用“隐藏定价”按钮',
  pricingHidePricingButtonDisable: '禁用“隐藏定价”按钮',
  pricingOrganization: '组织定价',
  pricingOrganizationDeleteConfirm: '是否确实要删除此组织定价？',
  pricingOrganizationDescription: '对于组织及其子级组织中的所有用户，组织定价将替代全球定价。',
  pricingOrganizationDuplicate: '此组织已被覆盖',
  pricingOrganizationEmptyState: '没有定义任何组织替代。点击{add}开始。',
  pricingRetailEnabled: '显示零售价',
  pricingWholesaleEnabled: '显示批发价',
  primary: '主要',
  print: '打印',
  printerLane: '打印机通道',
  printJob: '打印任务',
  printLogo: '打印标志',
  printSetting: '打印设置',
  printSettingDeleteFail: '未能删除打印设置',
  printSettingSaveFail: '未能保存打印设置',
  printSettingEmptyMessage: '未定义打印设置。添加打印设置以开始。',
  printSettingSetDefaultSuccess: '默认打印设置已设置',
  printSettingUpdateFail: '未能更新打印设置',
  printSettingCopyMessage: '为新打印设置输入唯一名称。',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: '已创建打印设置',
  printSettings: '打印设置',
  printSettingsLogo: '打印设置徽标',
  printing: '打印',
  printingDisabled: '禁用所有打印',
  privacyPolicy: '隐私政策',
  process: '处理',
  processContent: '处理内容',
  processing: '正在处理',
  processToDraft: '起草流程',
  processedPlzToDraft: '经处理 PLZ 至草稿',
  production: '制造',
  professional: '专业',
  profile: '个人资料',
  prohibitExportDownloadOfContent: '禁止导出/下载内容',
  prohibitExportDownloadOfContentHelpText: '设置为\'启用\'将阻止任何用户（具有特权）导出或下载此内容。',
  property: '属性 | 属性',
  province: '省/自治区/直辖市',
  public: '公开',
  publish: '发布',
  publisherLane: '发布者通道',
  publisherManualLane: '发布者手动通道',
  publishThumbnailJob: '发布缩略图',
  publishDocuments: '发布文档',
  publishDraft: '发布设计图',
  publisher: '发行商 | 发行商',
  publishingErrors: '发布错误',
  publishingErrorsWithName: '发布错误 {name}',
  publishingInProgress: '正在发布中，无法提交',
  publisherJob: '发布商任务',
  publisherMessage: '内容创建、管理和打印设置',
  purchaseOrder: '采购订单',
  purchaseOrderTemplateEnabled: '启用订单模板',
  purchaseOrderTemplateDisabled: '禁用订单模板',
  qty: '数量',
  quantity: '数量',
  query: '查询',
  quickAdd: '快速添加',
  quickAddInCartEnabled: '在购物车中启用快速添加',
  quickLinks: '快速链接',
  quote: '报价 | 报价',
  quoteFormType: '报价表单',
  quoteTemplateDisabled: '禁用报价模板',
  quoteTemplateEnabled: '启用报价模板',
  range: '范围',
  rearrange: '重新安排',
  rebuildIndexJob: '重建索引',
  recentlyAdded: '最近添加',
  recentlyEdited: '最近编辑',
  recentlyViewed: '最近查看',
  rectangle: '矩形',
  redirect: '重定向',
  refresh: '刷新',
  related: '相关',
  relatedDescription: '将库中存在的媒体关联至此 {type}。请注意，关联的媒体必须已应用访问控制，以便用户可以在 \'相关\' 选项卡中进行查看。',
  relatedInfo: '相关信息',
  relatedView: '查看相关媒体',
  relationship: '关系 | 关系',
  releaseNotes: '版本说明',
  rememberMe: '记住我的凭据',
  remainingWidth: '剩余宽度',
  remove: '移除',
  removeAll: '全部移除',
  removeItemConfirmation: '是否确实要移除{item}？',
  removeItemCount: '是否确实要删除{count} {type}？',
  replaceExisting: '替换现有',
  report: '报告 | 报告',
  reportAnIssue: '报告问题',
  reportAnIssueMessage: '电子邮件已成功发送。',
  reportCatalogName: '报告目录名称',
  reportCatalogNotSet: '尚未针对该报告定义报告目录。',
  reportDefinition: '报告定义 | 报告定义',
  reportDefinitionDeleteError: '报告定义删除失败',
  reportsEmptyState: '无报告',
  reportExportTimeWarning: '根据记录计数和所选格式，可能需要几分钟才能完成导出。',
  reporting: '报告',
  reportingMessage: '查看各种报表和指标',
  reportSettings: '报告设定',
  reportsLibrary: '报告资料库',
  requestForQuote: '请求报价',
  requestFormType: '报价表单',
  required: '必填',
  requiredItem: '{item}是必填项。',
  requiredMessage: '{item}是必填项。',
  requiredMessageAndUnique: '{Item} 是必需的，并且必须是唯一的。',
  requireAuthentication: '需要验证',
  requiredReading: '必读内容',
  requiredReadingMessage: '请查看以下内容：  ',
  requiredUrlMessage: '必须填写格式正确的网址。',
  reset: '重置',
  resetPassword: '重置密码',
  resetPasswordError: '重置您的密码时出现问题，请重试。',
  resetPasswordTokenError: '密码重置令牌无效。',
  resetZoom: '重置缩放比例',
  resource: '资源 | 资源',
  resize: '调整大小',
  resizeThumbnails: '自动调整缩略图大小',
  result: '结果 | 结果',
  resultFor: '{item}的结果 | {item}的结果',
  retail: '零售',
  retailPrice: '零售价',
  retailTotal: '零售总价',
  return: '返回',
  returnToItem: '返回至{item}',
  returnToPartList: '返回至零件列表',
  returnToMedia: '返回至媒体',
  returnToTemplate: '返回模板',
  revertChanges: '还原更改',
  right: '右侧',
  romanianDescription: '罗马尼亚语描述',
  romanianName: '罗马尼亚语名称',
  rootLevelBrowseFlow: '根级别',
  row: '行',
  russianDescription: '俄语描述',
  russianName: '俄语名称',
  sampleData: '样本数据',
  samplePreview: '样本预览',
  sandbox: '沙盒',
  sat: '周六',
  save: '保存',
  saveChanges: '是否要将更改保存到{item}？{name}',
  saveChangesToItem: '是否要将更改保存到此{item}？',
  saved: '已保存',
  saveNewAPIKey: '新集成秘钥未保存，返回以完成设置',
  saveNewWidgetKey: '未保存新的小工具键。返回完成设置。',
  search: '搜索',
  searchableFieldsSuccessfullyUpdated: '可搜索字段已成功更新。',
  searchConfiguration: '搜素配置',
  searchCriteria: '搜索条件',
  searchDocumoto: '搜索 Documoto',
  searchFeatures: '搜索功能',
  searchFieldConfigDescription: '以下系统字段搜索设置仅适用于库常规检索、搜索结果页和购物车中的快捷添加。其他搜索功能（例如管理中心搜索和所使用库）不会受到影响。',
  searchPrompt: '搜索……',
  searchProperty: '搜索属性 | 搜索属性',
  searchResultsConfiguration: '搜索结果配置',
  searchSettings: '搜索设置',
  searchSettingsDescription: '这些设置控制各种库搜索行为、功能和结果。',
  searchTerm: '搜索字词',
  searchable: '可搜索',
  searchableTag: '可搜索的标签',
  section: '章节',
  select: '选择',
  selectAField: '选择{field}',
  selectAll: '全选',
  selectAndClose: '选择并关闭',
  selected: '已选择',
  selectedTargetPage: '选择的目标页',
  selectChapters: '选择章节',
  selectChildren: '选择子级',
  selectFields: '选择{fields}',
  selectItem: '选择 {item}',
  selectMedia: '选择媒介',
  selectPage: '选择页面',
  selectPages: '选择页面',
  selectTargetPage: '选择目标页',
  send: '发送',
  september: '九月',
  serbianDescription: '塞尔维亚语描述',
  serbianName: '塞尔维亚语名称',
  serviceUri: '服务网址',
  sessionLog: '会话日志',
   set: '设置',
  setAsDefault: '设置为默认值',
  settings: '设置',
  settingsMessage: '更新您的用户偏好设置和密码',
  sequence: '顺序',
  shape: '形状',
  shapeTool: '形状工具',
  shared: '共享',
  sharedCart: '共享购物车 | 共享购物车',
  sharedCartDeleteContent: '这将为共享此购物车的所有组织删除此购物车。',
  sharedCartOrganizationContent: '选择可以使用此共享购物车的组织。',
  sharedShoppingCartsEnabled: '启用共享购物车',
  shoppingCartExportJob: '导出购物车',
  showExportFromSearch: '允许从搜索结果导出',
  showExportFromSearchHelp: '允许导出搜索到的手册列表',
  showHelp: '显示帮助',
  showIdentifierInSearch: '在搜索中显示标识符',
  showItem: '显示{item}',
  showLess: '折叠',
  showMediaDescriptionFilter: '显示媒体描述过滤器',
  showMediaIdentifierFilter: '显示媒体标识符过滤器',
  showMediaNameFilter: '显示媒体名称过滤器',
  showMore: '展开',
  showOrganizationFilter: '显示组织过滤器',
  showPartNameFilter: '显示零件名称过滤器',
  showPartNumberFilter: '显示零件编号过滤器',
  showRecentlyAdded: '显示最近添加',
  showRecentlyViewed: '显示最近查看',
  showSearchButton: '显示首页搜索按钮',
  showSearchButtonHelp: '在库标题中显示 “转至搜索” 按钮',
  signature: '签名',
  size: '大小',
  solrSlaveSvrOverride: 'SOLR Slave 服务器基本 URL 覆盖',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: '最相关',
  sortType: '排序类型',
  spanishDescription: '西班牙语描述',
  spanishName: '西班牙语名称',
  square: '方形',
  ssoConfiguration: 'SSO 配置',
  ssoIdpMetadataUrl: 'SSO IDP 元数据 URL',
  ssoLoginMaxAuthenticationAgeSeconds: 'SSO 登录最大认证时间秒数',
  ssoSamlDnAttributeName: 'SSO SAML DN 属性名称',
  ssoSamlEmailAttributeName: 'SSO SAML 电子邮件属性名称',
  ssoSamlMemberofAttributeName: 'SSO SAML 会员属性名称',
  ssoSniForSsoMetadataRetrieval: '使用 SNI 进行 SSO 元数据检索',
  standard: '标准',
  standardUserGroup: '标准用户组',
  startDate: '开始日期',
  startDateRequired: '开始日期为必填。',
  state: '州',
  static: '静态',
  status: '状态',
  statusCode: '状态代码 | 状态代码',
  stitchPages: '拼接页面',
  storeFrontAdmin: '店面管理员',
  storefront: '店面',
  storefrontIntegration: '店面集成 | 店面集成',
  storefrontIntegrationSubtitle: '这些设置控制各种店面集成元素和选项。更改时请多加注意。有关更多信息，请联系 Documoto 支持部门。',
  storefrontSettings: '店面设置',
  storefrontSettingsDescription: '此类设置控制各种店铺元素和选项。更改时请多加注意。有关更多信息，请联系 Documoto 服务部门。',
  storefrontSettingsOrgDescription: '这些设置控制各种组织层级的店面元素和选项。更改时请多加注意。有关更多信息，请联系 Documoto 服务部门。',
  storeFilterValues: '存储筛选值',
  storeFilterValuesDescription: '启用此设置将存储用户在其搜索页面筛选器中为该标签设置的值。',
  stroke: '线条',
  style: '样式 | 样式',
  styles: {
    colorHotpoint: '热点默认设置',
    invalidHexcode: '无效的十六进制编码',
    inheritedFromOrg: '从组织继承{org}',
    inheritedFromSystem: '从系统默认设置继承',
    inheritedFromTenant: '从承租人继承',
    organization: '组织风格 | 组织风格',
    organizationSaved: '组织风格已保存',
    sampleNavigation: '导航标签',
    samplePrimary: '导航/按钮',
    sampleSelectedHotpoint: '已选择热点',
    tenant: '承租人风格 | 承租人风格',
    tenantSaved: '承租人风格已保存'
  },
  subChapter: '子章节 | 子章节',
  subject: '主题',
  subjectMessage: '必须填写主题',
  submit: '提交',
  submitPurchaseOrder: '采购订单',
  submitRequestForQuote: '请求报价',
  submitted: '已提交',
  submitter: '提交者',
  submittedBy: '提交人',
  submitterEmail: '提交者电子邮件',
  submitterFirstName: '提交者名字',
  submittedFilesMessage: '已提交 {fileCount} 个文件。',
  submitterLastName: '提交者姓氏',
  submitterOrgName: '提交者组织名称',
  submitterUserName: '提交者用户名',
  success: '成功',
  imageSaveSuccess: '已成功更新图像',
  successNotificationDuration: '添加至购物车 - 成功提示通知时长',
  suffix: '后缀 | 后缀',
  suggestion: '建议 | 建议',
  sun: '周日',
  superadmin: '超级管理员',
  superAdminSettings: "超级管理员设置",
  supersede: '取代',
  supersedeComplete: '是否要完成取代？',
  supersedeDiscardChanges: '要舍弃取代？',
  supersedeMainText: '取代操作可在发现旧零件的每一个零件页面上使用一个或多个其他零件替代现有零件。旧零件编号已删除，但仍可通过标签进行搜索，因此可以使用旧零件编号或新零件编号查找内容。',
  supersedeMessage: '零件更新会立即生效。搜索将在几分钟内反映更新。',
  supersedeNotProcessed: '将不会处理您的取代。',
  supersedeReplacePart: '查找将替换零件的一个或多个零件',
  supersedeSuccessful: '取代成功',
  supersedeWith: '将零件替换为……',
  supplier: '供应商 | 供应商',
  supplierKey: '供应商秘钥',
  supplierDescription: '供应该零件的公司',
  supportedImageFormats: '支持的图像格式：GIF、JPG、JPEG、PNG',
  supportedDraftArchives: '不支持的文件；支持的草稿文件：MDZ、PLZ',
  svgFinder: 'SVG 查找器',
  swedishDescription: '瑞典语描述',
  swedishName: '瑞典语名称',
  system: '系统',
  systemAdmin: '系统管理员',
  systemFields: '系统字段',
  systemFieldSearchAndFiltering: '系统字段搜索和筛选',
  systemStatus: '系统状态',
  systemName: '系统名称',
  systemVersion: '系统版本',
  tabDelimited: '制表符分隔',
  tableOfContents: '目录',
  tabloid: '文摘',
  tag: '标签 | 标签',
  tagName: '标签名称',
  tagEditor: {
    rangeBoundLengthInvalid: '限值不能超过 {limit} 位。',
    tagEditorModal: '标签编辑器模态',
    filterPrompt: '当前显示前 100 个值。使用过滤器查看更多。'
  },
  tagSearchAndFiltering: '标签搜索和筛选',
  tagSearchAndFilteringHelpText: '提示: 一些搜索和筛选设置也可以在标签管理器标签编辑器中配置，请参见此处:  ',
  tagSubmitAffected: '除更改外，任何{type}内容都将自动删除标签{tag}。',
  tagDeleteAffectedCount: '这将删除标签，并从 {count} 个项目中删除标签信息。',
  tagDeleteAffectedNone: '当前没有项目使用此标签。',
  tagManagerDeleteWarning: '删除后，所有具有 {deleteCategoryName} 的现有媒体都将失去此类别。',
  tagsDisplayMore: '当前显示前 100 个值。使用过滤器查看更多。',
  tagsManager: '标签管理器',
  tagValue: '标签值 | 标签值',
  tagValueMissing: '必须输入标签值',
  tagValueMultiple: '以分号分隔多个条目',
  tagValueTooLong: '标签值超过 25 个字符的限制 | 标签值超过 25 个字符的限制',
  taxon: '分类',
  taxonomy: '分类法',
  template: '模板 | 模板',
  templateEmailRecipients: '模板电子邮件收件人',
  templateLayout: {
  addRow: '添加行',
  addItem: '添加项目',
  deleteRow: '删除行',
  deleteColumn: '删除列',
  fullWidth: '全宽',
  oneColumn: '一列',
  threeColumns: '三列',
  twoColumns: '两列'
  },
  tenant: '租户 | 租户',
  tenantAdmin: '租户管理员',
  tenantAdminMessage: '控制租户范围设置',
  tenantCleanerJob: '租户维护',
  tenantConfiguration: '租户配置',
  tenantEmailRecipients: '租户电子邮件收件人',
  tenantEmailRecipientsOverrideMessage: '如果指定了组织电子邮件收件人，这些将被覆盖',
  tenantIndexQueueJob: '租户索引队列',
  tenantKey: '租户秘钥',
  tenantKeyError: '租户秘钥不是唯一的',
  tenantKeyMessage: '租户秘钥必须是唯一的',
  tenantLabelDeleteMessage: '删除此标签将还原为默认的 Documoto 名称。',
  tenantReindexModulus: '租户再索引模块',
  tenantSettings: {
    collapseTOCLabel: '折叠匹配页面上的目录',
    defaultLocaleLabel: '默认区域设置',
    defaultLocaleMessage: '租户默认区域设置。',
    deleteMissingTranslationsOnPublishLabel: '删除发布中缺失的翻译？',
    deleteMissingTranslationsOnPublishMessage: '启用以在发布时删除部分翻译',
    defaultSupplierLabel: '默认供应商',
    defaultSupplierMessage: '租户默认供应商。',
    enableChapterIndexingMessage: '索引章节',
    enableChapterIndexingLabel: '为媒体章节打开索引',
    enableIndexingLabel: '发布索引',
    enableIndexingMessage: '开启发布索引。',
    helpUrlLabel: '默认帮助网址',
    helpUrlMessage: '替代帮助系统的网址。',
    hotPointLinkPageInBookOnlyLabel: '仅书中的页面热点链接',
    hotPointLinkPageInBookOnlyMessage: '仅书中页面的热点链接',
    mediaTimestampLabel: '在访问控制更改时更新媒体时间戳',
    mediaTimestampMessage: '如果更改访问控制必须更新媒体的时间戳，则启用。',
    publisherDefaultUom: '发行方默认度量单位',
    publishingContactLabel: '发布联系人电子邮件地址',
    publishingContactMessage: '报告内容问题时将接收电子邮件的默认电子邮件地址。',
    replaceHotpointLabel: '发布时始终替换热点链接',
    replaceHotpointMessage: '仅用于 Docustudio 发布。',
    ssoLoginSystemLabel:  'SSO 登录系统',
    ssoLoginSystemMessage: '用于 SSO 的身份提供程序系统。',
    userPasswordExpirationLabel: '用户密码有效期',
    userPasswordExpirationMessage: '密码必须重置的天数；留空以永不过期',
    lockPartTrOnPublishLabel: '在发布中锁定部分翻译',
    lockPartTrOnPublishMessage: '防止在发布期间修改部分翻译',
    exportPgAsHashLabel: '导出页面文件名称作为 Hash 秘钥',
    exportPgAsHashMessage: '在导出的内容中使用页面 Hash 秘钥作为页面文件名'
  },
  tenantSettingsTitle: '租户设置',
  tenantSettingsSaved: '租户设置已保存',
  tenantSettingsSubtitle: '此设置控制所有租户的属性和行为。请谨慎更改。联系 Documoto 服务部门获得更多资讯。',
  tenantSuppliers: '租户供应商',
  tenantLabelsPageText: '标签用于自定义在 Documoto 库和管理页面中找到的许多对象的名称。',
  tenantPrintSettingsDescription: '选择租户范围的默认打印设置。除非在组织或媒体上被覆盖，否则此选项用于所有手册的打印。',
  tenantType: '租户类别',
  tenantWebserviceUser: '租户网络服务使用者',
  text: '文本',
  textArea: '文本区域',
  textField: '文本字段',
  textFile: '文本文件 (CSV, TXT)',
  textTool: '文本工具',
  thu: '周四',
  thumbnail: '缩略图',
  thumbnailDeleted: '缩略图已成功删除',
  thumbnailDeletedError: '缩略图删除失败',
  thumbnailUpdated: '缩略图已成功更新',
  thumbnailUpdatedError: '缩略图更新失败',
  thumbnailReapplyWarning: '系统可以根据媒体的内容重新应用缩略图。此过程可能需要花费几分钟。',
  thumbnailResizeTooltip: '如果选中，则会将缩略图大小调整为不大于 500x500 像素',
  title: '标题',
  to: '至',
  toastNotifications: '提示通知',
  toDate: '截止日期',
  toc: '目录',
  tocClipBoardSuccessTitle: '已复制到剪贴板',
  tocClipBoardWarningText: '警告：未保存的更改无法复制到剪贴板',
  tocClipBoardWarningTitle: '已复制到剪贴板',
  tocDisplayingFirstValues: '当前显示前 100 个值。使用过滤器查看更多。',
  tocDuplicateWarningText: '在一个章节中检测到重复的页面。重复页已被删除。',
  tocEmptyMessage: '您似乎尚未创建目录。',
  toGetStarted: '以开始',
  tocMissingChapterName: '缺少章节名称',
  tocModalTableCaption: '标签编辑器模态',
  tocNothingSelected: '未选择任何对象',
  tocOpenEditor: '打开编辑器',
  tocSaveRequired: '编辑章节详细信息之前需要保存',
  tocState: '目录初始状态',
  tocThumbnailDeleteFailTitle: '缩略图删除失败',
  tocThumbnailDeleteSuccessTitle: '缩略图删除成功',
  tocThumbnailUploadFailTitle: '缩略图上传失败',
  tocThumbnailUploadSuccessTitle: '缩略图上传成功',
  tocTranslationMessage: '此章节的名称和可选说明',
  tocUntitledPage: '无标题页面',
  tooManySelections: '列表超过 {limit} 个选择限制',
  togglePage: '切换页面',
  top: '顶部',
  total: '总计',
  totalItems: '总项目',
  translation: '翻译 | 翻译',
  translationHelpTextItem: '此{item}的名称和可选说明',
  true: '真实',
  tryAgain: '请重试。',
  tue: '周二',
  turkishDescription: '土耳其语描述',
  turkishName: '土耳其语名称',
  twoSided: '双面',
  type: '类型 | 类型',
  typeToConfirm: '输入{value}以确认',
  typography: '排版',
  ukraineDescription: '乌克兰语描述',
  ukraineName: '乌克兰语名称',
  unableToRetrieveResults: '无法检索您的查询/搜索结果',
  unauthorized: '未授权',
  unknownError: '未知错误，请通过 support{\'@\'}documoto.com 与 Documoto 支持团队联系，以获取帮助',
  unknownPart: '未知零件',
  unknownType: '未知的类型',
  unsavedAPIKeyWarning: '若选择不返回，您的 API 秘钥将丢失',
  unsavedChanges: '您有未保存的更改。要继续吗？',
  unsavedChangesWarning: '如不保存更改，这些更改将丢失。',
  unsavedWidgetKeyWarning: '您若不返回，则您的小工具键将会丢失。',
  unsupportedBrowserMessage: '我们采用最新技术创建了这款应用程序，使其速度更快、更易于使用。非常遗憾，您的浏览器不支持这些技术。',
  uom: '度量单位',
  update: '更新',
  updateFailed: '更新失败',
  updateIndex: '更新索引',
  updatePassword: '更新密码',
  updatePasswordMessage: '密码已更新',
  updatePasswordPending: '密码更改等待保存。',
  updated: '已更新',
  upgradeBrowser: '请升级您的浏览器。',
  upload: '上传',
  uploadComplete: '上传完成',
  uploadInProgress: '正在上传',
  upperBound: '上限',
  untitledOrg: '未命名组织',
  usCurrency: '美元',
  useDefaultCartColumns: '使用默认购物车栏',
  useDefaultPartsListColumns: '使用默认零件列表栏',
  user: '用户',
  userAddress: '用户地址 | 用户地址',
  userCount: '用户数量',
  userEditable: '用户可编辑',
  userExpirationDate: '用户到期日期',
  userExpirationDateInvalid: '必须输入用户到期日期',
  userGroup: '用户组 | 用户组',
  userGroupEmpty: '用户组不能为空',
  userGroupType: '用户组类别',
  userGroupUniqueMessage: '此名称必须区别于该租户的所有其他用户组名称',
  userGroupUsers: '用户组用户',
  username: '用户名',
  usernameEmpty: '用户名不可为空',
  users: '用户',
  useSniForSsoMetadataRetrieval: '使用 SNI 进行 SSO 元数据检索',
  value: '值',
  validate: '生效',
  validateTenantDirectories: {
    buttonLabel: '验证内容目录',
    error: '未能验证目录',
    success: '验证租户目录'
  },
  variantSKU: '变体 SKU',
  validationMessage: '验证信息 | 验证信息',
  valueGteError: '值必须小于或等于 {max}',
  valueLteError: '值必须大于或等于 {min}',
  vendorName: '供应商名称',
  verifyPassword: '验证密码',
  vertical: '垂直',
  version: '版本',
  video: '视频 | 视频',
  view: '查看',
  viewAllPagesAndBooks: '查看：所有页面和账簿',
  viewBooks: '查看：账簿',
  viewPages: '查看：页面',
  viewModeList: '列表视图',
  viewModeTable: '表格视图',
  visibility: '显示范围',
  visibilityDescription: '谁可以查看此{entity}',
  visible: '显示',
  waitForUpload: '请等待上传完成。',
  watermark: '水印',
  watermarkFont: '水印字体',
  watermarkOpacityPercentage: '水印不透明度 %',
  watermarkOrientation: '水印方向',
  watermarkSaved: '水印已保存',
  watermarkSaveFailed: '水印保存失败。',
  watermarkSelectionRequired: '用户名或文本选择必填。',
  watermarkText: '水印文本',
  wed: '周三',
  whereUsed: '使用位置',
  wholesale: '批发',
  wholesalePrice: '批发价',
  wholesaleTotal: '批发总价',
  widget: '小工具',
  widgetAuthHeader: '小工具授权值',
  widgetKey: '小工具键 | 小工具键',
  widgetKeyAdmin: '小工具键管理',
  widgetKeyCopied: '小工具授权值已复制到剪贴板',
  widgetKeyDeleted: '小工具键已删除',
  widgetKeyDeletedError: '删除小工具键时出错',
  widgetKeyDeleteMsg: '删除小工具键将会禁止该工具的使用',
  widgetKeyManager: '小工具键管理器',
  widgetKeyNameEmpty: '名称必须唯一且必填。此值还将作为任何小工具活动的用户名用于报告和记录目的。',
  widgetKeyRegenError: '重新生成小工具键时出错',
  widgetKeyRegenMsg: '重新生成此小工具键会禁用现有集成',
  widgetKeyRegenTitle: '您确定要重新生成该小工具键？',
  widgetKeySaved: '小工具键已保存',
  widgetPermissions: '小工具用户是只读的，功能访问由小工具级别的配置管理。标准用户组权限不适用于小工具用户。存在媒体类别访问权限。请继续分配媒体类别访问控制。',
  widgetRefreshErrorMsg: "请刷新页面以查看请求的内容",
  widgetUserGroup: '小工具用户组',
  widgetUserGroupDeleteMsg: '无法删除带有用户的小工具组',
  willBeAffected: '将受影响。',
  willBeDeleted: '{content}将被删除。',
  willBeInsertedIntoItem: '{name}将被插入{item}',
  willBeUpdated: '将更新。',
  zipCode: '邮编',
  zoomHotpointToCanvas: '缩放热点到画布大小',
  zoomIn: '放大',
  zoomOut: '缩小'
}
/* eslint-enable max-len */
