<template>
  <div
    class="field has-addons is-marginless"
    v-on-clickaway="closeActive"
  >
    <div class="control">
      <div
        :class="{'is-active': isActive}"
        class="dropdown"
      >
        <div class="dropdown-trigger">
          <button
            class="button category-dropdown-button"
            @click="toggleActive"
          >
            <span>{{ name }} ({{ selectionIndicator }})</span>
            <span class="icon is-small">
              <i class="fa fa-angle-down" />
            </span>
          </button>
        </div>
        <div
          class="dropdown-menu"
          id="dropdown-menu"
          role="menu"
        >
          <div class="dropdown-content">
            <div
              v-for="{key, count} in filter.values"
              :key="key"
              class="dropdown-item"
            >
              <div
                data-testid="category-dropdown-item"
                class="field category-dropdown-item"
                @click="change({key, value: count})"
              >
                <input
                  type="checkbox"
                  :checked="selected.indexOf(key) >= 0"
                  class="is-checkradio"
                >
                <label data-testid="label">{{ mappings[key] }} {{ count >= 0 ? `(${count})` : '' }}</label>
              </div>
            </div>
            <div
              v-if="!hasOptions"
              class="dropdown-item"
            >
              <div class="field">
                {{ $i18n.t('noItemsFound') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="control">
      <a
        data-testid="remove-filter-button"
        class="button remove-filter-button"
        @click="removeFilter"
      >
        <span class="icon is-small"><i class="fa fa-times" /></span>
      </a>
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from 'vue3-click-away'

export default {
  name: 'MappedListOptionsFilter',
  props: {
    filter: {
      type: Object,
      required: true
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },
  directives: {
    onClickaway
  },
  data () {
    return {
      selected: [],
      isActive: false
    }
  },
  watch: {
    selectedValues () {
      this.selected = this.selectedValues.slice()
    }
  },
  computed: {
    mappings () {
      const result = {}
      this.filter.options.forEach(({ key, name }) => {
        result[key] = name
      })
      return result
    },
    name () {
      if (this.filter.name.indexOf('%%') === 0) {
        return this.$i18n.tc(this.filter.name.replace(/%%/g, ''), 2)
      }
      return this.filter.name
    },
    selectionIndicator () {
      if (this.selected.length === 1 && this.filter.values) {
        return this.mappings[this.selected[0]]
      } else if (this.selected.length > 1) {
        return this.selected.length
      }
      return this.$i18n.t('any')
    },
    hasOptions () {
      return this.filter.values && this.filter.values.length > 0
    }
  },
  methods: {
    change ({ key }) {
      const index = this.selected.indexOf(key)
      if (index >= 0) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(key)
      }
      this.$emit('change', this.selected)
    },
    closeActive () {
      this.isActive = false
    },
    toggleActive () {
      this.isActive = !this.isActive
    },
    removeFilter () {
      this.$emit('remove')
    }
  }
}
</script>

<style scoped>
.dropdown-content {
  width: max-content;
  max-height: 50vh;
  overflow: auto;
}
.button {
  padding-left: .5rem;
  padding-right: .5rem;
}
.button .icon:last-child:not(:first-child) {
  margin-left: 0;
}
</style>
