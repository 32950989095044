/**
 * Buefy
 *
 * @reference https://buefy.org/documentation
 *
 * Tree Shaking see "Usage": https://buefy.org/documentation/start
 *
 */

import Vue from 'vue';
import Buefy from '../buefy';

Vue.use(Buefy, {
  defaultIconPack: 'fa'
});
