/*
 * This module contains imperative and managment logic
 * for updates to the back-end state of data
 * associated with the draft BOM list in Admin Page Composer
 * E.g., changes to part translations, part numbers,
 * parent-child relationships, etc.
 *
 * In contrast, the draftBom module and its sub-module(s)
 * manage the ephemeral, front-end state of the draft BOM list
 * E.g., performing searches, opening/closing modals,
 * tracking which draft bom cell is in edit mode, etc.
 */
import i18n from '@/locales'
import * as BluebirdPromise from 'bluebird'
import { v4 as uuidv4 } from 'uuid'
import { ActionContext, GetterTree, MutationTree } from 'vuex'
import draftTags from './draftTags'
import draftHotpointLinks from './draftHotpointLinks'
import {
  DraftBomItem,
  draftBomStorePaths,
  EditTypes,
  IndexTypeValue,
  PartCodeDto,
  Translation
} from '@/interfaces/admin'
import { StringToNumberMap } from '@/interfaces/global'
import dragAndDrop from './dragAndDrop'
import { getGlobalPartPricing, getPartTagsByPartId } from '@/controllers/admin'
import {
  formatPartTagValues,
  getDefaultSupplier,
  getDefaultUom,
  getUpdatedTranslations,
  hasRequiredFields
} from './helpers'
import { getPart } from '@/components/admin/content/editor/page/page-illustration/services'
import DraftPart from './helpers/draftPart'
import { postError } from '@/helpers/notification'

// Interfaces and Types
export enum Mutations {
  ADD_PART = 'draftPage/ADD_PART',
  ADD_TO_SELECTED_ROWS = 'draftBom/ADD_TO_SELECTED_ROWS',
  BULK_DRAFT_BOM_ITEM_UPDATE = 'draftPage/BULK_DRAFT_BOM_ITEM_UPDATE',
  BULK_PART_UPDATES = 'draftPage/BULK_PART_UPDATES',
  CLEAR_SELECTED_ROWS = 'draftBom/CLEAR_SELECTED_ROWS',
  DELETE_DRAFT_BOM_ITEMS = 'draftPage/DELETE_DRAFT_BOM_ITEMS',
  DELETE_PART_ID = 'draftPage/DELETE_PART_ID',
  HIDE_ALL_CHILDREN = 'draftPage/HIDE_ALL_CHILDREN',
  SET_IS_UPDATING = 'draftBom/SET_IS_UPDATING',
  SHOW_ALL_CHILDREN = 'draftPage/SHOW_ALL_CHILDREN',
  UPDATE_DRAFT_BOM_ITEM = 'draftPage/UPDATE_DRAFT_BOM_ITEM',
  UPDATE_DRAFT_BOM_ORDER = 'draftPage/UPDATE_DRAFT_BOM_ORDER',
  UPDATE_ORPHANED_PARTS = 'draftPage/UPDATE_ORPHANED_PARTS',
  SET_PART_AS_PART_CODE = 'draftPage/SET_PART_AS_PART_CODE'
}

export enum LocalMutations {
  SET_IS_DIRTY = 'SET_IS_DIRTY',
  SET_IS_EXPORTING = 'SET_IS_EXPORTING',
  SET_IS_IMPORTING = 'SET_IS_IMPORTING',
  SET_IS_UPDATING = 'SET_IS_UPDATING',
  SET_IS_SELECTING_DD = 'SET_IS_SELECTING_DD'
}

export enum GettersList {
  hasParts = 'hasParts',
  refKeyToMasterListIdx = 'refKeyToMasterListIdx',
  selected = 'selected',
  dropIndex = 'dropIndex',
  highlightedPart = 'highlightedPart',
  getIndentationAndParent = 'getIndentationAndParent',
  lastDraftBomItemIndex = 'lastDraftBomItemIndex'
}

interface Getters {
  hasParts: boolean,
  refKeyToMasterListIdx: StringToNumberMap,
  selected: number[], // Indexes of currently selected parts in bom list
  dropIndex: number, // Returns either the only or the last in a series of selected parts
  highlightedPart: DraftBomItem|null,
  getIndentationAndParent: any[], // Indent level and Parent to assign to a part being inserted into bom list
  lastDraftBomItemIndex: number,
  hasAllChildrenInSelected: (children: string[]) => boolean,
  hasSelectedCollapsedParent: boolean,
}

type Context = ActionContext<DirtyState, any>

// DirtyState of DraftBomItem
export interface DirtyState {
  isDirty: boolean,
  isExporting: boolean,
  isImporting: boolean,
  isUpdating: boolean,
  isSelectingDropdown: boolean,
}

interface IndexPartDataSupplier {
  index: number,
  partData: {
    id: number,
    partNumber: string,
    translations: Translation[],
    uom: 'string'
  },
  supplierId: number
}

interface PartAndChildren {
  refKey: string,
  children: string[]
}

// draftPage Module
const state: DirtyState = {
  isDirty: false,
  isExporting: false,
  isImporting: false,
  isUpdating: false,
  isSelectingDropdown: false
}

const getters: GetterTree<DirtyState, any> = {
  // Mapping object to support O(1) look ups from state.draftBomItems
  refKeyToMasterListIdx (state, getters, rootState): StringToNumberMap {
    const map: StringToNumberMap = {}
    rootState.draftPage.draftBomItems.forEach((part: DraftBomItem, index: number) => {
      map[part?.refKey ?? ''] = index
    })
    return map
  },
  // Indexes of currently selected parts in bom list
  selected (state, getters, rootState): number[] {
    return rootState.draftBom?.selectedRows.indices ?? []
  },
  // Index where to insert a new row
  dropIndex (state, getters: Getters): number {
    const selected = getters.selected
    switch (selected.length) {
      case 0:
        return 0
      case 1:
        return selected[0] + 1
      default:
        return selected[selected.length - 1] + 1
    }
  },
  // Returns last visible part in selection
  highlightedPart (state, getters: Getters, rootState, rootGetters): DraftBomItem|null {
    const lastVisibleSelectedIdx = rootGetters['draftBom/lastVisibleSelected']
    const bomList = rootState.draftPage.draftBomItems
    return (lastVisibleSelectedIdx < 0) ? null : bomList[lastVisibleSelectedIdx]
  },
  // Helper function that supports hasSelectedCollapsedParent
  hasAllChildrenInSelected: (state, getters: Getters, rootState, rootGetters) => (childRefs: string[]): boolean => {
    const selectedIndicesMap = rootGetters['draftBom/selectedIndicesMap']
    const childIndices = childRefs.map(refKey => getters.refKeyToMasterListIdx[refKey])
    return childIndices.every(idx => !!selectedIndicesMap[idx])
  },
  hasSelectedCollapsedParent (state, getters: Getters, rootState): boolean {
    const { highlightedPart } = getters
    const bomList = rootState.draftPage.draftBomItems
    const lastSelectedPart = bomList[getters.selected[getters.selected.length - 1]]
    return (!highlightedPart || !(highlightedPart?.children ?? []).length)
      ? false
      : highlightedPart.refKey !== lastSelectedPart.refKey
  },
  // Indentation level and Parent to assign to a part being inserted into bom list
  getIndentationAndParent (state, getters: Getters): any[] {
    // When:
      // Nothing is highlighted -> insert at root level
      // Highlighted is expanded parent -> insert as child of highlighted
      // Highlighted is not parent or is collapsed -> insert as sibling
    const { highlightedPart, hasSelectedCollapsedParent } = getters
    const isExpandedParent = !hasSelectedCollapsedParent && !!highlightedPart?.children?.length
    if (!highlightedPart) return [0, null]
    return isExpandedParent
      ? [highlightedPart.indentation + 1, highlightedPart.refKey]
      : [highlightedPart.indentation, highlightedPart.parent]
  },
  lastDraftBomItemIndex (state, getters, rootState): number {
    return rootState.draftPage.draftBomItems.length - 1
  },
  // Tread carefully; canSave is reactive when the array is updated appropriately
  canSave (state, getters, rootState) {
    return rootState.draftPage.draftBomItems.every((part: DraftBomItem) => {
      return hasRequiredFields(part)
    })
  },
  isDirtyBomList (state) {
   return state.isDirty
  },
  getUpdatedParentChildren: (state, getters: Getters, rootState) => (parentIdx: number, refKey: string):string[] => {
    /*
     * When adding a part below a collapsed part that has a parent part of its own,
     * this helper function gets an updated list of children of the parent part
     * containing the newly added part's refKey in the proper order
     */
    const parentsChildren: string[] = Array.from(rootState.draftPage.draftBomItems[parentIdx].children)
    const insertionIdx = parentsChildren.findIndex(ref => ref === getters.highlightedPart?.refKey) + 1
    return parentsChildren.slice(0, insertionIdx)
      .concat([refKey])
      .concat(parentsChildren.slice(insertionIdx))
  }
}

const actions = {
  async updatePartId ({ commit, dispatch, rootState }: Context, payload: IndexTypeValue): Promise<void> {
    try {
      // Get list of part number and name partial string matches
      const suggestions = !!payload.value.length
        ? await dispatch('search/searchWithReturn', {
          q: payload.value,
          searchableType: 'Part'}, { root: true })
        : []

      // Determine if there is an exact match to a pre-existing part --
      // one suggestion with an exact match for partNumber and supplier
      const isOnlySuggestion = suggestions?.length === 1
      const numbersMatch = payload.value === suggestions[0]?.identifier
      const { supplierId } = rootState.draftPage.draftBomItems[payload.index]
      const supplier = rootState.suppliers.suppliers.find((it: any) => it.supplierId === supplierId)
      const suppliersMatch = isOnlySuggestion && (supplier?.name ?? null) === suggestions[0]?.supplierKey

      // If there is an exact match to a pre-existing part
      // then update partId to the partId of the suggested part
      // Otherwise, set partId to null so that Turbo knows to assign
      // a new partId for the draftBomItem on saving
      if (isOnlySuggestion && numbersMatch && suppliersMatch) {
        commit(Mutations.UPDATE_DRAFT_BOM_ITEM, {
          index: payload.index,
          type: EditTypes.partId,
          value: suggestions[0].entityId
        }, { root: true })
      } else {
        commit(Mutations.DELETE_PART_ID, payload.index, { root: true })
      }
      commit(LocalMutations.SET_IS_DIRTY, true)
    } catch (e) {
      console.log(e)
    }
  },
  async updateDraftBomItem ({ commit, rootState, dispatch, rootGetters }: Context, payload: IndexTypeValue): Promise<void> {
    // Only initiate dirty manager if updating part data the backend
    // cares about -- i.e., expanding/collapsing a part will not trigger
    // dirty manager but changing data in ones of its cells will
    if (payload.type !== EditTypes.expanded && payload.type !== EditTypes.visible) {
      commit(LocalMutations.SET_IS_DIRTY, true)
    }
    commit(LocalMutations.SET_IS_UPDATING, true)
    // If the user updates supplier, update the partId to null
    // to allow Turbo to create a new part with a new partId
    // on saving to the backend
    const isSupplier = payload.type === EditTypes.supplierId
    if (isSupplier) {
      commit(Mutations.DELETE_PART_ID, payload.index, { root: true })
    }

    // When editing a partNumber, conditionally update the partId as well.
    // If the user provides a partNumber that matches an existing part
    // and the suppliers match, then partId is set to the partId of
    // the matching, pre-existing part.
    // Otherwise, partId is set to null, allowing Turbo to create one
    // on saving to the backend.
    if (payload.type === EditTypes.partNumber) {
      await dispatch('updatePartId', payload)
    }

    // Publisher will create a new part on translation changes to a part code.
    // Thus, deleting part id in this case ensures "NEW" pill gets displayed in BOM
    if (payload.type === EditTypes.partTranslations || payload.type === EditTypes.partName) {
      const isAPartCode = !!rootState.draftPage
        .draftBomItems[payload.index].partNumberCodeId
      isAPartCode && commit(Mutations.DELETE_PART_ID, payload.index, { root: true })
    }

    /*
     * If the part name is being updated,
     * then update the proper translation file.
     * If no translation file exists, then create one
     * with a deep copy to avoid mutating the state
     */
    if (payload.type === EditTypes.partName) {
      const name = payload.value
      const locale = rootState.user.locale
      const translations = rootState.draftPage
        .draftBomItems[payload.index].partTranslations || []
      payload.type = EditTypes.partTranslations
      payload.value = getUpdatedTranslations(name, locale, translations)
    }

    commit(Mutations.UPDATE_DRAFT_BOM_ITEM, payload, { root: true })

    // Update part DTO if updated partNumber value equals a part code
    if (payload.type === EditTypes.partNumber) {
      const isAPartCode = rootGetters['partCodeLookups/isAPartCode'](payload.value)
      if (isAPartCode) {
        const { partCodesMap } = rootState.partCodeLookups
        const partCode: PartCodeDto = partCodesMap[payload.value]
        const partCodeUpdatePayload = {
          index: payload.index,
          partCode: partCode.partcode,
          partCodeId: partCode.id,
          desc: partCode?.desc ?? ''
        }
        commit(Mutations.SET_PART_AS_PART_CODE, partCodeUpdatePayload , { root: true })
      }
    }
    commit(LocalMutations.SET_IS_UPDATING, false)
  },
  bulkDraftBomItemUpdate ({ commit }: Context, payload: IndexPartDataSupplier): void {
    commit(Mutations.BULK_DRAFT_BOM_ITEM_UPDATE, payload, { root: true })
    commit(LocalMutations.SET_IS_DIRTY, true)
  },
  deleteDraftBomItem ({ commit, getters, rootState }: Context, refKeys: Array<string>):void {
    const partsToDelete: number[] = []
    const childrenToUpdate: IndexTypeValue[] = []

    const drillDown = (ref: string) => {
      try {
        // Ensure matching part exists
        const part = rootState.draftPage.draftBomItems.find((item: DraftBomItem) => item.refKey === ref)
        const hasChildren = Object.prototype.hasOwnProperty.call(part, 'children')
        const hasParent = Object.prototype.hasOwnProperty.call(part, 'parent')

        if (part && hasChildren && hasParent) {
          // Cache children
          const { parent } = part
          const children = part?.children ?? []
          const hasChildren = children.length > 0 ?? false

          // Add part to partsToDelete List
          const idx: number|undefined = getters.refKeyToMasterListIdx[ref]
          if (typeof idx === 'number') {
            partsToDelete.push(idx)
          }

          // If the Bom Item had a parent & the parent isn't also being deleted,
          // then remove the item from the parent's children field
          if (parent && typeof getters.refKeyToMasterListIdx[parent] === 'number') {
            const parentIdx: number = getters.refKeyToMasterListIdx[parent]
            const oldChildren = rootState.draftPage.draftBomItems[parentIdx]
              .children || []
            const updatedChildren: string[] = oldChildren
              .filter((child: string) => child !== ref)

            // Add updatedChildren to childrenToUpdate list
            childrenToUpdate.push({ index: parentIdx, type: EditTypes.children, value: updatedChildren })
          }

          // If the Bom Item had children, then recursively call fn on all of them
          if (hasChildren) {
            children.forEach((child: string) => drillDown(child))
          }
        }
      } catch {
        return true
      }
    }

    // Call recursive helper function to account for nested child parts
    refKeys.forEach((key: string) => {
      drillDown(key)
    })

    // Call bulk delete and update mutations
    commit(Mutations.BULK_PART_UPDATES, childrenToUpdate, { root: true })
    commit(Mutations.DELETE_DRAFT_BOM_ITEMS, partsToDelete, { root: true })
    commit(Mutations.CLEAR_SELECTED_ROWS, '', { root: true })
    commit(LocalMutations.SET_IS_DIRTY, true)
  },
  async addPart ({ commit, getters, dispatch, rootState, state, rootGetters }: Context): Promise<void> {
    // If in edit mode when adding a part, waiting here prevents a race condtion
    // b/w addPart & updateDraftBomItem, which is called to ensure everything newly
    // typed into the field in edit mode is not lost by adding a part
    if (state.isUpdating) {
      while (state.isUpdating) await (BluebirdPromise as any).delay(100)
    }

    const refKey = uuidv4()
    const highlightedPart = getters.highlightedPart
    const dropIndex = getters.dropIndex
    const [indentation, parent] = getters.getIndentationAndParent

    // If there's a part in the list currently highlighted,
    // then place the new part below it.
    // Additionally, if the highlighted part has children & is expanded,
    // then make the new part the first child of the highlighted part
    // If the highlighted part has children & is NOT expanded,
    // then make the new part the next sibling of the highlighted part
    if (highlightedPart && parent) {
      const parentIdx = getters.refKeyToMasterListIdx[parent]
      const parentIsSelected = parent === getters.highlightedPart.refKey
      const addAsFirstChildOfSelected = [refKey].concat(highlightedPart.children)
      const addAsSiblingOfSelected = rootState.draftPage.draftBomItems[parentIdx].children
        .concat([refKey])
      const addAsChildOrSibling = parentIsSelected ? addAsFirstChildOfSelected : addAsSiblingOfSelected
      const children = getters.hasSelectedCollapsedParent
        ? getters.getUpdatedParentChildren(parentIdx, refKey)
        : addAsChildOrSibling
      await dispatch('updateDraftBomItem', { index: parentIdx, type: EditTypes.children, value: children })
      await dispatch('updateDraftBomItem', { index: parentIdx, type: EditTypes.expanded, value: true })
    }
    const part = new DraftPart({
      draftPageId: rootState.draftPage.id,
      refKey,
      indentation,
      parent,
      locale: rootState.user.locale
    })
    // If there is a cell in edit mode, then the index
    let partRefInEditMode
    let fieldInEditMode = ''
    const hasCellInEditMode = rootGetters['draftBom/isInCellEditMode']
    if (hasCellInEditMode) {
      const { index, type } = rootState.draftBom.cellInEditMode
      partRefInEditMode = rootState.draftPage.draftBomItems[index].refKey
      fieldInEditMode = type
    }

    // Conditionally apply default supplier & UOM
    const defaultSupplier = getDefaultSupplier(rootState.user.tenantDefaultSupplier, rootState.suppliers.suppliers)
    if (!!defaultSupplier) {
      part.supplierId = defaultSupplier.supplierId
    }
    const defaultUom = getDefaultUom(rootState.user.tenantDefaultUom)
    if (!!defaultUom) { part.unitOfMeasure = defaultUom }

    // Add part to the draftBOM list
    commit(Mutations.ADD_PART, { part, index: dropIndex }, { root: true })
    commit(LocalMutations.SET_IS_DIRTY, true)

    // Update index of cell in edit mode accordingly
    if (hasCellInEditMode) {
      const updatedIdx = getters.refKeyToMasterListIdx[partRefInEditMode]
      commit('draftBom/SET_CELL_IN_EDIT_MODE', { index: updatedIdx, type: fieldInEditMode }, { root: true })
    }
  },
  async showPartAndAllParents ({ commit, getters, rootState }: Context, idx: number): Promise<void> {
    const partUpdates: IndexTypeValue[] = []
    const addPartAndCheckParent = (index: number) => {
      const { parent, visible, refKey } = rootState.draftPage.draftBomItems[index]
      // Determine if parent is at root level and is already visible
      const parentIndex = parent ? getters.refKeyToMasterListIdx[parent] : null
      const parentIsNotRoot = typeof parentIndex === 'number'
      // Ensure parent is expanded if there is one
      if (parentIsNotRoot) {
        partUpdates.push({ index: parentIndex, type: EditTypes.expanded, value: true })
      }

      // Ensure the part and its siblings are visible
      const parentPartChildren = rootState.draftPage.draftBomItems[parentIndex]?.children ?? []
      const partAndSiblings = parentIsNotRoot
        ? parentPartChildren.filter((childRef: string) => !(childRef === refKey && visible))
        : []
      partAndSiblings
        .map((childRef: string) => getters.refKeyToMasterListIdx[childRef])
        .forEach((idx: number) => partUpdates.push({ index: idx, type: EditTypes.visible, value: true }))
      // Recursively invoke until all updates are pushed to list
      if (parent) {
        addPartAndCheckParent(parentIndex)
      }
    }

    addPartAndCheckParent(idx)
    commit(Mutations.BULK_PART_UPDATES, partUpdates, { root: true })
  },
  async openDropDown ({ commit, getters }: Context, { refKey, children }: PartAndChildren): Promise<void> {
    // Set translucent spinners to run
    commit(Mutations.SET_IS_UPDATING, true, { root: true })
    await (BluebirdPromise as any).delay(1)

    // Expand the part's dropdown and show its children
    const masterListIdx = getters.refKeyToMasterListIdx[refKey]
    const childrenIndices = children.map(childRef => getters.refKeyToMasterListIdx[childRef])

    commit(Mutations.UPDATE_DRAFT_BOM_ITEM, { index: masterListIdx, type: EditTypes.expanded, value: true }, { root: true })
    commit(Mutations.SHOW_ALL_CHILDREN, childrenIndices, { root: true })

    // Stop spinners and update visible list
    commit(Mutations.SET_IS_UPDATING, false, { root: true })
  },
  async closeDropDown ({ commit, getters, rootState }: Context, { refKey, children }: PartAndChildren): Promise<void> {
    // Set translucent spinners to run
    commit(Mutations.SET_IS_UPDATING, true, { root: true })
    await (BluebirdPromise as any).delay(1)

    // Build a list of all nested children to hide & collapse
    const allNestedChildIndices: number[] = []
    const childrenIdxMap: StringToNumberMap = {}
    const getNestedParts = (childIdxList: number[]) => {
      childIdxList.forEach((idx: number) => {
        allNestedChildIndices.push(idx)
        childrenIdxMap[idx] = idx
        const children = rootState.draftPage.draftBomItems[idx]?.children ?? []
        const nestedChildIndices: number[] = children
          .map((child: string) => getters.refKeyToMasterListIdx[child]) ?? []
        if (children.length > 0) {
          getNestedParts(nestedChildIndices)
        }
      })
    }
    const childIdx = children.map(ref => getters.refKeyToMasterListIdx[ref])
    getNestedParts(childIdx)

    // Collapse the selected row & then collapse and hide all its children recursively
    commit(Mutations.UPDATE_DRAFT_BOM_ITEM, { index: getters.refKeyToMasterListIdx[refKey], type: EditTypes.expanded, value: false }, { root: true })
    commit(Mutations.HIDE_ALL_CHILDREN, allNestedChildIndices, { root: true })
    if (allNestedChildIndices.includes(rootState.draftBom.cellInEditMode?.index)) {
      commit('draftBom/SET_CELL_IN_EDIT_MODE', {}, { root: true })
    }

    /*
     * If the user closes a parent part &&
     * the currently selected items include just children of the part
     * without the part itself,
     * then clear selected items on closing a parent part.
     */
    let hasSelectedOnlyChildren = true
    for (let i = 0; i < getters.selected.length; i++) {
      const currentIdx = getters.selected[i]
      if (!childrenIdxMap[currentIdx]) {
        hasSelectedOnlyChildren = false
        break
      }
    }

    if (hasSelectedOnlyChildren) {
      commit(Mutations.CLEAR_SELECTED_ROWS, '', { root: true })
    }

    // Stop spinners and update visible list
    commit(Mutations.SET_IS_UPDATING, false, { root: true })
  },
  async selectDropdownItem ({ rootState, dispatch, commit }: Context, payload: any): Promise<void> {
    commit(LocalMutations.SET_IS_SELECTING_DD, true)
    try {
      const data = await getPart(payload.val.entityId)
      const globalPrices = await getGlobalPartPricing(payload.val.entityId)
      const partTags = await getPartTagsByPartId(payload.val.entityId)

      data.partTagValues = formatPartTagValues(partTags, rootState.draftPage.tenantId)
      data.retailPrice = globalPrices?.retail ?? 0
      data.wholesalePrice = globalPrices?.wholesale ?? 0
      data.discountedPrice = globalPrices?.discount ?? 0
      const { supplierId } = rootState.suppliers.suppliers
        .find((supplier: any) => supplier.supplierKey === data.supplierKey)

      await dispatch(draftBomStorePaths.bulkDraftBomItemUpdate, {
        partData: data,
        index: payload.indexInMasterList,
        supplierId
      }, { root: true })
      await dispatch(draftBomStorePaths.setCellInEditMode, {}, { root: true })
    } catch {
      postError({
        title: i18n.global.t('draftBomError'),
      })
    } finally {
      await dispatch(draftBomStorePaths.setSearchSuggestions, [], { root: true })
      commit(LocalMutations.SET_IS_SELECTING_DD, false)
    }
  },
  setIsExporting({ commit }: Context, payload: boolean) {
    commit(LocalMutations.SET_IS_EXPORTING, payload)
  },
  setIsImporting({ commit }: Context, payload: boolean) {
    commit(LocalMutations.SET_IS_IMPORTING, payload)
  },
  setIsDirty({ commit }: Context, payload: boolean) {
    commit(LocalMutations.SET_IS_DIRTY, payload)
  }
}

const mutations: MutationTree <DirtyState> = {
  [LocalMutations.SET_IS_DIRTY] (state, payload: boolean) {
    state.isDirty = payload
  },
  [LocalMutations.SET_IS_EXPORTING] (state, payload: boolean) {
    state.isExporting = payload
  },
  [LocalMutations.SET_IS_IMPORTING] (state, payload: boolean) {
    state.isImporting = payload
  },
  [LocalMutations.SET_IS_UPDATING] (state, payload: boolean) {
    state.isUpdating = payload
  },
  [LocalMutations.SET_IS_SELECTING_DD] (state, payload: boolean) {
    state.isSelectingDropdown = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    draftTags,
    dragAndDrop,
    draftHotpointLinks
  }
}
