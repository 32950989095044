<template>
  <th
    class="tag-header is-hidden-mobile"
    :class="className"
  >
    {{ name }}
  </th>
</template>

<script>
export default {
  name: 'BomHeaderTag',
  props: {
    column: {
      type: Object,
      required: true
    }
  },
  computed: {
    className () {
      return `bom-tag-${this.column.property}`
    },
    name () {
      if (this.column.name.indexOf('%%') === 0) {
        return this.$i18n.tc(this.column.name.replace(/%%/g, ''), 2)
      }
      return this.column.name
    }
  }
}
</script>
