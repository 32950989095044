<template>
  <b-field>
    <b-input  :value="email"
              disabled
              expanded />
    <b-button type="is-danger is-light"
              icon-left="trash"
              class="ml-1"
              outlined
              @click="deleteEmail"/>
  </b-field>
</template>

<script>
export default {
  name: "EmailEntry",
  props: {
    email: { 
      type: String,
      default: ""
    }
  },
  methods: {
    deleteEmail() {
      this.$emit('deleteEmail', this.email)
    }
  }
}
</script>