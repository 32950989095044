<template>
  <span @click="goHome"
    class="navbar-item"
  >
    <div class="is-arrowless navbar-link is-paddingless">
      <i class="fas fa-home home-icon" />
    </div>
  </span>
</template>
<script setup>
import { useWidgetNavigator } from '@/components/widgets/composables/useWidgetNavigator'
const { goHome } = useWidgetNavigator()
</script>
<style scoped>
.home-icon {
  margin-left: .25em;
}
</style>
