import { ref, Ref, readonly } from 'vue'

const isLoading = ref(false)
const errorFetchingContent: Ref<any|null> = ref(null)

export function useFetch() {
  function fetchDecorator(fun: Function) {
    return async function(...args: any) {
      isLoading.value = true
      try {
        await fun(...args)
      } catch(e: any) {
        errorFetchingContent.value = e
      } finally {
        await new Promise(resolve => setTimeout(resolve, 250))
        isLoading.value = false
      }
    }
  }

  function setIsLoading(val: boolean) {
    isLoading.value = val
  }

  function setErrorFetchingContent(val: any) {
    errorFetchingContent.value = val
  }

  return {
    isLoading: readonly(isLoading),
    errorFetchingContent: readonly(errorFetchingContent),
    fetchDecorator,
    setIsLoading,
    setErrorFetchingContent
  }
}