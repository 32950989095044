import http from '@/http'
import search from './search'
import { getRouteParamId } from "@/helpers/router"
import { getRoute } from "@/router/getRoute"

const BROWSE = 'BROWSE'
const BROWSE_LOADING = 'BROWSE_LOADING'
const BROWSE_RESET = 'BROWSE_RESET'

const state = {
  id: '',
  name: '',
  children: [],
  breadcrumb: [],
  isLoaded: false
}

const actions = {
  async getBrowse({commit, rootState, state}, { id = '', router }) {
    try {
      const route = getRoute()
      commit(BROWSE_LOADING)
      const ids = id.split('/')
      const {[ids.length - 1]: last} = ids

      const fetch = [
        http.get(`browse/${last}`)
      ]
      if (last) {
        fetch.push(http.get(`browse/${last}/breadcrumb`))
      }
      const [result1, result2] = await Promise.all(fetch)
      await commit(BROWSE, {
        taxon: result1.data,
        breadcrumb: result2 ? result2.data : []
      })

      if (id && result1.data.children.length === 0 &&
          route.name !== 'BrowseSearch') {
        await router.replace({
          path: `/browse/${id}/search`
        })
      }
    } catch (err) {
      const response = err.response? err.response : err
      await router.replace({path: '/error', query: {status: response.status? response.status : '' }})
    }
  },
  async navigateToBrowse(context, { taxon, router }) {
    const route = getRoute()
    const id = await getRouteParamId(route.params.id)
    console.log("navigateToBrowseId", id)
    if (taxon.hasChildren) {
      await router.push({
        path: `/browse/${id ? `${id}/${taxon.id}` : taxon.id}`
      })
    } else {
      await router.push({
        path: `/browse/${id ? `${id}/${taxon.id}` : taxon.id}/search`
      })
    }
  },
  navigateToHome({ rootState },{ replace = false, router: router }) {
    if (replace) {
      router.replace({name: 'Home'})
    } else {
      router.push({name: 'Home'})
    }
  }
}

const mutations = {
  [BROWSE](state, {taxon, breadcrumb}) {
    if (state.id !== taxon.id) {
      state.search.offset = 0
      state.search.filters.selected = {}
    }
    Object.assign(state, taxon)

    state.breadcrumb = breadcrumb || []
    state.isLoaded = true
    state.search.isLoaded = false
  },
  [BROWSE_LOADING](state) {
    state.isLoaded = false
  },
  [BROWSE_RESET](state) {
    state.id = ''
    state.name = ''
    state.children = []
    state.isLoaded = false
    state.search.path = ''
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    search
  }
}
