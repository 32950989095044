<template>
  <div class="field" >
    <label class="label">
      {{ item.name }}
      <span v-if="canSaveLocal"
            class="is-pulled-right">
        <label class="has-text-weight-normal mr-1">
          {{ $t('save') }}
        </label>
        <input v-model="saveLocal"
               type="checkbox"
               @change="change" />
      </span>
    </label>
    <div class="field has-addons calendar-wrapper">
      <div class="control is-expanded">
        <input class="input"
               readonly
               type="text"
               v-model="value"
               :class="{'is-danger': !isValid}"
               :disabled="!isEditable"
               @click.stop.prevent="toggleCalendar" />
      </div>
      <div class="control">
        <button class="button has-text-info"
                :disabled="!isEditable"
                @click.stop.prevent="toggleCalendar">
          <span class="icon">
            <i class="fas fa-calendar-alt"/>
          </span>
        </button>
      </div>
    </div>
    <p v-if="!isValid"
       class="help is-danger">
      {{ $t('invalidField', {field: item.name}) }}
    </p>
    <calendar v-show="showCalendar"
              v-on-clickaway="closeCalendar"
              class="calendar"
              @selectDate="selectDate" />
  </div>
</template>

<script>
import Calendar from '@/components/common/calendar/Calendar'
import getSaveLocalState from '@/components/library/orders/cells/getSaveLocalState'
import { directive as onClickaway } from 'vue3-click-away'
import { getTime, format } from 'date-fns'

export default {
  name: 'OrderCellDate',
  components: {
    Calendar
  },
  directives: {
    onClickaway
  },
  props: {
    canEdit: Boolean,
    canSaveLocal: Boolean,
    data: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showCalendar: false
    }
  },
  computed: {
    isEditable () {
      return this.item.editable && this.canEdit
    },
    isValid () {
      if (this.item.required) {
        return !!this.value
      }
      return true
    }
  },
  methods: {
    change () {
      this.$emit('change', {
        field: this.item,
        value: getTime(this.value),
        updateLocal: this.saveLocal && this.isValid
      })
    },
    closeCalendar () {
      this.showCalendar = false
    },
    selectDate (rawDate) {
      this.value = this.formatDate(rawDate)
      this.change()
      this.toggleCalendar()
    },
    toggleCalendar () {
      this.showCalendar = !this.showCalendar
    }
  },
  setup (props, context) {
    const { saveLocal } = getSaveLocalState(props)
    const formatDate = (rawDate) => format(rawDate, 'YYYY-MM-DD')
    const value = formatDate(props.data)

    /*
     * Ensure initial value is set, especially when user cannot edit field.
     * Dates should default to system time
     */
    context.emit('change', {
      field: props.item,
      value: getTime(props.data),
      updateLocal: saveLocal.value && !!value
    })

    return {
      formatDate,
      saveLocal,
      value
    }
  }
}
</script>

<style scoped>
.calendar-wrapper {
  margin: 0;
  padding: 0 !important;
}
.has-text-info {
  color: #3273dc;
  cursor: pointer;
}
.calendar {
  position: absolute;
  z-index: 999;
}
.field {
  padding: .5rem 1rem;
  width: 100%;
}
</style>
