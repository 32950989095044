export function getLocalStorage(val: string): string|null {
    return localStorage.getItem(val)
}

export function setLocalStorage(type: string, val: string): void {
    localStorage.setItem(type, val)
}

export function clearLocalStorage(type: string): void {
    localStorage.removeItem(type)
}