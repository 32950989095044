<template>
  <section class="section is-paddingless">
    <template v-if="content.openInNewBrowserWindow">
      <a :href="content.externalUrl"
         target="_blank">
        <thumbnail
          :thumbnail-url="content.thumbnailUrl"
          :thumbnail-type="content.contentType"
          data-int="card-thumbnail"
          class="is-128x128" />
        <label class="is-size-5 has-text-centered">
          {{ $t("clickToOpen") }} {{ content.externalUrl }}
        </label>
      </a>
    </template>
    <template v-else>
      <iframe :class="{ 'is-xv2': isXv2 }"
              :src="content.externalUrl" />
    </template>
  </section>
</template>
<script>
import { mapState } from 'vuex';
import Thumbnail from '@/components/common/Thumbnail';
export default {
  name: 'ExternalViewer',
  components: {
    Thumbnail
  },
  computed: {
    ...mapState({
      content: ({ content }) => content
    }),
    isXv2() {
      return (
        this.content.externalUrl &&
        this.content.externalUrl.slice(-4) === '.xv2'
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.section {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  position: relative;
}
.image-centered {
  margin: auto;
}
iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  &.is-xv2 {
    top: 0;
    left: 0;
  }
}
</style>
