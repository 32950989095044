<template>
  <div class="level-item">
    <figure class="media-left">
      <a :href="attachment" target="_blank">
        <span class="icon">
          <i class="fa fa-lg fa-paperclip"/>
        </span>
        {{ attachmentName(attachment) }}
      </a>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'NotificationAttachment',
  props: {
    attachment: {
      type: String,
      default: ''
    }
  },
  methods: {
    attachmentName(attachment) {
      const paths = attachment.split('/');
      const {[paths.length - 1]: last} = paths;

      return last;
    }
  }
};
</script>

<style scoped>

</style>
