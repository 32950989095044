/**
 * For the 'accept' attribute in an input field
 */
export const acceptedFileTypes: string = [
  ".3ds",
  ".3dm",
  ".3dxml",
  ".3mf",
  ".arc",
  ".asm",
  ".catdrawing",
  ".catpart",
  ".catproduct",
  ".catshape",
  ".cgr",
  ".dae",
  ".dgn",
  ".dlv",
  ".dwf",
  ".dwfx",
  ".dwg",
  ".dxf",
  ".exp",
  ".fbx",
  ".glb",
  ".gltf",
  ".iam",
  ".iges",
  ".ifc",
  ".ifczip",
  ".igs",
  ".ipt",
  ".jt",
  ".model",
  ".mf1",
  ".neu",
  ".nwd",
  ".obj",
  ".par",
  ".pkg",
  ".prt",
  ".prc",
  ".psm",
  ".pwd",
  ".rfa",
  ".rvt",
  ".sab",
  ".sat",
  ".session",
  ".sldasm",
  ".sldprt",
  ".step",
  ".stl",
  ".stp",
  ".stpx",
  ".stpxz",
  ".stpz",
  ".u3d",
  ".unv",
  ".vda",
  ".vrml",
  ".wrl",
  ".xas",
  ".x_b",
  ".xpr",
  ".x_t",
  ".xmt",
  ".xmt_txt",
  ".zip"
].join(',')

