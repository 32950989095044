export interface PaginationHeaders {
  'x-count'?: string,
  'x-limit'?: string,
  'x-offset'?: string,
  'x-page-count'?: string,
  'x-page-num'?: string,
  'x-total'?: string,
  'x-cursor'?: string
}

function getPageCount (total: number, limit: number): number {
  let count = Math.trunc(total / limit)
  // has remainder
  if ((total % limit) > 0) count++
  return count
}
function getPageNumber (offset: number, limit: number): number {
  return offset / limit + 1
}

function getOffset (cursor: string, limit: number, count: number) {
  const cur = Number(cursor.split(',').pop())
  let offset
  // handle when last set is returned. do not update offset to cursor count returned
  if (cur === count) {
    offset = Math.trunc(count / limit) * limit
  } else {
    offset = cur - limit
  }
  return offset
}
export class Pagination {
  count?: number
  limit?: number
  offset?: number
  pageCount?: number
  pageNum?: number
  total?: number
  cursor? : string

  constructor (headers: PaginationHeaders) {
    /*
      New pagination api returns:
      x-total
      x-page-count
      x-page-num
      x-offset
      If those are not present, use the mixed results pagination api returns to set those values:
      x-total
      x-count
      x-limit
      x-cursor
     */
    this.count = Number(headers['x-count'])
    this.limit = Number(headers['x-limit'])
    this.total = Number(headers['x-total'])
    this.cursor = headers['x-cursor'] || undefined
    this.offset = headers['x-offset'] ? Number(headers['x-offset']) : getOffset(this.cursor!, this.limit, this.count)
    this.pageCount = headers['x-page-count'] ? Number(headers['x-page-count']) : getPageCount(this.count, this.limit)
    this.pageNum = headers['x-page-num'] ? Number(headers['x-page-num']) : getPageNumber(this.offset, this.limit)
  }
}

export default {
  Pagination
}
