/* eslint-disable max-len */
module.exports =
{
  aOfB: '{a} di {b}',
  about: 'Informazioni',
  aboutThisItem: 'Informazioni su questo elemento',
  accessControls: 'Controlli degli Accessi',
  accessControlsCopiedText: 'I controlli degli accessi vengono aggiornati immediatamente. I privilegi di sicurezza dei media verranno aggiornati nei prossimi minuti.',
  accessControlsCopiedTitle: 'Controlli degli accessi copiati con successo',
  accessControlsCopyConfirmation: 'Questa procedura rimuove tutti i controlli degli accessi esistenti da {to} e li sostituisce con i controlli degli accessi da {from}. Questa azione non può essere annullata.',
  accessControlsDescription: 'I controlli degli accessi definiscono chi può visualizzare questo medium, in base alle Organizzazioni e alle Categorie Media selezionate.',
  accessControlsIndeterminance: 'Nota: Le organizzazioni madri hanno sempre accesso al contenuto assegnato ai loro figli.',
  account: 'Account',
  accountErrorMessage: 'Messaggio di errore dell’account',
  action: 'Azione | Azioni',
  actionDelete: 'cancella',
  actionLoad: 'carica',
  actionPaste: 'Incolla',
  actionSave: 'Salva',
  actionRun: 'esegui',
  actionUndoWarning: 'Questa azione non può essere annullata.',
  actionUpload: 'carica',
  actualSize: 'Dimensione Reale',
  add: 'Aggiungi',
  addAPIKey: 'Aggiungi una Chiave API',
  addItem: 'Aggiungi {item}',
  addABookText: 'Aggiungi un libro',
  addASharedCart: 'Aggiungi un carrello condiviso',
  addATemplate: 'Aggiungere un modello',
  addATag: 'Aggiungere un Tag',
  addABookToStart: '{addABookText} per iniziare.',
  addAForm: 'Aggiungi un Modulo per iniziare',
  addAFormOnly: 'Aggiungi un Modulo',
  addAMedia: 'Aggiungere un medium',
  addAnOrderQuoteEmailFormat: 'Aggiungi un Formato E-mail Ordine/Preventivo',
  addAPrintSettingText: 'Aggiungi un’Impostazione di Stampa',
  addAPrintSettingToStart: '{addAPrintSettingText} per iniziare.',
  addBooks: 'Aggiungi Libri',
  addChildBrowseFlow: 'Aggiungi Flusso di Navigazione Figlio',
  addChapter: 'Aggiungi Capitolo',
  addComment: 'Aggiungi un commento',
  addExistingPart: 'Aggiungi Parte Esistente',
  addGroupsToUsers: 'Aggiungi {groups} gruppi a {users} utenti',
  additionalInformation: 'Informazioni Aggiuntive',
  additionalNotification: 'Notifica Aggiuntiva',
  additionalPartNumberDescription: 'Usa come campo numero parte aggiuntivo',
  addBrowseFlow: 'Aggiungi Flusso di Navigazione',
  addMedia: 'Aggiungi Media',
  addPages: 'Aggiungi Pagine',
  addPart: 'Aggiungi una Parte',
  addReports: 'Aggiungi Report',
  addRootBrowseFlow: 'Aggiungi la Radice del Flusso di Navigazione',
  addRow: 'Aggiungi Riga',
  addTemplates: 'Aggiungere modelli',
  addToCartMessage: '{partNumber} aggiunto correttamente',
  addToUserGroup: 'Aggiungi ai Gruppi',
  address: 'Indirizzo | Indirizzi',
  addressInUse: 'Un indirizzo è già stato impostato come predefinito e non può essere cancellato',
  addressInUseSubtitle: 'Per cancellare questo indirizzo, è necessario prima rimuoverlo come predefinito.',
  addressLine: 'Riga dell‘indirizzo {number}',
  addWidgetKey: 'Aggiungi una chiave widget',
  admin: 'Amministrazione',
  adminCenter: 'Centro Amministrazione',
adminCenterBanner: {
    bodyLabel: 'Corpo del banner',
    enableLabel: 'Abilita banner amministratore',
    headerGreenOption: 'Verde',
    headerLabel: 'Intestazione banner',
    headerRedOption: 'Rosso',
    headerYellowOption: 'Giallo',
    title: 'Banner interfaccia di amministrazione',
    typeLabel: 'Tipo di banner'
  },
  adminCenterMessage: 'Centro gestione del contenuto , utenti, impostazioni libreria, reportistica ed esportazioni',
  adminMessage: 'Caratteristiche aggiunte non appena diventano disponibili',
  administration: 'Amministrazione',
  affectedBookMedia: '{count} Media libro saranno aggiornati.',
  affectedTemplateMedia: '{count} Modelli di libri saranno aggiornati.',
  after: 'Dopo',
  aggregatedFromChildren: 'Aggregati dai Figli',
  aiAlgorithm: 'Scanner AI',
  all: 'Tutto',
  alphanumeric: 'Alfanumerico',
  algorithm: 'Algoritmo',
  alignHorizontal: 'Allinea in Orizzontale',
  alignVertical: 'Allinea in Verticale',
  analyticsJSON: 'Analisi JSON',
  andCountMore: 'e altri {count}...',
  any: 'Qualsiasi',
  apiKey: 'Chiave API | Chiavi API',
  apiKeyAdmin: 'Amministratore Chiave API',
  apiAuthHeader: 'Intestazione Autorizzazione API',
  apiKeyCopied: 'Valore Intestazione Autorizzazione API copiato negli Appunti',
  apiKeyDeleted: 'Chiave API eliminata',
  apiKeyDeletedError: 'Errore durante l’eliminazione della Chiave API',
  apiKeyDeleteMsg: 'L’eliminazione della seguente Chiave API disabiliterà il suo utilizzo da parte delle integrazioni',
  apiKeyDeleteTitle: 'Sei sicuro di voler eliminare questa Chiave API?',
  apiKeyInstructions: 'Si genererà una chiave una volta eseguito il Salvataggio',
  apiKeyManager: 'Gestore Chiave API',
  apiKeyNameEmpty: 'Il nome non può essere vuoto',
  apiKeyRegenError: 'Errore durante la rigenerazione della Chiave API',
  apiKeyRegenMsg: 'La rigenerazione della seguente Chiave API potrebbe disabilitare le integrazioni esistenti',
  apiKeyRegenTitle: 'Sei sicuro di voler rigenerare questa Chiave API?',
  apiKeySaved: 'Chiave API Salvata',
  appliesTo: 'Si applica a',
  appliesToEmpty: 'Richiede la selezione di almeno un tipo di entità',
  appliesToPopulated: 'Selezionare a quali entità può essere applicato il tag',
  applyItem: 'Applica {item}',
  applyToChildItems: 'Applica al Figlio {item}',
  april: 'Aprile',
  area: 'Area',
  areYouSure: 'Sei sicuro di voler eliminare {item}?',
  areYouSureCopy: 'Sei sicuro di voler copiare {item}?',
  assembly: 'Assemblaggio | Assemblaggi',
  assemblyCreatorSuccessTitle: 'Creazione dell’assieme inviata',
  assemblyCreatorSuccessSubtitle: 'Le modifiche ai collegamenti hotpoint e all’indice del libro verranno effettuate immediatamente. I risultati della ricerca verranno visualizzati una volta completata l’indicizzazione.',
  assemblyConfirmationTitle: 'Vuoi completare l’assemblaggio creato?',
  assemblyCreatorHelpText1: 'Questa funzione ti permette di inserire una pagina selezionata nei libri ovunque si trovi la parte {partName}, collegando automaticamente la parte e la pagina selezionata attraverso un link hotpoint. A scelta, puoi restringere l’inserimento ai soli libri selezionati.',
  assemblyCreatorHelpText2: 'La pagina selezionata viene inserita una sola volta per libro dopo la prima occorrenza di {partName}.',
  assemblyStepOne: 'Fase 1: Seleziona la pagina da inserire',
  assemblyStepTwo: 'Fase 2: Scegliere i libri in cui inserire la pagina selezionata',
  asset: 'Risorsa | Risorse',
  assetsNotFound: 'Nessun {type} Trovato',
  assignBrowseFlow: 'Assegna Flusso di Navigazione',
  assignedOrganizations: 'Organizzazioni Assegnate',
  atLeastFiveError: 'Seleziona un valore non inferiore a 5.',
  attachment: 'Allegato | Allegati',
  attachmentEditorDescription: 'Allega immagini, video e documenti direttamente a questa entità. Nota: si troveranno nella scheda "Correlato".',
  attachmentView: 'Vedi Allegati',
  attachRelatedPDFs: 'Allegare PDF delle relative pagine delle parti',
  attributions: 'Assegnazioni',
  august: 'Agosto',
  automaticZoom: 'Zoom Automatico',
  autoHotpoint: 'Hotpoint Automatico',
  autoPopulate: 'Popola Automaticamente',
  availability: 'Disponibilità',
  back: 'Indietro',
  backCover: 'Copertina Posteriore',
  backStitch: 'Cucitura Posteriore',
  backToLibrary: 'Torna a Libreria',
  backToSearch: 'Torna a Cerca',
  badCredentials: 'Credenziali Non Valide',
  bahasaDescription: 'Descrizione in Bahasa Indonesiano',
  bahasaName: 'Nome in Bahasa Indonesiano',
  banner: 'Banner',
  before: 'Prima',
  betaAccessKeys: 'Chiavi di accesso beta',
  betaResources: 'Risorse Beta',
  betaReleaseVideo: 'Video Versione Beta',
  blobFinder: 'Rilevatore BLOB',
  body: 'Corpo',
  bomDefaultSupplier: 'Fornitore BOM Predefinito',
  bomItem: 'Elemento BOM',
  bom: 'BOM',
  bomExportSuccess: 'Esportazione BOM Riuscita',
  bomLayout: 'Layout della Distinta dei Materiali',
  bomListOptions: 'Opzioni distinta base',
  bomListSettings: 'Impostazioni distinta base',
  both: 'Entrambi',
  book: 'Libro | Libri',
  bookInserts: 'Inserti Libro',
  bookLabel: 'Libro | Libri',
  bookPrintingDisable: 'Disabilita Stampa Libro',
  booksAndPagesDisplay: 'Visualizza Libri e Pagine',
  boost: 'Potenzia la Ricerca',
  boostDescription: 'Aggiungi un valore superiore a 1,5 per aumentare la pertinenza della ricerca di questo tag rispetto a numeri delle parti, identificatori di media e nomi',
  boostRangeInvalid: 'L’aumento deve essere compreso tra 0,5 e 100,0',
  border: 'Bordo',
  bottom: 'Fondo',
  branding: 'Branding',
  browse: 'Sfoglia',
  browseComputer: 'Cerca nel Computer',
  browseFlow: 'Sfoglia Flusso | Sfoglia Flussi',
  browseFlowAssigned: 'Flusso di Navigazione Assegnato',
  browseFlowCopyMessage: 'Inserire un nome unico per il nuovo Flusso di Navigazione.',
  browseFlowDeleted: 'Flusso di navigazione eliminato',
  browseFlowDeleteMessage: 'Cancellare un flusso di navigazione non cancella solo il flusso di navigazione stesso, ma anche tutti i suoi flussi figli.',
  browseFlowEmptyState: 'Nessun Flusso di Navigazione definito. {new} per iniziare.',
  browseFlowHeirarchy: 'Gerarchia Flusso di Navigazione',
  browseFlowNoExistingCriteria: 'Non sono stati impostati criteri di ricerca per questo flusso di navigazione.  Utilizzare i controlli qui sopra per selezionare i criteri di ricerca.',
  browseFlowMoveToRoot: 'Trascinare qui il nodo per creare un figlio di primo livello',
  browseFlowNoAssignedOrgs: 'Nessuna organizzazione assegnata',
  browseFlowSaveFailed: 'Il flusso di navigazione non può essere aggiornato',
  browseFlowSetDefaultSuccess: 'Flusso di Navigazione Predefinito impostato',
  browseFlowThumbnailDeleteFailTitle: 'Cancellazione dell’Anteprima Non Riuscita',
  browseFlowThumbnailDeleteSuccessTitle: 'Cancellazione dell’Anteprima Riuscita',
  browseFlowThumbnailUploadFailTitle: 'Caricamento dell’Anteprima Non Riuscito',
  browseFlowThumbnailUploadSuccessTitle: 'Caricamento dell’Anteprima Riuscito',
  browseFlowTooManyOrgs: 'Hai troppe organizzazioni per utilizzare l`attuale editor del flusso di navigazione. Contattare Documoto Support a {email} per richiedere supporto per la modifica dei flussi di navigazione di Documoto.',
  browserPartiallySupport: 'In questo browser si possono visualizzare differenze estetiche e altre modifiche.',
  browseFlowSaved: 'Flusso di Navigazione salvato',
  browserDeprecation: {
    title: 'Avviso Deprecazione Browser',
    text: 'In linea con la graduale eliminazione del supporto di Microsoft per Internet Explorer (tutte le versioni) e dei relativi problemi di sicurezza, non supporteremo più l`accesso degli utenti a questo sito tramite Internet Explorer (tutte le versioni) dopo il 30 settembre 2021.'
  },
  buildHotpointLinks: 'Crea Link Hotpoint',
  buildHotpointLinksInfo: 'Questa azione prima rimuove tutti i link hotpoint esistenti dalle pagine del libro, poi crea nuovi link hotpoint tra le parti e ogni pagina di assemblaggio compatibile.',
  buildHotpointLinksTitle: 'Vuoi creare link hotpoint su questo libro?',
  bulkIndexingLane: 'Lane per l’indicizzazione del blocco',
  bulkInsert: 'Inserimento Blocco',
  bulkExportConfirm: 'Vuoi esportare {n} {unit}?',
  bulkExportLimit: 'Il limite è {limit} libri per ogni esportazione',
  bulkPageInsertJob: 'Inserimento Pagine in Blocco',
  business: 'Attività',
  cancel: 'Annulla',
  canceled: 'Annullato',
  cancelAssemblyCreatorTitle: 'Eliminare la creazione dell’assieme?',
  cancelAssemblyCreatorSubtitle: 'La creazione dell’assieme non verrà processata.',
  cannotExceed100: 'Non può superare il 100%',
  cart: 'Carrello | Carrelli',
  cartBehaviorEnabled: 'Attiva il Messaggio Aggiungi a Quantità del Carrello',
  cartBehaviorDisabled: 'Disattiva il Messaggio Aggiungi a Quantità del Carrello',
  cartBehaviorLabel: 'Aggiungi a il Comportamento Quantità del Carrello',
  cartColumn: 'Colonna Carrello | Colonne Carrello',
  cartColumn1: '1^ Colonna Prezzo del Carrello',
  cartColumn2: '2^ Colonna Prezzo del Carrello',
  cartColumn3: '3^ Colonna Prezzo del Carrello',
  cartColumnPartLocationContent1: 'Abilitare questa funzione può comportare le seguenti implicazioni:  ',
  cartColumnPartLocationContent2: 'L’unicità della riga di una parte nel carrello dipende anche dalla sua posizione all’interno di un contenuto, se applicabile.',
  cartColumnPartLocationContent3: 'Questa impostazione non può essere disabilitata a livello dell’organizzazione e verrà applicata a tutti gli utenti.',
  cartColumnPartLocationContent4: 'Se è presente un’integrazione Invia Carrello, questa impostazione non deve essere disabilitata senza aver prima contattato il Customer Success Manager Documoto.',
  cartColumnPartLocationOption: 'Attivare Dati di Localizzazione delle Parti nel Carrello',
  cartColumnPartLocationTitle: 'Sei sicuro di voler procedere all’attivazione dei Dati di Localizzazione delle Parti nel Carrello?',
  cartDisable: 'Disabilita il Carrello',
  cartEmailFailed: 'Invio Email di Carrello non riuscito',
  cartEmailSent: 'Email di Carrello inviata',
  cartEnable: 'Attiva il Carrello',
  cartSaveEnable: 'Attiva il Salvataggio del Carrello',
  cartErpMessage: 'Inviato correttamente a Erp',
  cartImportError: 'Errore durante l’importazione del carrello',
  cartLoadedWithItemsRemoved: '{count} articolo(i) rimosso(i) perché non disponi dell’autorizzazione per ordinarlo(i) in questo momento',
  cartSharedAddEdit: 'Aggiungi/Modifica Carrelli Condivisi',
  cartUpdated: 'Carrello Aggiornato',
  cartUpdatedAndRemovedCount: 'Carrello Aggiornato; {count} parti non ordinabili rimosse',
  category: 'Categoria | Categorie',
  categoryCreated: 'Categoria Creata',
  categoryUpdated: 'Categoria Aggiornata',
  centimeter: 'Centimetro | Centimetri',
  changeNotification: 'Notifica di Modifica',
  changeScope: 'Sono interessati {n} {type}',
  changeUserOrganization: 'Cambio Organizzazione',
  chapter: 'Capitolo | Capitoli',
  chapterHeader: 'Titolo Capitolo',
  chaptersOnly: 'Solo Capitoli',
  chineseDescription: 'Descrizione in Cinese',
  chineseName: 'Nome in Cinese',
  circle: 'Cerchio',
  city: 'Città',
  classicAdminMessage: 'Questo ti reindirizzerà a Flex per utilizzare i moduli di gestione.',
  classicAdmins: 'Gestioni Classiche',
  classicExporter: 'Esportazione Classica',
  classicExporterMessage: 'Modulo di esportazione contenuto Legacy basato su Flash',
  classicOrganizationAdmin: 'Gestore dell’Organizzazione Classica',
  classicOrganizationAdminMessage: 'Modulo di gestione utente Legacy basato su Flash per la gestione delle org. e degli utenti',
  classicPublisher: 'Editore Classico',
  classicPublisherMessage: 'Modulo di pubblicazione Legacy basato su Flash per creazione di contenuti e impostazioni di stampa',
  classicReporting: 'Reportistica Classica',
  classicReportingMessage: 'Modulo di reportistica Legacy basato su Flash',
  classicTenantAdmin: 'Gestione Proprietario Classica',
  classicTenantAdminMessage: 'Modulo di gestione Legacy basato su Flash per le impostazioni ampie del titolare',
  className: 'Nome classe',
  clear: 'Elimina',
  clearItemNumber: 'Cancella il numero dell‘articolo',
  clearItemNumberTitle: 'Volete cancellare il numero dell‘articolo?',
  clearItemNumberMessage: 'La cancellazione del codice prodotto comporta la rimozione dei seguenti dati:',
  clearFilters: 'Cancella i Filtri',
  clearHotpoints: 'Elimina Hotpoint',
  clearSearch: 'Cancella Ricerca',
  clickHereToUpload: 'Trascina il file oppure clicca per caricare una sovrascrittura.',
  clickOkToContinue: 'Fai clic su "OK" per continuare.',
  clickToDownload: 'Clicca per il Download',
  clickToOpen: 'Clicca per Aprire',
  clipboard: 'Appunti',
  clipboardAccessError: 'Modifica le impostazioni del browser per consentire l’accesso agli Appunti',
  close: 'Chiudi',
  closed: 'Chiuso',
  collapseAll: 'Riduci Tutto',
  color: 'Colore | Colori',
  column: 'Colonna | Colonne',
  columnName: 'Nome Colonna',
  columnItemType: {
    customText: 'Testo Personalizzato',
    documentTitle: 'Titolo Documento',
    horizontalLine: 'Linea Orizzontale',
    pageName: 'Nome Pagina',
    pageNumber: 'Numero Pagina',
    printDate: 'Data di Stampa',
    tenantName: 'Nome Tenant',
    userName: 'Nome Utente'
  },
  combinedPage: 'Pagina Combinata',
  combinedPageMessage: 'Diagramma in alto, elenco parti in basso',
  comingSoon: 'Imminente',
  commaDelimited: 'Delimitato da virgola',
  comment: 'Commento | Commenti',
  commentAdded: 'Commento aggiunto',
  commentDeleted: 'Commento cancellato',
  commentEdit: 'Aggiungi/Modifica del Commento',
  commentEditAll: 'Modifica Tutti i Commenti',
  commentUpdated: 'Commento aggiornato',
  commentView: 'Vedi i Commenti',
  commerceSystem: 'Sistema di Commercio',
  completed: 'Completato',
  confirm: 'Conferma',
  confirmBrowseFlowApplyToChildren: 'Vuoi applicare questo flusso di navigazione alle organizzazioni figlie?',
  confirmBrowseFlowOverrideToChildren: 'Questo rimuoverà qualunque sovrascrittura dei flussi di navigazione definita nelle organizzazioni figlie.',
  confirmPrintSettingsApplyToChildren: 'Vuoi applicare questa impostazione di stampa alle organizzazioni figlie?',
  confirmPrintSettingsOverrideToChildren: 'Questo rimuoverà qualunque sovrascrittura delle impostazioni di stampa definita nelle organizzazioni figlie.',
  contains: 'Contiene',
  containsHeaderRow: 'Contiene la Riga di Intestazione',
  content: 'Contenuto | Contenuti',
  contentAccessControls: 'Controlli degli Accessi {content}',
  contentEntity: 'Entità del Contenuto | Entità dei Contenuti',
  contentNotFound: 'Contenuto non trovato',
  contentUpdatedQueueJob: 'Coda di Aggiornamento dei Contenuti',
  contourShape: 'Forma Contorno',
  convertEntity: 'Convertire {entity}',
  convertToEntity: 'Convertire in {entity}',
  copyAccessControls: 'Copia i Controlli degli Accessi',
  copyAccessControlsWarning: 'Questo copierà i controlli degli accessi assegnati da una Organizzazione selezionata all’attuale Organizzazione',
  copy: 'Copia',
  copyAndRetain: 'Copia e Conserva per l’Uso dell’Integrazione',
  copyAndRetainWidget: 'Copia e conserva, questa è l’unica volta in cui il valore Autorizzazione widget sarà visibile nell’applicazione',
  copyAtoB: 'Copia {a} in {b}',
  copyContent: 'Copiare {type}? {name}',
  copyingItem: 'Copia di {item}',
  copyItem: 'Copia {item}',
  copyItemQuestion: 'Copiare {item}?',
  copyTo: 'Copia in {Target}',
  copyMeMessage: 'Mettimi in copia su questo messaggio',
  copyrightMessage: '© 2010-{year} Documoto Inc. Tutti i diritti riservati.',
  count: 'Contare',
  country: 'Paese | Paesi',
  coverPages: 'Copertine',
  coverAmpStitchPages: 'Copertina e Pagine dei Punti',
  createAssembly: 'Creare Assemblaggio',
  created: 'Creato',
  createdBy: 'Creato da {name}',
  createdDate: 'Data di Creazione',
  createdOn: 'Creato il',
  currentPassword: 'Password Corrente',
  custom: 'Personalizzazione',
  czechDescription: 'Descrizione in Ceco',
  czechName: 'Nome in Ceco',
  dashboard: 'Pannello | Pannelli',
  dashboardLibrary: 'Libreria Dashboard',
  dataInsights: 'Approfondimenti sui Dati',
  dataInsightsInfoMessage: 'Centro Report e Dashboard per approfondimenti su contenuti, utenti e dati della libreria.',
  dataMapping: 'Mappatura dei Dati/Dati Campione (fino a 10 righe)',
  dataOptions: 'Opzioni Dati',
  date: 'Data',
  dateModified: 'Data Modificata',
  debugPageBuilderPLZ: 'Debug del PLZ del generatore di pagina',
  december: 'Dicembre',
  decreaseHotpoint: 'Riduci Hotpoint',
  default: 'Predefinito',
  defaultAddress: 'Indirizzo Predefinito',
  defaultBrowseFlow: 'Flusso di Navigazione Predefinito',
  defaultCurrencyCode: 'Codice di Valuta Predefinito',
  defaultCurrencySymbolOn: 'Simbolo della valuta per default attivato',
  defaultEmailRecipient: 'Destinatario E-mail Predefinito | Destinatari E-mail Predefiniti',
  defaultImage: 'Immagine Predefinita | Immagini Predefinite',
  defaultImagesManager: 'Gestore di Immagini Predefinite',
  defaultItemImage: 'Immagine Predefinita {item}',
  defaultPartsListLayout: 'Layout Predefinito della Lista delle Parti',
  defaultPartsListLayoutHelp: 'Layout predefinito delle parti a destra o sotto l’illustrazione',
  defaultSearchMode: 'Modalità di Ricerca Predefinita',
  defaultSearchModeHelp: 'Metodo di ricerca globale predefinito per \'contiene\' o \'esatto\'',
  defaultSortMode: 'Modalità di Ordine Predefinita',
  defaultSupplier: 'Fornitore Predefinito',
  defaultTocStateOpen: 'Stato TOC Aperto Predefinito',
  defaultTocStateOpenHelp: 'Determina se il TOC deve aprirsi automaticamente quando si visualizza un libro',
  defaultUom: 'UDM Predefinita',
  defaultValue: 'Valore Predefinito',
  defaults: 'Impostazioni predefinite',
  defaultsAndCapabilities: 'Impostazioni predefinite e Capacità',
  delete: 'Cancella',
  deleteAll: 'Eredita dall’Elemento Genitore.',
  deleteContent: 'Sei sicuro di voler eliminare {type}? {name}',
deletePart: {
    infoOne: 'Le eliminazioni delle parti avranno immediatamente effetto nella Libreria e in Ammin. contenuti. La parte eliminata resterà ricercabile fino alla reindicizzazione entro pochi minuti.',
    deletingPartNumber: '{partNumber} sarà eliminato.',
    affectedCarts: 'Questa parte sarà rimossa in modo permanente da tutti i carrelli che la contengono.',
    infoTwo: 'Questa azione non può essere annullata senza l’assistenza Documoto. Dopo 90 giorni il record eliminato e tutte le associazioni corrispondenti saranno cancellati in modo permanente.'
  },
deletePartInUse: {
    title: 'Questa parte appartiene alla/e pagina/e e non può essere eliminata.',
    body: 'Documoto attualmente supporta solo l’eliminazione di parti che non appartengono alle pagine. Rimuovere questa parte dalle pagine pubblicate o dalle bozze per continuare con l’eliminazione.',
    submitLabel: 'Vai alla pagina dove usata'
  },
  deleteRelated: 'Sei sicuro di voler eliminare {count} {type}?',
  deletedItem: '{item} cancellato',
  deleteFilesMessage: 'Sei sicuro di voler eliminare {count} il (i) file?',
  deleteHotpointLinksTitle: 'Sei sicuro di voler eliminare i collegamenti hotpoint?',
  deleteMessage: 'Sei sicuro di voler eliminare?',
  deleteSelectedCriteria: 'Cancella Criteri Selezionati',
  deleteTagsTitle: 'Sei sicuro di voler eliminare i tag?',
  deleteTaxonMessage: 'Questo cancellerà definitivamente le classificazioni con i relativi figli.’ Sei sicuro di voler procedere?',
  deleteTaxonomyMessage: 'Questo cancellerà definitivamente tutte le classificazioni, per questa Org. o Titolare. Sei sicuro di voler procedere?',
  deleteTenantSupplier: 'Sei sicuro di voler eliminare questo fornitore per il titolare?',
  demo: 'Demo',
  description: 'Descrizione',
  deselectAll: 'Deseleziona Tutto',
  deselectCriteria: 'Deseleziona Criteri',
  detail: 'Dettaglio | Dettagli',
  diagonal: 'Diagonale',
  diameter: 'Diametro',
  disable: 'Disabilita',
  disableAll: 'Disabilita tutto',
  disableFwdReindex: 'Disabilita il Forwarding Reindex Target Media per DSW',
  disableTEK: 'Disattiva Accesso tramite URL Diretto',
  disabled: 'Disabilitato',
  discard: 'Eliminare',
  discardChanges: 'Elimina le modifiche',
  discardQ: 'Elimina le modifiche?',
  discounted: 'Scontato',
  discountedPrice: 'Prezzo Scontato',
  discountedTotal: 'Sconto Totale',
  discountPrice: 'Prezzo Scontato',
  displayInOrdersList: 'Mostra nell’Elenco Ordini',
  displayOrder: 'Mostra Ordine',
  displayOrderDescription: 'Imposta l`ordine di visualizzazione del menu a tendina dei Filtri per i tag del tipo di contenuto',
  document: 'Documento | Documenti',
  documentLabel: 'Documento | Documenti',
  documotoAcademy: 'Documoto Academy',
  documotoLicensePackage: 'Pacchetto di licenza Documoto',
  domain: 'Dominio',
  download: 'Scarica',
  downloadBrowser: 'Scarica uno di questi grandi browser.',
  downloadFailed: 'Errore durante il download di {name}',
  downloadStarted: 'Download in corso di {name}',
  draft: 'Bozza | Bozze',
  draftBomDeleteTitle: 'Sei sicuro di voler eliminare questa parte dalla pagina?',
  draftBomMultipleDeleteTitle: 'Sei sicuro di voler eliminare queste parti dalla pagina?',
  draftBomDropError: 'Impossibile annidare parti a più di 5 livelli di profondità',
  draftBomEmptyField: 'Tutti i campi in Elenco Parti devono essere compilati',
  draftBomQuantityError: 'La quantità deve essere un numero',
  draftBomError: 'Errore nella manipolazione della bozza della Distinta Base',
  draftPage: 'Bozza di Pagina',
  draftPageDelete: 'Elimina Bozza',
  draftPageDeleteMessage: 'Bozza di pagina eliminata con successo.',
  draftPageDeleteWarning: 'Eliminando la bozza di pagina verranno rimossi gli aggiornamenti in corso.',
  draftPageLastUpdated: 'Bozza - Aggiornata l’ultima volta in data {date} da {username}',
  draftPageLoadError: 'Impossibile caricare la pagina di bozza.',
  draftPageProcessingPlz: 'Caricamento della pagina da file PLZ in corso',
  draftPagePublishInProgress: 'Pubblicazione Pagina in corso',
  draftPageSaveSuccess: 'Bozza di pagina salvata con successo.',
  draftPageSaveUnsuccessful: 'Salvataggio della bozza di pagina non riuscito.',
  draftSheetImageHotpointsFailure: 'Impossibile generare hotpoint {algorithm} per l’immagine della bozza.',
  draftValidationMessage: {
    item: 'ATTENZIONE: il DiBa {partNumber} non ha un numero articolo specificato',
    empty: 'Nessun Errore/Avviso di Convalida',
    hotpointOrHotpointLink: 'L’articolo {item} della distinta base non ha hotpoint/collegamenti hotpoint specificati',
    quantity: 'L’articolo {item} della distinta base non ha quantità specificate',
    tag: 'Valore tag mancante tag {tagName} articolo {item} della distinta base',
    translationDescription: 'Descrizione traduzione in {lang} mancante articolo {item} della distinta base',
    uom: 'L’articolo {item} della distinta base non ha unità di misura specificate',
    supersede: 'L’articolo {partNumber} ha sostituito {supersededPartNumber} a causa di una sostituzione.'
  },
  drawAutohotpointRegion: 'Disegna dimensione {shape}',
  drawMinimumArea: 'Disegna area minima',
  dropFileMessage: 'Trascina qui il file oppure clicca per caricarlo.',
  duplicate: 'Duplicato',
  duplicateSaveError: 'Voce doppia. Impossibile salvare.',
  dutchDescription: 'Descrizione in Olandese',
  dutchName: 'Nome in Olandese',
  dynamic: 'Dinamico',
  dynamicNaming: 'Denominazione Dinamica',
  ecommerce: 'eCommerce',
  ecommerceEmailFormatCreated: 'Formato E-mail E-commerce creato',
  ecommerceEmailFormatSaved: 'Formato E-mail E-commerce salvato',
  ecommerceLogo: 'Logo e-commerce',
  ecommerceUseExtOrder: 'Numero di ordine esterno per uso in e-commerce',
  edit: 'Modifica',
  editItem: 'Modifica {item}',
  email: 'E-mail',
  emailFormat: 'Formato E-mail',
  emailFormatTemplate: 'Modello di Formato E-mail | Modelli di Formato E-mail',
  emailList: 'Elenco E-mail',
  emailMessage: 'È richiesta un’e-mail',
  emailRecipient: 'Destinatario E-mail | Destinatari E-mail',
  emailRecipientMessage: 'Questi indirizzi riceveranno un’e-mail quando lo stato dell’ordine cambia in',
  emptyMessage: {
    orderSuggestions: 'Sembra che non ci siano ancora dei Suggerimenti di Ordini per questa parte.'
  },
  emptyBomState: 'Nessun Elemento BOM definito per pagina. Fai clic su +{add} per iniziare',
  enable: 'Attiva',
  enableAll: 'Attiva tutto',
  enableAPIKeyAdmin: 'Attiva Amministratore Chiave API',
  enabled: 'Attivato',
  enableCommerce: 'Attiva eCommerce',
  enableEcommerceOrderManagement: 'Attiva Gestione ordini e-commerce',
  enableExporter: 'Attiva Esportatore',
  enableExportToLinkOne: 'Attiva Esportazione a LinkOne',
  enableExportToSmartEquip: 'Attiva Esportazione a SmartEquip',
  enableLibraryBetaFeatures: 'Abilita le funzioni beta della libreria',
  enablePerformanceLogging: 'Attiva il log delle prestazioni',
  enablePLZToDraft: 'Attiva Elaborazione PLZ in bozza',
  enableRocketUi: 'Attiva Esperienza Utente Rocket',
  enableWidgetLogo: 'Abilita il logo widget Documoto',
  enableWidgets: 'Abilita widget',
  encryptedKey: 'Chiave Crittografica',
  endDate: 'Data Finale',
  endDateBad: 'La data di inizio deve essere uguale o precedente alla data di fine.',
  enforceNumericSize: 'Rinforzare la Dimensione Numerica',
  englishDescription: 'Descrizione in Inglese',
  englishName: 'Nome in Inglese',
  enterprise: 'Società',
  enterReportParameters: 'Inserire i Parametri del Report',
  enterUniqueNameForNewItem: 'Inserire un nome unico per il nuovo {item}',
  enterValidNumber: 'Inserire un numero valido',
  enterValidEmail: 'Inserire un’e-mail valida',
  entityType: 'Tipo di Entità | Tipi di Entità',
  erp: 'ERP',
  erpEndpointConfig: 'Configurazione ERP-Endpoint',
  erpPartInfo: 'Minuti di durata della Cache per informazione su componente ERP',
  erpSystem: 'Sistema ERP',
  erpSystems: 'Sistemi ERP',
  erpSystemSaved: 'Sistema ERP salvato',
  error: 'Errore',
  errorDeletePageMessage: 'Si è verificato un errore durante l’eliminazione della pagina.',
  errorInputParsing: 'Problema nell’analisi dei file di input.',
  errorPageMessage: 'Si è verificato un errore.',
  errorPageTitle: 'C’è qualcosa di sbagliato...',
  errorType: {
    error: 'Errore',
    warning: 'Avviso'
  },
  essential: 'Essenziale | Essenziali',
  eta: 'ETA',
  etlJob: 'ETL',
  exact: 'Esatto',
  excelFile: 'File Excel',
  expandAll: 'Espandi Tutto',
  export: 'Esporta',
  exportSearch: 'Esporta Ricerca',
  exportAsCsv: 'Esporta come CSV (Formato Richiesto per l’Importazione)',
  exportAsCSVDropdown: 'Esporta come CSV',
  exportAsHtml: 'Esporta come HTML',
  exportAsPdf: 'Esporta come PDF',
  exportAsPlz: 'Esporta come PLZ',
  exportAsXlsx: 'Esporta come XLSX',
  exportBasicPartInfo: 'Esporta Informazioni di Base delle Parti',
  exportCartToXlsxDisabled: 'Disabilita Esportazione del Carrello in XLSX',
  exportCartToXlsxEnabled: 'Attiva Esportazione del Carrello in XLSX',
  exportDisplayedCartColumns: 'Esporta Colonne Carrello Visualizzate',
  exportJob: 'Lavoro di Esportazione',
  exportLane: 'Esporta lane',
  exportMessage: 'Lavoro di esportazione inviato correttamente.',
  exporter: 'Esportazione',
  exporterMessage: 'Consente di esportare il contenuto',
  exportParts: 'Esporta Parti',
  external: 'Esterno | Esterni',
  externalUrl: 'URL esterno',
  faceted: 'Faceted',
  facetedTag: 'I valori dei filtri devono essere sfaccettati',
  facetedTagTooMany: 'Il tag supera il valore limite1 unico di 1.000 tag',
  failedAction: '{action} {content} non riuscito.',
  failedProcessContentSql: 'Elaborazione Contenuti non riuscita a causa di un testo non autorizzato. Caricare nuovamente il contenuto supportato.',
  failedToCopy: 'Copia di {content} non riuscita.',
  failedToDelete: 'Cancellazione {content} non riuscita.',
  failedToLoad: 'Caricamento {content} non riuscito.',
  failedToSave: 'Salvataggio {content} non riuscito.',
  failedToSubmitItem: 'Impossibile inviare {item}',
  failedToUpdate: 'Aggiornamento di {content} non riuscito.',
  false: 'Falso',
  faq: 'FAQ',
  favorites: 'Preferiti',
  favoritesLists: 'Elenco di Libri Preferiti',
  february: 'Febbraio',
  feedbackForm: 'Invia Feedback',
  feedbackFormMessage: 'Fornisci input sulle caratteristiche che vorresti visualizzare',
  fieldDefinition: 'Definizione Campo',
  field: 'Campo | Campi',
  fieldName: 'Nome campo',
  file: 'File | File',
  filename: 'Nome del file',
  fill: 'Riempimento',
  filter: 'Filtro | Filtri',
  filterOrgNameOnly: 'Filtra: Solo Nome Org.',
  filterOrgParentNames: 'Filtra: Nomi Genitore e Org.',
  filterable: 'Filtrabile',
  filterableTag: 'Tag Filtrabile',
  filterBy: 'Filtra per',
  filterBoundsError: 'I filtri devono contenere almeno 3 caratteri',
  finnishDescription: 'Descrizione in Finlandese',
  finnishName: 'Nome in Finlandese',
  firstName: 'Nome',
  firstNameEmpty: 'Il campo nome non può essere vuoto',
  fixedHotpointSize: 'Dimensione Hotpoint Fissa',
  font: 'Font | Font',
  footer: 'Piè di pagina',
  forgotPassword: 'Password Dimenticata',
  forgotPasswordMessage: 'Un’e-mail con le istruzioni per reimpostare la password verrà inviata al nome utente fornito.',
  forgotPasswordSuccess: 'Se il nome utente fornito risulta valido, verrà inviata un’e-mail con le istruzioni per reimpostare la password all’indirizzo in archivio.',
  form: 'Modulo| Moduli',
  format: 'Formato',
  formattingOptions: 'Opzioni di Formattazione',
  formCount: 'Conteggio Moduli',
  formName: 'Nome Modulo',
  formType: 'Tipo Modulo',
  freezeImage: 'Congela Immagine',
  frenchDescription: 'Descrizione in Francese',
  frenchName: 'Nome in Francese',
  fri: 'Ven',
  from: 'Da',
  fromDate: 'Dalla Data',
  frontCover: 'Copertina Anteriore',
  frontStitch: 'Cucitura Anteriore',
  fullScreen: 'Schermo intero',
  gdprFaqs: 'Domande frequenti sul GDPR',
  gdprMessage: 'Accedendo alla presente sezione del nostro sito web, si accetta l‘utilizzo dei cookie essenziali. Questi cookie sono essenziali per poter navigare all‘interno dei nostri Servizi. Senza i cookie, non possiamo fornire i servizi richiesti. Per maggiori informazioni, si veda la nostra {policy}.',
  general: 'Generale',
  generalSearch: 'Ricerca Generale',
  generic: 'Generico',
  germanDescription: 'Descrizione in Tedesco',
  germanName: 'Nome in Tedesco',
  goToHome: 'Vai a Home',
  goToJobManager: 'Vai al Gestore del Lavoro',
  goToSearch: 'Vai a Cerca',
  hashKey: 'Tasto Cancelletto',
  headerAmpFooter: 'Intestazione e Piè di pagina',
  header: 'Intestazione',
  help: 'Aiuto',
  helpCenter: 'Centro Assistenza',
  highlightDescription: 'Sottolineare le parti con questo tag nell’elenco parti',
  history: 'Cronologia',
  home: 'Home',
  horizontal: 'Orizzontale',
  host: 'Host',
  hotpoint: 'Hotpoint',
  hotpointCalloutBehavior: 'Comportamento callout Hotpoint',
  hotpointLinksWillBeCreated: 'I link hotpoint verranno creati in {count} {content}',
  hotpointLink: 'Collegamento Hotpoint | Collegamenti Hotpoint',
  hotpointsGenerated: '{count} punti attivi trovati nell‘immagine.',
  hotpointShift: 'Hotpoint (Shift H)',
  hourlyDWSRequestLimit: 'Limite della richiesta DWS per ora',
  hourlyRESTRequestLimit: 'Limite della richiesta REST per ora',
  htmlUiUserSwitchEnabled: 'Switch HTML UI User attivato',
  hungarianDescription: 'Descrizione in Ungherese',
  hungarianName: 'Nome in Ungherese',
  icon: 'Icona',
  id: 'ID',
  identifier: 'Identificatore',
  ignore: 'IGNORARE',
  ignoreAccessControls: 'Ignora i Controlli degli Accessi',
  ignoreMatchingTranslations: 'Ignora le Traduzioni con abbinamenti di lingue su Import',
  illustration: 'Illustrazione | Illustrazioni',
  image: 'Immagine | Immagini',
  imageDeleteMessage: 'Sei sicuro di voler eliminare questa Immagine di Pagina?',
  imageUploadFailure: 'Caricamento Immagine Non Riuscito',
  imageSaveFail: 'Salvataggio Immagine Non Riuscito',
  imageOptimizer: 'Ottimizzatore di Immagini',
  imageSizeExceededTitle: 'Dimensione Massima Immagine Superata',
  imageSizeExceededMessage: 'La dimensione massima consentita del file immagine in Documoto è di {size} megabyte. La dimensione dell’immagine che stai tentando di caricare supera il limite massimo consentito e non può essere caricata. Ridimensionare l’immagine da aggiungere a questa pagina.',
  imageDimensionsExceededMessage: 'La dimensione massima dell’immagine in Documoto è di 65,5 megapixel (8.191 X 8.191 pixel).  La dimensione dell’immagine che stai tentando di caricare supera il limite massimo consentito e non può essere caricata. Fai clic su “Ridimensiona” per consentire a Documoto di ridimensionare e inserire l’immagine, oppure su “Annulla” per modificare l’immagine e inserirla manualmente',
  import: 'Importa',
  importBOM: 'Importa distinta base',
  importBOMError: 'Elaborazione dei dati campione non riuscita; ricontrollare la sorgente di ingresso',
  importParts: 'Importa Parti',
  inCart: 'Nel Carrello',
  inCartDescription: 'Visualizza i tag nel carrello',
  includePagePartDetails: 'Includi i dettagli a livello di Articolo della Pagina',
  includePartDetails: 'Includi i dettagli a livello di Articolo',
  indexQueueJob: 'Indicizza coda',
  inPartsList: 'Nell’Elenco delle Parti',
  inPartsListDescription: 'Visualizza i tag nell’elenco parti',
  inputSource: 'Sorgente di Ingresso',
  inch: 'Pollice | Pollici',
  includeComments: 'Includi Commenti',
  includeCommentsAuthorAndDate: 'Includi Autore e Data Commenti',
  includeHeaderRow: 'Includi Riga di Intestazione',
  includeImages: 'Includi Immagini',
  includePagesIndex: 'Includi Indice Pagine',
  includePartsIndex: 'Includi Indice Parti',
  includePartsList: 'Includi Elenco delle Parti',
  includeSubChapters: 'Includi i Sottocapitoli',
  indexLane: 'Indicizza lane',
  useSequentialPageNumbering: 'Usa Numerazione Pagine Sequenziale',
  hideBookInSearchAndRecent: 'Nascondi Libri in Risultati della Ricerca e in Elementi Aggiunti Di Recente',
  hideDuplicatePages: 'Nascondi Pagine Duplicate',
  hidePageInSearchAndRecent: 'Nascondi Pagine in Risultati della Ricerca e in Elementi Aggiunti Di Recente',
  hidePartInSearchAndRecent: 'Nascondi Parti in Risultati della Ricerca e in Elementi Aggiunti Di Recente',
  hotpointZoomBehavior: 'Comportamento zoom Hotpoint',
  includeNewParts: 'Includi solo “nuovi” articoli (non presenti in Documoto)',
  includeWatermark: 'Includere watermark?',
  increaseHotpoint: 'Aumenta Hotpoint',
  indexed: 'Indicizzato',
  indexingConfiguration: 'Configurazione dell’indicizzazione',
  indexIncludeTimestamp: 'Timestamp dell’inclusione dell’indice',
  info: 'Info',
  information: 'Informazioni',
  inherit: 'Eredita',
  inheritedParent: 'Ereditato dall’Elemento Genitore.',
  inputSpecification: 'Specifiche di Ingresso',
  inputType: 'Tipo di Input',
  inputTypeListboxWarning: 'Le configurazioni di ordinamento verranno visualizzate dopo il salvataggio.',
  integration: 'Integrazione | Integrazioni',
  integrationsAddPartToErpCart: '{action} Aggiungi Parte al Carrello ERP',
  integrationsAddToShoppingCartPricingFromErp: '{action} Aggiungi ai Prezzi del Carrello da ERP',
  integrationsDisableErp: 'Disabilita ERP',
  integrationsDoErpForQuotes: 'Esegui ERP per Preventivi',
  integrationsEnableErp: 'Attiva ERP',
  integrationsEnableFetchPartInfoFromErp: 'Attiva Recupero delle Info sulla Parte da ERP',
  integrationsEnableScopeErp: 'Attiva ERP {scope}',
  integrationsEnableSendShoppingCartToErp: 'Attiva l’Invio del Carrello a ERP',
  integrationsEnableSyncPoAddressWithErp: 'Attiva Sync. Indirizzo PO con ERP',
  integrationsErpAvailabilityMappedFrom: 'Disponibilità ERP Mappato Da',
  integrationsErpCurrencyCodeMappedFrom: 'Codice Valuta ERP Mappato Da',
  integrationsErpDiscountedPriceMappedFrom: 'Prezzo Scontato ERP Mappato Da',
  integrationsErpEtaMappedFrom: 'ETA ERP Mappato Da',
  integrationsErpMappings: 'Mappature ERP',
  integrationsErpRetailPriceMappedFrom: 'Prezzo al Dettaglio ERP Mappato Da',
  integrationsErpWholesalePriceMappedFrom: 'Prezzo All’ingrosso ERP Mappato Da',
  integrationsInvokeGetPartInfoFromClient: 'Richiamare GetPartInfo dal Cliente',
  integrationsParseAddressForType: 'Analizzare Indirizzo per Tipo',
  integrationsShoppingCartAndPartsTitle: 'Carrello e Parti',
  integrationsShoppingCartAvailability: '{action} Disponibilità del Carrello',
  integrationsShoppingCartEtaFromErp: '{action} Carrello ETA da ERP',
  internalDescription: 'Descrizione Interna',
  internalName: 'Nome Interno',
  internalNameOptional: 'Il tuo identificatore per il tag',
  internalNameRequired: 'È richiesto un nome',
  invalidDecimals: 'Sono consentite solo due cifre decimali',
  invalidField: '{field} non è valido',
  invalidFileType: 'Tipo di file non supportato. Fare riferimento al Supporto Tecnico Documoto per una lista completa delle estensioni supportate.',
  isSearchable: 'È Consultabile',
  insertDataField: 'Inserisci Campo Dati',
  isSystem: 'È un Sistema',
  italianDescription: 'Descrizione in Italiano',
  italianName: 'Nome in Italiano',
  item: '#',
  itemAlt: 'Item',
  itemCopied: '{item} copiato',
  itemDeleted: '{item} cancellato',
  itemInProgress: '{item} in corso',
  itemInformation: 'Informazioni {item}',
  itemMustBeLteItem: '{itemA} deve essere uguale o precedente a {itemB}.',
  itemMustBeUnique: '{item} deve essere unico',
  itemMustBeUniqueAndNotEmpty: '{item} deve essere unico ed è obbligatorio',
  itemName: 'Nome {item}',
  itemNotShown: '',
  itemSaved: '{item} salvato',
  itemSpecification: 'Specifiche dell’articolo',
  showChapterTOCForHiddenPages: 'Mostra TOC Capitolo per le Pagine Nascoste',
  january: 'Gennaio',
  japaneseDescription: 'Descrizione in Giapponese',
  japaneseName: 'Nome in Giapponese',
  job: 'Lavoro | Lavori',
  jobManager: 'Gestore del Lavoro',
  jobManagerMessage: 'Esporta, pubblica e stampa/scarica lavori',
  july: 'Luglio',
  june: 'Giugno',
  koreanDescription: 'Descrizione in Coreano',
  koreanName: 'Nome in Coreano',
  label: 'Etichetta | Etichette',
  labelKey: 'Tasto Etichette',
  landscape: 'Orizzontale',
  laneConfiguration: 'Configurazione del lane',
  language: 'Lingua',
  lastModifiedDate: 'Data Ultima Modifica',
  lastGenerated: 'Ultima Chiave Generata',
  lastName: 'Cognome',
  lastNameEmpty: 'Il campo cognome non può essere vuoto',
  lastUpdatedOn: 'Data dell’ultimo aggiornamento',
  layout: 'Layout',
  layoutOptions: 'Opzioni Layout',
  ledger: 'Registro contabile',
  left: 'Sinistra',
  legal: 'Legale',
  letter: 'Lettera',
  library: 'Libreria',
  libraryAdmin: 'Gestione della Libreria',
  libraryBannerLogo: 'Logo Banner della Libreria',
  librarySettings: 'Impostazioni della Libreria',
  librarySettingsDescription: 'Queste impostazioni controllano i vari elementi e le opzioni della Libreria. Prestare attenzione durante le modifiche. Contattare i Servizi Documoto per ulteriori informazioni.',
  licenseConfiguration: 'Configurazione della licenza',
  line: 'Linea',
  lineTool: 'Strumento Riga',
  link: 'Link | Link',
  listBox: 'Casella di Riepilogo',
  listBoxValue: 'Valore Casella Elenco | Valori Casella Elenco',
  listValue: 'Valore Elenco | Valori Elenco',
  load: 'Carica',
  loadMore: 'Carica altri',
  loading: 'Caricamento in corso',
  locale: 'Impostazioni locali',
  login: 'Accesso',
  loginBackground: 'Accesso Background',
  loginGDPRMessageBeginning: 'Questo sito utilizza i cookie. Continuando ad utilizzare questo sito web si dà il consenso all‘utilizzo dei cookie. Per maggiori informazioni, si veda la nostra ',
  loginGDPRMessageEnd: 'e le nostre',
  loginSSO: 'Accesso SSO',
  logiReportName: 'Nome Report Logi',
  logiReportToggle: 'Report Logi?',
  logo: 'Logo | Loghi',
  logout: 'Chiusura sessione',
  lookupLoadParts: 'Ricerca/Carica Parti da Documoto su Importa',
  lowerBound: 'Limite Inferiore',
  makePagesDivisibleBy: 'Rendi le Pagine Divisibili Per:  ',
  mainInfo: 'Informazioni Principali',
  mainLogo: 'Logo Principale',
  manageItem: 'Gestisci {item}',
  management: 'Gestione',
  march: 'Marzo',
  margin: 'Margine | Margini',
  markdown: 'Ribasso',
  may: 'Maggio',
  media: 'Media | Media',
  mediaCategory: 'Categoria Media | Categorie Media',
  mediaCategoryEditor: 'Editor Categoria Media',
  mediaCategoryName: 'Nome della Categoria Media',
  mediaCategoryNameMissing: 'Tutte le categorie media richiedono un nome',
  mediaBookDeleteMessage: 'Cancellando questo medium si rimuove l’accesso a questo contenuto sia nella Libreria che nell’Amministrazione del Contenuto e non sarà più disponibile per gli utenti o gli Amministratori dei Contenuti. Qualsiasi parte o pagina trovata in questo libro, che non si trova in altri libri, non sarà più disponibile nella Libreria.',
  mediaDeleteMessage: 'Cancellando questo modello lo si rimuoverà dall’Amministrazione del Contenuto e non sarà più disponibile per la copia su nuovi Media.',
  mediaDeleteSuccessText: 'Verrà rimosso dagli elenchi una volta completata l’indicizzazione.',
  mediaDescription: 'Descrizione media',
  mediaIdentifier: 'Identificatore media',
  mediaName: 'Nome media',
  mediaPrintSettingsSaveSuccess: 'Impostazioni Stampa dei Media salvati',
  mediaWhereUsed: 'Media Dove Sono Usati',
  menu: 'Menu',
  merge: 'Unisci',
  mergeOrOverrideMessage: 'Si desidera sovrascrivere o unire questi articoli?',
  mergeWarning: 'Dopo l’importazione dei dati verranno aggiornate le parti esistenti e aggiunte le nuove voci',
  message: 'Messaggio',
  messageLevel: 'Livello di Messaggio',
  microsite: 'Microsito | Micrositi',
  micrositeAdmin: {
    cardSubtitle: 'File di contenuti compresso e informazioni di inserimento HTML',
    cardTitle: 'Contenuto del sito',
    errorRequiredFile: 'È necessario un file ZIP con i contenuti del sito',
    fieldFile: 'File ZIP con i contenuti del sito',
    fieldPoint: 'Punto di ingresso HTML',
  },
  minArea: 'Area Minima',
  minAreaMessage: 'È richiesto un valore minimo. Il valore non può superare un intervallo compreso tra 1 e 1.500.  Deve essere un valore intero.',
  multipleAlternatingPages: 'Pagine Alternate Multiple',
  multipleAlternatingPagesMessage: 'Immagini sulle pagine 1 e 3, elenco parti sulle pagine 2 e 4.',
  mToM: 'M2M',
  multiplePages: 'Pagine Multiple',
  multiplePagesMessage: 'Immagine sulla propria pagina seguita dalle pagine con l’elenco delle parti',
  requiredField: 'Campo Richiesto',
  messageMessage: 'È necessario un messaggio',
  metadata: 'Metadati',
  mine: 'Mio',
  miscellaneous: 'Miscellanea',
  mon: 'Lun',
  myCart: 'Il Mio Carrello | I Miei Carrelli',
  navigation: 'Navigazione',
  nItemsCreated: '{n} {items} Creati',
  nItemsDeleted: '{n} {items} Cancellati',
  nOfMItems: '{n} di {m} {items}',
  name: 'Nome',
  nameDescriptionHelpText: 'Nome e descrizione opzionale',
  new: 'Nuovo',
  newAPIKey: 'Nuova Chiave API',
  newERPSystem: 'Nuovo sistema ERP',
  newItem: 'Nuovo {item}',
  newKeyGenerated: 'Nuova Chiave di Integrazione Generata, Copiare e Conservare per l’uso futuro',
  newMediaCategory: 'Nuova Categoria Media',
  newPassword: 'Nuova Password',
  newsItem: 'Elemento Notizie | Elementi Notizie',
  newTenant: 'Nuovo titolare',
  newWidgetKey: 'Nuova chiave widget',
  newWidgetKeyGenerated: 'Nuova chiave widget generata, copiare e conservare per l’uso futuro',
  noTenantSuppliers: 'Nessun fornitore assegnato al titolare.',
  noAPIKeys: 'Nessuna Chiave API definita.',
  node: 'Nodo',
  noData: 'Nessun Dato',
  noFilesSelected: 'Nessun File Selezionato',
  noIllustrationFound: 'Nessuna Illustrazione Trovata',
  noImageFound: 'Nessuna Immagine Trovata',
  noItemAvailable: 'Nessun {item} disponibile',
  noItemFound: 'Nessun {item} trovato.',
  noItemSelected: 'Nessun {item} selezionato',
  noItemAddedToTarget: 'Nessun {item} aggiunto al {target}. ',
  noItemsFound: 'Nessun Elemento Trovato',
  noMoreResults: 'Nessun altro risultato',
  noFormsMessage: 'Nessun Modulo definito.',
  noMediaDefined: 'Nessun media definito',
  nonSearchableFieldsSuccessfullyUpdated: 'Campi non ricercabili aggiornati correttamente.',
  noPartsSelected: 'Nessuna parte selezionata.',
  noPrice: 'Richiedi il Prezzo',
  noResults: 'Nessun risultato',
  norwegianDescription: 'Descrizione in Norvegese',
  norwegianName: 'Nome in Norvegese',
  noSearchableTags: 'Nessun tag consultabile',
  notOrderable: 'Non Ordinabile',
  notSelected: 'Non selezionato',
  noTagsFound: 'Nessuna etichetta trovata',
  noUnsavedChanges: 'Nessuna Modifica Non Salvata',
  none: 'Nessuno',
  notImplemented: 'Non Implementato',
  notification: 'Notifica | Notifiche',
  notificationsMessage: 'Annunci importanti e novità',
  notMapped: 'Non Mappato',
  november: 'Novembre',
  noWidgetKeys: 'Nessuna chiave widget definita.',
  numeric: 'Numerico',
  numberOf: '# di {item}',
  october: 'Ottobre',
  of: 'di',
  off: 'Disattivo',
  offlineContent: 'Contenuto Offline',
  ok: 'Ok',
  oldPassword: 'Vecchia password',
  on: 'Attivato',
  opacity: 'Opacità',
  open: 'Aperto',
  openItem: '{item} aperto',
  optimizeIndexJob: 'Ottimizza Indice',
  options: 'Opzioni',
  openTab: 'Apri il link in una nuova scheda',
  order: 'Ordine | Ordini',
  orderable: 'Ordinabile',
  orderableDescription: 'La parte può essere aggiunta al carrello e acquistata',
  orderableLocal: 'Ordinabile su questa pagina',
  orderableLocalBlocked: 'Parte non Ordinabile Globalmente',
  orderDisable: 'Disabilita Cronologia Ordini',
  orderEmailRecipient: 'Destinatario E-mail d’Ordine | Destinatari E-mail d’Ordine',
  orderEntity: 'Entità Ordine | Entità Ordini',
  orderFieldUneditableRequired: 'Impostare un campo Ordine/Preventivo come necessario e non modificabile dall’utente può rendere impossibile il completamento del tuo modulo Ordine/Preventivo. Verificare i moduli Ordine/Preventivo che possono essere completati.',
  orderFieldsEmptyMessage: 'Nessun campo Ordine/Preventivo definito.',
  orderFieldsEmptyPrompt: 'Aggiungi un campo Ordine/Preventivo',
  orderField: {
    nameMessage: 'Se cambi il nome del campo Ordine/Preventivo, assicurati di aggiornare tutti i moduli Ordine/Preventivo o i formati e-mail che utilizzano questo campo.',
  },
  orderFieldCopyMessage: 'Inserire un nome univoco per il nuovo campo Ordine/Preventivo',
  orderForm: {
    uniqueNameInputHint: 'Inserire un nome unico per il nuovo Modulo.'
  },
  orderFormField: 'Campo Ordine/Preventivo | Campi Ordine/Preventivo',
  orderFormLayout: 'Layout modulo Ordine/Preventivo | Layout modulo Ordine/Preventivo',
  orderFormSetDefault: 'Modulo Ordine/Preventivo Predefinito',
  orderFormType: 'Modulo d’Ordine',
  orderLayout: 'Ordine Layout | Ordini Layout',
  orderManagementApprover: 'Approvatore per la Gestione dell’Ordine',
  orderManagementDisable: 'Disabilita Amministrazione Gestione del Lavoro',
  orderManagementViewer: 'Visualizzatore Gestione dell’Ordine',
  orderNumber: 'Numero di Ordine',
  orderQuoteEmail: 'E-mail Ordine/Preventivo | Email Ordini/Preventivi',
  orderQuoteEmailFormat: 'Formato E-mail Ordine/Preventivo | Formati E-mail Ordini/Preventivi',
  orderQuoteEmailFormatCopyMessage: 'Seleziona una lingua per il nuovo Formato E-mail Ordine/Preventivo.',
  orderQuoteFetchError: 'Errore durante l’acquisizione dei dati del Modulo di Ordine/Preventivo',
  orderQuoteForm: 'Modulo d‘ordine/preventivo | Moduli d‘ordine/preventivi',
  orderQuoteSaveConfirmation: 'Salvare le modifiche apportate ai moduli di Ordine/Preventivo dell’Organizzazione?',
  orderQuoteSaveError: 'Errore durante il salvataggio dei dati del Modulo di Ordine/Preventivo',
  orderQuoteFormSaved: 'Modulo Ordine/Preventivo salvato',
  orderStatus: 'Stato Ordine/Preventivo | Stati Ordine/Preventivo',
  orderStatusDeleteMessage: 'Questo stato è stato usato per alcuni ordini e non può essere cancellato.',
  orderSubmittedMessage: 'Inviato Correttamente',
  orderSuggestion: 'Suggerimento di Ordine | Suggerimenti di Ordine',
  orderSuggestionEnable: 'Attiva Suggerimenti di Ordine',
  orderSuggestionRemovalWarning: 'La parte non verrà più mostrata come suggerimento d’ordine. | Le parti non verranno più mostrate come suggerimenti d’ordine.',
  orderSuggestionThreshold: 'Limite Suggerimenti di Ordine',
  ordersLogo: 'Logo Ordini',
  ordersMessage: 'Cronologia invio di ordini',
  orientation: 'Orientamento',
  org: 'Org.',
  orgEmailRecipients: 'Destinatari E-mail Org.',
  organization: 'Organizzazione | Organizzazioni',
  organizationAdmin: 'Amministratore dell’Organizzazione',
  organizationAdminBrowseFlow: 'Un’Organizzazione può usare il flusso di navigazione predefinito, in alternativa è possibile sovrascrivere e utilizzare un altro flusso di navigazione.',
  organizationAdminMessage: 'Controlli della gestione utente e impostazioni org',
  organizationEmpty: 'Deve essere selezionata l’Organizzazione',
  organizationEmailRecipients: 'Destinatari E-mail Organizzazione',
  organizationEnforceAccessControlsForQuickAdd: 'Applica i controlli per l’accesso per un’aggiunta rapida',
  organizationKey: 'Tasto Organizzazione',
  organizationLibrarySettingsDescription: 'Queste impostazioni controllano i vari elementi e le opzioni della Libreria a livello dell’Organizzazione. Prestare attenzione durante le modifiche. Contattare i Servizi Documoto per ulteriori informazioni.',
  organizationMoveToRoot: 'Trascina qui per spostare la radice',
  organizationName: 'Nome organizzazione',
  organizationRearrange: 'Riordinare l’Organizzazione',
  organizationRearrangeDescription: 'La struttura dell’Organizzazione genitore-figlio definisce molti attributi ereditabili di un`Organizzazione come le Impostazioni di Stampa, gli Stili e altre. Trascina le Organizzazioni per creare la gerarchia desiderata.',
  organizationReportSettingsSuccessMessage: 'Impostazioni report salvate',
  organizationSelect: 'Seleziona Organizzazione',
  organizationTags: 'Tag di Organizzazione',
  organizationsEmpty: 'Sembra che tu non abbia ancora creato nessuna organizzazione',
  orgNameOnly: 'Solo Nome Org.',
  other: 'Altro',
  otherSettings: 'Altre Impostazioni',
  outboundCustomerIdParameter: 'Nome Parametro ID del Cliente in Uscita',
  outboundSessionIdParameter: 'Nome Parametro ID della Sessione in Uscita',
  outlineMode: 'Modalità Contorno (Shift O)',
  output: 'Output',
  outputCatalog: 'Catalogo Output',
  outputDefinition: 'Definizione Output | Definizioni Output',
  outputDefinitionDeleted: 'Definizione Output cancellata',
  outputDefinitionSaved: 'Definizione Output salvata',
  outputFileName: 'Nome File Output',
  outputName: 'Nome Output',
  outputDestination: 'Destinazione di Output',
  outputSpecification: 'Specifiche di Output',
  outputSubType: 'Sottotipo Output',
  outputType: 'Tipo Output',
  overridden: 'Sovrascritto',
  overriddenByOrg: 'Sovrascritto dall’Org.',
  override: 'Sovrascrivi',
  overrideBrowseFlow: 'Sovrascrivi Flusso di Navigazione',
  overrideFacetLimitValue: 'Sovrascrivere il valore limite del facet',
  overrideHotpointScale: 'Sovrascrivi Scala Hotpoint',
  overridePdfNamingStandard: 'Sovrascrivi standard di denominazione file PDF',
  overridePrintSettings: 'Sovrascrivi Impostazioni di Stampa',
  overrideFontSettings: 'Sovrascrivi Impostazioni Font',
  overrideOrderForm: 'Sovrascrivi Modulo d’Ordine',
  overrideQuoteForm: 'Sovrascrivi Modulo di Preventivo',
  overrideSvgOpacity: 'Sovrascrivi Opacità SVG',
  overrideWarning: 'Con l’importazione dei dati verrà sostituita la distinta base corrente',
  owner: 'Proprietario | Proprietari',
  page: 'Pagina | Pagine',
  pageBulkInsertConfirmTitle: 'Vuoi completare l’inserimento del blocco?',
  pageBulkInsertConfirmInsertAfter: 'Questo inserirà {replacement} dopo {original} in {whereUsedBookCount} {bookUnits} e {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertBefore: 'Questo inserirà {replacement} prima {original} in {whereUsedBookCount} {bookUnits} e {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertConfirmInsertReplace: 'Questo inserirà {replacement} e rimuoverà {original} da {whereUsedBookCount} {bookUnits} e {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertBooksConfirmInsertAfter: 'Questo inserirà {replacement} dopo {original} in {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertBefore: 'Questo inserirà {replacement} prima {original} in {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertBooksConfirmInsertReplace: 'Questo inserirà {replacement} e rimuoverà {original} da   {whereUsedBookCount} {bookUnits}.',
  pageBulkInsertTemplatesConfirmInsertAfter: 'Questo inserirà {replacement} dopo {original} in {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertBefore: 'Questo inserirà {replacement} prima {original} in {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertTemplatesConfirmInsertReplace: 'Questo inserirà {replacement} e rimuoverà {original} da   {whereUsedTemplateCount} {templateUnits}.',
  pageBulkInsertDiscard: 'L’inserimento del blocco non verrà processato.',
  pageBulkInsertHelpText: 'Questa funzione permette di inserire la pagina source {thisDisplayName} nelle parti del libro selezionate in cui esiste già un’altra pagina {originalDisplayName}. Facoltativamente, puoi anche rimuovere {originalDisplayName} da quei libri.',
  pageBulkInsertInsertAfter: 'Inserire {replacement} DOPO {original}',
  pageBulkInsertInsertBefore: 'Inserire {replacement} PRIMA di {original}',
  pageBulkInsertInsertReplace: 'Inserire {replacement} e RIMUOVERE {original}',
  pageBulkInsertScopeAll: 'Tutti i {type} che hanno la pagina target {originalDisplayName} ({whereUsedCount} {units})',
  pageBulkInsertScopeNone: 'Nessuno dei  {type} che ha la pagina target  {originalDisplayName}',
  pageBulkInsertScopeSome: 'Alcuni dei {type} che hanno la pagina target  {originalDisplayName}',
  pageBulkInsertStep1: 'Fase 1: Trovare una pagina target desiderata',
  pageBulkInsertStep2: 'Fase 2: Decidere come inserire o sostituire le pagine',
  pageBulkInsertStep3: 'Fase 3: Scegliere i libri su cui applicare l’inserimento del blocco.',
  pageBulkInsertStep4: 'Fase 4: Scegliere i modelli su cui applicare l’inserimento del blocco.',
  pageBulkInsertSuccessTitle: 'Lavoro inserimento del  Blocco inviato',
  pageBulkInsertSuccessBody: 'Le modifiche all’indice del libro verranno effettuate immediatamente. I risultati della ricerca verranno visualizzati una volta completata l’indicizzazione.',
  pageBulkErrorTooManyBooks: 'Limitare il numero di libri selezionati a non più di 1.000',
  pageBuilder: 'Generatore di Pagina',
  pageComposer: 'Page Composer',
  pageCreated: 'Pagina Creata',
  pageDeleteMessage: 'Le eliminazioni delle parti avranno effetto immediato nella Libreria e in Ammin. contenuti. La parte eliminata resterà ricercabile fino alla reindicizzazione entro pochi minuti.',
  pageDeleteMessage2: 'Questa azione non può essere annullata senza l’assistenza Documoto. Dopo 90 giorni il record eliminato e tutte le associazioni corrispondenti saranno cancellati in modo permanente.',
  pageDescription: 'Descrizione pagina',
  pageFile: 'File Pagina',
  pageFilename: 'Nome file pagina',
  pageFilenameDeleted: '{filename} sarà eliminato.',
  pageBomPartsDeleted: '{count} parti nella BOM della pagina non saranno accessibili tramite {filename}.',
  pageDraftsDeleted: 'Le pagine bozza associate a questa pagina saranno eliminate in modo permanente.',
  pageFit: 'Adattamento Pagina',
  pageHeader: 'Intestazione Pagina',
  pageLabel: 'Pagina | Pagine',
  pageLayout: 'Layout Pagina',
  pageName: 'Nome pagina',
  pagePart: 'Parte Pagina | Parti Pagina',
  pagePartTag: 'Tag Parte di Pagina | Tag Parte di Pagina',
  pagePartData: 'Dati Pagina',
  pageUsageDataCouldNotBeLoaded: 'I dati di utilizzo della pagina non possono essere caricati',
  pagePartLevelDetails: 'Pagina Articolo Ordinabile/Disponibile, Pagina Etichette Articolo',
  pagePartNumberVisible: 'Numero Parte di Pagina Visibile',
  pagePartOrderable: 'Parte di Pagina Ordinabile',
  pagePartOrderableDescription: 'Parte Ordinabile da questa pagina',
  pagePartVisibleDescription: 'Numero di Parte visibile in questa pagina',
  pageUpdated: 'Pagina Aggiornata',
  pageViewer: 'Visualizzatore di Pagina',
  pageWidth: 'Larghezza Pagina',
  pagesWhereUsed: 'Pagine Dove Si Usano',
  parentName: 'Nome del Genitore',
  part: 'Parte | Parti',
  partCode: 'Codice della Parte | Codici della Parte',
  partCodeAddError: 'Impossibile aggiungere Codici Parti',
  partCodeErrorEmpty: 'Impossibile salvare: Il codice della parte non può essere vuoto',
  partCodeErrorInUse: 'Questo codice della parte è in uso da altre parti di Documoto e non può essere cancellato. Contattare l’Assistenza Documoto a support{\'@\'}documoto.com per ricevere supporto.',
  partCodeErrorNotFound: 'Questi codici della parte non possono essere cancellati perché non sono stati trovati. Assicurarsi che tutte le modifiche siano state salvate.',
  partCodesManager: 'Gestione dei Codici delle Parti',
  partCodesMessage: 'I codici parte possono essere utilizzati al posto dei numeri della parte e spesso vengono utilizzati per indicare quando gli articoli della DBA sono obsoleti, non disponibili o sono solo articoli di riferimento (es. \'PNNA\', \'-\', \'REF\').',
  partCreated: 'Parte Creata',
  partData: 'Dati',
  partUpdated: 'Articolo Aggiornato',
  partDebouncerEmailQueueJob: 'Coda di E-mail',
  partDescription: 'Descrizione della parte',
  partLabel: 'Parte | Parti',
  partLevelDetails: 'Prezzo Articolo, Ordinabile/Disponibile, Traduzioni Aggiuntive, Etichette Articolo',
  partName: 'Nome della Parte',
  partNumber: 'Numero della Parte',
  partNumberDescription: 'Il numero della parte interno che gli utenti finali vogliono cercare',
  partNumberVisible: 'Numero della Parte Visibile',
  partNumberVisibleDescription: 'Il numero della parte è visibile agli utenti finali',
  partNumberVisibleLocal: 'Visibile su questa pagina',
  partNumberVisibleLocalBlocked: 'Il Numero della Parte non è Visibile Globalmente',
  partOrderable: 'Parte Ordinabile',
  partRequired: 'È necessaria almeno una parte',
  partTagProperty: 'Proprietà Tag Parte | Proprietà Tag Parte',
  partTag: 'Tag Parte | Tag Parte',
  partThumbnailDescription: '',
  partialSupport: 'Supporto Parziale',
  partsListColumn: 'Colonna Elenco delle Parti | Colonne Elenco delle Parti',
  partsBook: 'Libro Parti',
  partsColumns: 'Colonne Parti',
  partsNotSelected: 'Immagine predefinita utilizzata per anteprima delle parti. Se non viene applicata un’anteprima personalizzata, Documoto visualizza un’anteprima standard predefinita',
  partsPage: 'Pagina Parti | Pagine Parti',
  partsPages: 'Pagine delle Parti',
  partsPageGroup: {
    excludePartCodesFromPartsLists: 'Escludi Codici Parti dagli Elenchi delle Parti',
    highlightAlternateRows: 'Evidenzia Righe Alternate',
    rotateImagesAutomatically: 'Ruota Immagini Automaticamente',
    printBorderAroundImages: 'Stampa Bordi Intorno alle Immagini',
    keepImagesOnLeft: 'Tieni le Immagini sulla Sinistra',
    keepBOMOnRight: 'Tieni il BOM sulla Destra',
    columnsUpdateErrorMsg: 'Si è verificato un errore durante il tentativo di aggiornamento delle Colonne Parti',
    columnsUpdateSuccessMsg: 'Colonne Parti aggiornate',
    columnsLoadErrorMsg: 'Caricamento delle parti non riuscito',
    printHotpointsOnImages: 'Stampa Hotpoint su Immagini',
  },
  partsList: 'Elenco Parti',
  partsListData: 'Dati Elenco delle Parti',
  partsListGridHeader: 'Intestazione Griglia Elenco Parti',
  password: 'Password',
  passwordExpiredHeader: 'Password scaduta',
  passwordExpiredInfo: 'La sua password è scaduta. La nuova password deve soddisfare i seguenti requisiti:',
  passwordCriteriaInfo: 'La nuova password deve soddisfare i seguenti requisiti:',
  passwordBlankMessage: 'La password non può essere vuota',
  passwordCannotBeSame: 'Non deve essere uguale alla password corrente',
  passwordCasingMassage: 'Deve contenere almeno un carattere minuscolo e uno maiuscolo',
  passwordLowerCaseMessage: 'La password deve contenere almeno un carattere minuscolo',
  passwordMinLengthMessage: 'Le password devono essere lunghe almeno 8 caratteri',
  passwordNotMatchingMessage: 'Le password non sono uguali',
  passwordSpecialCharactersMessage: 'La password deve contenere almeno un carattere speciale',
  passwordUpperCaseMessage: 'La password deve contenere almeno un carattere maiuscolo',
  passwordWillExpire1Day: 'La password di accesso scadrà fra meno di 1 giorno',
  passwordWillExpire5Days: 'La password di accesso scadrà fra meno di 5 giorni',
  passwordsMatch: 'Corrispondenza Password',
  permission: 'Permesso | Permessi',
  phoneNumber: 'Numero di Telefono',
  phoneNumberExt: 'Est.',
  phonePrimary: 'Numero di Telefono Primario',
  phoneSecondary: 'Numero di Telefono Secondario',
  pinFilter: 'Aggiungi il filtro alla pagina dei risultati della ricerca',
  pinned: 'Bloccato',
  plusMoreOrgs: '+ {totalOrgCount} Organizzazioni aggiuntive',
  polishDescription: 'Descrizione in Polacco',
  polishName: 'Nome in Polacco',
  populatePdfNameWith: 'Compila nome file PDF con',
  populatePdfNameSelection: 'Compila selezione nome file PDF',
  port: 'Porta',
  portrait: 'Ritratto',
  portugueseDescription: 'Descrizione in Portoghese',
  portugueseName: 'Nome in Portoghese',
  postComment: 'Pubblica Commento',
  postalCode: 'Codice Postale',
  poweredBy: 'Sviluppato da',
  preferences: 'Preferenze',
  preferencesDisable: 'Disabilita le Preferenze Utente',
  prefix: 'Prefisso | Prefissi',
  preview: 'Anteprima',
  previewOfName: 'Anteprima di {name}',
  pricing: 'Prezzo',
  pricingDiscountEnabled: 'Visualizza il Prezzo Scontato',
  pricingDisplayInCart: 'Visualizza i Prezzi nel Carrello',
  pricingDisplayInPartsList: 'Visualizza i Prezzi nell’Elenco Parti',
  pricingGlobal: 'Prezzo Globale',
  pricingGlobalDescription: 'Il prezzo normale quando non viene sovrascritta alcuna Organizzazione',
  pricingHidePricingButton: 'Attiva il Pulsante Nascondi il Prezzo',
  pricingHidePricingButtonDisable: 'Disabilita il Pulsante Nascondi il Prezzo',
  pricingOrganization: 'Prezzo dell’Organizzazione',
  pricingOrganizationDeleteConfirm: 'Sei sicuro di voler eliminare questo prezzo dell’Organizzazione?',
  pricingOrganizationDescription: 'Il prezzo dell’organizzazione sovrascrive il prezzo globale per tutti gli utenti all’interno di un’Organizzazione e delle sue Organizzazioni figlie.',
  pricingOrganizationDuplicate: 'Questa organizzazione è già stata sovrascritta',
  pricingOrganizationEmptyState: 'Nessuna sovrascrittura di organizzazione definita. Cliccare {add} per iniziare.',
  pricingRetailEnabled: 'Visualizza il Prezzo Al Dettaglio',
  pricingWholesaleEnabled: 'Visualizza il Prezzo All’Ingrosso',
  primary: 'Primario',
  print: 'Stampa',
  printerLane: 'Lane della stampante',
  printJob: 'Lavoro di Stampa',
  printLogo: 'Logo Stampa',
  printSetting: 'Impostazione di Stampa',
  printSettingDeleteFail: 'Cancellazione dell’Impostazione di Stampa Non Riuscita',
  printSettingSaveFail: 'Salvataggio dell’Impostazione di Stampa Non Riuscito',
  printSettingEmptyMessage: 'Nessuna Impostazione di Stampa definita. Aggiungi un’Impostazione di Stampa per iniziare.',
  printSettingSetDefaultSuccess: 'Set Impostazione di Stampa Predefinita',
  printSettingUpdateFail: 'Aggiornamento dell’Impostazione di Stampa Non Riuscito',
  printSettingCopyMessage: 'Inserire un nome unico per la nuova Impostazione di Stampa.',
  printSettingCoverLoadErrorMsg: '',
  printSettingCreated: 'Impostazione di Stampa creata',
  printSettings: 'Impostazioni di Stampa',
  printSettingsLogo: 'Logo Impostazioni di Stampa',
  printing: 'Stampa',
  printingDisabled: 'Disabilita Tutta la Stampa',
  privacyPolicy: 'Normativa sulla Privacy',
  process: 'Elaborazione',
  processContent: 'Elaborazione del Contenuto',
  processing: 'Elaborazione in corso',
  processToDraft: 'Elaborazione in bozza',
  processedPlzToDraft: 'PLZ elaborati in bozza',
  production: 'Produzione',
  professional: 'Professionale',
  profile: 'Profilo',
  prohibitExportDownloadOfContent: 'Vieta Esportare/Scaricare contenuto',
  prohibitExportDownloadOfContentHelpText: 'Impostando \'Attiva\' si impedirà agli utenti (con privilegi) di esportare o scaricare questo contenuto.',
  property: 'Proprietà | Proprietà',
  province: 'Provincia',
  public: 'Pubblico',
  publish: 'Pubblica',
  publisherLane: 'Lane dell’editore',
  publisherManualLane: 'Lane del Manuale dell’editore',
  publishThumbnailJob: 'Pubblica Anteprima',
  publishDocuments: 'Pubblica Documenti',
  publishDraft: 'Pubblica Bozza',
  publisher: 'Editore | Editori',
  publishingErrors: 'Errori di pubblicazione',
  publishingErrorsWithName: 'Pubblicazione degli Errori {name}',
  publishingInProgress: 'Pubblicazione in corso, impossibile inviare',
  publisherJob: 'Lavoro di Pubblicazione',
  publisherMessage: 'Creazione di contenuto, gestione e  impostazioni di stampa',
  purchaseOrder: 'Ordine d’Acquisto',
  purchaseOrderTemplateEnabled: 'Attiva i Modelli d’Ordine',
  purchaseOrderTemplateDisabled: 'Disabilita i Modelli d’Ordine',
  qty: 'Q.tà',
  quantity: 'Quantità',
  query: 'Query',
  quickAdd: 'Aggiunta Rapida',
  quickAddInCartEnabled: 'Attiva Aggiunta rapida nel Carrello',
  quickLinks: 'Link Veloci',
  quote: 'Preventivo | Preventivi',
  quoteFormType: 'Modulo di Preventivo',
  quoteTemplateDisabled: 'Disabilita i Modelli di Preventivo',
  quoteTemplateEnabled: 'Attiva i Modelli Preventivo',
  range: 'Scelta',
  rearrange: 'Riordinare',
  rebuildIndexJob: 'Ricrea Indice',
  recentlyAdded: 'Elementi Aggiunti Di Recente',
  recentlyEdited: 'Elementi Modificati Di Recente',
  recentlyViewed: 'Elementi Visualizzati Di Recente',
  rectangle: 'Rettangolo',
  redirect: 'Reindirizzare',
  refresh: 'Aggiorna',
  related: 'Correlato',
  relatedDescription: 'Medium correlato già esistente nella libreria a questo {type}. Nota: il relativo medium deve avere i controlli degli accessi applicati perché gli utenti possano vederlo nella scheda \'Correlato\'.',
  relatedInfo: 'Elementi Correlati',
  relatedView: 'Vedi Medium Correlato',
  relationship: 'Relazione | Relazioni',
  releaseNotes: 'Note di Rilascio',
  rememberMe: 'Ricordami',
  remainingWidth: 'Larghezza Rimanente',
  remove: 'Rimuovi',
  removeAll: 'Rimuovi Tutto',
  removeItemConfirmation: 'Sei sicuro di voler eliminare {item}?',
  removeItemCount: 'Sei sicuro di voler eliminare {count} {type}?',
  replaceExisting: 'Sostituisci Esistente',
  report: 'Resoconto | Resoconti',
  reportAnIssue: 'Riporta un Problema',
  reportAnIssueMessage: 'E-mail inviata correttamente.',
  reportCatalogName: 'Nome Catalogo Report',
  reportCatalogNotSet: 'Il catalogo dei report non è stato definito per il report.',
  reportDefinition: 'Definizione Report| Definizioni Report',
  reportDefinitionDeleteError: 'La definizione del report non è stata cancellata',
  reportsEmptyState: 'Nessun Report',
  reportExportTimeWarning: 'A seconda del numero di record e del formato selezionato, l’esportazione può richiedere alcuni minuti.',
  reporting: 'Resoconti',
  reportingMessage: 'Accesso a vari resoconti e metriche',
  reportSettings: 'Impostazioni di Report',
  reportsLibrary: 'Libreria di Report',
  requestForQuote: 'Richiesta di Preventivo',
  requestFormType: 'Modulo di Preventivo',
  required: 'Richiesto',
  requiredItem: 'È richiesto {item}.',
  requiredMessage: 'È richiesto un {item}.',
  requiredMessageAndUnique: 'Un {item} è obbligatorio e deve essere unico.',
  requireAuthentication: 'Richiede l’Autenticazione',
  requiredReading: 'Lettura Richiesta',
  requiredReadingMessage: 'Revisionare quanto segue:',
  requiredUrlMessage: 'È richiesto un formato URL corretto.',
  reset: 'Reimposta',
  resetPassword: 'Reimposta la Password',
  resetPasswordError: 'Si è verificato un problema durante la reimpostazione della password, riprovare.',
  resetPasswordTokenError: 'Il token di reimpostazione della password non è valido.',
  resetZoom: 'Azzera Zoom',
  resource: 'Risorsa | Risorse',
  resize: 'Ridimensiona',
  resizeThumbnails: 'Ridimensiona automaticamente anteprime',
  result: 'Risultato | Risultati',
  resultFor: 'Risultato per {item} | Risultati per {item}',
  retail: 'Vendita Al Dettaglio',
  retailPrice: 'Prezzo Al Dettaglio',
  retailTotal: 'Totale della Vendita Al Dettaglio',
  return: 'Ritorna',
  returnToItem: 'Ritorna a {item}',
  returnToPartList: 'Ritorna all’Elenco Parti',
  returnToMedia: 'Ritorna a Media',
  returnToTemplate: 'Ritorna a Modello',
  revertChanges: 'Annullare le Modifiche',
  right: 'Destra',
  romanianDescription: 'Descrizione in Romeno',
  romanianName: 'Nome in Romeno',
  rootLevelBrowseFlow: 'Livello Radice',
  row: 'Riga',
  russianDescription: 'Descrizione in Russo',
  russianName: 'Nome in Russo',
  sampleData: 'Dati Campione',
  samplePreview: 'Anteprima Esemplificativa',
  sandbox: 'Sandbox',
  sat: 'Sab',
  save: 'Salva',
  saveChanges: 'Salvare le modifiche a {item}? {name}',
  saveChangesToItem: 'Salvare le modifiche a questo {item}?',
  saved: 'Salvato',
  saveNewAPIKey: 'Nuova Chiave di Integrazione non Salvata, selezionare Ritorna per completare la configurazione',
  saveNewWidgetKey: 'Nuova chiave widget non salvata. Torna per completare la configurazione.',
  search: 'Cerca',
  searchableFieldsSuccessfullyUpdated: 'Campi ricercabili aggiornati correttamente.',
  searchConfiguration: 'Ricerca configurazione',
  searchCriteria: 'Criterio di Ricerca',
  searchDocumoto: 'Cerca Documoto',
  searchFeatures: 'Ricerca funzioni',
  searchFieldConfigDescription: 'Le seguenti impostazioni di ricerca del campo del sistema si applicano solo alla ricerca generale della Libreria, alla pagina dei risultati di ricerca e all’aggiunta rapida nel Carrello. Altre funzioni di ricerca (come la ricerca Centro amministratore e Libreria dove usata) non saranno interessate.',
  searchPrompt: 'Cerca...',
  searchProperty: 'Cerca la Proprietà | Cerca le Proprietà',
  searchResultsConfiguration: 'Configurazione dei risultati di ricerca',
  searchSettings: 'Impostazioni di ricerca',
  searchSettingsDescription: 'Queste impostazioni controllano diversi comportamenti, funzioni e risultati di ricerca della Libreria.',
  searchTerm: 'Termine di Ricerca',
  searchable: 'Consultabile',
  searchableTag: 'Tag Consultabile',
  section: 'Sezione',
  select: 'Seleziona',
  selectAField: 'Seleziona un {field}',
  selectAll: 'Seleziona Tutto',
  selectAndClose: 'Selezionare e Chiudere',
  selected: 'Selezionato',
  selectedTargetPage: 'Pagina Target Selezionata',
  selectChapters: 'Seleziona Capitolo/i',
  selectChildren: 'Seleziona Figli',
  selectFields: 'Seleziona {fields}',
  selectItem: 'Seleziona {item}',
  selectMedia: 'Seleziona Contenuto/i Multimediale/i',
  selectPage: 'Seleziona Pagina',
  selectPages: 'Seleziona Pagina/e',
  selectTargetPage: 'Seleziona Pagina Target',
  send: 'Invia',
  september: 'Settembre',
  serbianDescription: 'Descrizione in Serbo',
  serbianName: 'Nome in Serbo',
  serviceUri: 'Assistenza URI',
  sessionLog: 'Registro di Sessione',
  set: 'Imposta',
  setAsDefault: 'Imposta come Predefinito',
  settings: 'Impostazioni',
  settingsMessage: 'Aggiorna la tue preferenze utente e password',
  sequence: 'Sequenza',
  shape: 'Forma',
  shapeTool: 'Strumento Forma',
  shared: 'Condiviso',
  sharedCart: 'Carrello Condiviso | Carrelli Condivisi',
  sharedCartDeleteContent: 'Questo rimuove il Carrello Condiviso da tutte le Organizzazioni in cui è stato condiviso.',
  sharedCartOrganizationContent: 'Selezionare le Organizzazioni che possono usare questo Carrello Condiviso.',
  sharedShoppingCartsEnabled: 'Abilita Carrelli Condivisi',
  shoppingCartExportJob: 'Esportazione Carrello',
  showExportFromSearch: 'Consenti Esporta da Risultati Ricerca',
  showExportFromSearchHelp: 'Consenti l’esportazione della lista di libri risultante da una ricerca',
  showHelp: 'Mostra Aiuto',
  showIdentifierInSearch: 'Mostra Identificatore nella Ricerca',
  showItem: 'Mostra {item}',
  showLess: 'Mostra Di Meno',
  showMediaDescriptionFilter: 'Mostra il Filtro Descrizione dei Media',
  showMediaIdentifierFilter: 'Mostra il Filtro Identificatore dei Media',
  showMediaNameFilter: 'Mostra il Filtro Nome dei Media',
  showMore: 'Mostra Di Più',
  showOrganizationFilter: 'Mostra il Filtro dell’Organizzazione',
  showPartNameFilter: 'Mostra il Filtro Nome della Parte',
  showPartNumberFilter: 'Mostra il Filtro Numero della Parte',
  showRecentlyAdded: 'Mostra gli Elementi Aggiunti Di Recente',
  showRecentlyViewed: 'Mostra gli Elementi Visualizzati Di Recente',
  showSearchButton: 'Mostra il Pulsante di Ricerca sulla Homepage',
  showSearchButtonHelp: 'Mostra il pulsante \'Vai alla Ricerca\' nell’intestazione della Libreria',
  signature: 'Firma',
  size: 'Dimensione',
  solrSlaveSvrOverride: 'Sovrascrittura delle SOLR Slave Servers Base URLs',
  sortAsc: '{value} A-Z',
  sortDesc: '{value} Z-A',
  sortRelevant: 'Più Rilevante',
  sortType: 'Riordina per Tipo',
  spanishDescription: 'Descrizione in Spagnolo',
  spanishName: 'Nome in Spagnolo',
  square: 'Quadrato',
  ssoConfiguration: 'Configurazione SSO',
  ssoIdpMetadataUrl: 'URL dei metadati IDP SSO',
  ssoLoginMaxAuthenticationAgeSeconds: 'Durata massima in secondi per l’autenticazione dell’accesso SSO',
  ssoSamlDnAttributeName: 'Nome attributo DN SAML SSO',
  ssoSamlEmailAttributeName: 'Nome attributo Email SAML SSO',
  ssoSamlMemberofAttributeName: 'Nome attributo membro di SAML SSO',
  ssoSniForSsoMetadataRetrieval: 'Utilizza SNI per recuperare i metadati SSO',
  standard: 'Standard',
  standardUserGroup: 'Gruppo utente standard',
  startDate: 'Data Iniziale',
  startDateRequired: 'È richiesta una data di inizio.',
  state: 'Stato',
  static: 'Statico',
  status: 'Condizione',
  statusCode: 'Codice Stato | Codici Stato',
  stitchPages: 'Pagine dei Punti',
  storeFrontAdmin: 'Gestione della Vetrina',
  storefront: 'Vetrina',
  storefrontIntegration: 'Integrazione della Vetrina| Integrazioni della Vetrina',
  storefrontIntegrationSubtitle: 'Queste impostazioni controllano i vari elementi e le opzioni dell’Integrazione della Vetrina. Prestare attenzione durante le modifiche. Contattare l’Assistenza Documoto per ulteriori informazioni.',
  storefrontSettings: 'Impostazioni della Vetrina',
  storefrontSettingsDescription: 'Queste impostazioni controllano i vari elementi e le opzioni della Vetrina. Prestare attenzione durante le modifiche. Contattare i Servizi Documoto per ulteriori informazioni.',
  storefrontSettingsOrgDescription: 'Queste impostazioni controllano i vari elementi e le opzioni della Vetrina a livello dell’Organizzazione. Prestare attenzione durante le modifiche. Contattare i Servizi Documoto per ulteriori informazioni.',
  storeFilterValues: 'Memorizza valori filtro',
  storeFilterValuesDescription: 'L’abilitazione di questa impostazione memorizza i valori impostati dagli utenti nei filtri della pagina di ricerca per questa etichetta.',
  stroke: 'Traccia',
  style: 'Stile | Stili',
  styles: {
    colorHotpoint: 'Default di Hotpoint',
    invalidHexcode: 'Codice HEX non valido',
    inheritedFromOrg: 'Ereditato dall’Organizzazione {org}',
    inheritedFromSystem: 'Ereditato dal Default di Sistema',
    inheritedFromTenant: 'Ereditato dal Tenant',
    organization: 'Stile dell’Organizzazione | Stili dell’Organizzazione',
    organizationSaved: 'Stili dell’Organizzazione Salvati',
    sampleNavigation: 'Schede di Navigazione',
    samplePrimary: 'Navigazione/Tasti',
    sampleSelectedHotpoint: 'Hotpoint Selezionati',
    tenant: 'Stile del Tenant | Stili del Tenant',
    tenantSaved: 'Stili del Tenant Salvati'
  },
  subChapter: 'Sottocapitolo | Sottocapitoli',
  subject: 'Oggetto',
  subjectMessage: 'È richiesto un Oggetto',
  submit: 'Invia',
  submitPurchaseOrder: 'Ordine d’Acquisto',
  submitRequestForQuote: 'Richiesta di Preventivo',
  submitted: 'Inviato',
  submitter: 'Mittente',
  submittedBy: 'Inviato da',
  submitterEmail: 'E-mail Mittente',
  submitterFirstName: 'Nome Mittente',
  submittedFilesMessage: 'File {fileCount} Inviati.',
  submitterLastName: 'Cognome Mittente',
  submitterOrgName: 'Nome Organizzazione Mittente',
  submitterUserName: 'Nome Utente Mittente',
  success: 'Successo',
  imageSaveSuccess: 'Immagini aggiornate con successo',
  successNotificationDuration: 'Aggiungi al Carrello - Durata della Notifica Toast Riuscita',
  suffix: 'Suffisso | Suffissi',
  suggestion: 'Suggerimento | Suggerimenti',
  sun: 'Dom',
  superadmin: 'Superadmin',
  superAdminSettings: "Impostazioni Superadmin",
  supersede: 'Sostituisci',
  supersedeComplete: 'Vuoi completare la tua sostituzione?',
  supersedeDiscardChanges: 'Eliminare la tua sostituzione?',
  supersedeMainText: 'La sostituzione sostituisce una parte esistente con una o più parti, in tutte le Pagine delle Parti in cui viene trovata la parte vecchia. Il numero della parte vecchia viene cancellato ma rimane consultabile tramite tag, in modo che il contenuto possa essere trovato usando il vecchio numero o il nuovo numero.',
  supersedeMessage: 'Gli aggiornamenti delle parti hanno effetto immediato. La ricerca rifletterà gli aggiornamenti entro pochi minuti.',
  supersedeNotProcessed: 'La tua sostituzione non verrà processata.',
  supersedeReplacePart: 'Trova una o più parti che sostituiscano la parte',
  supersedeSuccessful: 'Sostituita Correttamente',
  supersedeWith: 'Sostituisci Parte Con...',
  supplier: 'Fornitore | Fornitori',
  supplierKey: 'Chiave del fornitore',
  supplierDescription: 'L’azienda che fornisce questa parte',
  supportedImageFormats: 'Formati dell’immagine supportati: GIF, JPG, JPEG, PNG',
  supportedDraftArchives: 'File non supportato; file di bozza supportati: MDZ, PLZ',
  svgFinder: 'Rilevatore SVG',
  swedishDescription: 'Descrizione in Svedese',
  swedishName: 'Nome in Svedese',
  system: 'Sistema',
  systemAdmin: 'Gestione del Sistema',
  systemFields: 'Campi del Sistema',
  systemFieldSearchAndFiltering: 'Cerca e filtra campo del sistema',
  systemStatus: 'Stato del Sistema',
  systemName: 'Nome sistema',
  systemVersion: 'Versione sistema',
  tabDelimited: 'Delimitato da tabulazione',
  tableOfContents: 'Indice',
  tabloid: 'Tabloid',
  tag: 'Tag | Tag',
  tagName: 'Nome etichetta',
  tagEditor: {
    rangeBoundLengthInvalid: 'Il limite non può avere più di {limit} cifre.',
    tagEditorModal: 'Modalità Editore Tag',
    filterPrompt: 'Visualizzazione dei primi 100 valori. Usa il filtro per vedere altro.'
  },
  tagSearchAndFiltering: 'Cerca e filtra etichetta',
  tagSearchAndFilteringHelpText: 'Suggerimento: alcune impostazioni relative a ricerca e filtri sono anche configurabili negli editor di etichette Gestione etichette, disponibili qui: ',
  tagSubmitAffected: 'Qualunque contenuto {type} cancellerà automaticamente il tag {tag} oltre alle modifiche.',
  tagDeleteAffectedCount: 'Questo cancellerà il tag e rimuoverà le informazioni del tag da {contare} elementi.',
  tagDeleteAffectedNone: 'Attualmente nessun elemento usa questo tag.',
  tagManagerDeleteWarning: 'Qualunque medium già presente con {deleteCategoryName} perderà questa categoria dopo la cancellazione.',
  tagsDisplayMore: 'Visualizzazione dei primi 100 valori. Usa il filtro per vedere di più.',
  tagsManager: 'Gestore del Tag',
  tagValue: 'Valore del Tag | Valori del Tag',
  tagValueMissing: 'È necessario inserire un valore del tag',
  tagValueMultiple: 'Punto e virgola per delimitare in caso di voci multiple',
  tagValueTooLong: 'Il valore del tag supera il limite di 25 caratteri | I valori del tag superano il limite dei 25 caratteri',
  taxon: 'Classificazione',
  taxonomy: 'Classificazione',
  template: 'Modello | Modelli',
  templateEmailRecipients: 'Modello Destinatari E-mail',
  templateLayout: {
    addRow: 'Aggiungi Riga',
    addItem: 'Aggiungi Elemento',
    deleteRow: 'Elimina Riga',
    deleteColumn: 'Elimina Colonna',
    fullWidth: 'Larghezza Intera',
    oneColumn: 'Una Colonna',
    threeColumns: 'Tre Colonne',
    twoColumns: 'Due Colonne'
  },
  tenant: 'Tenant',
  tenantAdmin: 'Gestione del Tenant',
  tenantAdminMessage: 'Impostazioni ampie per i controlli del tenant',
  tenantCleanerJob: 'Manutenzione Tenant',
  tenantConfiguration: 'Configurazione titolare',
  tenantEmailRecipients: 'Tenant Destinatari E-mail',
  tenantEmailRecipientsOverrideMessage: 'Questo verrà sovrascritto se vengono specificati i Destinatari E-mail dell’Organizzazione',
  tenantIndexQueueJob: 'Coda Indice Tenant',
  tenantKey: 'Chiave titolare',
  tenantKeyError: 'La chiave del titolare non è unica',
  tenantKeyMessage: 'La chiave del titolare deve essere unica',
  tenantLabelDeleteMessage: 'L’eliminazione di questa etichetta ripristina il nome Documoto predefinito',
  tenantReindexModulus: 'Modulo reindicizzazione titolare',
  tenantSettings: {
    collapseTOCLabel: 'Ridurre TOC nella Pagina Corrispondente',
    defaultLocaleLabel: 'Impostazioni Locali Predefinite',
    defaultLocaleMessage: 'Le impostazioni locali predefinite del proprietario.',
    deleteMissingTranslationsOnPublishLabel: 'Eliminare traduzioni mancanti su Pubblica?',
    deleteMissingTranslationsOnPublishMessage: 'Attiva per eliminare traduzioni di articoli su Pubblicazione',
    defaultSupplierLabel: 'Fornitore Predefinito',
    defaultSupplierMessage: 'Il fornitore predefinito del proprietario.',
    enableChapterIndexingMessage: 'Indicizzare i capitoli',
    enableChapterIndexingLabel: 'Attiva l‘indicizzazione per i capitoli multimediali',
    enableIndexingLabel: 'Indicizza sulla pubblicazione',
    enableIndexingMessage: 'Attiva l’indicizzazione per la pubblicazione.',
    helpUrlLabel: 'URL Aiuto Predefinito',
    helpUrlMessage: 'L’URL di un sistema di assistenza alternativo.',
    hotPointLinkPageInBookOnlyLabel: 'Collegamenti Hotpoint alla Pagina solo in formato libro',
    hotPointLinkPageInBookOnlyMessage: 'Collegamenti Hotpoint da Pagine solo in formato libro',
    mediaTimestampLabel: 'Aggiornamento Timestamp dei Media su Modifica Controllo degli Accessi',
    mediaTimestampMessage: 'Attiva se una modifica nei controlli degli accessi deve aggiornare il timestamp dei media.',
    publisherDefaultUom: 'UDM Predefinita Editore',
    publishingContactLabel: 'Pubblicazione dell’E-mail Contatto',
    publishingContactMessage: 'L’indirizzo e-mail predefinito che riceverà le e-mail quando viene riportato un problema di contenuto.',
    replaceHotpointLabel: 'Sostituisci Sempre Link Hotpoint nella Pubblicazione',
    replaceHotpointMessage: 'Solo per pubblicazioni Docustudio.',
    ssoLoginSystemLabel: 'Sistema di Login SSO',
    ssoLoginSystemMessage: 'Sistema di Identity Provider per SSO.',
    userPasswordExpirationLabel: 'Giorni rimanenti alla scadenza della password dell’utente',
    userPasswordExpirationMessage: 'Numero di giorni dopo i quali le password devono essere reimpostate; lasciare in bianco per evitare che scadano',
    lockPartTrOnPublishLabel: 'Bloccare le traduzioni di articoli su Pubblica',
    lockPartTrOnPublishMessage: 'Impedisce che le traduzioni di articoli vengano modificate durante la Pubblicazione',
    exportPgAsHashLabel: 'Esporta il nome del file pagina come chiave Hash',
    exportPgAsHashMessage: 'Utilizza chiavi Hash di pagina come nomi dei file pagina nel contenuto esportato'
  },
  tenantSettingsTitle: 'Impostazioni Tenant',
  tenantSettingsSaved: 'Impostazioni Tenant Salvate',
  tenantSettingsSubtitle: 'Le seguenti impostazioni controllano i comportamenti e le proprietà a livello del tenant. Fare attenzione quando si apportano le modifiche. Contattare Documoto Services per ulteriori informazioni.',
  tenantSuppliers: 'Fornitori del titolare',
  tenantLabelsPageText: 'Le etichette vengono usate per personalizzare i nomi di molti oggetti che si trovano all’interno della Libreria Documoto e delle schermate Amministrazione.',
  tenantPrintSettingsDescription: 'Scegliere l’impostazione di stampa predefinita per il titolare. Questo verrà utilizzato per tutte le stampe dei libri, salvo sovrascrittura dell’Organizzazione o del Medium.',
  tenantType: 'Tipo di titolare',
  tenantWebserviceUser: 'Utente Webservice del titolare',
  text: 'Testo',
  textArea: 'Area di Testo',
  textField: 'Casella di Testo',
  textFile: 'File di Testo (CSV, TXT)',
  textTool: 'Strumento Testo',
  thu: 'Gio',
  thumbnail: 'Anteprima',
  thumbnailDeleted: 'Cancellazione dell’Anteprima Riuscita',
  thumbnailDeletedError: 'Cancellazione dell’Anteprima Non Riuscita',
  thumbnailUpdated: 'Aggiornamento dell’Anteprima Riuscito',
  thumbnailUpdatedError: 'Aggiornamento dell’Anteprima Non Riuscito',
  thumbnailReapplyWarning: 'Il sistema può riapplicare una miniatura sulla base dei contenuti del medium. Potrebbe richiedere qualche minuto.',
  thumbnailResizeTooltip: 'Ridimensiona le anteprime a non più di 500x500 pixel, se selezionato',
  title: 'Titolo',
  to: 'A',
  toastNotifications: 'Notifiche Toast',
  toDate: 'Alla Data',
  toc: 'Toc',
  tocClipBoardSuccessTitle: 'Copiato negli Appunti',
  tocClipBoardWarningText: 'AVVISO: Le modifiche non salvate non possono essere copiate nei tuoi appunti',
  tocClipBoardWarningTitle: 'Copiato negli Appunti',
  tocDisplayingFirstValues: 'Visualizzazione dei primi 100 valori. Usa il filtro per vedere di più.',
  tocDuplicateWarningText: 'La pagina duplicata è stata individuata in un capitolo. Il duplicato è stato rimosso.',
  tocEmptyMessage: 'Sembra che tu non abbia ancora creato un Indice.',
  toGetStarted: 'per iniziare',
  tocMissingChapterName: 'Nome Capitolo Mancante',
  tocModalTableCaption: 'Modalità Editore Tag',
  tocNothingSelected: 'Nessuna Selezione',
  tocOpenEditor: 'Editor Aperto',
  tocSaveRequired: 'Salvataggio richiesto prima di modificare i dettagli del capitolo',
  tocState: 'Stato Iniziale dell’Indice',
  tocThumbnailDeleteFailTitle: 'Cancellazione dell’Anteprima Non Riuscita',
  tocThumbnailDeleteSuccessTitle: 'Cancellazione dell’Anteprima Riuscita',
  tocThumbnailUploadFailTitle: 'Caricamento dell’Anteprima Non Riuscito',
  tocThumbnailUploadSuccessTitle: 'Caricamento dell’Anteprima Riuscito',
  tocTranslationMessage: 'Nome e descrizione opzionale di questo capitolo',
  tocUntitledPage: 'Pagina Senza Titolo',
  tooManySelections: 'L‘elenco supera il limite di selezione {limit}',
  togglePage: 'Pagina di Attivazione',
  top: 'In alto',
  total: 'Totale',
  totalItems: 'Elementi Totali',
  translation: 'Traduzione | Traduzioni',
  translationHelpTextItem: 'Nome e descrizione opzionale di questo {item}',
  true: 'Vero',
  tryAgain: 'Si prega di riprovare.',
  tue: 'Mar',
  turkishDescription: 'Descrizione in Turco',
  turkishName: 'Nome in Turco',
  twoSided: 'Fronte-retro',
  type: 'Tipo | Tipi',
  typeToConfirm: 'Tipo {value} da confermare',
  typography: 'Tipografia',
  ukraineDescription: 'Descrizione in Ucraino',
  ukraineName: 'Nome in Ucraino',
  unableToRetrieveResults: 'Impossibile recuperare i risultati della query/ricerca',
  unauthorized: 'Non autorizzato',
  unknownError: 'Errore sconosciuto, contattare l’Assistenza Documoto a support{\'@\'}documoto.com per ricevere supporto',
  unknownPart: 'Parte Sconosciuta',
  unknownType: 'Tipo non riconosciuto',
  unsavedAPIKeyWarning: 'La tua Chiave API andrà persa se non selezionerai Ritorna',
  unsavedChanges: 'Non hai salvato le modifiche. Continua?',
  unsavedChangesWarning: 'Le tue modifiche verranno perse se non le salvi.',
  unsavedWidgetKeyWarning: 'Se non torni, la tua chiave widget andrà persa.',
  unsupportedBrowserMessage: 'Quest’applicazione è stata costruita con la tecnologia più recente. Ciò rende l’applicazione più veloce e facile da utilizzare. Sfortunatamente, il tuo browser non supporta queste tecnologie.',
  uom: 'UDM',
  update: 'Aggiorna',
  updateFailed: 'Aggiornamento non riuscito',
  updateIndex: 'Aggiorna l’Indice',
  updatePassword: 'Aggiorna la Password',
  updatePasswordMessage: 'Password aggiornata correttamente',
  updatePasswordPending: 'Cambio di password in attesa di salvataggio.',
  updated: 'Aggiornato',
  upgradeBrowser: 'Aggiornare il browser.',
  upload: 'Carica',
  uploadComplete: 'Caricamento Completato',
  uploadInProgress: 'Caricamento in Corso',
  upperBound: 'Limite Superiore',
  untitledOrg: 'Organizzazione Senza Titolo',
  usCurrency: 'USD',
  useDefaultCartColumns: 'Usare le Colonne del Carrello Predefinite',
  useDefaultPartsListColumns: 'Usare le Colonne Elenco delle Parti Predefinite',
  user: 'Utente',
  userAddress: 'Indirizzi Utente | Indirizzi Utente',
  userCount: 'Conteggio Utenti',
  userEditable: 'Modificabile dall’Utente',
  userExpirationDate: 'Data di scadenza dell’utente',
  userExpirationDateInvalid: 'Inserire la data di scadenza dell’utente',
  userGroup: 'Gruppo Utente | Gruppi Utenti',
  userGroupEmpty: 'I Gruppi Utenti non possono essere vuoti',
  userGroupType: 'Tipo gruppo utente',
  userGroupUniqueMessage: 'Questo nome deve essere diverso da tutti gli altri nomi del Gruppo Utente per questo proprietario',
  userGroupUsers: 'Utenti del Gruppo Utente',
  username: 'Nome Utente',
  usernameEmpty: 'Il campo username non può essere vuoto',
  users: 'Utenti',
  useSniForSsoMetadataRetrieval: 'Utilizza SNI per recuperare i metadati SSO',
  value: 'Valore',
  validate: 'Convalida',
  validateTenantDirectories: {
    buttonLabel: 'Convalida Directory di contenuto',
    error: 'Convalida delle directory non riuscita',
    success: 'Directory del titolari convalidate'
  },
  variantSKU: 'Variante SKU',
  validationMessage: 'Messaggio di Convalida | Messaggi di Convalida',
  valueGteError: 'Il valore deve essere inferiore o uguale a {max}',
  valueLteError: 'Il valore deve essere superiore o uguale a {min}',
  vendorName: 'Nome fornitore',
  verifyPassword: 'Verifica Password',
  vertical: 'Verticale',
  version: 'Versione',
  video: 'Video | Video',
  view: 'Visualizza',
  viewAllPagesAndBooks: 'Vista: Tutte le pagine e i libri',
  viewBooks: 'Vista: Libri',
  viewPages: 'Vista: Pagine',
  viewModeList: 'Elenco',
  viewModeTable: 'Tabella',
  visibility: 'Visibilità',
  visibilityDescription: 'Chi può vedere questo {entity}',
  visible: 'Visibile',
  waitForUpload: 'Attendere il completamento del caricamento.',
  watermark: 'Watermark',
  watermarkFont: 'Carattere watermark',
  watermarkOpacityPercentage: '% opacità watermark',
  watermarkOrientation: 'Orientamento watermark',
  watermarkSaved: 'Watermark salvato',
  watermarkSaveFailed: 'Impossibile salvare watermark.',
  watermarkSelectionRequired: 'Selezione Nome utente o Testo richiesti.',
  watermarkText: 'Testo watermark',
  wed: 'Mer',
  whereUsed: 'Dove Si Usa',
  wholesale: 'Vendita All’Ingrosso',
  wholesalePrice: 'Prezzo All’Ingrosso',
  wholesaleTotal: 'Totale Vendita All’Ingrosso',
  widget: 'Widget',
  widgetAuthHeader: 'Valore autorizzazione widget',
  widgetKey: 'Chiave widget | Chiavi widget',
  widgetKeyAdmin: 'Ammin chiave widget',
  widgetKeyCopied: 'Valore autorizzazione widget copiato negli Appunti',
  widgetKeyDeleted: 'Chiave widget eliminata',
  widgetKeyDeletedError: 'Errore durante l’eliminazione della chiave widget',
  widgetKeyDeleteMsg: 'L’eliminazione della chiave widget ne disabiliterà l’uso',
  widgetKeyManager: 'Gestione chiave widget',
  widgetKeyNameEmpty: 'Il nome deve essere univoco ed è obbligatorio. Questo valore sarà utilizzato anche per scopi di reporting e accesso come nome utente per qualsiasi attività del widget',
  widgetKeyRegenError: 'Errore durante la rigenerazione della chiave widget',
  widgetKeyRegenMsg: 'La rigenerazione di questa chiave widget potrebbe disabilitare le integrazioni esistenti',
  widgetKeyRegenTitle: 'Rigenerare questa chiave widget?',
  widgetKeySaved: 'Chiave widget salvata',
  widgetPermissions: 'Gli utenti widget sono di sola lettura, e l’accesso alla funzione è gestito dalla configurazione a livello di widget. Le autorizzazioni del gruppo utente standard non si applicano agli utenti widget. Privilegi di accesso alla Categoria Media. Procedere per assegnare i controlli di accesso della categoria dei media.',
  widgetRefreshErrorMsg: "Aggiornare la pagina per visualizzare il contenuto richiesto",
  widgetUserGroup: 'Gruppo utente widget',
  widgetUserGroupDeleteMsg: 'Impossibile eliminare il gruppo widget con utenti',
  willBeAffected: 'sarà interessato.',
  willBeDeleted: '{content} verrà cancellato.',
  willBeInsertedIntoItem: '{name} verrà inserito in {item}',
  willBeUpdated: 'sarà aggiornato.',
  zipCode: 'Codice Postale',
  zoomHotpointToCanvas: 'Aumenta Hotpoint alla dimensione Canvas',
  zoomIn: 'Ingrandisci',
  zoomOut: 'Rimpicciolisci'
}
/* eslint-enable max-len */
