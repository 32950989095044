/**
 * Recursively loops through nested arrays to find object by id. Preserves Vue reactivity.
 *
 * @param {{id:number, children?:{id:number}[]}[]} data
 * @param {number} id
 * @returns {{id:number} | *}
 */
export const findArrayItem = (data, id) => {
  let payload
  for (const el of data) {
    if ((payload !== undefined)) {
      break
    }

    if (el.id === id) {
      payload = el
      break
    } else if (el.children && Array.isArray(el.children) && el.children.length) {
      payload = findArrayItem(el.children, id)
    }
  }
  return payload
}
