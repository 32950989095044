<template>
  <basic-modal
    ref="modal"
    hide-discard
    :can-cancel="isLoading ? false : ['escape', 'x', 'outside']"
    :can-submit="canSubmit"
    :is-loading="isLoading"
    :submit-options="submitOptions"
    @close="$emit('close')"
    @submit="$emit('submit')"
  >
    <template #title>
      <slot name="title" />
    </template>
    <template #content>
      <slot name="content" />
    </template>
  </basic-modal>
</template>

<script>
import BasicModal from '@/components/common/modal/BasicModal'

export default {
  components: {
    BasicModal
  },
  props: {
    canSubmit: {
      type: Boolean,
      default: true
    },
    content: {
      type: String,
      default: null
    },
    isLoading: Boolean,
    submitOptions: {
      type: Object,
      default: () => ({
        label: 'submit',
        type: 'is-success'
      })
    }
  },
  methods: {
    open () {
      this.$refs.modal.open()
    },
    close () {
      this.$refs.modal.close()
    }
  }
}
</script>
