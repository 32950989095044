<template>
  <section class="section-header">
    <h5 v-if="title" class="title-five" v-text="title" />
    <h2 v-if="subtitle" class="subtitle" v-text="subtitle" />
    <div class="back-button">
      <slot name='back-button' />
    </div>
    <div class="body-copy">
      <slot name="body-copy" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    }
  }
};
</script>

<style scoped lang="scss">
.section-header {
  padding: 2rem 0;
  display: flex;
  flex-flow: column nowrap;
  .body-copy {
    max-width: 60%;
  }
}
</style>
