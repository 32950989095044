export enum TenantPrintSettingSystemPartColumn {
  ITEM,
  PARTNUMBER,
  PARTNAME,
  QUANTITY,
  UOM,
  RETAIL_PRICE,
  WHOLESALE_PRICE,
  DISCOUNTED_PRICE,
  SUPPLIER,
  RETAIL_TOTAL,
  WHOLESALE_TOTAL,
  DISCOUNTED_TOTAL,
  VARIANT_SKU
}