<template>
  <div class="card" @click="click">
    <div class="card-image">
      <thumbnail :thumbnail-url="item.thumbnailUrl"
                 :thumbnail-type="item.type"
                 data-int="card-thumbnail"
                 class="is-square"/>
    </div>
    <div class="card-content">
      <div class="content">
        <p data-int="card-name"
           class="has-text-centered truncate is-marginless"
           data-toggle="tooltip"
           :title="item.name">
          <slot>
            <router-link :to="{ path: uri }" @click.stop="">
              <strong>{{ item.name }}</strong>
            </router-link>
          </slot>
        </p>
        <p data-int="card-identifier"
           class="subtitle is-size-6 has-text-centered truncate-subtitle"
           data-toggle="tooltip"
           :title="item.identifier">
          {{ item.identifier }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Thumbnail from '@/components/common/Thumbnail';

export default {
  name: 'NavigatorCard',
  components: {
    Thumbnail
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    path: {
      type: String,
      required: true
    }
  },
  computed: {
    uri() {
      return `${this.path}/${this.item.type}:${this.item.id}`;
    }
  },
  methods: {
    click() {
      this.$emit('click', this.item);
    }
  }
};
</script>

<style scoped>
.card {
  height: 100%;
  max-width: 200px;
  cursor: pointer;
}
.card-content {
  padding: .5rem;
  line-height: normal;
}
.card-image {
  padding: 1rem;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  min-height: 2rem;
}
.truncate-subtitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
}
</style>
