<template>
  <th v-if="canEdit" data-int="cart-header-actions" />
</template>

<script>
export default {
  name: 'CartHeaderActions',
  props: {
    canEdit: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped>

</style>
