<template>
  <div class="sort-container">
    <div
      data-int="page-limit"
      class="dropdown is-right is-hoverable"
    >
      <div class="dropdown-trigger">
        <button
          data-int="page-limit-dropdown-trigger"
          class="button"
        >
          <span data-testid="dropdown-label">{{ selectedSortName }}</span>
          <span class="icon is-small"><i class="fa fa-angle-down" /></span>
        </button>
      </div>
      <div
        id="dropdown-menu"
        role="menu"
        class="dropdown-menu"
      >
        <div class="dropdown-content">
          <a
            v-for="(option, index) in options"
            :key="index"
            data-int="page-limit-value"
            :class="{'is-active': option.value === sort && option.direction === direction}"
            class="dropdown-item"
            @click="setSort(option)"
          >
            {{ option.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FiltersSort',
  props: {
    sort: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: ''
    }
  },
  computed: {
    options () {
      return [
        {
          value: '',
          direction: '',
          name: this.$i18n.t('sortRelevant')
        }, {
          value: 'name',
          direction: 'asc',
          locale: this.$i18n.locale.replace('-', '_'),
          name: this.$i18n.t('sortAsc', { value: this.$i18n.t('name') })
        }, {
          value: 'name',
          direction: 'desc',
          locale: this.$i18n.locale.replace('-', '_'),
          name: this.$i18n.t('sortDesc', { value: this.$i18n.t('name') })
        }, {
          value: 'identifier',
          direction: 'asc',
          name: this.$i18n.t('sortAsc', { value: this.$i18n.t('identifier') })
        }, {
          value: 'identifier',
          direction: 'desc',
          name: this.$i18n.t('sortDesc', { value: this.$i18n.t('identifier') })
        }, {
          value: 'description',
          direction: 'asc',
          locale: this.$i18n.locale.replace('-', '_'),
          name: this.$i18n.t('sortAsc', { value: this.$i18n.t('description') })
        }, {
          value: 'description',
          direction: 'desc',
          locale: this.$i18n.locale.replace('-', '_'),
          name: this.$i18n.t('sortDesc', { value: this.$i18n.t('description') })
        }, {
          value: 'searchable_type',
          direction: 'asc',
          noModifier: true,
          name: this.$i18n.t('sortAsc', { value: this.$i18n.tc('type') })
        }, {
          value: 'searchable_type',
          direction: 'desc',
          noModifier: true,
          name: this.$i18n.t('sortDesc', { value: this.$i18n.tc('type') })
        }, {
          value: 'updated',
          direction: 'asc',
          noModifier: true,
          name: this.$i18n.t('sortAsc', { value: this.$i18n.t('updated') })
        }, {
          value: 'updated',
          direction: 'desc',
          noModifier: true,
          name: this.$i18n.t('sortDesc', { value: this.$i18n.t('updated') })
        }
      ]
    },
    selectedSortName () {
      const selection = this.options
        .find((s) => s.value.length &&
              this.sort.startsWith(s.value) &&
              s.direction === this.direction)

      const result = selection || this.options[0]
      return result.name
    }
  },
  methods: {
    setSort (sort) {
      this.$emit('setSort', sort)
    }
  }
}
</script>
