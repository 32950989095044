<template>
  <main class="preferences-profile-container">
    <section class="profile-container">
      <div class="field">
        <label class="label">{{ $t('username') }}</label>
        <div class="control has-icons-left">
          <input class="input"
                 type="text"
                 v-model="username"
                 :placeholder="$t('username')"
                 :disabled="true"/>
          <span class="icon is-small is-left">
            <i class="fa fa-user"/>
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('email') }}</label>
        <div class="control has-icons-left">
          <input class="input"
                 type="text"
                 v-model="email"
                 :placeholder="$t('email')"
                 :disabled="true"/>
          <span class="icon is-small is-left">
            <i class="fa fa-envelope"/>
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('firstName') }}</label>
        <div class="control is-expanded">
          <input class="input"
                 type="text"
                 v-model="firstName"
                 :placeholder="$t('firstName')"
                 :disabled="true"/>
        </div>
      </div>
      <div class="field">
        <label class="label">{{ $t('lastName') }}</label>
        <div class="control">
          <input class="input"
                 type="text"
                 v-model="lastName"
                 :placeholder="$t('lastName')"
                 :disabled="true"/>
        </div>
      </div>
      <br/>
      <br/>
      <div>
        <div class="field">
          <label class="label">{{ $t('updatePassword') }}</label>
          <h2 class="mb-2 has-text-danger">
            {{ $t('passwordCriteriaInfo') }}
          </h2>
          <ul class="mb-2 info">
            <li>{{ $t('passwordCannotBeSame') }}</li>
            <li>{{ $t('passwordMinLengthMessage') }}</li>
            <li>{{ $t('passwordCasingMassage') }}</li>
            <li>{{ $t('passwordSpecialCharactersMessage') }}</li>
          </ul>
          <div class="control has-icons-left">
            <input v-model="currentPassword"
                   type="password"
                   :placeholder="$t('currentPassword')"
                   class="input preferences-current-password"/>
            <span class="icon is-small is-left">
            <i class="fa fa-key"/>
          </span>
          </div>
        </div>
        <div class="field">
          <div class="control has-icons-left">
            <input v-model.trim="newPassword"
                   type="password"
                   :placeholder="$t('newPassword')"
                   class="input preferences-new-password"
                   :class="{'is-danger': !isValidAndNotBlank}"/>
            <span class="icon is-small is-left">
            <i class="fa fa-key"/>
          </span>
          </div>
        </div>
        <div class="field">
          <div class="control has-icons-left">
            <input v-model.trim="verifyPassword"
                   type="password"
                   :placeholder="$t('verifyPassword')"
                   class="input preferences-verify-password"
                   :class="{'is-danger': !isValidAndNotBlank}"/>
            <span class="icon is-small is-left">
            <i class="fa fa-key"/>
          </span>
          </div>
          <p v-if="!isBlank && !isMatching" class="help is-danger has-text-left">
            {{ $t('passwordNotMatchingMessage') }}
          </p>
          <p v-if="!isBlank && !isMinLength" class="help is-danger has-text-left">
            {{ $t('passwordMinLengthMessage') }}
          </p>
          <p v-if="!isBlank && !hasUpperCase" class="help is-danger has-text-left">
            {{ $t('passwordUpperCaseMessage') }}
          </p>
          <p v-if="!isBlank && !hasLowerCase" class="help is-danger has-text-left">
            {{ $t('passwordLowerCaseMessage') }}
          </p>
          <p v-if="!isBlank && !hasSpecialCharacter" class="help is-danger has-text-left">
            {{ $t('passwordSpecialCharactersMessage') }}
          </p>
        </div>
        <div class="field">
          <p class="control">
            <button :disabled="!isValid"
                    class="button is-primary"
                    @click="updatePassword">
              {{ $t('updatePassword') }}
            </button>
          </p>
        </div>
      </div>
    </section>

  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Profile',
  computed: {
    ...mapState({
      user: state => state.user,
      updatePasswordSuccess: ({ user }) => user.passwordUpdateSuccess
    }),
    username: {
      get() {
        return this.user.username;
      },
      set() {
        // TODO Update User Profile
      }
    },
    email: {
      get() {
        return this.user.email;
      },
      set() {
        // TODO Update User Profile
      }
    },
    firstName: {
      get() {
        return this.user.firstName;
      },
      set() {
        // TODO Update User Profile
      }
    },
    lastName: {
      get() {
        return this.user.lastName;
      },
      set() {
        // TODO Update User Profile
      }
    },
    isValid() {
      return this.currentPassword && this.isMinLength && this.hasUpperCase &&
        this.hasLowerCase && this.hasSpecialCharacter && this.isMatching;
    },
    isValidAndNotBlank() {
      if (this.isBlank) {
        return true;
      }
      return this.isValid;
    },
    isBlank() {
      return this.newPassword.length === 0;
    },
    isMinLength() {
      return this.newPassword.length > 7;
    },
    hasUpperCase() {
      return /[A-Z]+/.test(this.newPassword);
    },
    hasLowerCase() {
      return /[a-z]+/.test(this.newPassword);
    },
    hasSpecialCharacter() {
      return /[^A-Za-z0-9]/.test(this.newPassword);
    },
    isMatching() {
      return this.newPassword === this.verifyPassword;
    }
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      verifyPassword: ''
    };
  },
  watch: {
    updatePasswordSuccess () {
      if (this.updatePasswordSuccess) {
        this.currentPassword = ''
        this.newPassword = ''
        this.verifyPassword = ''
      }
    }
  },
  methods: {
    ...mapActions({
      updateUserPassword: 'user/updatePassword'
    }),
    async updatePassword() {
      await this.updateUserPassword({
        currentPassword: this.currentPassword,
        newPassword: this.newPassword
      })
    }
  },
  created() {
    document.title = this.$t('profile')
  }
};
</script>

<style scoped>
.preferences-profile-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  padding-top: .5rem;
}
.profile-container {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  max-width: 48rem;
}
@media only screen and (min-width: 768px) {
  .profile-container {
    width: 48rem;
    margin: 0 auto;
  }
}
</style>
