<template>
  <div class="field" v-on-clickaway="closeCalendar">
    <div class="control" :class="{'has-icons-left': showIcon}">
      <input v-model="selectedDate"
             :placeholder="filter.name"
             readonly
             :class="{'is-danger': hasDateError}"
             class="input"
             type="text"
             @click="toggleActive"
             @change="change"/>
      <span v-if="showIcon" class="icon is-small is-left">
        <i class="fas fa-calendar-alt"/>
      </span>
    </div>
    <p v-if="hasDateError" class="help is-danger">{{ $t('invalidField', {field: filter.name}) }}</p>
    <calendar v-show="showCalendar" class="calendar" @selectDate="selectDate"/>
  </div>
</template>

<script>
import {directive as onClickaway} from 'vue3-click-away';
import {format} from 'date-fns';
import Calendar from './calendar/Calendar';

export default {
  name: 'DateInput',
  components: {
    Calendar
  },
  directives: {
    onClickaway
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    value: {
      type: Number,
      default: 0
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedDate: '',
      showCalendar: false,
      hasDateError: false
    };
  },
  watch: {
    value() {
      if (this.value) {
        this.selectedDate = format(this.value, 'YYYY-MM-DD');
      }
    }
  },
  methods: {
    toggleActive() {
      this.showCalendar = !this.showCalendar;
    },
    closeCalendar() {
      this.showCalendar = false;
    },
    selectDate(date) {
      this.hasDateError = false;
      this.selectedDate = format(date, 'YYYY-MM-DD');
      this.closeCalendar();
      this.$emit('change', this.selectedDate);
    },
    change() { // handles manual date entry
      const date = Date.parse(this.selectedDate.replace(/-/g, '/'));
      if (isNaN(date)) {
        this.hasDateError = true;
      } else {
        format(date, 'YYYY-MM-DD');
      }
    }
  }
};
</script>

<style scoped>
.calendar {
  position: absolute;
  z-index: 999;
}
</style>
