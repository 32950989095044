<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div
      data-int="quick-add-modal"
      class="modal-card"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">
          <strong data-int="quick-add-modal-title">{{ $t('quickAdd') }}</strong>
        </p>
        <button
          data-int="quick-add-modal-cancel"
          class="delete"
          aria-label="cancel"
          @click="cancel"
        />
      </header>
      <section class="modal-card-body">
        <div class="control">
          <input
            v-model="query"
            type="text"
            autocomplete="off"
            autofocus
            :placeholder="$t('search') + '...'"
            data-int="quick-add-input"
            class="input quick-add-input is-primary"
          >
        </div>
        <div class="table-container">
          <table class="table is-bordered is-striped is-narrow is-fullwidth is-hoverable">
            <tbody>
              <tr
                v-for="suggestion in suggestions"
                :key="suggestion.id"
                :class="{'is-selected': suggestion === selected}"
                class="suggestion"
                @click="select(suggestion)"
                @dblclick.stop="add(suggestion)"
              >
                <td>
                  {{ suggestion.identifier }} - {{ suggestion.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          :disabled="!selected"
          class="button is-primary add-button"
          @click="add(selected)"
        >
          {{ $t('add') }}
        </button>
        <button
          class="button cancel-button"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { SearchCallTypes } from "@/helpers/SearchCallType";
import { mapActions } from "vuex"
export default {
  name: 'QuickAddModal',
  data () {
    return {
      input: '',
      suggestions: [],
      selected: undefined
    }
  },
  computed: {
    query: {
      get () {
        return this.input
      },
      set (value) {
        this.input = value
        this.getQuickAddResults()
      }
    }
  },
  methods: {
    ...mapActions({
      getQuickAdd: 'search/searchWithReturn'
    }),
    async getQuickAddResults () {
      this.suggestions = await this.getQuickAdd({
        callType: SearchCallTypes.QUICKADD,
        q: this.input,
        searchableType: ['Part']
      })
    },
    select (part) {
      if (this.selected !== part) {
        this.selected = part
      } else {
        this.selected = undefined
      }
    },
    add (part) {
      this.$emit('addToCart', {
        part,
        qty: 1
      })
      this.cancel()
    },
    cancel () {
      this.$emit('cancel')
    }
  },
  mounted () {
    const quickAdd = document.getElementsByClassName('quick-add-input')

    if (quickAdd.length) {
      quickAdd[0].focus()
    }
    this.query = ''
  }
}
</script>

<style scoped>
  .table-container {
    overflow: auto;
  }
.table {
  margin: 1rem 0;
  overflow: auto;
}
.modal-card {
  width: 60%;
}
.modal-card-body {
  flex-direction: column;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .modal-card {
    height: 40rem !important;
  }
}
@media only screen and (max-width: 769px) {
  .modal-card {
    width: 95%;
    height: 75%;
  }
  .modal-content, .modal-card {
    max-height: 100% !important;
  }
}
</style>
