<template>
  <quick-add-modal  @addToCart="addToCart"
                    @cancel="$emit('cancel')"/>
</template>

<script>
import { mapActions } from "vuex"
import QuickAddModal from "@/components/common/QuickAddModal"
export default {
  name: "CartQuickAddModalWrapper",
  components: { QuickAddModal },
  methods: {
    ...mapActions({
      addToCart: "cart/addToCart"
    })
  }
}
</script>