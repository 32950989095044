<template>
  <div :class="[ 'columns', 'border-bottom', 'mt-2',
        { 'top-border-hover': index !== 0 }]"
       @click="e => handleClick(e, 'rowClick')">
    <div v-for="({
          booleanTag, columnClass, conversionMethod, deleteDisabled, field,
          flipBoolean, getPills, isLink, isLinkToNewTab, getToRoute,
          getThumbnailType, getThumbnailUrl, pillsClass, type, disabledLogic,
        }, i) in columns"
         :key="i"
         :class="[
           'wrap-cell',
            columnClass,
            { 'pending-delete' : source.addDeleteClass}
         ]">
      <div class="cell" @click="e => handleClick(e, 'cellClick', field)">
        <span v-if="booleanTag">
          <boolean-tag :value="flipBoolean ? !source[booleanTag] : source[booleanTag]"/>
        </span>
        <span v-if="cellTest(field)">
          <span v-if="type === 'dateShort'">
            {{ dateFormatShort(source[field]) }}
          </span>
          <span v-else-if="type === 'dateLong'">
            {{ dateFormatLong(source[field]) }}
          </span>
          <span v-else-if="type === 'thumbnailWithText'">
            <div class="is-row">
              <stateless-thumbnail  v-if="isWidget"
                                    :is-widget="true"
                                    :thumbnail-url="getThumbnailUrl(source)"
                                    :thumbnail-type="getThumbnailType(source).toLowerCase()"
                                    ref="imageWrapper" 
                                    class="is-32x32"
                                    @error="e => handleImageError(e, source)">
              </stateless-thumbnail>
              <div  v-else
                    ref="imageWrapper" 
                    class="image is-32x32 thumbnail-with-text"
                    :class="[ !getThumbnailUrl(source) && `${getThumbnailType(source).toLowerCase()}-thumbnail` ]">
                <img  :src="getThumbnailUrl(source)" 
                      @error="e => handleImageError(e, source)">
              </div>
              <span v-if="isWidget && isLink"
                    class="has-text-link pointer-on-hover">
                {{ source[field] }}
              </span>
              <router-link  v-else-if="isLink" 
                            :to="getToRoute ? getToRoute(source) : ''">
                {{ source[field] }}
              </router-link>
              <span v-else>{{ source[field] }}</span>
            </div>
          </span>
          <span v-else-if="type === 'booleanCheckbox'">
            <b-checkbox size='is-small'
                        class="mt-3"
                        :value="source[field]"
                        :disabled="disabledLogic ? disabledLogic(source) : false"
                        @input="emitToVirtualScrollList('check', field)"/>
          </span>
          <span v-else-if="type === 'numberInput'">
            <b-numberinput class="mr-3"
                           :value="source[field]"
                           :controls="disabledLogic ? !disabledLogic(source) : true"
                           min="0.5"
                           max="100.0"
                           step="0.5"
                           :size="'is-small'"
                           :controls-position="'compact'"
                           :editable="false"
                           @input="e => emitValueToVirtualScrollList('numberChange', field, e)"
            />
          </span>
          <template v-else-if="type === 'method'">
            <router-link v-if="isLink"
                         :to="getToRoute ? getToRoute(source) : ''">
              {{ conversionMethod(source[field]) }}
            </router-link>
            <span v-else>
              <!--should be passed as a local method name-->
              {{ conversionMethod(source[field]) }}
            </span>
          </template>
          <span v-else-if="type === 'translation'">
            {{ $t(source[field]) }}
          </span>
          <template v-else-if="!booleanTag">
            <span v-if="isWidget && isLink"
                  class="has-text-link pointer-on-hover">
              {{ source[field] }}
            </span>
            <router-link  v-else-if="isLink" 
                          :to="getToRoute ? getToRoute(source) : ''">
              {{ source[field] }}
            </router-link>
            <span v-else>{{ source[field] }}</span>
          </template>
          <router-link v-else-if="isLinkToNewTab"
                       target="'_blank'"
                       :to="getToRoute ? getToRoute(source) : ''">
            {{ source[field] }}
          </router-link>
          <span v-else>
            {{ source[field] }}
          </span>
        </span>
        <!-- If a pill is necessary in the grid, it will be rendered here
            via the provided helper fun that finds it in the data source -->
        <span v-if="getPills" :class="pillsClass || ''">
          <b-tag v-for="({ name, type }, i) in getPills(source)"
                 :class="['mx-1', type || 'is-primary' ]"
                 :key="`${field}${index}-${i}`">
            {{ name }}
          </b-tag>
        </span>
        <span v-if="field === 'icon' && !!getThumbnailUrl(source)">
          <stateless-thumbnail  v-if="isWidget"
                                :is-widget="true"
                                :thumbnail-url="getThumbnailUrl(source)"
                                :thumbnail-type="getThumbnailType(source).toLowerCase()"
                                ref="imageWrapper" 
                                @error="e => handleImageError(e, source)">
          </stateless-thumbnail>
          <div  v-else
                ref="imageWrapper" 
                class="image is-32x32 thumbnail-with-text"
                :class="[ !getThumbnailUrl(source) && `${getThumbnailType(source).toLowerCase()}-thumbnail` ]">
            <img  :src="getThumbnailUrl(source)" 
                  @error="e => handleImageError(e, source)">
          </div>
        </span>
        <span v-if="field === 'checkbox'">
          <b-checkbox v-if="isMultiSelect"
                      size='is-small'
                      class="mt-3"
                      :data-unit="`virtual-grid-checkbox-${index}`"
                      :value="typeof selectedItemsMap[index] === 'number' ? true : false"
                      :disabled="isSelectingAll"
                      @input="emitToVirtualScrollList('check', index)"/>
          <b-radio v-else
                   size="is-small"
                   class="mt-3"
                   :data-unit="`virtual-grid-radio-${index}`"
                   :value="typeof selectedItemsMap[index] === 'number' ? false : true"
                   @input="emitToVirtualScrollList('radio', index)"/>
        </span>
        <span v-if="field === 'button' && type === 'default'">
          <b-button v-if="source.hasSetAsDefault && !source.default"
                    data-unit="vs-setAsDefault-btn"
                    :label="$t('setAsDefault')"
                    @click="e => handleClick(e, 'setAsDefault')"/>
          <b-button v-if="source.hasSetAsDefault && source.default"
                    data-unit="vs-setAsDefault-btn"
                    :label="$t('setAsDefault')"
                    disable/>
        </span>
        <span v-if="field === 'button' && type === 'copy'">
          <b-tooltip :label="$t('copy')">
              <b-button v-if="source.hasCopy"
                        data-unit="vs-copy-btn"
                        class="mr-2"
                        icon-left="copy"
                        type="is-success is-light"
                        @click="e => handleClick(e, 'copy')"/>
          </b-tooltip>
        </span>
        <span v-if="field === 'button' && source.hasRefresh
          && type === 'refresh'">
          <b-tooltip :label="$t('refresh')">
              <b-button data-unit="vs-refresh-btn"
                        icon-right="fa fa-sync-alt"
                        type="is-success is-light"
                        @click="handleRefresh"/>
          </b-tooltip>
        </span>
        <span v-if="field === 'button' && source.hasDelete && type === 'delete'">
          <b-tooltip :label="$t('delete')">
              <b-button v-if="source.default || (source.canDisable && deleteDisabled(source[field])) || source.deleteDisabled"
                        data-unit="vs-delete-btn"
                        icon-right="fa fa-trash"
                        type="is-danger is-light"
                        disabled/>
              <b-button v-else
                        data-unit="vs-delete-btn"
                        icon-right="fa fa-trash"
                        type="is-danger is-light"
                        @click="handleDelete"/>
          </b-tooltip>
        </span>
        <span v-if="field === 'button' && type === 'details'">
          <b-button v-if="source.hasDetails"
                    data-unit="vs-details-btn"
                    class="mb-2"
                    icon-left="fa fa-fas fa-external-link-alt"
                    @click="e => handleClick(e, 'hasDetails')"/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import emitToComponent from './emitToComponentMixin'
import BooleanTag from '@/components/admin/content/BooleanTag'
import {Thumbnail} from '@/components/common'
import dateFormat from '@/helpers/dateFormat'
import BNumberinput from "@/buefy/src/components/numberinput/Numberinput"
import StatelessThumbnail from './StatelessThumbnail'

export default {
  name: 'VirtualScrollListRow',
  mixins: [emitToComponent, dateFormat],
  components: { BNumberinput, BooleanTag, Thumbnail, StatelessThumbnail },
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      required: true
    },
    buttons: {
      type: Object,
      default: () => {}
    },
    columns: {
      default: () => []
    },
    hasButtons: {
      type: Boolean
    },
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    selectedText: {
      type: String,
      required: true
    },
    selectedItemsMap: {
      type: Object,
      default: () => {}
    },
    useWhiteText: {
      type: Boolean,
      default: true
    },
    isSelectingAll: {
      type: Boolean,
      default: false
    },
    isWidget: Boolean
  },
  methods: {
    cellTest(field) {
      return field !== 'button' && field !== 'icon' && field !== 'checkbox'
    },
    emitToVirtualScrollList(eventName, field) {
      const payload = {
        index: this.index,
        field: field || ''
      }
      this.emitToComponent('VirtualScrollList', eventName, payload)
    },
    emitValueToVirtualScrollList(eventName, field, value) {
      const payload = {
        index: this.index,
        field: field || '',
        value: value
      }
      this.emitToComponent('VirtualScrollList', eventName, payload)
    },
    handleClick(event, eventName, field = '') {
      const classesString = !event.path
        ? []
        : event.path.flatMap(it => {
          return !!it.className
            ? it.className
            : []
        }).join(' ')
      const isButton = classesString.includes('button')
      const isIcon = classesString.includes('fa')
      const isCell = classesString.includes('cell')
      if (eventName === 'cellClick' && this.isWidget) {
        this.emitToVirtualScrollList('useWidgetNavToContent', this.source)
      }
      else if (eventName === 'setAsDefault' || eventName === 'copy' || eventName === 'cellClick') {
        this.emitToVirtualScrollList(eventName, field)
      } 
      else if (!isButton && !isIcon && !isCell) {
        this.emitToVirtualScrollList('rowClick')
      }
    },
    handleDelete() {
      this.emitToVirtualScrollList('delete', this.source)
    },
    handleImageError(e, source) {
      e.target.style.display = "none"
      if (!this.$refs.imageWrapper[0]) return
      this.$refs.imageWrapper[0].classList
        .add(`${source?.entityType?.toLowerCase()}-thumbnail`)
    },
    handleRefresh() {
      this.emitToVirtualScrollList('refresh', this.source)
    }
  }
}
</script>

<style lang="scss" scoped>
.right-margin {
  display: flex;
  justify-content: flex-end;
}
.borders {
  border-right: solid 1px var(--grey-darker--25--rgba);
  border-bottom: solid 1px var(--grey-darker--25--rgba);
  padding: .5em 0 .5em .5em;
}
.border-bottom {
  border-bottom: 2px solid rgb(219, 219, 219);
}
.bom-columns {
  margin-top: 0;
  margin-bottom: 0;
}
.bom-columns:first-child {
  border-left: solid 1px var(--grey-darker--25--rgba);
}
.columns:hover {
  background-color: #fafafa;
}
.top-border-hover:hover {
  border-top: solid 1px var(--grey-darker--25--rgba);
}
.wrap-cell {
  word-wrap: break-word;
  display: flex;
  align-items: center;
}
.button[data-unit="vs-setAsDefault-btn"][disable] {
  border-color: #dbdbdb;
  color: #dbdbdb;
  cursor: auto;

  &:hover {
    border-color: #dbdbdb;
    color: #dbdbdb;
  }

  &:focus:not(:active), .is-focused:not(:active) {
    box-shadow: unset;
  }
  &:focus, .is-focused {
    border-color: #dbdbdb;
    color: #dbdbdb;
  }
}
.pending-delete {
  background-color: var(--grey-darker--light-color);
  text-decoration: line-through
}
.is-row {
  display: flex;
  .image {
    margin-right: 10px;
  }
}
.thumbnail-with-text {
  min-width: 32px;
}
</style>
