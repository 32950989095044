<template>
  <main class="order-container">
    <nav
      v-if="orderLoaded"
      class="level is-marginless"
    >
      <div class="level-left">
        <div class="level-item">
          <div class="subtitle is-5">
            <strong data-unit="status-label">{{ $t('status') }}:</strong>
          </div>
          <div class="control">
            <div  v-if="isSaveable"
                  class="select status">
              <bulma-select v-if="isSaveable"
                            :value="status"
                            @input="updateStatus">
                <option v-for="sc in availableStatusCodes"
                        :key="sc.code"
                        :value="sc.code">
                  {{ sc.name }}
                </option>
              </bulma-select>
            </div>
            <label
              class="subtitle status is-5"
              data-unit="view-only-status"
              v-else
            >{{ this.statusCodes[status] }}</label>
          </div>
        </div>
      </div>
      <div class="level-item">
        <div class="is-flex is-flex-direction-column mr-2">
          <p class="subtitle is-5">
            <strong data-unit="comment-label">
              {{ $tc('comment', 1) }}:
            </strong>
          </p>
          <p  v-if="hasUpdatedComments"
              class="has-text-grey">{{ $t('updated') }}: {{ updatedComment }}</p>
        </div>
        <textarea v-if="isSaveable && isDirtyStatus"
                  v-model="approverComment"
                  data-unit="comments-editor"
                  class="textarea-styling order-comment-textbox is-size-6"
                  maxlength="255"/>
        <div  v-else
              v-html="orderHtmlComments"
              data-unit="comments-viewer"
              style="overflow-y: scroll;"
              class="textarea-styling order-comment-textbox has-background-light"/>
      </div>
      <div class="level-right">
        <div class="level-item">
          <a
            :href="downloadURL"
            download
            class="button print-button"
          >
            <span class="icon"><i class="fa fa-download" /></span>
            <span>{{ $t('download') }}</span>
          </a>
          <button
            class="button history-button"
            @click="history"
          >
            <span class="icon"><i class="fa fa-history" /></span>
            <span>{{ $t('history') }}</span>
          </button>
        </div>
      </div>
    </nav>
    <section
      v-if="orderLoaded"
      class="order"
    >
      <div
        v-for="(row, index) in orderLayout"
        :key="index"
        class="columns"
      >
        <div
          v-for="(column, index) in row"
          :key="index"
          class="column"
        >
          <order-cell
            v-for="item in column"
            :key="item"
            :item="orderFields[item]"
            :data="orderData[item] || null"
            :can-edit="isEditable"
          />
        </div>
      </div>
      <div class="items">
        <label class="label">{{ $tc('part', 2) }}</label>
        <order-part-table-wrapper :can-edit="isEditable">
          <div class="level-left">
            <button
              v-if="isEditable && isQuickAddEnabled"
              class="button level-item is-info quick-add-button"
              @click="quickAddModalOpen = true"
            >
              {{ $t('add') }}
            </button>
          </div>
        </order-part-table-wrapper>
      </div>
    </section>
    <section
      v-else
      class="order-loading"
    >
      <loading-icon />
    </section>
    <nav
      v-if="orderLoaded"
      class="level is-mobile"
    >
      <div class="level-left">
        <div
          v-if="isSaveable || userCanUpdate"
          class="level-item"
        >
          <button
            class="button is-primary"
            data-int="submit-order"
            :disabled="!canUpdateOrder"
            @click="updateOrderAndNavToOrders"
          >
            {{ $t('update') }}
          </button>
        </div>
        <div class="level-item">
          <button
            class="button close-button"
            @click="navigateToOrders"
          >
            {{ $t('close') }}
          </button>
        </div>
      </div>
    </nav>
    <order-history-modal
      v-if="historyModalOpen"
      :order="order"
      @close="historyModalOpen = false"
    />
    <order-quick-add-modal-wrapper
      v-if="quickAddModalOpen && isQuickAddEnabled"
      @cancel="quickAddModalOpen = false"
    />
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import { sanitizeHtml } from '@/helpers/sanitizeHtml'
import LoadingIcon from '@/components/common/LoadingIcon'
import OrderQuickAddModalWrapper from './parts/OrderQuickAddModalWrapper'
import OrderHistoryModal from './OrderHistoryModal'
import OrderCell from './cells/OrderCell'
import OrderPartTableWrapper from './parts/OrderPartTableWrapper'
import BulmaSelect from '@/components/common/BulmaSelect.vue'
import dateFormat from '@/helpers/dateFormat'

export default {
  name: 'Order',
  mixins: [dateFormat],
  components: {
    OrderCell,
    OrderPartTableWrapper,
    OrderHistoryModal,
    OrderQuickAddModalWrapper,
    LoadingIcon,
    BulmaSelect
  },
  data () {
    return {
      historyModalOpen: false,
      quickAddModalOpen: false,
      status: '',
      approverComment: '',
      isDirtyStatus: false,
      orderHistory: null
    }
  },
  watch: {
    order () {
      this.status = this.order.statusCode
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.info,
      orderLayout: (state) => state.order.layout,
      orderFields: (state) => state.order.fields,
      orderData: (state) => state.order.data,
      orderHtmlComments: ({ order }) => sanitizeHtml(order.info?.htmlComments ?? ''),
      orderLoaded: (state) => state.order.isLoaded,
      orderUpdating: (state) => state.order.isUpdating,
      statusCodes: (state) => state.statuscodes.items,
      userId: (state) => state.user.id
    }),
    ...mapGetters({
      isQuickAddEnabled: 'user/isQuickAddEnabled',
      isOrderViewer: 'user/isOrderViewer',
      isOrderApprover: 'user/isOrderApprover',
      canSubmitOrder: 'order/canSubmitOrder',
      hasErpCart: 'user/hasCartSendToErp'
    }),
    updatedComment() {
      if (!this.hasOrderHistory || !this.hasUpdatedComments) return ''

      const lastIdx = this.orderHistory.length - 1
      const lastCommentIdx = this.orderHistory
        .findLastIndex(it => !!it.comments)
      const mostRecentUpdate = (lastIdx === lastCommentIdx)
        ? this.orderHistory[lastCommentIdx].updated
        : this.orderHistory[Math.min(lastCommentIdx + 1, lastIdx)].updated
        
      return this.dateFormatShort(mostRecentUpdate)
    },
    canUpdateOrder () {
      return this.isOrderApprover || this.canSubmitOrder
    },
    isOpenOrder() {
      return this.order.statusCode === 'OPEN'
    },
    userIsCreator() {
      return this.order.createdByUserId === this.userId
    },
    userCanUpdate() {
      return this.isOpenOrder && this.userIsCreator
    },
    isEditable () {
      return (this.isOrderApprover || this.userIsCreator)
        && this.isOpenOrder
    },
    isCancellable () {
      return this.isOrderApprover || this.isOrderViewer || (this.order.statusCode === 'SUBMITTED')
    },
    isSaveable () {
      return !this.hasErpCart && (this.isOrderApprover || this.isOrderViewer)
    },
    availableStatusCodes () {
      return Object.keys(this.statusCodes).flatMap(key => {
        const statusCode = { 
          code: key, 
          name: this.statusCodes[key] 
        }
        switch(key) {
          case 'CANCELLED':
            return (this.isCancellable) ? statusCode : []
          case 'OPEN':
            return (this.isSaveable || 
                (this.status === 'OPEN' && !this.hasErpCart)) 
              ? statusCode
              : []
          default:
            return statusCode
        }
      })
    },
    downloadURL () {
      return `/api/orders/${this.orderId}/print/pdf`
    },
    orderId () {
      if (this.$route.params.id) {
        return this.$route.params.id
      } else {
        return undefined
      }
    },
    hasOrderHistory() {
      return this.orderHistory?.length > 1
    },
    hasUpdatedComments() {
      return this.hasOrderHistory 
        && this.orderHistory.some(it => !!it.comments)
    }
  },
  methods: {
    ...mapActions({
      updateOrder: 'order/updateOrder',
      duplicateOrder: 'order/duplicateOrder',
      navigateToOrders: 'order/navigateToOrders',
      getOrder: 'order/getOrder',
      getStatusCodes: 'statuscodes/getStatusCodes',
      getOrderHistory: 'order/getOrderHistoryWithReturn'
    }),
    history () {
      this.historyModalOpen = true
    },
    async updateOrderAndNavToOrders () {
      const status = this.userCanUpdate ? 'SUBMITTED' : this.status
      await this.updateOrder({ status, comments: this.approverComment })
      this.navigateToOrders()
    },
    updateStatus(updatedValue) {
      this.status = updatedValue
      this.approverComment = this.order.rawComments
      this.isDirtyStatus = true
    }
  },
  async created() {
    await this.getOrder(this.$route.params.id)
    this.orderHistory = await this.getOrderHistory(this.$route.params.id)
    await this.getStatusCodes()
    await this.$nextTick()
    this.status = this.order?.statusCode ?? ''
    document.title = this.order?.orderNumber ?? ''
  }
}
</script>

<style scoped>
.order-container {
  flex: 1;
  display: flex;
  flex-flow: column;
  background-color: darkgray;
}
.order-loading {
  flex: 1;
  display: flex;
  flex-flow: column;
  background-color: white;
  justify-content: center;
  align-items: center;
}
.order {
  flex: 1;
  overflow: auto;
  padding: 1rem;
  background-color: white;
}
.level {
  padding: 1rem;
}
.level.is-mobile .level-item:last-child {
  margin-right: 0;
}
.print-button {
  margin-right: .5rem;
}
.status {
  margin-left: .25rem;
}
.level {
  border-bottom: lightgray 1px solid;
  background-color: white;
}
.order-comment-textbox {
  width: 70%; 
  height: 70px;
  max-width: 700px;
}
</style>
