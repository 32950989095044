<template>
  <div data-int="browse-card" class="card" @click="click">
    <div class="card-image">
      <thumbnail :thumbnail-url="item.thumbnailUrl"
                 thumbnail-type="taxonomy"
                 data-int="browse-thumbnail"
                 class="is-square"/>
    </div>
    <div class="card-content">
      <div class="content">
        <p data-int="browse-card-name"
           class="is-size-6 has-text-centered truncate is-marginless"
           data-toggle="tooltip"
           :title="item.name">
          <router-link :to="{path}">
            <strong>{{ item.name }}</strong>
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Thumbnail from '@/components/common/Thumbnail';

export default {
  name: 'BrowseCard',
  components: {
    Thumbnail
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    path() {
      const id = this.$route.params.id?.join('/')?.replace(',', '/') || '';
      return `/browse/${id ? `${id}/${this.item.id}` : this.item.id}${this.item.hasChildren ? '' : '/search'}`;
    },
    isWidget() {
      return this.$store.getters['widgets/isWidget']
    }
  },
  methods: {
    click() {
      const event = this.isWidget ? 'widgetNav' : 'click'
      this.$emit(event, this.item);
    }
  }
};
</script>

<style scoped>
.card {
  height: 100%;
  max-width: 200px;
}
.card-content {
  padding: .5rem;
  line-height: normal;
}
.card-image {
  padding: 1rem;
}
.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -moz-box-orient: block-axis;
  -webkit-box-orient: block-axis;
  vertical-align: middle;
  min-height: 2rem;
}
</style>
