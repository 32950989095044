<script setup lang="ts">
import { computed, ref } from 'vue'

defineEmits(['click'])

const props = defineProps({
  color: {
    default: 0,
    type: Number,
    validator (value: number) {
      return [0, 1, 2].includes(value)
    }
  },
  type: {
    type: String,
    required: true,
    validator (value: string) {
      const supportedIcons = [
        'back',                'bottom',
        'camera',              'click',
        'cuttingplane',        'cuttingplane-x',
        'cuttingplane-y',      'cuttingplane-z',
        'cuttingplane-face',   'cuttingplane-section',
        'cuttingplane-toggle', 'cuttingplane-reset',
        'explode',             'face',
        'front',               'hidden-line',
        'home',                'iso',
        'left',                'measure-angle',
        'measure-distance',    'measure-edge',
        'measure-point',       'note',
        'orbit',               'ortho',
        'persp',               'redline-circle',
        'redline-freehand',    'redline-note',
        'redline-rectangle',   'right',
        'select',              'area-select',
        'settings',            'shaded',
        'snapshot',            'top',
        'turntable',           'walk',
        'wireframe',           'wireframeshaded',
        'xray'
      ]

      return supportedIcons.includes(value)
    }
  }
})

const hover = ref(false)
const iconType = `hoops-sprite-${props.type}`
const classArray = computed(() => {
    return [
      { [iconType]: true },
      { 'hover': hover.value }
    ]
  }
)
const toggleHover = () => {
  hover.value = !hover.value
}
</script>

<template>
  <div class="drop-borders button hoops-sprite"
       :class="classArray"
       @click="$emit('click')"
       @mouseover="toggleHover"
       @mouseout="toggleHover" />
</template>

<style scoped lang="scss">
.drop-borders {
  border: unset;
  border-radius: 4px;
}

.hoops-sprite {
  background: url('/public/hoops/css/images/toolbarsprite.png') no-repeat top left;
  scale: 0.75;
}

.hoops-sprite-back {
  width: 32px;
  height: 32px;
  background-position: -5px -5px;
}

.hoops-sprite-back.hover {
  width: 32px;
  height: 32px;
  background-position: -47px -5px;
}

.hoops-sprite-bottom {
  width: 32px;
  height: 32px;
  background-position: -89px -5px;
}

.hoops-sprite-bottom.hover {
  width: 32px;
  height: 32px;
  background-position: -131px -5px;
}

.hoops-sprite-camera {
  width: 32px;
  height: 32px;
  background-position: -173px -5px;
}

.hoops-sprite-camera.hover {
  width: 32px;
  height: 32px;
  background-position: -215px -5px;
}

.hoops-sprite-click {
  width: 32px;
  height: 32px;
  background-position: -257px -5px;
}

.hoops-sprite-click.hover {
  width: 32px;
  height: 32px;
  background-position: -299px -5px;
}

.hoops-sprite-cuttingplane {
  width: 32px;
  height: 32px;
  background-position: -5px -47px;
}

.hoops-sprite-cuttingplane.hover {
  width: 32px;
  height: 32px;
  background-position: -47px -47px;
}

.hoops-sprite-cuttingplane-x {
  width: 32px;
  height: 32px;
  background-position: -341px -257px;
}

.hoops-sprite-cuttingplane-y {
  width: 32px;
  height: 32px;
  background-position: -341px -215px;
}

.hoops-sprite-cuttingplane-z {
  width: 32px;
  height: 32px;
  background-position: -341px -173px;
}

.hoops-sprite-cuttingplane-face {
  width: 32px;
  height: 32px;
  background-position: -173px -47px;
}

.hoops-sprite-cuttingplane-section {
  width: 32px;
  height: 32px;
  background-position: -341px -341px;
}

.hoops-sprite-cuttingplane-toggle {
  width: 32px;
  height: 32px;
  background-position: -341px -299px;
}

.hoops-sprite-cuttingplane-reset {
  width: 32px;
  height: 32px;
  background-position: -299px -341px;
}

.hoops-sprite-explode {
  width: 32px;
  height: 32px;
  background-position: -89px -47px;
}

.hoops-sprite-explode.hover {
  width: 32px;
  height: 32px;
  background-position: -131px -47px;
}

.hoops-sprite-face {
  width: 32px;
  height: 32px;
  background-position: -173px -47px;
}

.hoops-sprite-face.hover {
  width: 32px;
  height: 32px;
  background-position: -215px -47px;
}

.hoops-sprite-front {
  width: 32px;
  height: 32px;
  background-position: -257px -47px;
}

.hoops-sprite-front.hover {
  width: 32px;
  height: 32px;
  background-position: -299px -47px;
}

.hoops-sprite-hidden-line {
  width: 32px;
  height: 32px;
  background-position: -5px -89px;
}

.hoops-sprite-hidden-line.hover {
  width: 32px;
  height: 32px;
  background-position: -47px -89px;
}

.hoops-sprite-home {
  width: 32px;
  height: 32px;
  background-position: -89px -89px;
}

.hoops-sprite-home.hover {
  width: 32px;
  height: 32px;
  background-position: -131px -89px;
}

.hoops-sprite-iso {
  width: 32px;
  height: 32px;
  background-position: -173px -89px;
}

.hoops-sprite-iso.hover {
  width: 32px;
  height: 32px;
  background-position: -215px -89px;
}

.hoops-sprite-left {
  width: 32px;
  height: 32px;
  background-position: -257px -89px;
}

.hoops-sprite-left.hover {
  width: 32px;
  height: 32px;
  background-position: -299px -89px;
}

.hoops-sprite-measure-angle {
  width: 32px;
  height: 32px;
  background-position: -5px -131px;
}

.hoops-sprite-measure-angle.hover {
  width: 32px;
  height: 32px;
  background-position: -47px -131px;
}

.hoops-sprite-measure-distance {
  width: 32px;
  height: 32px;
  background-position: -89px -131px;
}

.hoops-sprite-measure-distance.hover {
  width: 32px;
  height: 32px;
  background-position: -131px -131px;
}

.hoops-sprite-measure-edge {
  width: 32px;
  height: 32px;
  background-position: -173px -131px;
}

.hoops-sprite-measure-edge.hover {
  width: 32px;
  height: 32px;
  background-position: -215px -131px;
}

.hoops-sprite-measure-point {
  width: 32px;
  height: 32px;
  background-position: -257px -131px;
}

.hoops-sprite-measure-point.hover {
  width: 32px;
  height: 32px;
  background-position: -299px -131px;
}

.hoops-sprite-note {
  width: 32px;
  height: 32px;
  background-position: -5px -173px;
}

.hoops-sprite-note.hover {
  width: 32px;
  height: 32px;
  background-position: -47px -173px;
}

.hoops-sprite-orbit {
  width: 32px;
  height: 32px;
  background-position: -89px -173px;
}

.hoops-sprite-orbit.hover {
  width: 32px;
  height: 32px;
  background-position: -131px -173px;
}

.hoops-sprite-ortho {
  width: 32px;
  height: 32px;
  background-position: -173px -173px;
}

.hoops-sprite-ortho.hover {
  width: 32px;
  height: 32px;
  background-position: -215px -173px;
}

.hoops-sprite-persp {
  width: 32px;
  height: 32px;
  background-position: -257px -173px;
}

.hoops-sprite-persp.hover {
  width: 32px;
  height: 32px;
  background-position: -299px -173px;
}

.hoops-sprite-redline-circle {
  width: 32px;
  height: 32px;
  background-position: -5px -215px;
}

.hoops-sprite-redline-circle.hover {
  width: 32px;
  height: 32px;
  background-position: -47px -215px;
}

.hoops-sprite-redline-freehand {
  width: 32px;
  height: 32px;
  background-position: -89px -215px;
}

.hoops-sprite-redline-freehand.hover {
  width: 32px;
  height: 32px;
  background-position: -131px -215px;
}

.hoops-sprite-redline-note {
  width: 32px;
  height: 32px;
  background-position: -173px -215px;
}

.hoops-sprite-redline-note.hover {
  width: 32px;
  height: 32px;
  background-position: -215px -215px;
}

.hoops-sprite-redline-rectangle {
  width: 32px;
  height: 32px;
  background-position: -257px -215px;
}

.hoops-sprite-redline-rectangle.hover {
  width: 32px;
  height: 32px;
  background-position: -299px -215px;
}

.hoops-sprite-right {
  width: 32px;
  height: 32px;
  background-position: -5px -257px;
}

.hoops-sprite-right.hover {
  width: 32px;
  height: 32px;
  background-position: -47px -257px;
}

.hoops-sprite-select {
  width: 32px;
  height: 32px;
  background-position: -89px -257px;
}

.hoops-sprite-select.hover {
  width: 32px;
  height: 32px;
  background-position: -131px -257px;
}

.hoops-sprite-area-select {
  width: 32px;
  height: 32px;
  background-position: -257px -341px;
}

.hoops-sprite-settings {
  width: 32px;
  height: 32px;
  background-position: -173px -257px;
}

.hoops-sprite-settings.hover {
  width: 32px;
  height: 32px;
  background-position: -215px -257px;
}

.hoops-sprite-shaded {
  width: 32px;
  height: 32px;
  background-position: -257px -257px;
}

.hoops-sprite-shaded.hover {
  width: 32px;
  height: 32px;
  background-position: -299px -257px;
}

.hoops-sprite-snapshot {
  width: 32px;
  height: 32px;
  background-position: -5px -299px;
}

.hoops-sprite-snapshot.hover {
  width: 32px;
  height: 32px;
  background-position: -47px -299px;
}

.hoops-sprite-top {
  width: 32px;
  height: 32px;
  background-position: -89px -299px;
}

.hoops-sprite-top.hover {
  width: 32px;
  height: 32px;
  background-position: -131px -299px;
}

.hoops-sprite-turntable {
  width: 32px;
  height: 32px;
  background-position: -173px -299px;
}

.hoops-sprite-turntable.hover {
  width: 32px;
  height: 32px;
  background-position: -215px -299px;
}

.hoops-sprite-walk {
  width: 32px;
  height: 32px;
  background-position: -257px -299px;
}

.hoops-sprite-walk.hover {
  width: 32px;
  height: 32px;
  background-position: -299px -299px;
}

.hoops-sprite-wireframe {
  width: 32px;
  height: 32px;
  background-position: -341px -5px;
}

.hoops-sprite-wireframe.hover {
  width: 32px;
  height: 32px;
  background-position: -341px -47px;
}

.hoops-sprite-wireframeshaded {
  width: 32px;
  height: 32px;
  background-position: -341px -89px;
}

.hoops-sprite-wireframeshaded.hover {
  width: 32px;
  height: 32px;
  background-position: -341px -131px;
}

.hoops-sprite-xray {
  width: 32px;
  height: 32px;
  background-position: -341px -5px;

  /* Temporary style to differentiate between the xray and wireframe icon */
  background-color: lightgrey;
}
</style>
