<template>
  <section  class="my-2">
    <b-field  :type="{ 'is-danger': !canSave }"
              :message="warningText">
      <b-input  :value="newAddress"
                :disabled="disableAll"
                expanded 
                @input="$emit('update:newAddress', $event)"
                @blur="addEmailIfValid"/>
      <b-button type="is-success is-light"
                icon-left="plus"
                class="ml-1"
                :disabled="disableAll || !isEmailValid"
                outlined
                @click="addEmail"/>
    </b-field>
    <email-entry  v-for="(email, i) in value"
                  :key="i"
                  :email="email"
                  @deleteEmail="deleteEmail"/>
  </section>
</template>

<script>
import { isEmailValid } from "@/helpers"
import EmailEntry from "./EmailEntry"

export default {
  name: "EmailsList",
  components: { EmailEntry },
  props: {
    value: {  // List of emails
      type: Array,
      default: () => []
    },
    canSave: Boolean,
    disableAll: Boolean,
    newAddress: {
      type: String,
      default: ""
    },
    isLoading: Boolean
  },
  computed: {
    isEmailValid() { return isEmailValid(this.newAddress) },
    listHasAnEmail() { return !!this.value.length },
    warningText() {
      if (this.disableAll) {
        this.setCanSave(true)
        this.resetEmails()
        return ""
      }

      if (!this.newAddress && !this.listHasAnEmail) {
        this.setCanSave(false)
        return this.$t('emailMessage')
      } else if (!!this.newAddress && !this.isEmailValid) {
        this.setCanSave(false)
        return this.$t('invalidField', { field: this.$t('email') })
      } 
      this.setCanSave(true)
      return ""
    }
  },
  methods: {
    addEmail() {
      this.$emit("input", [
        ...this.value,
        this.newAddress
      ])
      this.resetNewAddress()
    },
    async addEmailIfValid() {
      // Next tick & wait are necessary in case user clicks submit
      // There's a race condition that otherwise will block the submit event
      // If the modal is submitting (isLoading), then it's not necessary to add the email
      await this.$nextTick()
      await this.$wait(250)
      if (!this.isEmailValid || this.isLoading) return
      this.addEmail()
    },
    deleteEmail(email) {
      this.$emit("input", this.value.filter(it => it !== email))
    },
    resetNewAddress() {
      this.$emit('update:newAddress', '')
    },
    resetEmails() {
      this.$emit("input", [])
      this.resetNewAddress()
    },
    setCanSave(val) {
      this.$emit("update:canSave", val)
    }
  }
}
</script>