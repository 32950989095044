import i18n from '@/locales'
import { DrawMode } from '@/plugins/hoops/types/web-viewer'
import { readonly } from 'vue'

export type HoopsDrawMode = {
  iconType: string
  label: string
  value: DrawMode
}

export const HoopsDrawModeOptions = readonly<HoopsDrawMode[]>([
  {
    iconType: 'wireframeshaded',
    label: i18n.global.t('threeD.drawMode.wireframeShaded'),
    value: DrawMode.WireframeOnShaded
  },
  {
    iconType: 'shaded',
    label: i18n.global.t('threeD.drawMode.shaded'),
    value: DrawMode.Shaded
  },
  {
    iconType: 'hidden-line',
    label: i18n.global.t('threeD.drawMode.hiddenLines'),
    value: DrawMode.HiddenLine
  },
  {
    iconType: 'wireframe',
    label: i18n.global.t('threeD.drawMode.wireframe'),
    value: DrawMode.Wireframe
  },
  {
    iconType: 'xray',
    label: i18n.global.t('threeD.drawMode.label'),
    value: DrawMode.XRay
  },
])
