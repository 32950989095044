<template>
  <div class="popover">
    <div class="card">
      <div  style="width: 2rem;"
            class="closeIcon">
        <button style="width: 2rem;"
                class="delete"
                @click="$emit('close')"/>
      </div>
      <div class="card-content">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <slot/>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>


<style scoped lang="scss">
.card {
  position: relative;
  border: solid 1px lightslategray;
  top: -1em;

  .closeIcon {
    position: absolute;
    top: .5em;
    right: 0;
  }
}
</style>
