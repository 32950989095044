<template>
  <div class="videoViewerWrapper is-paddingless">
    <video class="video-js vjs-default-skin" preload="auto" data-setup='{"fluid": true}'/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'ViewerVideo',
  data() {
    return {
      player: undefined
    };
  },
  watch: {
    asset() {
      this.dispose();
      this.initalize();
    }
  },
  computed: {
    ...mapState({
      asset: ({ content }) => content.contentUrl
    })
  },
  methods: {
    initalize() {
      const videoOptions = {
        autoplay: true,
        controls: true,
        preload: 'auto',
        language: this.$i18n.locale,
        sources: [{
          src: this.asset
        }],
        techOrder: ['html5', 'flash'],
        plugins: {}
      };

      this.player = null;

      if (window.videojs) {
        this.player = videojs(this.$el.children[0], videoOptions);
      }
    },
    dispose() {
      try {
        if (this.player && window.videojs) {
          this.player.pause()
          videojs(this.$el.children[0]).dispose()
        }
      } catch {
        console.log("Error disposing")
      } finally {
        this.player = undefined
      }
      
    }
  },
  mounted() {
    if (!this.player && !!this.asset) {
      this.initalize();
    }
  },
  beforeUnmount() {
    if (this.player) {
      this.dispose();
    }
  },

};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/video.js/6.5.0/video-js.min.css');

.videoViewerWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: darkgray;

}
.video-js {
  position: static;
  background-color: darkgray;
}
</style>

<style>
.video-js {
  color: black;
  font-size: .75rem;
}
.video-js .vjs-control-bar {
  background-color: white;
}
.video-js .vjs-load-progress {
  background-color: gray;
}
.video-js .vjs-play-progress {
  background-color: gray;
}
.video-js .vjs-volume-level {
  background-color: gray;
}
.video-js .vjs-volume-bar {
  background-color: gray;
}
.video-js .vjs-slider {
  background-color: lightgrey;
}
.video-js button {
  color: black;
}
.video-js button:focus {
  outline: none;
}
</style>
