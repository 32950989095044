<template>
  <main data-int="shelves" class="section is-paddingless">
    <hero :button-label="$t('goToSearch')" />
    <section class="shelves-container">
      <div>
        <h1 class="title is-size-3 is-marginless">
          <label>
            {{ $t('favoritesLists') }}
          </label>
        </h1>
      </div>
      <section v-if="!!shelves" class="shelves">
        <template v-if="shelves.length === 0">
          {{ $t('noItemsFound') }}
        </template>
        <template v-else>
          <shelf-section v-for="(shelf, shelfIndex) in shelves"
                         :key="shelfIndex"
                         :shelf-id="shelf.id"
                         class="shelf no-padding"
                         :class="{'hide-thumbnails': hideThumbnails}" />
        </template>
      </section>
    </section>
  </main>
</template>

<script>
import http from '@/http';

import Hero from '@/components/common/Hero';
import ShelfSection from './ShelfSection';

export default {
  components: {
    Hero,
    ShelfSection
  },
  data() {
    return {
      shelves: null
    };
  },
  async created() {
    try {
      document.title = this.$t('favorites')
      const {data} = await http.get('media-shelves');
      this.shelves = data;
    } catch (error) {
      console.log('error :>> ', error);
    }
  },
  computed: {
    hideThumbnails() {
      return this.shelves.length > 10;
    }
  }
};
</script>

<style scoped>
.shelves {
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
}

.no-padding {
  margin: 0px -16px;
}

.shelves-container {
  padding: 1rem;
}

.hide-thumbnails :deep(.card-image)  {
  display: none;
}
</style>
