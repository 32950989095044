import i18n from '@/locales'
import { ActionContext, GetterTree, MutationTree } from 'vuex'
import { FieldAndTagsToAdd, FieldAndTagsToUpdate, DraftTagValue, TagMetaData, Tag, DraftTagState, TagDataAndName, draftBomStorePaths, TagFields, checkedTags, draftTagTypes } from '@/interfaces/admin'
import { NumberToStringMap } from '@/interfaces/global'
import { getStructuredTagValues } from './helpers'
import { cloneDeep } from "lodash"
import { postError } from '@/helpers/notification'

type Context = ActionContext<DraftTagState, any>

export enum PrevTagValues {
  prevPartTagValues = 'prevPartTagValues',
  prevPagePartTagValues = 'prevPagePartTagValues'
}

export enum MutationTypes {
  SET_TAG_VALUES = 'SET_TAG_VALUES',
  SET_LOADING = 'SET_LOADING',
  SET_CHECKED_PG_PART_TAGS = 'SET_CHECKED_PG_PART_TAGS',
  SET_CHECKED_PART_TAGS = 'SET_CHECKED_PART_TAGS'
}

enum GettersDraftTags {
  applicableTagNames = 'tagEditor/applicableTagNames'
}

interface checkedTagSetter {
  type: draftTagTypes,
  value: TagDataAndName[]
}

const state: DraftTagState = {
  loading: false,
  checkedPagePartTags: [],
  checkedPartTags: []
}

const getters: GetterTree<DraftTagState, any> = {
  partTagValues (state, getters): TagDataAndName[] {
    const partTagValues: DraftTagValue[] = getters.partInDetailEditor?.partTagValues ?? []
    const hasPartTags = partTagValues.length > 0

    // Use helper function to structure tag values in the proper format for the components
    return hasPartTags ? getStructuredTagValues(partTagValues, getters.tagIdToName) : []
  },
  pagePartTagValues (state, getters): TagDataAndName[] {
    const pagePartTagValues: DraftTagValue[] = getters.partInDetailEditor?.pagePartTagValues ?? []
    const hasPagePartTags = pagePartTagValues.length > 0

    // Use helper function to structure tag values in the proper format for the components
    return hasPagePartTags ? getStructuredTagValues(pagePartTagValues, getters.tagIdToName) : []
  },
  tagIdToName (state, getters): NumberToStringMap {
    const map: NumberToStringMap = {}
    getters.partTags
      .concat(getters.pagePartTags)
      .forEach((tag: TagMetaData) => {
        map[tag.id] = tag.name
      })

    return map
  },
  partInDetailEditorIndex (state, getters, rootState): number {
    return rootState.draftBom.partInDetailEditorIndex
  },
  partTags (state, getters, rootState, rootGetters): TagMetaData[] {
    return rootGetters[GettersDraftTags.applicableTagNames]('part')
  },
  partInDetailEditor (state, getters, rootState) {
    return rootState.draftPage.draftBomItems[getters.partInDetailEditorIndex]
  },
  partTagValuesForModal (state, getters, rootState): Tag[] {
    const partTagValues: DraftTagValue[] = getters.partInDetailEditor?.partTagValues ?? []

    return partTagValues.map((tag: DraftTagValue) => {
      return {
        ...tag,
        name: getters.tagIdToName[tag.tagNameId]
      }
    })
  },
  prevPartTagValues (state, getters, rootState): DraftTagValue[] {
    const partTagValues: DraftTagValue[] = getters.partInDetailEditor?.partTagValues ?? []
    return cloneDeep(partTagValues)
  },
  pagePartTags (state, getters, rootState, rootGetters): TagMetaData[] {
    return rootGetters[GettersDraftTags.applicableTagNames]('page-part')
  },
  pagePartTagValuesForModal (state, getters, rootState): Tag[] {
    const pagePartTagValues: DraftTagValue[] = getters.partInDetailEditor?.pagePartTagValues ?? []
    return pagePartTagValues.map((tag: DraftTagValue) => {
      return {
        ...tag,
        name: getters.tagIdToName[tag.tagNameId]
      }
    })
  },
  prevPagePartTagValues (state, getters, rootState): DraftTagValue[] {
    const pagePartTagValues: DraftTagValue[] = getters.partInDetailEditor?.pagePartTagValues ?? []
    return cloneDeep(pagePartTagValues)
  },
  getPrevTagValues: () => (type: TagFields): PrevTagValues => {
    return type === TagFields.partTagValues
      ? PrevTagValues.prevPartTagValues
      : PrevTagValues.prevPagePartTagValues
  },
  hasCheckedTags: (state: DraftTagState) => (type: checkedTags) => {
    return state[type].length > 0
  },
  hasCheckedPagePartTags (state, getters): Boolean {
    return getters.hasCheckedTags(checkedTags.checkedPagePartTags)
  },
  hasCheckedPartTags (state, getters): Boolean {
    return getters.hasCheckedTags(checkedTags.checkedPartTags)
  },
  checkedTagsByType: (state: DraftTagState) => (type: draftTagTypes) => {
    const stateTypeMapping = Object.freeze({
      [draftTagTypes.part]: checkedTags.checkedPartTags,
      [draftTagTypes['page-part']]: checkedTags.checkedPagePartTags
    })
    return state[stateTypeMapping[type]]
  },
  hasCheckedTagsByType: (state: DraftTagState, getters) => (type: draftTagTypes) => {
    const gettersMapping = Object.freeze({
      [draftTagTypes.part]: getters.hasCheckedPartTags,
      [draftTagTypes['page-part']]: getters.hasCheckedPagePartTags
    })
    return gettersMapping[type]
  }
}

const actions = {
  async addTags ({ getters, dispatch }: Context, { type, tagsList }: FieldAndTagsToAdd): Promise<void> {
    try {
      // Build payload with list of updated tags
      const prevTagValues: PrevTagValues = getters.getPrevTagValues(type)
      const updatedPartTags: DraftTagValue[] = getters[prevTagValues]
        .concat(tagsList)
      const payload = {
        index: getters.partInDetailEditorIndex,
        type,
        value: updatedPartTags
      }
      // Update the tags of the appropriate draft BOM item
      await dispatch(draftBomStorePaths.updateDraftBomItem, payload, { root: true })
    } catch (e) {
      console.log(e)
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('draftBomError'),
      })
    }
  },
  async deleteTags ({ dispatch, getters, state, commit }: Context, { type, tagsList }: FieldAndTagsToUpdate): Promise<void> {
    try {
      // Build payload with list of updated tags
      const prevTagValues: PrevTagValues = getters.getPrevTagValues(type)

      // Filter part tags to remove matches by id OR pendingId
      // pendingId accomodates checks for added tags not yet saved to DB
      const updatedPartTags: DraftTagValue[] = getters[prevTagValues]
        .flatMap((tag: DraftTagValue) => {
          const hasIdMatch = tagsList.some(it => {
            const idMatch: boolean = (it?.id ?? '1') === (tag?.id ?? '2')
            const pendingIdMatch: boolean = (it?.pendingId ?? '1') === (tag?.pendingId ?? '2')
            return idMatch || pendingIdMatch
          })
          return hasIdMatch ? [] : [tag]
        })

      const payload = {
        index: getters.partInDetailEditorIndex,
        type,
        value: updatedPartTags
      }

      // Update the tags of the appropriate draft BOM item
      await dispatch(draftBomStorePaths.updateDraftBomItem, payload, { root: true })

      // Zero out checked tags
      const tagFieldToCommitMap = Object.freeze({
        [TagFields.partTagValues]: MutationTypes.SET_CHECKED_PART_TAGS,
        [TagFields.pagePartTagValues]: MutationTypes.SET_CHECKED_PG_PART_TAGS
      })
      commit(tagFieldToCommitMap[type], [])
    } catch (e) {
      console.log(e)
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('draftBomError'),
      })
    }
  },
  async editTags ({ dispatch, getters }: Context, { type, tagsList }: FieldAndTagsToUpdate): Promise<void> {
    try {
      // Build payload with list of updated tags
      const prevTagValues: PrevTagValues = getters.getPrevTagValues(type)
      const updatedPartTags: DraftTagValue[] = getters[prevTagValues]
        .map((prev: DraftTagValue) => {
          const isTagToUpdate = tagsList.find(it => {
            const idMatch = (it?.id ?? '1') === (prev?.id ?? '2')
            const pendingIdMatch = (it?.pendingId ?? '1') === (prev?.pendingId ?? '2')
            return idMatch || pendingIdMatch
          })
          const result = { ...prev }
          Array.from(['value', 'lowerBoundValue', 'upperBoundValue', 'suffixValue', 'prefixValue'])
            .forEach(field => updateResult(field))

          return result
          // Helper function for checking each field
          // in case tagsList has multiple edits to update
          function updateResult(field: string) {
            if (isTagToUpdate?.hasOwnProperty(field)) {
              if (isTagToUpdate[field] === null) {
                delete result[field]
              } else {
                result[field] = isTagToUpdate[field]
              }
            }
          }
        })

      const payload = {
        index: getters.partInDetailEditorIndex,
        type,
        value: updatedPartTags
      }

      // Update the tags of the appropriate draft BOM item
      await dispatch(draftBomStorePaths.updateDraftBomItem, payload, { root: true })
    } catch (e) {
      console.log(e)
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('draftBomError'),
      })
    }
  },
  async loadDraftTags ({ commit, dispatch }: Context) {
    try {
      commit(MutationTypes.SET_LOADING, true)
      await dispatch('tagEditor/getTagNames', '', { root: true })
    } catch (e) {
      postError({
        title: i18n.global.t('error'),
        text: i18n.global.t('draftBomError'),
      })
    } finally {
      commit(MutationTypes.SET_LOADING, false)
    }
  },
  setCheckedTags (context: Context, payload: checkedTagSetter) {
    const commitTypeMapping = Object.freeze({
      [draftTagTypes.part]: MutationTypes.SET_CHECKED_PART_TAGS,
      [draftTagTypes['page-part']]: MutationTypes.SET_CHECKED_PG_PART_TAGS
    })
    context.commit(MutationTypes[commitTypeMapping[payload.type]], payload.value)
  }
}

const mutations: MutationTree <DraftTagState> = {
  [MutationTypes.SET_LOADING] (state, value: boolean) {
    state.loading = value
  },
  [MutationTypes.SET_CHECKED_PG_PART_TAGS]: setCheckedTagsMutation(checkedTags.checkedPagePartTags),
  [MutationTypes.SET_CHECKED_PART_TAGS]: setCheckedTagsMutation(checkedTags.checkedPartTags)
}

// Helper higher-order setter function for
// committing checked tags in TagEditor
function setCheckedTagsMutation (type: checkedTags) {
  return function (state: DraftTagState, tags: any[]) {
    state[type] = tags
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
