import { ActionContext, MutationTree } from "vuex";
import { TenantAdminBanner } from "@/interfaces/admin";
import http from "@/http";

type Context = ActionContext<TenantAdminBanner[], any>

interface State {
  activeBanners: TenantAdminBanner[],
  allBanners: TenantAdminBanner[]
}

const BANNER_KEY = 'dismissed_admin_banner'

export enum Mutations {
  CLEAR_ALL = 'CLEAR_ALL',
  DISMISS_ADMIN_BANNER = 'DISMISS_ADMIN_BANNER',
  GET_ADMIN_BANNERS = 'GET_ADMIN_BANNERS'
}

const state: State = {
  activeBanners: [],
  allBanners: []
}

const actions = {
  // Initiated by route change into /admin
  async checkForAdminBanners({ commit, rootGetters }: Context) {
    if (rootGetters['user/isSuperAdmin']) {
      return
    }
    try {
      const { data } = await http.get('/admin/banners')
      commit(Mutations.GET_ADMIN_BANNERS, data)
    } catch (e) {
      console.log(e)
    }
  },
  async clearAll({ commit }: Context) {
    commit(Mutations.CLEAR_ALL)
  },
  async dismiss({ commit }: Context, id: Number) {
    commit(Mutations.DISMISS_ADMIN_BANNER, id)
  }
};

const mutations: MutationTree<State> = {
  [Mutations.CLEAR_ALL](state) {
    state.allBanners = []
    state.activeBanners = []
    localStorage.removeItem(BANNER_KEY)
  },
  [Mutations.DISMISS_ADMIN_BANNER](state, id: Number) {
    const dismissedIds: Array<Number> = JSON.parse(localStorage.getItem(BANNER_KEY) || '[]')

    if (!dismissedIds.includes(id)) {
      dismissedIds.push(id)
    }

    state.activeBanners = state.allBanners
      .filter(element => !dismissedIds.includes(element.id!!))
    localStorage.setItem(BANNER_KEY, JSON.stringify(dismissedIds))
  },
  [Mutations.GET_ADMIN_BANNERS](state, banners: TenantAdminBanner[]) {
    const dismissedIds: Array<Number> = JSON.parse(localStorage.getItem(BANNER_KEY) || '[]')

    state.allBanners = [...banners]
    state.activeBanners = state.allBanners
      .filter(element => !dismissedIds.includes(element.id!!))
  }
};

export default {
  namespaced: true,
  actions,
  mutations,
  state
};
