<script setup lang="ts">

import { HoopsConfigs, useComposablesForVueComponentWithHoopsCommunicator } from '@/plugins/hoops'
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import BottomToolbar from '@/components/admin/content/editor/part/three-d/BottomToolbar.vue'
import TopToolbar from '@/components/admin/content/editor/part/three-d/TopToolbar.vue'
import TopToolbarMobile from '@/components/admin/content/editor/part/three-d/TopToolbarMobile.vue'

const emit = defineEmits(['close'])
const props = defineProps({
  filepath: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  }
})
const store = useStore()
const close = () => emit('close')

useComposablesForVueComponentWithHoopsCommunicator()

onMounted(async () => {
  await store.dispatch(
    'hoopsWebViewer/createViewer',
    new HoopsConfigs({
      elementId: 'hoopsWebViewer',
      filepath: props.filepath,
      snapshotFilename: props.title
    })
  )
})

</script>

<template>
  <div class="is-active modal">
    <div class="modal-background"
         @click="close" />
    <div class="modal-card modal-card-size">
      <div class="box box-size">
        <!-- top-toolbar mixes well with the modal close button -->
        <div class="mb-2">
          <button class="delete is-hidden-touch is-pulled-right"
                  aria-label="close"
                  @click="close" />
          <top-toolbar class="toolbar-size mb-1"
                       :title="title" />
        </div>
        <!-- top-toolbar-mobile has a close option and should not mix with the modal close button -->
        <top-toolbar-mobile class="toolbar-size mb-1"
                            has-close-option
                            :title="title"
                            @close="close" />
        <div id="hoopsWebViewer" />
        <bottom-toolbar class="toolbar-size mt-1" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.modal-card-size {
  min-height: 75vh;
  min-width: 75vw;
}
@media (max-width: 768px) {
  .modal-card-size {
    height: 85vh;
  }
}

.box-size {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.toolbar-size {
  flex-grow: 0 !important;
}

#hoopsWebViewer {
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
  height: 600px;
  outline: 1px solid #dbdbdb;
  position: relative;
  width: 100%;
}
</style>
