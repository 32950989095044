<template>
  <pagination :offset="offset"
              :limit="limit"
              :total="total"
              @changePage="changePage"
              @changePerPage="changePerPage"/>
</template>

<script>
import { mapState, mapActions } from "vuex"
import Pagination from "@/components/common/Pagination"
export default {
  name: "SearchPaginationWrapper",
  components: { Pagination },
  computed: {
    ...mapState({
      offset: (state) => state.search.offset,
      limit: (state) => state.search.limit,
      total: (state) => state.search.total
    })
  },
  methods: {
    ...mapActions({
      changePage: 'search/setSearchPage',
      changePerPage: 'search/setSearchResultsPerPage'
    })
  }
}
</script>