<template>
  <div v-if="content" class="level-item" >
    <figure class="media-left">
      <router-link :to="{ path: uri }">
        <span class="icon">
          <i class="fas fa-external-link-alt"/>
        </span>
        {{ content.name }}
      </router-link>
    </figure>
  </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
  name: 'NotificationMedia',
  props: {
    mediaId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      content: null
    };
  },
  watch: {
    mediaId() {
      this.loadContent();
    }
  },
  async mounted() {
    this.loadContent();
  },
  computed: {
    uri() {
      if (this.content) {
        return `/${this.entityType}/${this.content.entityId || this.content.id}`;
      }
      return '';
    },
    entityType() {
      if (this.content) {
        return this.content.contentType;
      }
      return '';
    }
  },
  methods: {
    ...mapActions({
      getContent: 'content/getContentWithReturn'
    }),
    async loadContent() {
      if (this.mediaId) {
        this.content = await this.getContent({
          entityId: this.mediaId,
          entityType: 'media'
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
