import http from '@/http';
import router from '@/router';
import { getRoute } from "@/router/getRoute"
import filters from './filters';

const ORDERS_SEARCH = 'ORDERS_SEARCH';
const ORDERS_COLUMNS = 'ORDERS_COLUMNS';
const ORDERS_UPDATE_OFFSET = 'ORDERS_UPDATE_OFFSET';
const ORDERS_UPDATE_LIMIT = 'ORDERS_UPDATE_LIMIT';

const ORDERS_SORTING_UPDATE = 'ORDERS_SORTING_UPDATE';
const ORDERS_SORTING_APPEND = 'ORDERS_SORTING_APPEND';
const ORDERS_SORTING_SPLICE = 'ORDERS_SORTING_SPLICE';

const state = {
  items: [],
  offset: 0,
  limit: 25,
  total: 0,
  sorting: [],
  columns: []
};

const actions = {
  async search({state, commit}) {
    try {
      const params = {
        limit: state.limit,
        offset: state.offset * state.limit
      };
      Object.keys(state.filters.selected)
          .forEach((key) => {
            const filter = state.filters.items.find((f) => f.field === key);
            if (filter.type === 'date') {
              const min = state.filters.selected[key][0];
              const max = state.filters.selected[key][1];

              params[`${key}:min`] = min <= max ? min : max;
              params[`${key}:max`] = min >= max ? min : max;
            } else {
              params[key] = state.filters.selected[key];
            }
            params.sort = state.sorting;
          });
      const {headers, data} = await http.get('orders/search', {params});
      commit(ORDERS_SEARCH, {items: data, meta: headers});
    } catch (err) {
      // na
    }
  },
  async getOrderHistoryColumns({commit}) {
    try {
      const {data} = await http.get('orders/columns');
      commit(ORDERS_COLUMNS, {columns: data});
    } catch (error) {
      console.log('error :>> ', error);
    }
  },
  backToFirstPage({commit}) {
    commit(ORDERS_UPDATE_OFFSET, {offset: 0});
  },
  setOrdersPage({rootState, commit, dispatch}, page) {
    const route = getRoute()
    const {q = '', exact: exactMatch = false} = route.query;
    const offset = Math.max(page, 0);
    commit(ORDERS_UPDATE_OFFSET, {offset});
    dispatch('search', {q, exactMatch});
  },
  setOrderResultsPerPage({commit, dispatch}, limit) {
    commit(ORDERS_UPDATE_LIMIT, {limit});
    dispatch('search');
  },
  navigateToOrders({state}, query) {
    if (query) {
      router.push({name: 'Orders', query});
    } else {
      const queryParams = Object.assign({}, state.filters.selected);
      router.push({name: 'Orders', query: queryParams});
    }
  },
  navigateToOrder(context, order) {
    router.push({name: 'Order', params: {id: order.id.toString()}});
  },
  async toggleSortHeader({state, commit, dispatch}, {field}) {
    const ascField = `${field}:asc`;
    const dscField = `${field}:dsc`;

    const ascLocation = state.sorting.indexOf(ascField);
    const dscLocation = state.sorting.indexOf(dscField);

    if (ascLocation >= 0) {
      commit(ORDERS_SORTING_UPDATE, {index: ascLocation, value: dscField});
    } else if (dscLocation >= 0) {
      commit(ORDERS_SORTING_SPLICE, {index: dscLocation, length: 1});
    } else {
      commit(ORDERS_SORTING_APPEND, {value: ascField});
    }

    dispatch('search');
  }
};

const mutations = {
  [ORDERS_SEARCH](state, {items, meta}) {
    state.items = items;
    state.total = meta['x-count'] ? Number(meta['x-count']) : 0;
  },
  [ORDERS_UPDATE_OFFSET](state, {offset}) {
    state.offset = offset;
  },
  [ORDERS_UPDATE_LIMIT](state, {limit}) {
    state.limit = limit;
    state.offset = 0;
  },
  [ORDERS_COLUMNS](state, {columns}) {
    state.columns = columns;
  },
  [ORDERS_SORTING_APPEND](state, {value}) {
    state.sorting.push(value);
  },
  [ORDERS_SORTING_UPDATE](state, {index, value}) {
    state.sorting[index] = value;
  },
  [ORDERS_SORTING_SPLICE](state, {index, length}) {
    state.sorting.splice(index, length);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    filters
  }
};
