/**
 * Validates email address.
 *
 * Rules:
 *
 * 1. Email isn't blank.
 * 2. Contains an @ symbol with at least 1 character before it.
 * 3. Has a domain. I.e., @ with at least 2 letters after it.
 * 4. Ends with a fullstop with at least 2 letters after it.
 *
 * @param {string} email
 * @returns {boolean} whether or not submitted email is valid.
 */
export const validateEmail = (email) => {
  return /(.+)@(.+){2,}\.(.+){2,}/.test(email)
}
