/*
 * Supported configurations for our implementation of Hoops Communicator
 */
export interface HoopsConfigOptions {
  debug?: boolean
  elementId: string
  explodeMagnitudeMaxValue?: number
  filepath: string
  selectionHighlighting?: boolean
  snapshotFilename?: string
  snapshotWidth?: number
  zoomAnimationDuration?: number
  zoomScaleFactor?: number
}

export class HoopsConfigs {
  debug: boolean
  elementId: string
  explodeMagnitudeMaxValue: number
  filepath: string
  selectionHighlighting: boolean
  snapshotFilename: string
  snapshotWidth: number
  zoomAnimationDuration: number
  zoomScaleFactor: number

  constructor({
    debug = false,
    elementId,
    explodeMagnitudeMaxValue = 2.0,
    filepath,
    selectionHighlighting = false,
    snapshotFilename = 'untitled.png', // png default; file extension optional
    snapshotWidth = 800, // scales based on the canvas ratios
    zoomAnimationDuration = 200, // milliseconds
    zoomScaleFactor = 0.25
  }: HoopsConfigOptions) {
    this.debug = debug
    this.elementId = elementId
    this.explodeMagnitudeMaxValue = explodeMagnitudeMaxValue
    this.filepath = filepath
    this.selectionHighlighting = selectionHighlighting
    this.snapshotFilename = snapshotFilename
    this.snapshotWidth = snapshotWidth
    this.zoomAnimationDuration = zoomAnimationDuration
    this.zoomScaleFactor = zoomScaleFactor

    if (debug) {
      console.log(
        'Hoops Configs',
        {
          'debug': debug,
          'elementId': elementId,
          'explodeMagnitudeMaxValue': explodeMagnitudeMaxValue,
          'filepath': filepath,
          'selectionHighlighting': selectionHighlighting,
          'snapshotFilename': snapshotFilename,
          'snapshotWidth': snapshotWidth,
          'zoomAnimationDuration': zoomAnimationDuration,
          'zoomScaleFactor': zoomScaleFactor,
        }
      )
    }
  }
}
